import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {TokenStorage} from '../../auth/token-storage.service';
import {AppConfig} from '../../../config/appConfig';
import {Observable} from 'rxjs';
import {ResultObject} from '../../models/api/ResultObject';
import {ServicePattern} from '../../models/interface/ServicePattern';
import {EstabelecimentoFunctions} from '../../models/interface/EstabelecimentoFunctions';
import {PlanoComercial} from '../../models/api/PlanoComercial';

@Injectable()
export class PlanoComercialService extends EstabelecimentoFunctions implements ServicePattern<PlanoComercial> {

	readonly ROUTE = '/planoComercial';

	constructor(private http: HttpClient, private tokenStorage: TokenStorage) {
		super();
	}

	getAll(
		dateFilter: { dataInicio: Date, dataFim: Date } = {dataInicio: null, dataFim: null},
		page: number = null,
		perPage: number = null,
		componentId: string = '',
	): Observable<PlanoComercial[]> {
		return this.http.get<PlanoComercial[]>(
			AppConfig.getAPIEndpoint() + this.ROUTE + '/list/' + this.tokenStorage.getUserInfo().idUsuario,
			{
				headers: AppConfig.getHTTPHeader().headers.set('componentId', componentId),
				params: {
					...this.getDateFilterQueryParam(dateFilter.dataInicio, dateFilter.dataFim),
					...(page || page == 0 ? { page: page + 1 } : {}),
					...(perPage ? { per_page: perPage } : {})
				}
			}
		);
	}

	get(idPlanoComercial: string, componentId: string = ''): Observable<PlanoComercial> {
		return this.http.get<PlanoComercial>(
			`${AppConfig.getAPIEndpoint()}${this.ROUTE}/info/${this.tokenStorage.getUserInfo().idUsuario}/${idPlanoComercial}`,
			{
				headers: AppConfig.getHTTPHeader().headers.set('componentId', componentId),
				params: {}
			}
		);
	}

	save(body: PlanoComercial, componentId: string = ''): Observable<ResultObject<any>> {
		return this.http.post<ResultObject<any>>(
			AppConfig.getAPIEndpoint() + this.ROUTE + '/' + this.tokenStorage.getUserInfo().idUsuario,
			body,
			{
				headers: AppConfig.getHTTPHeader().headers.set('componentId', componentId)
			}
		);
	}

	update(body: PlanoComercial, componentId: string = ''): Observable<ResultObject<any>> {
		return this.http.put<ResultObject<any>>(
			AppConfig.getAPIEndpoint() + this.ROUTE + '/' + this.tokenStorage.getUserInfo().idUsuario,
			body,
			{
				headers: AppConfig.getHTTPHeader().headers.set('componentId', componentId)
			}
		);
	}

	delete(idPlanoComercial: string, componentId: string = ''): Observable<ResultObject<any>> {
		return this.http.delete<ResultObject<any>>(
			AppConfig.getAPIEndpoint() + this.ROUTE + '/' +
			this.tokenStorage.getUserInfo().idUsuario +
			'/' + idPlanoComercial,
			{
				headers: AppConfig.getHTTPHeader().headers.set('componentId', componentId)
			}
		);
	}

	associarEmpresa(idPlanoComercial: string, idEmpresa: string, componentId: string = '', removerAssociacao: boolean = false): Observable<ResultObject<any>> {
		return this.http.post<ResultObject<any>>(
			`${AppConfig.getAPIEndpoint()}${this.ROUTE}/associar/empresa/${this.tokenStorage.getUserInfo().idUsuario}`,
			{ idPlanoComercial: idPlanoComercial, idEmpresa: idEmpresa, removerAssociacao: removerAssociacao },
			{ headers: AppConfig.getHTTPHeader().headers.set('componentId', componentId) }
		);
	}

	associarLoja(idPlanoComercial: string, idLoja: string, componentId: string = '', removerAssociacao: boolean = false): Observable<ResultObject<any>> {
		return this.http.post<ResultObject<any>>(
			`${AppConfig.getAPIEndpoint()}${this.ROUTE}/associar/loja/${this.tokenStorage.getUserInfo().idUsuario}`,
			{ idPlanoComercial: idPlanoComercial, idLoja: idLoja, removerAssociacao: removerAssociacao },
			{ headers: AppConfig.getHTTPHeader().headers.set('componentId', componentId) }
		);
	}

}
