import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {TokenStorage} from '../../auth/token-storage.service';
import {EstabelecimentoFunctions} from '../../models/interface/EstabelecimentoFunctions';
import {ServicePattern} from '../../models/interface/ServicePattern';
import {EstabelecimentosBloqueados} from '../../models/api/EstabelecimentosBloqueados';
import {Observable} from 'rxjs';
import {AppConfig} from '../../../config/appConfig';

@Injectable()
export class EstabelecimentosBloqueadosService extends EstabelecimentoFunctions implements ServicePattern<EstabelecimentosBloqueados>{

	readonly ROUTE = '/estabelecimentosBloqueados';

	constructor(private http: HttpClient, private tokenStorage: TokenStorage) {
		super();
	}

	getAll(
		componentId: string,
        filtroEstabelecimento: string = undefined,
        page: number,
        per_page: number,
	):
		Observable<any[]> {
		return this.http.get<any[]>(
			AppConfig.getAPIEndpoint() + this.ROUTE + '/list/' + this.tokenStorage.getUserInfo().idUsuario,
			{
				headers: AppConfig.getHTTPHeader().headers.set('componentId', componentId),
				params: {
					...(page || page == 0 ? { page: (page).toString() } : {}),
					...(per_page ? { per_page: per_page.toString() } : {}),
                    ...(filtroEstabelecimento ? { filtroEstabelecimento: filtroEstabelecimento } : {}),
				}
			}
		);
	}

}
