import {SelectValue} from '../../interface/SelectValue';

export enum TipoNF {
	NFE = 'nfe',
	NFCE = 'nfce'
}

export const TipoNFSelectValues: SelectValue<TipoNF>[] = [
	{ value: TipoNF.NFE, text: 'NF-e' },
	{ value: TipoNF.NFCE, text: 'NFc-e' }
];
