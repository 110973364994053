import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {TokenStorage} from '../../auth/token-storage.service';
import {AppConfig} from '../../../config/appConfig';
import {Observable} from 'rxjs';
import {Entregador} from '../../models/api/Entregador';
import {ResultObject} from '../../models/api/ResultObject';
import {ServicePattern} from '../../models/interface/ServicePattern';
import {EstabelecimentoFunctions} from '../../models/interface/EstabelecimentoFunctions';
import {PedidoFilter} from './pedidos.service';
import {Estabelecimento} from '../../models/template/Estabelecimento';
import {DateFilter} from '../../models/interface/DateFilter';

export class VendaFilter {

	idProduto: string;

	constructor(params: any = {}) {
		if (!params) params = {};
		this.idProduto = params.idProduto || null;
	}
}

@Injectable()
export class EntregadoresService extends EstabelecimentoFunctions implements ServicePattern<Entregador> {

  constructor(private http: HttpClient, private tokenStorage: TokenStorage ) {
	  super();
  }

	getAll(
		dateFilter: DateFilter = {dataInicio: null, dataFim: null},
		page: number = null,
		perPage: number = null,
		componentId: string = '',
		searchString: string = null,
		estabelecimento?: Estabelecimento,
		showAll?: boolean,
		portaria?: boolean
	): Observable<Entregador[]> {

		return this.http.get<Entregador[]>(
			AppConfig.getAPIEndpoint() + '/entregadores/list/' + this.tokenStorage.getUserInfo().idUsuario,
			{
				headers: AppConfig.getHTTPHeader().headers.set('componentId', componentId),
				params: {
					...this.getEstabelecimentoQueryParam(estabelecimento),
					...this.getDateFilterQueryParam(dateFilter.dataInicio, dateFilter.dataFim),
					...(page || page == 0 ? { page: page + 1 } : {}),
					...(perPage ? { per_page: perPage } : {}),
					...(searchString != null ? { searchString: searchString } : {}),
					...(showAll != null ? { showAll: showAll } : {}),
					...(portaria != null ? { portaria } : {})
				}
			}
		);
	}

	getAllWithVendasFilter(dateFilter: { dataInicio: Date, dataFim: Date } = { dataInicio: null, dataFim: null },
						   componentId: string = '',
						   vendaFilter: VendaFilter = new VendaFilter()):
		Observable<Entregador[]> {
		return this.http.get<Entregador[]>(
			AppConfig.getAPIEndpoint() + '/entregadores/list/' + this.tokenStorage.getUserInfo().idUsuario,
			{
				headers: AppConfig.getHTTPHeader().headers.set('componentId', componentId),
				params: {
					...this.getEstabelecimentoQueryParam(),
					...this.getDateVendasFilterQueryParam(dateFilter.dataInicio, dateFilter.dataFim),
					...(vendaFilter.idProduto ? { idProduto: vendaFilter.idProduto } : {}),
				}
			}
		);
	}

	getAllWithPedidosFilter(
		componentId: string = '',
		page: number = null,
		perPage: number = null,
		pedidoFilter: PedidoFilter = new PedidoFilter()
	): Observable<Entregador[]> {
		return this.http.get<Entregador[]>(
			AppConfig.getAPIEndpoint() + '/entregadores/list/' + this.tokenStorage.getUserInfo().idUsuario,
			{
				headers: AppConfig.getHTTPHeader().headers.set('componentId', componentId),
				params: {
					showAll: true,
					...this.getEstabelecimentoQueryParam(),
					...this.getDatePedidosFilterQueryParam(pedidoFilter.dateFilter.dataInicio, pedidoFilter.dateFilter.dataFim),
					...(pedidoFilter.statusFilter && pedidoFilter.statusFilter.length > 0 ? { status: pedidoFilter.statusFilter.join(',') } : {}),
					...(pedidoFilter.pedidoOrigem && pedidoFilter.pedidoOrigem.length > 0 ? { pedidoOrigem: pedidoFilter.pedidoOrigem.join(',') } : {}),
					...(pedidoFilter.acertado ? { acertado: pedidoFilter.acertado } : {}),
					...(page || page == 0 ? {page: page + 1} : {}),
					...(perPage ? {per_page: perPage} : {})
				}
			}
		);
	}

	get(idEntregador: number, componentId: string = ''): Observable<Entregador> {
		return this.http.get<Entregador>(
			AppConfig.getAPIEndpoint() + '/entregadores/' + idEntregador + '/' + this.tokenStorage.getUserInfo().idUsuario,
			{
				headers: AppConfig.getHTTPHeader().headers.set('componentId', componentId)
			}
		);
	}

	save(body: Entregador, getEstabelecimentoFromCache: boolean = true, componentId: string = ''): Observable<ResultObject<any>> {
		if (getEstabelecimentoFromCache == true) { body.estabelecimento = this.getEstabelecimentoOnCache(); }
		return this.http.post<ResultObject<any>>(
			AppConfig.getAPIEndpoint() + '/entregadores/' + this.tokenStorage.getUserInfo().idUsuario,
			body,
			{
				headers: AppConfig.getHTTPHeader().headers.set('componentId', componentId)
			}
		);
	}

	update(body: Entregador, componentId: string = ''): Observable<ResultObject<any>> {
		return this.http.put<ResultObject<any>>(
			AppConfig.getAPIEndpoint() + '/entregadores/' + this.tokenStorage.getUserInfo().idUsuario,
			body,
			{
				headers: AppConfig.getHTTPHeader().headers.set('componentId', componentId)
			}
		);
	}

	delete(idEntregador: number, componentId: string = ''): Observable<ResultObject<any>> {
		return this.http.delete<ResultObject<any>>(
			AppConfig.getAPIEndpoint() + '/entregadores/' + this.tokenStorage.getUserInfo().idUsuario + '/' + idEntregador,
			{
				headers: AppConfig.getHTTPHeader().headers.set('componentId', componentId)
			}
		);
	}

	getEntriesCount(componentId: string = ''): Observable<{entries: number}> {
		return this.http.get<{entries: number}>(
			AppConfig.getAPIEndpoint() + '/entregadores/list/' + this.tokenStorage.getUserInfo().idUsuario,
			{
				headers: AppConfig.getHTTPHeader().headers.set('componentId', componentId),
				params: {
					...this.getEstabelecimentoQueryParam(),
					entriesOnly: true
				}
			}
		);
	}

	/**
	 * Formata a query string do filtro de data para Venda
	 */
	getDateVendasFilterQueryParam(dataInicio = null, dataFim = null): any {
		if (!!dataInicio && !!dataFim) {
			dataInicio.setUTCHours(0, 0, 0, 0);
			dataFim.setUTCHours(23, 59, 59, 59);
			return {
				vendaDataInicio: dataInicio.toISOString(),
				vendaDataFim: dataFim.toISOString()
			};
		}
		return {};
	}

	/**
	 * Formata a query string do filtro de data para PEdido
	 */
	getDatePedidosFilterQueryParam(dataInicio = null, dataFim = null): any {
		if (!!dataInicio && !!dataFim) {
			dataInicio.setUTCHours(0, 0, 0, 0);
			dataFim.setUTCHours(23, 59, 59, 59);
			return {
				pedidoDataInicio: dataInicio.toISOString(),
				pedidoDataFim: dataFim.toISOString()
			};
		}
		return {};
	}

	getEntriesOnlyQueryParam(isFirstQuery: boolean = false): string {
		const _s = isFirstQuery ? '?' : '&';
		return _s + 'entriesOnly=true';
	}

	removeAssociation(idEntregador: string, idEmpresa: string, idLoja: string, componentId: string = '') {
		return this.http.post<ResultObject<any>>(
			AppConfig.getAPIEndpoint() + '/entregadores/remover/associacao/' + this.tokenStorage.getUserInfo().idUsuario,
			{
				idEntregador: idEntregador,
				idEmpresa: idEmpresa,
				idLoja: idLoja
			},
			{
				headers: AppConfig.getHTTPHeader().headers.set('componentId', componentId)
			}
		);
	}

	associate(idEntregador: string, idEmpresa: string, idLoja: string, componentId: string = '') {
		return this.http.post<ResultObject<any>>(
			AppConfig.getAPIEndpoint() + '/entregadores/associacao/' + this.tokenStorage.getUserInfo().idUsuario,
			{
				idEntregador: idEntregador,
				idEmpresa: idEmpresa,
				idLoja: idLoja
			},
			{ headers: AppConfig.getHTTPHeader().headers.set('componentId', componentId) }
		);
	}

}
