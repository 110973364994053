import {SelectValue} from '../../interface/SelectValue';

export enum TipoNotaFiscal {
	ENTRADA = <any>'0',
	SAIDA = <any>'1'
}

export const TipoNotaFiscalSelectValues: SelectValue<TipoNotaFiscal>[] = [
	{ value: TipoNotaFiscal.ENTRADA, text: 'Entrada' },
	{ value: TipoNotaFiscal.SAIDA, text: 'Saída' },
];
