import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {TokenStorage} from '../../auth/token-storage.service';
import {EstabelecimentoFunctions} from '../../models/interface/EstabelecimentoFunctions';
import {ServicePattern} from '../../models/interface/ServicePattern';
import {Logs, ErrosSincronizacao} from '../../models/api/Logs';
import {Observable} from 'rxjs';
import {AppConfig} from '../../../config/appConfig';
import {ResultObject} from '../../models/api/ResultObject';

@Injectable()
export class LoggerService extends EstabelecimentoFunctions implements ServicePattern<Logs> {

	constructor(private http: HttpClient, private tokenStorage: TokenStorage) {
		super();
	}

	getAll(dateFilter: { dataInicio: Date, dataFim: Date } = {dataInicio: null, dataFim: null}, componentId: string = ''): Observable<Logs[]> {
		return this.http.get<Logs[]>(
			AppConfig.getAPIEndpoint() + '/logs/list/' + this.tokenStorage.getUserInfo().idUsuario,
			{
				headers: AppConfig.getHTTPHeader().headers.set('componentId', componentId),
				params: {
					...this.getEstabelecimentoQueryParam(),
					...this.getDateFilterQueryParam(dateFilter.dataInicio, dateFilter.dataFim)
				}
			}
		);
	}

	getAllSystem(dateFilter: { dataInicio: Date, dataFim: Date } = { dataInicio: null, dataFim: null }, page: number = null, perPage: number = null, componentId: string = ''): Observable<Logs[]> {
		return this.http.get<Logs[]>(
			AppConfig.getAPIEndpoint() + '/logs/sys_logs/' + this.tokenStorage.getUserInfo().idUsuario,
			{
				headers: AppConfig.getHTTPHeader().headers.set('componentId', componentId),
				params: {
					...this.getEstabelecimentoQueryParam(),
					...this.getDateFilterQueryParam(dateFilter.dataInicio, dateFilter.dataFim),
					...(page || page == 0 ? { page: page + 1 } : {}),
					...(perPage ? { per_page: perPage } : {})
				}
			}
		);
	}

	getAllSync(componentId: string = ''): Observable<ErrosSincronizacao[]>{
		return this.http.get<ErrosSincronizacao[]>(
			AppConfig.getAPIEndpoint() + '/logs/erros_sincronizacao/' + this.tokenStorage.getUserInfo().idUsuario,
			{
				headers: AppConfig.getHTTPHeader().headers.set('componentId', componentId)
			}
		)
	}

	get(idLog: number, componentId: string = ''): Observable<Logs> {
		return this.http.get<Logs>(
			AppConfig.getAPIEndpoint() + '/logs/' + idLog + '/' + this.tokenStorage.getUserInfo().idUsuario,
			{
				headers: AppConfig.getHTTPHeader().headers.set('componentId', componentId)
			}
		);
	}

	save(body: Logs, getEstabelecimentoFromCache: boolean = true, componentId: string = ''): Observable<ResultObject<any>> {
		return this.http.post<ResultObject<any>>(
			AppConfig.getAPIEndpoint() + '/logs/' + this.tokenStorage.getUserInfo().idUsuario,
			body,
			{
				headers: AppConfig.getHTTPHeader().headers.set('componentId', componentId)
			}
		);
	}

	update(body: Logs, componentId: string = ''): Observable<ResultObject<any>> {
		return this.http.put<ResultObject<any>>(
			AppConfig.getAPIEndpoint() + '/logs/' + this.tokenStorage.getUserInfo().idUsuario,
			body,
			{
				headers: AppConfig.getHTTPHeader().headers.set('componentId', componentId)
			}
		);
	}

	delete(idLog: number, componentId: string = ''): Observable<ResultObject<any>> {
		return this.http.delete<ResultObject<any>>(
			AppConfig.getAPIEndpoint() + '/logs/' + this.tokenStorage.getUserInfo().idUsuario + '/' + idLog,
			{
				headers: AppConfig.getHTTPHeader().headers.set('componentId', componentId)
			}
		);
	}

}
