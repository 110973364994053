
import { Injectable } from '@angular/core';
import { EstabelecimentoFunctions } from '../../models/interface/EstabelecimentoFunctions';
import { ServicePattern } from '../../models/interface/ServicePattern';
import { HttpClient } from '@angular/common/http';
import { TokenStorage } from '../../auth/token-storage.service';
import { Observable } from 'rxjs';
import { AppConfig } from '../../../config/appConfig';
import { ResultObject } from '../../models/api/ResultObject';
import { Documento } from '../../models/api/Documento';

@Injectable()
export class DocumentosService extends EstabelecimentoFunctions implements ServicePattern<Documento> {

	constructor(private http: HttpClient, private tokenStorage: TokenStorage) {
		super();
	}

	getAll(convenio: string, page: number, per_page: number, componentId = '', nome?: string, desc?: string): Observable<any> {
		return this.http.get<any>(
			AppConfig.getAPIEndpoint() + '/documentosConvenios/' + this.tokenStorage.getUserInfo().idUsuario,
			{
				headers: AppConfig.getHTTPHeader().headers.set('componentId', componentId),
				params: {
					convenio,
					page: String(page),
					per_page: String(per_page),
					...(nome ? { nome } : {}),
					...(desc ? { desc } : {})
				}
			}
		);
	}

	create(body: FormData): Observable<ResultObject<any>> {
		return this.http.post<ResultObject<any>>(
			AppConfig.getAPIEndpoint() + '/documentosConvenios/' + this.tokenStorage.getUserInfo().idUsuario,
			body
		);
	}

	delete(idDocumento: string, componentId: string = ''): Observable<ResultObject<any>> {
		return this.http.delete<ResultObject<any>>(
			AppConfig.getAPIEndpoint() + '/documentosConvenios/' + this.tokenStorage.getUserInfo().idUsuario + '/' + idDocumento,
			{
				headers: AppConfig.getHTTPHeader().headers.set('componentId', componentId)
			}
		);
	}

}