import {DefaultFields} from '../interface/DefaultFields';

export class Endereco extends DefaultFields<Endereco> {
	idEndereco: number;
	titulo: string;
	endereco: string;
	numero: string;
	complemento: string;
	cep: string;
	bairro: string;
	cidade: string;
	estado: string;
	estadoAcronimo: string;
	estadoCod: string;
	codigoMunicipio: string;
	referencia: string;
	observacao: string;
	isDefault: boolean;

	lat: string;
	long: string;

	/** ViaCEP API Params */
	logradouro: string;
	localidade: string;
	uf: string;
	unidade: string;
	ibge: string;
	gia: string;
}

export function getObjectFromViaCEPParams(eFromApi: Endereco): Endereco {
	let e = new Endereco();
	e.cep = eFromApi.cep;
	e.complemento = eFromApi.complemento;
	e.endereco = eFromApi.logradouro;
	e.bairro = eFromApi.bairro;
	e.cidade = eFromApi.localidade;
	e.estadoAcronimo = eFromApi.uf;
	return e;
}


export function getObjectFromGeocodeResult(geoCodeResult): Endereco {
	const _end = new Endereco();

	if (!!geoCodeResult.geometry) {

		_end.lat = geoCodeResult.geometry.location.lat();
		_end.long = geoCodeResult.geometry.location.lng();
	}

	/** Verifica os componentes do endereço */
	for (const ac of geoCodeResult.address_components.reverse()) {

		/** Verifica o tipo do endereço e popula o objeto EnderecoCompleto */
		verificarTipo: for (const type of ac.types) {
			switch (type) {

				/* Endereço */
				case AddressComponentType.STREET_NUMBER:
				case AddressComponentType.ROUTE:
				case AddressComponentType.SUBLOCALITY_2:
				case AddressComponentType.SUBLOCALITY_3:
				case AddressComponentType.SUBLOCALITY_4:
				case AddressComponentType.SUBLOCALITY_5:
					// case AddressComponentType.SUBLOCALITY:
					const logr = _end.endereco;
					_end.endereco = !!logr ? logr + ', ' + ac.long_name : ac.long_name;
					break verificarTipo;

				/* Bairro */
				case AddressComponentType.SUBLOCALITY_1:
				case AddressComponentType.BURGH:
				case AddressComponentType.BURGH_1:
					_end.bairro = ac.long_name;
					break verificarTipo;

				/* Cidade */
				case AddressComponentType.CITY:
					_end.cidade = ac.long_name;
					break verificarTipo;

				/* Estado */
				case AddressComponentType.STATE:
					_end.estado = ac.short_name;
					break verificarTipo;

				/* CEP */
				case AddressComponentType.POSTAL_CODE:
					if (ac.long_name != '70297-400') { _end.cep = ac.long_name.replace('-', ''); }
					break verificarTipo;
			}
		}
	}

	return _end;
}

export enum AddressComponentType {
	STREET_NUMBER = 'street_number',
	ROUTE = 'route',
	SUBLOCALITY = 'sublocality', // endereco
	SUBLOCALITY_1 = 'sublocality_level_1', // endereco
	SUBLOCALITY_2 = 'sublocality_level_2', // endereco
	SUBLOCALITY_3 = 'sublocality_level_3', // endereco
	SUBLOCALITY_4 = 'sublocality_level_4', // endereco
	SUBLOCALITY_5 = 'sublocality_level_5', // endereco
	BURGH = 'administrative_area_level_4', // bairro
	BURGH_1 = 'administrative_area_level_3', // bairro
	CITY = 'administrative_area_level_2',
	STATE = 'administrative_area_level_1',
	COUNTRY = 'country',
	POSTAL_CODE = 'postal_code'
}
