import { Injectable } from '@angular/core';
import { ServicePattern } from '../../models/interface/ServicePattern';
import { HttpClient } from '@angular/common/http';
import { TokenStorage } from '../../auth/token-storage.service';
import { Observable } from 'rxjs';
import { AppConfig } from '../../../config/appConfig';
import { ResultObject } from '../../models/api/ResultObject';
import { Pedido } from '../../models/api/Pedido';
import { EstabelecimentoFunctions } from '../../models/interface/EstabelecimentoFunctions';
import { Empresa } from '../../models/api/Empresa';
import { Loja } from '../../models/api/Loja';
import { PedidoStatus } from '../../models/enum/PedidoStatus';
import { DateFilter } from '../../models/interface/DateFilter';
import { PedidoOrigem } from '../../models/enum/PedidoOrigem';
import { BoletoPJBank } from '../../models/api/boleto/BoletoPJBank';
import { Estabelecimento } from '../../models/template/Estabelecimento';
import { Grupo } from '../../models/api/grupo/Grupo';
import { ConfiguracaoPedido } from '../../models/api/ConfiguracaoPedido';
import { IndicacaoCompra } from '../../models/enum/IndicacaoCompra';

export class PedidoFilter {
	dateFilter?: DateFilter;
	statusFilter?: PedidoStatus[];
	filterCanalVendas?: IndicacaoCompra[];
	pedidoOrigem?: PedidoOrigem[];
	idEntregador?: string;
	idCliente?: string;
	acertado?: boolean | boolean[];
	idProduto?: string;

	constructor(params: PedidoFilter = {}) {
		if (!params) params = {};
		this.dateFilter = params.dateFilter || <DateFilter>{};
		this.statusFilter = params.statusFilter || [];
		this.filterCanalVendas = params.filterCanalVendas || [];
		this.pedidoOrigem = params.pedidoOrigem || [];
		this.idEntregador = params.idEntregador || null;
		this.idCliente = params.idCliente || null;
		this.acertado = params.acertado || null;
		this.idProduto = params.idProduto || null;
	}
}

export interface BoletoResponse {
	boleto: BoletoPJBank;
	pedido: Pedido;
}

@Injectable()
export class PedidosService extends EstabelecimentoFunctions implements ServicePattern<Pedido> {

	readonly ROUTE = '/pedidos';

	constructor(private http: HttpClient, private tokenStorage: TokenStorage) {
		super();
	}

	getAll(
		componentId: string = '',
		page: number = null,
		pageSize: number = null,
		pedidoFilter: PedidoFilter = new PedidoFilter(),
		autoPopulateHours: boolean = true,
		ignoreEstabelecimentoOnCache: boolean = false,
		estabelecimentos: { empresa?: string, loja?: string, multiEmpresa?: string, multiLoja?: string } = null,
		estabelecimentoOnParam: boolean = false,
		searchString: string = null,
		entriesOnly?: boolean
	): Observable<Pedido[]> {

		return this.http.get<Pedido[]>(
			AppConfig.getAPIEndpoint() + this.ROUTE + '/list/' + this.tokenStorage.getUserInfo().idUsuario,
			{
				headers: AppConfig.getHTTPHeader().headers.set('componentId', componentId),
				params: {
					...(
						ignoreEstabelecimentoOnCache == true && estabelecimentoOnParam != true ?
							(estabelecimentos != null ? estabelecimentos : {}) :
							this.getEstabelecimentoQueryParam(ignoreEstabelecimentoOnCache == true ? estabelecimentos : undefined)
					),
					...this.getDateFilterWithFieldQueryParam(pedidoFilter.dateFilter.campo, pedidoFilter.dateFilter.dataInicio, pedidoFilter.dateFilter.dataFim, autoPopulateHours),
					...(pedidoFilter.statusFilter && pedidoFilter.statusFilter.length > 0 ? { status: pedidoFilter.statusFilter.join(',') } : {}),
					...(pedidoFilter.filterCanalVendas && pedidoFilter.filterCanalVendas.length > 0 ? { indicacaoCompra: pedidoFilter.filterCanalVendas.join(',') } : {}),
					...(pedidoFilter.pedidoOrigem && pedidoFilter.pedidoOrigem.length > 0 ? { pedidoOrigem: pedidoFilter.pedidoOrigem.join(',') } : {}),
					...(pedidoFilter.idEntregador ? { idEntregador: pedidoFilter.idEntregador } : {}),
					...(pedidoFilter.idProduto ? { idProduto: pedidoFilter.idProduto } : {}),
					...(pedidoFilter.idCliente ? { idCliente: pedidoFilter.idCliente } : {}),
					...(
						pedidoFilter.acertado ?
							{ acertado: Array.isArray(pedidoFilter.acertado) ? pedidoFilter.acertado.join(',') : pedidoFilter.acertado }
							: {}
					),
					...(page || page == 0 ? { page: page + 1 } : {}),
					...(pageSize ? { per_page: pageSize } : {}),
					...(searchString != null ? { searchString: searchString } : {}),
					...(entriesOnly != null ? { entriesOnly: entriesOnly } : {}),
					...(pedidoFilter.dateFilter.notPopulateHour != null ? { notPopulateHour: pedidoFilter.dateFilter.notPopulateHour } : {})
				}
			}
		);
	}

	get(idProduto: number): Observable<Pedido> {
		// TODO
		return null;
	}

	emitirNota(idPedido: any, sendToEmail: boolean = false, idSmtpConfig: string = '', emailToSend: string = '', idCliente: string = null, componentId: string = '', docCliente: string = null, autorizacaoCartao: string = null, clientePadrao: boolean = false, cfe = false): Observable<ResultObject<any>> {
		return this.http.post<ResultObject<any>>(
			AppConfig.getAPIEndpoint() + this.ROUTE + '/emitir_nota/' + idPedido + '/' + this.tokenStorage.getUserInfo().idUsuario,
			{
				sendToEmail,
				idSmtpConfig,
				emailToSend,
				...(idCliente ? { idCliente } : {}),
				...(docCliente ? { docCliente } : {}),
				...(autorizacaoCartao ? { autorizacaoCartao } : {}),
				clientePadrao,
				cfe
			},
			{
				headers: AppConfig.getHTTPHeader().headers.set('componentId', componentId)
			}
		);
	}

	emitirMultiplasNota(idsPedidos: any, componentId: string = '', clientePadrao: boolean = false, cfe = false) {
		return this.http.post<any>(
			AppConfig.getAPIEndpoint() + this.ROUTE + '/emitir_nota_multilplo/' + this.tokenStorage.getUserInfo().idUsuario,
			{
				idsPedidos,
				clientePadrao,
				cfe
			},
			<any>{
				headers: AppConfig.getHTTPHeader().headers.set('componentId', componentId),
				responseType: 'blob'
			}
		);
	}

	criarGrupoDeAcerto(grupo: Grupo, componentId: string = ''): Observable<ResultObject<any>> {
		return this.http.post<ResultObject<any>>(
			AppConfig.getAPIEndpoint() + this.ROUTE + '/criarGrupoDeAcerto/' + this.tokenStorage.getUserInfo().idUsuario,
			{
				grupo
			},
			{
				headers: AppConfig.getHTTPHeader().headers.set('componentId', componentId)
			}
		);
	}

	emitirBoleto(idPedido: any, tokenControle: string, idCliente: string = null, cpf_cnpj?: string, nome?: string, componentId: string = ''): Observable<BoletoPJBank> {
		return this.http.get<BoletoPJBank>(
			`${AppConfig.getAPIEndpoint() + this.ROUTE}/emitir_boleto/${idPedido}/${this.tokenStorage.getUserInfo().idUsuario}`,
			{
				headers: AppConfig.getHTTPHeader().headers.set('componentId', componentId),
				params: {
					...(idCliente != null ? { idCliente: idCliente } : {}),
					...(cpf_cnpj ? { cpf_cnpj } : {}),
					...(nome ? { nome } : {}),
					tokenControle
				}
			}
		);
	}

	save(body: Pedido | any, getEstabelecimentoFromCache: boolean = true, componentId: string = '', cpf_cnpj?: string, nome?: string): Observable<ResultObject<any> | BoletoResponse> {
		if (getEstabelecimentoFromCache) { body.estabelecimento = this.getEstabelecimentoOnCache(); }

		let $body = Array.isArray(body) ? [...body] : { ...body };
		this.clearPedidoObject($body, false);
		if (cpf_cnpj) $body.cpf_cnpj = cpf_cnpj
		if (nome) $body._nome = nome

		console.log(JSON.stringify(body));

		return this.http.post<ResultObject<any> | BoletoResponse>(
			AppConfig.getAPIEndpoint() + this.ROUTE + '/lancamento2/' + this.tokenStorage.getUserInfo().idUsuario,
			$body,
			{
				headers: AppConfig.getHTTPHeader().headers.set('componentId', componentId)
			}
		);
	}

	update(body: Pedido | any, componentId: string = ''): Observable<ResultObject<any>> {
		let $body = Array.isArray(body) ? [...body] : { ...body };
		this.clearPedidoObject($body, true);

		console.log(JSON.stringify(body));

		return this.http.put<ResultObject<any>>(
			AppConfig.getAPIEndpoint() + this.ROUTE + (localStorage.alterar2 == 'true' ? '/alterar2/' : '/') + this.tokenStorage.getUserInfo().idUsuario,
			$body,
			{
				headers: AppConfig.getHTTPHeader().headers.set('componentId', componentId)
			}
		);
	}

	alterarStatus(body: Pedido | any, componentId: string = ''): Observable<ResultObject<any>> {
		let $body = Array.isArray(body) ? [...body] : { ...body };
		this.clearPedidoObject($body, true);

		console.log(JSON.stringify(body));

		return this.http.put<ResultObject<any>>(
			AppConfig.getAPIEndpoint() + this.ROUTE + '/status/' + this.tokenStorage.getUserInfo().idUsuario,
			$body,
			{
				headers: AppConfig.getHTTPHeader().headers.set('componentId', componentId)
			}
		);
	}

	alterarStatusMultiplos(pedidos: string[], dataEntrega: Date, componentId: string = ''): Observable<ResultObject<any>> {
		return this.http.put<ResultObject<any>>(
			AppConfig.getAPIEndpoint() + this.ROUTE + '/status_multiplo/' + this.tokenStorage.getUserInfo().idUsuario,
			{
				pedidos,
				...(dataEntrega ? { dataEntrega: dataEntrega.toISOString() } : {})
			},
			{
				headers: AppConfig.getHTTPHeader().headers.set('componentId', componentId)
			}
		);
	}

	repassarPedido(pedidos: string[], entregador: string, alterarEntregadorAcerto: boolean, componentId: string = '') {
		return this.http.put<ResultObject<any>>(
			AppConfig.getAPIEndpoint() + this.ROUTE + '/repassar/' + this.tokenStorage.getUserInfo().idUsuario,
			{
				pedidos,
				entregador,
				alterarEntregadorAcerto
			},
			{
				headers: AppConfig.getHTTPHeader().headers.set('componentId', componentId)
			}
		);
	}

	acertarPedido(body: Pedido | any, componentId: string = ''): Observable<ResultObject<any>> {
		let $body = Array.isArray(body) ? [...body] : { ...body, novoAcerto: true };
		this.clearPedidoObject($body, true);
		return this.http.put<ResultObject<any>>(
			AppConfig.getAPIEndpoint() + '/v2/pedido/acertarPedido/' + this.tokenStorage.getUserInfo().idUsuario,
			$body,
			{
				headers: AppConfig.getHTTPHeader().headers.set('componentId', componentId)
			}
		);
	}

	acertarMultiplosPedido(body: Pedido | any, componentId: string = ''): Observable<ResultObject<any>> {
		let $body = Array.isArray(body) ? [...body] : { ...body, novoAcerto: true };
		this.clearPedidoObject($body, true);
		return this.http.put<ResultObject<any>>(
			AppConfig.getAPIEndpoint() + '/v2/pedido/acertarMultiplosPedidos/' + this.tokenStorage.getUserInfo().idUsuario,
			$body,
			{
				headers: AppConfig.getHTTPHeader().headers.set('componentId', componentId)
			}
		);
	}

	delete(idPedido: number): Observable<ResultObject<any>> {
		// TODO
		return null;
	}

	getPedidosPorEstabelecimento(
		dateFilter: {
			dataInicio: Date,
			dataFim: Date
		} = { dataInicio: null, dataFim: null },
		componentId: string = ''
	): Observable<{ empresas: Empresa[], lojas: Loja[] }> {
		return this.http.get<{ empresas: Empresa[], lojas: Loja[] }>(
			AppConfig.getAPIEndpoint() + this.ROUTE + '/list/estabelecimento/' + this.tokenStorage.getUserInfo().idUsuario,
			{
				headers: AppConfig.getHTTPHeader().headers.set('componentId', componentId),
				params: {
					...this.getEstabelecimentoQueryParam(),
					...this.getDateFilterQueryParam(dateFilter.dataInicio, dateFilter.dataFim),
				}
			}
		);
	}

	reverterAcertoFinanceiro(idPedido: number, componentId: string = ''): Observable<ResultObject<any>> {
		return this.http.delete<ResultObject<any>>(
			AppConfig.getAPIEndpoint() + this.ROUTE + '/reverter-acerto/' + idPedido + '/' + this.tokenStorage.getUserInfo().idUsuario,
			{
				headers: AppConfig.getHTTPHeader().headers.set('componentId', componentId)
			}
		);
	}

	reverterMultiplosAcertosFinanceiros(ids: string, componentId: string = ''): Observable<ResultObject<any>> {
		return this.http.delete<ResultObject<any>>(
			AppConfig.getAPIEndpoint() + this.ROUTE + '/reverter-multiplos-acertos/' + ids + '/' + this.tokenStorage.getUserInfo().idUsuario,
			{
				headers: AppConfig.getHTTPHeader().headers.set('componentId', componentId)
			}
		);
	}

	getEntriesCount(componentId: string = '', estabelecimento?: Estabelecimento): Observable<{ entries: number }> {
		return this.http.get<{ entries: number }>(
			AppConfig.getAPIEndpoint() + this.ROUTE + '/list/' + this.tokenStorage.getUserInfo().idUsuario,
			{
				headers: AppConfig.getHTTPHeader().headers.set('componentId', componentId),
				params: {
					...this.getEstabelecimentoQueryParam(estabelecimento),
					entriesOnly: true
				}
			}
		);
	}

	/**
	 * Limpa os objetos desnecessários antes de enviar o request.
	 *
	 * @param {Pedido} pedido - Objeto do pedido.
	 * @param {boolean} isUpdate - Se o clear é para um update.
	 */
	clearPedidoObject(pedido: Pedido, isUpdate: boolean = false) {

		delete pedido.historicoAcaos;
		delete pedido.rotaDeEntrega;

		if (pedido.estabelecimento != null) {
			if (pedido.estabelecimento.empresa != null) {
				if (pedido.estabelecimento.empresa.idEmpresa != null || pedido.estabelecimento.empresa._id != null) {
					pedido.estabelecimento.empresa = {
						_id: pedido.estabelecimento.empresa._id,
						_uniqueId: pedido.estabelecimento.empresa._uniqueId,
						idEmpresa: pedido.estabelecimento.empresa.idEmpresa
					} as any;
				}
			}
			if (pedido.estabelecimento.loja != null) {
				if (pedido.estabelecimento.loja.idLoja != null || pedido.estabelecimento.loja._id != null) {
					pedido.estabelecimento.loja = {
						_id: pedido.estabelecimento.loja._id,
						_uniqueId: pedido.estabelecimento.loja._uniqueId,
						idLoja: pedido.estabelecimento.loja.idLoja
					} as any;
				}
			}
		}

		if (isUpdate) {
			// delete pedido.cliente;
			if (pedido.cliente) {
				delete pedido.cliente.enderecos;
				delete (<any>pedido.cliente).vendas;
				delete pedido.cliente.pedidos;
				delete pedido.cliente.contas;
				delete pedido.cliente.historicoAcaos;
				delete pedido.cliente.produto;
				delete pedido.cliente.tipoPagamentoBloqueado;

				if (pedido.cliente.saveValorNegociado != true) {
					delete pedido.cliente.valorNegociados;
				}
			}

			delete pedido.valoresNegociados;

			delete pedido.empresa;
			delete (pedido as any).empresas;
			delete pedido.loja;
			delete (pedido as any).lojas;
			delete pedido.atendidoPor;
			delete pedido.historico;
			delete pedido.produtos;

			delete pedido.entregador;
			delete pedido.contas;
			delete pedido.notificacaos;

			delete pedido.createdAt;
			delete pedido.deletedAt;
			delete pedido.entregador;
			delete pedido.estoquesToUpdate;
			delete pedido.grupoIdGrupo;
			delete pedido.isEditMode;
			delete pedido.taxaTransacaoCartao;
			delete pedido.updatedAt;
			delete pedido.venda;

			delete pedido._showQuickInfoPopUp;
		} else {

			if (pedido.cliente) {
				delete pedido.cliente.enderecos;
				delete pedido.cliente.pedidos;
				delete pedido.cliente.contas;
				delete pedido.cliente.historicoAcaos;
				delete pedido.cliente.produto;

				if (pedido.cliente.saveValorNegociado != true) {
					delete pedido.cliente.valorNegociados;
				}
			}
		}
	}

	obterConfiguracaoPedido(idEst: string, componentId = ''): Observable<ConfiguracaoPedido> {
		return this.http.get<ConfiguracaoPedido>(
			AppConfig.getAPIEndpoint() + this.ROUTE + '/configuracaoPedido/' + idEst + '/' + this.tokenStorage.getUserInfo().idUsuario,
			{
				headers: AppConfig.getHTTPHeader().headers.set('componentId', componentId)
			}
		);
	}

	cadastrarConfiguracaoPedido(body: ConfiguracaoPedido, componentId: string = ''): Observable<any> {
		return this.http.put<any>(
			AppConfig.getAPIEndpoint() + this.ROUTE + '/configuracaoPedido/' + body.est + '/' + this.tokenStorage.getUserInfo().idUsuario,
			body,
			{
				headers: AppConfig.getHTTPHeader().headers.set('componentId', componentId)
			}
		)
	}

}
