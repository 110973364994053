import {EstabelecimentoFunctions} from '../../models/interface/EstabelecimentoFunctions';
import {ServicePattern} from '../../models/interface/ServicePattern';
import {MovimentacaoFinanceira} from '../../models/api/MovimentacaoFinanceira';
import {HttpClient} from '@angular/common/http';
import {TokenStorage} from '../../auth/token-storage.service';
import {Observable} from 'rxjs';
import {AppConfig} from '../../../config/appConfig';
import {DateFilter} from '../../models/interface/DateFilter';
import {ResultObject} from '../../models/api/ResultObject';
import {Injectable} from '@angular/core';
import {Estabelecimento} from '../../models/template/Estabelecimento';
import {FonteBancaria} from '../../models/api/FonteBancaria';
import { Empresa } from '../../models/api/Empresa';
import { Loja } from '../../models/api/Loja';

@Injectable()
export class MovimentacaoFinanceiraService extends EstabelecimentoFunctions implements ServicePattern<MovimentacaoFinanceira> {

	private readonly ROUTE = '/financeiro/movimentacao';

	constructor(private http: HttpClient, private tokenStorage: TokenStorage) {
		super();
	}

	getAll(
		dateFilter: DateFilter = { campo: null, dataInicio: null, dataFim: null },
		page: number = null,
		perPage: number = null,
		est: Estabelecimento = new Estabelecimento(null, null, null),
		fonteBancaria: FonteBancaria = null,
		componentId: string = ''
	): Observable<MovimentacaoFinanceira[]> {
		let idLoja = est.loja != null ? est.loja.idLoja || est.loja._id : null;
		let idEmpresa = est.empresa != null ? est.empresa.idEmpresa || est.empresa._id : null;
		let idFonteBancaria = fonteBancaria != null ? fonteBancaria._id : null;
		return this.http.get<MovimentacaoFinanceira[]>(
			`${AppConfig.getAPIEndpoint()}${this.ROUTE}/list/${this.tokenStorage.getUserInfo().idUsuario}`,
			{
				headers: AppConfig.getHTTPHeader().headers.set('componentId', componentId),
				params: {
					...this.getDateFilterWithFieldQueryParam(dateFilter.campo, dateFilter.dataInicio, dateFilter.dataFim),
					...(page || page == 0 ? { page: page + 1 } : {}),
					...(perPage ? { per_page: perPage } : {}),
					...(idLoja ? { loja: idLoja } : {}),
					...(idEmpresa ? { empresa: idEmpresa } : {}),
					...(idFonteBancaria ? { fonteBancaria: idFonteBancaria } : {})
				}
			}
		);
	}

	movimentacoesFormaPagamento(
		dataInicio: Date = null,
		dataFim: Date = null,
		empresas: Empresa[] = null,
		lojas: Loja[] = null,
		componentId: string = ''
	) {
		return this.http.get<any[]>(
			`${AppConfig.getAPIEndpoint()}${this.ROUTE}/formaPagamento/${this.tokenStorage.getUserInfo().idUsuario}`,
			{
				headers: AppConfig.getHTTPHeader().headers.set('componentId', componentId),
				params: {
					...(dataInicio ? { dataInicio: dataInicio.getTime().toString() } : {}),
					...(dataFim ? { dataFim: dataFim.getTime().toString() } : {}),
					...(empresas ? { empresas: empresas.map(e => e._id).join(',') } : {}),
					...(lojas ? { lojas: lojas.map(l => l._id).join(',') } : {}),
				}
			}
		)
	}

	save(body: MovimentacaoFinanceira, componentId: string = ''): Observable<MovimentacaoFinanceira> {
		return this.http.post<MovimentacaoFinanceira>(
			`${AppConfig.getAPIEndpoint()}${this.ROUTE}/${this.tokenStorage.getUserInfo().idUsuario}`,
			body,
			{ headers: AppConfig.getHTTPHeader().headers.set('componentId', componentId) }
		);
	}

	delete(_id: number, componentId: string = ''): Observable<ResultObject<any>> {
		return this.http.delete<ResultObject<any>>(
			`${AppConfig.getAPIEndpoint()}${this.ROUTE}/${this.tokenStorage.getUserInfo().idUsuario}/${_id}`,
			{ headers: AppConfig.getHTTPHeader().headers.set('componentId', componentId) }
		);
	}

	update(body: MovimentacaoFinanceira, componentId: string = ''): Observable<ResultObject<any>> {
		return this.http.put<ResultObject<any>>(
			`${AppConfig.getAPIEndpoint()}${this.ROUTE}/${this.tokenStorage.getUserInfo().idUsuario}`,
			body,
			{ headers: AppConfig.getHTTPHeader().headers.set('componentId', componentId) }
		);
	}

}
