import { Observable, Subject } from 'rxjs';
import { map, filter } from 'rxjs/operators';

export class Broadcaster {
	private _event: Subject<BroadcastEvent>;

	constructor() {
		this._event = new Subject<BroadcastEvent>();
	}

	broadcast$(key: any, data?: any) {
		this._event.next({ key, data });
	}

	on$<T>(key: any): Observable<T> {
		return this._event.asObservable().pipe(
			filter(event => event.key === key),
			map(event => <T>event.data)
		);
	}

	event$<T>(key: any): Observable<BroadcastEvent> {
		return this._event.asObservable().pipe(
			filter(event => event.key === key),
			map(event => event)
		);
	}
}

export interface BroadcastEvent {
	key: any;
	data?: any;
}

export enum BroadcasterEvents {
	/* SCRIPT EVENTS */
	SCRIPT_LOG = 'script:log',
	SCRIPT_SUCCESS = 'script:success',
	SCRIPT_ERROR = 'script:error',
	SCRIPT_INTERROMPIDO = 'script:interrompido',
	/* FIM SCRIPT EVENTS */
	
	/* TEMPLATE EVENTS */
	PHONE_CALLS_TOGGLE = 'template:phone_calls_toggle',
	SHOW_PHONE_CALLS_BUTTON = 'template:show_phone_calls_button',
	SELECT_EST = 'template:select_est',
	TOGGLE_EST_LOADING = 'template:toggle_est_loading',
	OPEN_FILTER = 'template:open_filter',
	SHOW_ESTABELECIMENTOS_SELECTED = 'template:show_estabelecimentos_selected',
	SHOW_FILTER_BUTTON = 'template:show_filter_button',
	SHOW_EXPORT_BUTTONS = 'template:show_export_buttons',
	SHOW_EXPORT_BUTTONS_NO_DOWNLOAD = 'template:show_export_buttons_no_download',
	EXPORT_PDF = 'template:export_pdf',
	EXPORT_EXCEL = 'template:export_excel',
	EXPORT_VCARD = 'template:export_vcard',
	SHOW_VCARD_BUTTON = 'template:show_vcard_button',
	IMPRIMIR_TABELA = 'template:imprimir_tabela',
	ADMIN_MENU_SELECTED = 'template:admin_menu_selected',
	SHOW_ADMIN_MENU = 'template:show_admin_menu',
	LEFT_MENU_TOGGLE = 'template:left_menu_toggle',
	OPERACAO_FISCAL_CHANGE = 'template:operacao_fiscal_change',
	OPERACAO_FISCAL_CHANGE_NEW_VALUE = 'template:operacao_fiscal_new_value',
	SHOW_DEFAULT_DATE = 'template:show_default_date',
	DEFAULT_DATE_UPDATED = 'template:default_date_updated',
	/* FIM TEMPLATE EVENTS */

	/* CONNECTION EVENTS */
	CONNECTION_CLOSED = 'connection_closed',
	RECONNECTION_SUCCESS = 'reconnection_success',
	/* FIM CONNECTION EVENTS */

	/* LOG EVENTS */
	NOVO_LOG = 'novo_log',
	/* FIM LOG EVENTS */

	/* CALLCENTER EVENTS */
	ON_LOGIN = 'on_login',
	ON_LOGOUT = 'on_logout',
	VERSION_CHECKER = 'version_checker',
	NOVO_PEDIDO = 'novo_pedido',
	PEDIDO_ATUALIZADO = 'pedido_atualizado',
	EMPRESA_CADASTRADA = 'empresa_cadastrada',
	LOJA_CADASTRADA = 'loja_cadastrada',
	ATUALIZAR_EMPRESAS = 'atualizar_empresas',
	ATUALIZAR_LOJAS = 'atualizar_lojas',
	NOVA_NOTIFICACAO = 'nova_notificacao',
	ADICIONAR_NOTIFICACAO_PERSISTENTE = 'adicionar_notificacao_persistente',
	REMOVER_NOTIFICACAO_PERSISTENTE = 'remover_notificacao_persistente',
	REMOVER_ESTABELECIMENTO_SELECTION = 'remover_estabelecimento_selection',
	CHAMADA_BINA = 'chamada_bina',
	CHAMADA_BINA_ATENDIDA = 'chamada_bina_atendida',
	CHAMADA_BINA_INICIADA = 'chamada_bina_iniciada',
	DESLOGAR = 'deslogar',
	REINICIAR = 'reiniciar',
	FRIENDS_LIST = 'friends_list',
	/* FIM CALLCENTER EVENTS */

	/** */
	ESTABELECIMENTO_SELECIONADO = 'estabelecimento_selecionado',
	CHANGE_USER_PROFILE = 'change_user_profile',
	POPUP_SEFAZ = 'popup_sefaz',
	EMITINDO_NF = 'emitindo_nf',
	BLOQUEIO_ESTOQUE = 'bloqueio_estoque',
	ATUALIZAR_BLOQUEIOS = 'atualizar_bloqueios',
	BINA_FECHAR_POPUP = 'bina_fechar_popup',
	RESET_PEDIDO = 'reset_pedido',
	LOADING = 'sisloading',
	VOIP_INICIADO = 'voip_iniciado',
	ABRIR_BUSCAR_CLIENTE = 'abrir_buscar_cliente',
	ESTOQUE_COUNT = 'estoque-count',
	ESTOQUE_ERROR = 'estoque-error'
}
