import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { TokenStorage } from '../../auth/token-storage.service';
import { AppConfig } from '../../../config/appConfig';

@Injectable()
export class ContratosService {
	readonly ROUTE = '/contratos'

	constructor(private http: HttpClient, private tokenStorage: TokenStorage) { }

	cadastrarContrato(nome, arquivo, componentId: string = ''): Observable<any> {
		const formData = new FormData()
		formData.append('nome', nome)
		formData.append('arquivo', arquivo)
		return this.http.post<any>(
			AppConfig.getAPIEndpoint() + this.ROUTE + '/cadastrar/' + this.tokenStorage.getUserInfo().idUsuario,
			formData,
			{
				headers: new HttpHeaders({ componentId })
			}
		)
	}

	listarContratos(componentId: string = ''): Observable<any[]> {
		return this.http.get<any[]>(
			AppConfig.getAPIEndpoint() + this.ROUTE + '/listar/' + this.tokenStorage.getUserInfo().idUsuario,
			{
				headers: AppConfig.getHTTPHeader().headers.set('componentId', componentId)
			}
		)
	}

	listarSignatarios(uuid, componentId: string = ''): Observable<any[]> {
		return this.http.get<any[]>(
			AppConfig.getAPIEndpoint() + this.ROUTE + '/listar_signatarios/' + uuid + '/' + this.tokenStorage.getUserInfo().idUsuario,
			{
				headers: AppConfig.getHTTPHeader().headers.set('componentId', componentId)
			}
		)
	}

	cancelarContratos(uuid, componentId: string = ''): Observable<any>{
		return this.http.delete<any>(
			AppConfig.getAPIEndpoint() + this.ROUTE + '/cancelar/' + uuid + '/' + this.tokenStorage.getUserInfo().idUsuario,
			{
				headers: new HttpHeaders({ componentId })
			}
		)
	}

	baixarContrato(uuid, componentId: string = ''): Observable<any>{
		return this.http.get<any[]>(
			AppConfig.getAPIEndpoint() + this.ROUTE + '/baixar/' + uuid + '/' + this.tokenStorage.getUserInfo().idUsuario,
			{
				headers: AppConfig.getHTTPHeader().headers.set('componentId', componentId)
			}
		)
	}

	enviarContrato(uuid, message, componentId: string = ''): Observable<any>{
		return this.http.post<any>(
			AppConfig.getAPIEndpoint() + this.ROUTE + '/enviar_documento/' + uuid + '/' + this.tokenStorage.getUserInfo().idUsuario,
			{
				message
			},
			{
				headers: AppConfig.getHTTPHeader().headers.set('componentId', componentId)
			}
		)
	}

	removerSignatario(uuid, email, key, componentId: string = '') {
		return this.http.post<any>(
			AppConfig.getAPIEndpoint() + this.ROUTE + '/remover_signatario/' + uuid + '/' + this.tokenStorage.getUserInfo().idUsuario,
			{
				email,
				key
			},
			{
				headers: AppConfig.getHTTPHeader().headers.set('componentId', componentId)
			}
		)
	}

	alterarSignatario(uuid, emailBefore, emailAfter, key, componentId: string = '') {
		return this.http.post<any>(
			AppConfig.getAPIEndpoint() + this.ROUTE + '/alterar_signatario/' + uuid + '/' + this.tokenStorage.getUserInfo().idUsuario,
			{
				emailBefore,
				emailAfter,
				key
			},
			{
				headers: AppConfig.getHTTPHeader().headers.set('componentId', componentId)
			}
		)
	}

	cadastrarSignatario(uuid, email, componentId: string = '') {
		return this.http.post<any>(
			AppConfig.getAPIEndpoint() + this.ROUTE + '/add_signatario/' + uuid + '/' + this.tokenStorage.getUserInfo().idUsuario,
			{
				email
			},
			{
				headers: AppConfig.getHTTPHeader().headers.set('componentId', componentId)
			}
		)
	}

	statusAssinatura(componentId: string = '') {
		return this.http.get<any>(
			AppConfig.getAPIEndpoint() + this.ROUTE + '/status_assinatura/' + this.tokenStorage.getUserInfo().idUsuario,
			{
				headers: AppConfig.getHTTPHeader().headers.set('componentId', componentId)
			}
		)
	}

}