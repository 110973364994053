export enum TipoMovimentacaoEstoque {
	NOTA_FISCAL_ENTRADA = <any>1,
	COMODATO_ENTRADA = <any>2,
	ENTRADA_SEM_NOTA = <any>3,
	CONTROLE_AVARIAS = <any>4, //SAIDA
	SAIDAS_SEM_NOTA = <any>5,
	COMODATO_SAIDA = <any>6,
	NOTA_FISCAL_SAIDA = <any>7,
	TRANSFERENCIA = <any>8,
	ATUALIZACAO = <any>9,
	DEVOLUCAO = <any>10,
	EMPRESTIMO_ENTRADA = <any>11,
	EMPRESTIMO_SAIDA = <any>12,
	CONTROLE_AVARIAS_ENTRADA = <any>13,
	DOACAO = <any>14
}

export enum TipoMovimentacao {
	ENTRADA = <any>1,
	SAIDAS = <any>2
}
