import { AfterViewInit, ChangeDetectorRef, Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { CallcenterFunctions } from '../../../../../core/models/interface/CallcenterFunctions';
import { MatPaginator, MatSnackBar, MatSort, MatTableDataSource } from '@angular/material';
import { FormaPagamento, Pagamento, Pedido } from '../../../../../core/models/api/Pedido';
import { PedidoStatus } from '../../../../../core/models/enum/PedidoStatus';
import { LayoutConfigService } from '../../../../../core/services/layout-config.service';
import { isLastPos, showSnackBar, UtilsService } from '../../../../../core/services/utils.service';
import { PedidoFilter, PedidosService } from '../../../../../core/services/api/pedidos.service';
import { Broadcaster, BroadcasterEvents } from '../../../../../core/models/broadcaster';
import * as objectPath from 'object-path';
import { TipoPagamento } from '../../../../../core/models/enum/TipoPagamento';
import { Cliente } from '../../../../../core/models/api/Cliente';
import { Entregador } from '../../../../../core/models/api/Entregador';
import { fadeIn, fadeInOut } from '../../../../animations/fadeInOut';
import { Snackbar } from '../../../../../core/models/enum/Snackbar';
import { EntregadoresService } from '../../../../../core/services/api/entregadores.service';
import { merge, of as observableOf } from 'rxjs';
import { catchError, map, startWith, switchMap } from 'rxjs/operators';
import { DateFilter } from '../../../../../core/models/interface/DateFilter';
import { DEFAULT_DATE_OPTIONS } from '../../../../partials/content/general/filtro/filtro.component';
import { ClientesService } from '../../../../../core/services/api/clientes.service';
import { TagNotificacao } from '../../../../../core/models/enum/TagNotificacao';
import { PedidoOrigem } from '../../../../../core/models/enum/PedidoOrigem';
import { FormControl } from '@angular/forms';
import { TipoCliente } from '../../../../../core/models/enum/TipoCliente';
import { BoletoPJBank } from '../../../../../core/models/api/boleto/BoletoPJBank';
import { SMTPConfigService } from '../../../../../core/services/api/smtp-config.service';
import { SMTPConfig } from '../../../../../core/models/api/SMTPConfig';
import { IndicacaoCompra } from '../../../../../core/models/enum/IndicacaoCompra';
import moment from 'moment';
import { nanoid } from 'nanoid';

declare var document: any;
declare const _prompt;
declare const _dialog;
declare const _alert;

export function checkPedidoNotifications(pedidos: Pedido[], broadcaster: Broadcaster) {
	if (pedidos) {
		pedidos.forEach((p: Pedido) => {
			if (p && p.idPedido && p.status) {
				if (p.status == PedidoStatus.PEDIDO_NOVO ||
					p.status == PedidoStatus.PEDIDO_PENDENTE ||
					p.status == PedidoStatus.PEDIDO_DEVOLVIDO) {

					let text;
					let tag;
					let onClickUrl;
					let buttonText;

					switch (p.status) {
						case PedidoStatus.PEDIDO_NOVO: {
							text = `Novo pedido de n°${p.numero}.`;
							tag = TagNotificacao.ALERT;
							onClickUrl = '/pedidos/repassar';
							buttonText = 'Ir para repassar pedidos';
							break;
						}
						case PedidoStatus.PEDIDO_PENDENTE: {
							text = `Pedido Pendente de n°${p.numero}.`;
							tag = TagNotificacao.PENDENTE;
							onClickUrl = '/pedidos/repassar';
							buttonText = 'Ir para repassar pedidos';
							break;
						}
						case PedidoStatus.PEDIDO_DEVOLVIDO: {
							text = `Pedido n°${p.numero} foi devolvido.`;
							tag = TagNotificacao.PENDENTE;
							onClickUrl = '/pedidos/todos';
							buttonText = 'Ir para todos os pedidos';
							break;
						}
					}

					broadcaster.broadcast$(BroadcasterEvents.ADICIONAR_NOTIFICACAO_PERSISTENTE, <any>{
						idNotificacao: p.idPedido,
						text: text,
						tags: [tag],
						timestamp: new Date(p.createdAt),
						onClickUrl: onClickUrl,
						buttonText: buttonText,
						origem_app: p.origem_app
					});
				} else {
					broadcaster.broadcast$(BroadcasterEvents.REMOVER_NOTIFICACAO_PERSISTENTE, p.idPedido);
				}
			}
		});
	}
}

enum ListarPedidosFiltros {
	CLIENTE = <any>'listar-todos-pedidos:cliente',
	ENTREGADOR = <any>'listar-todos-pedidos:entregador',
	STATUS = <any>'listar-todos-pedidos:status',
	DATA_REPASSE = <any>'listar-todos-pedidos:dataRepasse',
	DATA_ENTREGA = <any>'listar-todos-pedidos:dataEntrega',
	CREATED_AT = <any>'listar-todos-pedidos:createdAt',
	ORIGEM_PEDIDO = <any>'listar-todos-pedidos:origem_pedido',
	CANAL_VENDAS = <any>'listar-todos-pedidos:canal_vendas',
}

@Component({
	selector: 'm-pedidos',
	templateUrl: './pedidos.component.html',
	styleUrls: ['./pedidos.component.scss'],
	animations: [fadeIn, fadeInOut]
})

export class PedidosComponent extends CallcenterFunctions implements OnInit, AfterViewInit, OnDestroy {

	/**
	 * Configs
	 */
	public static readonly ROUTE_ID = 'callcenter:pedidos';
	public static readonly VISIBLE_ONLY_TO_MANAGER: boolean = false;

	/* Secondary IDS */
	public static readonly SECUNDARY_ROUTE_IDS = {
		REPASSAR: 'callcenter:pedidos:repassar',
		REPASSADOS: 'callcenter:pedidos:repassados',
		AGENDADOS: 'callcenter:pedidos:agendados',
		ENTREGUES: 'callcenter:pedidos:entregues',
		PENDENTES: 'callcenter:pedidos:pendentes',
		CANCELADOS: 'callcenter:pedidos:cancelados',
	};

	private static _context = null;
	/** */

	_websocketDisconnected: boolean = false;
	_lastInfoUpdate: Date = new Date(Date.now());
	_lastOrderRepassed: Date = new Date(Date.now());

	_resultsLength = 0;

	displayedColumns: string[] = [
		'selecao',
		'numero',
		'actionsListPedido',
		'data',
		'cliente',
		'telefoneCliente',
		'enderecoCliente',
		'produtos',
		'entregador',
		'valorTotal',
		'observacaoCliente',
		'observacaoPedido',
		'atendidoPor',
		'createdAt',
		'dataRepasse',
		'dataEntrega',
		'indicacaoCompra',
		'credito',
		'parcelamento',
	];

	filteredClientes: Cliente[];
	clienteCtrl = new FormControl();
	clienteSearchInput;

	pedidos: Pedido[];

	dataSource: MatTableDataSource<Pedido | any>;

	showClientePopup: boolean;
	showClienteBoletoPopup: boolean;
	_pedidoSelecionado: any;

	@ViewChild(MatPaginator) paginator: MatPaginator;
	@ViewChild(MatSort) sort: MatSort;

	LOAD_PEDIDOS_EVENT = 'load_pedidos_event';

	/* enum */
	pedidoStatus = PedidoStatus;
	tipoPagamento = TipoPagamento;
	tipoCliente = TipoCliente;
	formaPagamento = FormaPagamento;

	/** Broadcaster observables cache */
	private obs = [];

	pedidoSelecionado: Pedido | Pedido[];
	pedidosParaRepassar: Pedido[] = [];
	pedidosParaEntregar: Pedido[] = [];
	pedidosParaEmitirCupom: Pedido[] = [];
	repassarMultiplos: boolean = false;
	entregarMultiplos: boolean = false;
	emitirMultiplosCupons: boolean = false;

	document = document;

	/** Template vars */
	filterStatus: PedidoStatus[];
	filterCanalVendas: IndicacaoCompra[];
	filterOrigem: PedidoOrigem[];
	filterCliente;
	filterEntregador;

	filterShown: boolean = false;
	dateFilters: DateFilter[];

	filtros: any[] = [];

	setupFiltrosTimeout;

	count_entregues: number;
	count_agendados: number;
	count_novo: number;
	count_repassado: number;
	count_em_transito: number;
	count_pendente: number;
	count_devolvido: number;
	count_cancelado: number;

	cancelarNota: boolean = false;
	cancelarNotaJust: string;
	cancelarNotaFuso: string = "-03:00";

	popUpPedidoOpen: boolean = false;

	aditionalDateFilters = [
		/* { title: 'Data\\Hora Repasse', id: ListarPedidosFiltros.DATA_REPASSE, ...DEFAULT_DATE_OPTIONS }, */
		/* { title: 'Data\\Hora Entrega', id: ListarPedidosFiltros.DATA_ENTREGA, ...DEFAULT_DATE_OPTIONS }, */
		{ title: 'Data\\Hora do Pedido', canSelectHour: true, id: ListarPedidosFiltros.CREATED_AT, ...DEFAULT_DATE_OPTIONS }
	];

	searchString: string;
	canShowLoading: boolean = true;

	smtpConfigs: SMTPConfig[] = [];

	lock: boolean = false;

	constructor(
		private layoutConfigService: LayoutConfigService,
		public utils: UtilsService,
		private cdRef: ChangeDetectorRef,
		private pedidosService: PedidosService,
		private _router: Router,
		public snackBar: MatSnackBar,
		private clientesService: ClientesService,
		private entregadoresService: EntregadoresService,
		private _broadcaster: Broadcaster,
		public route: ActivatedRoute,
		private smtpConfigService: SMTPConfigService
	) {

		super(_router);

		// força o reload da rota quando o parametro é alterado
		this._router.routeReuseStrategy.shouldReuseRoute = () => false;

		PedidosComponent._context = this;

		/**
		 * Observables
		 */
		this.obs.push(

			/**
			 * Se inscreve nos eventos de conexão e reconexão do websocket
			 */
			this._broadcaster.on$(BroadcasterEvents.CONNECTION_CLOSED).subscribe((data: any) => {
				this._websocketDisconnected = true;
				this.detectChanges();
			}),

			this._broadcaster.on$(BroadcasterEvents.RECONNECTION_SUCCESS).subscribe((data: any) => {
				this._lastInfoUpdate.setTime(Date.now());
				this._websocketDisconnected = false;
				this.detectChanges();
			}),

			/** Se inscreve nos eventos de pedidos */
			merge(
				this._broadcaster.on$(BroadcasterEvents.NOVO_PEDIDO),
				this._broadcaster.on$(BroadcasterEvents.PEDIDO_ATUALIZADO)
			).subscribe((data: Pedido) => this.sincronizarPedido(data)),

			/** Se inscreve no evento de mudança de estabelecimento */
			this._broadcaster.on$(BroadcasterEvents.ESTABELECIMENTO_SELECIONADO).subscribe((data) => this.setupFiltros()),

			/** Se inscreve no evento de mudança de data default */
			this._broadcaster.on$(BroadcasterEvents.DEFAULT_DATE_UPDATED).subscribe((data) => {
				this.setDefaultDate();
				this._broadcaster.broadcast$(this.LOAD_PEDIDOS_EVENT, {});
			}),

			/** Se inscreve no evento de impressão/exportação de PDF */
			merge(
				this._broadcaster.event$(BroadcasterEvents.IMPRIMIR_TABELA),
				this._broadcaster.event$(BroadcasterEvents.EXPORT_PDF)
			).subscribe(async (data) => {
				this.exportAsPDFFile(
					`Pedidos ${this.getPageName()}`,
					`pedidos_${this.getPageName()}${this.utils.formatDate(this.today)}`,
					await this.formatToExport(),
					this.dateFilters.length > 0 ? this.dateFilters[0].dataInicio : null,
					this.dateFilters.length > 0 ? this.dateFilters[0].dataFim : null,
					data.key === BroadcasterEvents.IMPRIMIR_TABELA,
					undefined,
					undefined,
					undefined,
					undefined,
					true,
					'horizontal'
				);
			}),

			/** Se inscreve no evento de exportão de tabela para EXCEL */
			this._broadcaster.on$(BroadcasterEvents.EXPORT_EXCEL).subscribe(async (data) => {
				this.exportAsExcelFile(
					`pedidos_${this.getPageName()}${this.utils.formatDate(this.today)}`,
					await this.formatToExport()
				);
			}),
		);
	}

	ngAfterViewInit() {
		this.setupRequestPagination();

		this.getClientes(null);
		this.clienteCtrl.valueChanges.subscribe(value => this.getClientes(value));
	}

	selecionarPedido(p: Pedido) {
		if (p.acertado) {
			return _alert('Esse pedido já foi acertado');
		}
		this.pedidoSelecionado = p;
	}

	toggleRepassar(value: boolean) {
		this.pedidosParaRepassar = [];
		this.repassarMultiplos = value;
	}

	// Faz com que os filtros sejam liberados para o usuário cancelar a nota fiscal
	toggleCancelarNota() {
		this.cancelarNota = !this.cancelarNota;
	}

	toggleEntregarMultiplos(value: boolean) {
		this.pedidosParaEntregar = [];
		this.entregarMultiplos = value;
	}

	toggleEmitirMultiplosCupons(value: boolean) {
		this.pedidosParaEmitirCupom = [];
		this.emitirMultiplosCupons = value;
	}

	async alterarStatusMultiplosPedidos() {

		const pedidoAcerdado = this.pedidosParaEntregar.find(p => p.acertado);
		if (pedidoAcerdado) {
			return _alert(`O pedido Nº ${pedidoAcerdado.numero} já foi acertado`)
		}

		const values = await _dialog('Confirme a data da entrega', null, [
			{
				name: 'data',
				component: 'inputComponent',
				label: 'Data da entrega',
				args: ['date'],
				default: moment().format('YYYY-MM-DD')
			}
		]);
		if (values) {
			this._load.show();
			this.pedidosService.alterarStatusMultiplos(this.pedidosParaEntregar.map(p => p._id), moment(values.data, 'YYYY-MM-DD').toDate()).subscribe(
				() => {
					showSnackBar('Pedidos alterados com sucesso', Snackbar.TYPE_SUCCESS, this.snackBar);
					this.toggleEntregarMultiplos(false);
					this.setupRequestPagination();
					this.lock = false;
				},
				(err) => {
					_alert('Não foi possível alterar o status de todos os pedidos');
					this._load.hide();
					this.lock = false;
				}
			)
		}
	}

	async emitirMultiplosCuponsAction() {
		try {
			await this.popupNFCEMultiplo(this.pedidosParaEmitirCupom, this.pedidosService, PedidosComponent.ROUTE_ID, this._broadcaster, this.snackBar);
			this.toggleEntregarMultiplos(false);
			this.setupRequestPagination();
		}
		catch (err) { }

	}

	prepararRepasseDePedidos() {
		if (this.pedidosParaRepassar.length == 0) {
			this._alert('Selecione pelo menos um pedido');
		}
		else {
			const pedAcertado = this.pedidosParaRepassar.find(ped => ped.acertado);
			if (pedAcertado) {
				return _alert(`O pedido Nº ${pedAcertado.numero} já foi acertado`);
			}
			this.pedidoSelecionado = this.pedidosParaRepassar;
			this.toggleRepassar(false);
		}
	}

	pedidoEstaSelecionadoParaRepasse(pedido: Pedido) {
		return this.pedidosParaRepassar.some(_pedido => _pedido._id == pedido._id);
	}

	pedidoEstaSelecionadoParaEntrega(pedido: Pedido) {
		return this.pedidosParaEntregar.some(_pedido => _pedido._id == pedido._id);
	}

	pedidoEstaSelecionadoParaEmissaoDeCupom(pedido: Pedido) {
		return this.pedidosParaEmitirCupom.some(_pedido => _pedido._id == pedido._id);
	}

	selecionarPedidoParaRepasse(pedido: Pedido) {
		if (!pedido) {
			return false;
		}
		if (!this.pedidoEstaSelecionadoParaRepasse(pedido)) {
			this.pedidosParaRepassar.push(pedido);
		} else {
			this.pedidosParaRepassar = this.pedidosParaRepassar.filter((_pedido: Pedido) => _pedido._id != pedido._id);
		}
	}

	selecionarPedidoParaEntrega(pedido: Pedido) {
		if (!pedido) {
			return false;
		}
		if (!this.pedidoEstaSelecionadoParaEntrega(pedido)) {
			this.pedidosParaEntregar.push(pedido);
		} else {
			this.pedidosParaEntregar = this.pedidosParaEntregar.filter((_pedido: Pedido) => _pedido._id != pedido._id);
		}
	}

	selecionarPedidoParaEmitirCupom(pedido: Pedido) {
		if (!pedido) {
			return false;
		}
		if (!this.pedidoEstaSelecionadoParaEmissaoDeCupom(pedido)) {
			this.pedidosParaEmitirCupom.push(pedido);
		} else {
			this.pedidosParaEmitirCupom = this.pedidosParaEmitirCupom.filter((_pedido: Pedido) => _pedido._id != pedido._id);
		}
	}

	ngOnInit() {

		// change page config, refer to config/layout.ts
		const newLayoutOption = objectPath.set(this.layoutConfigService.layoutConfig, 'config.aside.left.display', false);
		this.layoutConfigService.setModel(newLayoutOption, true);

		this._broadcaster.broadcast$(BroadcasterEvents.SHOW_DEFAULT_DATE, true);
		this._broadcaster.broadcast$(BroadcasterEvents.SHOW_EXPORT_BUTTONS, true);
		this._broadcaster.broadcast$(BroadcasterEvents.SHOW_ESTABELECIMENTOS_SELECTED, true);

		this.setDefaultParams();
		this.carregarSMTPConfigs();
	}

	ngOnDestroy() {

		/** Se desinscreve das chamadas do Broadcaster */
		for (const o of this.obs) o.unsubscribe();

		if (PedidosComponent._context) PedidosComponent._context = null;

		this._broadcaster.broadcast$(BroadcasterEvents.SHOW_DEFAULT_DATE, false);
		this._broadcaster.broadcast$(BroadcasterEvents.SHOW_EXPORT_BUTTONS, false);

	}

	carregarSMTPConfigs() {
		this._load.show();
		this.smtpConfigService.getAll(undefined, undefined, undefined, PedidosComponent.ROUTE_ID, undefined).subscribe(
			(res: SMTPConfig[]) => {
				this.smtpConfigs = res;
				this._load.hide();
			},
			() => {
				_alert('Erro ao carregar as configurações de SMTP');
				this._load.hide();
			}
		);
	}

	setDefaultParams() {

		let applyDefaultDateFilter = true;

		/** Seta o status a ser filtrado nos pedidos */
		switch (this.route.snapshot.paramMap.get('status')) {
			case 'pendentes':
				this.filterStatus = [PedidoStatus.PEDIDO_PENDENTE, PedidoStatus.PEDIDO_DEVOLVIDO];
				break;
			case 'entregues':
				this.filterStatus = [PedidoStatus.PEDIDO_ENTREGUE];
				break;
			case 'cancelados':
				this.filterStatus = [PedidoStatus.PEDIDO_CANCELADO];
				break;
			case 'repassar':
				// applyDefaultDateFilter = false;
				this.filterStatus = [PedidoStatus.PEDIDO_NOVO, PedidoStatus.PEDIDO_PENDENTE, PedidoStatus.PEDIDO_DEVOLVIDO];

				this.displayedColumns = this.displayedColumns.filter(
					(v) =>
						v != 'entregador' &&
						v != 'atendidoPor' &&
						v != 'dataRepasse' &&
						v != 'dataEntrega'
				);

				break;
			case 'repassados':
				applyDefaultDateFilter = false;
				this.filterStatus = [PedidoStatus.PEDIDO_REPASSADO, PedidoStatus.PEDIDO_EM_TRANSITO];
				break;
			case 'agendados':
				applyDefaultDateFilter = false;
				this.filterStatus = [PedidoStatus.PEDIDO_AGENDADO];
				break;
			default:
				this.filterStatus = [
					PedidoStatus.PEDIDO_CANCELADO,
					PedidoStatus.PEDIDO_DEVOLVIDO,
					PedidoStatus.PEDIDO_EM_TRANSITO,
					PedidoStatus.PEDIDO_ENTREGUE,
					PedidoStatus.PEDIDO_NOVO,
					PedidoStatus.PEDIDO_PENDENTE,
					PedidoStatus.PEDIDO_REPASSADO
				];
		}

		this.filterOrigem = [
			PedidoOrigem.TELEATENDIMENTO,
			PedidoOrigem.PORTARIA,
			PedidoOrigem.APLICATIVO,
			PedidoOrigem.QRCODEAPP
		];

		if (applyDefaultDateFilter) this.setDefaultDate();
	}

	setDefaultDate() {
		let df = this.getDefaultDateOnCache();
		// let todayDateStart = DATE_FILTER_TODAY_CORRECTION.dataInicio;
		// let todayDateEnd = DATE_FILTER_TODAY_CORRECTION.dataFim;

		this.dateFilters = [
			{
				dataInicio: df.dataInicio,
				dataFim: df.dataFim,
				campo: 'data'
			}
		];
	}

	setupRequestPagination() {

		// Se o usuário mudar a ordenação, reseta a pagina para a primeira página.
		this.sort.sortChange.subscribe(() => this.paginator.pageIndex = 0);

		// setTimeout(() => this.setDefaultParams(), 200);

		this.setupFiltros();

		this.obs.push(

			merge(
				this.sort.sortChange,
				this.paginator.page,
				this._broadcaster.on$(BroadcasterEvents.ESTABELECIMENTO_SELECIONADO),
				this._broadcaster.on$(this.LOAD_PEDIDOS_EVENT)
			).pipe(
				startWith({}),
				switchMap(() => {
					if (this.canShowLoading) this._load.show();
					this.canShowLoading = true;
					let pedidoFilter = new PedidoFilter({
						dateFilter: this.dateFilters && this.dateFilters.length > 0 ? this.dateFilters[0] : null,
						statusFilter: this.filterStatus,
						filterCanalVendas: this.filterCanalVendas,
						pedidoOrigem: this.filterOrigem,
						...(this.filterEntregador && this.filterEntregador.idEntregador ? { idEntregador: this.filterEntregador.idEntregador } : {})
					});
					const isSilvaGas = this.route.snapshot.queryParamMap.get('silvagas') == 'true';
					return this.pedidosService.getAll(
						PedidosComponent.ROUTE_ID,
						this.paginator.pageIndex,
						this.paginator.pageSize,
						pedidoFilter,
						pedidoFilter.dateFilter != null ? !pedidoFilter.dateFilter.notPopulateHour : undefined,
						isSilvaGas ? true : undefined,
						isSilvaGas ? { multiEmpresa: '5d1399737e07b763d5e843eb' } : undefined,
						undefined,
						this.searchString
					);
				}),
				map((data: any) => {
					this._resultsLength = data.total_count;

					this.count_entregues = data.count_entregues;
					this.count_agendados = data.count_agendados;
					this.count_novo = data.count_novo;
					this.count_repassado = data.count_repassado;
					this.count_em_transito = data.count_em_transito;
					this.count_pendente = data.count_pendente;
					this.count_devolvido = data.count_devolvido;
					this.count_cancelado = data.count_cancelado;

					return data.items;
				}),
				catchError(() => {
					this._load.hide();
					return observableOf([]);
				})
			).subscribe((pedidos: Pedido[]) => this.setPedidoDataSource(pedidos))
		);
	}

	setPedidoDataSource(pedidos: Pedido[]) {
		this.pedidos = pedidos;
		checkPedidoNotifications(pedidos, this._broadcaster);

		const result = this.groupObjectsByEstabelecimento<any>(pedidos);
		let resultPed = [];

		result.forEach(ped => {
			if (ped.isGroupBy) {
				resultPed.push(ped);
				resultPed.push({ isHeaderRow: true });
			} else {
				ped.tokenControle = nanoid(36);
				resultPed.push(ped)
			};
		});

		this.dataSource = new MatTableDataSource(
			this.contaProdutosQuantidade(resultPed)
		);
		this.setSearchFilter();

		this._load.hide();
		setTimeout(() => {
			this.detectChanges();
		}, 1000);
	}

	detectChanges() {
		try { this.cdRef.detectChanges(); } catch (err) { }
	}

	getPageName() {
		switch (this.route.snapshot.paramMap.get('status')) {
			case 'entregues': return 'entregues';
			case 'cancelados': return 'cancelados';
			case 'repassados': return 'repassado';
			default: return '';
		}
	}

	closeClientePopup() {
		this.showClientePopup = false;
		this.detectChanges();
	}

	closeClienteBoletoPopup() {
		this.showClienteBoletoPopup = false;
		this.detectChanges();
	}

	async getClientes(queryValue = null) {
		this.filteredClientes = (await this.clientesService.getAll(
			undefined,
			0,
			5,
			PedidosComponent.ROUTE_ID,
			undefined,
			undefined,
			queryValue
		).toPromise<any>()).items;
		this.detectChanges();
	}

	async emitirBoleto(pedido: any, cliente: Cliente = null) {
		this._pedidoSelecionado = pedido;
		if (!pedido.cliente && !(cliente || { idCliente: null }).idCliente) {
			this.showClienteBoletoPopup = true;
		}
		else {
			let cpf_cnpj = null
			let nome = null

			if (!(pedido.cliente || { cpf: null }).cpf && !(pedido.cliente || { cnpj: null }).cnpj && !(cliente || { cpf: null }).cpf && !(cliente || { cnpj: null }).cnpj) {
				cpf_cnpj = await _prompt('CPF ou CNPJ do cliente')
				if (!cpf_cnpj) {
					this.showClienteBoletoPopup = false
					return;
				}
			}
			if ((cliente || { nome: '' }).nome.length < 6 && (pedido.cliente || { nome: '' }).nome.length < 6) {
				nome = await _prompt('Insira o nome do cliente (deve conter no mínimo 6 caracteres)', (cliente || { nome: null }).nome || (pedido.cliente || { nome: null }).nome)
				if (!nome || nome.length < 6) {
					this.showClienteBoletoPopup = false
					return;
				}
			}

			this._load.show();
			this.pedidosService.emitirBoleto(pedido.idPedido, pedido.tokenControle, (cliente || { idCliente: null }).idCliente, cpf_cnpj, nome, PedidosComponent.ROUTE_ID).subscribe(
				(res: BoletoPJBank) => {
					// window.open(res.linkBoleto, '_blank');
					this.utils.downloadFile(res.linkBoleto, res.nomeBoleto);
					this._load.hide(() => this.detectChanges());
				},
				_err => {
					this._load.hide(() => this.detectChanges());
					if (_err.error != null && _err.error.showAsInfo == true) {
						this._alert(
							(_err.error || <any>{}).title || _err.error || _err,
							(_err.error || <any>{}).message || _err.error || ''
						);
					} else {
						showSnackBar((_err.error || <any>{}).title || _err.error || _err, Snackbar.TYPE_DANGER, this.snackBar, (_err.error || <any>{}).message || _err.error || '');
					}
				}
			);
			this.showClienteBoletoPopup = false;
		}
		this.detectChanges();
	}

	async emitirNota(pedido: Pedido) {
		const ok = await this.popupNFCE(
			pedido,
			this.pedidosService,
			this.smtpConfigService,
			this.utils,
			pedido.pagamentos.some(pag => pag.tipoPagamento == TipoPagamento.CARTAO_CREDITO || pag.tipoPagamento == TipoPagamento.CARTAO_DEBITO || pag.tipoPagamento == TipoPagamento.CARTAO_TODOS),
			this._broadcaster,
			this.snackBar,
			!!pedido.chaveNota && pedido['notafiscal'] && pedido['notafiscal']['status'] != 'CANCELADA',
			undefined,
			PedidosComponent.ROUTE_ID
		)
		if (ok) {
			pedido.chaveNota = nanoid(36);
			if (pedido['notafiscal']) {
				pedido['notafiscal'].status = 'AUTORIZADA';
			}
			this.detectChanges();
		}
	}

	setSearchFilter() {
		this.dataSource.filterPredicate = (data: Pedido, filter: string) => {
			let concatedData = '' +
				data.numero +
				this.getStatusName(data.status) +
				data.dataRepasse +
				data.dataEntrega +
				data.createdAt +
				(data.atendidoPor ? data.atendidoPor.nome : '') +
				this.getProdutoEEstoqueComQuantidade(data.produtos, data.estoquesToUpdate, data.produtoQuantidade, false) +
				this.getEnderecoDefaultFormated((data.cliente || {} as any).enderecos || [], false) +
				((data.cliente || {} as any).nome || '') +
				((data.entregador || {} as any).nome || '') +
				this.getTipoPagamentoName(data.tipoPagamento) +
				'R$ ' + (data.valorTotal || 0).toFixed(2);

			concatedData = concatedData.trim().toLowerCase();
			return concatedData.indexOf(filter) !== -1;
		};
	}

	onFilterToggle(event: { shown: boolean }) {
		this.filterShown = event.shown;
		this.detectChanges();
	}

	onFiltroSelected(event: any) {
		const filtro = {
			cliente: (event[ListarPedidosFiltros.CLIENTE] || {}).idEntregador ? event[ListarPedidosFiltros.CLIENTE] : null,
			entregador: (event[ListarPedidosFiltros.ENTREGADOR] || {}).idEntregador ? event[ListarPedidosFiltros.ENTREGADOR] : null,
			status: event[ListarPedidosFiltros.STATUS] || [],
			dataRepasse: event[ListarPedidosFiltros.DATA_REPASSE],
			dataEntrega: event[ListarPedidosFiltros.DATA_ENTREGA],
			createdAt: event[ListarPedidosFiltros.CREATED_AT],
			pedidoOrigem: event[ListarPedidosFiltros.ORIGEM_PEDIDO],
			canalVendas: event[ListarPedidosFiltros.CANAL_VENDAS],
		};

		this.dateFilters = [];

		/*
		if (!!filtro.dataRepasse) {
			this.dateFilters.push({
				dataInicio: filtro.dataRepasse.dateFilterStart,
				dataFim: filtro.dataRepasse.dateFilterEnd,
				field: 'dataRepasse'
			});
		}
		if (!!filtro.dataEntrega) {
			this.dateFilters.push({
				dataInicio: filtro.dataEntrega.dateFilterStart,
				dataFim: filtro.dataEntrega.dateFilterEnd,
				field: 'dataEntrega'
			});
		}
		*/

		if (filtro.createdAt) {
			if (filtro.createdAt.dateFilterStart) filtro.createdAt.dateFilterStart = new Date(filtro.createdAt.dateFilterStart.getTime() - UtilsService.TZ_CORRECTION);
			if (filtro.createdAt.dateFilterEnd) filtro.createdAt.dateFilterEnd = new Date(filtro.createdAt.dateFilterEnd.getTime() - UtilsService.TZ_CORRECTION);
			if (filtro.createdAt.dateFilterStart || filtro.createdAt.dateFilterEnd) {
				this.dateFilters.push({
					dataInicio: filtro.createdAt.dateFilterStart,
					dataFim: filtro.createdAt.dateFilterEnd,
					campo: 'data',
					notPopulateHour: true
				});
			}
		}

		if (filtro.status && filtro.status.length > 0) this.filterStatus = filtro.status;
		if (filtro.canalVendas && filtro.canalVendas.length > 0) this.filterCanalVendas = filtro.canalVendas;
		if (filtro.pedidoOrigem && filtro.pedidoOrigem.length > 0) this.filterOrigem = filtro.pedidoOrigem;

		this.filterCliente = filtro.cliente;
		this.filterEntregador = filtro.entregador;

		this._broadcaster.broadcast$(this.LOAD_PEDIDOS_EVENT, {});
	}

	formatToExport(_paginate?: boolean): Promise<any[]> {
		const paginate = _paginate ? _paginate : localStorage.paginate ? JSON.parse(localStorage.paginate) : true;

		const formatData = (_data: any[]) => {
			const result = [];

			for (const data of _data) {

				/** Verifica se é um header row */
				if ((data as any || {} as any).isHeaderRow == true) {

					let fillColor = [255, 255, 255];
					let textColor = [0, 0, 0];

					result.push({
						'Data': {
							content: 'Data',
							styles: { fontStyle: 'bold', fillColor: fillColor, textColor: textColor, cellWidth: 20 }
						},
						'Atendido Por': {
							content: 'Atendido Por',
							styles: { fontStyle: 'bold', fillColor: fillColor, textColor: textColor, cellWidth: 30 }
						},
						'Status': {
							content: 'Status',
							styles: { fontStyle: 'bold', fillColor: fillColor, textColor: textColor, cellWidth: 20 }
						},
						'Produtos': {
							content: 'Produtos',
							styles: { fontStyle: 'bold', fillColor: fillColor, textColor: textColor, cellWidth: 25 }
						},
						'End. Entrega': {
							content: 'End. Entrega',
							styles: { fontStyle: 'bold', fillColor: fillColor, textColor: textColor, cellWidth: 50 }
						},
						'Cliente': {
							content: 'Cliente',
							styles: { fontStyle: 'bold', fillColor: fillColor, textColor: textColor, cellWidth: 40 }
						},
						'Tel. Cliente': {
							content: 'Tel. Cliente',
							styles: { fontStyle: 'bold', fillColor: fillColor, textColor: textColor, cellWidth: 20 }
						},
						'Entregador': {
							content: 'Entregador',
							styles: { fontStyle: 'bold', fillColor: fillColor, textColor: textColor, cellWidth: 20 }
						},
						'Vl. Total': {
							content: 'Vl. Total',
							styles: { fontStyle: 'bold', fillColor: fillColor, textColor: textColor, cellWidth: 20 }
						},
						'Canal de Venda': {
							content: 'Canal de Venda',
							styles: { fontStyle: 'bold', fillColor: fillColor, textColor: textColor, cellWidth: 20 }
						},
						'Obs. do Cliente': {
							content: 'Obs. do Cliente',
							styles: { fontStyle: 'bold', fillColor: fillColor, textColor: textColor, cellWidth: 20 }
						},
						/* 'Troco': {
							content: 'Troco',
							styles: { fontStyle: 'bold', fillColor: fillColor, textColor: textColor }
						}, */
					});

				} else if ((data as any || {} as any).isGroupProdutos == true) {

					let content = '';

					for (const prod of (data.produtos || [])) {
						content += `${content != '' ? '\n' : ''}x${prod.quantidade || 0} `;
						content += ` ${prod.nome} `;
						if (prod.tipoEstoqueToRender == null) content += ` (SEM INFORMAÇÂO DE ESTOQUE) `;
						else content += ` (${this.getTipoEstoque(prod.tipoEstoqueToRender)}) `;
					}

					result.push({
						'Data': { content: '', colSpan: 0 },
						'Atendido Por': { content: '', colSpan: 0 },
						'Status': { content: '', colSpan: 0 },
						'Produtos': {
							content: content,
							colSpan: 1,
							styles: { halign: 'left', fontStyle: 'bold', }
						},
						'End. Entrega': { content: '', colSpan: 0 },
						'Cliente': { content: '', colSpan: 0 },
						'Tel. Cliente': { content: '', colSpan: 0 },
						'Entregador': { content: '', colSpan: 0 },
						'Vl. Total': { content: '', colSpan: 0 },
						'Canal de Venda': { content: '', colSpan: 0 },
						'Obs. do Cliente': { content: '', colSpan: 0 },
						//'Troco': {content: '', colSpan: 0},
					});

				} else if ((data as any || {} as any).isGroupBy == true) {

					let content = '';

					if (!!data.empresa) content = data.empresa.nomeFantasia || '';
					else if (!!data.loja) content = data.loja.nomeFantasia || '';
					else if ((data as any).customTitle) content = (data as any).customTitle;

					result.push({
						'Data': {
							content: '> ' + content,
							colSpan: 999,
							styles: { halign: 'left', fontStyle: 'bold', fillColor: [255, 255, 255], textColor: [0, 0, 0] }
						},
						'Atendido Por': { content: '', colSpan: 0 },
						'Status': { content: '', colSpan: 0 },
						'Produtos': { content: '', colSpan: 0 },
						'End. Entrega': { content: '', colSpan: 0 },
						'Cliente': { content: '', colSpan: 0 },
						'Tel. Cliente': { content: '', colSpan: 0 },
						'Entregador': { content: '', colSpan: 0 },
						'Vl. Total': { content: '', colSpan: 0 },
						'Canal de Venda': { content: '', colSpan: 0 },
						'Obs. do Cliente': { content: '', colSpan: 0 },
						//'Troco': {content: '', colSpan: 0},
					});
				} else {

					let produtos = '';
					for (const c in data.produtos) {
						if (!data.isGroupProdutos) {
							const produto = data.produtos[c];
							let estoqueToUpdate = (data.estoquesToUpdate || []).find(est => est.idProduto == (produto._id || produto.idProduto) && (est.estoqueSelecionado || {}).tipoEstoque == produto.tipoEstoqueToRender);
							if (estoqueToUpdate == null) {
								estoqueToUpdate = (data.estoquesToUpdate || []).find(est => est.idProduto == (produto._id || produto.idProduto));
							}
							produtos += (produtos != '' ? ', ' : '') + (estoqueToUpdate.quantidade || 0) + 'x ' + produto.nome;
						}
					}

					const endereco = !!data.cliente ? this.getEnderecoDefaultFormated(data.cliente.enderecos, false) : ''
					const _endereco = endereco.length > 15 ? endereco.substr(0, 15) + '\n' + endereco.substr(15) : endereco

					result.push({
						'Data': this.utils.formatDate(data.createdAt, true),
						'Atendido Por': data.atendidoPor ? data.atendidoPor.nome : '',
						'Status': this.getStatusName(data.status),
						'Produtos': produtos,
						'End. Entrega': _endereco,
						'Cliente': !!data.cliente ? data.cliente.nome : '',
						'Tel. Cliente': !!data.cliente ? data.cliente.telefone : '',
						'Entregador': !!data.entregador ? data.entregador.nome : '',
						'Vl. Total': {
							content: !!data.valorTotal ? 'R$ ' + data.valorTotal.toFixed(2) : '',
							styles: {
								minCellWidth: 20
							}
						},
						'Canal de Venda': (this.getIndicacaoCompra(data.indicacaoCompra) || ""),
						'Obs. do Cliente': (((data.cliente || {}).enderecos || [])[0] || {}).observacao,
						//'Troco': data.tipoPagamento == TipoPagamento.DINHEIRO && !!data.troco ? 'R$ ' + this.calcularTroco(data) : '--',
					});
				}
			}

			let valorTotal = 0;

			(_data || []).forEach((ped: Pedido) => valorTotal += Number(ped.valorTotal || 0));

			const defaultStyle = { styles: { halign: 'left', fontStyle: 'bold', fillColor: [255, 255, 255], textColor: [0, 0, 0] } };

			result.push({
				'Data': { content: '> Total', colSpan: 1, ...defaultStyle },
				'Atendido Por': { content: '', colSpan: 1, ...defaultStyle },
				'Status': { content: '', colSpan: 1, ...defaultStyle },
				'Produtos': { content: '', colSpan: 1, ...defaultStyle },
				'End. de Entrega': { content: '', colSpan: 1, ...defaultStyle },
				'Cliente': { content: '', colSpan: 1, ...defaultStyle },
				'Tel. Cliente': { content: '', colSpan: 1, ...defaultStyle },
				'Entregador': { content: '', colSpan: 1, ...defaultStyle },
				'Valor Total': {
					content: `R$ ${(valorTotal || 0).toFixed(2)}`,
					colSpan: 1,
					...defaultStyle
				},
				'Canal de Venda': { content: '', colSpan: 1, ...defaultStyle },
				'Obs. do Cliente': { content: '', colSpan: 1, ...defaultStyle },
				//'Troco': {content: '', colSpan: 0},
			});

			return result;
		};

		return new Promise((resolve) => {
			if (paginate) {
				if (!!this.dataSource && this.dataSource.data.length > 0) {
					resolve(formatData(this.dataSource.data));
				}
				else {
					resolve(null)
				}
			}
			else {
				this._load.show()
				let pedidoFilter = new PedidoFilter({
					dateFilter: this.dateFilters && this.dateFilters.length > 0 ? this.dateFilters[0] : null,
					statusFilter: this.filterStatus,
					pedidoOrigem: this.filterOrigem,
					filterCanalVendas: this.filterCanalVendas,
					...(this.filterEntregador && this.filterEntregador.idEntregador ? { idEntregador: this.filterEntregador.idEntregador } : {})
				});
				const isSilvaGas = this.route.snapshot.queryParamMap.get('silvagas') == 'true';
				this.pedidosService.getAll(
					PedidosComponent.ROUTE_ID,
					undefined,
					undefined,
					pedidoFilter,
					pedidoFilter.dateFilter != null ? !pedidoFilter.dateFilter.notPopulateHour : undefined,
					isSilvaGas ? true : undefined,
					isSilvaGas ? { multiEmpresa: '5d1399737e07b763d5e843eb' } : undefined,
					undefined,
					this.searchString
				).subscribe(
					(res: Pedido[]) => {
						if (res.length > 0) {
							const result = this.groupObjectsByEstabelecimento<any>(res);
							let resultPed = [];

							result.forEach(ped => {
								if (ped.isGroupBy) {
									resultPed.push(ped);
									resultPed.push({ isHeaderRow: true });
								} else {
									ped.tokenControle = nanoid(36);
									resultPed.push(ped)
								};
							});

							this._load.hide()
							resolve(formatData(resultPed))
						}
						else {
							resolve(null)
						}
					},
					error => {
						this._load.hide()
						resolve(null)
					}
				);
			}
		})
	}

	applyInputFilter(filterValue: string) {
		this.canShowLoading = false;

		if (this.dataSource.paginator) {
			this.dataSource.paginator.firstPage();
		}

		this.searchString = filterValue.trim().toLowerCase();
		this._broadcaster.broadcast$(this.LOAD_PEDIDOS_EVENT, {});
	}

	expandOptions(pedido) {
		if (!!this.dataSource && !!this.dataSource.data) {
			for (const c in this.dataSource.data) {
				if (this.dataSource.data[c]['idPedido'] != pedido.idPedido) {

					this.dataSource.data[c]['statusOptionsExpanded'] = false;
				}
			}
		}

		pedido.statusOptionsExpanded = !pedido.statusOptionsExpanded;
	}

	repassarPedidoOld(p: Pedido, e: Entregador, alterarEntregadorAcerto: boolean) {
		if (p && e) {
			p.entregadorIdEntregador = e.idEntregador;
			p.status = PedidoStatus.PEDIDO_REPASSADO;
			p.dataRepasse = new Date(Date.now());
			p.novoHistorico = [];
			p.novoHistorico.push({ desc: 'Repassado para o entregador(' + e.nome.toUpperCase() + ')' });
			this.atualizarPedido(p, null, 'Entregador alterado com sucesso!', 'Não foi possível alterar esse entregador, tente novamente!', alterarEntregadorAcerto);
		}
	}

	repassarPedido(p: Pedido | Pedido[], e: Entregador, alterarEntregadorAcerto: boolean) {
		this.pedidosService.repassarPedido(Array.isArray(p) ? p : [p._id], e._id, alterarEntregadorAcerto, PedidosComponent.ROUTE_ID).subscribe(
			() => {
				showSnackBar('Entregador alterado com sucesso!', Snackbar.TYPE_SUCCESS, this.snackBar);
				this._broadcaster.broadcast$(this.LOAD_PEDIDOS_EVENT, {});
			},
			(err) => {
				_alert('Não foi possível alterar esse entregador, tente novamente!');
				console.log(err);
			}
		)
	}

	verificarRepasse(p: Pedido | Pedido[], e: Entregador, alterarEntregadorAcerto: boolean) {
		this.repassarPedido(p, e, alterarEntregadorAcerto);
		//this.repassarPedidoOld(p as Pedido, e, alterarEntregadorAcerto);
	}

	async setupFiltros() {

		if (this.setupFiltrosTimeout) clearTimeout(this.setupFiltrosTimeout);

		setTimeout(() => this.runConfigFiltros(), 1000);
	}

	async runConfigFiltros() {
		this.filtros = [
			{
				title: 'Status',
				id: ListarPedidosFiltros.STATUS,
				showOptions: false,
				selectTitle: '',
				isSelectType: false,
				isMultiSelecao: true,
				multiSelecao: [],
				opcoes: [
					{ nome: 'Cancelado', active: (this.filterStatus || []).includes(PedidoStatus.PEDIDO_CANCELADO), value: PedidoStatus.PEDIDO_CANCELADO },
					{ nome: 'Agendado', active: (this.filterStatus || []).includes(PedidoStatus.PEDIDO_AGENDADO), value: PedidoStatus.PEDIDO_AGENDADO },
					{ nome: 'Novo', active: (this.filterStatus || []).includes(PedidoStatus.PEDIDO_NOVO), value: PedidoStatus.PEDIDO_NOVO },
					{ nome: 'Repassado', active: (this.filterStatus || []).includes(PedidoStatus.PEDIDO_REPASSADO), value: PedidoStatus.PEDIDO_REPASSADO },
					{ nome: 'Em Trânsito', active: (this.filterStatus || []).includes(PedidoStatus.PEDIDO_EM_TRANSITO), value: PedidoStatus.PEDIDO_EM_TRANSITO },
					{ nome: 'Entregue', active: (this.filterStatus || []).includes(PedidoStatus.PEDIDO_ENTREGUE), value: PedidoStatus.PEDIDO_ENTREGUE },
					{ nome: 'Pendente', active: (this.filterStatus || []).includes(PedidoStatus.PEDIDO_PENDENTE), value: PedidoStatus.PEDIDO_PENDENTE }
				]
			},
			{
				title: 'Origem do pedido',
				id: ListarPedidosFiltros.ORIGEM_PEDIDO,
				showOptions: false,
				selectTitle: '',
				isSelectType: false,
				isMultiSelecao: true,
				multiSelecao: [],
				opcoes: [
					{ nome: 'Teleatendimento', active: (this.filterOrigem || []).includes(PedidoOrigem.TELEATENDIMENTO), value: PedidoOrigem.TELEATENDIMENTO },
					{ nome: 'Portaria', active: (this.filterOrigem || []).includes(PedidoOrigem.PORTARIA), value: PedidoOrigem.PORTARIA },
					{ nome: 'Aplicativo', active: (this.filterOrigem || []).includes(PedidoOrigem.APLICATIVO), value: PedidoOrigem.APLICATIVO },
					{ nome: 'Aplicativo QR CODE', active: (this.filterOrigem || []).includes(PedidoOrigem.QRCODEAPP), value: PedidoOrigem.QRCODEAPP },
				]
			},
			{
				title: 'Canal de venda',
				id: ListarPedidosFiltros.CANAL_VENDAS,
				showOptions: false,
				selectTitle: '',
				isSelectType: false,
				isMultiSelecao: true,
				multiSelecao: [],
				opcoes: [
					{ nome: 'E-mail', active: (this.filterCanalVendas || []).includes(IndicacaoCompra.EMAIL), value: IndicacaoCompra.EMAIL },
					{ nome: 'Ima', active: (this.filterCanalVendas || []).includes(IndicacaoCompra.IMA), value: IndicacaoCompra.IMA },
					{ nome: 'Outdoor', active: (this.filterCanalVendas || []).includes(IndicacaoCompra.OUTDOOR), value: IndicacaoCompra.OUTDOOR },
					{ nome: 'Internet', active: (this.filterCanalVendas || []).includes(IndicacaoCompra.INTERNET), value: IndicacaoCompra.INTERNET },
					{ nome: 'Rede Social', active: (this.filterCanalVendas || []).includes(IndicacaoCompra.REDE_SOCIAL), value: IndicacaoCompra.REDE_SOCIAL },
					{ nome: 'Celular', active: (this.filterCanalVendas || []).includes(IndicacaoCompra.CELULAR), value: IndicacaoCompra.CELULAR },
					{ nome: 'Telefone', active: (this.filterCanalVendas || []).includes(IndicacaoCompra.TELEFONE), value: IndicacaoCompra.TELEFONE },
					{ nome: 'WhatsApp', active: (this.filterCanalVendas || []).includes(IndicacaoCompra.WHATSAPP), value: IndicacaoCompra.WHATSAPP },
					{ nome: 'Aplicativo', active: (this.filterCanalVendas || []).includes(IndicacaoCompra.APLICATIVO), value: IndicacaoCompra.APLICATIVO },
					{ nome: 'Portaria', active: (this.filterCanalVendas || []).includes(IndicacaoCompra.PORTARIA), value: IndicacaoCompra.PORTARIA },
					{ nome: 'Teleatendimento', active: (this.filterCanalVendas || []).includes(IndicacaoCompra.TELEATENDIMENTO), value: IndicacaoCompra.TELEATENDIMENTO },
					{ nome: 'Plantão', active: (this.filterCanalVendas || []).includes(IndicacaoCompra.PLANTAO), value: IndicacaoCompra.PLANTAO },
					{ nome: 'Venda à Porta', active: (this.filterCanalVendas || []).includes(IndicacaoCompra.VENDA_A_PORTA), value: IndicacaoCompra.VENDA_A_PORTA },
					{ nome: 'Sinalização', active: (this.filterCanalVendas || []).includes(IndicacaoCompra.SINALIZACAO), value: IndicacaoCompra.SINALIZACAO },
					{ nome: 'Impresso', active: (this.filterCanalVendas || []).includes(IndicacaoCompra.IMPRESSO), value: IndicacaoCompra.IMPRESSO },
					{ nome: 'Cliente Fidelizado', active: (this.filterCanalVendas || []).includes(IndicacaoCompra.CLIENTE_FIDELIZADO), value: IndicacaoCompra.CLIENTE_FIDELIZADO },
					{ nome: 'Automática', active: (this.filterCanalVendas || []).includes(IndicacaoCompra.AUTOMATICA), value: IndicacaoCompra.AUTOMATICA },
					{ nome: 'Agregado', active: (this.filterCanalVendas || []).includes(IndicacaoCompra.AGREGADO), value: IndicacaoCompra.AGREGADO },
					{ nome: 'Comércio/Indústria', active: (this.filterCanalVendas || []).includes(IndicacaoCompra.COMERCIO_INDUSTRIA), value: IndicacaoCompra.COMERCIO_INDUSTRIA },
					{ nome: 'Ch App', active: (this.filterCanalVendas || []).includes(IndicacaoCompra.CH_APP), value: IndicacaoCompra.CH_APP },
					{ nome: 'PG App', active: (this.filterCanalVendas || []).includes(IndicacaoCompra.PG_APP), value: IndicacaoCompra.PG_APP },
					{ nome: 'UP App', active: (this.filterCanalVendas || []).includes(IndicacaoCompra.UP_APP), value: IndicacaoCompra.UP_APP },
					{ nome: 'Aplicativo QR Code', active: (this.filterCanalVendas || []).includes(IndicacaoCompra.QRCODEAPP), value: IndicacaoCompra.QRCODEAPP },
					{ nome: 'Rádio', active: (this.filterCanalVendas || []).includes(IndicacaoCompra.RADIO), value: IndicacaoCompra.RADIO }
				]
			}
		];

		// const resultClientes: Cliente[] = await this.clientesService.getAll().toPromise<Cliente[]>();
		const resultClientes: Cliente[] = [];
		const resultEntregadores: Entregador[] = await this.entregadoresService.getAll(
			undefined,
			undefined,
			undefined,
			PedidosComponent.ROUTE_ID
		).toPromise<Entregador[]>();

		const resultFiltroClienteFormated = [];
		const resultFiltroEntregadorFormated = [];

		for (const clt of resultClientes) {
			// if ((clt.pedidos || []).length > 0) resultFiltroClienteFormated.push({nome: clt.nome, value: clt, active: false});
			resultFiltroClienteFormated.push({ nome: clt.nome, value: clt, active: false });
		}

		for (const ent of resultEntregadores) {
			// if ((ent.pedidos || []).length > 0) resultFiltroEntregadorFormated.push({ nome: ent.nome, value: ent, active: false });
			resultFiltroEntregadorFormated.push({ nome: ent.nome, value: ent, active: false });
		}

		const defaultFiltroSelectOptions = {
			showOptions: false,
			isSelectType: true,
			isMultiSelecao: false,
			singleSelecao: null,
		};

		this.filtros.unshift(
			{ title: 'Cliente', id: ListarPedidosFiltros.CLIENTE, selectTitle: 'Selecionar Cliente', opcoes: resultFiltroClienteFormated, ...defaultFiltroSelectOptions },
			{ title: 'Entregador', id: ListarPedidosFiltros.ENTREGADOR, selectTitle: 'Selecionar Entregador', opcoes: resultFiltroEntregadorFormated, ...defaultFiltroSelectOptions }
		);

		this.filtros.unshift();

		this.detectChanges();
	}

	async atualizarPedido(pedido, status: PedidoStatus, successMessage: string = 'Pedido atualizado com sucesso!', errorMessage: string = 'Não foi possível atualizar esse pedido, tente novamente!', alterarEntregadorAcerto: boolean) {

		if (status == PedidoStatus.PEDIDO_CANCELADO && (pedido.observacaoCancelamento == null || pedido.observacaoCancelamento.replace(/\s/g, '') == '')) {
			showSnackBar('Informe o motivo do cancelamento do pedido para continuar', Snackbar.TYPE_WARNING, this.snackBar);
			return;
		} else if (status == PedidoStatus.PEDIDO_CANCELADO && this.cancelarNota == true
			&& (this.cancelarNotaJust == null || this.cancelarNotaJust.length < 15)) {

			showSnackBar('Informe a justificativa do cancelamento da nota fiscal para continuar, o tamanho mínimo é 15 caracteres', Snackbar.TYPE_WARNING, this.snackBar);
			return;

		} else if (status == PedidoStatus.PEDIDO_CANCELADO && this.cancelarNota == true
			&& (this.cancelarNotaFuso == null || this.cancelarNotaFuso.replace(/\s/g, '') == '')) {

			showSnackBar('Informe o fuso horário da cidade de emissão da nota fiscal para continuar', Snackbar.TYPE_WARNING, this.snackBar);
			return;

		} else if (status !== PedidoStatus.PEDIDO_CANCELADO && pedido.acertado == true) {
			showSnackBar('Esse pedido foi acertado, reverta/cancele o acerto antes de alterar o status!', Snackbar.TYPE_WARNING, this.snackBar);
			return;
		} else {
			pedido.showEditConfirm = false;
		}

		let body: Pedido | any = JSON.parse(JSON.stringify({
			...pedido,
			cliente: undefined,
			pagamentos: undefined,
			pagamentosNoPedido: undefined,
			valorNegociados: undefined,
			alterarEntregadorAcerto
		}));

		delete body.cliente;
		delete body.pagamentos;
		delete body.pagamentosNoPedido;
		delete body.valorNegociados;

		if (status) {
			body.status = status;
			if (status == PedidoStatus.PEDIDO_ENTREGUE) body.dataEntrega = new Date(Date.now());
			if (body.novoHistorico == null) body.novoHistorico = [];
			body.novoHistorico.push({ desc: `Status do pedido foi alterado para ${this.getStatusName(status)}` });
		}

		// Os dados da nota fiscal são enviados apenas quando o pedido for cancelado. By Pedro Santos
		if (this.cancelarNota) {
			body.cancelarNota = true;
			body.cancelarNotaJson = {
				fuso: this.cancelarNotaFuso,
				justificativa: this.cancelarNotaJust,
				tipoNota: "nfce"
			}
		}

		if (!this.lock) {
			this.lock = true;
			if (status == PedidoStatus.PEDIDO_ENTREGUE) {
				this._load.show();
				this.pedidosService.alterarStatusMultiplos([pedido._id], body.dataEntrega).subscribe(
					() => {
						showSnackBar('Pedidos alterados com sucesso', Snackbar.TYPE_SUCCESS, this.snackBar);
						this.toggleEntregarMultiplos(false);
						this.toggleEmitirMultiplosCupons(false);
						this.setupRequestPagination();
						this.lock = false;
					},
					(err) => {
						_alert('Não foi possível alterar o status de todos os pedidos');
						this._load.hide();
						this.lock = false;
					}
				)
			}
			else {
				this.pedidosService.alterarStatus(body, PedidosComponent.ROUTE_ID).subscribe(
					_res => {
						this._load.hide();
						pedido.statusOptionsExpanded = false;
						this.detectChanges();
						showSnackBar(successMessage, Snackbar.TYPE_SUCCESS, this.snackBar);
						this._broadcaster.broadcast$(this.LOAD_PEDIDOS_EVENT, {});
						this.lock = false;
					},
					_err => {
						this._load.hide();
						this.detectChanges();
						this.lock = false;
						if (_err.error.text == 'bloqueado') return;
						showSnackBar((_err.error || <any>{}).title || _err.error || _err, Snackbar.TYPE_DANGER, this.snackBar, (_err.error || <any>{}).message || _err.error || '');
					},
				);
			}
		}
	}

	updateClienteObject(newValues: Cliente) {
		newValues._showQuickInfoPopUp = false;

		for (const iP in this.dataSource.data) {
			const _p = this.dataSource.data[iP];
			if (!!_p.cliente && _p.cliente.idCliente == newValues.idCliente) {
				this.dataSource.data[iP].cliente = this.cloneJSON(newValues);
			}
		}
	}

	updateEntregadorObject(newValues: Entregador) {
		newValues._showQuickInfoPopUp = false;

		for (const iP in this.dataSource.data) {
			const _p = this.dataSource.data[iP];
			if (!!_p.entregador && _p.entregador.idEntregador == newValues.idEntregador) {
				this.dataSource.data[iP].entregador = this.cloneJSON(newValues);
			}
		}
	}

	updatePedidoObject(message: string) {
		this._broadcaster.broadcast$(this.LOAD_PEDIDOS_EVENT, {});
		this.detectChanges();
		showSnackBar(message, Snackbar.TYPE_SUCCESS, this.snackBar);
	}

	/**
	 * Sincroniza/Atualiza as informações relacionadas ao pedido
	 * @param {Pedido} p - Pedido atualizado
	 */
	sincronizarPedido(p: Pedido) {
		if (p != null) {
			let pedidosToUpdate: Pedido[] = JSON.parse(JSON.stringify((this.pedidos || []).map(p => ({
				...p,
				valorNegociados: undefined
			}))));

			let pIndex = pedidosToUpdate.findIndex(ped => ped._id == p._id || ped._id == p.idPedido);
			if (pIndex >= 0) pedidosToUpdate[pIndex] = p;
			else if (p['isNovoPedido'] == true || p.status === PedidoStatus.PEDIDO_DEVOLVIDO) {
				console.log(p);
				pedidosToUpdate.unshift(p);
				if (this.paginator != null && this.paginator.pageSize != null && pedidosToUpdate.length > this.paginator.pageSize) {
					pedidosToUpdate.pop();
				}
				this._resultsLength = (this._resultsLength || 0) + 1;
			}

			this.setPedidoDataSource(pedidosToUpdate);
			//this._broadcaster.broadcast$(this.LOAD_PEDIDOS_EVENT, {});
		}
	}

	abrirPopUpConfirmacaoData(pedido: Pedido) {
		pedido['_mostrarAlertaPedidoEntregue'] = true;
		pedido.dataEntrega = new Date(Date.now());
	}

	contaProdutosQuantidade(pedidos: any[]): any[] {
		let resultFinal = [];
		let produtosVendidos = {};
		let valorTotalPedidos = 0;

		/** Formata a quantidae de produtos vendidos por empresa */
		let i = 0;
		for (let ped of pedidos) {

			if (ped.isGroupBy || ped.isTotalGeral) {
				let groupProdutos = [];
				for (const key in produtosVendidos) groupProdutos.push(produtosVendidos[key]);
				produtosVendidos = {};
				resultFinal.push({ isGroupProdutos: true, produtos: groupProdutos, valorTotalPedidos: valorTotalPedidos });
				valorTotalPedidos = 0;
			}

			if (ped.isGroupBy) resultFinal.push(ped);
			else {
				resultFinal.push(ped);

				for (const est of (ped.estoquesToUpdate || [])) {
					if (produtosVendidos[`${est.idProduto}${est.estoqueSelecionado.tipoEstoque}`]) {
						produtosVendidos[`${est.idProduto}${est.estoqueSelecionado.tipoEstoque}`].quantidade += Number(est.quantidade);
					} else {
						let produtoObj = (ped.produtos || []).find(p => (p._id || p.idProduto) == est.idProduto);
						produtosVendidos[`${est.idProduto}${est.estoqueSelecionado.tipoEstoque}`] = {
							nome: produtoObj != null ? produtoObj.nome : 'SEM INFORMAÇÂO DE NOME DE PRODUTO!',
							tipoEstoqueToRender: est.estoqueSelecionado.tipoEstoque,
							quantidade: est.quantidade
						};
					}
				}

				for (const produto of (ped.produtos || [])) {
					let estoqueToUpdate = null;
					for (const est of (ped.estoquesToUpdate || [])) {
						if (est.idProduto === produto._id && (est.estoqueSelecionado || <any>{}).tipoEstoque != null) {
							estoqueToUpdate = est;
							break;
						}
					}

					// Se não tem informação de estoque, salva o produto mesmo assim
					if (estoqueToUpdate == null) {
						if (produtosVendidos[`${produto.idProduto}`]) produtosVendidos[`${produto.idProduto}`].quantidade += 0;
						else produtosVendidos[`${produto.idProduto}`] = { nome: produto.nome, tipoEstoqueToRender: null, quantidade: 0 };
					}
				}

				valorTotalPedidos += Number(ped.valorTotal) || 0;

				if (isLastPos(i, pedidos)) {
					let groupProdutos = [];
					for (const key in produtosVendidos) groupProdutos.push(produtosVendidos[key]);
					resultFinal.push({ isGroupProdutos: true, produtos: groupProdutos, valorTotalPedidos: valorTotalPedidos });
					valorTotalPedidos = 0;
				}
			}
			++i;
		}

		return resultFinal;
	}

	hasPagamento: (t: TipoPagamento, pagamentos: Pagamento[]) => boolean = (t, pagamentos) => (pagamentos || []).find(p => p.tipoPagamento === t) != null;

}
