import { Injectable } from '@angular/core';
import { EstabelecimentoFunctions } from '../../../models/interface/EstabelecimentoFunctions';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { TokenStorage } from '../../../auth/token-storage.service';
import { Observable } from 'rxjs';
import { AppConfig } from '../../../../config/appConfig';
import { ServicePattern } from '../../../models/interface/ServicePattern';
import { ResultObject } from '../../../models/api/ResultObject';
import { BoletoInfoPJBank, BoletoPJBank } from '../../../models/api/boleto/BoletoPJBank';
import { DateFilter } from '../../../models/interface/DateFilter';

@Injectable()
export class BoletoService extends EstabelecimentoFunctions implements ServicePattern<BoletoPJBank | BoletoInfoPJBank> {

	private readonly ROUTE = '/boleto';

	constructor(private http: HttpClient, private tokenStorage: TokenStorage) {
		super();
	}

	getAll(
		dateFilter: DateFilter = { dataInicio: null, dataFim: null },
		componentId: string = '',
		page = 1,
		per_page = 25,
		filtros = {}
	): Observable<any> {
		return this.http.get<any>(
			AppConfig.getAPIEndpoint() + this.ROUTE + '/extrato/' + this.tokenStorage.getUserInfo().idUsuario,
			{
				headers: AppConfig.getHTTPHeader().headers.set('componentId', componentId),
				params: {
					...this.getEstabelecimentoQueryParam(),
					...this.getDateFilterWithFieldQueryParam(dateFilter.campo, dateFilter.dataInicio, dateFilter.dataFim),
					page,
					per_page,
					...filtros
				}
			}
		);
	}

	get(id: number | string, ...args): Observable<any> {
		// TODO
		return null;
	}

	save(body: any, ...args): Observable<ResultObject<any>> {
		// TODO
		return null;
	}

	delete(id: number | string, ...args): Observable<ResultObject<any>> {
		// TODO
		return null;
	}

	invalidarBoleto(body: { idEmpresa: string, idLoja: string, identificacao_pedido: string, idBoleto: string, motivo: string }, componentId: string = ''): Observable<any> {
		return this.http.delete<ResultObject<any>>(
			AppConfig.getAPIEndpoint() + this.ROUTE + '/invalidar/' + this.tokenStorage.getUserInfo().idUsuario,
			{
				headers: AppConfig.getHTTPHeader().headers.set('componentId', componentId),
				params: {
					idEmpresa: body.idEmpresa,
					idLoja: body.idLoja,
					identificacao_pedido: body.identificacao_pedido,
					idBoleto: body.idBoleto,
					motivo: body.motivo
				}
			}
		);
	}

	invalidarBoletoCloud(token: string, motivo: string, idBoleto: string, componentId: string = '', cancelarNota: boolean = false): Observable<any> {
		return this.http.put<any>(
			AppConfig.getAPIEndpoint() + this.ROUTE + '/invalidar_boleto_cloud/' + this.tokenStorage.getUserInfo().idUsuario,
			{ token, motivo, idBoleto, cancelarNota },
			{
				headers: AppConfig.getHTTPHeader().headers.set('componentId', componentId)
			}
		);
	}

	editarVencimento(token: string, date: string, idBoleto: string, componentId: string = ''): Observable<any> {
		return this.http.put<any>(
			AppConfig.getAPIEndpoint() + this.ROUTE + '/alterar_vencimento/' + this.tokenStorage.getUserInfo().idUsuario,
			{ token, date, idBoleto },
			{
				headers: AppConfig.getHTTPHeader().headers.set('componentId', componentId)
			}
		);
	}

	update(body: any, ...args): Observable<ResultObject<any>> {
		// TODO
		return null;
	}

	boletoPago(idBoleto: string, componentId: string = ''): Observable<BoletoPJBank> {
		return this.http.post<BoletoPJBank>(
			AppConfig.getAPIEndpoint() + this.ROUTE + '/boleto_pago/' + this.tokenStorage.getUserInfo().idUsuario,
			{
				idBoleto: idBoleto
			},
			{
				headers: AppConfig.getHTTPHeader().headers.set('componentId', componentId),
				params: {}
			}
		);
	}

	emitirBoleto(body, componentId: string = ''): Observable<BoletoPJBank> {
		return this.http.post<BoletoPJBank>(
			AppConfig.getAPIEndpoint() + this.ROUTE + '/emitir_boleto/' + this.tokenStorage.getUserInfo().idUsuario,
			body,
			{
				headers: AppConfig.getHTTPHeader().headers.set('componentId', componentId),
				params: {}
			}
		);
	}

	baixarCNAB(empresa: string = null, loja: string = null, componentId: string = ''): Observable<any> {
		return this.http.post<any>(
			AppConfig.getAPIEndpoint() + this.ROUTE + '/baixar_cnab/' + this.tokenStorage.getUserInfo().idUsuario,
			{
				...(empresa ? { empresa } : {}),
				...(loja ? { loja } : {})
			},
			{
				headers: AppConfig.getHTTPHeader().headers.set('componentId', componentId),
				params: {}
			}
		);
	}

	listarCNABs(multiEmpresa: string, multiLoja: string, componentId: string = ''): Observable<any> {
		return this.http.get<any>(
			AppConfig.getAPIEndpoint() + this.ROUTE + '/listar_cnabs/' + this.tokenStorage.getUserInfo().idUsuario,
			{
				headers: AppConfig.getHTTPHeader().headers.set('componentId', componentId),
				params: {
					...(multiEmpresa ? { multiEmpresa } : {}),
					...(multiLoja ? { multiLoja } : {}),
				}
			}
		);
	}

	listarRetornosCNABs(multiEmpresa: string, multiLoja: string, componentId: string = ''): Observable<any> {
		return this.http.get<any>(
			AppConfig.getAPIEndpoint() + this.ROUTE + '/listar_retornos_cnabs/' + this.tokenStorage.getUserInfo().idUsuario,
			{
				headers: AppConfig.getHTTPHeader().headers.set('componentId', componentId),
				params: {
					...(multiEmpresa ? { multiEmpresa } : {}),
					...(multiLoja ? { multiLoja } : {}),
				}
			}
		);
	}

	enviarRetorno(arquivo: File, idEmpresa, idLoja, componentId: string = ''): Observable<any> {
		const formData = new FormData();
		if (idEmpresa) {
			formData.append('idEmpresa', idEmpresa);
		}
		if (idLoja) {
			formData.append('idLoja', idLoja);
		}
		formData.append('arquivo', arquivo);

		return this.http.post<any>(
			AppConfig.getAPIEndpoint() + this.ROUTE + '/enviar_retorno/' + this.tokenStorage.getUserInfo().idUsuario,
			formData,
			{
				headers: new HttpHeaders({ componentId })
			}
		);
	}

	sendMail(linkBoleto: string, to: string, datadocumento: string, idSmtpConfig: string, idEmpresa?: string, idLoja?: string, componentId: string = ''): Observable<any> {
		return this.http.post<any>(
			`${AppConfig.getAPIEndpoint()}${this.ROUTE}/enviar_email/${this.tokenStorage.getUserInfo().idUsuario}`,
			{
				linkBoleto,
				to,
				datadocumento,
				idSmtpConfig,
				...(idEmpresa ? { idEmpresa } : {}),
				...(idLoja ? { idLoja } : {})
			},
			{
				headers: AppConfig.getHTTPHeader().headers.set('componentId', componentId)
			}
		);
	}
}
