import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {TokenStorage} from '../../auth/token-storage.service';
import {AppConfig} from '../../../config/appConfig';
import {Observable} from 'rxjs';
import {ResultObject} from '../../models/api/ResultObject';
import {ServicePattern} from '../../models/interface/ServicePattern';
import { EstabelecimentoFunctions } from '../../models/interface/EstabelecimentoFunctions';
import {Estabelecimento} from '../../models/template/Estabelecimento';
import {Contabil} from '../../models/api/Contabil';
import {DateFilter} from '../../models/interface/DateFilter';

@Injectable()
export class ContabilService extends EstabelecimentoFunctions implements ServicePattern<Contabil> {

	readonly ROUTE = '/contabil';

	constructor(private http: HttpClient, private tokenStorage: TokenStorage) {
		super();
	}

	getAll(dateFilter: DateFilter = { }, page: number = null, perPage: number = null, componentId: string = '', estabelecimento?: Estabelecimento): Observable<Contabil[]> {
		return this.http.get<Contabil[]>(
			`${AppConfig.getAPIEndpoint()}${this.ROUTE}/${this.tokenStorage.getUserInfo().idUsuario}`,
			{
				headers: AppConfig.getHTTPHeader().headers.set('componentId', componentId),
				params: {
					...this.getEstabelecimentoQueryParam(estabelecimento),
					...this.getDateFilterWithFieldQueryParam(dateFilter.campo || 'dataVenda', dateFilter.dataInicio, dateFilter.dataFim),
					...(page || page == 0 ? { page: page + 1 } : {}),
					...(perPage ? { per_page: perPage } : {})
				}
			}
		);
	}

	get(_id: string, componentId: string = ''): Observable<Contabil> {
		return this.http.get<Contabil>(
			`${AppConfig.getAPIEndpoint()}${this.ROUTE}/${this.tokenStorage.getUserInfo().idUsuario}`,
			{
				headers: AppConfig.getHTTPHeader().headers.set('componentId', componentId),
				params: {
					...this.getEstabelecimentoQueryParam(),
					_id
				}
			}
		);
	}

	save(body: Contabil, componentId: string = '' , getEstabelecimentoFromCache: boolean = true): Observable<ResultObject<Contabil>> {
		if (getEstabelecimentoFromCache == true) body.estabelecimento = this.getEstabelecimentoOnCache();
		return this.http.post<ResultObject<Contabil>>(
			`${AppConfig.getAPIEndpoint()}${this.ROUTE}/${this.tokenStorage.getUserInfo().idUsuario}`,
			body,
			{
				headers: AppConfig.getHTTPHeader().headers.set('componentId', componentId)
			}
		);
	}

	update(body: Contabil, componentId: string = ''): Observable<ResultObject<any>> {
		return this.http.put<ResultObject<any>>(
			`${AppConfig.getAPIEndpoint()}${this.ROUTE}/${body._id}/${this.tokenStorage.getUserInfo().idUsuario}`,
			body,
			{
				headers: AppConfig.getHTTPHeader().headers.set('componentId', componentId)
			}
		);
	}

	delete(_id: string, componentId: string = ''): Observable<ResultObject<any>> {
		return this.http.delete<ResultObject<any>>(
			`${AppConfig.getAPIEndpoint()}${this.ROUTE}/${_id}/${this.tokenStorage.getUserInfo().idUsuario}`,
			{
				headers: AppConfig.getHTTPHeader().headers.set('componentId', componentId)
			}
		);
	}
}
