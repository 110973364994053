import { Injectable, NgModule } from '@angular/core';
import {Observable} from 'rxjs';
import {
	HttpEvent,
	HttpInterceptor,
	HttpHandler,
	HttpRequest,
} from '@angular/common/http';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import {TokenStorage} from '../../auth/token-storage.service';
import {AppConfig} from '../../../config/appConfig';

declare let screen: any;

@Injectable()
export class HttpsRequestInterceptor implements HttpInterceptor {
	intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {

		if (req.url.includes(AppConfig.getAPIEndpoint())) {

			const token = localStorage.getItem(TokenStorage.KEY_ACCESS_TOKEN);

			let headers = req.headers
			.set('Authorization', token ? token : '')
			.set('ScreenResolution', `${screen.width}x${screen.height}`);

			if(localStorage.socketid) {
				headers = headers.set('socketid', localStorage.socketid);
			}

			const dupReq = req.clone({
				headers
			});
			
			return next.handle(dupReq);
		} else {
			const dupReq = req.clone({
				headers: req.headers.delete('Authorization'),
			});
			return next.handle(dupReq);
		}
	}
}


@NgModule({
	providers: [
		{
			provide: HTTP_INTERCEPTORS,
			useClass: HttpsRequestInterceptor,
			multi: true
		},
	],
})
export class Interceptor {}
