import {Injectable} from '@angular/core';
import {Broadcaster, BroadcasterEvents} from '../../models/broadcaster';
import {Router} from '@angular/router';
import {AppConfig} from '../../../config/appConfig';
import {$getUserInfo, TokenStorage} from '../../auth/token-storage.service';

import * as io from 'socket.io-client';
import {Pedido} from '../../models/api/Pedido';
import {Empresa} from '../../models/api/Empresa';
import {Loja} from '../../models/api/Loja';
import {Notificacao} from '../../models/api/Notificacao';
import {$getEstabelecimentoOnCache} from '../../models/interface/EstabelecimentoFunctions';
import {TipoEstabelecimento} from '../../models/enum/TipoEstabelecimento';
import {Logs} from '../../models/api/Logs';
import {Usuario} from '../../models/api/Usuario';
import {Cliente} from '../../models/api/Cliente';

declare const _alert;
declare const window;

function notificar(title: string, message: string, cb?: Function) {
	if ('Notification' in window) {
		const Notification = window.Notification
		const notify = () => {
			const notification = new Notification(title, { body: message, tag: 'vendergas' })
			notification.onclick = () => {
				if (cb) cb()
			}
		}
		if (Notification.permission == 'granted') {
			notify()
		}
		else {
			Notification.requestPermission().then(permission => {
				if (permission == 'granted') {
					notify()
				}
			})
		}
	}
}

export function $objetoPertenceAoEstabelecimentoSelecionado<T>(o: T | any): boolean {
	let est = $getEstabelecimentoOnCache();
	let result = false;
	let userInfo = $getUserInfo();
	if (est && o) {
		if (est.allSelected == true) {
			if (userInfo.isAdmin) result = true;
			else {
				for (const empresa of userInfo.empresas || []) {
					if (
						((empresa || <any>{}).idEmpresa || empresa) == ((o.empresa || {}).idEmpresa || o.empresa) ||
						((empresa || <any>{}).idEmpresa || empresa) == o.empresaIdEmpresa ||
						(o.empresas || []).includes(empresa.idEmpresa || empresa)
					) {
						result = true;
						break;
					}
				}
				for (const loja of userInfo.lojas || []) {
					if (
						((loja || <any>{}).idLoja || loja) == ((o.loja || {}).idLoja || o.loja) ||
						((loja || <any>{}).idLoja || loja) == o.lojaIdLoja ||
						(o.lojas || []).includes(loja.idLoja || loja)
					) {
						result = true;
						break;
					}
				}
			}
		} else if (
			est.empresa != null &&
			(
				(est.empresa.idEmpresa || est.empresa) == ((o.empresa || {}).idEmpresa || o.empresa) ||
				(est.empresa.idEmpresa || est.empresa) == o.empresaIdEmpresa ||
				(o.empresas || []).includes(est.empresa.idEmpresa || est.empresa)
			)
		) {
			result = true;
		} else if (
			est.loja != null &&
			(
				(est.loja.idLoja || est.loja) == ((o.loja || {}).idLoja || o.loja) ||
				(est.loja.idLoja || est.loja) == o.lojaIdLoja ||
				(o.lojas || []).includes(est.loja.idLoja || est.loja)
			)
		) {
			result = true;
		} else if (
			est.tipoEstabelecimento == TipoEstabelecimento.MULTI_SELECAO &&
			est.multiSelecao != null &&
			(o.empresa != null || o.loja != null || o.empresas != null || o.lojas != null || o.empresaIdEmpresa != null || o.lojaIdLoja != null)
		) {
			for (const _mEst of est.multiSelecao) {
				if (
					_mEst.empresa != null &&
					(
						(_mEst.empresa.idEmpresa || _mEst.empresa) == ((o.empresa || {}).idEmpresa || o.empresa) ||
						(_mEst.empresa.idEmpresa || _mEst.empresa) == o.empresaIdEmpresa ||
						(o.empresas || []).includes(_mEst.empresa.idEmpresa || _mEst.empresa)
					)
				) {
					result = true;
					break;
				} else if (
					_mEst.loja != null &&
					(
						(_mEst.loja.idLoja || _mEst.loja) == ((o.loja || {}).idLoja || o.loja) ||
						(_mEst.loja.idLoja || _mEst.loja) == o.lojaIdLoja ||
						(o.lojas || []).includes(_mEst.loja.idLoja || _mEst.loja)
					)
				) {
					result = true;
					break;
				}
			}
		} else if (userInfo.isAdmin) result = true;

		if (
			result == false &&
			userInfo.isAdmin &&
			(o.empresa == null && o.loja == null && (o.empresas || []).length < 1 && (o.lojas || []).length < 1 && o.empresaIdEmpresa == null && o.lojaIdLoja == null)
		) result = true;
	}
	return result;
}

@Injectable()
export class SocketService {

    private static _socket: SocketIOClient.Socket;

    public static _broadcaster;
    public static _router;

    private static readonly _MESSAGES = [
		/* SCRIPT MESSAGES */
		{
			message: 'error',
			callback: (err) => {
				_alert(err.title, err.message);
			}
		},
		{
			message: 'scriptLog',
			callback: async (log: string) => {
				SocketService._broadcaster.broadcast$(BroadcasterEvents.SCRIPT_LOG, log)
			}
		},
		{
			message: 'scriptSuccess',
			callback: async () => {
				SocketService._broadcaster.broadcast$(BroadcasterEvents.SCRIPT_SUCCESS, {})
			}
		},
		{
			message: 'scriptError',
			callback: async (log: string) => {
				SocketService._broadcaster.broadcast$(BroadcasterEvents.SCRIPT_ERROR, {})
			}
		},
		{
			message: 'scriptInterrompido',
			callback: async (log: string) => {
				SocketService._broadcaster.broadcast$(BroadcasterEvents.SCRIPT_INTERROMPIDO, {})
			}
		},
		/* FIM SCRIPT MESSAGES */
		
    	/* CONNECTION MESSAGES */
		{
			message: 'disconnected',
			callback: async () => {
				console.log('disconnected');
				delete localStorage.socketid;
				SocketService._broadcaster.broadcast$(BroadcasterEvents.CONNECTION_CLOSED, {})
			}
		},
		{
			message: 'reconnect_error',
			callback: async () => {
				console.log('reconnect_error');
				SocketService._broadcaster.broadcast$(BroadcasterEvents.CONNECTION_CLOSED, {})
			}
		},
		{
			message: 'reconnect',
			callback: async () => {
				console.log('reconnect');
				const _socket = SocketService.getSocket()
				_socket.emit('versionChecker');
				SocketService._broadcaster.broadcast$(BroadcasterEvents.RECONNECTION_SUCCESS, {});

				const user = TokenStorage.retrieveUserInfo();
				if (user && user.isAdmin) {
					const token = new TokenStorage().getAccessTokenSync()
					_socket.emit('joinAdmin', token)
				}
			}
		},
		/* FIM CONNECTION MESSAGES */

		/* LOGS MESSAGES */		{
			message: 'novoLog',
			callback: async (log: Logs) => {
				if (($getUserInfo() || <Usuario>{}).isAdmin == true) SocketService._broadcaster.broadcast$(BroadcasterEvents.NOVO_LOG, log);
			}
		},
		/* FIM LOGS MESSAGES */

		/* CALLCENTER MESSAGES */
		{
			message: 'versionChecker',
			callback: async (v: VersionObject) => SocketService._broadcaster.broadcast$(BroadcasterEvents.VERSION_CHECKER, v)
		},
		{
			message: 'novoPedido',
			callback: async (p: Pedido) => {
				if ($objetoPertenceAoEstabelecimentoSelecionado(p)) {
					SocketService._broadcaster.broadcast$(BroadcasterEvents.NOVO_PEDIDO, p);
				}
			}
		},
		{
			message: 'pedidoAtualizado',
			callback: async (p: Pedido) => {
				if ($objetoPertenceAoEstabelecimentoSelecionado(p)) {
					SocketService._broadcaster.broadcast$(BroadcasterEvents.PEDIDO_ATUALIZADO, p);
				}
			}
		},
		{
			message: 'empresaCadastrada',
			callback: async (e: Empresa) => SocketService._broadcaster.broadcast$(BroadcasterEvents.EMPRESA_CADASTRADA, e)
		},
		{
			message: 'lojaCadastrada',
			callback: async (l: Loja) => SocketService._broadcaster.broadcast$(BroadcasterEvents.LOJA_CADASTRADA, l)
		},
		{
			message: 'novaNotificacao',
			callback: async (n: Notificacao) => {}//TODO: Reativar notificações > SocketService._broadcaster.broadcast$(BroadcasterEvents.NOVA_NOTIFICACAO, n)
		},
		{
			message: 'bina',
			callback: async (c: Cliente, estabelecimentos: {lojas: string[], empresas: string[], empresaEmitente: string, lojaEmitente: string}, idChamada: string, via: string, voip: boolean = false) => {
				c.idChamada = idChamada;
				c.via = via;
				c.voip = voip;
				const userInfo = $getUserInfo()
				if (
					(
						estabelecimentos.lojas.some(loja => (userInfo.lojas as any[]).includes(loja)) ||
						estabelecimentos.empresas.some(empresa => (userInfo.empresas as any[]).includes(empresa))
					) &&
					(
						userInfo.empresasBinaRecebe.includes(estabelecimentos.empresaEmitente) ||
						userInfo.lojasBinaRecebe.includes(estabelecimentos.lojaEmitente)
					)
				) {
					SocketService._broadcaster.broadcast$(BroadcasterEvents.CHAMADA_BINA, c)
				}
			}
		},
		{
			message: 'bina_fechar_popup',
			callback: async (numero: string) => {
				SocketService._broadcaster.broadcast$(BroadcasterEvents.BINA_FECHAR_POPUP, numero);
			}
		},
		{
			message: 'deslogar',
			callback: async () => SocketService._broadcaster.broadcast$(BroadcasterEvents.DESLOGAR)
		},
		{
			message: 'reiniciar',
			callback: async () => SocketService._broadcaster.broadcast$(BroadcasterEvents.REINICIAR)
		},
		{
			message: 'bina_atendida',
			callback: async (info: { idCliente: string, telefone: string, usuarioQueAtendeu: string, empresas: Empresa[], lojas: Loja[], voip: boolean }) => {
				if ($objetoPertenceAoEstabelecimentoSelecionado<any>(info)) SocketService._broadcaster.broadcast$(BroadcasterEvents.CHAMADA_BINA_ATENDIDA, info);
			}
		},
		{
			message: 'alert',
			callback: async (message) => {
				notificar('Erro', message);
			}
		},
		{
			message: 'success',
			callback: async (message) => {
				_alert('Sucesso', message);
				notificar('Sucesso', message);
			}
		},
		{
			message: 'error',
			callback: async (message) => {
				_alert('Erro', message);
				notificar('Erro', message);
			}
		},
		{
			message: 'socketid',
			callback: async (id) => {
				localStorage.socketid = id;
			}
		},
		{
			message: 'bloqueioEstoque',
			callback: async (idBloqueio, date) => {
				SocketService._broadcaster.broadcast$(BroadcasterEvents.BLOQUEIO_ESTOQUE, { idBloqueio, date });
			}
		},
		{
			message: 'estoque-count',
			callback: async (count, length, pedidos, idEntregador, dateStart, dateEnd) => {
				SocketService._broadcaster.broadcast$(BroadcasterEvents.ESTOQUE_COUNT, { count, length, pedidos, idEntregador, dateStart, dateEnd });
			}
		},
		{
			message: 'estoque-error',
			callback: async (count, length, numero) => {
				SocketService._broadcaster.broadcast$(BroadcasterEvents.ESTOQUE_ERROR);
			}
		}
	];

    constructor(private broadcaster: Broadcaster, private router: Router) {
        SocketService._broadcaster = this.broadcaster;
        SocketService._router = this.router;

        window['disconnectSocket'] = () => SocketService.disconnectSocket();
    }

    public static getSocket(): SocketIOClient.Socket {
        if (this._socket == null) this.initSocket();
        return this._socket;
    }

    public static initSocket() {
        //if (this._socket == null || this._socket.connected) {
        if (this._socket == null) {

            this._socket = io(AppConfig.getAPIEndpoint(), {
                forceNew: true,
                transports: ['websocket', 'polling'],
                reconnection: true,
                reconnectionAttempts: Infinity,
                reconnectionDelay: 1000,
                reconnectionDelayMax: 5000
            });

			const user = TokenStorage.retrieveUserInfo();
			if (user) {
				this._socket.emit('saveInfo', { id: user.idUsuario, nome: user.nome, role: user.role });
				if (user.isAdmin) {
					const token = new TokenStorage().getAccessTokenSync()
					this._socket.emit('joinAdmin', token)
				}
			}

            this._socket.emit('versionChecker');
            this.configSocketCallbacks();
        }
    }

    public static disconnectSocket() {
        if (this._socket != null) {
            this._socket.close();
            this._socket = null;
        }
    }

    private static configSocketCallbacks() {
		for (const websocketMessage of this._MESSAGES) {
			this._socket.off(websocketMessage.message);
			this._socket.on(websocketMessage.message, websocketMessage.callback);
		}
    }

}

export interface VersionObject {
	version: string;
}
