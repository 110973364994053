export enum FormaPagamentoNota{
	DINHEIRO = <number>1,
	CARTAO_CREDITO = <number>2,
	CARTAO_DEBITO = <number>3,
	CHEQUE = <number>4,
	BOLETO = <number>5
}

export enum FormaPagamentoNotaVista {
	A_PRAZO = <number>1,
	A_VISTA = <number>2
}

export enum PrazoPagamento {
	UMA = <number>1,
	DUAS = <number>2,
	TRES = <number>3,
	QUATRO = <number>4,
	CINCO = <number>5,
	SEIS = <number>6,
	SETE = <number>7,
	OITO = <number>8,
	NOVE = <number>9,
	DEZ = <number>10,
	ONZE = <number>11,
	DOZE = <number>12
}
