
import {Injectable} from '@angular/core';
import {EstabelecimentoFunctions} from '../../models/interface/EstabelecimentoFunctions';
import {ServicePattern} from '../../models/interface/ServicePattern';
import {HttpClient} from '@angular/common/http';
import {TokenStorage} from '../../auth/token-storage.service';
import {Observable} from 'rxjs';
import {AppConfig} from '../../../config/appConfig';
import {ResultObject} from '../../models/api/ResultObject';
import { Participante } from '../../models/api/Participante';
import { UsuarioConvenio } from '../../models/api/UsuarioConvenio';

@Injectable()
export class UsuariosConvenioService extends EstabelecimentoFunctions implements ServicePattern<UsuarioConvenio> {

	constructor(private http: HttpClient, private tokenStorage: TokenStorage ) {
		super();
	}

	getAll(convenio: string, page: number, per_page: number, componentId = '', search?: string): Observable<any> {
		return this.http.get<any>(
			AppConfig.getAPIEndpoint() + '/usuariosConvenios/' + this.tokenStorage.getUserInfo().idUsuario,
			{
				headers: AppConfig.getHTTPHeader().headers.set('componentId', componentId),
				params: {
					page: String(page),
					per_page: String(per_page),
					...(convenio ? { convenio } : {}),
					...(search ? {search} : {})
				}
			}
		);
	}

	save(body: UsuarioConvenio, componentId: string = ''): Observable<ResultObject<any>> {
		return this.http.post<ResultObject<any>>(
			AppConfig.getAPIEndpoint() + '/usuariosConvenios/' + this.tokenStorage.getUserInfo().idUsuario,
			body,
			{
				headers: AppConfig.getHTTPHeader().headers.set('componentId', componentId)
			}
		);
	}

	update(body: UsuarioConvenio, componentId: string = ''): Observable<ResultObject<any>> {
		return this.http.put<ResultObject<any>>(
			AppConfig.getAPIEndpoint() + '/usuariosConvenios/' + this.tokenStorage.getUserInfo().idUsuario,
			body,
			{
				headers: AppConfig.getHTTPHeader().headers.set('componentId', componentId)
			}
		);
	}

	delete(idUsuarioConvenio: string, componentId: string = ''): Observable<ResultObject<any>> {
		return this.http.delete<ResultObject<any>>(
			AppConfig.getAPIEndpoint() + '/usuariosConvenios/' + this.tokenStorage.getUserInfo().idUsuario + '/' + idUsuarioConvenio,
			{
				headers: AppConfig.getHTTPHeader().headers.set('componentId', componentId)
			}
		);
	}

}