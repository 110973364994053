import {Endereco} from './Endereco';
import {Pagamento, Pedido} from './Pedido';
import {DefaultFields} from '../interface/DefaultFields';
import {Estabelecimento} from '../template/Estabelecimento';
import {TipoCliente} from '../enum/TipoCliente';
import {ValorNegociado} from './ValorNegociado';
import {Conta} from './Conta';
import {Produto} from './Produto';
import {HistoricoAcao} from './HistoricoAcao';
import {TipoPagamento} from '../enum/TipoPagamento';
import {VendaAntecipada} from './VendaAntecipada';
import {TipoEstoque} from '../enum/TipoEstoque';
import {SelectValue} from '../interface/SelectValue';

export enum FiadoStatus {
	PAGO = 'pago',
	AGUARDANDO_PAGAMENTO = 'aguardando_pagamento',
	VENCIDO = 'venciado',
	CANCELADO = 'cancelado'
}

export class VendaFiada {
	cliente: string;
	pedidoNumero: string;
	numeroFatura: string;
	produtos: { nome: string; quantidade: number; tipoEstoque: TipoEstoque; };
	valorTotal: number;
	data: Date;
	dataVencimento: Date;
	status: FiadoStatus;
	pagoEm: Date;
	pagamentos: Pagamento[];
	valorPago: number;
	tipoPagamentoPago: TipoPagamento;
	cadastradoPor: { nome: string; _id: string; };
	valorFiado: number;
}

export enum TipoCFOP {
	CONSUMIDOR_FINAL = 'consumidor_final',
	PONTO_DE_VENDA = 'ponto_de_venda'
}

export const TipoCFOPSelectValues: SelectValue<TipoCFOP>[] = [
	{ value: TipoCFOP.CONSUMIDOR_FINAL, text: 'Consumidor Final' },
	{ value: TipoCFOP.PONTO_DE_VENDA, text: 'Ponto de Venda' }
];


export class DividaComodato {
	produtoId: string;
	quantidade: number;
	valor: number;
	tipoEstoque: TipoEstoque;
}

export class Cliente extends DefaultFields<Cliente> {
	ultimaCompra: string;
	idCliente: any;
	nome: string;
	telefone: string;
	outrosTelefones: string[];
	outrosTelefones_container: { telefone: string, _uniqueId: string }[];
	codigoCliente: string;
	imagemUrl: string;
	email: string;
	genero: string;
	cpf: string;
	notificar: boolean;
	enderecos: Endereco[];
	enderecoDefault: Endereco;
	pedidos: Pedido[];
	tipoCliente: TipoCliente;
	dtAniversario: Date;
	prazoMaximo: number;
	prazoMaximo_formated: Date;
	perfil: String;
	idChamada: any;
	via: string;
	voip: boolean;

	tipoCFOP: TipoCFOP;

	vendasFiadas: VendaFiada[];

	tipoPagamentoBloqueado: TipoPagamento[];

	vendaAntecipadas: VendaAntecipada[];

	cnpj: string;
	nomeFantasia: string;
	inscricaoEstadual: any | number;
	inscricaoMunicipal: any | number;
	razaoSocial: string;
	nomeDistribuidora: string;
	produtoIdProduto: number;
	produtoGeralmenteComprado: any;
	contas: Conta[];
	produto: Produto;
	busca: boolean = false;
	dividasComodato: DividaComodato[];

	valorNegociados: ValorNegociado[];

	empresas: any;
	lojas: any;

	/** Request Params */
	saveValorNegociado: boolean;

	/** Template Vars */
	estabelecimento: Estabelecimento;
	alertas: ClienteAlerta[];
	alertaSelected: ClienteAlerta;

	atrasoPagamento: ClienteAlerta;
	showAtrasoPagamentoConfirm: boolean;
	atrasoPagamentoPassConfirm: string;

	_credito: number = 0;
}

export class ClienteAlerta {

	tipo: ClienteAlertaTipo;
	title: string;
	desc: string;
	pedido: Pedido;
	_showQuickInfoPopUp: boolean;
}

export enum ClienteAlertaTipo {

	INFO = <any>'info',
	WARNING = <any>'warning',
	DANGER = <any>'danger'
}
