import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { TokenStorage } from '../../auth/token-storage.service';
import { ServicePattern } from '../../models/interface/ServicePattern';
import { Empresa } from '../../models/api/Empresa';
import { Observable } from 'rxjs';
import { AppConfig } from '../../../config/appConfig';
import { ResultObject } from '../../models/api/ResultObject';
import { EstabelecimentoFunctions } from '../../models/interface/EstabelecimentoFunctions';
import { Loja } from '../../models/api/Loja';


@Injectable()
export class EmpresasService extends EstabelecimentoFunctions implements ServicePattern<Empresa> {

	constructor(private http: HttpClient, private tokenStorage: TokenStorage) {
		super();
	}

	getAll(
		dateFilter: { dataInicio: Date, dataFim: Date } = { dataInicio: null, dataFim: null },
		componentId: string = '',
		page: number = null,
		perPage: number = null,
		searchString: string = null,
		nome: string = null,
		cnpj: string = null,
		ativo: string = null,
		estadoAcronimo: string = null,
		sisContratados: string[] = null,
		plano: string = null,
		perfil: string = null,
		ultimaInteracao?: number,
		popup: boolean = false
	): Observable<Empresa[]> {
		return this.http.get<Empresa[]>(
			AppConfig.getAPIEndpoint() + '/empresas/list/' + this.tokenStorage.getUserInfo().idUsuario,
			{
				headers: AppConfig.getHTTPHeader().headers.set('componentId', componentId),
				params: {
					...this.getDateFilterQueryParam(dateFilter.dataInicio, dateFilter.dataFim),
					...(page || page == 0 ? { page: page + 1 } : {}),
					...(perPage ? { per_page: perPage } : {}),
					...(searchString != null ? { searchString: searchString } : {}),
					...(nome ? { nome } : {}),
					...(cnpj ? { cnpj } : {}),
					...(ativo ? { ativo } : {}),
					...(estadoAcronimo ? { estadoAcronimo } : {}),
					...((sisContratados || []).length > 0 ? { sisContratados: sisContratados.join(',') } : {}),
					...(plano ? { plano } : {}),
					...(perfil ? { perfil } : {}),
					...(ultimaInteracao ? { ultimaInteracao } : {}),
					popup: popup ? 'true' : 'false'
				}
			}
		);
	}

	get(idEmpresa: number, componentId: string = ''): Observable<Empresa> {
		return this.http.get<Empresa>(
			AppConfig.getAPIEndpoint() + '/empresas/' + idEmpresa + '/' + this.tokenStorage.getUserInfo().idUsuario,
			{
				headers: AppConfig.getHTTPHeader().headers.set('componentId', componentId),
			}
		);
	}

	getContagemPorEstados(componentId: string = ''): Observable<Empresa> {
		return this.http.get<Empresa>(
			AppConfig.getAPIEndpoint() + '/empresas/contagemPorEstados/' + this.tokenStorage.getUserInfo().idUsuario,
			{
				headers: AppConfig.getHTTPHeader().headers.set('componentId', componentId),
			}
		);
	}

	save(body: Empresa, componentId: string = ''): Observable<ResultObject<any>> {
		return this.http.post<ResultObject<any>>(
			AppConfig.getAPIEndpoint() + '/empresas/' + this.tokenStorage.getUserInfo().idUsuario,
			body,
			{
				headers: AppConfig.getHTTPHeader().headers.set('componentId', componentId)
			}
		);
	}

	update(body: Empresa, componentId: string = ''): Observable<ResultObject<any>> {
		return this.http.put<ResultObject<any>>(
			AppConfig.getAPIEndpoint() + '/empresas/' + this.tokenStorage.getUserInfo().idUsuario,
			body,
			{
				headers: AppConfig.getHTTPHeader().headers.set('componentId', componentId)
			}
		);
	}

	delete(idEmpresa: number, componentId: string = ''): Observable<ResultObject<any>> {
		return this.http.delete<ResultObject<any>>(
			AppConfig.getAPIEndpoint() + '/empresas/' + this.tokenStorage.getUserInfo().idUsuario + '/' +
			idEmpresa, {
			headers: AppConfig.getHTTPHeader().headers.set('componentId', componentId)
		}
		);
	}

	getEntriesCount(componentId: string = ''): Observable<{ entries: number }> {
		return this.http.get<{ entries: number }>(
			AppConfig.getAPIEndpoint() + '/empresas/list/' + this.tokenStorage.getUserInfo().idUsuario,
			{
				headers: AppConfig.getHTTPHeader().headers.set('componentId', componentId),
				params: {
					...this.getEstabelecimentoQueryParam(),
					entriesOnly: true
				}
			}
		);
	}

	salvaCertificado(body, componentId: string = ''): Observable<ResultObject<any>> {
		let formData = new FormData();
		formData.append('senhaCertificado', body.senhaCertificado);
		formData.append('certificado', body.arquivoCertificado[0]);
		return this.http.post<ResultObject<any>>(
			AppConfig.getAPIEndpoint() + '/empresas/upload_certificado/' + body.idEmpresa + '/' + this.tokenStorage.getUserInfo().idUsuario,
			formData,
			{
				headers: new HttpHeaders({
					'Cache-Control': 'no-cache',
					'componentId': componentId
				})
			}
		);
	}

	salvarToken(body: Empresa, componentId: string = ''): Observable<ResultObject<any>> {
		return this.http.post<ResultObject<any>>(
			AppConfig.getAPIEndpoint() + '/empresas/cadastrar_token/' + body.idEmpresa + '/' + this.tokenStorage.getUserInfo().idUsuario,
			body,
			{
				headers: AppConfig.getHTTPHeader().headers.set('componentId', componentId)
			}
		);
	}

	estabelecimentosSelecionados(empresas: string[], lojas?: string[], componentId: string = ''): Observable<(Empresa | Loja)[]> {
		return this.http.get<(Empresa | Loja)[]>(
			AppConfig.getAPIEndpoint() + '/empresas/estabelecimentos_selecionados/' + this.tokenStorage.getUserInfo().idUsuario,
			{
				params: {
					...(empresas ? { empresas: empresas.join(',') } : {}),
					...(lojas ? { lojas: lojas.join(',') } : {})
				},
				headers: AppConfig.getHTTPHeader().headers.set('componentId', componentId)
			}
		)
	}

	exigenciaBloqueio(est: string, tipo: 'empresa' | 'loja', exigirBloqueio: boolean, componentId = ''): Observable<any> {
		return this.http.put<ResultObject<any>>(
			AppConfig.getAPIEndpoint() + '/empresas/exigencia_bloqueio/' + this.tokenStorage.getUserInfo().idUsuario,
			{ est, tipo, exigirBloqueio },
			{
				headers: AppConfig.getHTTPHeader().headers.set('componentId', componentId)
			}
		);
	}

}
