import {Injectable} from '@angular/core';
import {ServicePattern} from '../../../models/interface/ServicePattern';
import {HttpClient} from '@angular/common/http';
import {Observable} from 'rxjs';
import {AppConfig} from '../../../../config/appConfig';
import {ResultObject} from '../../../models/api/ResultObject';
import {EstabelecimentoFunctions} from '../../../models/interface/EstabelecimentoFunctions';
import {Banco} from '../../../models/api/boleto/Banco';
import {$getUserInfo} from '../../../auth/token-storage.service';
import {DateFilter} from '../../../models/interface/DateFilter';
import {TipoEstabelecimento} from '../../../models/enum/TipoEstabelecimento';

export interface CodigosBanco {
	bancos: any;
}

@Injectable()
export class BancoService extends EstabelecimentoFunctions implements ServicePattern<Banco> {

	readonly ENDPOINT = `${AppConfig.getAPIEndpoint()}/banco`;

	constructor(private http: HttpClient) {
		super();
	}

	getAll(
		dateFilter: DateFilter = <DateFilter>{},
		componentId: string = '',
		page: number = null,
		perPage: number = null
	): Observable<Banco[]> {
		return this.http.get<Banco[]>(
			`${this.ENDPOINT}/${$getUserInfo().idUsuario}`,
			{
				headers: AppConfig.getHTTPHeader().headers.set('componentId', componentId),
				params: {
					...this.getEstabelecimentoQueryParam(),
					...this.getDateFilterQueryParam(dateFilter.dataInicio, dateFilter.dataFim),
					...(page || page == 0 ? { page: page + 1 } : {}),
					...(perPage ? { per_page: perPage } : {})
				}
			}
		);
	}

	get(idBanco: string, componentId: string = ''): Observable<Banco> {
		return this.http.get<Banco>(
			`${this.ENDPOINT}/${idBanco}/${$getUserInfo().idUsuario}`,
			{
				headers: AppConfig.getHTTPHeader().headers.set('componentId', componentId)
			}
		);
	}

	save(body: Banco, getEstabelecimentoFromCache: boolean = true, componentId: string = ''): Observable<ResultObject<any>> {
		if (getEstabelecimentoFromCache) body.estabelecimento = this.getEstabelecimentoOnCache();
		return this.http.post<ResultObject<any>>(
			`${this.ENDPOINT}/${$getUserInfo().idUsuario}`,
			{
				idEmpresa: body.estabelecimento.tipoEstabelecimento == TipoEstabelecimento.EMPRESA ? body.estabelecimento.empresa.idEmpresa : undefined,
				idLoja: body.estabelecimento.tipoEstabelecimento == TipoEstabelecimento.LOJA ? body.estabelecimento.loja.idLoja : undefined,
				...body
			},
			{
				headers: AppConfig.getHTTPHeader().headers.set('componentId', componentId),
			}
		);
	}

	update(body: Banco, componentId: string = ''): Observable<ResultObject<any>> {
		return this.http.put<ResultObject<any>>(
			`${this.ENDPOINT}/${body.idBanco}/${$getUserInfo().idUsuario}`,
			{
				idEmpresa: body.estabelecimento.tipoEstabelecimento == TipoEstabelecimento.EMPRESA ? body.estabelecimento.empresa.idEmpresa : undefined,
				idLoja: body.estabelecimento.tipoEstabelecimento == TipoEstabelecimento.LOJA ? body.estabelecimento.loja.idLoja : undefined,
				...body
			},
			{
				headers: AppConfig.getHTTPHeader().headers.set('componentId', componentId)
			}
		);
	}

	delete(idBanco: string, componentId: string = ''): Observable<ResultObject<any>> {
		return this.http.delete<ResultObject<any>>(
			`${this.ENDPOINT}/${idBanco}/${$getUserInfo().idUsuario}`,
			{
				headers: AppConfig.getHTTPHeader().headers.set('componentId', componentId)
			}
		);
	}

	getCodigoBancos(): Observable<CodigosBanco> {
		return this.http.get<CodigosBanco>(`${this.ENDPOINT}/codigos`);
	}

}
