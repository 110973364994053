import {SelectValue} from '../interface/SelectValue';

export enum TipoCliente {
	PESSOA_FISICA = '1',
	PESSOA_JURIDICA = '2',
	COMERCIO = '3',
	PRODUTOR_RURAL = '4'
}

export const TipoClienteSelectValues: SelectValue<TipoCliente>[] = [
	{ value: TipoCliente.PESSOA_FISICA, text: 'Pessoa Física' },
	{ value: TipoCliente.PESSOA_JURIDICA, text: 'Pessoa Jurídica ' },
	{ value: TipoCliente.COMERCIO, text: 'Comércio' },
	{ value: TipoCliente.PRODUTOR_RURAL, text: 'Produtor Rural' }
];

