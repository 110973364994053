import {Injectable} from '@angular/core';
import {EstabelecimentoFunctions} from '../../models/interface/EstabelecimentoFunctions';
import {ServicePattern} from '../../models/interface/ServicePattern';
import {HttpClient} from '@angular/common/http';
import {TokenStorage} from '../../auth/token-storage.service';
import {Observable} from 'rxjs';
import {AppConfig} from '../../../config/appConfig';
import {ResultObject} from '../../models/api/ResultObject';
import {Conta} from '../../models/api/Conta';
import {TipoConta} from '../../models/enum/TipoConta';
import {StatusConta} from '../../models/enum/StatusConta';
import {TipoPagamento} from '../../models/enum/TipoPagamento';
import {DateFilter} from '../../models/interface/DateFilter';
import { Parcela } from '../../models/api/Parcela';
import { ContaDespesa } from '../../models/api/ContaDespesa';

@Injectable()
export class ContasService extends EstabelecimentoFunctions implements ServicePattern<Conta> {

	constructor(private http: HttpClient, private tokenStorage: TokenStorage ) {
		super();
	}

	getAll(
			filter: {
				tipoConta?: TipoConta,
				status?: StatusConta | StatusConta[],
				tipoPagamento?: TipoPagamento,
				recebidoNaPortaria?: boolean,
				multiStatus?: boolean,
				contaDespesa?: ContaDespesa,
				relatorioDRE?: boolean,
			} = {
				tipoConta: null,
				status: null,
				tipoPagamento: null,
				
				contaDespesa: null
			},
		   	dateFilter: DateFilter = { dataInicio: null, dataFim: null, campo: null },
			page: number = null,
			perPage: number = null,
			componentId: string = '',
			multiDateFilter: DateFilter[] = null,
			searchString: string = null,
			dre: boolean = false,
			calcularValorTotal: boolean = false,
			outrosFiltros: any = {},
			pago: boolean = false,
			ordem = -1,
			mostrarNoFechamentoDeCaixa: boolean = false,
			sortBy: string = 'vencimento'
	): Observable<Conta[]> {

		let customParams = {};

		if (filter.tipoConta != null) customParams['tipo'] = filter.tipoConta;
		if (filter.contaDespesa != null) customParams['contaDespesa'] = filter.contaDespesa._id;
		if (filter.status != null) {
			if (filter.multiStatus && (!Array.isArray(filter.status) || (<any>filter.status || []).length > 0)) {
				customParams['status'] = Array.isArray(filter.status) ? filter.status.join(',') : filter.status;
			} else {
				customParams['status'] = filter.status;
			}
		}
		if (filter.tipoPagamento != null) customParams['tipoPagamento'] = filter.tipoPagamento;
		if (filter.recebidoNaPortaria != null) customParams['recebidoNaPortaria'] = filter.recebidoNaPortaria;

		let mDateFilter = null;
		if (multiDateFilter != null) {
			mDateFilter = {};
			for (let mf of multiDateFilter) {
				if (mf.campo != null && mf.dataInicio != null && mf.dataFim != null) {
					let dateFormated = this.getDateFilterWithFieldQueryParam(mf.campo, mf.dataInicio, mf.dataFim);
					mDateFilter[`${mf.campo}-dataInicio`] = dateFormated.dataInicio;
					mDateFilter[`${mf.campo}-dataFim`] = dateFormated.dataFim;
				}
			}
		}

		return this.http.get<Conta[]>(
			AppConfig.getAPIEndpoint() + '/contas/list/' + this.tokenStorage.getUserInfo().idUsuario,
			{
				headers: AppConfig.getHTTPHeader().headers.set('componentId', componentId),
				params: {
					...customParams,
					...this.getEstabelecimentoQueryParam(),
					...this.getDateFilterWithFieldQueryParam(dateFilter.campo, dateFilter.dataInicio, dateFilter.dataFim),
					...(page || page == 0 ? { page: page + 1 } : {}),
					...(perPage ? { per_page: perPage } : {}),
					...(mDateFilter ? mDateFilter : {}),
					...(searchString != null ? { searchString: searchString } : {}),
					...(dre != null ? { dre: dre } : {}),
					...(calcularValorTotal != null ? { calcularValorTotal: calcularValorTotal } : {}),
					...(filter.multiStatus != null ? { multiStatus: filter.multiStatus } : {}),
					outrosFiltros: JSON.stringify(outrosFiltros),
					...(pago ? {pago} : {}),
					ordem: String(ordem),
					mostrarNoFechamentoDeCaixa,
					sortBy
				}
			}
		);
	}

	get(idConta: number): Observable<Conta> {
		// TODO
		return null;
	}

	save(body: Conta, getEstabelecimentoFromCache: boolean = true, componentId: string = '', parcelas?: Parcela[]): Observable<ResultObject<any>> {
		if (getEstabelecimentoFromCache) { body.estabelecimento = this.getEstabelecimentoOnCache(); }
		return this.http.post<ResultObject<any>>(
			AppConfig.getAPIEndpoint() + '/contas/' + this.tokenStorage.getUserInfo().idUsuario,
			{
				...body,
				...(!!parcelas && parcelas.length ? { parcelas } : [])
			},
			{
				headers: AppConfig.getHTTPHeader().headers.set('componentId', componentId)
			}
		);
	}

	update(body: Conta, componentId: string = ''): Observable<ResultObject<any>> {
		return this.http.put<ResultObject<any>>(
			AppConfig.getAPIEndpoint() + '/contas/' + this.tokenStorage.getUserInfo().idUsuario,
			body,
			{
				headers: AppConfig.getHTTPHeader().headers.set('componentId', componentId)
			}
		);
	}

	delete(idConta: number, componentId: string = ''): Observable<ResultObject<any>> {
		return this.http.delete<ResultObject<any>>(
			AppConfig.getAPIEndpoint() + '/contas/' + this.tokenStorage.getUserInfo().idUsuario + '/' + idConta,
			{
				headers: AppConfig.getHTTPHeader().headers.set('componentId', componentId)
			}
		);
	}

	getEntriesCount(componentId: string = ''): Observable<{entries: number}> {
		return this.http.get<{entries: number}>(
			AppConfig.getAPIEndpoint() + '/contas/list/' + this.tokenStorage.getUserInfo().idUsuario,
			{
				headers: AppConfig.getHTTPHeader().headers.set('componentId', componentId),
				params: {
					...this.getEstabelecimentoQueryParam(),
					entriesOnly: true
				}
			}
		);
	}

}
