import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {TokenStorage} from '../../auth/token-storage.service';
import {AppConfig} from '../../../config/appConfig';
import {Observable} from 'rxjs';
import {ResultObject} from '../../models/api/ResultObject';
import {ServicePattern} from '../../models/interface/ServicePattern';
import {Venda} from '../../models/api/Venda';
import {EstabelecimentoFunctions} from '../../models/interface/EstabelecimentoFunctions';
import {PerfilCliente} from '../../models/enum/PerfilCliente';
import {VendaAntecipada} from '../../models/api/VendaAntecipada';
import {Cliente} from '../../models/api/Cliente';
import {Estabelecimento} from '../../models/template/Estabelecimento';
import {DateFilter} from '../../models/interface/DateFilter';
import {Entregador} from '../../models/api/Entregador';
import {TipoPagamento} from '../../models/enum/TipoPagamento';
import { StatusConta } from '../../models/enum/StatusConta';

export class VendasFilter {

	idEntregador: string;

	constructor(params: any = {}) {
		if (!params) params = {};
		this.idEntregador = params.idEntregador || null;
	}

}

@Injectable()
export class VendasService extends EstabelecimentoFunctions implements ServicePattern<Venda> {

	constructor(private http: HttpClient, private tokenStorage: TokenStorage) {
		super();
	}

	getAllByPerfil(
		dataInicio: string,
		dataFim: string,
		estabelecimentos: { empresa?: string, loja?: string, multiEmpresa?: string, multiLoja?: string } = null,
		componentId: string = '',
		perfil: PerfilCliente[]
	): Observable<any[]> {
		return this.http.get<any[]>(
			AppConfig.getAPIEndpoint() + '/relatorios/vendas/perfil-cliente/' + this.tokenStorage.getUserInfo().idUsuario,
			{
				headers: AppConfig.getHTTPHeader().headers.set('componentId', componentId),
				params: {
					...this.getEstabelecimentoQueryParam(),
					...(dataInicio ? {dataInicio} : {}),
					...(dataFim ? {dataFim} : {}),
					...(estabelecimentos ? estabelecimentos : {}),
					...(perfil ? {perfil: perfil.join(',')} : {})
				}
			}
		);
	}

	getAll(
		dateFilter: {
			dataInicio: Date,
			dataFim: Date,
			campo: string
		} = {dataInicio: null, dataFim: null, campo: null},
		populatePedidosInfo: boolean = false,
		componentId: string = '',
		vendasFilter: VendasFilter = new VendasFilter()
	): Observable<Venda[]> {

		return this.http.get<Venda[]>(
			AppConfig.getAPIEndpoint() + '/vendas/list/' + this.tokenStorage.getUserInfo().idUsuario,
			{
				headers: AppConfig.getHTTPHeader().headers.set('componentId', componentId),
				params: {
					...this.getEstabelecimentoQueryParam(),
					...this.getDateFilterWithFieldQueryParam(dateFilter.campo, dateFilter.dataInicio, dateFilter.dataFim),
					populatePedidosInfo: populatePedidosInfo,
					...(vendasFilter.idEntregador ? {idEntregador: vendasFilter.idEntregador} : {})
				}
			}
		);
	}

	get(id: number): Observable<Venda> {
		// TODO
		return null;
	}

	save(body: Venda): Observable<ResultObject<any>> {
		// TODO
		return null;
	}

	update(body: Venda): Observable<ResultObject<any>> {
		// TODO
		return null;
	}

	delete(id: number): Observable<ResultObject<any>> {
		// TODO
		return null;
	}

	getEntriesCount(componentId: string = ''): Observable<{ entries: number }> {
		return this.http.get<{ entries: number }>(
			AppConfig.getAPIEndpoint() + '/vendas/list/' + this.tokenStorage.getUserInfo().idUsuario,
			{
				headers: AppConfig.getHTTPHeader().headers.set('componentId', componentId),
				params: {
					...this.getEstabelecimentoQueryParam(),
					entriesOnly: true
				}
			}
		);
	}

	buscarVendasAntecipadasDeUmCliente(idCliente: string = null, componentId: string = ''): Observable<VendaAntecipada[]> {
		return this.http.get<VendaAntecipada[]>(
			`${AppConfig.getAPIEndpoint()}/vendas/antecipada/buscar/cliente/${this.tokenStorage.getUserInfo().idUsuario}/${idCliente}`,
			{ headers: AppConfig.getHTTPHeader().headers.set('componentId', componentId), }
		);
	}

	buscarVendasAntecipadasPorCliente(
		dateFilter: DateFilter = {},
		componentId: string = '',
		tipoVendaAntecipada: string = null,
		cliente: Cliente = null,
		estabelecimento?: Estabelecimento
	): Observable<any> {
		let idCliente = cliente != null ? cliente._id : null;
		return this.http.get<any>(
			`${AppConfig.getAPIEndpoint()}/vendas/antecipada/cliente/${this.tokenStorage.getUserInfo().idUsuario}`,
			{
				headers: AppConfig.getHTTPHeader().headers.set('componentId', componentId),
				params: {
					...this.getEstabelecimentoQueryParam(estabelecimento),
					...this.getDateFilterWithFieldQueryParam(dateFilter.campo, dateFilter.dataInicio, dateFilter.dataFim),
					...(tipoVendaAntecipada ? { tipo: tipoVendaAntecipada } : {}),
					...(idCliente ? { cliente: idCliente } : {}),
				}
			}
		);
	}

	toggleBloqueioFiado(empresa: string = null, loja: string = null, data: Date, componentId: string = ''): Observable<any> {
		return this.http.post<any>(
			AppConfig.getAPIEndpoint() + '/vendas/fiada/bloqueio/' + this.tokenStorage.getUserInfo().idUsuario,
			{
				empresa,
				loja,
				data
			},
			{
				headers: AppConfig.getHTTPHeader().headers.set('componentId', componentId)
			}
		);
	}

	buscarVendasFiadasPorCliente(
		dateFilter: DateFilter = {},
		componentId: string = '',
		cliente: Cliente = null,
		estabelecimento?: Estabelecimento,
		searchString: string = null,
		valor: string = null,
		numeroPedido: string = null,
		dataLancamentoInicial: string = null,
		dataLancamentoFinal: string = null
	): Observable<any> {
		let idCliente = cliente != null ? cliente._id : null;
		return this.http.get<any>(
			`${AppConfig.getAPIEndpoint()}/vendas/fiada/cliente/${this.tokenStorage.getUserInfo().idUsuario}`,
			{
				headers: AppConfig.getHTTPHeader().headers.set('componentId', componentId),
				params: {
					...this.getEstabelecimentoQueryParam(estabelecimento),
					...this.getDateFilterWithFieldQueryParam(dateFilter.campo, dateFilter.dataInicio, dateFilter.dataFim),
					...(dataLancamentoInicial ? { dataLancamentoInicial } : {}),
					...(dataLancamentoFinal ? { dataLancamentoFinal } : {}),
					...(idCliente ? { cliente: idCliente } : {}),
					...(searchString ? { searchString } : {}),
					...(valor ? { valor } : {}),
					...(numeroPedido ? { numeroPedido } : {})
				}
			}
		);
	}

	cadastrarVendaAntecipada(body: VendaAntecipada, componentId: string = ''): Observable<VendaAntecipada> {
		return this.http.post<VendaAntecipada>(
			`${AppConfig.getAPIEndpoint()}/vendas/antecipada/${this.tokenStorage.getUserInfo().idUsuario}`,
			body,
			{ headers: AppConfig.getHTTPHeader().headers.set('componentId', componentId) }
		);
	}

	cadastrarVendaFiada(empresa: string, loja: string, cliente: string, data: string, vencimento: string, valor: string, fatura: string, componentId: string = ''): Observable<VendaAntecipada> {
		return this.http.post<VendaAntecipada>(
			`${AppConfig.getAPIEndpoint()}/vendas/fiada/cadastrar/${this.tokenStorage.getUserInfo().idUsuario}`,
			{
				...(empresa ? { empresa } : {}),
				...(loja ? { loja } : {}),
				...(cliente ? { cliente } : {}),
				...(data ? { data } : {}),
				...(vencimento ? { vencimento } : {}),
				...(valor ? { valor } : {}),
				...(fatura ? { fatura } : {})
			},
			{ headers: AppConfig.getHTTPHeader().headers.set('componentId', componentId) }
		);
	}

	excluirVendaAntecipada(idVendaAntecipada: string, componentId: string = ''): Observable<VendaAntecipada> {
		return this.http.delete<VendaAntecipada>(
			`${AppConfig.getAPIEndpoint()}/vendas/antecipada/${this.tokenStorage.getUserInfo().idUsuario}/${idVendaAntecipada}`,
			{ headers: AppConfig.getHTTPHeader().headers.set('componentId', componentId) }
		);
	}

	confirmarPagamento(idPedido: string, tipoPagamento: TipoPagamento, valorPago: number, componentId: string = '', dataPagamento: Date, idUnico: string): Observable<VendaAntecipada> {
		return this.http.post<VendaAntecipada>(
			`${AppConfig.getAPIEndpoint()}/vendas/fiada/confirmar-pagamento/${this.tokenStorage.getUserInfo().idUsuario}`,
			{
				idPedido: idPedido,
				tipoPagamento: tipoPagamento,
				valorPago: valorPago,
				dataPagamento,
				...(idUnico ? { idUnico } : {})
			},
			{ headers: AppConfig.getHTTPHeader().headers.set('componentId', componentId) }
		);
	}

	reverterPagamento(idPedido: string, status: StatusConta, componentId: string = ''): Observable<VendaAntecipada> {
		return this.http.post<VendaAntecipada>(
			`${AppConfig.getAPIEndpoint()}/vendas/fiada/reverter-pagamento/${this.tokenStorage.getUserInfo().idUsuario}`,
			{
				idPedido,
				status
			},
			{ headers: AppConfig.getHTTPHeader().headers.set('componentId', componentId) }
		);
	}

}
