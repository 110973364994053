import {RouterModule} from '@angular/router';
import {Injectable, NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {NgbModule} from '@ng-bootstrap/ng-bootstrap';
import {QuickSidebarComponent} from './layout/quick-sidebar/quick-sidebar.component';
import {ScrollTopComponent} from './layout/scroll-top/scroll-top.component';
import {TooltipsComponent} from './layout/tooltips/tooltips.component';
import {ListSettingsComponent} from './layout/quick-sidebar/list-settings/list-settings.component';
import {MessengerModule} from './layout/quick-sidebar/messenger/messenger.module';
import {CoreModule} from '../../core/core.module';
import {ListNotificationModule} from './layout/quick-sidebar/list-timeline/list-notification.module';
import {PerfectScrollbarModule} from 'ngx-perfect-scrollbar';
import {NoticeComponent} from './content/general/notice/notice.component';
import {PortletModule} from './content/general/portlet/portlet.module';
import {SpinnerButtonModule} from './content/general/spinner-button/spinner-button.module';
import {BlogComponent} from './content/widgets/general/blog/blog.component';
import {FinanceStatsComponent} from './content/widgets/general/finance-stats/finance-stats.component';
import {PackagesComponent} from './content/widgets/general/packages/packages.component';
import {TasksComponent} from './content/widgets/general/tasks/tasks.component';
import {SupportTicketsComponent} from './content/widgets/general/support-tickets/support-tickets.component';
import {RecentActivitiesComponent} from './content/widgets/general/recent-activities/recent-activities.component';
import {RecentNotificationsComponent} from './content/widgets/general/recent-notifications/recent-notifications.component';
import {BestSellerComponent} from './content/widgets/general/best-seller/best-seller.component';
import {AuthorProfitComponent} from './content/widgets/general/author-profit/author-profit.component';
import {DataTableComponent} from './content/widgets/general/data-table/data-table.component';
import {WidgetChartsModule} from './content/widgets/charts/widget-charts.module';
import {StatComponent} from './content/widgets/general/stat/stat.component';

import {
	MatAutocompleteModule,
	MatButtonModule,
	MatCardModule,
	MatCheckboxModule,
	MatChipsModule,
	MatDatepickerModule,
	MatIconModule,
	MatInputModule,
	MatListModule,
	MatMenuModule,
	MatNativeDateModule,
	MatPaginatorModule,
	MatProgressBarModule,
	MatProgressSpinnerModule,
	MatRadioModule,
	MatSelectModule,
	MatSlideToggleModule,
	MatSnackBarModule,
	MatSortModule,
	MatTableModule,
	MatTabsModule,
	MatTooltipModule
} from '@angular/material';
import {SelecionarEstabelecimentoComponent} from './content/general/selecionar-estabelecimento/selecionar-estabelecimento.component';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {ConfirmModalComponent} from './content/general/confirm-modal/confirm-modal.component';
import {FiltroComponent} from './content/general/filtro/filtro.component';
import {SelecionarEntregadorComponent} from './content/general/selecionar-entregador/selecionar-entregador.component';
import {NgxMaskModule} from 'ngx-mask';
import {BuscarClienteComponent} from './buscar-cliente/buscar-cliente.component';
import {BuscarCidadeComponent} from './buscar-cidade/buscar-cidade.component';
import {GerenciarAssociacaoComponent} from './gerenciar-associacao/gerenciar-associacao.component';
import {OWL_DATE_TIME_LOCALE, OwlDateTimeIntl, OwlDateTimeModule, OwlNativeDateTimeModule} from 'ng-pick-datetime';
import { BuscarEnderecoComponent } from './buscar-endereco/buscar-endereco.component';
import { FaturasComponent } from './faturas/faturas.component';

// here is the default text string
@Injectable()
export class DefaultIntl extends OwlDateTimeIntl {

	/** A label for the cancel button */
	cancelBtnLabel = 'Cancelar';

	/** A label for the set button */
	setBtnLabel = 'Aplicar';
}

@NgModule({
	declarations: [
		QuickSidebarComponent,
		ScrollTopComponent,
		TooltipsComponent,
		ListSettingsComponent,
		NoticeComponent,
		BlogComponent,
		FinanceStatsComponent,
		PackagesComponent,
		TasksComponent,
		SupportTicketsComponent,
		RecentActivitiesComponent,
		RecentNotificationsComponent,
		BestSellerComponent,
		AuthorProfitComponent,
		DataTableComponent,
		StatComponent,

		/**
		 * VENDERGAS
		 */
		SelecionarEstabelecimentoComponent,
		SelecionarEntregadorComponent,
		ConfirmModalComponent,
		FiltroComponent,
		BuscarClienteComponent,
		BuscarCidadeComponent,
		BuscarEnderecoComponent,
		GerenciarAssociacaoComponent,
		FaturasComponent
	],
	exports: [
		QuickSidebarComponent,
		ScrollTopComponent,
		TooltipsComponent,
		ListSettingsComponent,
		NoticeComponent,
		BlogComponent,
		FinanceStatsComponent,
		PackagesComponent,
		TasksComponent,
		SupportTicketsComponent,
		RecentActivitiesComponent,
		RecentNotificationsComponent,
		BestSellerComponent,
		AuthorProfitComponent,
		DataTableComponent,
		StatComponent,

		PortletModule,
		SpinnerButtonModule,

		/**
		 * VENDERGAS
		 */
		SelecionarEstabelecimentoComponent,
		SelecionarEntregadorComponent,
		ConfirmModalComponent,
		FiltroComponent,
		BuscarClienteComponent,
		BuscarCidadeComponent,
		BuscarEnderecoComponent,
		GerenciarAssociacaoComponent,
		FaturasComponent
	],
	imports: [
		CommonModule,
		RouterModule,
		NgbModule,
		PerfectScrollbarModule,
		MessengerModule,
		ListNotificationModule,
		CoreModule,
		PortletModule,
		SpinnerButtonModule,
		FormsModule,
		ReactiveFormsModule,

		/* Material */
		MatButtonModule,
		MatMenuModule,
		MatSelectModule,
		MatInputModule,
		MatTableModule,
		MatAutocompleteModule,
		MatRadioModule,
		MatIconModule,
		MatNativeDateModule,
		MatProgressBarModule,
		MatDatepickerModule,
		MatCardModule,
		MatPaginatorModule,
		MatSortModule,
		MatCheckboxModule,
		MatProgressSpinnerModule,
		MatSnackBarModule,
		MatTabsModule,
		MatTooltipModule,
		MatSlideToggleModule,
		MatChipsModule,
		MatListModule,
		MatCheckboxModule,
		/* */

		NgxMaskModule.forRoot(),

		//AgmCoreModule.forRoot({ apiKey: AppConfig.GOOGLE_MAPS_API_KEY }),

		OwlDateTimeModule,
		OwlNativeDateTimeModule,

		WidgetChartsModule
	],
	providers: [
		{provide: OwlDateTimeIntl, useClass: DefaultIntl},
		{provide: OWL_DATE_TIME_LOCALE, useValue: 'pt-BR'},
	]
})
export class PartialsModule {}
