import {Injectable} from '@angular/core';
import {EstabelecimentoFunctions} from '../../models/interface/EstabelecimentoFunctions';
import {ServicePattern} from '../../models/interface/ServicePattern';
import {HttpClient} from '@angular/common/http';
import {TokenStorage} from '../../auth/token-storage.service';
import {Observable} from 'rxjs';
import {AppConfig} from '../../../config/appConfig';
import {ResultObject} from '../../models/api/ResultObject';
import moment from 'moment';
import { AberturaCaixa } from '../../models/api/AberturaCaixa';

@Injectable()
export class AberturaCaixaService extends EstabelecimentoFunctions implements ServicePattern<AberturaCaixa> {

	constructor(private http: HttpClient, private tokenStorage: TokenStorage ) {
		super();
	}

	getAll(
		ests: string,
		dataInicio: Date,
		dataFim: Date,
		componentId = ''
	): Observable<any> {
		return this.http.get<any>(
			AppConfig.getAPIEndpoint() + '/financeiro/aberturaCaixa/' + this.tokenStorage.getUserInfo().idUsuario,
			{
				headers: AppConfig.getHTTPHeader().headers.set('componentId', componentId),
				params: {
					ests,
					dataInicio: moment(dataInicio).toISOString(),
					dataFim: moment(dataFim).toISOString()
				}
			}
		);
	}

	save(body: AberturaCaixa, increment: boolean, componentId: string = ''): Observable<ResultObject<any>> {
		return this.http.post<ResultObject<any>>(
			AppConfig.getAPIEndpoint() + '/financeiro/aberturaCaixa/' + this.tokenStorage.getUserInfo().idUsuario,
			{
				...body,
				increment
			},
			{
				headers: AppConfig.getHTTPHeader().headers.set('componentId', componentId)
			}
		);
	}

	obterBloqueiosEntrada(empresas: string = '', lojas: string = '', componentId: string = ''): Observable<any> {
		return this.http.get<any>(
			AppConfig.getAPIEndpoint() + '/financeiro/bloqueios/' + this.tokenStorage.getUserInfo().idUsuario,
			{
				headers: AppConfig.getHTTPHeader().headers.set('componentId', componentId),
				params: {
					empresas,
					lojas,
				}
			}
		);
	}

	toggleBloqueioEntrada(empresa: string = null, loja: string = null, data: Date, componentId: string = ''): Observable<any> {
		return this.http.post<any>(
			AppConfig.getAPIEndpoint() + '/financeiro/bloqueios/' + this.tokenStorage.getUserInfo().idUsuario,
			{
				empresa,
				loja,
				data
			},
			{
				headers: AppConfig.getHTTPHeader().headers.set('componentId', componentId)
			}
		);
	}

	historicoBloqueiosEntrada(est: string, page: number, per_page: number, componentId = ''): Observable<any> {
		return this.http.get<any>(
			AppConfig.getAPIEndpoint() + '/financeiro/historico_bloqueios/' + this.tokenStorage.getUserInfo().idUsuario,
			{
				headers: AppConfig.getHTTPHeader().headers.set('componentId', componentId),
				params: {
					page: page.toString(),
					per_page: per_page.toString(),
					est
				}
			}
		);
	}

}
