import { Injectable } from '@angular/core';
import { EstabelecimentoFunctions } from '../../../models/interface/EstabelecimentoFunctions';
import { HttpClient } from '@angular/common/http';
import { TokenStorage } from '../../../auth/token-storage.service';
import { Observable } from 'rxjs';
import { AppConfig } from '../../../../config/appConfig';
import { ServicePattern } from '../../../models/interface/ServicePattern';
import { ResultObject } from '../../../models/api/ResultObject';
import { DateFilter } from '../../../models/interface/DateFilter';
import { TipoNotaFiscal } from '../../../models/enum/notaFiscal/TipoNotaFiscal';
import { Entregador } from '../../../models/api/Entregador';
import { Estabelecimento } from '../../../models/template/Estabelecimento';
import { NotaCoberturaResponse } from '../../../models/api/NotaCobertura';
import { Cliente } from '../../../models/api/Cliente';
import { ProtocoloInvalidacaoFaixa } from '../../../models/api/ProtocoloInvalidacaoFaixa';
import { RegraContingencia } from '../../../models/api/RegraContingencia';
interface NotaFiscalResponse {
	estabelecimento: string;
	tipo: string;
	naturezaOperacao: string;
	dtemissao: string;
	situacao: string;
	valorTotal: number;
	nomedestinatario: string;
	serie: string;
	numero: string;
	url: string;
	codnf: string;
	chaveNota: string;
	tipoEntradaSaida: string;
	xml_tipoEntradaSaida: string;
	cnpj: string;
	tipoNota: string;
}

@Injectable()
export class NotaFiscalService extends EstabelecimentoFunctions implements ServicePattern<any> {

	private readonly ROUTE = '/notaFiscal';

	constructor(private http: HttpClient, private tokenStorage: TokenStorage) {
		super();
	}

	listarNotasRapido(est: string, dtInicio: string, dtFim: string, componentId: string = ''): Observable<any[]> {
		return this.http.get<any[]>(
			AppConfig.getAPIEndpoint() + this.ROUTE + '/list_rapido/' + this.tokenStorage.getUserInfo().idUsuario,
			{
				headers: AppConfig.getHTTPHeader().headers.set('componentId', componentId),
				params: {
					est,
					dtInicio,
					dtFim
				}
			}
		)
	}

	listarNotasTecnospeed(filtros: any, componentId: string = ''): Observable<any[]> {
		return this.http.get<any[]>(
			AppConfig.getAPIEndpoint() + this.ROUTE + '/list/tecnospeed/' + this.tokenStorage.getUserInfo().idUsuario,
			{
				headers: AppConfig.getHTTPHeader().headers.set('componentId', componentId),
				params: filtros
			}
		)
	}

	consultarNotaTecnospeed(tipoNota: string, cnpj: string, chave: string, componentId: string = ''): Observable<any[]> {
		return this.http.get<any[]>(
			AppConfig.getAPIEndpoint() + this.ROUTE + '/consultar/tecnospeed/' + this.tokenStorage.getUserInfo().idUsuario,
			{
				headers: AppConfig.getHTTPHeader().headers.set('componentId', componentId),
				params: {
					tipoNota,
					cnpj,
					chave
				}
			}
		)
	}

	getAll(
		dateFilter: DateFilter = { dataInicio: null, dataFim: null },
		componentId: string = '',
		xml: boolean = false,
		situacao: 'AUTORIZADA' | 'CANCELADA' | 'ENVIADA' | 'REGISTRADA' | 'REJEITADA' | 'INUTILIZADA' | 'RECEBIDA' | 'DENEGADA' | 'CONTINGENCIA' = null,

		ignoreEstabelecimentoOnCache: boolean = false,
		estabelecimentos: { empresa?: string, loja?: string, multiEmpresa?: string, multiLoja?: string } = null,
		estabelecimentoOnParam: boolean = false,

		tipoNF: 'nfe' | 'nfce' | 'CFeSat' = null,
		operacaoFiscal: string = null,
		tipoEntradaSaida: TipoNotaFiscal = null,
		rejeitadas: boolean = false
	): Observable<any[]> {
		return this.http.get<any[]>(
			AppConfig.getAPIEndpoint() + this.ROUTE + '/list/' + this.tokenStorage.getUserInfo().idUsuario,
			{
				headers: AppConfig.getHTTPHeader().headers.set('componentId', componentId),
				params: {
					// ...this.getEstabelecimentoQueryParam(),
					rejeitadas,
					...(
						ignoreEstabelecimentoOnCache == true && estabelecimentoOnParam != true ?
							(estabelecimentos != null ? estabelecimentos : {}) :
							this.getEstabelecimentoQueryParam(ignoreEstabelecimentoOnCache == true ? estabelecimentos : undefined)
					),
					...this.getDateFilterQueryParam(dateFilter.dataInicio, dateFilter.dataFim),
					...(xml != null ? { xml: xml } : {}),
					...(situacao != null ? { situacao: situacao } : {}),
					...(tipoNF != null ? { tipoNF: tipoNF } : {}),
					...(operacaoFiscal != null ? { operacaoFiscal: operacaoFiscal } : {}),
					...(tipoEntradaSaida != null ? { tipoEntradaSaida: tipoEntradaSaida } : {}),
				}
			}
		);
	}

	getAllCte(
		dateFilter: DateFilter = { dataInicio: null, dataFim: null },
		componentId: string = '',
		situacao: 'AUTORIZADA' | 'CANCELADA' | 'ENVIADA' | 'REGISTRADA' | 'REJEITADA' | 'INUTILIZADA' | 'RECEBIDA' | 'DENEGADA' | 'CONTINGENCIA' = null,

		ignoreEstabelecimentoOnCache: boolean = false,
		estabelecimentos: { empresa?: string, loja?: string, multiEmpresa?: string, multiLoja?: string } = null,
		estabelecimentoOnParam: boolean = false,

		operacaoFiscal: string = null,
		tipoEntradaSaida: TipoNotaFiscal = null
	): Observable<any[]> {
		return this.http.get<any[]>(
			AppConfig.getAPIEndpoint() + this.ROUTE + '/cte/list/' + this.tokenStorage.getUserInfo().idUsuario,
			{
				headers: AppConfig.getHTTPHeader().headers.set('componentId', componentId),
				params: {
					tipoNF: 'cte',
					...(
						ignoreEstabelecimentoOnCache == true && estabelecimentoOnParam != true ?
							(estabelecimentos != null ? estabelecimentos : {}) :
							this.getEstabelecimentoQueryParam(ignoreEstabelecimentoOnCache == true ? estabelecimentos : undefined)
					),
					...this.getDateFilterQueryParam(dateFilter.dataInicio, dateFilter.dataFim),
					...(situacao != null ? { situacao: situacao } : {}),
					...(operacaoFiscal != null ? { operacaoFiscal: operacaoFiscal } : {}),
					...(tipoEntradaSaida != null ? { tipoEntradaSaida: tipoEntradaSaida } : {})
				}
			}
		);
	}

	importarXML(idEmpresa: string, idLoja: string, arquivos: FileList, componentId: string = '', tipoNota?: 'nfe' | 'nfce' | 'mdfe' | 'cte' | 'CFeSat'): Observable<any> {
		const formData = new FormData();

		if (idEmpresa) formData.append('idEmpresa', idEmpresa);

		if (idLoja) formData.append('idLoja', idLoja);

		for (const arquivo of Array.from(arquivos)) formData.append('arquivos', arquivo);

		if (tipoNota) formData.append('tipoNota', tipoNota);

		return this.http.post<any>(
			AppConfig.getAPIEndpoint() + this.ROUTE + '/importar_xml/' + this.tokenStorage.getUserInfo().idUsuario,
			formData
		);
	}

	downloadLoteXML(idEmpresa, idLoja, tipoNota: 'nfe' | 'nfce' | 'mdfe', dataInicio, dataFim, operacaoFiscal = null, nomeProduto = null, situacao: string = null) {
		return this.http.get<any>(
			AppConfig.getAPIEndpoint() + this.ROUTE + '/lote/xml/' + this.tokenStorage.getUserInfo().idUsuario,
			<any>{
				responseType: 'blob',
				params: {
					...(idEmpresa ? { idEmpresa } : {}),
					...(idLoja ? { idLoja } : {}),
					...(tipoNota ? { tipoNota } : {}),
					...(operacaoFiscal ? { operacaoFiscal } : {}),
					...this.getDateFilterQueryParam(dataInicio, dataFim),
					...(nomeProduto ? { nomeProduto } : {}),
					...(situacao ? { situacao } : {})
				}
			}
		);
	}

	gerarProtocoloExportacao(
		body: {
			est: string,
			tipoNota: string,
			dataInicial: Date,
			dataFinal: Date,
			pdf: boolean,
			serie: number,
			numeroInicial: number,
			numeroFinal: number
		},
		componentId: string = ''
	) {
		return this.http.post<any>(
			AppConfig.getAPIEndpoint() + this.ROUTE + '/gerar_protocolo/' + this.tokenStorage.getUserInfo().idUsuario,
			body,
			{
				headers: AppConfig.getHTTPHeader().headers.set('componentId', componentId),
			}
		);
	}

	consultarProtocoloExportacao(
		body: {
			est: string,
			tipoNota: string,
			protocolo: string
		},
		componentId: string = ''
	) {
		return this.http.get<any>(
			AppConfig.getAPIEndpoint() + this.ROUTE + '/consultar_protocolo/' + this.tokenStorage.getUserInfo().idUsuario,
			{
				headers: AppConfig.getHTTPHeader().headers.set('componentId', componentId),
				params: body
			}
		);
	}

	baixarXMLProtocoloExportacao(
		body: {
			est: string,
			tipoNota: string,
			protocolo: string
		},
		componentId: string = ''
	) {
		return this.http.get<any>(
			AppConfig.getAPIEndpoint() + this.ROUTE + '/baixar_xml_protocolo/' + this.tokenStorage.getUserInfo().idUsuario,
			<any>{
				responseType: 'blob',
				headers: AppConfig.getHTTPHeader().headers.set('componentId', componentId),
				params: body
			}
		);
	}

	baixarPDFProtocoloExportacao(
		body: {
			est: string,
			tipoNota: string,
			protocolo: string
		},
		componentId: string = ''
	) {
		return this.http.get<any>(
			AppConfig.getAPIEndpoint() + this.ROUTE + '/baixar_pdf_protocolo/' + this.tokenStorage.getUserInfo().idUsuario,
			<any>{
				responseType: 'blob',
				headers: AppConfig.getHTTPHeader().headers.set('componentId', componentId),
				params: body
			}
		);
	}

	listarProtocolosExportacao(
		body: {
			est: string
		},
		componentId: string = ''
	) {
		return this.http.get<any>(
			AppConfig.getAPIEndpoint() + this.ROUTE + '/listar_protocolos/' + this.tokenStorage.getUserInfo().idUsuario,
			{
				headers: AppConfig.getHTTPHeader().headers.set('componentId', componentId),
				params: body
			}
		);
	}

	downloadPDF(idEmpresa: string, idLoja: string, chaveNota: string, tipoNota: 'nfe' | 'nfce' | 'mdfe' | 'cte', forceDownload: boolean = false) {
		return this.http.get<any>(
			AppConfig.getAPIEndpoint() + this.ROUTE + '/download/pdf/' + this.tokenStorage.getUserInfo().idUsuario,
			<any>{
				responseType: 'blob',
				params: {
					...(idEmpresa ? { idEmpresa } : {}),
					...(idLoja ? { idLoja } : {}),
					forceDownload,
					tipoNota: tipoNota,
					chaveNota: chaveNota
				}
			}
		);
	}

	downloadXMLFile(idEmpresa: string, idLoja: string, chaveNota: string, tipoNota: 'nfe' | 'nfce' | 'mdfe' | 'cte' | 'cfesat', componentId: string = '') {
		return this.http.get<any>(
			AppConfig.getAPIEndpoint() + this.ROUTE + '/download/xml/' + this.tokenStorage.getUserInfo().idUsuario,
			{
				headers: AppConfig.getHTTPHeader().headers.set('componentId', componentId),
				params: {
					...(idEmpresa ? { idEmpresa } : {}),
					...(idLoja ? { idLoja } : {}),
					tipoNota: tipoNota,
					chaveNota: chaveNota
				}
			}
		);
	}

	invalidaFaixa(body: any, componentId: string = ''): Observable<ProtocoloInvalidacaoFaixa> {
		return this.http.post<ProtocoloInvalidacaoFaixa>(
			AppConfig.getAPIEndpoint() + this.ROUTE + '/invalidar/' + this.tokenStorage.getUserInfo().idUsuario,
			body,
			{
				headers: AppConfig.getHTTPHeader().headers.set('componentId', componentId),
				params: {}
			}
		);
	}

	buscarProtocolosDeInvalidacao(page: number = null, perPage: number = null, componentId: string = ''): Observable<ProtocoloInvalidacaoFaixa[]> {
		return this.http.get<ProtocoloInvalidacaoFaixa[]>(
			AppConfig.getAPIEndpoint() + this.ROUTE + '/protoclos/inutilizacao/' + this.tokenStorage.getUserInfo().idUsuario,
			{
				headers: AppConfig.getHTTPHeader().headers.set('componentId', componentId),
				params: {
					...this.getEstabelecimentoQueryParam(),
					...(page || page == 0 ? { page: page + 1 } : {}),
					...(perPage ? { per_page: perPage } : {})
				}
			}
		);
	}

	get(id: number | string, ...args): Observable<any> {
		// TODO
		return null;
	}

	save(body: any, ...args): Observable<ResultObject<any>> {
		// TODO
		return null;
	}

	delete(id: number | string, ...args): Observable<ResultObject<any>> {
		// TODO
		return null;
	}

	update(body: any, ...args): Observable<ResultObject<any>> {
		// TODO
		return null;
	}

	emitirCte(body, componentId: string = ''): Observable<{ url: string }> {
		return this.http.post<{ url: string }>(
			AppConfig.getAPIEndpoint() + this.ROUTE + '/emitir_cte/' + this.tokenStorage.getUserInfo().idUsuario,
			body,
			{
				headers: AppConfig.getHTTPHeader().headers.set('componentId', componentId),
				params: {}
			}
		);
	}

	carregarConfigCTE(idEmpresa, idLoja, componentId: string = ''): Observable<any> {
		return this.http.get<any>(
			AppConfig.getAPIEndpoint() + this.ROUTE + '/carregar_config_cte/' + this.tokenStorage.getUserInfo().idUsuario,
			{
				headers: AppConfig.getHTTPHeader().headers.set('componentId', componentId),
				params: {
					...(idEmpresa ? { idEmpresa } : {}),
					...(idLoja ? { idLoja } : {})
				}
			}
		);
	}

	carregarConfigMDFE(idEmpresa, idLoja, componentId: string = ''): Observable<any> {
		return this.http.get<any>(
			AppConfig.getAPIEndpoint() + this.ROUTE + '/carregar_config_mdfe/' + this.tokenStorage.getUserInfo().idUsuario,
			{
				headers: AppConfig.getHTTPHeader().headers.set('componentId', componentId),
				params: {
					...(idEmpresa ? { idEmpresa } : {}),
					...(idLoja ? { idLoja } : {})
				}
			}
		);
	}

	emitirNota(body, componentId: string = ''): Observable<{ url: string }> {
		return this.http.post<{ url: string }>(
			AppConfig.getAPIEndpoint() + this.ROUTE + '/emitir_nota/' + this.tokenStorage.getUserInfo().idUsuario,
			body,
			{
				headers: AppConfig.getHTTPHeader().headers.set('componentId', componentId),
				params: {}
			}
		);
	}

	consultarNota(
		chave: string,
		cnpj: string,
		tipoNota: 'nfe' | 'nfce',
		componentId: string = '',
		idEmpresa?: string,
		idLoja?: string,
		urlOnly: boolean = true
	): Observable<NotaFiscalResponse> {
		return this.http.get<NotaFiscalResponse>(
			AppConfig.getAPIEndpoint() + this.ROUTE + '/consultar/' + this.tokenStorage.getUserInfo().idUsuario,
			{
				headers: AppConfig.getHTTPHeader().headers.set('componentId', componentId),
				params: {
					...(cnpj ? { cnpj: cnpj } : {}),
					...(chave ? { chave: chave } : {}),
					...(tipoNota ? { tipoNota: tipoNota } : {}),
					...(idEmpresa ? { idEmpresa: idEmpresa } : {}),
					...(idLoja ? { idLoja: idLoja } : {}),
					urlOnly: urlOnly
				} as any
			}
		);
	}

	obterCertificado(idEmpresa: string = null, idLoja: string = null, componentId: string): Observable<{ nome: string, vencimento: string }> {
		return this.http.get<{ nome: string, vencimento: string }>(
			AppConfig.getAPIEndpoint() + this.ROUTE + '/obter_certificado/' + this.tokenStorage.getUserInfo().idUsuario,
			{
				headers: AppConfig.getHTTPHeader().headers.set('componentId', componentId),
				params: {
					...(idEmpresa ? { idEmpresa } : {}),
					...(idLoja ? { idLoja } : {}),
				}
			}
		);
	}

	obterTodosCertificado(cnpjs: string[], componentId: string): Observable<any[]> {
		return this.http.get<any[]>(
			AppConfig.getAPIEndpoint() + this.ROUTE + '/obter_todos_certificados/' + this.tokenStorage.getUserInfo().idUsuario,
			{
				headers: AppConfig.getHTTPHeader().headers.set('componentId', componentId),
				params: {
					cnpjs: cnpjs.join(',')
				}
			}
		);
	}

	obterCSC(idEmpresa: string = null, idLoja: string = null, componentId: string): Observable<{ token: string, id: string }> {
		return this.http.get<{ token: string, id: string }>(
			AppConfig.getAPIEndpoint() + this.ROUTE + '/obter_csc/' + this.tokenStorage.getUserInfo().idUsuario,
			{
				headers: AppConfig.getHTTPHeader().headers.set('componentId', componentId),
				params: {
					...(idEmpresa ? { idEmpresa } : {}),
					...(idLoja ? { idLoja } : {}),
				}
			}
		);
	}

	cancelarNota(body: { justificativa: string, chave: string, tipoNota: 'CFeSat' | 'cte' | 'nfe' | 'nfce' | 'mdfe', idEmpresa: string, idLoja: string, cancelarBoleto?: boolean, fuso?: String }, componentId: string = ''): Observable<any> {
		return this.http.post<any>(
			AppConfig.getAPIEndpoint() + this.ROUTE + '/cancelar_nota/' + this.tokenStorage.getUserInfo().idUsuario,
			body,
			{
				headers: AppConfig.getHTTPHeader().headers.set('componentId', componentId),
				params: {}
			}
		);
	}

	cancelarNotaDB(body: { chave: string, cancelarBoleto?: boolean }, componentId: string = ''): Observable<any> {
		return this.http.post<any>(
			AppConfig.getAPIEndpoint() + this.ROUTE + '/cancelar_nota_db/' + this.tokenStorage.getUserInfo().idUsuario,
			body,
			{
				headers: AppConfig.getHTTPHeader().headers.set('componentId', componentId),
				params: {}
			}
		);
	}

	descartarNota(body: { chave: string, tipoNota: 'cte' | 'nfe' | 'nfce' | 'mdfe', idEmpresa: string, idLoja: string }, componentId: string = ''): Observable<any> {
		return this.http.post<any>(
			AppConfig.getAPIEndpoint() + this.ROUTE + '/descartar_nota/' + this.tokenStorage.getUserInfo().idUsuario,
			body,
			{
				headers: AppConfig.getHTTPHeader().headers.set('componentId', componentId),
				params: {}
			}
		);
	}

	resolverNota(body: { chave: string, tipoNota: 'cte' | 'nfe' | 'nfce' | 'mdfe', idEmpresa: string, idLoja: string }, componentId: string = ''): Observable<any> {
		return this.http.post<any>(
			AppConfig.getAPIEndpoint() + this.ROUTE + '/resolver_nota/' + this.tokenStorage.getUserInfo().idUsuario,
			body,
			{
				headers: AppConfig.getHTTPHeader().headers.set('componentId', componentId),
				params: {}
			}
		);
	}

	emitirCCe(chave: string, orgao: string, seqEvento: string, correcao: string, lote: string, cnpj: string, componentId: string = ''): Observable<any> {
		return this.http.post<any>(
			AppConfig.getAPIEndpoint() + this.ROUTE + '/emitir_cce/' + this.tokenStorage.getUserInfo().idUsuario,
			{
				chave,
				orgao,
				seqEvento,
				correcao,
				lote,
				cnpj
			},
			{
				headers: AppConfig.getHTTPHeader().headers.set('componentId', componentId)
			}
		);
	}

	emitirMDe(tipo: string, chave: string, cnpj: string, componentId: string = '', justificativa: string): Observable<any> {
		return this.http.post<any>(
			AppConfig.getAPIEndpoint() + this.ROUTE + '/emitir_mde/' + this.tokenStorage.getUserInfo().idUsuario,
			{
				tipo,
				chave,
				cnpj,
				justificativa
			},
			{
				headers: AppConfig.getHTTPHeader().headers.set('componentId', componentId)
			}
		);
	}

	emitirMDFe(body: any, componentId: string = '') {
		return this.http.post<any>(
			AppConfig.getAPIEndpoint() + this.ROUTE + '/emitir_mdfe/' + this.tokenStorage.getUserInfo().idUsuario,
			body,
			{
				headers: AppConfig.getHTTPHeader().headers.set('componentId', componentId)
			}
		);
	}

	listarEventos(chave: string, cnpj: string, componentId: string = '', tipo?: 'cte' | any): Observable<any[]> {
		return this.http.get<any[]>(
			AppConfig.getAPIEndpoint() + this.ROUTE + '/listar_eventos/' + this.tokenStorage.getUserInfo().idUsuario,
			{
				headers: AppConfig.getHTTPHeader().headers.set('componentId', componentId),
				params: {
					chave,
					cnpj,
					...(tipo != null ? { tipo: tipo } : {})
				}
			}
		);
	}

	baixarEvento(chave: string, cnpj: string, id: string, documento: string, componentId: string = ''): Observable<any[]> {
		return this.http.get<any[]>(
			AppConfig.getAPIEndpoint() + this.ROUTE + '/baixar_evento/' + this.tokenStorage.getUserInfo().idUsuario,
			{
				headers: AppConfig.getHTTPHeader().headers.set('componentId', componentId),
				params: { chave, cnpj, id, documento }
			}
		);
	}

	buscarNotasCobertura(
		componentId = '',
		dateFilter: DateFilter = {},
		entregador: Entregador = null,
		cliente: Cliente = null,
		estabelecimento?: Estabelecimento,
		incluir_boleto?: boolean
	): Observable<NotaCoberturaResponse> {
		let idEntregador = entregador != null ? entregador._id || entregador.idEntregador : null;
		let idCliente = cliente != null ? cliente._id || cliente.idCliente : null;
		return this.http.get<NotaCoberturaResponse>(
			`${AppConfig.getAPIEndpoint()}/notaCobertura/list/${this.tokenStorage.getUserInfo().idUsuario}`,
			{
				headers: AppConfig.getHTTPHeader().headers.set('componentId', componentId),
				params: {
					...this.getEstabelecimentoQueryParam(estabelecimento),
					...this.getDateFilterWithFieldQueryParam(dateFilter.campo, dateFilter.dataInicio, dateFilter.dataFim),
					...(idEntregador ? { entregador: idEntregador } : {}),
					...(idCliente ? { cliente: idCliente } : {}),
					...(incluir_boleto != null ? { incluir_boleto: incluir_boleto } : {})
				}
			}
		);
	}

	listarMDFes(multiEmpresa?: string, multiLoja?: string, limite?: number, dataInicio?: Date, dataFim?: Date, componentId: string = ''): Observable<any[]> {
		return this.http.get<any[]>(
			`${AppConfig.getAPIEndpoint()}${this.ROUTE}/listar_mdfes/${this.tokenStorage.getUserInfo().idUsuario}`,
			{
				headers: AppConfig.getHTTPHeader().headers.set('componentId', componentId),
				params: {
					...(multiEmpresa ? { multiEmpresa } : {}),
					...(multiLoja ? { multiLoja } : {}),
					...(limite ? { limite: limite.toString() } : {}),
					...(dataInicio ? { dataInicio: dataInicio.toString() } : {}),
					...(dataFim ? { dataFim: dataFim.toString() } : {})
				}
			}
		);
	}

	encerrarMDFe(cnpj: string, chave: string, protocolo: string, codigoUF: string, codigoMunicipio: string, componentId: string = ''): Observable<any> {
		return this.http.post<any>(
			`${AppConfig.getAPIEndpoint()}${this.ROUTE}/encerrar_mdfe/${this.tokenStorage.getUserInfo().idUsuario}`,
			{ cnpj, chave, protocolo, codigoUF, codigoMunicipio },
			{
				headers: AppConfig.getHTTPHeader().headers.set('componentId', componentId)
			}
		);
	}

	sendMail(chave: string, tipoNota: string, to: string, dataEmissao: string, idSmtpConfig: string, idEmpresa?: string, idLoja?: string, componentId: string = ''): Observable<any> {
		return this.http.post<any>(
			`${AppConfig.getAPIEndpoint()}${this.ROUTE}/enviar_email/${this.tokenStorage.getUserInfo().idUsuario}`,
			{
				chave,
				tipoNota,
				to,
				dataEmissao,
				idSmtpConfig,
				...(idEmpresa ? { idEmpresa } : {}),
				...(idLoja ? { idLoja } : {})
			},
			{
				headers: AppConfig.getHTTPHeader().headers.set('componentId', componentId)
			}
		);
	}

	cadastrarSatConfig(cnpjsh: string, signac: string, caixa: string, versaoLayout: string, empresa?: string, loja?: string, componentId: string = ''): Observable<any> {
		return this.http.post<any>(
			`${AppConfig.getAPIEndpoint()}${this.ROUTE}/satConfig/${this.tokenStorage.getUserInfo().idUsuario}`,
			{
				empresa,
				loja,
				cnpjsh,
				signac,
				caixa,
				versaoLayout
			},
			{
				headers: AppConfig.getHTTPHeader().headers.set('componentId', componentId)
			}
		)
	}

	atualizarSatConfig(id: string, cnpjsh: string, signac: string, caixa: string, versaoLayout: string, componentId: string = ''): Observable<any> {
		return this.http.put<any>(
			`${AppConfig.getAPIEndpoint()}${this.ROUTE}/satConfig/${id}/${this.tokenStorage.getUserInfo().idUsuario}`,
			{
				cnpjsh,
				signac,
				caixa,
				versaoLayout
			},
			{
				headers: AppConfig.getHTTPHeader().headers.set('componentId', componentId)
			}
		)
	}

	listarSatConfig(empresa?: string, loja?: string, componentId: string = ''): Observable<any> {
		return this.http.get<any>(
			`${AppConfig.getAPIEndpoint()}${this.ROUTE}/satConfig/${this.tokenStorage.getUserInfo().idUsuario}`,
			{
				params: {
					empresa,
					loja
				},
				headers: AppConfig.getHTTPHeader().headers.set('componentId', componentId)
			}
		)
	}

	removerSatConfig(idSatConfig: string, componentId: string = ''): Observable<any> {
		return this.http.delete<any>(
			`${AppConfig.getAPIEndpoint()}${this.ROUTE}/satConfig/${idSatConfig}/${this.tokenStorage.getUserInfo().idUsuario}`,
			{
				headers: AppConfig.getHTTPHeader().headers.set('componentId', componentId)
			}
		)
	}

	obterRegraContingencia(componentId = ''): Observable<RegraContingencia> {
		return this.http.get<RegraContingencia>(
			AppConfig.getAPIEndpoint() + this.ROUTE + '/regraContingencia/' + this.tokenStorage.getUserInfo().idUsuario,
			{
				headers: AppConfig.getHTTPHeader().headers.set('componentId', componentId)
			}
		);
	}

	cadastrarRegraContingencia(body: RegraContingencia, componentId: string = ''): Observable<any> {
		return this.http.put<any>(
			AppConfig.getAPIEndpoint() + this.ROUTE + '/regraContingencia/' + this.tokenStorage.getUserInfo().idUsuario,
			body,
			{
				headers: AppConfig.getHTTPHeader().headers.set('componentId', componentId)
			}
		)
	}
}