import {Empresa} from '../api/Empresa';
import {TipoEstabelecimento} from '../enum/TipoEstabelecimento';
import {Loja} from '../api/Loja';
import {Produto} from '../api/Produto';
import {DefaultFields} from '../interface/DefaultFields';

export class Estabelecimento extends DefaultFields<Estabelecimento> {

	id?: string;
	estLocalId: string;
	tipoEstabelecimento: TipoEstabelecimento;
	empresa: Empresa;
	loja: Loja;
	nome: string;
	allSelected: boolean;
	produtos: Produto[];
	senhaCertificado: string;
	arquivoCertificado: File;

	multiSelecao: Estabelecimento[];

	_lastUpdate: number;
	_callBroadcast: boolean;

	constructor(tipoEstabelecimento: TipoEstabelecimento, empresa: Empresa, loja: Loja) {
		super();

		this.tipoEstabelecimento = tipoEstabelecimento;
		this.empresa = empresa;
		this.loja = loja;

		if (this.tipoEstabelecimento == TipoEstabelecimento.EMPRESA) {
			if (!!this.empresa) {
				this.nome = this.empresa.nomeFantasia;
				this.estLocalId = 'emp' + this.empresa.idEmpresa;
				this.id = this.empresa.idEmpresa;
			}
		} else if (this.tipoEstabelecimento == TipoEstabelecimento.LOJA) {
			if (!!this.loja) {
				this.nome = this.loja.nomeFantasia;
				this.estLocalId = 'loj' + this.loja.idLoja;
				this.id = this.loja.idLoja;
			}
		} else if (this.tipoEstabelecimento == TipoEstabelecimento.MULTI_SELECAO) {
			this.multiSelecao = [];
		}
	}

}
