import {Permissoes} from './Permissoes';
import {UsuarioFuncao} from './UsuarioFuncao';
import {DefaultFields} from '../interface/DefaultFields';
import {Estabelecimento} from '../template/Estabelecimento';
import {Loja} from './Loja';
import {Empresa} from './Empresa';
import {Entregador} from './Entregador';
import {TipoPagamento} from '../enum/TipoPagamento';
import {ChatParticipantStatus} from '../../../content/partials/ng-chat';

export class Usuario extends DefaultFields<Usuario> {

	/* Model */
	idUsuario?: any;
	email?: string;
	nome?: string;
	genero?: string;
	senha?: string;
	senhaAutorizacao?: string;
	notificar?: string;
	cpf?: string;
	usuarioFuncaoIdUsuarioFuncao?: string;
	usuarioFuncao?: UsuarioFuncao;
	numero?: string;
	lojas?: Loja[];
	lojasBina?: string[];
	lojasBinaRecebe?: string[];
	empresas?: Empresa[];
	empresasBina?: string[];
	empresasBinaRecebe?: string[];
	lastVersion?: string;
	limiteDesconto?: number;
	limiteDescontoReais?: number;
	bloquearDataPedido?: boolean;
	ultimaInteracao?: Date;

	entregadorIdEntregador?: any;
	entregador?: Entregador;

	chatStatus?: ChatParticipantStatus;

	/* Request Options */
	associarUsuarioAdministrativo?: boolean;
	usuarioAdministrativo?: Usuario;
	confirmarSenha?: string;
	confirmarSenhaAutorizacao?: string;

	removerFuncao?: boolean;

	empresaPrincipal?: string | Empresa | any;
	lojaPrincipal?: string | Loja | any;

	/** Template Vars */
	estabelecimento?: Estabelecimento;
	showAssociationPopUp?: boolean;
	autorizacaoAutenticada?: boolean;
	exibirTipoCFOPCliente?: boolean;

	/** VOIP */
	voip_host?: string;
	voip_client_id?: string;
	voip_client_secret?: string;

	/** Auth Info */
	isAdmin?: boolean;
	isUserGroupOwner?: boolean;
	tokenExpiration?: number;
	permissoes?: Permissoes;
	permissoes_estabelecimento?: Permissoes;
	pagamentos_acessiveis_estabelecimento?: TipoPagamento[];
	pagamentos_acessiveis_usuario?: TipoPagamento[];
	role?: string;
}
