import {Estabelecimento} from '../template/Estabelecimento';
import {TokenStorage} from '../../auth/token-storage.service';
import {TipoEstabelecimento} from '../enum/TipoEstabelecimento';


export function $getEstabelecimentoOnCache(): Estabelecimento {
	try {
		return <Estabelecimento>JSON.parse(localStorage.getItem(TokenStorage.KEY_CACHE_ESTABELECIMENTO));
	} catch (err) { return null; }
}

/**
 * Funções comuns ds Estabeleciomento
 */
export class EstabelecimentoFunctions {

	estabelecimento: Estabelecimento;
	tipoEstabelecimento = TipoEstabelecimento;

	getEstabelecimentoOnCache(): Estabelecimento {
		return $getEstabelecimentoOnCache();
	}

	setEstabelecimentoOnCache(e: Estabelecimento, callBroadcast: boolean = true) {
		if (this.estabelecimento.multiSelecao && this.estabelecimento.multiSelecao.length > 0) {
			e._lastUpdate = Date.now();
			e._callBroadcast = callBroadcast;
			localStorage.setItem(TokenStorage.KEY_CACHE_ESTABELECIMENTO, JSON.stringify(e));
		}
	}

	/**
	 * Gera um query param com o id do Estabeleciomento selecionado
	 */
	getEstabelecimentoQueryParam(_est = null): any {
		this.estabelecimento = this.getEstabelecimentoOnCache();
		let _estab = _est != null ? _est : this.estabelecimento;
		if (_estab) {
			switch (_estab.tipoEstabelecimento) {
				case TipoEstabelecimento.EMPRESA: {
					if (_estab.empresa) {
						return {
							empresa: _estab.empresa.idEmpresa
						};
					}
					break;
				}
				case TipoEstabelecimento.LOJA: {
					if (_estab.loja) {
						return {
							loja: _estab.loja.idLoja
						};
					}
					break;
				}
				case TipoEstabelecimento.MULTI_SELECAO: {

					let idEmpresas = null;
					let idLojas = null;

					for (const est of _estab.multiSelecao) {

						if (!!est.empresa) {
							idEmpresas = (idEmpresas == null || idEmpresas == '' ? '' + est.empresa.idEmpresa : idEmpresas + ', ' + est.empresa.idEmpresa );
						}

						if (!!est.loja) {
							idLojas = (idLojas == null || idLojas == '' ? '' + est.loja.idLoja : idLojas + ', ' + est.loja.idLoja );
						}
					}

					if (idEmpresas || idLojas) {
						let resultParam = {};
						if (!!idEmpresas) resultParam['multiEmpresa'] = idEmpresas;
						if (!!idLojas) resultParam['multiLoja'] = idLojas;
						return resultParam;
					}
				}
			}
		}

		return {};
	}

	/**
	 * Formata a query string do filtro de data
	 */
	getDateFilterQueryParam(dataInicio: Date = null, dataFim: Date = null, autoPopulateHours: boolean = true): string | any {
		if (dataInicio && dataFim) {
			let _dtInicio = new Date(dataInicio);
			let _dtFim = new Date(dataFim);
			if (autoPopulateHours == true) {
				_dtInicio.setUTCHours(0, 0, 0);
				_dtFim.setUTCHours(23, 59, 59);
			}
			return { dataInicio: _dtInicio.toISOString(), dataFim: _dtFim.toISOString() };
		}
		return {};
	}

	getDateFilterWithFieldQueryParam(campo = null, dataInicio = null, dataFim = null, autoPopulateHours: boolean = true): string | any {
		if (campo && dataInicio && dataFim) {
			let _dtInicio = new Date(dataInicio);
			let _dtFim = new Date(dataFim);
			if (autoPopulateHours == true) {
				_dtInicio.setUTCHours(0, 0, 0);
				_dtFim.setUTCHours(23, 59, 59);
			}
			return { campo: campo, dataInicio: _dtInicio.toISOString(), dataFim: _dtFim.toISOString() };
		}
		return {};
	}

	/**
	 * Funções relacionadas a seleção de Estabelecimento
	 */
	getEstabelecimentoName(e: Estabelecimento = null) {
		const _est = e || this.estabelecimento;

		if (!!_est) {
			if (_est.allSelected == true) {
				return 'Todos';
			}
			switch (_est.tipoEstabelecimento) {
				case TipoEstabelecimento.EMPRESA: return _est.empresa.nomeFantasia;
				case TipoEstabelecimento.LOJA: return _est.loja.nomeFantasia;
			}
		}
		return '';
	}
}
