import {Injectable} from '@angular/core';
import {ServicePattern} from '../../../models/interface/ServicePattern';
import {HttpClient} from '@angular/common/http';
import {TokenStorage} from '../../../auth/token-storage.service';
import {Observable} from 'rxjs';
import {AppConfig} from '../../../../config/appConfig';
import {ResultObject} from '../../../models/api/ResultObject';
import {EstabelecimentoFunctions} from '../../../models/interface/EstabelecimentoFunctions';
import {RegraTributacao} from '../../../models/api/RegraTributacao';
import {DateFilter} from '../../../models/interface/DateFilter';

export class RegraTributacaoFilter {
	dateFilter: DateFilter;

	constructor(params: any = {}) {
		if (!params) params = {};
		this.dateFilter = params.dateFilter || <any>{};
	}
}

@Injectable()
export class RegraTributacaoService extends EstabelecimentoFunctions implements ServicePattern<RegraTributacao> {

	private readonly ROUTE = '/notaFiscal/regra/tributacao';

	constructor(private http: HttpClient, private tokenStorage: TokenStorage) {
		super();
	}

	getAll(
		componentId: string = '',
		page: number = null,
		perPage: number = null,
		regraTributacaoFilter: RegraTributacaoFilter = new RegraTributacaoFilter()
	): Observable<RegraTributacao[]> {

		return this.http.get<RegraTributacao[]>(
			AppConfig.getAPIEndpoint() + this.ROUTE + '/list/' + this.tokenStorage.getUserInfo().idUsuario,
			{
				headers: AppConfig.getHTTPHeader().headers.set('componentId', componentId),
				params: {
					...this.getEstabelecimentoQueryParam(),
					...this.getDateFilterQueryParam(regraTributacaoFilter.dateFilter.dataInicio, regraTributacaoFilter.dateFilter.dataFim),
					...(page || page == 0 ? { page: page + 1 } : {}),
					...(perPage ? { per_page: perPage } : {})
				}
			}
		);
	}

	get(idRegraTributacao: string, componentId: string = ''): Observable<RegraTributacao> {
		return this.http.get<RegraTributacao>(
			`${AppConfig.getAPIEndpoint()}${this.ROUTE}/info/${this.tokenStorage.getUserInfo().idUsuario}/${idRegraTributacao}`,
			{
				headers: AppConfig.getHTTPHeader().headers.set('componentId', componentId),
				params: {}
			}
		);
	}

	save(body: RegraTributacao, componentId: string = '', getEstabelecimentoFromCache: boolean = true): Observable<ResultObject<any>> {
		if (getEstabelecimentoFromCache) { (<any>body).estabelecimento = this.getEstabelecimentoOnCache(); }
		return this.http.post<ResultObject<any>>(
			AppConfig.getAPIEndpoint() + this.ROUTE + '/' + this.tokenStorage.getUserInfo().idUsuario,
			body,
			{
				headers: AppConfig.getHTTPHeader().headers.set('componentId', componentId)
			}
		);
	}

	update(body: RegraTributacao): Observable<ResultObject<any>> {
		// TODO
		return null;
	}

	delete(idRegraTributacao: string, componentId: string = ''): Observable<ResultObject<any>> {
		return this.http.delete<ResultObject<any>>(
			`${AppConfig.getAPIEndpoint()}${this.ROUTE}/${this.tokenStorage.getUserInfo().idUsuario}/${idRegraTributacao}`,
			{
				headers: AppConfig.getHTTPHeader().headers.set('componentId', componentId)
			}
		);
	}

	getEntriesCount(componentId: string = ''): Observable<{entries: number}> {
		return this.http.get<{entries: number}>(
			AppConfig.getAPIEndpoint() + this.ROUTE + '/list/' + this.tokenStorage.getUserInfo().idUsuario,
			{
				headers: AppConfig.getHTTPHeader().headers.set('componentId', componentId),
				params: {
					...this.getEstabelecimentoQueryParam(),
					entriesOnly: true
				}
			}
		);
	}

}
