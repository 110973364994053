import {BrowserModule, HAMMER_GESTURE_CONFIG} from '@angular/platform-browser';
import {LOCALE_ID, NgModule} from '@angular/core';
import {TranslateModule} from '@ngx-translate/core';
import {HttpClientModule} from '@angular/common/http';

import 'hammerjs';

import {AppComponent} from './app.component';
import {AppRoutingModule} from './app-routing.module';

import {AuthenticationModule} from './core/auth/authentication.module';

import {LayoutModule} from './content/layout/layout.module';
import {PartialsModule} from './content/partials/partials.module';
import {CoreModule} from './core/core.module';

import {LayoutConfigService} from './core/services/layout-config.service';
import {MenuConfigService} from './core/services/menu-config.service';
import {PageConfigService} from './core/services/page-config.service';
import {UserService} from './core/services/user.service';
import {UtilsService} from './core/services/utils.service';
import {ClassInitService} from './core/services/class-init.service';
import {NgbModule} from '@ng-bootstrap/ng-bootstrap';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {GestureConfig, MatCheckboxModule, MatProgressSpinnerModule} from '@angular/material';
import {OverlayModule} from '@angular/cdk/overlay';

import {MessengerService} from './core/services/messenger.service';
import {ClipboardService} from './core/services/clipboard.sevice';

import {PERFECT_SCROLLBAR_CONFIG, PerfectScrollbarConfigInterface} from 'ngx-perfect-scrollbar';
import {LayoutConfigStorageService} from './core/services/layout-config-storage.service';
import {QuickSearchService} from './core/services/quick-search.service';
import {SubheaderService} from './core/services/layout/subheader.service';
import {HeaderService} from './core/services/layout/header.service';
import {MenuHorizontalService} from './core/services/layout/menu-horizontal.service';
import {MenuAsideService} from './core/services/layout/menu-aside.service';
import {LayoutRefService} from './core/services/layout/layout-ref.service';
import {SplashScreenService} from './core/services/splash-screen.service';
import {DataTableService} from './core/services/datatable.service';
import {AuthGuard} from './core/auth/_guards/auth.guard';
import {ClientesService} from './core/services/api/clientes.service';
import {EntregadoresService} from './core/services/api/entregadores.service';
import {Interceptor} from './core/services/api/HttpsRequestInterceptor';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {ProdutosService} from './core/services/api/produtos.service';
import {ProdutosPadroesService} from './core/services/api/produtosPadroes.service';
import {NaturezaOpFiscalService} from './core/services/api/naturezaOpFiscal.service';
import {PedidosService} from './core/services/api/pedidos.service';
import {UsuarioFuncaoService} from './core/services/api/usuario-funcao.service';
import {UsuariosService} from './core/services/api/usuarios.service';
import {FornecedorService} from './core/services/api/fornecedor.service';
import {SocketService} from './core/services/api/socket.service';
import {Broadcaster} from './core/models/broadcaster';
import {EstoquesService} from './core/services/api/estoques.service';
import {EmpresasService} from './core/services/api/empresas.service';
import {VeiculosService} from './core/services/api/veiculos.service';
import {MovimentacaoEstoqueService} from './core/services/api/movimentacao-estoque.service';
import {VendasService} from './core/services/api/vendas.service';
import {ContasService} from './core/services/api/conta.service';
import {LojasService} from './core/services/api/lojas.service';
import {EstatisticasService} from './core/services/api/estatisticas.service';
import {NotificacoesService} from './core/services/api/notificacoes.service';
import {TaxaTransacaoCartoesService} from './core/services/api/taxaTransacaoCartoes.service';
import {NotasAvariadasService} from './core/services/api/notas-avariadas.service';
import {LoggerService} from './core/services/api/logger.service';
import {registerLocaleData} from '@angular/common';

import localePtBR from '@angular/common/locales/pt';
import localePtBRExtra from '@angular/common/locales/extra/pt';
import {HistoricosService} from './core/services/api/historicos.service';
import {DespesasService} from './core/services/api/despesas.service';
import {ContasDespesasService} from './core/services/api/contas-despesas.service';
import {EstabelecimentosBloqueadosService} from './core/services/api/estabelecimentos-bloqueados.service';
import {ServiceWorkerModule} from '@angular/service-worker';
import {environment} from '../environments/environment';
import {RelatoriosService} from './core/services/api/relatorios.service';
import {NgChatModule} from './content/partials/ng-chat';
import {RegraTributacaoService} from './core/services/api/nota-fiscal/regra-tributacao.service';
import {OperacaoFiscalService} from './core/services/api/nota-fiscal/operacao-fiscal.service';
import {PlanoComercialService} from './core/services/api/plano-comercial.service';
import {NotaFiscalService} from './core/services/api/nota-fiscal/nota-fiscal.service';
import {BancoService} from './core/services/api/boleto/banco.service';
import {ValorNegociadoService} from './core/services/api/valor-negociado.service';
import {BoletoConfigService} from './core/services/api/boleto/boleto-config.service';
import {BoletoService} from './core/services/api/boleto/boleto.service';
import {HistoricosEstoqueService} from './core/services/api/historicos-estoque.service';
import {MovimentacaoFinanceiraService} from './core/services/api/movimentacao-financeira.service';
import {FonteBancariaService} from './core/services/api/fonte-bancaria.service';
import {DespesaVeiculoService} from './core/services/api/despesa-veiculo.service';
import {BinaService} from './core/services/api/bina.service';
import {DespesaCombustivelService} from './core/services/api/despesa-combustivel.service';
import { CategoriasProdutoService } from './core/services/api/categoriasProduto.service';
import {AplicativoConfigService} from './core/services/api/aplicativo/aplicativo-config.service';
import { ContratosService } from './core/services/api/contratos.service';
import { ScriptService } from './core/services/api/script.service';
import { TemplatesContratoService } from './core/services/api/templatesContrato.service';
import { TraducaoNFService } from './core/services/api/traducaoNF.service';
import {ContabilService} from './core/services/api/contabil.service';
import { MdfeconfigService } from './core/services/api/mdfeconfig.service';
import {SMTPConfigService} from './core/services/api/smtp-config.service';
import { SocketLocationService } from './core/services/api/socketLocation.service';
import { ConveniosService } from './core/services/api/convenios.service';
import { ParticipantesService } from './core/services/api/participantes.service';
import { ProdutosConvenioService } from './core/services/api/produtos-convenio.service';
import { PedidosConvenioService } from './core/services/api/pedidosConvenio.service';
import { CupomDescontoService } from './core/services/api/cupom-desconto.service';
import { UsuariosConvenioService } from './core/services/api/usuariosConvenio.service';
import { DocumentosService } from './core/services/api/documentos.service';
import { AberturaCaixaService } from './core/services/api/aberturaCaixa.service';
import { EstabelecimentosService } from './core/services/api/estabelecimentos.service';
import { NgxEchartsModule } from 'ngx-echarts';
import * as echarts from 'echarts';
import { AjusteDREService } from './core/services/api/ajusteDre.service';

const DEFAULT_PERFECT_SCROLLBAR_CONFIG: PerfectScrollbarConfigInterface = {
	// suppressScrollX: true
};

registerLocaleData(localePtBR, 'pt-BR', localePtBRExtra);

@NgModule({
	declarations: [AppComponent],
	imports: [
		BrowserAnimationsModule,
		BrowserModule,
		AppRoutingModule,
		HttpClientModule,
		// environment.isMockEnabled ? HttpClientInMemoryWebApiModule.forRoot(FakeApiService) : [],
		LayoutModule,
		PartialsModule,
		CoreModule,
		OverlayModule,
		AuthenticationModule,
		FormsModule,
		ReactiveFormsModule,

		/* Material */
		MatCheckboxModule,
		/* */

		//NgxPermissionsModule.forRoot(),
		NgbModule.forRoot(),
		TranslateModule.forRoot(),
		MatProgressSpinnerModule,
		Interceptor,
		ServiceWorkerModule.register('ngsw-worker.js', { enabled: environment.production }),
		NgChatModule,
		NgxEchartsModule
	],
	providers: [
		//AclService,
		AuthGuard,
		LayoutConfigService,
		LayoutConfigStorageService,
		LayoutRefService,
		MenuConfigService,
		PageConfigService,
		UserService,
		UtilsService,
		ClassInitService,
		MessengerService,
		ClipboardService,
		QuickSearchService,
		DataTableService,
		SplashScreenService,

		/** API */
		ClientesService,
		ProdutosService,
		ProdutosPadroesService,
		NaturezaOpFiscalService,
		PedidosService,
		ContabilService,
		PlanoComercialService,
		BinaService,
		CategoriasProdutoService,
		ContratosService,
		ScriptService,
		TemplatesContratoService,
		SMTPConfigService,
		EstabelecimentosService,

		/* Apliactivo */
		AplicativoConfigService,

		/* nota fiscal */
		RegraTributacaoService,
		OperacaoFiscalService,
		NotaFiscalService,
		TraducaoNFService,
		/* */

		/* Boletos */
		BoletoService,
		BancoService,
		BoletoConfigService,
		/* */

		RelatoriosService,
		UsuarioFuncaoService,
		UsuariosService,
		FornecedorService,
		EntregadoresService,
		EstoquesService,
		EmpresasService,
		VeiculosService,
		MovimentacaoEstoqueService,
		MovimentacaoFinanceiraService,
		DespesaVeiculoService,
		DespesaCombustivelService,
		FonteBancariaService,
		VendasService,
		ContasService,
		DespesasService,
		LojasService,
		SocketService,
		SocketLocationService,
		Broadcaster,
		EstatisticasService,
		NotificacoesService,
		ValorNegociadoService,
		TaxaTransacaoCartoesService,
		NotasAvariadasService,
		LoggerService,
		HistoricosService,
		HistoricosEstoqueService,
		ContasDespesasService,
		EstabelecimentosBloqueadosService,
		MdfeconfigService,
		ConveniosService,
		AberturaCaixaService,
		ParticipantesService,
		ProdutosConvenioService,
		PedidosConvenioService,
		UsuariosConvenioService,
		CupomDescontoService,
		DocumentosService,
		AjusteDREService,
		/** FIM API */

		{
			provide: PERFECT_SCROLLBAR_CONFIG,
			useValue: DEFAULT_PERFECT_SCROLLBAR_CONFIG
		},

		{ provide: LOCALE_ID, useValue: 'pt-BR' },

		// template services
		SubheaderService,
		HeaderService,
		MenuHorizontalService,
		MenuAsideService,
		{
			provide: HAMMER_GESTURE_CONFIG,
			useClass: GestureConfig
		}
	],
	bootstrap: [AppComponent]
})
export class AppModule {}
