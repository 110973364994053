
import {Injectable} from '@angular/core';
import {EstabelecimentoFunctions} from '../../models/interface/EstabelecimentoFunctions';
import {ServicePattern} from '../../models/interface/ServicePattern';
import {HttpClient} from '@angular/common/http';
import {TokenStorage} from '../../auth/token-storage.service';
import {Observable} from 'rxjs';
import {AppConfig} from '../../../config/appConfig';
import {ResultObject} from '../../models/api/ResultObject';
import { ProdutoConvenio } from '../../models/api/ProdutoConvenio';
import { Convenio } from '../../models/api/Convenio';

@Injectable()
export class ProdutosConvenioService extends EstabelecimentoFunctions implements ServicePattern<ProdutoConvenio> {

	constructor(private http: HttpClient, private tokenStorage: TokenStorage ) {
		super();
	}

	getAll(est: string, convenio: string, page: number, per_page: number, componentId = '', search?: string): Observable<any> {
		return this.http.get<any>(
			AppConfig.getAPIEndpoint() + '/produtosConvenio/' + this.tokenStorage.getUserInfo().idUsuario,
			{
				headers: AppConfig.getHTTPHeader().headers.set('componentId', componentId),
				params: {
					est,
					convenio,
					page: String(page),
					per_page: String(per_page),
					...(search ? {search} : {})
				}
			}
		);
	}

	importar(convenio: Convenio, idEst: string, componentId: string = ''): Observable<ResultObject<any>> {
		return this.http.post<ResultObject<any>>(
			AppConfig.getAPIEndpoint() + '/produtosConvenio/importar/' + this.tokenStorage.getUserInfo().idUsuario,
			{
				convenio,
				idEst
			},
			{
				headers: AppConfig.getHTTPHeader().headers.set('componentId', componentId)
			}
		);
	}

	save(body: ProdutoConvenio, componentId: string = ''): Observable<ResultObject<any>> {
		return this.http.post<ResultObject<any>>(
			AppConfig.getAPIEndpoint() + '/produtosConvenio/' + this.tokenStorage.getUserInfo().idUsuario,
			body,
			{
				headers: AppConfig.getHTTPHeader().headers.set('componentId', componentId)
			}
		);
	}

	update(body: ProdutoConvenio, componentId: string = ''): Observable<ResultObject<any>> {
		return this.http.put<ResultObject<any>>(
			AppConfig.getAPIEndpoint() + '/produtosConvenio/' + this.tokenStorage.getUserInfo().idUsuario,
			body,
			{
				headers: AppConfig.getHTTPHeader().headers.set('componentId', componentId)
			}
		);
	}

	delete(idProdutoConvenio: string, componentId: string = ''): Observable<ResultObject<any>> {
		return this.http.delete<ResultObject<any>>(
			AppConfig.getAPIEndpoint() + '/produtosConvenio/' + this.tokenStorage.getUserInfo().idUsuario + '/' + idProdutoConvenio,
			{
				headers: AppConfig.getHTTPHeader().headers.set('componentId', componentId)
			}
		);
	}

}