import {Injectable} from '@angular/core';
import {AppConfig} from '../../../config/appConfig';
import * as io from 'socket.io-client';

@Injectable()
export class SocketLocationService {

    private static _socket: SocketIOClient.Socket;

    constructor() {
        window['disconnectSocketLocation'] = () => SocketLocationService.disconnectSocket();
    }

    public static getSocket(): SocketIOClient.Socket {
        if (this._socket == null) this.initSocket();
        return this._socket;
    }

    public static initSocket() {
        if (this._socket == null) {
            this._socket = io(AppConfig.getLocationEndpoint(), {
                forceNew: true,
                transports: ['websocket', 'polling'],
                reconnection: true,
                reconnectionAttempts: Infinity,
                reconnectionDelay: 1000,
                reconnectionDelayMax: 5000
            });
        }
    }

    public static disconnectSocket() {
        if (this._socket != null) {
            this._socket.close();
            this._socket = null;
        }
    }

}