import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ListNotificationComponent } from './list-notification.component';
import { NotificationItemComponent } from './timeline-item/notification-item.component';
import { CoreModule } from '../../../../../core/core.module';

@NgModule({
	imports: [CommonModule, CoreModule],
	declarations: [ListNotificationComponent, NotificationItemComponent],
	exports: [ListNotificationComponent, NotificationItemComponent]
})
export class ListNotificationModule {}
