import {EstabelecimentoFunctions} from '../../models/interface/EstabelecimentoFunctions';
import {ServicePattern} from '../../models/interface/ServicePattern';
import {HttpClient} from '@angular/common/http';
import {TokenStorage} from '../../auth/token-storage.service';
import {Observable} from 'rxjs';
import {AppConfig} from '../../../config/appConfig';
import {DateFilter} from '../../models/interface/DateFilter';
import {ResultObject} from '../../models/api/ResultObject';
import {Injectable} from '@angular/core';
import {FonteBancaria} from '../../models/api/FonteBancaria';
import {Estabelecimento} from '../../models/template/Estabelecimento';
import { TipoEstabelecimento } from '../../models/enum/TipoEstabelecimento';

@Injectable()
export class FonteBancariaService extends EstabelecimentoFunctions implements ServicePattern<FonteBancaria> {

	private readonly ROUTE = '/financeiro/fonteBancaria';

	constructor(private http: HttpClient, private tokenStorage: TokenStorage) {
		super();
	}

	getAll(
		dateFilter: DateFilter = { dataInicio: null, dataFim: null },
		page: number = null,
		perPage: number = null,
		est: Estabelecimento = new Estabelecimento(null, null, null),
		componentId: string = ''
	): Observable<FonteBancaria[]> {
		let idLoja = est.loja != null ? est.loja.idLoja || est.loja._id : null;
		let idEmpresa = est.empresa != null ? est.empresa.idEmpresa || est.empresa._id : null;
		let multiEmpresa = (!idEmpresa && !idLoja && est.multiSelecao) ? est.multiSelecao.filter(m => m.tipoEstabelecimento == TipoEstabelecimento.EMPRESA).map(m => m.id).join(',') : null;
		let multiLoja = (!idEmpresa && !idLoja && est.multiSelecao) ? est.multiSelecao.filter(m => m.tipoEstabelecimento == TipoEstabelecimento.LOJA).map(m => m.id).join(',') : null;
		return this.http.get<FonteBancaria[]>(
			`${AppConfig.getAPIEndpoint()}${this.ROUTE}/list/${this.tokenStorage.getUserInfo().idUsuario}`,
			{
				headers: AppConfig.getHTTPHeader().headers.set('componentId', componentId),
				params: {
					...(idEmpresa || idLoja ? {} : (multiEmpresa || multiLoja) ? {
						...(multiEmpresa ? { empresa: multiEmpresa } : {}),
						...(multiLoja ? { loja: multiLoja } : {})
					} : this.getEstabelecimentoQueryParam()),
					...this.getDateFilterQueryParam(dateFilter.dataInicio, dateFilter.dataFim),
					...(page || page == 0 ? { page: page + 1 } : {}),
					...(perPage ? { per_page: perPage } : {}),
					...(idLoja ? { loja: idLoja } : {}),
					...(idEmpresa ? { empresa: idEmpresa } : {})
				}
			}
		);
	}

	save(body: FonteBancaria, componentId: string = ''): Observable<FonteBancaria> {
		return this.http.post<FonteBancaria>(
			`${AppConfig.getAPIEndpoint()}${this.ROUTE}/${this.tokenStorage.getUserInfo().idUsuario}`,
			body,
			{ headers: AppConfig.getHTTPHeader().headers.set('componentId', componentId) }
		);
	}

	delete(_id: number, componentId: string = ''): Observable<ResultObject<any>> {
		return this.http.delete<ResultObject<any>>(
			`${AppConfig.getAPIEndpoint()}${this.ROUTE}/${this.tokenStorage.getUserInfo().idUsuario}/${_id}`,
			{ headers: AppConfig.getHTTPHeader().headers.set('componentId', componentId) }
		);
	}

	update(body: FonteBancaria, componentId: string = ''): Observable<ResultObject<any>> {
		return this.http.put<ResultObject<any>>(
			`${AppConfig.getAPIEndpoint()}${this.ROUTE}/${this.tokenStorage.getUserInfo().idUsuario}`,
			body,
			{ headers: AppConfig.getHTTPHeader().headers.set('componentId', componentId) }
		);
	}

}
