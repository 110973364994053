import {Injectable} from '@angular/core';
import {ServicePattern} from '../../models/interface/ServicePattern';
import {Veiculo} from '../../models/api/Veiculo';
import {HttpClient} from '@angular/common/http';
import {TokenStorage} from '../../auth/token-storage.service';
import {Observable} from 'rxjs';
import {AppConfig} from '../../../config/appConfig';
import {ResultObject} from '../../models/api/ResultObject';
import {EstabelecimentoFunctions} from '../../models/interface/EstabelecimentoFunctions';
import {DateFilter} from '../../models/interface/DateFilter';
import {Estabelecimento} from '../../models/template/Estabelecimento';

@Injectable()
export class VeiculosService extends EstabelecimentoFunctions implements ServicePattern<Veiculo> {

	constructor(private http: HttpClient, private tokenStorage: TokenStorage) {
		super();
	}

	getAll(
		dateFilter: DateFilter = {dataInicio: null, dataFim: null},
		componentId: string = '',
		page: number = null,
		perPage: number = null,
		searchString: string = null,
		estabelecimento?: Estabelecimento,
		showAll?: boolean
	): Observable<Veiculo[]> {
		return this.http.get<Veiculo[]>(
			AppConfig.getAPIEndpoint() + '/veiculos/list/' + this.tokenStorage.getUserInfo().idUsuario,
			{
				headers: AppConfig.getHTTPHeader().headers.set('componentId', componentId),
				params: {
					...this.getEstabelecimentoQueryParam(estabelecimento),
					...this.getDateFilterQueryParam(dateFilter.dataInicio, dateFilter.dataFim),
					...(page || page == 0 ? { page: page + 1 } : {}),
					...(perPage ? { per_page: perPage } : {}),
					...(searchString != null ? { searchString: searchString } : {}),
					...(showAll != null ? { showAll: showAll } : {})
				}
			}
		);
	}

	get(idVeiculo: number, componentId: string = ''): Observable<Veiculo> {
		return this.http.get<Veiculo>(
			AppConfig.getAPIEndpoint() + '/veiculos/' + idVeiculo + '/' + this.tokenStorage.getUserInfo().idUsuario,
			{
				headers: AppConfig.getHTTPHeader().headers.set('componentId', componentId)
			}
		);
	}

	save(body: Veiculo, getEstabelecimentoFromCache: boolean = true, componentId: string = ''): Observable<ResultObject<any>> {
		if (getEstabelecimentoFromCache) { body.estabelecimento = this.getEstabelecimentoOnCache(); }
		return this.http.post<ResultObject<any>>(
			AppConfig.getAPIEndpoint() + '/veiculos/' + this.tokenStorage.getUserInfo().idUsuario,
			body,
			{
				headers: AppConfig.getHTTPHeader().headers.set('componentId', componentId)
			}
		);
	}

	update(body: Veiculo, componentId: string = ''): Observable<ResultObject<any>> {
		return this.http.put<ResultObject<any>>(
			AppConfig.getAPIEndpoint() + '/veiculos/' + this.tokenStorage.getUserInfo().idUsuario,
			body,
			{
				headers: AppConfig.getHTTPHeader().headers.set('componentId', componentId)
			}
		);
	}

	delete(idVeiculo: number, componentId: string = ''): Observable<ResultObject<any>> {
		return this.http.delete<ResultObject<any>>(
			AppConfig.getAPIEndpoint() + '/veiculos/' + this.tokenStorage.getUserInfo().idUsuario + '/' + idVeiculo,
			{
				headers: AppConfig.getHTTPHeader().headers.set('componentId', componentId)
			}
		);
	}

	getEntriesCount(componentId: string = ''): Observable<{entries: number}> {
		return this.http.get<{entries: number}>(
			AppConfig.getAPIEndpoint() + '/veiculos/list/' + this.tokenStorage.getUserInfo().idUsuario,
			{
				headers: AppConfig.getHTTPHeader().headers.set('componentId', componentId),
				params: {
					...this.getEstabelecimentoQueryParam(),
					entriesOnly: true
				}
			}
		);
	}

	removeAssociation(idVeiculo: string, idEmpresa: string, idLoja: string, componentId: string = '') {
		return this.http.post<ResultObject<any>>(
			AppConfig.getAPIEndpoint() + '/veiculos/remover/associacao/' + this.tokenStorage.getUserInfo().idUsuario,
			{
				idVeiculo: idVeiculo,
				idEmpresa: idEmpresa,
				idLoja: idLoja
			},
			{
				headers: AppConfig.getHTTPHeader().headers.set('componentId', componentId)
			}
		);
	}

	associate(idVeiculo: string, idEmpresa: string, idLoja: string, componentId: string = '') {
		return this.http.post<ResultObject<any>>(
			AppConfig.getAPIEndpoint() + '/veiculos/associacao/' + this.tokenStorage.getUserInfo().idUsuario,
			{
				idVeiculo: idVeiculo,
				idEmpresa: idEmpresa,
				idLoja: idLoja
			},
			{ headers: AppConfig.getHTTPHeader().headers.set('componentId', componentId) }
		);
	}

}
