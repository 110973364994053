import {
	AfterViewInit,
	ChangeDetectionStrategy,
	ChangeDetectorRef,
	Component,
	ElementRef,
	HostBinding,
	NgZone,
	OnDestroy,
	OnInit,
	ViewChild,
} from '@angular/core';
import { LayoutConfigService } from './core/services/layout-config.service';
import { ClassInitService } from './core/services/class-init.service';
import { TranslationService } from './core/services/translation.service';
import * as objectPath from 'object-path';
import { DomSanitizer } from '@angular/platform-browser';
import { NavigationEnd, Router } from '@angular/router';
import { PageConfigService } from './core/services/page-config.service';
import { filter } from 'rxjs/operators';
import { SplashScreenService } from './core/services/splash-screen.service';

import { SocketService } from './core/services/api/socket.service';
import { UsuariosService } from './core/services/api/usuarios.service';
import { $isLogged, TokenStorage, $getUserInfo } from './core/auth/token-storage.service';
import { AppConfig } from './config/appConfig';

import { environment } from '../environments/environment';
// language list
import { locale as ptBRLang } from './config/i18n/pt-BR';
import { locale as enLang } from './config/i18n/en';
import { locale as chLang } from './config/i18n/ch';
import { locale as esLang } from './config/i18n/es';
import { locale as jpLang } from './config/i18n/jp';
import { locale as deLang } from './config/i18n/de';
import { locale as frLang } from './config/i18n/fr';
import { Notificacao } from './core/models/api/Notificacao';
import { Broadcaster, BroadcasterEvents } from './core/models/broadcaster';
import { merge } from 'rxjs';
import { Pedido } from './core/models/api/Pedido';
import { checkPedidoNotifications } from './content/pages/components/pedidos/pedidos/pedidos.component';
import { NOTIFICATION_CLASSES } from './content/layout/header/topbar/notification/notification.component';
import { fadeIn, fadeInOut } from './content/animations/fadeInOut';
import { Estabelecimento } from './core/models/template/Estabelecimento';
import { $getEstabelecimentoOnCache } from './core/models/interface/EstabelecimentoFunctions';
import { DATE_FILTER_TODAY_CORRECTION, getRandomLetter, isWidthMobile, showSnackBar, UtilsService } from './core/services/utils.service';
import { TipoEstabelecimento } from './core/models/enum/TipoEstabelecimento';
import { Empresa } from './core/models/api/Empresa';
import { Loja } from './core/models/api/Loja';
import { CallcenterFunctions } from './core/models/interface/CallcenterFunctions';
import { EmpresasService } from './core/services/api/empresas.service';
import { AuthenticationService } from './core/auth/authentication.service';
import { Usuario } from './core/models/api/Usuario';
import { HttpClient } from '@angular/common/http';
import { Cliente } from './core/models/api/Cliente';
import { LojasService } from './core/services/api/lojas.service';
import { BinaService } from './core/services/api/bina.service';
import { ContratosService } from './core/services/api/contratos.service';
import { MatSnackBar } from '@angular/material';
import { OperacaoFiscalFilter, OperacaoFiscalService } from './core/services/api/nota-fiscal/operacao-fiscal.service';
import { OperacaoFiscal } from './core/models/api/OperacaoFiscal';
import { EstoquesService } from './core/services/api/estoques.service';
import axios from 'axios';
import io from 'socket.io-client';
import { NotaFiscalService } from './core/services/api/nota-fiscal/nota-fiscal.service';
import moment from 'moment';
import { EstabelecimentosService } from './core/services/api/estabelecimentos.service';
import { bus } from './core/models/interface/EventEmitter';

// LIST KNOWN ISSUES
// [Violation] Added non-passive event listener; https://github.com/angular/angular/issues/8866

declare let window: any;
declare const DTMF: any;
declare const _alert: any;
declare const globalThis;
declare const d4sign;
declare let Tawk_API;
declare let Tawk_Loaded;
declare const _confirm;
declare const _senha_autorizacao;
declare const Conpass;

@Component({
	// tslint:disable-next-line:component-selector
	selector: 'm-root',
	templateUrl: './app.component.html',
	styleUrls: ['./app.component.scss'],
	// changeDetection: ChangeDetectionStrategy.OnPush,
	changeDetection: ChangeDetectionStrategy.Default,
	animations: [fadeIn, fadeInOut]
})
export class AppComponent extends CallcenterFunctions implements AfterViewInit, OnInit, OnDestroy {

	public static readonly ROUTE_ID = 'callcenter:app_component';

	public static ATALHOS_OPFIS = [];

	title = 'VENDERGAS';

	@HostBinding('style') style: any;
	@HostBinding('class') classes: any = '';

	@ViewChild('splashScreen', { read: ElementRef })
	splashScreen: ElementRef;
	splashScreenImage: string;
	sisloading: boolean = false;
	logo: string = `../assets/app/media/img/logos/vendergas_logos/${moment().isBetween(moment('12-01', 'MM-DD').startOf('day'), moment('12-25', 'MM-DD').endOf('day')) ? 'logo_christmas.png' : `logo300x150_paglogin.png`}`;

	persistentNotifications: Notificacao[] = [];

	_interval_checkPersistentNotification;
	_interval_checkEstabelecimentoChanged;
	_interval_checkLoginExpirado;

	_interval_checkNovaChamada;

	_lastEstUpdate: number;

	isLogged: boolean;
	mostrarPopUp: boolean = false;
	ignorarPopUp: boolean;
	socket: any;
	userInfo: Usuario;
	popupContrato: boolean = false;
	popupSefaz: boolean = false;
	emitindoNf: boolean = false;
	quantidadeNotas: Number;
	contratoMsg: string;
	congelarPopup: boolean = false;
	qsEst: string = '';
	lockBloqueio: boolean = false;
	cnpjsAlertados: string[] = [];
	pagamentosAlertados: string[] = [];
	showNewVersion: boolean = true;
	lockFaturasRequest: boolean = false;

	chamadaClientes: Cliente[];

	/** Estabelecimento selection vars **/
	empresas: Empresa[];
	lojas: Loja[];
	_empresas: Empresa[];
	_lojas: Loja[];
	showLeftMenuContainerEst: boolean = false;
	showLeftMenuEst: boolean = false;
	allSelected: boolean = false;
	/** Fim estabelecimento selection vars */

	/** Observables */
	private obs = [];

	/** Funcs */
	isWidthMobile: () => boolean = isWidthMobile;

	constructor(
		private layoutConfigService: LayoutConfigService,
		private classInitService: ClassInitService,
		private sanitizer: DomSanitizer,
		private translationService: TranslationService,
		public router: Router,
		private pageConfigService: PageConfigService,
		private splashScreenService: SplashScreenService,
		private usuariosService: UsuariosService,
		private tokenStorare: TokenStorage,
		private cdRef: ChangeDetectorRef,
		private ngZone: NgZone,
		private _broadcaster: Broadcaster,
		private tokenStorage: TokenStorage,
		private empresasService: EmpresasService,
		private lojasService: LojasService,
		private authService: AuthenticationService,
		private http: HttpClient,
		private binaService: BinaService,
		private contratosService: ContratosService,
		private operacaoFiscalService: OperacaoFiscalService,
		private snackbar: MatSnackBar,
		private notaService: NotaFiscalService,
		/**
		 * Instanciação do SocketService necessária para o Broadcast localizado no SocketService
		 * poder ser usado no escopo da aplicação. Não remover ou achar uma solução melhor!
		 **/
		private socketService: SocketService,
		private estoquesService: EstoquesService,
		private utils: UtilsService,
		private estabelecimentosService: EstabelecimentosService
	) {
		super(router);
		globalThis.logout = () => { this.authService.logout(true) }

		this.updateVersionUser();

		// subscribe to class update event
		this.classInitService.onClassesUpdated$.subscribe(classes => {
			// get body class array, join as string classes and pass to host binding class
			setTimeout(() => this.classes = classes.body.join(' '));
		});

		this.layoutConfigService.onLayoutConfigUpdated$.subscribe(model => {
			this.classInitService.setConfig(model);

			this.style = '';
			if (objectPath.get(model.config, 'self.layout') === 'boxed') {
				const backgroundImage = objectPath.get(model.config, 'self.background');
				if (backgroundImage) {
					this.style = this.sanitizer.bypassSecurityTrustStyle('background-image: url(' + objectPath.get(model.config, 'self.background') + ')');
				}
			}

			// splash screen image
			this.splashScreenImage = objectPath.get(model.config, 'loader.image');
		});

		// register translations
		this.translationService.loadTranslations(ptBRLang, enLang, chLang, esLang, jpLang, deLang, frLang);

		// override config by router change from pages config
		this.router.events
			.pipe(filter(event => event instanceof NavigationEnd))
			.subscribe(event => {
				this.layoutConfigService.setModel({ page: objectPath.get(this.pageConfigService.getCurrentPageConfig(), 'config') }, true);
			});

		/**
		 * Observables
		 */
		this.obs.push(

			/** Se inscreve no evento de mudança de estabelecimento */
			this._broadcaster.on$(BroadcasterEvents.ESTABELECIMENTO_SELECIONADO).subscribe((data: Estabelecimento) => {
				this._lastEstUpdate = data._lastUpdate;
				delete localStorage.empresasInicial;
				delete localStorage.lojasInicial;
			}),

			/** Se inscreve no evento para adicionar uma notificação persistente */
			this._broadcaster.on$(BroadcasterEvents.ADICIONAR_NOTIFICACAO_PERSISTENTE).subscribe((data: Notificacao) => this.addPersistentNotification(data)),

			/** Se inscreve no evento para remover uma notificação persistente */
			this._broadcaster.on$(BroadcasterEvents.REMOVER_NOTIFICACAO_PERSISTENTE).subscribe((id: string) => this.removePersistentNotification(id)),

			/** Se inscreve nos eventos de pedidos */
			merge(
				this._broadcaster.on$(BroadcasterEvents.NOVO_PEDIDO),
				this._broadcaster.on$(BroadcasterEvents.PEDIDO_ATUALIZADO)
			).subscribe((p: Pedido) => checkPedidoNotifications([p], this._broadcaster)),

			/**
			 *  Eventos de chamada da BINA
			 */
			this._broadcaster.on$(BroadcasterEvents.CHAMADA_BINA).subscribe((c: Cliente) => this.setChamada(c)),

			this._broadcaster.on$(BroadcasterEvents.BINA_FECHAR_POPUP).subscribe((numero: string) => this.removerChamadaNumero(numero)),

			/**
			 *  Eventos de chamada da BINA
			 */
			this._broadcaster.on$(BroadcasterEvents.CHAMADA_BINA_ATENDIDA).subscribe((info: any) => this.chamadaAtendida(info)),

			/** Atualiza lista de empresas */
			this._broadcaster.on$(BroadcasterEvents.ATUALIZAR_EMPRESAS).subscribe(async (data) => {
				if ($isLogged()) {
					// this.atualizarListEmpresas();
				}
			}),

			/** Se inscreve no evento para abrir o menu de seleção de estabelecimento */
			this._broadcaster.on$(BroadcasterEvents.SELECT_EST).subscribe((id: string) => this.openLeftMenu()),

			/** Se inscreve no evento para remoção de estabelecimento selecionado */
			this._broadcaster.on$(BroadcasterEvents.REMOVER_ESTABELECIMENTO_SELECTION).subscribe((data: Estabelecimento) => this.removerEstabelecimento(data)),

			/**
			 * Eventos de login
			 */
			this._broadcaster.on$(BroadcasterEvents.ON_LOGIN).subscribe((data) => setTimeout(() => this.startStack(), 500)),

			/** Reseta algumas variaveis no logout */
			this._broadcaster.on$(BroadcasterEvents.ON_LOGOUT).subscribe((data) => this.resetStack()),

			this._broadcaster.on$(BroadcasterEvents.DESLOGAR).subscribe(() => this.authService.logout(true)),

			this._broadcaster.on$(BroadcasterEvents.REINICIAR).subscribe(() => history.go(0)),

			this._broadcaster.on$(BroadcasterEvents.POPUP_SEFAZ).subscribe((value: boolean) => {
				this.popupSefaz = value;
			}),
			// Faz com que o pop up de emissão de multiplas nfces apareça/desapaeça e 
			// seta o valor do número de notas fiscais que estão sendo emitidas. By Pedro Santos
			this._broadcaster.on$(BroadcasterEvents.EMITINDO_NF).subscribe((args: any) => {
				this.emitindoNf = args.emitindoNf;
				this.quantidadeNotas = args.quantidadeNotas || 0;
			}),

			this._broadcaster.on$(BroadcasterEvents.LOADING).subscribe((value: boolean) => {
				this.sisloading = value;
			}),

			this._broadcaster.on$(BroadcasterEvents.ESTABELECIMENTO_SELECIONADO).subscribe(() => {
				this.carregarCertificados();
				this.carregarFaturas();
			}),

			this._broadcaster.on$(BroadcasterEvents.BLOQUEIO_ESTOQUE).subscribe(({ idBloqueio, date }) => {
				if (!this.lockBloqueio) {
					this.lockBloqueio = true;
					_confirm(`Estoque do dia ${date} bloqueado. Deseja desbloquear?`).then(ok => {
						if (ok) {
							_senha_autorizacao().then(autorizado => {
								if (autorizado) {
									this.estoquesService.removerBloqueio(idBloqueio, AppComponent.ROUTE_ID).subscribe(
										() => {
											this.lockBloqueio = false;
											this._broadcaster.broadcast$(BroadcasterEvents.ATUALIZAR_BLOQUEIOS);
											_alert('Bloqueio removido. Tente realizar a operação novamente');
										},
										() => {
											this.lockBloqueio = false;
											_alert('Não foi possível remover o bloqueio');
										}
									)
								}
								else if (autorizado === false) {
									this.lockBloqueio = false;
									_alert('Senha inválida');
								}
							})
						}
						else {
							this.lockBloqueio = false;
						}
					})
				}
			}),

			/* Eventos dos scripts */
			merge(
				this._broadcaster.event$(BroadcasterEvents.SCRIPT_SUCCESS),
				this._broadcaster.event$(BroadcasterEvents.SCRIPT_ERROR),
				this._broadcaster.event$(BroadcasterEvents.SCRIPT_INTERROMPIDO)
			).subscribe(data => {
				let title = 'Atenção!';
				switch (data.key) {
					case BroadcasterEvents.SCRIPT_SUCCESS: title = 'Um script foi finalizado com sucesso'; break;
					case BroadcasterEvents.SCRIPT_ERROR: title = 'Um script foi finalizado com erro'; break;
					case BroadcasterEvents.SCRIPT_INTERROMPIDO: title = 'Um script foi interrompido'; break;
				}
				_alert(title, 'Seu arquivo de log está disponível para download');
			}),

			/** Altera as operações fiscais */
			merge(
				this._broadcaster.on$(BroadcasterEvents.OPERACAO_FISCAL_CHANGE),
				this._broadcaster.on$(BroadcasterEvents.ESTABELECIMENTO_SELECIONADO)
			).subscribe((data: Estabelecimento | boolean) => {
				if ($isLogged()) this.updateAtalhos();
			})
		);

		bus.on('CARREGAR_FATURAS', (estId) => {
			this.carregarFaturas(false, estId);
		});
	}

	ngOnInit(): void {

		if (localStorage.cnpjsAlertados) {
			if (localStorage.dataAlertada && moment(localStorage.dataAlertada).isBefore(moment().startOf('day'))) {
				delete localStorage.cnpjsAlertados;
				delete localStorage.dataAlertada;
			}
			else {
				this.cnpjsAlertados = JSON.parse(localStorage.cnpjsAlertados);
			}
		}

		if (localStorage.pagamentosAlertados) {
			if (localStorage.dataPagamentoAlertada && moment(localStorage.dataPagamentoAlertada).isBefore(moment().startOf('day'))) {
				delete localStorage.pagamentosAlertados;
				delete localStorage.dataPagamentoAlertada;
			}
			else {
				this.pagamentosAlertados = JSON.parse(localStorage.pagamentosAlertados);
			}
		}

		window.onkeydown = e => {
			if (e.altKey && e.ctrlKey && e.shiftKey && e.key == 'O') {
				this.playAudio('../../../../../assets/app/media/audio/olha-o-gas.mp3');
			}
		};

		//this.checkVoip();

		if (moment().isBetween(moment('12-01', 'MM-DD').startOf('day'), moment('12-25', 'MM-DD').endOf('day'))) {
			const christmasIco = './assets/app/media/img/logos/logo-vendergas-christmas.png';
			document.title = `${document.title} v${AppConfig.CONFIG.version} BUILD ${AppConfig.CONFIG.build}`;
			(() => {
				const link = document.querySelector('link[rel*=\'icon\']') || document.createElement('link') as any;
				link.type = 'image/png';
				link.rel = 'icon';
				link.href = christmasIco;
				document.getElementsByTagName('head')[0].appendChild(link);
			})();
		}
		else if (!environment.production) {
			const bugIco = './assets/app/media/img/logos/bug.png';
			document.title = `${document.title} v${AppConfig.CONFIG.version} BUILD ${AppConfig.CONFIG.build}`;
			(() => {
				const link = document.querySelector('link[rel*=\'icon\']') || document.createElement('link') as any;
				link.type = 'image/png';
				link.rel = 'icon';
				link.href = bugIco;
				document.getElementsByTagName('head')[0].appendChild(link);
			})();
		}

		if ($isLogged()) this.startStack();

		// if ($isLogged()) this.openLeftMenu();

		/** Se inscreve no evento de scroll */
		window.addEventListener('scroll', this.$scrollPositionListener, { passive: true });

		this.$scrollPositionListener();

		this.verificarPopUpChamadaDragActive();

		this.setDefaultDateOnCache(DATE_FILTER_TODAY_CORRECTION.dataInicio, DATE_FILTER_TODAY_CORRECTION.dataFim);

		this._broadcaster.broadcast$(BroadcasterEvents.SHOW_ESTABELECIMENTOS_SELECTED, true);
	}

	async checkCertExpiration() {
		const info = await this.utils.certInfo();
		for (const cert of info) {
			if (cert.daysRemaining <= 15) {
				_alert(`O certificado SSL de ${cert.name} expira em ${cert.daysRemaining} dias`);
			}
		}
	}

	ngOnDestroy(): void {

		this.resetStack();

		/** Se desinscreve dos observables */
		(this.obs || []).forEach((i) => {
			i.unsubscribe();
		});

		/** Se desinscreve no evento de scroll */
		window.removeEventListener('scroll', this.$scrollPositionListener);

		bus.off('CARREGAR_FATURAS');
	}

	ngAfterViewInit(): void {
		if (this.splashScreen) {
			this.splashScreenService.init(this.splashScreen.nativeElement);
		}
	}

	detectChanges() { try { this.cdRef.detectChanges(); } catch (err) { } }

	async updateAtalhos() {
		try {
			AppComponent.ATALHOS_OPFIS = await this.operacaoFiscalService.getAll(
				AppComponent.ROUTE_ID,
				undefined,
				undefined,
				new OperacaoFiscalFilter({
					ativo: true,
				})
			).toPromise<OperacaoFiscal[]>();

			this._broadcaster.broadcast$(BroadcasterEvents.OPERACAO_FISCAL_CHANGE_NEW_VALUE, {});

			this.detectChanges();
		} catch (err) { console.log(err); }
	}

	$scrollPositionListener() {
		const value = (window.pageYOffset || document.documentElement.scrollTop) - (document.documentElement.clientTop || 0);
		if (value < 160) {
			document.body.classList.remove('past-top-menu');
		} else if (value >= 160) {
			document.body.classList.add('past-top-menu');
		}
	}

	buscarEstabelecimento(qs: string) {
		if (qs) {
			this._empresas = this.empresas.filter(empresa => this.simplify(empresa.nomeFantasia).includes(this.simplify(qs)) || this.simplify(empresa.razaoSocial).includes(this.simplify(qs)));
			this._lojas = this.lojas.filter(empresa => this.simplify(empresa.nomeFantasia).includes(this.simplify(qs)) || this.simplify(empresa.razaoSocial).includes(this.simplify(qs)));
		}
		else {
			this._empresas = [...this.empresas];
			this._lojas = [...this.lojas];
		}
	}

	carregarUnquieCertificado(cnpjs: string[]): Promise<any[]> {
		return new Promise((resolve, reject) => {
			this.notaService.obterTodosCertificado(cnpjs, AppComponent.ROUTE_ID).subscribe(
				res => {
					resolve(res);
				},
				err => {
					reject(err)
				}
			)
		})
	}

	carregarUnquieFaturas(ids: string[]): Promise<any[]> {
		return new Promise((resolve, reject) => {
			this.estabelecimentosService.obterFaturas(ids, AppComponent.ROUTE_ID).subscribe(
				res => {
					resolve(res);
				},
				err => {
					reject(err)
				}
			)
		})
	}

	async carregarCertificados() {
		const estOnCache = this.getEstabelecimentoOnCache();
		const color = '#FFA500';
		const font = '#000000';
		if (estOnCache) {
			const empresas = estOnCache.multiSelecao.filter(est => est.tipoEstabelecimento == TipoEstabelecimento.EMPRESA);
			const lojas = estOnCache.multiSelecao.filter(est => est.tipoEstabelecimento == TipoEstabelecimento.LOJA);
			const certificados = await this.carregarUnquieCertificado(empresas.concat(lojas).map(est => (est.empresa || est.loja).cnpj).filter(cnpj => !!cnpj).map(cnpj => cnpj.replace(/[^\d]/g, '')));
			for (const certificado of certificados) {
				const estName = certificado.certificatename.match(/CN=(.+):/)[1];
				const mom = moment(certificado.validatedate);
				const date = mom.format('DD/MM/YYYY');
				const time = mom.format('HH:mm');
				if (!this.cnpjsAlertados.includes(certificado.cnpjsoftwarehouse) && moment(certificado.validatedate).isSameOrAfter(moment())) {
					if (moment(certificado.validatedate).isSame(moment(), 'day')) {
						await this._alert(`O certificado de ${estName} vence hoje às ${time}`, undefined, undefined, true, color, font);
						this.cnpjsAlertados.push(certificado.cnpjsoftwarehouse);
					}
					else if (certificado.leftDays <= 10) {
						await this._alert(`O certificado de ${estName} vencerá em ${date} às ${time}`, undefined, undefined, true, color, font);
						this.cnpjsAlertados.push(certificado.cnpjsoftwarehouse);
					}
				}
			}
			localStorage.cnpjsAlertados = JSON.stringify(this.cnpjsAlertados);
			if (!localStorage.dataAlertada) {
				localStorage.dataAlertada = moment().toISOString();
			}
		}
	}

	recebido(fatura) {
		return fatura.status == 'RECEIVED' || fatura.status == 'CONFIRMED' || fatura.status == 'RECEIVED_IN_CASH';
	}

	async carregarFaturas(checkRepeated = true, estId = null) {
		const estOnCache = this.getEstabelecimentoOnCache();
		const color = '#ffd280';
		const font = '#000000';
		if ((estOnCache || estId) && !this.lockFaturasRequest) {
			this.lockFaturasRequest = true;
			let ids = [];
			if (estId) {
				ids = [estId];
			}
			else {
				const empresas = estOnCache.multiSelecao.filter(est => est.tipoEstabelecimento == TipoEstabelecimento.EMPRESA);
				const lojas = estOnCache.multiSelecao.filter(est => est.tipoEstabelecimento == TipoEstabelecimento.LOJA);
				ids = empresas.concat(lojas).map(est => (est.empresa || est.loja)._id);
			}
			const estabelecimentos = await this.carregarUnquieFaturas(ids);
			for (const estabelecimento of estabelecimentos) {
				if (!this.pagamentosAlertados.includes(estabelecimento._id) || !checkRepeated) {
					const fatura0 = estabelecimento.faturas[0];
					const fatura1 = estabelecimento.faturas[1];
					const fatura = !fatura1 ? fatura0 : !this.recebido(fatura1) && !this.recebido(fatura0) ? fatura1 : fatura0;
					const mom = moment(fatura.dueDate).endOf('day');
					const date = mom.format('DD/MM/YYYY');
					const diff = mom.diff(moment(), 'days');
					let text = null;
					let subText = null;
					if (!this.recebido(fatura)) {
						if (fatura.status == 'OVERDUE' || mom.isBefore(moment())) {
							if (diff == 0) {
								text = `A fatura de ${estabelecimento.nomeFantasia} venceu em hoje`;
							}
							else {
								text = `A fatura de ${estabelecimento.nomeFantasia} venceu em ${date}`;
							}
							const blockDay = mom.add(10, 'days');
							if (moment().isSame(blockDay, 'day')) {
								subText = `Se não reconhecermos seu pagamento até o final do dia, seu sistema será bloqueado.`;
							}
							else if (moment().isBefore(blockDay)) {
								subText = `Se não reconhecermos seu pagamento até dia ${blockDay.format('DD/MM/YYYY')}, seu sistema será bloqueado.`;
							}
							else {
								subText = 'Seu sistema foi bloqueado. Efetue o pagamento para liberarmos o seu acesso.';
							}
						}
						else if (diff == 0) {
							text = `A fatura de ${estabelecimento.nomeFantasia} vence hoje`;
						}
						else if (diff <= 5) {
							text = `A fatura de ${estabelecimento.nomeFantasia} vence em ${diff} dias`;
						}
						if (text) {
							const gotopay = await this._confirm(text, 'Ver fatura', 'Fechar', subText, color, font);
							if (checkRepeated) {
								this.pagamentosAlertados.push(estabelecimento._id);
							}
							if (gotopay) {
								window.open(fatura.invoiceUrl, '_blank');
							}
						}
					}
				}
				if (checkRepeated) {
					localStorage.pagamentosAlertados = JSON.stringify(this.pagamentosAlertados);
					if (!localStorage.dataPagamentoAlertada) {
						localStorage.dataPagamentoAlertada = moment().toISOString();
					}
				}
			}
			this.lockFaturasRequest = false;
		}
	}

	verificarLoginExpirado() {
		const user: Usuario = this.tokenStorage.getUserInfo();
		if ((user.tokenExpiration - Date.now()) <= 0) {
			this.authService.logout();
		}
		else {
			//Conpass.startFlow("vxX3p2J97u0Yt");
			//Conpass.startNps("DC6tPNwLQqScn");
			if (this.showNewVersion) {
				this.showNewVersion = false;
			}
		}
	}

	/* tslint:disable */ showPersisentNotifications: boolean = true; /* tslint:enable */
	async startStack() {
		this._broadcaster.broadcast$(BroadcasterEvents.CHANGE_USER_PROFILE, true);

		this.userInfo = $getUserInfo({});
		this.socket = SocketService.getSocket();

		if (window.Conpass) {
			window.Conpass.init({
				name: this.userInfo.nome,
				email: this.userInfo.email,
			});
		}

		if (this._interval_checkPersistentNotification != null) clearInterval(this._interval_checkPersistentNotification);
		this._interval_checkPersistentNotification = setInterval(() => {
			if (!$isLogged()) {
				this.persistentNotifications = [];
				this.detectChanges();
			}
			let IS_ON_NEW_CART = (this.router.url || '').includes('/lancar-pedido') || (this.router.url || '').includes('/lancar-pedido-rapido');
			this.showPersisentNotifications = !IS_ON_NEW_CART || (IS_ON_NEW_CART && localStorage.getItem(TokenStorage.SHOW_NEW_ORDER_NOTIFICATION_ON_CART) != 'false');
			if (
				(this.persistentNotifications || []).filter(not => not.showPopUp).length > 0 &&
				this.showPersisentNotifications &&
				(localStorage.getItem(TokenStorage.PLAY_NOTIFICATION_SOUND) != 'false') &&
				!this.userInfo.isAdmin
			) {
				this.playAudio('../../../../../assets/app/media/audio/quite-impressed.mp3');
			}
		}, 1000);

		if (this._interval_checkEstabelecimentoChanged != null) clearInterval(this._interval_checkEstabelecimentoChanged);
		this._interval_checkEstabelecimentoChanged = setInterval(() => {
			if ($isLogged()) {
				let est = $getEstabelecimentoOnCache();
				if (est && this._lastEstUpdate != est._lastUpdate && est._callBroadcast) {
					this._broadcaster.broadcast$(BroadcasterEvents.ESTABELECIMENTO_SELECIONADO, est);
				}
			}
		}, 1000);

		if (this._interval_checkNovaChamada != null) clearInterval(this._interval_checkNovaChamada);
		this._interval_checkNovaChamada = setInterval(() => {
			if ($isLogged() && (this.chamadaClientes || []).length > 0 && localStorage.getItem(TokenStorage.PLAY_BINA_SOUND) != 'false') {
				this.playAudio('../../../../../assets/app/media/audio/to-the-point.mp3');
			}
		}, 700);

		if (this._interval_checkLoginExpirado != null) clearInterval(this._interval_checkLoginExpirado);
		this._interval_checkLoginExpirado = setInterval(() => {
			if ($isLogged()) this.verificarLoginExpirado();
		}, 5000);

		// this.estabelecimento = $getEstabelecimentoOnCache() || new Estabelecimento(TipoEstabelecimento.MULTI_SELECAO, null, null);
		this.estabelecimento = new Estabelecimento(TipoEstabelecimento.MULTI_SELECAO, null, null);

		this.isLogged = true;
		//this.podeMostrarPopUp();
		this.ignorarPopUp = localStorage.getItem(TokenStorage.IGNORAR_POP_UP) != 'false';

		this.updateAtalhos();

		await this.atualizarListEmpresas(false);

		if (!this.userInfo.isAdmin) {
			this.toggleTodosEstabelecimentos();
		}
		else {
			this.checkCertExpiration();
		}

		this.carregarCertificados();
		this.carregarFaturas();

		this.checkChat();

		if (moment().isBefore(new Date(2022, 0, 1, 18, 30))) {
			_alert('Comunicado Atendimento Final de Ano Sistema Vendergas', 'Informamos que na sexta-feira 31/12/2021 excepcionalmente o suporte irá funciona de 08:30 as 12:30 Hr, sábado dia 01/01/2022 não haverá atendimento. Voltaremos na segunda-feira 03/01/2022 em atendimento no horário normal de 08:30 às 18:30 H.<br>Desejamos a todos boas festas e um feliz ano novo!<br>Suporte Vendergas');
		}

		this.detectChanges();
	}

	checkChat() {
		if (Tawk_Loaded) {
			Tawk_API.showWidget();
			Tawk_API.setAttributes({
				name: this.isOnDevelopment ? 'DESENVOLVEDOR' : `${this.userInfo.nome} (${(this.userInfo.empresaPrincipal || this.userInfo.lojaPrincipal || { nomeFantasia: 'Sem estabelecimento' }).nomeFantasia})`
			}, function (error) { });
		}
		else {
			setTimeout(() => {
				this.checkChat();
			}, 1000);
		}
	}

	resetStack() {
		this.empresas = [];
		this.lojas = [];
		this._empresas = [];
		this._lojas = [];
		this.persistentNotifications = [];
		this.allSelected = false;

		if (this._interval_checkPersistentNotification != null) clearInterval(this._interval_checkPersistentNotification);
		if (this._interval_checkEstabelecimentoChanged != null) clearInterval(this._interval_checkEstabelecimentoChanged);
		if (this._interval_checkNovaChamada != null) clearInterval(this._interval_checkNovaChamada);
		if (this._interval_checkLoginExpirado != null) clearInterval(this._interval_checkLoginExpirado);

		this.isLogged = false;
		if (this.socket) this.socket.close();

		if (Tawk_Loaded) {
			Tawk_API.endChat();
			Tawk_API.hideWidget();
		}

		this.detectChanges();
	}

	updateVersionUser() {
		let user = this.tokenStorare.getUserInfo();
		if (user != null) {
			;
			this.usuariosService.update({ idUsuario: user.idUsuario, lastVersion: AppConfig.getVersion() }).subscribe(
				res => { },
				error1 => console.log(error1)
			);
		}
	}

	/* tslint:disable */
	audioIsPlaying: boolean = false;

	playAudio(src: string = '../../../../../assets/app/media/audio/quite-impressed.mp3') {
		if (!this.audioIsPlaying) {
			const _audio = new Audio();
			_audio.src = src;
			_audio.loop = false;
			this.amplifyMedia(_audio, 4);
			_audio.addEventListener('ended', () => this.audioIsPlaying = false, false);
			_audio.play();
			this.audioIsPlaying = true;
		}
	}

	/* tslint:enable */

	amplifyMedia(audio, multiplier) {
		let context = new (window.AudioContext || window.webkitAudioContext);
		let result = {
			context: context,
			source: context.createMediaElementSource(audio),
			gain: context.createGain(),
			media: audio,
			amplify: (m) => result.gain.gain.value = m,
			getAmpLevel: () => result.gain.gain.value
		};
		result.source.connect(result.gain);
		result.gain.connect(context.destination);
		result.amplify(multiplier);
		return result;
	}

	closePDFViewer() {
		(document.getElementById('vendergas-pdf-viewer') as any).style.display = 'none';
		(document.getElementById('pdf-viewer-container') as any).innerHTML = '';
	}

	addPersistentNotification(n: Notificacao) {
		if (n) {
			if (this.persistentNotifications == null) this.persistentNotifications = [];

			for (const pn of this.persistentNotifications) if (pn.idNotificacao == n.idNotificacao) return;

			if (n.tags.length > 0) n['class'] = NOTIFICATION_CLASSES[n.tags[0]];

			n.showPopUp = false;

			setTimeout(() => {
				n.showPopUp = true;
				this.detectChanges();
			}, 100);

			this.persistentNotifications.unshift(n);
		}
	}

	removePersistentNotification(id: string) {
		if (this.persistentNotifications) {
			this.persistentNotifications = this.persistentNotifications.filter((v) => v.idNotificacao != id);
			this.detectChanges();
		}
	}

	/**
	 * ESTABELECIMENTO
	 */
	closeLeftMenu() {
		this.showLeftMenuEst = false;
		const ctx = this;
		setTimeout(() => {
			ctx.showLeftMenuContainerEst = false;
			ctx.detectChanges();
		}, 100);
	}

	openLeftMenu() {
		this.showLeftMenuContainerEst = true;
		const ctx = this;
		setTimeout(() => {
			ctx.showLeftMenuEst = true;
			ctx.detectChanges();
		}, 100);
	}

	async atualizarListEmpresas(verificarEstabeleciemntosEmCache: boolean = false) {

		this.toggleEstLoading(true);

		try {
			const _empresas = await this.empresasService.getAll().toPromise<any[]>();
			const _lojas = await this.lojasService.getAll().toPromise<any[]>();

			const ASGas = _empresas.find(emp => emp._id == '5d1b96e5a876ed3cbb556c6e');
			const SilvaGas = _empresas.find(emp => emp._id == '5d1399737e07b763d5e843eb');

			if (ASGas && SilvaGas && !this.userInfo.isAdmin) {
				SilvaGas['disabled'] = true;
			}

			this.clearEmpresaObject(_empresas);

			this.empresas = _empresas.filter(emp => {
				if (emp.isAtivo == true) {
					emp._uniqueId = getRandomLetter(4) + Date.now();
					return true;
				}
				return false;
			});

			this.lojas = _lojas.filter(loj => {
				if (loj.isAtivo == true) {
					loj._uniqueId = getRandomLetter(4) + Date.now();
					return true;
				}
				return false;
			});

			this._empresas = [...this.empresas];
			this._lojas = [...this.lojas];

			this.detectChanges();
		} catch (_err) {
			this.empresas = [];
			this.lojas = [];
			this._empresas = [];
			this._lojas = [];
			console.log(_err);
		}

		this.toggleEstLoading(false);
	}

	toggleTodosEstabelecimentos(callBroadcast: boolean = true) {
		this.toggleEstLoading(true);

		let options: any = {
			action: this.allSelected ? 'remove' : 'insert',
			updateCache: false
		};

		for (const emp of this.empresas || []) {
			if (!emp['disabled']) {
				let wasRemoved = this.toggleEmpresa(emp, false, options, true);

				if (!wasRemoved) {

					// Seleciona as lojas dessa empresa
					/*
					for (const loj of emp.lojas || []) {
						this.toggleLoja(loj, false, {
							action: 'insert',
							updateCache: false
						});
					}
					*/
				}
			}
		}

		for (const loj of this.lojas || []) {
			let wasRemoved = this.toggleLoja(loj, false, options);
		}

		this.setEstabelecimentoOnCache(this.estabelecimento, callBroadcast);

		this.checkIfAllSelected();

		this.toggleEstLoading(false);
	}

	removerEstabelecimento(est: Estabelecimento) {
		let options = <any>{ action: 'remove', updateCache: true };
		if (est.empresa != null) this.toggleEmpresa(est.empresa, true, options);
		else if (est.loja != null) this.toggleLoja(est.loja, true, options);
	}

	toggleEmpresa(
		e: Empresa,
		callBroadcast: boolean = true,
		options?: { action: 'remove' | 'insert', updateCache: boolean },
		toggleSubLojas?: boolean
	): boolean {

		let removed = false;

		if (options) {
			removed = options.action == 'remove';
			if (options.action == 'remove') {
				this.estabelecimento.multiSelecao = this.estabelecimento.multiSelecao.filter(est => {
					if ((est.empresa || <any>{}).idEmpresa == e.idEmpresa) {
						if (est.empresa) est.empresa.isSelected = !removed;
						return false;
					} else {
						return true;
					}
				});
			}
		} else {

			this.estabelecimento.multiSelecao = this.estabelecimento.multiSelecao.filter(est => {
				if (est.empresa && est.empresa.idEmpresa == e.idEmpresa) {
					removed = true;
					return false;
				}
				return true;
			});
		}

		if (!removed && this.estabelecimento.multiSelecao.find(_e => (_e.empresa || <Empresa>{}).idEmpresa === e.idEmpresa) == null) {
			this.estabelecimento.multiSelecao.push(new Estabelecimento(TipoEstabelecimento.EMPRESA, e, null));
		} else {
			if (toggleSubLojas == true) {

				// Deseleciona as lojas dessa empresa
				this.estabelecimento.multiSelecao = this.estabelecimento.multiSelecao.filter(est => (est.loja || <any>{}).empresaIdEmpresa != e.idEmpresa);
				for (const loj of e.lojas) loj.isSelected = false;
			}
		}

		e.isSelected = !removed;

		if (options == null || options.updateCache != false) this.setEstabelecimentoOnCache(this.estabelecimento, callBroadcast);

		this.checkIfAllSelected();

		return removed;
	}

	toggleLoja(l: Loja, callBroadcast: boolean = true, options?: { action: 'remove' | 'insert', updateCache: boolean }): boolean {

		let removed = false;

		if (options) {

			removed = options.action == 'remove';

			if (options.action == 'remove') {
				this.estabelecimento.multiSelecao = this.estabelecimento.multiSelecao.filter(est => {
					if ((est.loja || <any>{}).idLoja == l.idLoja) {
						if (est.loja) est.loja.isSelected = !removed;
						return false;
					} else {
						return true;
					}
				});
			}
		} else {
			this.estabelecimento.multiSelecao = this.estabelecimento.multiSelecao.filter(est => {
				if (est.loja && est.loja.idLoja == l.idLoja) {
					removed = true;
					return false;
				}
				return true;
			});
		}

		if (!removed && this.estabelecimento.multiSelecao.find(_e => (_e.loja || <Loja>{}).idLoja === l.idLoja) == null) {
			this.estabelecimento.multiSelecao.push(new Estabelecimento(TipoEstabelecimento.LOJA, null, l));
		}

		l.isSelected = !removed;

		if (options == null || options.updateCache != false) this.setEstabelecimentoOnCache(this.estabelecimento, callBroadcast);

		this.checkIfAllSelected();

		return removed;
	}

	checkIfAllSelected() {
		let isAllSelected = true;

		empresaIt: for (const emp of this.empresas || []) {
			if (emp.isSelected == true) {
				isAllSelected = true;
				/*
				for (const loj of emp.lojas || []) {
					if (loj.isSelected != true) {
						isAllSelected = false;
						break empresaIt;
					}
				}
				*/
			} else if (!emp['disabled']) {
				isAllSelected = false;
				break;
			}
		}

		if (isAllSelected) {
			for (const loj of this.lojas || []) {
				if (loj.isSelected != true) {
					isAllSelected = false;
					break;
				}
			}
		}

		this.allSelected = isAllSelected;

		this.detectChanges();
	}

	temLojaParaSelecionar() {
		let result = false;
		if (this.estabelecimento && (this.estabelecimento.multiSelecao || []).length > 0) {
			for (let est of this.estabelecimento.multiSelecao) {
				if (est.empresa && est.empresa.lojas && est.empresa.lojas.length > 0) {
					result = true;
					break;
				}
			}
		}
		return result;
	}

	toggleEstLoading(show: boolean) {
		this._broadcaster.broadcast$(BroadcasterEvents.TOGGLE_EST_LOADING, show);
	}

	iniciarChamada(chamadaCliente: Cliente) {
		if (chamadaCliente != null) {
			chamadaCliente['carregando'] = true;
			this.socket.emit('bina_atendida', {
				...chamadaCliente,
				usuarioQueAtendeu: this.userInfo.idUsuario
			});
			this.binaService.iniciarPedido(chamadaCliente.idChamada, AppComponent.ROUTE_ID).subscribe(
				() => {
					chamadaCliente['carregando'] = false;
					this.socket.emit('bina_fechar_popup', chamadaCliente.telefone);
				},
				() => {
					chamadaCliente['carregando'] = true;
					this.socket.emit('bina_fechar_popup', chamadaCliente.telefone);
				}
			);
		}
	}

	setChamada(chamadaCliente: Cliente) {
		if (this.chamadaClientes == null) this.chamadaClientes = [];
		if (this.chamadaClientes.find(c => c.idCliente == chamadaCliente.idCliente || c.telefone == chamadaCliente.telefone) === undefined) {
			this.chamadaClientes.push(chamadaCliente);
			setTimeout(() => {
				this.dragElement(document.getElementById(`pop-up-chamada-${chamadaCliente.idChamada}`), document.getElementById(`pop-up-chamada-title-${chamadaCliente.idChamada}`));
			}, 500);
		}
		this.detectChanges();
	}

	verificarPopUpChamadaDragActive() {
		setInterval(() => {
			(this.chamadaClientes || []).forEach(c => {
				let element = document.getElementById(`pop-up-chamada-${c.idCliente}`);
				if (element != null && element['dragActive'] != true) this.dragElement(document.getElementById(`pop-up-chamada-${c.idChamada}`), document.getElementById(`pop-up-chamada-title-${c.idChamada}`));
			});
		}, 1000);
	}

	removeChamada(chamadaCliente: Cliente) {
		if (this.chamadaClientes == null) this.chamadaClientes = [];
		this.chamadaClientes = this.chamadaClientes.filter(c => c.telefone != chamadaCliente.telefone);
		this.socket.emit('bina_fechar_popup', { telefone: chamadaCliente.telefone, chamada: chamadaCliente.idChamada, usuario: $getUserInfo().idUsuario });
		this.detectChanges();
	}

	removerChamadaNumero(numero: string) {
		if (this.chamadaClientes == null) this.chamadaClientes = [];
		this.chamadaClientes = this.chamadaClientes.filter(c => c.telefone != numero);
		this.detectChanges();
	}

	chamadaAtendida(info: { idCliente: string, telefone: string, usuarioQueAtendeu: string, empresas: Empresa[], lojas: Loja[], voip: boolean }) {
		if (info) {
			this.removeChamada(<Cliente>{
				idCliente: info.idCliente,
				telefone: info.telefone
			});

			if (info.usuarioQueAtendeu == this.userInfo.idUsuario) {
				if (!(this.router.url || '').includes('/lancar-pedido') || (this.router.url || '').includes('/lancar-pedido-rapido')) {
					this.router.navigate(['/lancar-pedido'], {
						queryParams: {
							...((info || <Cliente>{}).idCliente != null ? { idClienteChamada: info.idCliente } : {}),
							...((info || <Cliente>{}).telefone != null ? { telefoneClienteChamada: info.telefone } : {}),
							...(((info || <Cliente>{}).empresas || []).length > 0 ? { empresas: info.empresas.join(',') } : {}),
							...(((info || <Cliente>{}).lojas || []).length > 0 ? { lojas: info.lojas.join(',') } : {}),
							voip: info.voip
						}
					});
				} else {
					this._broadcaster.broadcast$(BroadcasterEvents.CHAMADA_BINA_INICIADA, { ...(info || {}) });
				}
			}
		}
	}

	primeiroIndex(): number {
		let indexToValidate = 0;
		for (let notif of (this.persistentNotifications || [])) {
			if (notif.showPopUp) break;
			else ++indexToValidate;
		}
		return indexToValidate;
	}

	vezesPopUpMostrado: () => number = () => parseInt(localStorage.getItem(TokenStorage.POP_UP_SHOWED_TIMES) || '0');

	podeMostrarPopUp() {
		const build = Number(AppConfig.getBuild());
		const userBuild = Number(localStorage.build || 0);
		let buildCount = Number(localStorage.buildCount || 0);
		if ((localStorage.getItem(TokenStorage.IGNORAR_POP_UP) != 'true') && userBuild < build && this.isLogged) {
			this.mostrarPopUp = true;
			buildCount = 1;
		}
		else if (buildCount < 2) {
			this.mostrarPopUp = true;
			buildCount++
		}
		else {
			this.mostrarPopUp = false;
		}
		localStorage.buildCount = buildCount;
		localStorage.build = build;
	}

	fecharPopUp() {
		this.mostrarPopUp = false;
		let currentShowedTimes = this.vezesPopUpMostrado();

		localStorage.setItem(TokenStorage.POP_UP_SHOWED_TIMES, `${currentShowedTimes + 1}`);
		localStorage.setItem(TokenStorage.IGNORAR_POP_UP, `${this.ignorarPopUp}`);

		this.detectChanges();
	}

	connectVoipSocket(data, host) {
		const instanceId = Math.round(Math.random() * 10 ** 10);
		const serviceEndpoint = '/hubgetsb/ws/' + data.device_id + '/' + instanceId;

		const socket = io.connect(host, {
			query: 'access_token=' + data.access_token,
			transports: ['websocket'],
			path: serviceEndpoint
		});

		socket.on('connect', () => {
			this._broadcaster.broadcast$(BroadcasterEvents.VOIP_INICIADO, true);
		});

		socket.on('message', (data) => {
			console.log(data);
		});

		socket.on('disconnect', () => {
			this._broadcaster.broadcast$(BroadcasterEvents.VOIP_INICIADO, false);
		});

		socket.on('error', (err) => {
			console.log('VOIP ERROR: ' + err);
		});
	}

	async checkVoip() {
		const url = new URL(location.href);
		const code = url.searchParams.get('code');
		const error = url.searchParams.get('error');
		const error_description = url.searchParams.get('error_description');
		const user: Usuario = this.tokenStorage.getUserInfo();
		const voip_data = localStorage.voip_data ? JSON.parse(localStorage.voip_data) : null;
		const host = user.voip_host;

		if (voip_data) {
			this.connectVoipSocket(voip_data, host);
		}
		else if (code) {
			const client_id = user.voip_client_id;
			const client_secret = user.voip_client_secret;

			const { data } = await axios.post(`${AppConfig.getAPIEndpoint()}/apis/voip`, {
				code,
				client_id,
				host,
				client_secret
			});

			localStorage.voip_data = JSON.stringify(data);
			this.connectVoipSocket(data, host);
		}
		else if (error) {
			_alert(error_description);
		}
	}
}
