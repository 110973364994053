import {Injectable} from '@angular/core';
import {ServicePattern} from '../../models/interface/ServicePattern';
import {Loja} from '../../models/api/Loja';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {TokenStorage} from '../../auth/token-storage.service';
import {Observable} from 'rxjs';
import {AppConfig} from '../../../config/appConfig';
import {ResultObject} from '../../models/api/ResultObject';
import {EstabelecimentoFunctions} from '../../models/interface/EstabelecimentoFunctions';

@Injectable()
export class LojasService extends EstabelecimentoFunctions implements ServicePattern<Loja> {

	constructor(private http: HttpClient, private tokenStorage: TokenStorage) {
		super();
	}


	getAll(
		dateFilter: { dataInicio: Date, dataFim: Date } = { dataInicio: null, dataFim: null },
		componentId: string = '',
		page: number = null,
		perPage: number = null,
		searchString: string = null,
		nome: string = null,
		cnpj: string = null,
		ativo: string = null,
		estadoAcronimo: string = null,
		sisContratados: string[] = null,
		plano: string = null,
		perfil: string = null,
		ultimaInteracao?: number,
		popup: boolean = false
	): Observable<Loja[]> {
		return this.http.get<Loja[]>(
			AppConfig.getAPIEndpoint() + '/lojas/list/' + this.tokenStorage.getUserInfo().idUsuario,
			{
				headers: AppConfig.getHTTPHeader().headers.set('componentId', componentId),
				params: {
					...this.getDateFilterQueryParam(dateFilter.dataInicio, dateFilter.dataFim),
					...(page || page == 0 ? { page: page + 1 } : {}),
					...(perPage ? { per_page: perPage } : {}),
					...(searchString != null ? { searchString: searchString } : {}),
					...(nome ? { nome } : {}),
					...(cnpj ? { cnpj } : {}),
					...(ativo ? { ativo } : {}),
					...(estadoAcronimo ? { estadoAcronimo } : {}),
					...((sisContratados || []).length > 0 ? { sisContratados: sisContratados.join(',') } : {}),
					...(plano ? { plano } : {}),
					...(perfil ? { perfil } : {}),
					...(ultimaInteracao ? { ultimaInteracao } : {}),
					popup: popup ? 'true' : 'false'
				}
			}
		);
	}

	get(idLoja: number, componentId: string = ''): Observable<Loja> {
		return this.http.get<Loja>(
			AppConfig.getAPIEndpoint() + '/lojas/' + idLoja + '/' + this.tokenStorage.getUserInfo().idUsuario,
			{
				headers: AppConfig.getHTTPHeader().headers.set('componentId', componentId)
			}
		);
	}
	getContagemPorEstados(componentId: string = ''): Observable<Loja> {
		return this.http.get<Loja>(
			AppConfig.getAPIEndpoint() + '/lojas/contagemPorEstados/' + this.tokenStorage.getUserInfo().idUsuario,
			{
				headers: AppConfig.getHTTPHeader().headers.set('componentId', componentId)
			}
		);
	}

	save(body: Loja, componentId: string = ''): Observable<ResultObject<any>> {
		return this.http.post<ResultObject<any>>(
			AppConfig.getAPIEndpoint() + '/lojas/' + this.tokenStorage.getUserInfo().idUsuario,
			body,
			{
				headers: AppConfig.getHTTPHeader().headers.set('componentId', componentId)
			}
		);
	}

	update(body: Loja, componentId: string = ''): Observable<ResultObject<any>> {
		return this.http.put<ResultObject<any>>(
			AppConfig.getAPIEndpoint() + '/lojas/' + this.tokenStorage.getUserInfo().idUsuario,
			body,
			{
				headers: AppConfig.getHTTPHeader().headers.set('componentId', componentId)
			}
		);
	}

	delete(idLoja: number, componentId: string = ''): Observable<ResultObject<any>> {
		return this.http.delete<ResultObject<any>>(
			AppConfig.getAPIEndpoint() + '/lojas/' + this.tokenStorage.getUserInfo().idUsuario + '/' +
			idLoja,
			{
				headers: AppConfig.getHTTPHeader().headers.set('componentId', componentId)
			}
		);
	}

	getEntriesCount(componentId: string = ''): Observable<{entries: number}> {
		return this.http.get<{entries: number}>(
			AppConfig.getAPIEndpoint() + '/lojas/list/' + this.tokenStorage.getUserInfo().idUsuario,
			{
				headers: AppConfig.getHTTPHeader().headers.set('componentId', componentId),
				params: {
					...this.getEstabelecimentoQueryParam(),
					entriesOnly: true
				}
			}
		);
	}

	salvaCertificado(body: Loja, componentId: string = ''): Observable<ResultObject<any>> {
		let formData = new FormData();
		formData.append('senhaCertificado', body.senhaCertificado);
		formData.append('certificado', body.arquivoCertificado[0]);
		return this.http.post<ResultObject<any>>(
			AppConfig.getAPIEndpoint() + '/lojas/upload_certificado/' + body.idLoja + '/' + this.tokenStorage.getUserInfo().idUsuario,
			formData,
			{
				headers: new HttpHeaders({
					'Cache-Control': 'no-cache',
					'componentId': componentId
				})
			}
		);
	}

	salvarToken(body: Loja, componentId: string = ''): Observable<ResultObject<any>> {
		return this.http.post<ResultObject<any>>(
			AppConfig.getAPIEndpoint() + '/lojas/cadastrar_token/' + body.idLoja + '/' + this.tokenStorage.getUserInfo().idUsuario,
			body,
			{
				headers: AppConfig.getHTTPHeader().headers.set('componentId', componentId)
			}
		);
	}

}
