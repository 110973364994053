import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {TokenStorage} from '../../auth/token-storage.service';
import {EstabelecimentoFunctions} from '../../models/interface/EstabelecimentoFunctions';
import {ServicePattern} from '../../models/interface/ServicePattern';
import {ContaDespesa} from '../../models/api/ContaDespesa';
import {Observable} from 'rxjs';
import {AppConfig} from '../../../config/appConfig';
import {ResultObject} from '../../models/api/ResultObject';
import {DateFilter} from '../../models/interface/DateFilter';

@Injectable()
export class ContasDespesasService extends EstabelecimentoFunctions implements ServicePattern<ContaDespesa>{

	readonly ROUTE = '/contaDespesas';

	constructor(private http: HttpClient, private tokenStorage: TokenStorage) {
		super();
	}

	getAll(
		dateFilter: DateFilter = { dataInicio: null, dataFim: null, campo: null},
		componentId: string = '',
		ignoreEstabelecimentoOnCache: boolean = false,
		estabelecimentos: { empresa?: string, loja?: string, multiEmpresa?: string, multiLoja?: string } = null,
		estabelecimentoOnParam: boolean = false,
		page: number = null,
		pageSize: number = null
	):
		Observable<ContaDespesa[]> {
		return this.http.get<ContaDespesa[]>(
			AppConfig.getAPIEndpoint() + this.ROUTE + '/list/' + this.tokenStorage.getUserInfo().idUsuario,
			{
				headers: AppConfig.getHTTPHeader().headers.set('componentId', componentId),
				params: {
					...(
						ignoreEstabelecimentoOnCache == true && estabelecimentoOnParam != true ?
							( estabelecimentos != null ? estabelecimentos : {} ) :
							this.getEstabelecimentoQueryParam(ignoreEstabelecimentoOnCache == true ? estabelecimentos : undefined)
					),
					...this.getDateFilterQueryParam(dateFilter.dataInicio, dateFilter.dataFim),
					...this.getDateFilterWithFieldQueryParam(dateFilter.campo, dateFilter.dataInicio, dateFilter.dataFim),
					...(page || page == 0 ? { page: page + 1 } : {}),
					...(pageSize ? { per_page: pageSize } : {}),
				}
			}
		);
	}

	get(idContaDespesa: number, componentId: string = ''): Observable<ContaDespesa> {
		// TODO
		return null;
	}

	save(body: ContaDespesa, getEstabelecimentoFromCache: boolean = true, componentId: string = ''): Observable<ResultObject<any>> {
		if (getEstabelecimentoFromCache) {
			body.estabelecimento = this.getEstabelecimentoOnCache();
		}
		return this.http.post<ResultObject<any>>(
			AppConfig.getAPIEndpoint() + this.ROUTE + '/' + this.tokenStorage.getUserInfo().idUsuario,
			body,
			{
				headers: AppConfig.getHTTPHeader().headers.set('componentId', componentId)
			}
		);
	}

	update(body: ContaDespesa, componentId: string = ''): Observable<ResultObject<any>> {
		return this.http.put<ResultObject<any>>(
			AppConfig.getAPIEndpoint() + this.ROUTE + '/' + this.tokenStorage.getUserInfo().idUsuario,
			body,
			{
				headers: AppConfig.getHTTPHeader().headers.set('componentId', componentId)
			}
		);
	}

	delete(idContaDespesa: number, componentId: string = ''): Observable<ResultObject<any>> {
		return this.http.delete<ResultObject<any>>(
			AppConfig.getAPIEndpoint() + this.ROUTE + '/' + 	this.tokenStorage.getUserInfo().idUsuario +	'/' + idContaDespesa,
			{
				headers: AppConfig.getHTTPHeader().headers.set('componentId', componentId)
			}
		);
	}

	getEntriesCount(componentId: string = ''): Observable<{ entries: number }> {
		return this.http.get<{ entries: number }>(
			AppConfig.getAPIEndpoint() + this.ROUTE + '/list/' + this.tokenStorage.getUserInfo().idUsuario,
			{
				headers: AppConfig.getHTTPHeader().headers.set('componentId', componentId),
				params: {
					...this.getEstabelecimentoQueryParam(),
					entriesOnly: true
				}
			}
		);
	}

	getEntriesOnlyQueryParam(isFirstQuery: boolean = false): string {
		const _s = isFirstQuery ? '?' : '&';
		return _s + 'entriesOnly=true';
	}
}
