import {Component, Inject, Injectable} from '@angular/core';
import {MAT_SNACK_BAR_DATA, MatSnackBar} from '@angular/material';
import {Snackbar} from '../models/enum/Snackbar';
import {Endereco} from '../models/api/Endereco';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {Observable} from 'rxjs';
import {AppConfig} from '../../config/appConfig';
import * as moment from 'moment-timezone';

import {} from 'googlemaps';
import {DateFilter} from '../models/interface/DateFilter';
import {UF} from '../models/api/RegraTributacao';
import {environment} from '../../../environments/environment';
import axios from 'axios';

declare const _alert: any;
declare const window;

export const LISTA_DE_ESTADOS: UF[] = [
	{ sigla: 'AC', cod: '12' },
	{ sigla: 'AL', cod: '27' },
	{ sigla: 'AP', cod: '16' },
	{ sigla: 'AM', cod: '13' },
	{ sigla: 'BA', cod: '29' },
	{ sigla: 'CE', cod: '23' },
	{ sigla: 'DF', cod: '53' },
	{ sigla: 'ES', cod: '32' },
	{ sigla: 'GO', cod: '52' },
	{ sigla: 'MA', cod: '21' },
	{ sigla: 'MT', cod: '51' },
	{ sigla: 'MS', cod: '50' },
	{ sigla: 'MG', cod: '31' },
	{ sigla: 'PA', cod: '15' },
	{ sigla: 'PB', cod: '25' },
	{ sigla: 'PR', cod: '41' },
	{ sigla: 'PE', cod: '26' },
	{ sigla: 'PI', cod: '22' },
	{ sigla: 'RJ', cod: '33' },
	{ sigla: 'RN', cod: '24' },
	{ sigla: 'RS', cod: '43' },
	{ sigla: 'RO', cod: '11' },
	{ sigla: 'RR', cod: '14' },
	{ sigla: 'SC', cod: '42' },
	{ sigla: 'SP', cod: '35' },
	{ sigla: 'SE', cod: '28' },
	{ sigla: 'TO', cod: '17' }
];

export const TZ_CORRECTION = 10800000;

export const DATE_FILTER_TODAY_CORRECTION: DateFilter = (() => {
	let dataInicio = new Date(getTodayDate().getTime() - TZ_CORRECTION);
	let dataFim = new Date(getTodayDate().getTime() - TZ_CORRECTION);
	dataInicio.setUTCHours(0, 1, 59);
	dataFim.setUTCHours(23, 59, 59);
	return <DateFilter>{dataInicio, dataFim};
})();

export const DATE_FILTER_3_MONTH_CORRECTION: DateFilter = (() => {
	let dataInicio = new Date(getPrev3MonthFirstDate().getTime() - TZ_CORRECTION);
	let dataFim = new Date(getTodayDate().getTime() - TZ_CORRECTION);
	dataInicio.setUTCHours(0, 1, 59);
	dataFim.setUTCHours(23, 59, 59);
	return <DateFilter>{dataInicio, dataFim};
})();

export function isWidthMobile(mobileWidthBreakpoint: number = 650): boolean { return getMaxDocumentWidth() <= mobileWidthBreakpoint; }

export function getMaxDocumentWidth(): number {
	return Math.max(
		document.documentElement['clientWidth'],
		document.body['scrollWidth'],
		document.documentElement['scrollWidth'],
		document.body['offsetWidth'],
		document.documentElement['offsetWidth'],
	);
}

@Injectable()
export class UtilsService {

	public static readonly TZ_CORRECTION: number = TZ_CORRECTION;

	monthNames = ['Janeiro', 'Fevereiro', 'Março', 'Abril', 'Maio', 'Junho', 'Julho', 'Agosto', 'Setembro', 'Outubro', 'Novembro', 'Dezembro'];
	shortMonthNames = ['Jan', 'Fev', 'Mar', 'Abr', 'Mai', 'Jun', 'Jul', 'Ago', 'Set', 'Out', 'Nov', 'Dez'];

	weekNames = ['Domingo', 'Segunda-feira', 'Terça-feira', 'Quarta-feira', 'Quinta-feira', 'Sexta-feira', 'Sábado'];
	shortWeekNames = ['Dom', 'Seg', 'Ter', 'Qua', 'Qui', 'Sex', 'Sáb'];

	constructor(private http: HttpClient) {
	}

	downloadFile(url: string, nome?: string, ext: string = 'pdf', print: boolean = false, callBackPrint?: (urlObject) => void) {
		this.http.get<any>(`${AppConfig.getAPIEndpoint()}/apis/generico`, { responseType: 'blob', params: { url } } as any).subscribe(data => {
			const file = new Blob([data], {type: print ? 'application/pdf' : 'octet/stream'});
			const urlObject = URL.createObjectURL(file);
			if (print) {
				if (callBackPrint != null) callBackPrint(urlObject);
			} else {
				const a = document.createElement('a');
				a.href = urlObject;
				a.download = (nome ? nome : /[^\/]*$/.exec(url || '').toString()) + '.' + ext;
				a.click();
				URL.revokeObjectURL(urlObject);
			}
		}, err => console.log(err));
	}

	downloadFileFromData(data: string, fileName: string, type: string = 'octet/stream') {
		const a = document.createElement('a');
		const file = new Blob([data], {type});
		const url = URL.createObjectURL(file);
		a.href = url;
		a.download = fileName;
		a.click();
		URL.revokeObjectURL(url);
	}
 
	getCepFromCorreios(cep: string): Observable<Endereco> {
		const cepFixed = cep.replace(/[^\d]/g, '');
		return this.http.get<Endereco>(`${AppConfig.getAPIEndpoint()}/apis/viacep/${cep}`);
	}

	async getInfoFromCNPJ(cnpj: string, timeout: number = 5000): Promise<any> {
		//Testar com 11128277000110

		return new Promise(async (resolve, reject) => {

			try {
				const { data } = await axios.get(`${AppConfig.getAPIEndpoint()}/apis/cnpj/${cnpj}`);
				if (data && data.cnpj != null) {
					resolve(data);
					return;
				}
			} catch (e) {
				console.log(e);
			}

			try {
				const JSONP_CALLBACK = `_CNPJ${Date.now()}${cnpj}`;
				const URL = `https://www.receitaws.com.br/v1/cnpj/${cnpj}?callback=${JSONP_CALLBACK}`; // `${AppConfig.getAPIEndpoint()}/apis/cnpj/${cnpj}`

				let _timeout = setTimeout(() => {
					delete window[JSONP_CALLBACK];
					// resolve({ status: 'ERROR', message: 'Falha ao buscar CNPJ, tente novamente.' });
					_alert('Atenção!', 'Falha ao buscar CNPJ, tente novamente.');
					reject(new Error('Falha ao buscar CNPJ, tente novamente.'));
				}, timeout);

				window[JSONP_CALLBACK] = async (dados) => {
					resolve(dados);
					clearTimeout(_timeout);
					delete window[JSONP_CALLBACK];
					await axios.post(
						`${AppConfig.getAPIEndpoint()}/apis/cnpj/${cnpj}`,
						dados
					);
				};

				const script = document.createElement('script');
				script.id = JSONP_CALLBACK;
				script.src = URL;
				document.body.appendChild(script);
				document.body.removeChild(script);
			} catch (err) {
				reject(err);
			}
		});
	}

	async certInfo(){
		const { data } = await axios.get(`${AppConfig.getAPIEndpoint()}/apis/ssl`);
		return data;
	}

	rad(x) {
		return x * Math.PI / 180;
	};

	getDistanceBetween(p1, p2) {
		p1.lat = Number(p1.lat);
		p2.lat = Number(p2.lat);
		p1.lng = Number(p1.lng);
		p2.lng = Number(p2.lng);
		var R = 6378137; // Earth’s mean radius in meter
		var dLat = this.rad(p2.lat - p1.lat);
		var dLong = this.rad(p2.lng - p1.lng);
		var a = Math.sin(dLat / 2) * Math.sin(dLat / 2) +
			Math.cos(this.rad(p1.lat)) * Math.cos(this.rad(p2.lat)) *
			Math.sin(dLong / 2) * Math.sin(dLong / 2);
		var c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
		var d = R * c;
		return d; // returns the distance in meter
	};

	/**
	 * Build url parameters key and value pairs from array or object
	 * @param obj
	 */
	urlParam(obj: any): string {
		return Object.keys(obj)
			.map(k => k + '=' + encodeURIComponent(obj[k]))
			.join('&');
	}

	/**
	 * Simple object check.
	 * @param item
	 * @returns {boolean}
	 */
	isObject(item) {
		return item && typeof item === 'object' && !Array.isArray(item);
	}

	/**
	 * Deep merge two objects.
	 * @param target
	 * @param ...sources
	 * @see https://stackoverflow.com/a/34749873/1316921
	 */
	mergeDeep(target, ...sources) {
		if (!sources.length) {
			return target;
		}
		const source = sources.shift();

		if (this.isObject(target) && this.isObject(source)) {
			for (const key in source) {
				if (this.isObject(source[key])) {
					if (!target[key]) {
						Object.assign(target, { [key]: {} });
					}
					this.mergeDeep(target[key], source[key]);
				} else {
					Object.assign(target, { [key]: source[key] });
				}
			}
		}

		return this.mergeDeep(target, ...sources);
	}

	getWeekName(date: string, short: boolean = false) {
		if (!!date) {
			try {
				const _d = new Date(date);
				return short ? this.shortWeekNames[_d.getDay()] : this.weekNames[_d.getDay()];
			} catch (err) {}
		}
		return '';
	}

	getRandomLetter = getRandomLetter;

	getWeekNumber(date) {
		const dt = new Date(date);
		const thisDay = dt.getDate();
		const newDate = dt;
		newDate.setDate(1);
		const digit = newDate.getDay();
		const Q = (thisDay + digit) / 7;
		const R = (thisDay + digit) % 7;
		return R !== 0 ? Math.ceil(Q) : Q;
	}

	getPath(obj, val, path?) {
		path = path || '';
		let fullpath = '';
		for (const b in obj) {
			if (obj[b] === val) {
				return path + '/' + b;
			} else if (typeof obj[b] === 'object') {
				fullpath =
					this.getPath(obj[b], val, path + '/' + b) || fullpath;
			}
		}
		return fullpath;
	}

	validateCPF(cpf) {
		cpf = cpf.replace(/[^\d]/g, '')
		if (/^\d{11}$/.test(cpf)) {
			let digits = cpf.substr(0, 9)
			let d1 = 0
			for (let i = 10; i >= 2; i--) {
				d1 += digits[10 - i] * i
			}
			d1 = d1 % 11
			if (d1 < 2) {
				d1 = 0
			}
			else {
				d1 = 11 - d1
			}
			digits += d1
			let d2 = 0
			for (let i = 11; i >= 2; i--) {
				d2 += digits[11 - i] * i
			}
			d2 = d2 % 11
			if (d2 < 2) {
				d2 = 0
			}
			else {
				d2 = 11 - d2
			}
			return cpf.substr(-2) == `${d1}${d2}`
		}
		return false
	}

	formatPhone(phone, autoDDD = '61', includeNine = false) {
		if (!phone) return null;
		const onlyNumber = phone.trim().replace(/[^\d]/g, '');
		const clearZeros = onlyNumber.replace(/^0+/, '');
		if (onlyNumber.length < 8) return null;
		const ddd = clearZeros.length > 9 ? clearZeros.substr(0, 2) : (autoDDD != null ? autoDDD : '');
		const eightDigits = clearZeros.replace(/^\d+(\d{8})$/, '$1');
		const withNine = (parseInt(eightDigits[0]) >= 6 || includeNine) ? '9' + eightDigits : eightDigits;
		return ddd + withNine;
	}

	/* tslint:disable */
	public formatDate(d: any, showHour: boolean = false): string {
		if (d == null) { return '--' }

		let data = new Date(d);
		let h = (data.getHours().toString().length == 1) ? "0" + data.getHours().toString() : data.getHours();
		let m = (data.getMinutes().toString().length == 1) ? "0" + data.getMinutes().toString() : data.getMinutes();
		let s = (data.getSeconds().toString().length == 1) ? "0" + data.getSeconds().toString() : data.getSeconds();
		let dia = (data.getDate().toString().length == 1) ? "0" + data.getDate().toString() : data.getDate();
		let mes = (data.getMonth() + 1).toString().length == 1 ? "0" + (data.getMonth() + 1) : data.getMonth() + 1;
		if (showHour) {
			// return dia + ' de ' + MONTH_NAMES[data.getMonth()].toLowerCase() + ' de ' + data.getFullYear() + " às " + h + ":" + m + ":" + s;
			return dia + '/' + mes + '/' + data.getFullYear() + " " + h + ":" + m // + ":" + s;
		} else {
			// return dia + ' de ' + MONTH_NAMES[data.getMonth()].toLowerCase() + ' de ' + data.getFullYear();
			return dia + '/' + mes + '/' + data.getFullYear();
		}
	}

	getFormatedTimestamp(txt, withHour: boolean = false, todayText: string = 'ás ') {
		let now = new Date(Date.now());
		let data = new Date(txt);//ISO DATA

		let h = (data.getHours().toString().length == 1) ? "0" + data.getHours().toString() : data.getHours();
		let m = (data.getMinutes().toString().length == 1) ? "0" + data.getMinutes().toString() : data.getMinutes();
		let s = (data.getSeconds().toString().length == 1) ? "0" + data.getSeconds().toString() : data.getSeconds();
		let dia = (data.getDate().toString().length == 1) ? "0" + data.getDate().toString() : data.getDate();
		let mes = (data.getMonth() + 1).toString().length == 1 ? "0" + (data.getMonth() + 1) : data.getMonth() + 1;

		if (now.getDate() == data.getDate() && now.getFullYear() == data.getFullYear()) { return todayText + h + ':' + m; }
		else if (now.getDate() - 1 == data.getDate() && now.getFullYear() == data.getFullYear()) { return 'Ontem ' + h + ':' + m; }
		else if (now.getFullYear() == data.getFullYear()) { return dia + ' ' + this.shortMonthNames[data.getMonth()] + (withHour ? ' ' + h + ':' + m : '') }
		else { return dia + '/' + mes + '/' + data.getFullYear() + (withHour ? ' ' + h + ':' + m : '') }
	}

	formatToTimeAgo(value: string) {

		const d = new Date(value);
		const now = new Date();

		const seconds = Math.round(
			Math.abs((now.getTime() - d.getTime()) / 1000)
		);

		const minutes = Math.round(Math.abs(seconds / 60));
		const hours = Math.round(Math.abs(minutes / 60));
		const days = Math.round(Math.abs(hours / 24));
		const months = Math.round(Math.abs(days / 30.416));
		const years = Math.round(Math.abs(days / 365));

		if (seconds <= 45) return 'Agora';
		else if (seconds <= 90) return '1 min';
		else if (minutes <= 45) return minutes + ' mins';
		else if (minutes <= 90) return '1 hr';
		else if (hours <= 22) return hours + ' hrs';
		else if (hours <= 36) return '1 dia';
		else if (days <= 25) return days + ' dias';
		else if (days <= 45) return '1 mês';
		else if (days <= 345) return months + ' meses';
		else if (days <= 545) return '1 ano';
		else return years + ' anos';
	}

	changeTimezone(date, ianatz) {

		// suppose the date is 12:00 UTC
		let invdate = new Date(date.toLocaleString('en-US', {
			timeZone: ianatz
		}));

		// then invdate will be 07:00 in Toronto
		// and the diff is 5 hours
		let diff = date.getTime()-invdate.getTime();

		// so 12:00 in Toronto is 17:00 UTC
		return new Date(date.getTime()+diff);
	}

	formatDoubleNumber(number) { let ts = number.toString(); return ts.length > 1 ? ts : `0${ts}`; }

	notificar(title: string, message: string, cb?: Function) {
		const Notification = window.Notification
		const notify = () => {
			const notification = new Notification(title, { body: message, tag: 'vendergas' })
			notification.onclick = () => {
				if (cb) cb()
			}
		}
		if ('Notification' in window) {
			if (Notification.permission == 'granted') {
				notify()
			}
			else {
				Notification.requestPermission().then(permission => {
					if (permission == 'granted') {
						notify()
					}
				})
			}
		}
	}
}

export function getWeekNumber(date) {
	const dt = new Date(date);
	const thisDay = dt.getDate();
	const newDate = dt;
	newDate.setDate(1);
	const digit = newDate.getDay();
	const Q = (thisDay + digit) / 7;
	const R = (thisDay + digit) % 7;
	return R !== 0 ? Math.ceil(Q) : Q;
}

export function getCustomDayRoom(date, dayRoom: number) {
	const dt = new Date(date);
	const thisDay = dt.getDate();
	const newDate = dt;
	newDate.setDate(1);
	const digit = newDate.getDay();
	const Q = (thisDay + digit) / dayRoom;
	const R = (thisDay + digit) % dayRoom;
	return R !== 0 ? Math.ceil(Q) : Q;
}

export function formatDate(d: any, showHour: boolean = false, dateSeparation: string = 'às', utc: boolean = true, noSummerTime: boolean = false): string {
	if (d == null) { return '--' }

	let _data = moment(d);
	if(noSummerTime) _data = _data.subtract(moment(d).isDST() ? 1 : 0, 'hours');
	if (utc) _data = _data.utc();
	return _data.format(`DD/MM/YYYY${showHour ? ` [${dateSeparation}] HH:mm` : ``}`);
}
/* tslint:enable */

export function isInteger(value: any): value is number {
	return typeof value === 'number' && isFinite(value) && Math.floor(value) === value;
}


export function isString(value: any): value is string {
	return typeof value === 'string';
}

export const MONTH_NAMES: string[] = [
	'Janeiro', 'Fevereiro', 'Março', 'Abril', 'Maio', 'Junho',
	'Julho', 'Agosto', 'Setembro', 'Outubro', 'Novembro', 'Dezembro'
];

/**
 * SNACKBAR
 */

@Component({
	selector: 'm-snack-bar',
	template: '' +
		'<mat-icon [ngClass]="{\'success\': data.type == \'success\', \'warning\': data.type == \'warning\', \'danger\': data.type == \'danger\'}" style="float: left !important; display: inline-block; margin-top: -5px; margin-right: 20px; margin-left: -7px;font-size: 30px !important;">{{data.icon}}</mat-icon>' +
		'<span [ngClass]="{\'success\': data.type == \'success\', \'warning\': data.type == \'warning\', \'danger\': data.type == \'danger\'}" class="no-select" style="font-weight: 400;display: inline !important;margin-top: 3px;font-size: 16px;">{{data.text}}</span>' +
		'<span *ngIf="!!data.subText"  class="no-select" style="font-weight: 400;display: block !important;margin-top: 3px;font-size: 10px;text-align: right">{{data.subText}}</span>',
	styles: ['.success { color: #34bfa3 } .warning { color: #ffb822 } .danger { color: #f4516c }']
})
export class SnackBarDataComponent {
	constructor(
		@Inject(MAT_SNACK_BAR_DATA) public data: any,
	) {}
}

export async function showSnackBar(text: string, type: Snackbar = Snackbar.TYPE_INFO, snackBar: MatSnackBar, subText: string = null): Promise<boolean> {
	if (type === Snackbar.TYPE_DANGER || type === Snackbar.TYPE_WARNING) {
		return await _alert(text, subText);
	} else {
		let icon = 'info';
		if (type === Snackbar.TYPE_SUCCESS) { icon = 'check'; }
		const sb = snackBar.openFromComponent(SnackBarDataComponent, {
			data: { text: text, subText: subText, icon: icon, type: type },
			duration: 3000,
			horizontalPosition: 'center',
			verticalPosition: 'bottom'
		});
		return false;
	}
}


/**
 * DATE
 */
export function getTodayDate(minusDay: number = null) { return new Date(!!minusDay ? (Date.now() - (86400000 * minusDay)) : Date.now()); }
export function getTodayDatePlus(plusDay: number = null) { return new Date(!!plusDay ? (Date.now() + (86400000 * plusDay)) : Date.now()); }

export function getDateFirstDayOfMonth() { const now = new Date(); return new Date(now.getFullYear(), now.getMonth(), 1); }
export function getDateLastDayOfMonth() { const now = new Date(); return new Date(now.getFullYear(), now.getMonth() + 1, 0); }

export function getPrev3MonthFirstDate() { const now = new Date(); return new Date(now.getFullYear() - (now.getMonth() > 0 ? 0 : 1), (now.getMonth() - 3 + 12) % 12, 1); }

export function getPrevMonthFirstDate() { const now = new Date(); return new Date(now.getFullYear() - (now.getMonth() > 0 ? 0 : 1), (now.getMonth() - 1 + 12) % 12, 1); }
export function getPrevMonthLastDate() { const now = new Date(); return new Date(now.getFullYear(), now.getMonth(), 0); }

export function getPrevYearFirstDate() { const now = new Date(); return new Date(now.getFullYear() - 1, 0, 1); }
export function getPrevYearLastDate() { const now = new Date(); return new Date(now.getFullYear() - 1, 11, 31); }

export function getThisYearFirstDate() { const now = new Date(); return new Date(now.getFullYear(), 0, 1); }
export function getThisYearLastDate() { const now = new Date(); return new Date(now.getFullYear(), 11, 31); }

/** RANDOM */

export function getRandomNumber(length: number = 9999): string {
	let _number = (Math.floor(Math.random() * length) + 1).toString();
	const _numberLength = length.toString().length;
	const _lenghtFill = _numberLength - _number.length;
	if (_number.length < _numberLength) {
		for (let c = 0; c < _lenghtFill; ++c) {
			_number = '0' + _number;
		}
	}
	return _number;
}

export const ALPHABET = ['a', 'b', 'c', 'd', 'e', 'f', 'g', 'h', 'i', 'j', 'k', 'l', 'm', 'n', 'o', 'p', 'q', 'r', 's', 't', 'u', 'v', 'w', 'x', 'y', 'z'];

export function getRandomLetter(length: number = 1): string {
	let result = '';
	for (let c = 0; c < length; ++c) result += ALPHABET[(Math.floor(Math.random() * (ALPHABET.length - 1)))].toUpperCase();
	return result;
}

/**
 * Verifica se é a última posição de um array
 * @param i - index a ser verificado
 * @param array
 */
export function isLastPos(i: number, array: any[]): boolean {
	return (i || 0) === (array || []).length - 1;
}

declare let jsPDF: any;
const TABLE_FONT_SIZE = 9;

export const HEADER_STYLE_DEF = {
	fillColor: [54, 53, 58],
	textColor: [255, 255, 255],
	halign: 'center',
	cellPadding: 3,
	fontStyle: 'bold',
	lineColor: [54, 53, 58],
	lineWidth: 0.05,
	fontSize: 7
};

export const GROUP_START_STYLE_DEF = {
	...HEADER_STYLE_DEF,
	halign: 'left',
	lineWidth: 1,
	lineColor: [255, 255, 255],
	fontSize: 10
};

export const BODY_STYLE_DEF = {
	fillColor: [255, 255, 255],
	textColor: [0, 0, 0],
	lineWidth: 0.05,
	halign: 'center',
	fontStyle: 'bold',
	lineColor: [54, 53, 58],
	fontSize: 7
};

export const TOTAL_STYLE_DEF = {
	...BODY_STYLE_DEF,
	fillColor: [255, 255, 255],
	halign: 'left',
	fontSize: 10
};

export function $renderPDFInDocument(data) {
	console.log(data);
	(document.getElementById('vendergas-pdf-viewer') as any).style.display = 'block';
	(document.getElementById('pdf-viewer-container') as any).innerHTML = `<embed width='100%' height='100%' src='${data}' type="application/pdf"/>`;
}

export function gerarTabelaImpressa(
	pageName,
	PDFFileName,
	jsonData,
	infoDateStart,
	infoDateEnd,
	print = false,
	hideHeader = false,
	orientation /*: 'horizontal' | 'vertical'*/,
	verifySizeOnPrint = false
) {

	if (jsonData == null || !Array.isArray(jsonData)) return;

	const doc = new jsPDF(orientation === 'horizontal' ? 'l' : 'p', 'mm', 'a4');

	const headerStyleDef = HEADER_STYLE_DEF;

	const bodyStyleDef = BODY_STYLE_DEF;

	let f = n => n < 10 ? `0${n}` : `${n}`;

	const getCols = () => {
		const filteredWords = [
			'isGroupBy',
			'content',
			'isSummaryRow',
			'isNameProduct',
			'isHeaderRow',
			'isGroupTotal',
			'isTotalProdutos',
			'isTotalGeral',
			'isGroupCusto',
			'isGroupProdutos',
			'isGroupSaldoTotal'
		];

		if (jsonData && Array.isArray(jsonData) && jsonData.length > 0) {
			let cols = [];

			jsonData.forEach(jd => {
				Object.keys(jd).forEach(key => {
					if (!cols.includes(key) && !filteredWords.includes(key)) cols.push(key);
				});
			});

			return [cols];
		}
		return [];
	};

	const getRows = () => {
		if (jsonData && Array.isArray(jsonData) && jsonData.length > 0) {
			return jsonData.map(jd => Object.keys(jd).map(key => jd[key]));
		}
		return [];
	};

	const centeredText = (text, y) => {
		let textWidth = doc.getStringUnitWidth(text) * doc.internal.getFontSize() / doc.internal.scaleFactor;
		let textOffset = (doc.internal.pageSize.width - textWidth) / 2;
		doc.text(textOffset, y, text);
	};

	const createBackground = (colorRGBA, y, margin, height, width = doc.internal.pageSize.width) => {
		doc.setFillColor(colorRGBA[0], colorRGBA[1], colorRGBA[2], colorRGBA[3]);
		doc.rect(margin /* x */, y /* y */, width - ((margin || 0) * 2) /* width */, height /* height */, 'F');
	};

	const getDateFormated = date => `${f(date.getDate())}.${f(date.getMonth() + 1)}.${f(date.getFullYear())}`;

	createBackground([255, 255, 255, 0], 3, 3, 20);

	doc.setTextColor(255, 255, 255);
	doc.setFontSize(30);
	doc.setFontType('bold');

	centeredText(`${pageName} ${getDateFormated(infoDateStart)} ~ ${getDateFormated(infoDateEnd)}`.toUpperCase(), 17);

	const pageHeight = 20;

	/** Body */
	doc.autoTable({
		// startY: doc.autoTableEndPosY() + 0.2,
		startY: pageHeight + 5,
		styles: {overflow: 'linebreak', cellWidth: 'auto', fontSize: TABLE_FONT_SIZE},
		headStyles: headerStyleDef,
		bodyStyles: bodyStyleDef,
		showHead: hideHeader ? 'never' : 'everyPage',
		showFoot: 'never',
		columnStyles: {text: {cellWidth: 'auto'}},
		margin: { left: 3, right: 3},
		head: getCols(),
		body: getRows()
	});

	if (print) {
		let output = doc.output('datauristring');

		let size = 0;

		try {
			let binary = atob(output.split(',')[1]);
			let array = [];
			for (let i = 0; i < binary.length; i++) array.push(binary.charCodeAt(i));
			let file = new Blob([new Uint8Array(array)], {type: 'application/pdf'});
			size = (file.size / 1048576);
		} catch (err) {
			console.log(err);
		}

		if (verifySizeOnPrint && size > 1.5) {
			this._confirm(
				`O arquivo de impressão(${(size || 0).toFixed(2)} MB) ultrapassa o tamanho limite(1.5 MB) para ser exibido. Deseja baixar o arquivo?`,
				'Baixar',
				'Fechar'
			).then(res => {
				if (res) {
					doc.save((PDFFileName || Date.now()) + '_VENDERGAS' + '.pdf');
				}
			}).catch(err => console.log(err));
		} else $renderPDFInDocument(output);
	} else {
		doc.save((PDFFileName || Date.now()) + '_VENDERGAS' + '.pdf');
	}
}
