import {Injectable} from '@angular/core';
import {EstabelecimentoFunctions} from '../../models/interface/EstabelecimentoFunctions';
import {ServicePattern} from '../../models/interface/ServicePattern';
import {HttpClient} from '@angular/common/http';
import {TokenStorage} from '../../auth/token-storage.service';
import {Observable} from 'rxjs';
import {AppConfig} from '../../../config/appConfig';
import {ResultObject} from '../../models/api/ResultObject';
import { Convenio } from '../../models/api/Convenio';
import moment from 'moment';
import { TipoPagamento } from '../../models/enum/TipoPagamento';

@Injectable()
export class ConveniosService extends EstabelecimentoFunctions implements ServicePattern<Convenio> {

	constructor(private http: HttpClient, private tokenStorage: TokenStorage ) {
		super();
	}

	getAll(
		est: string,
		page: number,
		per_page: number,
		componentId = '',
		search?: string,
		vencimentoInicio?: Date,
		vencimentoFim?: Date
	): Observable<any> {
		return this.http.get<any>(
			AppConfig.getAPIEndpoint() + '/convenios/' + this.tokenStorage.getUserInfo().idUsuario,
			{
				headers: AppConfig.getHTTPHeader().headers.set('componentId', componentId),
				params: {
					est,
					page: String(page),
					per_page: String(per_page),
					...(search ? {search} : {}),
					...(vencimentoInicio ? {vencimentoInicio: moment(vencimentoInicio).toISOString()} : {}),
					...(vencimentoFim ? {vencimentoFim: moment(vencimentoFim).toISOString()} : {})
				}
			}
		);
	}

	save(body: Convenio, componentId: string = ''): Observable<ResultObject<any>> {
		return this.http.post<ResultObject<any>>(
			AppConfig.getAPIEndpoint() + '/convenios/' + this.tokenStorage.getUserInfo().idUsuario,
			body,
			{
				headers: AppConfig.getHTTPHeader().headers.set('componentId', componentId)
			}
		);
	}

	update(body: Convenio, componentId: string = ''): Observable<ResultObject<any>> {
		return this.http.put<ResultObject<any>>(
			AppConfig.getAPIEndpoint() + '/convenios/' + this.tokenStorage.getUserInfo().idUsuario,
			body,
			{
				headers: AppConfig.getHTTPHeader().headers.set('componentId', componentId)
			}
		);
	}

	baixa(convenio: Convenio, tipoPagamento: TipoPagamento, dataPagamento: Date, vencimentoInicio: Date = null, vencimentoFim: Date = null, componentId: string = ''): Observable<ResultObject<any>> {
		return this.http.put<ResultObject<any>>(
			AppConfig.getAPIEndpoint() + '/convenios/baixa/' + this.tokenStorage.getUserInfo().idUsuario + '/' + convenio._id,
			{
				tipoPagamento,
				dataPagamento,
				vencimentoInicio,
				vencimentoFim
			},
			{
				headers: AppConfig.getHTTPHeader().headers.set('componentId', componentId)
			}
		);
	}

	delete(idConvenio: string, componentId: string = ''): Observable<ResultObject<any>> {
		return this.http.delete<ResultObject<any>>(
			AppConfig.getAPIEndpoint() + '/convenios/' + this.tokenStorage.getUserInfo().idUsuario + '/' + idConvenio,
			{
				headers: AppConfig.getHTTPHeader().headers.set('componentId', componentId)
			}
		);
	}

}
