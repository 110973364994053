import {ConfigModel} from '../core/interfaces/config';
import {TipoPagamento} from '../core/models/enum/TipoPagamento';
import {StatusConta} from '../core/models/enum/StatusConta';

export class PagesConfig implements ConfigModel {
	public config: any = {};

	constructor() {
		this.config = {
			'/empty': {
				page: {title: 'Vendergás', desc: '', subheader: true}
			},
			aplicativo: {
				cliente: {
					listar: {
						page: {title: 'APP Cliente', desc: 'APP Cliente', subheader: true}
					}
				}
			},
			smtp: {
				configurar: {
					page: {title: 'Configurações de SMTP', desc: 'Configurações de SMTP', subheader: true}
				}
			},
			gerenciar: {
				veiculos: {
					page: {title: 'Gerenciar Veículos', desc: 'Gerenciar Veículos', subheader: true}
				},
				combustivel: {
					page: {title: 'Controle de Combustível', desc: 'Gerenciar Combustível', subheader: true}
				}
			},
			notaFiscal: {
				invalidarFaixa: { page: {title: 'Inutilizar Faixa de Numeração', desc: 'Inutilizar Faixa de Numeração', subheader: true} },
				faixasInutilizadas: { page: {title: 'Relatório de Faixas Inutilizidas', desc: 'Relatório de Faixas Inutilizidas', subheader: true} },
				emitir: { page: {title: 'Emitir Nota-Fiscal', desc: 'Emitir Nota-Fiscal', subheader: true} },
				CTE: {
					emitir: {
						page: {title: 'Emitir CTE', desc: 'Emitir Nota-Fiscal', subheader: true}
					}
				},
				portatil: { page: { title: 'Nota Portátil', desc: 'Nota Portátil', subheader: true } },
				[`portatil?relatorio=nota-fiscal`]: { page: {title: 'Nota Portátil', desc: '', subheader: true} },
				[`portatil?relatorio=boleto`]: { page: {title: 'Boleto Portátil', desc: '', subheader: true} },
				'emitidas': { page: { title: 'Relatório de Notas Fiscais', desc: 'Relatório de Notas Fiscais', subheader: true } },
				'relatorio-tecnospeed': { page: { title: 'Relatório de Notas Fiscais', desc: 'Relatório de Notas Fiscais', subheader: true } },
				'emitidas?downloadXML=true': { page: {title: 'XML de Notas Emitidas (antigo)', desc: 'XML de Notas Emitidas (antigo)', subheader: true } },
				'exportar-xml': { page: {title: 'XML de Notas Emitidas', desc: 'XML de Notas Emitidas', subheader: true } },
				'emitidas?tipo=cte': { page: {title: 'Relatório CTe', desc: 'Relatório CTe', subheader: true } }
			},
			boleto: {
				emitir: {
					page: {title: 'Emitir Boleto', desc: 'Emitir Boleto', subheader: true}
				},
				listar: {
					page: {title: 'Boletos Emitidos', desc: 'Emitir Boleto', subheader: true}
				}
			},
			depurar: {
				pedidos: {
					page: {title: 'Depuração de Pedidos', desc: 'Depuração de Pedidos', subheader: true}
				}
			},
			'lancar-pedido': {
				page: {title: 'Lançar pedido', desc: 'Lançar Pedido', subheader: true}
			},
			'lancar-pedido-rapido': {
				page: {title: 'Lançar pedido rápido', desc: 'Lançar Pedido Rápido', subheader: true}
			},
			pedidos: {
				page: {title: 'Pedidos', desc: '', subheader: false},
				entregues: {page: {title: 'Pedidos entregues', desc: '', subheader: true}},
				cancelados: {page: {title: 'Pedidos cancelados', desc: '', subheader: true}},
				pendentes: {page: {title: 'Pedidos pendentes', desc: '', subheader: true}},
				repassados: {page: {title: 'Pedidos repassados', desc: '', subheader: true}},
				repassar: {page: {title: 'Repassar pedidos', desc: '', subheader: true}},
				agendados: {page: {title: 'Pedidos agendados', desc: '', subheader: true}},

				todos: {page: {title: 'Todos os Pedidos', desc: '', subheader: true}}
			},
			cadastrar: {
				cliente: {page: {title: 'Cadastrar cliente', desc: '', subheader: true}},
				produto: {page: {title: 'Cadastrar produto', desc: '', subheader: true}},
				usuario: {page: {title: 'Cadastrar usuário', desc: '', subheader: true}},
				fornecedor: {page: {title: 'Cadastrar fornecedor', desc: '', subheader: true}},
				empresa: {page: {title: 'Cadastrar empresa', desc: '', subheader: true}},
				loja: {page: {title: 'Cadastrar Revenda', desc: '', subheader: true}},
				veiculo: {page: {title: 'Cadastrar veículo', desc: '', subheader: true}},
				entregador: {page: {title: 'Cadastrar entregador', desc: '', subheader: true}}
			},
			builder: {
				page: {title: 'Layout Builder', desc: '', subheader: true}
			},
			relatorio: {
				clientes: {page: {title: 'Relatório de clientes', desc: '', subheader: true}},
				contabil: {page: {title: 'Relatório Contábil', desc: '', subheader: true}},
				'compras-clientes': {page: {title: 'Relatório de Compras por Cliente', desc: '', subheader: true}},
				produtos: {page: {title: 'Relatório de produtos', desc: '', subheader: true}},
				entregadores: {page: {title: 'Relatório de entregadores', desc: '', subheader: true}},
				veiculos: {page: {title: 'Relatório de veículos', desc: '', subheader: true}},
				fornecedor: {page: {title: 'Relatório de fornecedores', desc: '', subheader: true}},
				estabelecimentos: {page: {title: 'Gerenciar estabelecimentos', desc: '', subheader: true}},
				'historico-pedidos': {
					page: {
						title: 'Relatório de Histórico de Compras por Cliente',
						desc: '',
						subheader: true
					}
				},
				'notas-avariadas': {page: {tiel: 'Relatório de Notas Avariadas', desc: '', subheader: true}},
				'notas-avariadas2': {page: {tiel: 'Relatório de Notas Avariadas 2', desc: '', subheader: true}},
				logs: {page: {title: 'Relatório de Logs', desc: '', subheader: true}},
				logsSistema: { page: { title: 'Relatório de Logs do Sistema', desc: '', subheader: true } },
				'media-compras-clientes': {
					page: {
						title: 'Relatório de Previsão de Compras por Cliente',
						desc: '',
						subheader: true
					}
				},
				'compras-item': {page: {title: 'Relatório de produtos Comprados', desc: '', subheader: true}},
				'historico-acoes': {page: {title: 'Histórico de Ações', desc: '', subheader: true}},
				'vendas-produto': {page: {title: 'Relatório de Vendas por Produto', desc: '', subheader: true}},
				'vendas-valor-negociado': {page: {title: 'Relatório de valores por produto', desc: '', subheader: true}},
				'vendas-valor-negociado-entregador': {page: {title: 'Relatório de valores por entregador', desc: '', subheader: true}},
				'movimentos-diarios': {page: {title: 'Relatório de Movimentos do Dia', desc: '', subheader: true}},
				'historico-compras': {page: {title: 'Histórico de compras por cliente', desc: '', subheader: true}}
			},
			estoque: {
				atualizar: {page: {title: 'Atualizar estoque', desc: '', subheader: true}},
				movimentar: {page: {title: 'Movimentar do Estoque', desc: '', subheader: true}},
				relatorio: {
					page: {
						title: 'Relatório de Estoque', decs: '', subheader: true
					},
					completo: {
						page: {title: 'Relatório de Estoque Completo', desc: '', subheader: true}
					}
				},
				avariados: {page: {title: 'Relatório de Produtos Avariados', desc: '', subheader: true}}
			},
			'nota-fiscal': {
				cadastrar: {page: {title: 'Movimentação do estoque', desc: '', subheader: true}},
				relatorio: {page: {title: 'Registros de Notas Fiscais de Movimentações', desc: '', subheader: true}}
			},
			'movimentacoes': {
				relatorio: {page: {title: 'Relatórios das Movimentações', desc: '', subheader: true}},
				'relatorio-controle-mensal': {
					page: {
						title: 'Relatório de Controle de Movimento Mensal',
						desc: '',
						subheader: true
					}
				},
				'relatorio-comodatos': {page: {title: 'Relatório de Comodatos', desc: '', subheader: true}}
			},
			gestao: {
				page: { title: 'Gestão', desc: '', subheader: true },
				usuario: {
					funcao: {page: {title: 'Gerenciar permissões', desc: '', subheader: true}},
					relatorio: {page: {title: 'Gerenciar usuários', desc: '', subheader: true}}
				},
				cliente: {
					'valor-negociado': {page: {title: 'Valor Negociado', desc: '', subheader: true}}
				},
			},
			vendas: {
				'relatorio-vendas-perfil-cliente': {page: {title: 'Relatório de vendas por Perfil de Cliente', desc: '', subheader: true}},
				relatorio: {page: {title: 'Relatório de vendas por pedido', desc: '', subheader: true}},
				'relatorio-vendas-entregador': {
					page: {
						title: 'Relatório de vendas por entregador',
						desc: '',
						subheader: true
					}
				},
				'relatorio-vendas-entregador-canal': {
					page: {
						title: 'Relatório de vendas por entregador e canal de vendas',
						desc: '',
						subheader: true
					}
				},
				'relatorio-vendas-canal': {
					page: {
						title: 'Relatório de vendas por Canal de Venda',
						desc: '',
						subheader: true
					}
				},
				'relatorio-vendas-cliente': {
					page: {
						title: 'Relatório de Preço Médio por Cliente',
						desc: '',
						subheader: true
					}
				},
				'grafico-preco-medio-cliente': {
					page: {
						title: 'Gráfico de Preço Médio por cliente',
						desc: '',
						subheader: true
					}
				},
				'relatorio-vendas-pagamento-cliente': {
					page: {
						title: 'Relatório de Vendas Pagamento/Cliente',
						desc: '',
						subheader: true
					}
				},
				'relatorio-vendas-estabelecimento': {
					page: {
						title: 'Relatório de vendas por estabelecimento',
						desc: '',
						subheader: true
					}
				},
				'relatorio-fechamento-de-caixa': {
					page: {
						title: 'Relatório de fechamento de caixa',
						desc: '',
						subheader: true
					}
				},
				'resumo-vendas-estoque': {
					page: {
						title: 'Resumo das Vendas e Estoque',
						desc: '',
						subheader: true
					}
				},
				'relatorio-resumo-vendas': {
					page: {
						title: 'Resumo das Vendas',
						desc: '',
						subheader: true
					}
				},
				'relatorio-vendas-tempo-entrega': {
					page: {
						title: 'Relatório de vendas por tempo médio',
						desc: '',
						subheader: true
					}
				},
				'relatorio-forma-pagamento': {
					page: {
						title: 'Relatório de vendas por pagamento',
						desc: '',
						subheader: true
					}
				},
				'relatorio-tempo-entrega-produto': {
					page: {
						title: 'Vendas por tempo médio de entrega do produto',
						desc: '',
						subheader: true
					}
				},
				'relatorio-tempo-entrega-entregador': {
					page: {
						title: 'Vendas por tempo médio de entrega do entregador',
						desc: '',
						subheader: true
					}
				}
			},
			financeiro: {
				venda: {
					antecipada: {page: {title: 'Venda Antecipada', desc: '', subheader: true}},
					fiada: { page: {title: 'Venda à Prazo (fiado)', desc: '', subheader: true}}
				},
				movimentacao: {page: {title: 'Movimentação Financeira', desc: '', subheader: true}},
				acerto: {page: {title: 'Acerto Financeiro por Vendedor', desc: '', subheader: true}},
				'lancar-despesa': {page: {title: 'Saídas do Caixa (Sangria)', desc: '', subheader: true}},
				'relatorio-despesas': {page: {title: 'Relatório de Despesas', desc: '', subheader: true}},
				'relatorio-despesas-sangria': { page: { title: 'Relatório de Despesas(Sangria)', desc: '', subheader: true } },
				'relatorio-contas-despesas': { page: { title: 'Relatório de Contas Despesa', desc: '', subheader: true } },
				'pedidos-entregues': {page: {title: 'Total acertado por Empresa', desc: '', subheader: true}},
				cadastrar: {
					'conta-a-receber': {page: {title: 'Cadastrar conta a receber', desc: '', subheader: true}},
					'conta-a-pagar': {page: {title: 'Cadastrar conta a pagar', desc: '', subheader: true}},
					'taxa-trasacao-cartao': {
						page: {
							title: 'Cadastrar Taxa de Transação de Cartões',
							desc: '',
							subheader: true
						}
					}
				},
				relatorio: {
					'taxa-trasacao-cartao': {
						page: {
							title: 'Relatório Taxa de Transação de Cartões',
							desc: '',
							subheader: true
						}
					},
					dre: {
						page: {
							title: 'Demonstrativo de Resultado(DRE)',
							desc: '',
							subheader: true
						}
					},
					'relatorio-resultado': {
						page: {
							title: 'Relatório de Resultado',
							desc: '',
							subheader: true
						}
					},
					'saldo-despesas': {
						page: {
							title: 'Total Mensal das Despesas',
							desc: '',
							subheader: true
						}
					},
					'saldo-receitas': {
						page: {
							title: 'Total Mensal das Receitas',
							desc: '',
							subheader: true
						}
					}
				},
				contas: {
					'a-pagar#anchor': { page: {title: 'Contas a pagar', desc: '', subheader: true} },
					'a-pagar': { page: {title: 'Contas a pagar', desc: '', subheader: true} },
					'a-receber': { page: {title: 'Contas a Receber', desc: '', subheader: true} },
					'a-receber#anchor': { page: {title: 'Contas a Receber', desc: '', subheader: true} },

					/** Boletos a receber */
					[`a-receber?tipoPagamento=${TipoPagamento.BOLETO}&statusConta=${StatusConta.VENCIDO}#anchor`]: { page: {title: 'Boletos Vencidos', desc: '', subheader: true} },
					[`a-receber?tipoPagamento=${TipoPagamento.BOLETO}&statusConta=${StatusConta.VENCIDO}`]: { page: {title: 'Boletos Vencidos', desc: '', subheader: true} },
					[`a-receber?tipoPagamento=${TipoPagamento.BOLETO}&statusConta=${StatusConta.PAGO}#anchor`]: { page: {title: 'Boletos Baixados', desc: '', subheader: true} },
					[`a-receber?tipoPagamento=${TipoPagamento.BOLETO}&statusConta=${StatusConta.PAGO}`]: { page: {title: 'Boletos Baixados', desc: '', subheader: true} },
					[`a-receber?tipoPagamento=${TipoPagamento.BOLETO}&statusConta=${StatusConta.AGUARDANDO_PAGAMENTO}#anchor`]: { page: {title: 'Boletos À Vencer', desc: '', subheader: true} },
					[`a-receber?tipoPagamento=${TipoPagamento.BOLETO}&statusConta=${StatusConta.AGUARDANDO_PAGAMENTO}`]: { page: {title: 'Boletos À Vencer', desc: '', subheader: true} },
					[`a-receber?tipoPagamento=${TipoPagamento.BOLETO}#anchor`]: { page: {title: 'Todos os Boletos', desc: '', subheader: true} },
					[`a-receber?tipoPagamento=${TipoPagamento.BOLETO}`]: { page: {title: 'Todos os Boletos', desc: '', subheader: true} },

					/** Cartôes a Receber */
					[`a-receber?tipoPagamento=${TipoPagamento.CARTAO_TODOS}#anchor`]: { page: {title: 'Cartões a Receber', desc: '', subheader: true} },
					[`a-receber?tipoPagamento=${TipoPagamento.CARTAO_TODOS}`]: { page: {title: 'Cartões a Receber', desc: '', subheader: true} },

					/** Cheques a Receber */
					[`a-receber?tipoPagamento=${TipoPagamento.CHEQUE}#anchor`]: { page: {title: 'Cheques a Receber', desc: '', subheader: true} },
					[`a-receber?tipoPagamento=${TipoPagamento.CHEQUE}`]: { page: {title: 'Cheques a Receber', desc: '', subheader: true} },

					/** Todos a Receber */
					[`a-receber?tipoPagamento=${TipoPagamento.TODOS}#anchor`]: { page: {title: 'Todos a Receber', desc: '', subheader: true} },
					[`a-receber?tipoPagamento=${TipoPagamento.TODOS}`]: { page: {title: 'Todos a Receber', desc: '', subheader: true} },

				},
				'lancar-despesa-financeira': {page: {title: 'Lançar Despesa Financeira', desc: '', subheader: true}},
				'cadastrar-conta-despesa': { page: { title: 'Cadastrar Conta de Despesa', desc: '', subheader: true } },
				'contas-bancarias': { page: { title: 'Fontes Bancárias', desc: '', subheader: true } },
				'transferencia-conta': { page: { title: 'Transferência entre Contas', desc: '', subheader: true } },
				'lancamento-financeiro': { page: { title: 'Lançamento Financeiro', desc: '', subheader: true } },
				'relatorio-contas': { page: { title: 'Contas', desc: '', subheader: true } }
			},
			profile: {
				page: {title: 'Meus Dados', desc: '', subheader: true}
			},
			404: {
				page: {title: '404 Não Encontrado', desc: '', subheader: true}
			},
			loading: {
				page: {title: '', desc: '', subheader: true}
			},
			convenio: {
				convenios: {
					page: {title: 'Convênios', desc: '', subheader: true}
				},
				participantes: {
					page: {title: 'Participantes do Convênio', desc: '', subheader: true}
				},
				produtos: {
					page: {title: 'Produtos do Convênio', desc: '', subheader: true}
				},
				vendas: {
					page: {title: 'Vendas por Convênio', desc: '', subheader: true}
				}
			}
		};
	}
}
