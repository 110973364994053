import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { TokenStorage } from '../../auth/token-storage.service';
import { AppConfig } from '../../../config/appConfig';

@Injectable()
export class MdfeconfigService {
	readonly ROUTE = '/mdfeconfig'

	constructor(private http: HttpClient, private tokenStorage: TokenStorage) { }

	listarReboques(empresa?: string, loja?: string, componentId: string = ''): Observable<any[]> {
		return this.http.get<any[]>(
			AppConfig.getAPIEndpoint() + this.ROUTE + '/listar_reboques/' + this.tokenStorage.getUserInfo().idUsuario,
			{
				headers: AppConfig.getHTTPHeader().headers.set('componentId', componentId),
				params: {
					...(empresa ? { empresa } : {}),
					...(loja ? { loja } : {})
				}
			}
		)
	}

	listarCondutores(empresa?: string, loja?: string, componentId: string = ''): Observable<any[]> {
		return this.http.get<any[]>(
			AppConfig.getAPIEndpoint() + this.ROUTE + '/listar_condutores/' + this.tokenStorage.getUserInfo().idUsuario,
			{
				headers: AppConfig.getHTTPHeader().headers.set('componentId', componentId),
				params: {
					...(empresa ? { empresa } : {}),
					...(loja ? { loja } : {})
				}
			}
		)
	}

	cadastrarReboque(body: any, componentId: string = ''): Observable<any> {
		return this.http.post<any>(
			AppConfig.getAPIEndpoint() + this.ROUTE + '/cadastrar_reboque/' + this.tokenStorage.getUserInfo().idUsuario,
			body,
			{
				headers: AppConfig.getHTTPHeader().headers.set('componentId', componentId)
			}
		)
	}

	cadastrarCondutor(body: any, componentId: string = ''): Observable<any> {
		return this.http.post<any>(
			AppConfig.getAPIEndpoint() + this.ROUTE + '/cadastrar_condutor/' + this.tokenStorage.getUserInfo().idUsuario,
			body,
			{
				headers: AppConfig.getHTTPHeader().headers.set('componentId', componentId)
			}
		)
	}

	removerReboque(id: string, componentId: string = ''): Observable<any> {
		return this.http.delete<any>(
			AppConfig.getAPIEndpoint() + this.ROUTE + '/remover_reboque/' + id + '/' + this.tokenStorage.getUserInfo().idUsuario,
			{
				headers: AppConfig.getHTTPHeader().headers.set('componentId', componentId)
			}
		)
	}

	removerCondutor(id: string, componentId: string = ''): Observable<any> {
		return this.http.delete<any>(
			AppConfig.getAPIEndpoint() + this.ROUTE + '/remover_condutor/' + id + '/' + this.tokenStorage.getUserInfo().idUsuario,
			{
				headers: AppConfig.getHTTPHeader().headers.set('componentId', componentId)
			}
		)
	}

	editarReboque(id: string, body: any, componentId: string = ''): Observable<any> {
		return this.http.put<any>(
			AppConfig.getAPIEndpoint() + this.ROUTE + '/editar_reboque/' + id + '/' + this.tokenStorage.getUserInfo().idUsuario,
			body,
			{
				headers: AppConfig.getHTTPHeader().headers.set('componentId', componentId)
			}
		)
	}

	editarCondutor(id: string, body: any, componentId: string = ''): Observable<any> {
		return this.http.put<any>(
			AppConfig.getAPIEndpoint() + this.ROUTE + '/editar_condutor/' + id + '/' + this.tokenStorage.getUserInfo().idUsuario,
			body,
			{
				headers: AppConfig.getHTTPHeader().headers.set('componentId', componentId)
			}
		)
	}

}