import { Usuario } from './Usuario';
import { Cliente } from './Cliente';

export class AlteracaoClienteData {
	nome: string;
	telefone: string;
	endereco: string;
}

export class AlteracaoCliente {
	antigo: AlteracaoClienteData;
	novo: AlteracaoClienteData;
	usuario: Usuario;
	cliente: Cliente;
	lojas: string[];
	empresas: string[];
	createdAt: Date;
}

export class AlteracoesClienteResult {
	items: AlteracaoCliente[];
	total_count: number;
	current_page: number;
	page_size: number;
	total_pages: number;
	per_page: number;
}