import {ChangeDetectorRef, Component, HostBinding, Input, OnDestroy, OnInit} from '@angular/core';
import {TipoNotificacao} from '../../../../../core/models/enum/TipoNotificacao';
import {Notificacao} from '../../../../../core/models/api/Notificacao';
import {NotificacoesService} from '../../../../../core/services/api/notificacoes.service';
import {Broadcaster, BroadcasterEvents} from '../../../../../core/models/broadcaster';
import {fadeIn, fadeInOut} from '../../../../animations/fadeInOut';
import {TagNotificacao} from '../../../../../core/models/enum/TagNotificacao';
import {Router} from '@angular/router';
import {getRandomLetter, UtilsService} from '../../../../../core/services/utils.service';
import {$getUserInfo, TokenStorage} from '../../../../../core/auth/token-storage.service';
import {Cliente} from '../../../../../core/models/api/Cliente';

declare let window: any;

export const NOTIFICATION_CLASSES = (() => {
	let n = {};
	n[TagNotificacao.INFO] = 'notification-alert-info';
	n[TagNotificacao.IMPORTANT] = 'notification-alert-importante';
	n[TagNotificacao.RESOLVIDO] = 'notification-alert-resolvido';
	n[TagNotificacao.PENDENTE] = 'notification-alert-pendente';
	return n;
})();

@Component({
	selector: 'm-notification',
	templateUrl: './notification.component.html',
	styleUrls: ['./notification.component.scss'],
	animations: [fadeInOut, fadeIn]
})
export class NotificationComponent implements OnInit, OnDestroy {

	@HostBinding('class')
	// tslint:disable-next-line:max-line-length
	classes = 'm-nav__item m-topbar__notifications m-topbar__notifications--img m-dropdown m-dropdown--large m-dropdown--header-bg-fill m-dropdown--arrow m-dropdown--align-center 	m-dropdown--mobile-full-width';

	@HostBinding('attr.m-dropdown-toggle') attrDropdownToggle = 'click';
	@HostBinding('attr.m-dropdown-persistent') attrDropdownPersisten = 'true';

	@Input() newNotificationBellClass: any;
	@Input() newNotificationDotClass: any = 'dot-new-notification-hide';

	notificationsNotReaded: boolean = true;

	/* enum */
	tipoNotificacao = TipoNotificacao;

	notificacoes: Notificacao[];
	notificacoesPopUp: Notificacao[];

	private obs = [];

	constructor(private cdRef: ChangeDetectorRef, private notificacoesService: NotificacoesService, private _broadcaster: Broadcaster,
				public _router: Router, public utils: UtilsService) {

		this.obs.push(

			/** Se inscreve no evento de Nova Notificação */
			this._broadcaster.on$(BroadcasterEvents.NOVA_NOTIFICACAO).subscribe((data: Notificacao) => {

				let insert = true;

				if (this.notificacoes == null) this.notificacoes = [];

				for (const noti of this.notificacoes) {
					if (noti.idNotificacao == data.idNotificacao) insert = false;
				}

				if (insert) {
					this.notificacoes.unshift(data);
					this.addNotificationPopUpAlert(data);
				}
			}),
		);
	}

	async ngOnInit() {
		await this.loadNotifications();

		/**
		 * Se for um admin, pode lançar notificações de teste
		 */
		if ($getUserInfo().isAdmin) {
			window['notification'] = (
				text,
				tags = [TagNotificacao.INFO],
				tipoNotificacao = TipoNotificacao.PEDIDOS,
				onClickUrl = '',
				buttonText = 'Botão',
				cancelAfter = 300000
			) => {

				this.addNotificationPopUpAlert(<Notificacao>{
					idNotificacao: getRandomLetter(4) + Date.now(),
					text: text,
					tags: tags,
					timestamp: Date.now(),
					tipoNotificacao: tipoNotificacao,
					onClickUrl: onClickUrl,
					buttonText: buttonText,

				}, cancelAfter);
			};
		}
	}

	ngOnDestroy() {

		/** Se desinscreve dos observables */
		for (const o of this.obs) o.unsubscribe();
	}

	detectChanges() { try { this.cdRef.detectChanges(); } catch (err) {  } }

	async loadNotifications() {
		try {
			// this.notificacoes = await this.notificacoesService.getAll().toPromise<Notificacao[]>();
			// TODO: Verificar requisição de notificações
			this.notificacoes = [];
			this.detectChanges();
		} catch (err) { console.log(err); }
	}

	clearAlerts() {
		this.newNotificationBellClass = '';
		this.newNotificationDotClass = 'dot-new-notification-hide';
		this.notificationsNotReaded = false;
		this.detectChanges();
		this.removeAllNotificationPopUpAlert();
	}

	isNotificationDropdownOpen() { return document.getElementById('notifications-topbar').className.includes('m-dropdown--open'); }

	addNotificationPopUpAlert(n: Notificacao, cancelAfter: number = 30000) {
		if (!this.isNotificationDropdownOpen()) {

			if (this.notificacoesPopUp == null) this.notificacoesPopUp = [];
			n.showPopUp = false;

			setTimeout(() => { n.showPopUp = true; this.detectChanges(); }, 100);

			n['timeoutCancel'] = setTimeout(() => {
				this.removeNotificationPopUpAlert(n.idNotificacao, true);
			}, cancelAfter);

			if (n.tags.length > 0) n['class'] = NOTIFICATION_CLASSES[n.tags[0]];

			this.notificacoesPopUp.unshift(n);
			this.detectChanges();
		}

		this.newNotificationAlert(n.tags.length > 0 ? n.tags[0] : undefined);
	}

	newNotificationAlert(tipo: TagNotificacao = TagNotificacao.INFO) {
		this.newNotificationBellClass = 'm-animate-shake';

		if (!this.isNotificationDropdownOpen()) this.newNotificationBellClass += ` bell-new-notification-${tipo}`;

		this.newNotificationDotClass = 'm-animate-blink';
		this.notificationsNotReaded = true;

		const IS_ON_NEW_CART = (this._router.url || '').includes('/lancar-pedido') || (this._router.url || '').includes('/lancar-pedido-rapido');
		const SHOW_NOTIFICATION = !IS_ON_NEW_CART || (IS_ON_NEW_CART && localStorage.getItem(TokenStorage.SHOW_NEW_ORDER_NOTIFICATION_ON_CART) != 'false');

		// if (SHOW_NOTIFICATION && (!$getUserInfo().isAdmin || localStorage.getItem(TokenStorage.PLAY_NOTIFICATION_SOUND) != 'false')) this.playAudio();
		if ((localStorage.getItem(TokenStorage.PLAY_NOTIFICATION_SOUND) != 'false') && SHOW_NOTIFICATION) this.playAudio();

		this.detectChanges();
	}

	/* tslint:disable */
	audioIsPlaying: boolean = false;
	playAudio() {
		if (!this.audioIsPlaying) {
			const _audio = new Audio();
			_audio.src = '../../../../../../assets/app/media/audio/quite-impressed.mp3';
			_audio.loop = false;
			this.amplifyMedia(_audio, 4);
			_audio.addEventListener('ended', () => this.audioIsPlaying = false, false);
			_audio.play();
			this.audioIsPlaying = true;
		}
	}
	/* tslint:enable */

	removeNotificationPopUpAlert(idNotificacao, _callClearTimeout: boolean = true) {
		if (idNotificacao) {
			this.notificacoesPopUp = this.notificacoesPopUp.filter(function (v) {
				if (_callClearTimeout && v.idNotificacao == idNotificacao && v['timeoutCancel']) clearTimeout(v['timeoutCancel']);
				return v.idNotificacao != idNotificacao;
			});
			this.detectChanges();
		}
	}

	removeAllNotificationPopUpAlert() {
		for (const notif of (this.notificacoesPopUp || [])) {
			if (notif['timeoutCancel']) clearTimeout(notif['timeoutCancel']);
		}
		this.notificacoesPopUp = [];
		this.detectChanges();
	}

	amplifyMedia(audio, multiplier) {
		let context = new (window.AudioContext || window.webkitAudioContext);
		let result = { context: context, source: context.createMediaElementSource(audio), gain: context.createGain(), media: audio, amplify: (m) => result.gain.gain.value = m, getAmpLevel: () => result.gain.gain.value  };
		result.source.connect(result.gain);
		result.gain.connect(context.destination);
		result.amplify(multiplier);
		return result;
	}

}
