import {Injectable} from '@angular/core';
import {ServicePattern} from '../../models/interface/ServicePattern';
import {MovimentacaoEstoque} from '../../models/api/MovimentacaoEstoque';
import {HttpClient} from '@angular/common/http';
import {TokenStorage} from '../../auth/token-storage.service';
import {Observable} from 'rxjs';
import {AppConfig} from '../../../config/appConfig';
import {ResultObject} from '../../models/api/ResultObject';
import {EstabelecimentoFunctions} from '../../models/interface/EstabelecimentoFunctions';
import {DateFilter} from '../../models/interface/DateFilter';
import {TipoMovimentacao, TipoMovimentacaoEstoque} from '../../models/enum/TipoMovimentacaoEstoque';

@Injectable()
export class MovimentacaoEstoqueService extends EstabelecimentoFunctions implements ServicePattern<MovimentacaoEstoque> {

	constructor(private http: HttpClient, private tokenStorage: TokenStorage) {
		super();
	}

	getAll(
		dateFilter: DateFilter = {dataInicio: null, dataFim: null},
		page: number = null,
		perPage: number = null,
		componentId: string = '',
		tipoMovimentacao: TipoMovimentacaoEstoque[] = null,
		searchString: string = null,
		dateMovFilter = { dateFilterStart: null, dateFilterEnd: null },
		showOcultos: boolean = false,
		nomeCliente: string = null,
		nomeProduto: string[] = null
	): Observable<MovimentacaoEstoque[]> {
		return this.http.get<MovimentacaoEstoque[]>(
			AppConfig.getAPIEndpoint() + '/movimentacoesEstoque/list/' + this.tokenStorage.getUserInfo().idUsuario,
			{
				headers: AppConfig.getHTTPHeader().headers.set('componentId', componentId),
				params: {
					...this.getEstabelecimentoQueryParam(),
					...this.getDateFilterQueryParam(dateFilter.dataInicio, dateFilter.dataFim),
					...(page || page == 0 ? { page: page + 1 } : {}),
					...(perPage ? { per_page: perPage } : {}),
					...(tipoMovimentacao ? { tipoMovimentacao: tipoMovimentacao.join(',') } : {}),
					...(searchString != null ? { searchString: searchString } : {}),
					...(dateMovFilter != null ? {dataMovInicio: dateMovFilter.dateFilterStart, dataMovFim: dateMovFilter.dateFilterEnd} : {}),
					...(showOcultos ? { showOcultos } : {}),
					...(nomeCliente != null ? { nomeCliente: nomeCliente } : {}),
					...(nomeProduto != null ? { nomeProduto: nomeProduto.join(',') } : {}),
				}
			}
		);
	}

	get(idMovimentacaoEstoque: number, componentId: string = ''): Observable<MovimentacaoEstoque> {
		return this.http.get<MovimentacaoEstoque>(
			AppConfig.getAPIEndpoint() + '/movimentacoesEstoque/' + idMovimentacaoEstoque + '/' + this.tokenStorage.getUserInfo().idUsuario,
			{
				headers: AppConfig.getHTTPHeader().headers.set('componentId', componentId)
			}
		);
	}

	save(body: MovimentacaoEstoque, componentId: string = '', getEstabelecimentoFromCache: boolean = true): Observable<ResultObject<any>> {
		if (getEstabelecimentoFromCache) { body.estabelecimento = this.getEstabelecimentoOnCache(); }
		return this.http.post<ResultObject<any>>(
			AppConfig.getAPIEndpoint() + '/movimentacoesEstoque/' + this.tokenStorage.getUserInfo().idUsuario,
			body,
			{
				headers: AppConfig.getHTTPHeader().headers.set('componentId', componentId)
			}
		);
	}

	registrar(body: MovimentacaoEstoque, componentId: string = ''): Observable<ResultObject<any>> {
		return this.http.post<ResultObject<any>>(
			AppConfig.getAPIEndpoint() + '/movimentacoesEstoque/registrar/' + this.tokenStorage.getUserInfo().idUsuario,
			body,
			{
				headers: AppConfig.getHTTPHeader().headers.set('componentId', componentId)
			}
		);
	}

	apagarRegistro(id: string, componentId: string = ''): Observable<ResultObject<any>> {
		return this.http.delete<ResultObject<any>>(
			AppConfig.getAPIEndpoint() + '/movimentacoesEstoque/apagar_registro/' + id + '/' + this.tokenStorage.getUserInfo().idUsuario,
			{
				headers: AppConfig.getHTTPHeader().headers.set('componentId', componentId)
			}
		);
	}

	editarRegistro(id: string, body: any, componentId: string = ''): Observable<ResultObject<any>> {
		return this.http.put<ResultObject<any>>(
			AppConfig.getAPIEndpoint() + '/movimentacoesEstoque/editar_registro/' + id + '/' + this.tokenStorage.getUserInfo().idUsuario,
			body,
			{
				headers: AppConfig.getHTTPHeader().headers.set('componentId', componentId)
			}
		);
	}

	editar(id: string, body: MovimentacaoEstoque, componentId: string = ''): Observable<ResultObject<any>> {
		return this.http.put<ResultObject<any>>(
			AppConfig.getAPIEndpoint() + '/movimentacoesEstoque/' + this.tokenStorage.getUserInfo().idUsuario + '/' + id,
			body,
			{
				headers: AppConfig.getHTTPHeader().headers.set('componentId', componentId)
			}
		);
	}

	delete(idMovimentacaoEstoque: number): Observable<ResultObject<any>> {
		// TODO
		return null;
	}

	reverterMovimentacao(idMovimentacaoEstoque: number, componentId: string = ''): Observable<ResultObject<any>> {
		return this.http.post<ResultObject<any>>(
			AppConfig.getAPIEndpoint() + '/movimentacoesEstoque/reverter/movimentacao/' + idMovimentacaoEstoque +  '/' + this.tokenStorage.getUserInfo().idUsuario,
			{},
			{
				headers: AppConfig.getHTTPHeader().headers.set('componentId', componentId)
			}
		);
	}

	getEntriesCount(componentId: string = ''): Observable<{entries: number}> {
		return this.http.get<{entries: number}>(
			AppConfig.getAPIEndpoint() + '/movimentacoesEstoque/list/' + this.tokenStorage.getUserInfo().idUsuario,
			{
				headers: AppConfig.getHTTPHeader().headers.set('componentId', componentId),
				params: {
					...this.getEstabelecimentoQueryParam(),
					entriesOnly: true
				}
			}
		);
	}

	imprimirComodato(body: any, componentId: string = ''): Observable<any> {
		return this.http.post<any>(
			AppConfig.getAPIEndpoint() + '/movimentacoesEstoque/imprimirComodato/' + this.tokenStorage.getUserInfo().idUsuario,
			body,
			{
				headers: AppConfig.getHTTPHeader().headers.set('componentId', componentId)
			}
		)
	}

}
