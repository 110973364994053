import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { TokenStorage } from '../../auth/token-storage.service';
import { AppConfig } from '../../../config/appConfig';
import { Observable } from 'rxjs';
import { ResultObject } from '../../models/api/ResultObject';
import { EstabelecimentoFunctions } from '../../models/interface/EstabelecimentoFunctions';
import { NaturezaOpFiscal } from '../../models/api/NaturezaOpFiscal';


@Injectable()
export class NaturezaOpFiscalService extends EstabelecimentoFunctions {

    readonly ROUTE = '/naturezaOpFiscal';

    constructor(private http: HttpClient, private tokenStorage: TokenStorage) {
        super();
    }

    save(naturezaOpFiscal: NaturezaOpFiscal, componentId: string = ''): Observable<ResultObject<any>> {
        return this.http.post<ResultObject<any>>(
            AppConfig.getAPIEndpoint() + '/naturezaOpFiscal/' + this.tokenStorage.getUserInfo().idUsuario,
            naturezaOpFiscal,
            {
                headers: AppConfig.getHTTPHeader().headers.set('componentId', componentId)
            }
        );
    }

    getAll(
        componentId: string = ''
    ): Observable<any[]> {
        return this.http.get<any[]>(
            AppConfig.getAPIEndpoint() + '/naturezaOpFiscal/list/' + this.tokenStorage.getUserInfo().idUsuario,
            {
                headers: AppConfig.getHTTPHeader().headers.set('componentId', componentId)
            }
        );
    }

    update(naturezaOpFiscal: NaturezaOpFiscal, componentId: string = ''): Observable<ResultObject<any>> {
        return this.http.put<ResultObject<any>>(
            AppConfig.getAPIEndpoint() + '/naturezaOpFiscal/' + this.tokenStorage.getUserInfo().idUsuario,
            naturezaOpFiscal,
            {
                headers: AppConfig.getHTTPHeader().headers.set('componentId', componentId)
            }
        );
    }

    delete(idDescricao: string, componentId: string = ''): Observable<ResultObject<any>> {
        return this.http.delete<ResultObject<any>>(
            AppConfig.getAPIEndpoint() + '/naturezaOpFiscal/' + this.tokenStorage.getUserInfo().idUsuario + '/' + idDescricao,
            {
                headers: AppConfig.getHTTPHeader().headers.set('componentId', componentId)
            }
        );
    }

}
