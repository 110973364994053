import {Injectable} from '@angular/core';
import {ServicePattern} from '../../models/interface/ServicePattern';
import {UsuarioFuncao} from '../../models/api/UsuarioFuncao';
import {HttpClient} from '@angular/common/http';
import {TokenStorage} from '../../auth/token-storage.service';
import {Observable} from 'rxjs';
import {ResultObject} from '../../models/api/ResultObject';
import {AppConfig} from '../../../config/appConfig';
import {Cliente} from '../../models/api/Cliente';
import {EstabelecimentoFunctions} from '../../models/interface/EstabelecimentoFunctions';
import {split} from 'ts-node';
import {TipoEstabelecimento} from '../../models/enum/TipoEstabelecimento';

@Injectable()
export class UsuarioFuncaoService extends EstabelecimentoFunctions implements ServicePattern<UsuarioFuncao> {

	readonly ROUTE = '/usuarios';

	constructor(private http: HttpClient, private tokenStorage: TokenStorage) {
		super();
	}

	getAll(
		dateFilter: { dataInicio: Date, dataFim: Date } = {dataInicio: null, dataFim: null},
		empresas: string[] = [],
		lojas: string[] = [],
		componentId: string = '',
		separarEmpresa: boolean = null
	): Observable<any[]> {

		return this.http.get<any[]>(
			AppConfig.getAPIEndpoint() + this.ROUTE + '/list/funcao/' + this.tokenStorage.getUserInfo().idUsuario,
			{
				headers: AppConfig.getHTTPHeader().headers.set('componentId', componentId),
				params: {
					...this.getEstabelecimentoQueryParam(),
					...this.getDateFilterQueryParam(dateFilter.dataInicio, dateFilter.dataFim),
					...((empresas || []).length > 0 ? {empresas: empresas.join(',')} : {}),
					...((lojas || []).length > 0 ? {lojas: lojas.join(',')} : {}),
					...(separarEmpresa != null ? {separarEmpresa: separarEmpresa} : {})
				}
			}
		);
	}

	get(idUsuarioFuncao: number): Observable<UsuarioFuncao> {
		// TODO
		return null;
	}

	save(body: any, componentId: string = ''): Observable<ResultObject<any>> {
		return this.http.post<ResultObject<any>>(
			AppConfig.getAPIEndpoint() + this.ROUTE + '/funcao/' + this.tokenStorage.getUserInfo().idUsuario,
			{
				idEmpresa: body.estabelecimento.tipoEstabelecimento == TipoEstabelecimento.EMPRESA ? body.estabelecimento.empresa.idEmpresa : undefined,
				idLoja: body.estabelecimento.tipoEstabelecimento == TipoEstabelecimento.LOJA ? body.estabelecimento.loja.idLoja : undefined,
				...body
			},
			{
				headers: AppConfig.getHTTPHeader().headers.set('componentId', componentId)
			}
		);
	}

	update(body: UsuarioFuncao, componentId: string = ''): Observable<ResultObject<any>> {
		return this.http.put<ResultObject<any>>(
			AppConfig.getAPIEndpoint() + this.ROUTE + '/funcao/' + body.idUsuarioFuncao + '/' + this.tokenStorage.getUserInfo().idUsuario,
			body,
			{
				headers: AppConfig.getHTTPHeader().headers.set('componentId', componentId)
			}
		);
	}

	delete(idUsuarioFuncao: number, componentId: string = ''): Observable<ResultObject<any>> {
		return this.http.delete<ResultObject<any>>(
			AppConfig.getAPIEndpoint() + this.ROUTE + '/funcao/' + idUsuarioFuncao + '/' + this.tokenStorage.getUserInfo().idUsuario,
			{
				headers: AppConfig.getHTTPHeader().headers.set('componentId', componentId)
			}
		);
	}

	getBackEndRoutes(componentId: string = ''): Promise<string[]> {
		return this.http.get<string[]>(
			AppConfig.getAPIEndpoint() + this.ROUTE + '/list/rotas/' + this.tokenStorage.getUserInfo().idUsuario,
			{
				headers: AppConfig.getHTTPHeader().headers.set('componentId', componentId)
			}
		).toPromise<string[]>();
	}

	getBackEndNotifications(componentId: string = ''): Promise<string[]> {
		return this.http.get<string[]>(
			AppConfig.getAPIEndpoint() + this.ROUTE + '/list/notifications/' + this.tokenStorage.getUserInfo().idUsuario,
			{
				headers: AppConfig.getHTTPHeader().headers.set('componentId', componentId)
			}
		).toPromise<string[]>();
	}

	getAdministrativeUsers(componentId: string = ''): Observable<UsuarioFuncao[]> {
		return this.http.get<UsuarioFuncao[]>(
			AppConfig.getAPIEndpoint() + this.ROUTE + '/funcao/administrativo/list/' + this.tokenStorage.getUserInfo().idUsuario,
			{
				headers: AppConfig.getHTTPHeader().headers.set('componentId', componentId)
			}
		);
	}
}
