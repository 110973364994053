import {ChangeDetectorRef, Component, Input, OnDestroy, OnInit, Output, ViewChild} from '@angular/core';import {AuthenticationService} from '../../../../core/auth/authentication.service';
import {SpinnerButtonOptions} from '../../../partials/content/general/spinner-button/button-options.interface';
import {AuthNoticeService} from '../../../../core/auth/auth-notice.service';
import {TranslateService} from '@ngx-translate/core';
import {FormControl, NgForm, Validators} from '@angular/forms';
import {Subject} from 'rxjs';
import * as objectPath from 'object-path';
import {TokenStorage} from '../../../../core/auth/token-storage.service';
import {ActivatedRoute, Router} from '@angular/router';


@Component({
	selector: 'm-recover-password',
	templateUrl: './recover-password.component.html',
	styleUrls: ['./recover-password.component.scss']
})
export class RecoverPasswordComponent implements OnInit, OnDestroy {

	public static readonly ROUTE_ID = 'callcenter:recuperar_senha';
	public static readonly VISIBLE_ONLY_TO_MANAGER: boolean = false;

	public model: any = {senha: '', confirmarSenha: '', token: ''};
	public loading = false;

	@Input() action: string;
	@Output() actionChange = new Subject<string>();
	@ViewChild('f') f: NgForm;
	private validators = [Validators.required];
	confirmarSenhaFormControl = new FormControl('', this.validators);
	senhaFormControl = new FormControl('', this.validators);
	errors: any = [];
	param: string;

	spinner: SpinnerButtonOptions = {
		active: false,
		spinnerSize: 18,
		raised: true,
		buttonColor: 'primary',
		spinnerColor: 'accent',
		fullWidth: false
	};

	today: number = Date.now();

	constructor(private authService: AuthenticationService, private cdr: ChangeDetectorRef, public authNoticeService: AuthNoticeService,
				private translate: TranslateService, private tokenStorage: TokenStorage, private router: Router, private route: ActivatedRoute) {

		this.param = this.route.snapshot.queryParamMap.get('token');
	}

	ngOnInit() {}

	ngOnDestroy() {}

	loginPage(event: Event) {
		event.preventDefault();
		this.action = 'login';
		this.actionChange.next(this.action);
	}

	detectChanges() {
		try {
			this.cdr.detectChanges();
		} catch (e) {}
	}

	verifyPassword(s: string) {
		if (this.model.senha !== s) {
			this.confirmarSenhaFormControl.setErrors({'incorrect': true});
		}
	}

	submit() {
		this.spinner.active = true;
		if (this.validate(this.f)) {
			this.model.token = this.param;
			this.authService.recoverPassword(this.model).subscribe(response => {
					if (response.success) {
						this.action = 'login';
						this.actionChange.next(this.action);
					} else {
						this.authNoticeService.setNotice(response.message, 'error');
					}
					this.spinner.active = false;
					this.detectChanges();

				},
				err => {
					this.authNoticeService.setNotice(err.error.message, 'error');
					this.spinner.active = false;
					this.detectChanges();
				});
		}
	}

	validate(f: NgForm) {
		if (f.form.status === 'VALID') {
			return true;
		}

		this.errors = [];
		if (objectPath.get(f, 'form.controls.senha.errors.senha')) {
			this.errors.push(this.translate.instant('REC.VALIDATION.INVALID', {name: this.translate.instant('AUTH.INPUT.SENHA')}));
		}
		if (objectPath.get(f, 'form.controls.senha.errors.required')) {
			this.errors.push(this.translate.instant('REC.VALIDATION.REQUIRED', {name: this.translate.instant('AUTH.INPUT.SENHA')}));
		}

		if (!this.senhaFormControl.valid) {
			this.senhaFormControl.setErrors({'incorrect': true});
			return false;
		}
		if (!this.confirmarSenhaFormControl.valid) {
			this.confirmarSenhaFormControl.setErrors({'incorrect': true});
			return false;
		}

		if (this.errors.length > 0) {
			this.authNoticeService.setNotice(this.errors.join('<br/>'), 'error');
			this.spinner.active = false;
		}

		return false;
	}

}
