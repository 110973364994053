import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {TokenStorage} from '../../auth/token-storage.service';
import {AppConfig} from '../../../config/appConfig';
import {Observable} from 'rxjs';
import {ResultObject} from '../../models/api/ResultObject';
import {ServicePattern} from '../../models/interface/ServicePattern';
import {EstabelecimentoFunctions} from '../../models/interface/EstabelecimentoFunctions';
import {TaxaTransacaoCartao} from '../../models/api/TaxaTransacaoCartao';
import {Usuario} from '../../models/api/Usuario';
import {Estabelecimento} from '../../models/template/Estabelecimento';
import {TipoCartao} from '../../models/enum/TipoCartao';

@Injectable()
export class TaxaTransacaoCartoesService extends EstabelecimentoFunctions implements ServicePattern<TaxaTransacaoCartao> {

	readonly ROUTE = '/taxaTransacaoCartoes';

	constructor(private http: HttpClient, private tokenStorage: TokenStorage) {
		super();
	}

	getAll(
		dateFilter: { dataInicio: Date, dataFim: Date } = { dataInicio: null, dataFim: null },
		componentId: string = '',
		tipoCartao?: TipoCartao,
		estabelecimento?: Estabelecimento
	): Observable<TaxaTransacaoCartao[]> {
		return this.http.get<TaxaTransacaoCartao[]>(
			AppConfig.getAPIEndpoint() + this.ROUTE + '/list/' + this.tokenStorage.getUserInfo().idUsuario,
			{
				headers: AppConfig.getHTTPHeader().headers.set('componentId', componentId),
				params: {
					...this.getEstabelecimentoQueryParam(estabelecimento),
					...this.getDateFilterQueryParam(dateFilter.dataInicio, dateFilter.dataFim),
					...(tipoCartao != null ? {tipoCartao: tipoCartao} : {})
				}
			}
		);
	}

	get(idTaxaTransacaoCartao: number): Observable<TaxaTransacaoCartao> {
		// TODO
		return null;
	}

	save(body: TaxaTransacaoCartao, getEstabelecimentoFromCache: boolean = true, componentId: string = ''): Observable<ResultObject<any>> {
		if (getEstabelecimentoFromCache) { body.estabelecimento = this.getEstabelecimentoOnCache(); }
		return this.http.post<ResultObject<any>>(
			AppConfig.getAPIEndpoint() + this.ROUTE + '/' + this.tokenStorage.getUserInfo().idUsuario,
			body,
			{
				headers: AppConfig.getHTTPHeader().headers.set('componentId', componentId)
			}
		);
	}

	update(body: TaxaTransacaoCartao, componentId: string = ''): Observable<ResultObject<any>> {
		return this.http.put<ResultObject<any>>(
			AppConfig.getAPIEndpoint() + this.ROUTE + '/' + this.tokenStorage.getUserInfo().idUsuario,
			body,
			{
				headers: AppConfig.getHTTPHeader().headers.set('componentId', componentId)
			}
		);
	}

	delete(idTaxaTransacaoCartao: number, componentId: string = ''): Observable<ResultObject<any>> {
		return this.http.delete<ResultObject<any>>(
			AppConfig.getAPIEndpoint() + this.ROUTE + '/' + this.tokenStorage.getUserInfo().idUsuario + '/' + idTaxaTransacaoCartao,
			{
				headers: AppConfig.getHTTPHeader().headers.set('componentId', componentId)
			}
		);
	}

	getEntriesCount(componentId: string = ''): Observable<{entries: number}> {
		return this.http.get<{entries: number}>(
			AppConfig.getAPIEndpoint() + this.ROUTE + '/list/' + this.tokenStorage.getUserInfo().idUsuario,
			{
				headers: AppConfig.getHTTPHeader().headers.set('componentId', componentId),
				params: {
					...this.getEstabelecimentoQueryParam(),
					entriesOnly: true
				}
			}
		);
	}
}
