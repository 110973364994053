import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { TokenStorage } from '../../auth/token-storage.service';
import { EstabelecimentoFunctions } from '../../models/interface/EstabelecimentoFunctions';
import { Observable } from 'rxjs';
import { AppConfig } from '../../../config/appConfig';
import { CupomDesconto } from '../../models/api/CupomDesconto';

@Injectable()
export class CupomDescontoService extends EstabelecimentoFunctions {

	private route;

	constructor(private http: HttpClient, private tokenStorage: TokenStorage) {
		super();
		this.route = `${AppConfig.getAPIEndpoint()}/cuponsDesconto/${this.tokenStorage.getUserInfo().idUsuario}`;
	}

	listarCupons(idAplicativo: string, page = 1, per_page = 25, componentId: string = ''): Observable<any> {
		return this.http.get<any>(
			`${this.route}/${idAplicativo}`,
			{
				headers: AppConfig.getHTTPHeader().headers.set('componentId', componentId),
				params: {
					page: page.toString(),
					per_page: per_page.toString()
				}
			}
		);
	}

	atualizarCupom(idCupom: string, body: CupomDesconto, componentId: string = ''): Observable<any> {
		return this.http.put<any>(
			`${this.route}/${idCupom}`,
			body,
			{
				headers: AppConfig.getHTTPHeader().headers.set('componentId', componentId)
			}
		);
	}

	cadastrarCupom(idAplicativo: string, body, componentId: string = ''): Observable<any> {
		return this.http.post<any>(
			`${this.route}/${idAplicativo}`,
			body,
			{
				headers: AppConfig.getHTTPHeader().headers.set('componentId', componentId)
			}
		);
	}

	removerCupom(idCupom: string, componentId: string = ''): Observable<any> {
		return this.http.delete<any>(
			`${this.route}/${idCupom}`,
			{
				headers: AppConfig.getHTTPHeader().headers.set('componentId', componentId)
			}
		);
	}


}
