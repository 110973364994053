import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { TokenStorage } from '../../auth/token-storage.service';
import { Observable } from 'rxjs';
import { AppConfig } from '../../../config/appConfig';
import { EstabelecimentoFunctions } from '../../models/interface/EstabelecimentoFunctions';
import { PedidoStatus } from '../../models/enum/PedidoStatus';
import { DateFilter } from '../../models/interface/DateFilter';
import { PedidoOrigem } from '../../models/enum/PedidoOrigem';
import { Estabelecimento } from '../../models/template/Estabelecimento';
import { TipoMovimentacaoEstoque } from '../../models/enum/TipoMovimentacaoEstoque';

export class RelatorioFilter {
	dateFilter: DateFilter;
	statusFilter: PedidoStatus[];
	pedidoOrigem: PedidoOrigem[];
	idEntregador: string;
	idCliente: string;
	acertado: boolean;
	separarPorEmpresa: boolean;

	constructor(params: any = {}) {
		if (!params) params = {};
		this.dateFilter = params.dateFilter || <any>{};
		this.statusFilter = params.statusFilter || [];
		this.pedidoOrigem = params.pedidoOrigem || [];
		this.idEntregador = params.idEntregador;
		this.idCliente = params.idCliente;
		this.acertado = params.acertado;
		this.separarPorEmpresa = params.separarPorEmpresa;
	}
}

@Injectable()
export class RelatoriosService extends EstabelecimentoFunctions {

	private readonly ROUTE = '/relatorios';

	constructor(private http: HttpClient, private tokenStorage: TokenStorage) {
		super();
	}

	resumoPorPagamento(
		componentId: string = '',
		relatorioFilter: RelatorioFilter = new RelatorioFilter()
	): Observable<any> {

		return this.http.get<any>(
			AppConfig.getAPIEndpoint() + this.ROUTE + '/resumo/pagamento/' + this.tokenStorage.getUserInfo().idUsuario,
			{
				headers: AppConfig.getHTTPHeader().headers.set('componentId', componentId),
				params: {
					...this.getEstabelecimentoQueryParam(),
					...this.getDateFilterWithFieldQueryParam(relatorioFilter.dateFilter.campo, relatorioFilter.dateFilter.dataInicio, relatorioFilter.dateFilter.dataFim),
					...(relatorioFilter.statusFilter && relatorioFilter.statusFilter.length > 0 ? { status: relatorioFilter.statusFilter.join(',') } : {}),
					...(relatorioFilter.pedidoOrigem && relatorioFilter.pedidoOrigem.length > 0 ? { pedidoOrigem: relatorioFilter.pedidoOrigem.join(',') } : {}),
					...(relatorioFilter.idEntregador ? { idEntregador: relatorioFilter.idEntregador } : {}),
					...(relatorioFilter.acertado != null ? { acertado: relatorioFilter.acertado } : {}),
					...(relatorioFilter.separarPorEmpresa != null ? { separarPorEmpresa: relatorioFilter.separarPorEmpresa } : {})
				}
			}
		);
	}

	saldoDespesas(
		componentId: string = '',
		page: number = null,
		perPage: number = null,
		dateFilter: { dataInicio: Date, dataFim: Date, campo: string } = { dataInicio: null, dataFim: null, campo: null },
		relatorioDRE: boolean = true
	): Observable<any> {

		return this.http.get<any>(
			AppConfig.getAPIEndpoint() + this.ROUTE + '/saldo/despesas/' + this.tokenStorage.getUserInfo().idUsuario,
			{
				headers: AppConfig.getHTTPHeader().headers.set('componentId', componentId),
				params: {
					...this.getEstabelecimentoQueryParam(),
					...this.getDateFilterWithFieldQueryParam(dateFilter.campo, dateFilter.dataInicio, dateFilter.dataFim),
					...(page || page == 0 ? { page: page + 1 } : {}),
					...(perPage ? { per_page: perPage } : {}),
					...(relatorioDRE ? { relatorioDRE: relatorioDRE } : {})

				}
			}

		);
	}

	comprasPorCliente(
		componentId: string = '',
		page: number = null,
		perPage: number = null,
		dateFilter: { dataInicio: Date, dataFim: Date, campo: string } = { dataInicio: null, dataFim: null, campo: null },
		searchString: string = null
	): Observable<any> {

		return this.http.get<any>(
			AppConfig.getAPIEndpoint() + this.ROUTE + '/compras/cliente/' + this.tokenStorage.getUserInfo().idUsuario,
			{
				headers: AppConfig.getHTTPHeader().headers.set('componentId', componentId),
				params: {
					...this.getEstabelecimentoQueryParam(),
					...this.getDateFilterWithFieldQueryParam(dateFilter.campo, dateFilter.dataInicio, dateFilter.dataFim),
					...(page || page == 0 ? { page: page + 1 } : {}),
					...(perPage ? { per_page: perPage } : {}),
					...(searchString != null ? { searchString: searchString } : {})
				}
			}
		);
	}

	vendasPorProduto(
		componentId: string = '',
		dateFilter: DateFilter = <DateFilter>{}
	): Observable<any> {

		return this.http.get<any>(
			AppConfig.getAPIEndpoint() + this.ROUTE + '/vendas/produto/' + this.tokenStorage.getUserInfo().idUsuario,
			{
				headers: AppConfig.getHTTPHeader().headers.set('componentId', componentId),
				params: {
					...this.getEstabelecimentoQueryParam(),
					...this.getDateFilterQueryParam(dateFilter.dataInicio, dateFilter.dataFim)
				}
			}
		);
	}

	vendasPorValorNegociado(
		componentId: string = '',
		dateFilter: DateFilter = <DateFilter>{},
		filtros = {
			produto: null,
			valor: null,
			tipoEstoque: null
		}
	): Observable<any> {

		return this.http.get<any>(
			AppConfig.getAPIEndpoint() + this.ROUTE + '/vendas/valorNegociado/' + this.tokenStorage.getUserInfo().idUsuario,
			{
				headers: AppConfig.getHTTPHeader().headers.set('componentId', componentId),
				params: {
					...this.getEstabelecimentoQueryParam(),
					...this.getDateFilterQueryParam(dateFilter.dataInicio, dateFilter.dataFim),
					...(filtros.produto ? { produto: filtros.produto } : {}),
					...(filtros.valor ? { valor: filtros.valor } : {}),
					...(filtros.tipoEstoque ? { tipoEstoque: filtros.tipoEstoque } : {})
				}
			}
		);
	}

	vendasPorValorNegociadoEntregador(
		componentId: string = '',
		dateFilter: DateFilter = <DateFilter>{},
		filtros = {
			produto: null,
			valor: null,
			tipoEstoque: null,
			entregador: null
		}
	): Observable<any> {

		return this.http.get<any>(
			AppConfig.getAPIEndpoint() + this.ROUTE + '/vendas/valorNegociado/entregador/' + this.tokenStorage.getUserInfo().idUsuario,
			{
				headers: AppConfig.getHTTPHeader().headers.set('componentId', componentId),
				params: {
					...this.getEstabelecimentoQueryParam(),
					...this.getDateFilterQueryParam(dateFilter.dataInicio, dateFilter.dataFim),
					...(filtros.produto ? { produto: filtros.produto } : {}),
					...(filtros.valor ? { valor: filtros.valor } : {}),
					...(filtros.tipoEstoque ? { tipoEstoque: filtros.tipoEstoque } : {}),
					...(filtros.entregador ? { entregador: filtros.entregador } : {})
				}
			}
		);
	}

	vendasPorEnregador(
		componentId: string = '',
		dateFilter: DateFilter = <DateFilter>{}
	): Observable<any> {

		return this.http.get<any>(
			AppConfig.getAPIEndpoint() + this.ROUTE + '/vendas/entregador/' + this.tokenStorage.getUserInfo().idUsuario,
			{
				headers: AppConfig.getHTTPHeader().headers.set('componentId', componentId),
				params: {
					...this.getEstabelecimentoQueryParam(),
					...this.getDateFilterQueryParam(dateFilter.dataInicio, dateFilter.dataFim)
				}
			}
		);
	}

	vendasPorCanal(
		componentId: string = '',
		dateFilter: DateFilter = <DateFilter>{},
		indicacaoCompra?: string
	): Observable<any> {
		return this.http.get<any>(
			AppConfig.getAPIEndpoint() + this.ROUTE + '/vendas/canal/' + this.tokenStorage.getUserInfo().idUsuario,
			{
				headers: AppConfig.getHTTPHeader().headers.set('componentId', componentId),
				params: {
					...this.getEstabelecimentoQueryParam(),
					...this.getDateFilterQueryParam(dateFilter.dataInicio, dateFilter.dataFim),
					...(indicacaoCompra ? { indicacaoCompra } : {})
				}
			}
		);
	}

	vendasPorCliente(
		componentId: string = '',
		dateFilter: DateFilter = <DateFilter>{},
		cliente?: string,
		produto?: string,
	): Observable<any> {
		return this.http.get<any>(
			AppConfig.getAPIEndpoint() + this.ROUTE + '/vendas/cliente/' + this.tokenStorage.getUserInfo().idUsuario,
			{
				headers: AppConfig.getHTTPHeader().headers.set('componentId', componentId),
				params: {
					...this.getEstabelecimentoQueryParam(),
					...this.getDateFilterQueryParam(dateFilter.dataInicio, dateFilter.dataFim),
					...(cliente ? { cliente } : {}),
					...(produto ? {produto: produto} : {})
				}
			}
		);
	}

	precoMedioPorProduto(
		componentId: string = '',
		dateFilter: DateFilter = <DateFilter>{},
		cliente?: string,
		produto?: string,
		tipoEstoque?: string,
		ignoreEstabelecimentoOnCache: boolean = false,
		estabelecimentos: { empresa?: string, loja?: string, multiEmpresa?: string, multiLoja?: string } = null
	): Observable<any> {
		return this.http.get<any>(
			AppConfig.getAPIEndpoint() + this.ROUTE + '/vendas/preco_medio_por_produto/' + this.tokenStorage.getUserInfo().idUsuario,
			{
				headers: AppConfig.getHTTPHeader().headers.set('componentId', componentId),
				params: {
					...(
						ignoreEstabelecimentoOnCache == true  ?
							( estabelecimentos != null ? estabelecimentos : {} ) :
							this.getEstabelecimentoQueryParam()
					),
					...this.getDateFilterQueryParam(dateFilter.dataInicio, dateFilter.dataFim),
					...(cliente ? { cliente } : {}),
					...(produto ? {produto: produto} : {}),
					...(tipoEstoque ? {tipoEstoque: tipoEstoque} : {})
				}
			}
		);
	}

	vendasPorEntregadorCanal(
		componentId: string = '',
		dateFilter: DateFilter = <DateFilter>{},
		indicacaoCompra?: string,
		entregador?: string
	): Observable<any> {
		return this.http.get<any>(
			AppConfig.getAPIEndpoint() + this.ROUTE + '/vendas/entregador/canal/' + this.tokenStorage.getUserInfo().idUsuario,
			{
				headers: AppConfig.getHTTPHeader().headers.set('componentId', componentId),
				params: {
					...this.getEstabelecimentoQueryParam(),
					...this.getDateFilterQueryParam(dateFilter.dataInicio, dateFilter.dataFim),
					...(indicacaoCompra ? { indicacaoCompra } : {}),
					...(entregador ? { entregador } : {})
				}
			}
		);
	}

	vendasPorPagamentoCliente(
		componentId: string = '',
		dateFilter: DateFilter = <DateFilter>{},
		tipoPagamento?: string,
		cliente?: string
	): Observable<any> {
		return this.http.get<any>(
			AppConfig.getAPIEndpoint() + this.ROUTE + '/vendas/pagamento/cliente/' + this.tokenStorage.getUserInfo().idUsuario,
			{
				headers: AppConfig.getHTTPHeader().headers.set('componentId', componentId),
				params: {
					...this.getEstabelecimentoQueryParam(),
					...this.getDateFilterQueryParam(dateFilter.dataInicio, dateFilter.dataFim),
					...(tipoPagamento ? { tipoPagamento } : {}),
					...(cliente ? { cliente } : {})
				}
			}
		);
	}

	comprasPorItem(
		componentId: string = '',
		dateFilter: DateFilter = <DateFilter>{}
	): Observable<any> {

		return this.http.get<any>(
			AppConfig.getAPIEndpoint() + this.ROUTE + '/compras/item/' + this.tokenStorage.getUserInfo().idUsuario,
			{
				headers: AppConfig.getHTTPHeader().headers.set('componentId', componentId),
				params: {
					...this.getEstabelecimentoQueryParam(),
					...this.getDateFilterQueryParam(dateFilter.dataInicio, dateFilter.dataFim)
				}
			}
		);


	}

	resumoPorItem(
		componentId: string = '',
		dateFilter: DateFilter = <DateFilter>{}
	): Observable<any> {

		return this.http.get<any>(
			AppConfig.getAPIEndpoint() + this.ROUTE + '/resumo/item/' + this.tokenStorage.getUserInfo().idUsuario,
			{
				headers: AppConfig.getHTTPHeader().headers.set('componentId', componentId),
				params: {
					...this.getEstabelecimentoQueryParam(),
					...this.getDateFilterQueryParam(dateFilter.dataInicio, dateFilter.dataFim)
				}
			}
		);
	}

	controleMensal(
		componentId: string = '',
		dateFilter: DateFilter = <DateFilter>{},
		getEstabelecimentoFromCache: boolean = false,
		estabelecimento: Estabelecimento = null,
		produtosToFilter: string[] = null,
		tiposEntrada: TipoMovimentacaoEstoque[] = []
	): Observable<any> {

		return this.http.get<any>(
			AppConfig.getAPIEndpoint() + this.ROUTE + '/controle/movimento/' + this.tokenStorage.getUserInfo().idUsuario,
			{
				headers: AppConfig.getHTTPHeader().headers.set('componentId', componentId),
				params: {
					...(getEstabelecimentoFromCache ? this.getEstabelecimentoQueryParam() : estabelecimento),
					...this.getDateFilterQueryParam(dateFilter.dataInicio, dateFilter.dataFim),
					...((produtosToFilter || []).length > 0 ? { produtosToFilter: produtosToFilter.join(',') } : {}),
					tiposEntrada: tiposEntrada.join(',')
				}
			}
		);
	}

	notasFormaPagamento(mes: number, ano: number, tipoNota: string, idEst: string, componentId = '') {
		return this.http.get<any>(
			AppConfig.getAPIEndpoint() + this.ROUTE + '/notasFiscais/tipoPagamento/' + this.tokenStorage.getUserInfo().idUsuario,
			{
				headers: AppConfig.getHTTPHeader().headers.set('componentId', componentId),
				params: {
					mes: String(mes),
					ano: String(ano),
					tipoNota,
					idEst
				}
			}
		);
	}

}
