export enum PerfilCliente {
	CONSUMIDOR_FINAL = <any>'1',
	COMERCIO = <any>'2',
	PORTARIA = <any>'3',
	ATACADO = <any>'4',
	AVIARIO = <any>'5',
	RESIDENCIAL = <any>'6',
	SUPERMERCADO = <any>'7',
	INDUSTRIAL = <any>'8',
	ESCOLA = <any>'9',
	PREFEITURA = <any>'10',
	CONDOMINIO = <any>'11',
	PADARIA = <any>'12',
	RESTAURANTE = <any>'13',
	BAR = <any>'14',
	HOSPITAL = <any>'15',
	IGREJA = <any>'16',
	CONCORRENTE = <any>'17',
	REVENDEDOR = <any>'18',
	FILIAL = <any>'19',
	FUNCIONARIO = <any>'20',
	PONTO_DE_VENDA = <any>'21',
	CONVENIO = <any>'22',
	ROTA = <any>'23',
	QRCODEAPP = <any>'24',
}

export const PerfilClienteOptions = [
	{ nome: 'CONSUMIDOR FINAL', valor: PerfilCliente.CONSUMIDOR_FINAL },
	{ nome: 'COMÉRCIO', valor: PerfilCliente.COMERCIO },
	{ nome: 'PORTARIA', valor: PerfilCliente.PORTARIA },
	{ nome: 'ATACADO', valor: PerfilCliente.ATACADO },
	{ nome: 'AVIÁRIO', valor: PerfilCliente.AVIARIO },
	{ nome: 'RESIDENCIAL', valor: PerfilCliente.RESIDENCIAL },
	{ nome: 'SUPERMERCADO', valor: PerfilCliente.SUPERMERCADO },
	{ nome: 'INDUSTRIAL', valor: PerfilCliente.INDUSTRIAL },
	{ nome: 'ESCOLA', valor: PerfilCliente.ESCOLA },
	{ nome: 'PREFEITURA', valor: PerfilCliente.PREFEITURA },
	{ nome: 'CONDOMÍNIO', valor: PerfilCliente.CONDOMINIO },
	{ nome: 'PADARIA', valor: PerfilCliente.PADARIA },
	{ nome: 'RESTAURANTE', valor: PerfilCliente.RESTAURANTE },
	{ nome: 'BAR', valor: PerfilCliente.BAR },
	{ nome: 'HOSPITAL', valor: PerfilCliente.HOSPITAL },
	{ nome: 'IGREJA', valor: PerfilCliente.IGREJA },
	{ nome: 'CONCORRENTE', valor: PerfilCliente.CONCORRENTE },
	{ nome: 'REVENDEDOR', valor: PerfilCliente.REVENDEDOR },
	{ nome: 'FILIAL', valor: PerfilCliente.FILIAL },
	{ nome: 'FUNCIONÁRIO', valor: PerfilCliente.FUNCIONARIO },
	{ nome: 'PONTO DE VENDA', valor: PerfilCliente.PONTO_DE_VENDA },
	{ nome: 'CONVÊNIO', valor: PerfilCliente.CONVENIO },
	{ nome: 'ROTA', valor: PerfilCliente.ROTA },
	{ nome: 'APLICATIVO QR CODE', valor: PerfilCliente.QRCODEAPP }
]