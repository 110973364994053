import {Injectable} from '@angular/core';
import {ServicePattern} from '../../models/interface/ServicePattern';
import {HttpClient} from '@angular/common/http';
import {TokenStorage} from '../../auth/token-storage.service';
import {Observable} from 'rxjs';
import {AppConfig} from '../../../config/appConfig';
import {ResultObject} from '../../models/api/ResultObject';
import {Fornecedor} from '../../models/api/Fornecedor';
import {EstabelecimentoFunctions} from '../../models/interface/EstabelecimentoFunctions';
import {DateFilter} from '../../models/interface/DateFilter';

@Injectable()
export class FornecedorService extends EstabelecimentoFunctions implements ServicePattern<Fornecedor> {

	constructor(private http: HttpClient, private tokenStorage: TokenStorage) {
		super();
	}

	getAll(
		dateFilter: DateFilter = { dataInicio: null, dataFim: null },
		componentId: string = '',
		page: number = null,
		perPage: number = null,
		ignoreEstabelecimentoOnCache: boolean = false,
		estabelecimentos: { empresa?: string, loja?: string, multiEmpresa?: string, multiLoja?: string } = null,
		searchString: string = null,
		estabelecimentoOnParam: boolean = false,
		showAll?: boolean,
		showTransportador?: boolean,
		showAllTransportador: boolean = true
	): Observable<Fornecedor[]> {
		return this.http.get<Fornecedor[]>(
			AppConfig.getAPIEndpoint() + '/fornecedores/list/' + this.tokenStorage.getUserInfo().idUsuario,
			{
				headers: AppConfig.getHTTPHeader().headers.set('componentId', componentId),
				params: {
					...(
						ignoreEstabelecimentoOnCache == true && estabelecimentoOnParam != true ?
							( estabelecimentos != null ? estabelecimentos : {} ) :
							this.getEstabelecimentoQueryParam(ignoreEstabelecimentoOnCache == true ? estabelecimentos : undefined)
					),
					...this.getDateFilterQueryParam(dateFilter.dataInicio, dateFilter.dataFim),
					...(page || page == 0 ? { page: page + 1 } : {}),
					...(perPage ? { per_page: perPage } : {}),
					...(searchString != null ? { searchString: searchString } : {}),
					...(showAll != null ? { showAll: showAll } : {}),
					...(showTransportador != null ? { showTransportador: showTransportador } : {}),
					...(showAllTransportador != null ? { showAllTransportador: showAllTransportador } : {})
				}
			}
		);
	}

	get(idFornecedor: number, componentId: string = ''): Observable<Fornecedor> {
		return this.http.get<Fornecedor>(
			AppConfig.getAPIEndpoint() + '/forncedores/' + idFornecedor + '/' + this.tokenStorage.getUserInfo().idUsuario,
			{
				headers: AppConfig.getHTTPHeader().headers.set('componentId', componentId)
			}
		);
	}

	save(body: Fornecedor, getEstabelecimentoFromCache: boolean = true, componentId: string = ''): Observable<ResultObject<any>> {
		if (getEstabelecimentoFromCache) { body.estabelecimento = this.getEstabelecimentoOnCache(); }
		return this.http.post<ResultObject<any>>(
			AppConfig.getAPIEndpoint() + '/fornecedores/' + this.tokenStorage.getUserInfo().idUsuario,
			body,
			{
				headers: AppConfig.getHTTPHeader().headers.set('componentId', componentId),
			}
		);
	}

	update(body: Fornecedor, componentId: string = ''): Observable<ResultObject<any>> {
		return this.http.put<ResultObject<any>>(
			AppConfig.getAPIEndpoint() + '/fornecedores/' + this.tokenStorage.getUserInfo().idUsuario,
			body,
			{
				headers: AppConfig.getHTTPHeader().headers.set('componentId', componentId)
			}
		);
	}

	delete(idFornecedor: number, componentId: string = ''): Observable<ResultObject<any>> {
		return this.http.delete<ResultObject<any>>(
			AppConfig.getAPIEndpoint() + '/fornecedores/' + this.tokenStorage.getUserInfo().idUsuario + '/' + idFornecedor,
			{
				headers: AppConfig.getHTTPHeader().headers.set('componentId', componentId)
			}
		);
	}

	getEntriesCount(componentId: string = ''): Observable<{entries: number}> {
		return this.http.get<{entries: number}>(
			AppConfig.getAPIEndpoint() + '/fornecedores/list/' + this.tokenStorage.getUserInfo().idUsuario,
			{
				headers: AppConfig.getHTTPHeader().headers.set('componentId', componentId),
				params: {
					...this.getEstabelecimentoQueryParam(),
					entriesOnly: true
				}
			}
		);
	}

	removeAssociation(idFornecedor: string, idEmpresa: string, idLoja: string, componentId: string = '') {
		return this.http.post<ResultObject<any>>(
			AppConfig.getAPIEndpoint() + '/fornecedores/remover/associacao/' + this.tokenStorage.getUserInfo().idUsuario,
			{
				idFornecedor: idFornecedor,
				idEmpresa: idEmpresa,
				idLoja: idLoja
			},
			{
				headers: AppConfig.getHTTPHeader().headers.set('componentId', componentId)
			}
		);
	}

	associate(idFornecedor: string, idEmpresa: string, idLoja: string, componentId: string = '') {
		return this.http.post<ResultObject<any>>(
			AppConfig.getAPIEndpoint() + '/fornecedores/associacao/' + this.tokenStorage.getUserInfo().idUsuario,
			{
				idFornecedor: idFornecedor,
				idEmpresa: idEmpresa,
				idLoja: idLoja
			},
			{ headers: AppConfig.getHTTPHeader().headers.set('componentId', componentId) }
		);
	}

}
