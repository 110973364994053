<!-- splash screen -->
<div #splashScreen class="m-splash-screen">
	<!--<img [attr.src]="splashScreenImage" style="width: 90px; margin-bottom: 30px;" alt="Logo">-->
	<img [attr.src]="splashScreenImage" style="padding: 10px; margin-bottom: 30px; border-radius: 10px;" alt="Logo">

	<!--here we will have circle progress bar-->
	<mat-spinner color="white" diameter="40"></mat-spinner>
	<div style="text-align: center; color: rgba(255, 255, 255, .8); font-size: 13px; margin-top: 10px; font-weight: 100;">
		Carregando...
	</div>
</div>

<!--Loading do sistema-->
<div id="loader-bg" *ngIf="sisloading">
	<div id="loader-spinner"></div>
</div>

<!-- Persistent Notifications -->
<div class="persistent-notification-container">
	<ng-container *ngFor="let notif of persistentNotifications; let i = index;">

		<div *ngIf="notif.showPopUp == true && showPersisentNotifications" [@fadeInOut]
			 [ngClass]="notif['class']"
			 style="cursor: auto !important; position: absolute !important;"
			 [class.persistent-notification-container-hidden]="i !== primeiroIndex()"
			 class="notification-alert">

			<!-- top options -->
			<div style="display: block; margin-bottom: 10px;">
				<div *ngIf="notif.timestamp" class="not-timestamp" style="float: left !important; margin-top: -2px !important;">
					{{notif.timestamp | mTimeElapsed}}
				</div>
				<i (click)="notif.showPopUp = false; detectChanges()"
				   class="la la-close"
				   style="float: right; cursor: pointer;"></i>
				<div style="clear: both;"></div>
			</div>
			<!-- fim top options -->

			<div *ngIf="notif?.origem_app == true" class="app-title">Aplicativo</div>

			<div class="not-title">Atenção!</div>

			<div class="not-desc">{{notif.text}}</div>

			<div *ngIf="router.url != notif.onClickUrl" [@fadeInOut]
				 (click)="router.navigate([notif.onClickUrl]);"
				 class="not-button callcenter-button callcenter-button-small callcenter-button-blue">
				<i class="left-icon la la-external-link"></i>
				<span>{{notif.buttonText}}</span>
			</div>

			<div style="clear: both"></div>
		</div>

	</ng-container>
</div>
<!-- Fim Persistent Notifications -->

<!-- Chamada em Andamento -->
<ng-container *ngFor="let chamadaCliente of chamadaClientes || []">
	<div id="pop-up-chamada-{{chamadaCliente.idChamada}}" *ngIf="chamadaCliente != null && isLogged == true" [@fadeInOut] class="pop-up-chamada mat-elevation-z6">
		<div id="pop-up-chamada-title-{{chamadaCliente.idChamada}}" class="p-u-chamada-title">Chamada em Andamento</div>

		<div class="p-u-cliente-info">
			<div class="p-u-chamada-user-name">
				{{ chamadaCliente?.idCliente == null || chamadaCliente?.nome == null ? '+55 ' + chamadaCliente?.telefone : chamadaCliente?.nome }}
			</div>
			<img class="p-u-chamada-user-image" src="./assets/app/media/img/users/userProfile.png">
		</div>

		<div *ngIf="chamadaCliente?.idCliente != null" class="p-u-telefone-info">
			{{ '+55 ' + chamadaCliente?.telefone }}
		</div>
		
		<div *ngIf="chamadaCliente?.idCliente != null" class="p-u-endereco-info">
			{{ getEnderecoDefaultFormated(chamadaCliente?.enderecos)}}
		</div>

		<div *ngIf="chamadaCliente?.via" class="p-u-via-info">
			{{ chamadaCliente?.via }}
		</div>

		<hr/>

		<div (click)="iniciarChamada(chamadaCliente)" style="margin: 0 !important;"
			 [class.callcenter-button-disabled]="chamadaCliente['carregando'] == true"
			 class="callcenter-button">
			<i class="left-icon fa fa-phone-volume"></i>
			<span>Iniciar Pedido</span>
		</div>

		&nbsp;

		<div (click)="removeChamada(chamadaCliente)" style="margin: 0 !important; background-color: #f4516c;"
			 [class.callcenter-button-disabled]="chamadaCliente['carregando'] == true"
			 class="callcenter-button">
			<i class="left-icon fa fa-times"></i>
			<span>Fechar</span>
		</div>

		<div style="clear: both;"></div>
	</div>
</ng-container>
<!-- FIM Chamada em Andamento -->

<!-- VENDERGAS Pdf Viewer -->
<div id="vendergas-pdf-viewer" class="pdf-viewer">
	<div (click)="closePDFViewer()" class="pdf-viewer-background"></div>
	<div (click)="closePDFViewer()" class="pdf-viewer-close-button">Fechar</div>
	<div id="pdf-viewer-container" class="pdf-viewer-container mat-elevation-z6">

	</div>
</div>
<!-- Fim VENDERGAS Pdf Viewer -->

<router-outlet></router-outlet>

<!-- Estabelecimento Selection -->
<div *ngIf="showLeftMenuContainerEst == true" [@fadeInOut] class="left-menu-select-est-container">

	<div class="background-click-container" (click)="closeLeftMenu()"></div>

	<div [class.left-menu-select-est-showed]="showLeftMenuEst == true"
		 class="left-menu-select-est mat-elevation-z6">

		<div class="l-f-header mat-elevation-z6">

			<div (click)="closeLeftMenu()" class="l-f-back-button">
				<i class="fa fa-chevron-left"></i>
				<span>Voltar</span>
			</div>

			<hr/>

			<div class="l-f-title">
				<i class="fa fa-building"></i>
				<span>Selecionar Estabelecimento</span>
			</div>

		</div>

		<div class="l-m-options-container">
			<!--<div class="l-m-options-container l-m-options-background mat-elevation-z6">-->

			<div (click)="toggleTodosEstabelecimentos()" class="checkbox-container">
				<mat-checkbox [checked]="allSelected" color="accent">Selecionar todos</mat-checkbox>
			</div>

			<input autocomplete="off" class="search-est" placeholder="Buscar..." (input)="buscarEstabelecimento($event.target.value)" [(ngModel)]="qsEst">

			<div style="clear: both"></div>

		</div>

		<hr/>

		<div class="l-f-title l-f-title-background mat-elevation-z6">
			<i class="fa fa-chevron-right"></i>
			<span>Matriz</span>
		</div>

		<div *ngIf="_empresas == null || _empresas?.length < 1" [@fadeInOut] class="l-m-select-est-no-result">
			<i class="fa fa-stream"></i>
			<span class="text-no-result">Nenhum resultado encontrado</span>
		</div>

		<div *ngIf="_empresas != null && _empresas?.length > 0" [@fadeInOut] class="l-m-result-est-container styled-scrollbar">
			<ng-container *ngFor="let emp of _empresas">
				<div *ngIf="!emp['disabled']" (click)="toggleEmpresa(emp)" class="l-m-result-est">

					<mat-checkbox [checked]="emp.isSelected || allSelected == true" color="accent"></mat-checkbox>

					<div class="l-m-est-info-res">
						<span *ngIf="estabelecimento?.empresa != null && estabelecimento?.empresa?.idEmpresa == emp?.idEmpresa" class="l-m-est-selection-title">
							Empresa Selecionada
						</span>
						<span class="l-m-est-nome">{{emp?.nomeFantasia}}</span>
						<span class="l-m-est-end">{{getEnderecoDefaultFormated(emp?.enderecos)}}</span>
					</div>
				</div>
			</ng-container>
		</div>

		<div class="l-f-title l-f-title-background mat-elevation-z6">
			<i class="fa fa-chevron-right"></i>
			<span>Revendas</span>
		</div>

		<div *ngIf="_lojas == null || _lojas?.length < 1" [@fadeInOut] class="l-m-select-est-no-result">
			<i class="fa fa-stream"></i>
			<span class="text-no-result">Nenhum resultado encontrado</span>
		</div>

		<div class="l-m-result-est-container styled-scrollbar">
			<div *ngFor="let loj of _lojas" (click)="toggleLoja(loj)" class="l-m-result-est">
				<mat-checkbox [checked]="loj.isSelected || allSelected == true" color="accent"></mat-checkbox>

				<div class="l-m-est-info-res">
					<span *ngIf="estabelecimento?.loja != null && estabelecimento?.loja?.idLoja == loj?.idLoja" class="l-m-est-selection-title">
						Loja Selecionada
					</span>
					<span *ngIf="loj?.empresa != null" class="l-m-est-nome-empresa mat-elevation-z1"> Pertence a <b>{{loj?.empresa?.nomeFantasia}}</b> </span>
					<span *ngIf="loj?.empresa == null" class="l-m-est-nome-empresa mat-elevation-z1"> Não pertence a nenhuma empresa </span>
					<span class="l-m-est-nome">{{loj?.nomeFantasia}}</span>
					<span class="l-m-est-end">{{getEnderecoDefaultFormated(loj?.enderecos)}}</span>
				</div>
			</div>
		</div>


	</div>

</div>
<!-- Fim Estabelecimento Selection -->

<!-- POPUP ASSINATURA CONTRATO -->
<div class="popup" *ngIf="popupContrato && isLogged && userInfo" [@fadeInOut]>
	<div class="popup-box mat-elevation-z6">
		<div class="association-title">
			<div class="a-title">Assinar contrato</div>
			<i (click)="popupContrato = false;" class="la la-close" *ngIf="!congelarPopup"></i>
		</div>
		<div class="popupMsg" *ngIf="contratoMsg" [innerHtml]="contratoMsg"></div>
		<div id="signature-div"></div>
	</div>
</div>
<!-- FIM POPUP -->

<!-- POPUP SEFAZ -->
<div class="popupSefaz" *ngIf="popupSefaz" [@fadeInOut]>
	<div class="popup-box mat-elevation-z6">
		<div class="popupMsg">Comunicando com a SEFAZ...</div>
	</div>
</div>
<!-- FIM POPUP -->

<!-- POPUP ENVIO DE MULTIPLAS NFCES -->
<div class="popupSefaz" *ngIf="emitindoNf" [@fadeInOut]>
	<div class="popup-box mat-elevation-z6">
		<div class="popupMsg">Por favor aguarde, {{quantidadeNotas}} Notas Fiscais sendo processadas.</div>
	</div>
</div>
<!-- FIM POPUP -->


<div *ngIf="mostrarPopUp" [@fadeInOut] class="pop-up-novidades">

	<div class="p-novidades-container mat-elevation-z6">

		<!--Waves Container-->
		<div>
			<div class="top-info">

				<img class="t-inf-vendergas-logo" [src]="logo">

				<div [class.negative-margin-top]="vezesPopUpMostrado() >= 2" (click)="fecharPopUp()" class="callcenter-button callcenter-button-green mat-elevation-z3">
					<span>Fechar</span>
					<i class="right-icon la la-close"></i>
				</div>
				<mat-checkbox *ngIf="vezesPopUpMostrado() >= 2" style="display: block; margin-top: -5px; margin-right: 30px;" [checked]="ignorarPopUp" (change)="ignorarPopUp = !ignorarPopUp" >Não exibir mais esse Pop-up</mat-checkbox>
			</div>
			<svg class="waves" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" viewBox="0 24 150 28" preserveAspectRatio="none" shape-rendering="auto">
				<defs>
					<path id="gentle-wave" d="M-160 44c30 0 58-18 88-18s 58 18 88 18 58-18 88-18 58 18 88 18 v44h-352z" />
				</defs>
				<g class="parallax">
					<use xlink:href="#gentle-wave" x="48" y="0" fill="rgba(255,255,255,0.7" />
					<use xlink:href="#gentle-wave" x="48" y="3" fill="rgba(255,255,255,0.5)" />
					<use xlink:href="#gentle-wave" x="48" y="5" fill="rgba(255,255,255,0.3)" />
					<use xlink:href="#gentle-wave" x="48" y="7" fill="#fff" />
				</g>
			</svg>
			<div class="bottom-info">
				<img class="lets-encrypt-badge" src="../assets/app/media/img/logos/lets-encrypt.png">
			</div>
		</div>
		<!--Waves end-->

		<div style="width: 100%; height: calc(100% - 50px); margin-top: 60px; display:inline-block;">

			<div style="background: #0e0554;" class="inside-container mat-elevation-z6">
				<div class="i-c-content">
					<span class="title-inside">
						<i style="width: 7px !important; margin-right: 10px !important;" class="fa fa-exclamation"></i>
						Novidades da Versão
					</span>
					<div class="i-c-body styled-scrollbar">
						<ul>
							<li>
								Atualizamos a forma de busca de clientes pelo Teleatendimento, agora com uma nova interface será possível buscar o cliente por Número, Nome e Endereço de forma mais rápida e eficiente. 
							</li>
							<li>
								Agora o nosso sistema conta com mais um novo  relatório, o “Relatório de Retorno CNAB”, que se encontra em Gestão > Boletos > Relatório de Retorno CNAB.
							</li>
							<li>
								Foi inserido um alerta de renovação de certificado digital, 10 dias antes do certificado digital expirar o usuário começará a receber um alerta no sistema avisando quantos dias faltam para o certificado vencer.
							</li>
							<li>
								Foi adicionado a informação de bloqueio de  estoque e fechamento de caixa  nos relatórios de fechamento de caixa e estoque completo.
							</li>
							<li>
								Nessa nova versão pelo “relatório de notas emitidas” será possível visualizar os dados do usuário que emitiu a nota pelo sistema.*-
							</li>
							<li>
								Agora será possível cadastrar uma conta parcelada em “Despesa de veículo” e “Controle de combustível”.
							</li>
							<li>
								Agora temos um botão de “Guias e Tutoriais” ele está localizado no canto superior direito, ao clicar no botão de ”?” será redirecionado ao uma nova tela e lá terá acesso a todos os Guias e Tutorias disponíveis, com o tempo iremos adicionar mais, se tiver alguma sugestão nos envie pelo chat online.
							</li>
						</ul>
					</div>
				</div>
			</div>

			<!-- <div style="background: #39d4b9;" class="inside-container mat-elevation-z6">
				<div class="i-c-content">
					<span class="title-inside">
						<i class="fa fa-bullhorn"></i>
						Comunicados
					</span>
					<div class="i-c-body styled-scrollbar">
						<h2>Observações Venda Fiada</h2>
						<ul>
							<li> Ao marcar um pedido fiado como entregue, o estoque será movimentado.</li>
							<li> Ao lançar um pedido fiado na Portaria que já é marcado como Entregue, o estoque será
								movimentado.
							</li>
							<li> Ao cancelar um pedido fiado, a movimentação do estoque será revertida.</li>
							<li>
								Ao acertar um pedido fiado, somente a venda será gerada, a movimentação do estoque não
								acontece mais, pois ela já aconteceu no momento que o pedido foi marcado como Entregue.
							</li>
							<li>
								Ao Cancelar um pedido fiado, ele some do acerto financeiro e do relatório de pedidos
								fiados.
							</li>
							<li>
								No Aplicativo do entregador ao marcar um pedido fiado como entregue, o estoque é
								movimentado.
							</li>
						</ul>
					</div>
				</div>
			</div> -->

		</div>

		<!-- <div class="mat-elevation-z6" style="overflow: hidden; background: #FF9800; z-index: 10; width: calc(30% - 20px); vertical-align: top; margin-left: 20px; height: calc(100% - 220px); margin-top: 60px; border-radius: 10px; display:inline-block;">
			<span class="title-inside">
				<i class="fa fa-money-check"></i>
				Boletos
			</span>
		</div> -->

	</div>


</div>
