import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { TokenStorage } from '../../auth/token-storage.service';
import { AppConfig } from '../../../config/appConfig';
import axios from 'axios'

@Injectable()
export class ScriptService {
	readonly ROUTE = '/scripts'

	constructor(private http: HttpClient, private tokenStorage: TokenStorage) { }

	listarScripts(componentId: string = ''): Observable<any[]> {
		return this.http.get<any[]>(
			AppConfig.getAPIEndpoint() + this.ROUTE + '/listar_scripts/' + this.tokenStorage.getUserInfo().idUsuario,
			{
				headers: AppConfig.getHTTPHeader().headers.set('componentId', componentId)
			}
		)
	}

	executarScript(nome: string, args: string = '', componentId: string = ''): Observable<any> {
		return this.http.post<any>(
			AppConfig.getAPIEndpoint() + this.ROUTE + '/executar_script/' + this.tokenStorage.getUserInfo().idUsuario,
			{
				nome,
				args
			},
			{
				headers: AppConfig.getHTTPHeader().headers.set('componentId', componentId)
			}
		)
	}

	removerScript(nome: string, componentId: string = ''): Observable<any> {
		return this.http.post<any>(
			AppConfig.getAPIEndpoint() + this.ROUTE + '/remover_script/' + this.tokenStorage.getUserInfo().idUsuario,
			{
				nome
			},
			{
				headers: AppConfig.getHTTPHeader().headers.set('componentId', componentId)
			}
		)
	}

	renomearScript(nome: string, novoNome: string, componentId: string = ''): Observable<any> {
		return this.http.put<any>(
			AppConfig.getAPIEndpoint() + this.ROUTE + '/renomear_script/' + this.tokenStorage.getUserInfo().idUsuario,
			{
				nome,
				novoNome
			},
			{
				headers: AppConfig.getHTTPHeader().headers.set('componentId', componentId)
			}
		)
	}

	adicionarScript(nome: string, arquivo: File, componentId: string = ''): Observable<any> {
		const formData = new FormData()
		formData.append('nome', nome)
		formData.append('arquivo', arquivo)
		return this.http.post<any>(
			AppConfig.getAPIEndpoint() + this.ROUTE + '/adicionar_script/' + this.tokenStorage.getUserInfo().idUsuario,
			formData,
			{
				headers: new HttpHeaders({ componentId })
			}
		)
	}

	downloadLog(nome: string, componentId: string = ''): Promise<any> {
		return axios.post(
			AppConfig.getAPIEndpoint() + this.ROUTE + '/download_log/' + this.tokenStorage.getUserInfo().idUsuario,
			{
				nome
			},
			{
				headers: {
					componentId,
					Authorization: this.tokenStorage.getAccessTokenSync()
				}
			}
		)
	}

	limparUltimoScript(componentId: string = ''): Observable<any> {
		return this.http.delete<any>(
			AppConfig.getAPIEndpoint() + this.ROUTE + '/limpar_ultimo_script/' + this.tokenStorage.getUserInfo().idUsuario,
			{
				headers: AppConfig.getHTTPHeader().headers.set('componentId', componentId)
			}
		)
	}

	pararScript(componentId: string = ''): Observable<any> {
		return this.http.delete<any>(
			AppConfig.getAPIEndpoint() + this.ROUTE + '/parar_script/' + this.tokenStorage.getUserInfo().idUsuario,
			{
				headers: AppConfig.getHTTPHeader().headers.set('componentId', componentId)
			}
		)
	}
}