import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { TokenStorage } from '../../auth/token-storage.service';
import { AppConfig } from '../../../config/appConfig';

@Injectable()
export class CategoriasProdutoService {
	readonly ROUTE = '/categoriasProduto'

	constructor(private http: HttpClient, private tokenStorage: TokenStorage) { }

	cadastrarCategoria(nome: string, tipoCategoria: number, empresas: string[] = [], lojas: string[] = [], componentId: string = ''): Observable<any> {
		return this.http.post<any>(
			AppConfig.getAPIEndpoint() + this.ROUTE + '/' + this.tokenStorage.getUserInfo().idUsuario,
			{
				nome,
				tipoCategoria,
				empresas,
				lojas
			},
			{
				headers: AppConfig.getHTTPHeader().headers.set('componentId', componentId)
			}
		)
	}

	getAll(componentId: string = ''): Observable<any[]> {
		return this.http.get<any[]>(
			AppConfig.getAPIEndpoint() + this.ROUTE + '/' + this.tokenStorage.getUserInfo().idUsuario,
			{
				headers: AppConfig.getHTTPHeader().headers.set('componentId', componentId)
			}
		)
	}

	delete(id: string, componentId: string = ''): Observable<any> {
		return this.http.delete<any>(
			AppConfig.getAPIEndpoint() + this.ROUTE + '/' + id + '/' + this.tokenStorage.getUserInfo().idUsuario,
			{
				headers: AppConfig.getHTTPHeader().headers.set('componentId', componentId)
			}
		)
	}

	editar(id: string, nome?: string, empresas?: string[], lojas?: string[], componentId: string = ''): Observable<any> {
		return this.http.put<any>(
			AppConfig.getAPIEndpoint() + this.ROUTE + '/' + id + '/' + this.tokenStorage.getUserInfo().idUsuario,
			{
				nome,
				empresas,
				lojas
			},
			{
				headers: AppConfig.getHTTPHeader().headers.set('componentId', componentId)
			}
		)
	}
}