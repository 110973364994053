export enum IndicacaoCompra {
	EMAIL = <number>1,
	IMA = <number>2,
	OUTDOOR = <number>3,
	INTERNET = <number>4,
	REDE_SOCIAL = <number>5,
	WHATSAPP = <number>6,
	APLICATIVO = <number>7,
	PORTARIA = <number>8,
	TELEATENDIMENTO = <number>9,
	PLANTAO = <number>10,
	VENDA_A_PORTA = <number>11,
	SINALIZACAO = <number>12,
	IMPRESSO = <number>13,
	CLIENTE_FIDELIZADO = <number>14,
	AUTOMATICA = <number>15,
	AGREGADO = <number>16,
	COMERCIO_INDUSTRIA = <number>17,
	CELULAR = <number>18,
	TELEFONE = <number>19,
	CH_APP = <number>20,
	PG_APP = <number>21,
	UP_APP = <number>22,
	QRCODEAPP = <number>23,
	RADIO = <number>24
}
