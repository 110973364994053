import {DefaultFields} from '../interface/DefaultFields';
import {Produto} from './Produto';
import {Fornecedor} from './Fornecedor';
import {TipoEstoque} from '../enum/TipoEstoque';
import {Empresa} from './Empresa';
import {Estabelecimento} from '../template/Estabelecimento';

export class Estoque extends DefaultFields<Estoque> {
	idEstoque: number;
	produto: Produto[];
	fornecedor: Fornecedor;
	inicial: number;
	entradas: number;
	saidas: number;
	vendas: number;
	avariados: number;
	avariadosEntrada: number;
	final: number;
	isInUse: boolean;
	isHistory: boolean;
	numero: string;
	tipoEstoque: TipoEstoque;
	dtMovimentacao: Date;
	empresaEstoque: Empresa;
	estabelecimento: Estabelecimento;
}
