import {Injectable} from '@angular/core';
import {ServicePattern} from '../../../models/interface/ServicePattern';
import {HttpClient} from '@angular/common/http';
import {TokenStorage} from '../../../auth/token-storage.service';
import {Observable} from 'rxjs';
import {AppConfig} from '../../../../config/appConfig';
import {ResultObject} from '../../../models/api/ResultObject';
import {EstabelecimentoFunctions} from '../../../models/interface/EstabelecimentoFunctions';
import {DateFilter} from '../../../models/interface/DateFilter';
import {OperacaoFiscal} from '../../../models/api/OperacaoFiscal';
import {TipoNotaFiscal} from '../../../models/enum/notaFiscal/TipoNotaFiscal';
import {Estabelecimento} from '../../../models/template/Estabelecimento';
import {TipoNF} from '../../../models/enum/notaFiscal/TipoNF';

export class OperacaoFiscalFilter {
	dateFilter?: DateFilter;
	tipo?: TipoNotaFiscal;
	estabelecimento?: Estabelecimento;
	atalho?: boolean;
	tipo_notaFiscal?: TipoNF;
	ativo?: boolean;
	desc?: string;

	constructor(params: OperacaoFiscalFilter = <any>{}) {
		if (!params) params = <any>{};
		this.dateFilter = params.dateFilter || <any>{};
		this.tipo = params.tipo;
		this.estabelecimento = params.estabelecimento;
		this.atalho = params.atalho;
		this.tipo_notaFiscal = params.tipo_notaFiscal;
		this.ativo = params.ativo;
	}
}

@Injectable()
export class OperacaoFiscalService extends EstabelecimentoFunctions implements ServicePattern<OperacaoFiscal> {

	private readonly ROUTE = '/notaFiscal/operacao/fiscal';

	constructor(private http: HttpClient, private tokenStorage: TokenStorage) {
		super();
	}

	getAll(
		componentId: string = '',
		page: number = null,
		perPage: number = null,
		operacaoFiscalFilter: OperacaoFiscalFilter = new OperacaoFiscalFilter()
	): Observable<OperacaoFiscal[]> {

		return this.http.get<OperacaoFiscal[]>(
			AppConfig.getAPIEndpoint() + this.ROUTE + '/list/' + this.tokenStorage.getUserInfo().idUsuario,
			{
				headers: AppConfig.getHTTPHeader().headers.set('componentId', componentId),
				params: {
					...this.getEstabelecimentoQueryParam(operacaoFiscalFilter.estabelecimento),
					// ...this.getDateFilterQueryParam(operacaoFiscalFilter.dateFilter.dataInicio, operacaoFiscalFilter.dateFilter.dataFim),
					...(page || page == 0 ? { page: page + 1 } : {}),
					...(perPage ? { per_page: perPage } : {}),
					...(operacaoFiscalFilter.tipo != null ? { tipo: operacaoFiscalFilter.tipo } : {}),
					...(operacaoFiscalFilter.atalho != null ? { atalho: operacaoFiscalFilter.atalho } : {}),
					...(operacaoFiscalFilter.ativo != null ? { ativo: operacaoFiscalFilter.ativo } : {}),
					...(operacaoFiscalFilter.desc != null ? { desc: operacaoFiscalFilter.desc } : {}),
					...(operacaoFiscalFilter.tipo_notaFiscal != null ? { tipo_notaFiscal: operacaoFiscalFilter.tipo_notaFiscal } : {})
				}
			}
		);
	}

	get(idOperacaoFiscal: string, componentId: string = ''): Observable<OperacaoFiscal> {
		return this.http.get<OperacaoFiscal>(
			`${AppConfig.getAPIEndpoint()}${this.ROUTE}/info/${this.tokenStorage.getUserInfo().idUsuario}/${idOperacaoFiscal}`,
			{
				headers: AppConfig.getHTTPHeader().headers.set('componentId', componentId),
				params: {}
			}
		);
	}

	save(body: OperacaoFiscal, componentId: string = '', getEstabelecimentoFromCache: boolean = true): Observable<ResultObject<any>> {
		if (getEstabelecimentoFromCache) { (<any>body).estabelecimento = this.getEstabelecimentoOnCache(); }
		return this.http.post<ResultObject<any>>(
			AppConfig.getAPIEndpoint() + this.ROUTE + '/' + this.tokenStorage.getUserInfo().idUsuario,
			body,
			{
				headers: AppConfig.getHTTPHeader().headers.set('componentId', componentId)
			}
		);
	}

	update(body: OperacaoFiscal, componentId: string =''): Observable<ResultObject<any>> {
		return this.http.put<ResultObject<any>>(
            AppConfig.getAPIEndpoint() + this.ROUTE + '/' + this.tokenStorage.getUserInfo().idUsuario,
            body,
            {
                headers: AppConfig.getHTTPHeader().headers.set('componentId', componentId)
            }
        );
	}

	delete(idOperacaoFiscal: string, componentId: string = ''): Observable<ResultObject<any>> {
		return this.http.delete<ResultObject<any>>(
			`${AppConfig.getAPIEndpoint()}${this.ROUTE}/${this.tokenStorage.getUserInfo().idUsuario}/${idOperacaoFiscal}`,
			{
				headers: AppConfig.getHTTPHeader().headers.set('componentId', componentId)
			}
		);
	}

	getEntriesCount(componentId: string = ''): Observable<{entries: number}> {
		return this.http.get<{entries: number}>(
			AppConfig.getAPIEndpoint() + this.ROUTE + '/list/' + this.tokenStorage.getUserInfo().idUsuario,
			{
				headers: AppConfig.getHTTPHeader().headers.set('componentId', componentId),
				params: {
					...this.getEstabelecimentoQueryParam(),
					entriesOnly: true
				}
			}
		);
	}

}
