import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {TokenStorage} from '../../auth/token-storage.service';
import {AppConfig} from '../../../config/appConfig';
import {Observable} from 'rxjs';
import {ResultObject} from '../../models/api/ResultObject';
import {ServicePattern} from '../../models/interface/ServicePattern';
import {EstabelecimentoFunctions} from '../../models/interface/EstabelecimentoFunctions';
import {Estabelecimento} from '../../models/template/Estabelecimento';
import {DateFilter} from '../../models/interface/DateFilter';
import {SMTPConfig} from '../../models/api/SMTPConfig';

@Injectable()
export class SMTPConfigService extends EstabelecimentoFunctions implements ServicePattern<SMTPConfig> {

	readonly ROUTE = '/smtp';

	constructor(private http: HttpClient, private tokenStorage: TokenStorage) {
		super();
	}

	getAll(dateFilter: DateFilter = { }, page: number = null, perPage: number = null, componentId: string = '', estabelecimento?: Estabelecimento): Observable<SMTPConfig[]> {
		return this.http.get<SMTPConfig[]>(
			`${AppConfig.getAPIEndpoint()}${this.ROUTE}/${this.tokenStorage.getUserInfo().idUsuario}`,
			{
				headers: AppConfig.getHTTPHeader().headers.set('componentId', componentId),
				params: {
					...this.getEstabelecimentoQueryParam(estabelecimento),
					...this.getDateFilterWithFieldQueryParam(dateFilter.campo || 'dataVenda', dateFilter.dataInicio, dateFilter.dataFim),
					...(page || page == 0 ? { page: page + 1 } : {}),
					...(perPage ? { per_page: perPage } : {})
				}
			}
		);
	}

	test(body: SMTPConfig, componentId: string = ''): Observable<any> {
		return this.http.post<any>(
			`${AppConfig.getAPIEndpoint()}${this.ROUTE}/testar_conexao`,
			body,
			{
				headers: AppConfig.getHTTPHeader().headers.set('componentId', componentId)
			}
		);
	}

	get(_id: string, componentId: string = ''): Observable<SMTPConfig> {
		return this.http.get<SMTPConfig>(
			`${AppConfig.getAPIEndpoint()}${this.ROUTE}/${this.tokenStorage.getUserInfo().idUsuario}`,
			{
				headers: AppConfig.getHTTPHeader().headers.set('componentId', componentId),
				params: {
					...this.getEstabelecimentoQueryParam(),
					_id
				}
			}
		);
	}

	save(body: SMTPConfig, componentId: string = '' , getEstabelecimentoFromCache: boolean = true): Observable<ResultObject<SMTPConfig>> {
		if (getEstabelecimentoFromCache == true) body.estabelecimento = this.getEstabelecimentoOnCache();
		return this.http.post<ResultObject<SMTPConfig>>(
			`${AppConfig.getAPIEndpoint()}${this.ROUTE}/${this.tokenStorage.getUserInfo().idUsuario}`,
			body,
			{
				headers: AppConfig.getHTTPHeader().headers.set('componentId', componentId)
			}
		);
	}

	update(body: SMTPConfig, componentId: string = ''): Observable<ResultObject<any>> {
		return this.http.put<ResultObject<any>>(
			`${AppConfig.getAPIEndpoint()}${this.ROUTE}/${this.tokenStorage.getUserInfo().idUsuario}`,
			body,
			{
				headers: AppConfig.getHTTPHeader().headers.set('componentId', componentId)
			}
		);
	}

	delete(_id: string, componentId: string = ''): Observable<ResultObject<any>> {
		return this.http.delete<ResultObject<any>>(
			`${AppConfig.getAPIEndpoint()}${this.ROUTE}/${this.tokenStorage.getUserInfo().idUsuario}/${_id}`,
			{
				headers: AppConfig.getHTTPHeader().headers.set('componentId', componentId)
			}
		);
	}


	removeAssociation(_id: string, idEmpresa: string, idLoja: string, componentId: string = '') {
		return this.http.post<ResultObject<any>>(
			AppConfig.getAPIEndpoint() + '/smtp/remover/associacao/' + this.tokenStorage.getUserInfo().idUsuario,
			{
				_id: _id,
				idEmpresa: idEmpresa,
				idLoja: idLoja
			},
			{
				headers: AppConfig.getHTTPHeader().headers.set('componentId', componentId)
			}
		);
	}

	associate(_id: string, idEmpresa: string, idLoja: string, componentId: string = '') {
		return this.http.post<ResultObject<any>>(
			AppConfig.getAPIEndpoint() + '/smtp/associacao/' + this.tokenStorage.getUserInfo().idUsuario,
			{
				_id: _id,
				idEmpresa: idEmpresa,
				idLoja: idLoja
			},
			{ headers: AppConfig.getHTTPHeader().headers.set('componentId', componentId) }
		);
	}


}
