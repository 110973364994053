import {SelectValue} from '../interface/SelectValue';

export enum TipoVendaAntecipada {
	CREDITO = <any>'credito',
	DEBITO = <any>'debito'
}

export const TipoVendaAntecipadaSelectValues: SelectValue<TipoVendaAntecipada>[] = [
	{ value: TipoVendaAntecipada.CREDITO, text: 'Crédito' },
	{ value: TipoVendaAntecipada.DEBITO, text: 'Débito' }
];
