
import {Injectable} from '@angular/core';
import {EstabelecimentoFunctions} from '../../models/interface/EstabelecimentoFunctions';
import {ServicePattern} from '../../models/interface/ServicePattern';
import {HttpClient} from '@angular/common/http';
import {TokenStorage} from '../../auth/token-storage.service';
import {Observable} from 'rxjs';
import {AppConfig} from '../../../config/appConfig';
import { PedidoConvenio } from '../../models/api/PedidoConvenio';
import { ResultObject } from '../../models/api/ResultObject';
import { Participante } from '../../models/api/Participante';
import { ProdutoConvenio } from '../../models/api/ProdutoConvenio';
import moment from 'moment';
import { TipoPagamento } from '../../models/enum/TipoPagamento';

@Injectable()
export class PedidosConvenioService extends EstabelecimentoFunctions implements ServicePattern<PedidoConvenio> {

	constructor(private http: HttpClient, private tokenStorage: TokenStorage ) {
		super();
	}

	getAll(
		est: string,
		convenio: string,
		page: number,
		per_page: number,
		componentId = '',
		numeroPedido: string = null,
		status: number = null,
		dataInicio: Date = null,
		dataFim: Date = null,
		vencimentoInicio: Date = null,
		vencimentoFim: Date = null,
		produto: ProdutoConvenio = null,
		participante: Participante = null
	): Observable<any> {
		return this.http.get<any>(
			AppConfig.getAPIEndpoint() + '/pedidosConvenio/' + this.tokenStorage.getUserInfo().idUsuario,
			{
				headers: AppConfig.getHTTPHeader().headers.set('componentId', componentId),
				params: {
					est,
					page: String(page),
					per_page: String(per_page),
					...(convenio ? { convenio } : {}),
					...(numeroPedido ? { numeroPedido } : {}),
					...(status ? { status: String(status) } : {}),
					...(dataInicio ? { dataInicio: moment(dataInicio).toISOString() } : {}),
					...(dataFim ? { dataFim: moment(dataFim).toISOString() } : {}),
					...(vencimentoInicio ? { vencimentoInicio: moment(vencimentoInicio).toISOString() } : {}),
					...(vencimentoFim ? { vencimentoFim: moment(vencimentoFim).toISOString() } : {}),
					...(produto ? { produto: produto._id } : {}),
					...(participante ? { participante: participante._id } : {}),
				}
			}
		);
	}

	baixa(
		est: string,
		convenio: string,
		componentId = '',
		numeroPedido: string = null,
		status: number = null,
		dataInicio: Date = null,
		dataFim: Date = null,
		vencimentoInicio: Date = null,
		vencimentoFim: Date = null,
		produto: ProdutoConvenio = null,
		participante: Participante = null,
		tipoPagamento: TipoPagamento,
		dataPagamento: Date
	): Observable<any> {
		return this.http.put<any>(
			AppConfig.getAPIEndpoint() + '/pedidosConvenio/baixa/' + this.tokenStorage.getUserInfo().idUsuario,
			{
				est,
				tipoPagamento,
				dataPagamento,
				...(convenio ? { convenio } : {}),
				...(numeroPedido ? { numeroPedido } : {}),
				...(status ? { status } : {}),
				...(dataInicio ? { dataInicio: moment(dataInicio).toISOString() } : {}),
				...(dataFim ? { dataFim: moment(dataFim).toISOString() } : {}),
				...(vencimentoInicio ? { vencimentoInicio: moment(vencimentoInicio).toISOString() } : {}),
				...(vencimentoFim ? { vencimentoFim: moment(vencimentoFim).toISOString() } : {}),
				...(produto ? { produto: produto._id } : {}),
				...(participante ? { participante: participante._id } : {}),
			},
			{
				headers: AppConfig.getHTTPHeader().headers.set('componentId', componentId)
			}
		);
	}

	update(body: PedidoConvenio, componentId: string = ''): Observable<ResultObject<any>> {
		return this.http.put<ResultObject<any>>(
			AppConfig.getAPIEndpoint() + '/pedidosConvenio/' + this.tokenStorage.getUserInfo().idUsuario,
			body,
			{
				headers: AppConfig.getHTTPHeader().headers.set('componentId', componentId)
			}
		);
	}

}