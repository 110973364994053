import {
	Component,
	OnInit,
	Output,
	Input,
	ViewChild,
	OnDestroy,
	ChangeDetectionStrategy,
	ChangeDetectorRef,
	HostBinding
} from '@angular/core';
import { AuthenticationService } from '../../../../core/auth/authentication.service';
import { Router } from '@angular/router';
import { Subject } from 'rxjs';
import { AuthNoticeService } from '../../../../core/auth/auth-notice.service';
import { NgForm } from '@angular/forms';
import * as objectPath from 'object-path';
import { TranslateService } from '@ngx-translate/core';
import { SpinnerButtonOptions } from '../../../partials/content/general/spinner-button/button-options.interface';
import {TokenStorage} from '../../../../core/auth/token-storage.service';
import {fadeIn, fadeInOut} from '../../../animations/fadeInOut';
import {Broadcaster, BroadcasterEvents, BroadcastEvent} from '../../../../core/models/broadcaster';
import {Permissoes} from '../../../../core/models/api/Permissoes';
import { bus } from '../../../../core/models/interface/EventEmitter';

declare const _alert: any;

@Component({
	selector: 'm-login',
	templateUrl: './login.component.html',
	styleUrls: ['./login.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush,
	animations: [fadeIn, fadeInOut],
})
export class LoginComponent implements OnInit, OnDestroy {

	public static readonly ROUTE_ID = 'callcenter:login';
	public static readonly VISIBLE_ONLY_TO_MANAGER: boolean = false;

	public static ROOT_ROUTE_ID;
	public static getRouteById;

	public model: any = { email: '', senha: '' };
	@HostBinding('class') classes: string = 'm-login__signin';
	@Output() actionChange = new Subject<string>();
	public loading = false;

	@Input() action: string;

	@ViewChild('f') f: NgForm;
	errors: any = [];

	spinner: SpinnerButtonOptions = {
		active: false,
		spinnerSize: 18,
		raised: true,
		buttonColor: 'primary',
		spinnerColor: 'accent',
		fullWidth: false
	};

	constructor(
		private authService: AuthenticationService,
		private router: Router,
		public authNoticeService: AuthNoticeService,
		private translate: TranslateService,
		private cdr: ChangeDetectorRef,
		private tokenStorage: TokenStorage,
		private _broadcaster: Broadcaster
	) {
		window['__teste__'] = () => {
			this.model.email = 'admin@vendergas.com.br';
			this.model.senha = 'vendergas2019';
		}
	}

	submit() {
		this.spinner.active = true;
		if (this.validate(this.f)) {
			this.authService.login(this.model).subscribe(response => {
				if(response.ESTABELECIMENTO_ID) {
					bus.emit('CARREGAR_FATURAS', response.ESTABELECIMENTO_ID);
					this.authNoticeService.setNotice(response.error, 'error');
					this.spinner.active = false;
					this.cdr.detectChanges();
				}
				else {
					setTimeout(() => {
						if (response.success) {
							localStorage.ambiente = response.ambiente;
							if (this.redirecionarBaseadoNaPermissao(response)) {
								this._broadcaster.broadcast$(BroadcasterEvents.ON_LOGIN, { user: response.user, token: response.token });
							} else {
								_alert(
									'Você não tem nenhuma permissão de acesso!',
									'Contate seu administrador e tente novamente'
								);
							}
						} else {
							this.authNoticeService.setNotice(response.message, 'error');
						}

						this.spinner.active = false;
						this.cdr.detectChanges();
					}, 500);
				}
			}, err => {
				//this.authNoticeService.setNotice(this.translate.instant('AUTH.VALIDATION.INVALID_LOGIN'), 'error');
				this.authNoticeService.setNotice(err.error.message, 'error');
				this.spinner.active = false;
				this.cdr.detectChanges();
			});
		}
	}

	/**
	 * Verifica se o usuário tem permissão para acesso da página inicial, caso não tenha
	 * vai para a próxima página na permissão.
	 */
	redirecionarBaseadoNaPermissao(response): boolean {
		this.tokenStorage.setAccessToken(response.token).setUserInfo(response.user);
		this.router.navigate(['/']);
		return true;
	}

	ngOnInit(): void {
		// demo message to show
		if (!this.authNoticeService.onNoticeChanged$.getValue()) {
			/*
			const initialNotice = `Use account
			<strong>admin@demo.com</strong> and password
			<strong>demo</strong> to continue.`;
			this.authNoticeService.setNotice(initialNotice, 'success');
			*/
			this.authNoticeService.setNotice(null);
		}
	}

	ngOnDestroy(): void {
		this.authNoticeService.setNotice(null);
	}

	validate(f: NgForm) {
		if (f.form.status === 'VALID') {
			return true;
		}

		this.errors = [];
		if (objectPath.get(f, 'form.controls.email.errors.email')) {
			this.errors.push(this.translate.instant('AUTH.VALIDATION.INVALID', {name: this.translate.instant('AUTH.INPUT.EMAIL')}));
		}
		if (objectPath.get(f, 'form.controls.email.errors.required')) {
			this.errors.push(this.translate.instant('AUTH.VALIDATION.REQUIRED', {name: this.translate.instant('AUTH.INPUT.EMAIL')}));
		}

		if (objectPath.get(f, 'form.controls.password.errors.required')) {
			this.errors.push(this.translate.instant('AUTH.VALIDATION.INVALID', {name: this.translate.instant('AUTH.INPUT.PASSWORD')}));
		}
		if (objectPath.get(f, 'form.controls.password.errors.minlength')) {
			this.errors.push(this.translate.instant('AUTH.VALIDATION.MIN_LENGTH', {name: this.translate.instant('AUTH.INPUT.PASSWORD')}));
		}

		if (this.errors.length > 0) {
			this.authNoticeService.setNotice(this.errors.join('<br/>'), 'error');
			this.spinner.active = false;
		}

		return false;
	}

	forgotPasswordPage(event: Event) {
		this.action = 'forgot-password';
		this.actionChange.next(this.action);
	}

	register(event: Event) {
		this.action = 'register';
		this.actionChange.next(this.action);
	}
}
