import {EstabelecimentoFunctions} from '../../models/interface/EstabelecimentoFunctions';
import {ServicePattern} from '../../models/interface/ServicePattern';
import {HttpClient} from '@angular/common/http';
import {TokenStorage} from '../../auth/token-storage.service';
import {Observable} from 'rxjs';
import {AppConfig} from '../../../config/appConfig';
import {DateFilter} from '../../models/interface/DateFilter';
import {ResultObject} from '../../models/api/ResultObject';
import {Injectable} from '@angular/core';
import {Estabelecimento} from '../../models/template/Estabelecimento';
import {DespesaVeiculo} from '../../models/api/DespesaVeiculo';
import {Entregador} from '../../models/api/Entregador';
import {Fornecedor} from '../../models/api/Fornecedor';
import {Veiculo} from '../../models/api/Veiculo';
import { Parcela } from '../../models/api/Parcela';
import { StatusConta } from '../../models/enum/StatusConta';

interface ListResult {
	result: DespesaVeiculo[];
	resultTipoDespesa: string[];
	resultEntregador: Entregador[];
	resultFornecedor: string[];
	resultVeiculo: Veiculo[];
}

@Injectable()
export class DespesaVeiculoService extends EstabelecimentoFunctions implements ServicePattern<DespesaVeiculo> {

	private readonly ROUTE = '/veiculos/despesa';

	constructor(private http: HttpClient, private tokenStorage: TokenStorage) {
		super();
	}

	getAll(
		dateFilter: DateFilter = { campo: null, dataInicio: null, dataFim: null },
		page: number = null,
		perPage: number = null,
		est: Estabelecimento = new Estabelecimento(null, null, null),
		tipoDespesa: string = null,
		entregador: Entregador = null,
		_fornecedor: string = null,
		veiculo: Veiculo = null,
		componentId: string = '',
		status: StatusConta,

	
	): Observable<ListResult> {
		let idLoja = est.loja != null ? est.loja.idLoja || est.loja._id : null;
		let idEmpresa = est.empresa != null ? est.empresa.idEmpresa || est.empresa._id : null;
		let idEntregador = entregador != null ? entregador._id : null;
		let fornecedor = _fornecedor != null ? _fornecedor : null;
		let idVeiculo = veiculo != null ? veiculo._id : null;
		return this.http.get<ListResult>(
			`${AppConfig.getAPIEndpoint()}${this.ROUTE}/list/${this.tokenStorage.getUserInfo().idUsuario}`,
			{
				headers: AppConfig.getHTTPHeader().headers.set('componentId', componentId),
				params: {
					...this.getDateFilterWithFieldQueryParam(dateFilter.campo, dateFilter.dataInicio, dateFilter.dataFim),
					...(page || page == 0 ? { page: page + 1 } : {}),
					...(perPage ? { per_page: perPage } : {}),
					...(idLoja ? { loja: idLoja } : {}),
					...(idEmpresa ? { empresa: idEmpresa } : {}),
					...(tipoDespesa ? { tipo: tipoDespesa } : {}),
					...(idEntregador ? { entregador: idEntregador } : {}),
					...(fornecedor ? { fornecedor: fornecedor } : {}),
					...(idVeiculo ? { veiculo: idVeiculo } : {})
				}
			}
		);
	}

	save(body: DespesaVeiculo, componentId: string = '', parcelas?: Parcela[]): Observable<DespesaVeiculo> {
		return this.http.post<DespesaVeiculo>(
			`${AppConfig.getAPIEndpoint()}${this.ROUTE}/${this.tokenStorage.getUserInfo().idUsuario}`,
			{
				...body,
				...(!!parcelas && parcelas.length ? { parcelas } : [])
			},
			{ headers: AppConfig.getHTTPHeader().headers.set('componentId', componentId) }
		);
	}

	delete(_id: number, componentId: string = ''): Observable<ResultObject<any>> {
		return this.http.delete<ResultObject<any>>(
			`${AppConfig.getAPIEndpoint()}${this.ROUTE}/${this.tokenStorage.getUserInfo().idUsuario}/${_id}`,
			{ headers: AppConfig.getHTTPHeader().headers.set('componentId', componentId) }
		);
	}

	update(body: DespesaVeiculo, componentId: string = ''): Observable<ResultObject<any>> {
		return this.http.put<ResultObject<any>>(
			`${AppConfig.getAPIEndpoint()}${this.ROUTE}/${this.tokenStorage.getUserInfo().idUsuario}`,
			body,
			{ headers: AppConfig.getHTTPHeader().headers.set('componentId', componentId) }
		);
	}

}
