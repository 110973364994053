import {Injectable} from '@angular/core';
import {EstabelecimentoFunctions} from '../../models/interface/EstabelecimentoFunctions';
import {ServicePattern} from '../../models/interface/ServicePattern';
import {HttpClient} from '@angular/common/http';
import {TokenStorage} from '../../auth/token-storage.service';
import {Observable} from 'rxjs';
import {AppConfig} from '../../../config/appConfig';
import {ResultObject} from '../../models/api/ResultObject';
import {ValorNegociado} from '../../models/api/ValorNegociado';

@Injectable()
export class ValorNegociadoService extends EstabelecimentoFunctions implements ServicePattern<ValorNegociado> {

	readonly ROUTE = '/valoresNegociados';

	constructor(private http: HttpClient, private tokenStorage: TokenStorage) {
		super();
	}

	getAll(componentId: string = ''): Observable<ValorNegociado[]> {
		return this.http.get<ValorNegociado[]>(
			AppConfig.getAPIEndpoint() + this.ROUTE + '/list/' + this.tokenStorage.getUserInfo().idUsuario,
			{
				headers: AppConfig.getHTTPHeader().headers.set('componentId', componentId),
				params: {
					...this.getEstabelecimentoQueryParam()
				}
			}
		);
	}

	get(idConta: number): Observable<ValorNegociado> {
		// TODO
		return null;
	}

	save(body: ValorNegociado, componentId: string = ''): Observable<ResultObject<any>> {
		return this.http.post<ResultObject<any>>(
			AppConfig.getAPIEndpoint() + this.ROUTE + '/' + this.tokenStorage.getUserInfo().idUsuario,
			body,
			{
				headers: AppConfig.getHTTPHeader().headers.set('componentId', componentId)
			}
		);
	}

	update(body: ValorNegociado, componentId: string = ''): Observable<ResultObject<any>> {
		return this.http.put<ResultObject<any>>(
			AppConfig.getAPIEndpoint() + this.ROUTE + '/' + this.tokenStorage.getUserInfo().idUsuario,
			body,
			{
				headers: AppConfig.getHTTPHeader().headers.set('componentId', componentId)
			}
		);
	}

	delete(idValorNegociado: number, componentId: string = ''): Observable<ResultObject<any>> {
		return this.http.delete<ResultObject<any>>(
			AppConfig.getAPIEndpoint() + this.ROUTE + '/' + this.tokenStorage.getUserInfo().idUsuario + '/' + idValorNegociado,
			{
				headers: AppConfig.getHTTPHeader().headers.set('componentId', componentId)
			}
		);
	}

	getEntriesCount(componentId: string = ''): Observable<{ entries: number }> {
		return this.http.get<{ entries: number }>(
			AppConfig.getAPIEndpoint() + this.ROUTE + '/list/' + this.tokenStorage.getUserInfo().idUsuario,
			{
				headers: AppConfig.getHTTPHeader().headers.set('componentId', componentId),
				params: {
					...this.getEstabelecimentoQueryParam(),
					entriesOnly: true
				}
			}
		);
	}

}
