import { ResultObject } from '../api/ResultObject';
import { Router } from '@angular/router';
import { PedidoStatus } from '../enum/PedidoStatus';
import { Endereco } from '../api/Endereco';
import { $getEstabelecimentoOnCache, EstabelecimentoFunctions } from './EstabelecimentoFunctions';
import { StatusConta } from '../enum/StatusConta';
import { TipoPagamento } from '../enum/TipoPagamento';
import { TipoEstoque } from '../enum/TipoEstoque';
import { Empresa } from '../api/Empresa';
import { Loja } from '../api/Loja';
import { TipoVeiculo } from '../enum/TipoVeiculo';
import { SituacaoVeiculo } from '../enum/SituacaoVeiculo';
import moment from 'moment';
import axios from 'axios';
import { Permissoes } from '../../../core/models/api/Permissoes';
import * as XLSX from 'xlsx';
import {
	$renderPDFInDocument,
	DATE_FILTER_TODAY_CORRECTION,
	formatDate,
	getCustomDayRoom,
	getRandomLetter,
	getWeekNumber,
	showSnackBar,
	TZ_CORRECTION,
	UtilsService
} from '../../services/utils.service';
import { VendaStatus } from '../enum/VendaStatus';
import { SummaryType } from '../enum/SummaryType';
import { TipoMovimentacao, TipoMovimentacaoEstoque } from '../enum/TipoMovimentacaoEstoque';

import { Produto } from '../api/Produto';
import { IndicacaoCompra } from '../enum/IndicacaoCompra';
import { FormaPagamentoNota, FormaPagamentoNotaVista, PrazoPagamento } from '../enum/FormaPagamentoNota';
import { FormaPagamento, Pagamento, Pedido } from '../api/Pedido';
import { TipoCliente } from '../enum/TipoCliente';
import { ContaTipoGeradoPor } from '../enum/ContaTipoGeradoPor';
import { TipoCartao } from '../enum/TipoCartao';
import { TipoEstoqueNegociado } from '../enum/TipoEstoqueNegociado';
import { $getUserInfo, TokenStorage } from '../../auth/token-storage.service';
import { TipoNota } from '../enum/TipoNota';
import { SituacaoComodato } from '../enum/SituacaoComodato';
import { LancarPedidoRapidoTipo } from '../enum/LancarPedidoRapidoTipo';
import { Estabelecimento } from '../template/Estabelecimento';
import { StatusNotaAvariada } from '../enum/StatusNotaAvariada';
import { Entregador } from '../api/Entregador';
import { Venda } from '../api/Venda';
import { TipoNotaFiscal } from '../enum/notaFiscal/TipoNotaFiscal';
import { TipoNF } from '../enum/notaFiscal/TipoNF';
import { Cliente, FiadoStatus } from '../api/Cliente';
import { AppConfig } from '../../../config/appConfig';
import { UF } from '../api/RegraTributacao';
import { PedidosService } from '../../services/api/pedidos.service';
import { SMTPConfigService } from '../../services/api/smtp-config.service';
import { SMTPConfig } from '../api/SMTPConfig';
import { Broadcaster, BroadcasterEvents } from '../broadcaster';
import { PerfilCliente, PerfilClienteOptions } from '../enum/PerfilCliente';
import { Convenio } from '../api/Convenio';
import { Usuario } from '../api/Usuario';
import printJS from 'print-js';
import { Snackbar } from '../enum/Snackbar';
import { MatSnackBar } from '@angular/material';

declare const jsPDF: any;
declare const _alert: any;
declare const _confirm: any;
declare const _prompt: any;
declare const _dialog: any;
declare const estadosPoligonos: { state: { code: string, name: string, region: string }, borders: { lat: number, lng: number }[][] }[];

const LISTA_DE_ESTADOS = [
	{ sigla: 'AC', cod: '12', nome: 'Acre' },
	{ sigla: 'AL', cod: '27', nome: 'Alagoas' },
	{ sigla: 'AP', cod: '16', nome: 'Amapá' },
	{ sigla: 'AM', cod: '13', nome: 'Amazonas' },
	{ sigla: 'BA', cod: '29', nome: 'Bahia' },
	{ sigla: 'CE', cod: '23', nome: 'Ceará' },
	{ sigla: 'DF', cod: '53', nome: 'Distrito Federal' },
	{ sigla: 'ES', cod: '32', nome: 'Espírito Santo' },
	{ sigla: 'GO', cod: '52', nome: 'Goiás' },
	{ sigla: 'MA', cod: '21', nome: 'Maranhão' },
	{ sigla: 'MT', cod: '51', nome: 'Mato Grosso' },
	{ sigla: 'MS', cod: '50', nome: 'Mato Grosso do Sul' },
	{ sigla: 'MG', cod: '31', nome: 'Minas Gerais' },
	{ sigla: 'PA', cod: '15', nome: 'Pará' },
	{ sigla: 'PB', cod: '25', nome: 'Paraíba' },
	{ sigla: 'PR', cod: '41', nome: 'Paraná' },
	{ sigla: 'PE', cod: '26', nome: 'Pernambuco' },
	{ sigla: 'PI', cod: '22', nome: 'Piauí' },
	{ sigla: 'RJ', cod: '33', nome: 'Rio de Janeiro' },
	{ sigla: 'RN', cod: '24', nome: 'Rio Grande do Norte' },
	{ sigla: 'RS', cod: '43', nome: 'Rio Grande do Sul' },
	{ sigla: 'RO', cod: '11', nome: 'Rondônia' },
	{ sigla: 'RR', cod: '14', nome: 'Roraima' },
	{ sigla: 'SC', cod: '42', nome: 'Santa Catarina' },
	{ sigla: 'SP', cod: '35', nome: 'São Paulo' },
	{ sigla: 'SE', cod: '28', nome: 'Sergipe' },
	{ sigla: 'TO', cod: '17', nome: 'Tocantins' }
];

export function getTipoNotaFiscal(tipo: TipoNotaFiscal): string {
	if (tipo === TipoNotaFiscal.SAIDA) return 'Saída';
	if (tipo === TipoNotaFiscal.ENTRADA) return 'Entrada';
	return 'Desconhecido';
}

/**
 * Funções comuns entre os componentes
 */
export class CallcenterFunctions extends EstabelecimentoFunctions {

	private _r: Router;
	private readonly _routeUrl: string;

	document = document;

	estadosPoligonos = estadosPoligonos;

	result: ResultObject<any> = new ResultObject();

	_dateObject = Date;

	readonly IGNORE_PERMISSION = 'ignore-permission';

	_load = {
		changeDelay: 100,
		isLoading: false,
		hide: function (callback = null, customId: string = null) {
			setTimeout(() => {
				if (customId != null) this[customId] = false;
				else this.isLoading = false;
				if (callback) callback();
			}, this.changeDelay);
		},
		show: function (callback = null, customId: string = null) {
			setTimeout(() => {
				if (customId != null) this[customId] = true;
				else this.isLoading = true;
				if (callback) callback();
			}, this.changeDelay);
		},
		toggle: function (callback = null, customId: string = null) {
			setTimeout(() => {
				if (customId != null) this[customId] = !this[customId];
				else this.isLoading = !this.isLoading;
				if (callback) callback();
			}, this.changeDelay);
		},
	};

	today: number = Date.now();

	readonly isOnDevelopment = AppConfig.isOnDevelopment;

	$listaEstados: { sigla: string, cod: string, nome?: string }[] = JSON.parse(JSON.stringify(LISTA_DE_ESTADOS));

	/**
	 * NbDialog
	 */
	_alert: (message?: string, subMessage?: string, buttonTex?: string, bgTransp?: boolean, color?: string, font?: string) => Promise<any> = _alert;
	_confirm: (message?: string, confirmTex?: string, confirmCancelText?: string, subMessage?: string, color?: string, font?: string) => Promise<boolean> = _confirm;
	_prompt: (
		message?: string,
		defaultText?: string,
		type?: 'button' | 'checkbox' | 'color' | 'date' | 'datetime-local' | 'email' | 'file' | 'hidden' | 'image' | 'month' | 'number' | 'password' | 'radio' | 'range' | 'reset' | 'search' | 'submit' | 'tel' | 'text' | 'time' | 'url' | 'week'
	) => Promise<string | null> = _prompt;

	renderPDFInDocument: (data) => void = $renderPDFInDocument;

	constructor(_r: Router = null) {
		super();
		this._r = _r;
		this._routeUrl = (_r || <any>{}).url;
	}

	/**
	 * Common Select Options
	 */

	public readonly BANDEIRAS_OPTIONS: any[] = [
		{ name: 'Sorocred', value: 'Sorocred' },
		{ name: 'Maestro', value: 'Maestro' },
		{ name: 'Cielo', value: 'Cielo' },
		{ name: 'Rede', value: 'Rede' },
		{ name: 'Getnet', value: 'Getnet' },
		{ name: 'Stone', value: 'Stone' },
		{ name: 'Stelo', value: 'Stelo' },
		{ name: 'Visa', value: 'Visa' },
		{ name: 'Visa Electron', value: 'Visa Electron' },
		{ name: 'Mastercard', value: 'Mastercard' },
		{ name: 'Elo', value: 'Elo' },
		{ name: 'American Express', value: 'American Express' },
		{ name: 'Diners Club', value: 'Diners Club ' },
		{ name: 'BANESCARD', value: 'BANESCARD' },
		{ name: 'CABAL', value: 'CABAL' },
		{ name: 'HIPER', value: 'HIPER' },
		{ name: 'CUP', value: 'CUP' },
		{ name: 'JCB', value: 'JCB' },
		{ name: 'CREDZ', value: 'CREDZ' },
		{ name: 'MAIS', value: 'MAIS' },

	];

	public readonly TIPO_CLIENTE_OPTIONS: any[] = [
		{ name: 'Pessoa Física', value: TipoCliente.PESSOA_FISICA },
		{ name: 'Pessoa Jurídica', value: TipoCliente.PESSOA_JURIDICA },
		{ name: 'Comércio', value: TipoCliente.COMERCIO },
		{ name: 'Produtor Rural', value: TipoCliente.PRODUTOR_RURAL }
	];

	public readonly TIPO_NOTA_OPTIONS: any[] = [
		{ name: 'Entrada', value: TipoNota.NOTA_ENTRADA },
		{ name: 'Saída', value: TipoNota.NOTA_SAIDA },
	];

	public readonly SITUACAO_COMODATO_OPTIONS: any[] = [
		{ name: 'Encerrado', value: SituacaoComodato.ENCERRADO },
		{ name: 'Pendente', value: SituacaoComodato.PENDENTE },
		{ name: 'Recolher', value: SituacaoComodato.RECOLHER },
	];

	public readonly TIPO_CARTAO_OPTIONS: any[] = [
		// {name: 'Cartão Crédito', value: TipoCartao.CREDITO},
		// {name: 'Cartão Débito', value: TipoCartao.DEBITO},
		{ name: 'Cartão', value: TipoCartao.CARTAO },
		{ name: 'Boleto', value: TipoCartao.BOLETO },
	];

	public readonly PAYMENT_OPTIONS: any[] = [
		{ name: 'Boleto', value: TipoPagamento.BOLETO },
		{ name: 'Cheque', value: TipoPagamento.CHEQUE },
		{ name: 'Cartão de Débito', value: TipoPagamento.CARTAO_DEBITO },
		{ name: 'Cartão de Crédito', value: TipoPagamento.CARTAO_CREDITO },
		{ name: 'Dinheiro', value: TipoPagamento.DINHEIRO },
		{ name: 'Vale-Gás', value: TipoPagamento.VALE_GAS },
		{ name: 'Transferência', value: TipoPagamento.TRANSFERENCIA },
		{ name: 'Requisição', value: TipoPagamento.REQUISICAO },
		{ name: 'Fiado', value: TipoPagamento.FIADO },
		{ name: 'Outros', value: TipoPagamento.OUTROS },
		{ name: 'Convênio', value: TipoPagamento.CONVENIO },
		{ name: 'Débito Automático', value: TipoPagamento.DEBITO_AUTOMATICO },
		{ name: 'Cheque Pré', value: TipoPagamento.CHEQUE_PRE },
		{ name: 'PIX', value: TipoPagamento.PIX },
		{ name: 'Vale Água', value: TipoPagamento.VALE_AGUA },
		{ name: 'PicPay', value: TipoPagamento.PICPAY }
	];

	public readonly PAYMENT_OPTIONS_WITHOUT_OTHERS: any[] = [
		{ name: 'Boleto', value: TipoPagamento.BOLETO },
		{ name: 'Cheque', value: TipoPagamento.CHEQUE },
		{ name: 'Cartão de Débito', value: TipoPagamento.CARTAO_DEBITO },
		{ name: 'Cartão de Crédito', value: TipoPagamento.CARTAO_CREDITO },
		{ name: 'Dinheiro', value: TipoPagamento.DINHEIRO },
		{ name: 'Vale-Gás', value: TipoPagamento.VALE_GAS },
		{ name: 'Venda Antecipada', value: TipoPagamento.VENDA_ANTECIPADA },
		{ name: 'Transferência', value: TipoPagamento.TRANSFERENCIA },
		{ name: 'Requisição', value: TipoPagamento.REQUISICAO },
		{ name: 'Fiado', value: TipoPagamento.FIADO },
		{ name: 'Convênio', value: TipoPagamento.CONVENIO },
		{ name: 'Débito Automático', value: TipoPagamento.DEBITO_AUTOMATICO },
		{ name: 'Cheque Pré', value: TipoPagamento.CHEQUE_PRE },
		{ name: 'PIX', value: TipoPagamento.PIX },
		{ name: 'Vale Água', value: TipoPagamento.VALE_AGUA },
		{ name: 'PicPay', value: TipoPagamento.PICPAY }
	];

	public readonly PAYMENT_OPTIONS_COMMOM: any[] = [
		{ name: 'Boleto', value: TipoPagamento.BOLETO },
		{ name: 'Cheque', value: TipoPagamento.CHEQUE },
		{ name: 'Cartão de Débito', value: TipoPagamento.CARTAO_DEBITO },
		{ name: 'Cartão de Crédito', value: TipoPagamento.CARTAO_CREDITO },
		{ name: 'Dinheiro', value: TipoPagamento.DINHEIRO },
		{ name: 'Vale-Gás', value: TipoPagamento.VALE_GAS },
		{ name: 'Transferência', value: TipoPagamento.TRANSFERENCIA },
		{ name: 'Requisição', value: TipoPagamento.REQUISICAO },
		{ name: 'Convênio', value: TipoPagamento.CONVENIO },
		{ name: 'Débito Automático', value: TipoPagamento.DEBITO_AUTOMATICO },
		{ name: 'Cheque Pré', value: TipoPagamento.CHEQUE_PRE },
		{ name: 'PIX', value: TipoPagamento.PIX },
		{ name: 'Vale Água', value: TipoPagamento.VALE_AGUA },
		{ name: 'PicPay', value: TipoPagamento.PICPAY }
	];

	public readonly BOOLEAN_SIM_NAO_OPTIONS: any[] = [
		{ name: 'Sim', value: true },
		{ name: 'Não', value: false },
	];

	public readonly ESTOQUE_OPTIONS: any[] = [
		{ name: 'Vazio', value: TipoEstoque.ESTOQUE_VAZIO },
		{ name: 'Cheio', value: TipoEstoque.ESTOQUE_CHEIO }
	];

	public readonly ESTOQUE_NEGOCIADO_OPTIONS: any[] = [
		{ name: 'Vazio', value: TipoEstoqueNegociado.ESTOQUE_VAZIO },
		{ name: 'Cheio', value: TipoEstoqueNegociado.ESTOQUE_CHEIO },
		{ name: 'Todos', value: TipoEstoqueNegociado.TODOS }
	];

	public readonly CONTA_STATUS_OPTIONS: any[] = [
		{ name: 'Aguardando Pagamento', value: StatusConta.AGUARDANDO_PAGAMENTO },
		{ name: 'Vencido', value: StatusConta.VENCIDO },
		{ name: 'Pago', value: StatusConta.PAGO },
		{ name: 'Cancelado', value: StatusConta.CANCELADO }
	];

	public readonly CONTA_STATUS_OPTIONS_2: any[] = [
		{ name: 'Aguardando Pagamento', value: StatusConta.AGUARDANDO_PAGAMENTO },
		{ name: 'Vencido', value: StatusConta.VENCIDO },
		{ name: 'Recebido', value: StatusConta.PAGO },
		{ name: 'Cancelado', value: StatusConta.CANCELADO },
		{ name: 'Devolvido', value: StatusConta.DEVOLVIDO, filtro: 'a_receber' }
	];

	public readonly CONTA_STATUS_OPTIONS_3: any[] = [
		{ name: 'Aguardando Pagamento', value: StatusConta.AGUARDANDO_PAGAMENTO },
		{ name: 'Vencido', value: StatusConta.VENCIDO },
		{ name: 'Recebido', value: StatusConta.PAGO },
		{ name: 'Cancelado', value: StatusConta.CANCELADO },
	];



	public readonly TIPO_VEICULO_OPTIONS: any[] = [
		{ name: 'Automóvel', value: TipoVeiculo.AUTOMOVEL },
		{ name: 'Caminhão', value: TipoVeiculo.CAMINHAO },
		{ name: 'Caminhonete', value: TipoVeiculo.CAMINHONETE },
		{ name: 'Camioneta', value: TipoVeiculo.CAMIONETA },
		{ name: 'Carreta', value: TipoVeiculo.CARRETA },
		{ name: 'Cavalo', value: TipoVeiculo.CAVALO },
		{ name: 'Chassi Plataforma', value: TipoVeiculo.CHASSI_PLATAFORMA },
		{ name: 'Motocicleta', value: TipoVeiculo.MOTOCICLETA },
		{ name: 'Motor Casa', value: TipoVeiculo.MOTOR_CASA },
		{ name: 'Quadriciclo', value: TipoVeiculo.QUADRICICLO },
		{ name: 'Reboque', value: TipoVeiculo.REBOQUE },
		{ name: 'Side Car', value: TipoVeiculo.SIDE_CAR },
		{ name: 'Triciclo', value: TipoVeiculo.TRICICLO },
		{ name: 'Utilitário', value: TipoVeiculo.UTILITARIO }
	];

	obterUltimaInteracao(row: Usuario | Empresa | Loja) {
		return row.ultimaInteracao ? `(${moment().diff(moment(row.ultimaInteracao), 'days')} dias)` : '';
	}

	public readonly SITUACAO_VEICULO_OPTIONS: any[] = [
		{ name: 'Ativo', value: SituacaoVeiculo.ATIVO },
		{ name: 'Cedido por Terceiros', value: SituacaoVeiculo.CEDIDO_TERCEIROS },
		{ name: 'Em Manutenção', value: SituacaoVeiculo.EM_MANUTENCAO },
		{ name: 'Furtado', value: SituacaoVeiculo.FURTADO },
		{ name: 'Inativo', value: SituacaoVeiculo.INATIVO },
		{ name: 'Sinistrado', value: SituacaoVeiculo.SINISTRADO }
	];

	public readonly TIPO_MOVIMENTACAO_ENTRADA: any[] = [
		{ name: 'Nota Fiscal', value: TipoMovimentacaoEstoque.NOTA_FISCAL_ENTRADA },
		{ name: 'Comodato', value: TipoMovimentacaoEstoque.COMODATO_ENTRADA },
		{ name: 'Entrada', value: TipoMovimentacaoEstoque.ENTRADA_SEM_NOTA },
		{ name: 'Devolução', value: TipoMovimentacaoEstoque.DEVOLUCAO },
		{ name: 'Empréstimo', value: TipoMovimentacaoEstoque.EMPRESTIMO_ENTRADA },
		{ name: 'Avarias', value: TipoMovimentacaoEstoque.CONTROLE_AVARIAS_ENTRADA }
	];

	public readonly TIPO_MOVIMENTACAO_SAIDAS: any[] = [
		{ name: 'Nota Fiscal', value: TipoMovimentacaoEstoque.NOTA_FISCAL_SAIDA },
		{ name: 'Comodato', value: TipoMovimentacaoEstoque.COMODATO_SAIDA },
		{ name: 'Avarias', value: TipoMovimentacaoEstoque.CONTROLE_AVARIAS },
		{ name: 'Saída', value: TipoMovimentacaoEstoque.SAIDAS_SEM_NOTA },
		{ name: 'Doação', value: TipoMovimentacaoEstoque.DOACAO },
		{ name: 'Transferência', value: TipoMovimentacaoEstoque.TRANSFERENCIA },
		{ name: 'Empréstimo', value: TipoMovimentacaoEstoque.EMPRESTIMO_SAIDA }
	];

	public readonly TIPO_MOVIMENTACAO: any[] = [
		{ name: 'Entrada', value: TipoMovimentacao.ENTRADA },
		{ name: 'Saídas', value: TipoMovimentacao.SAIDAS }
	];

	public readonly TIPO_MOVIMENTACAO_CONTA: any[] = [
		{ name: 'Pendente', value: 'pendente' },
		{ name: 'Liquidado', value: 'pago' },
		{ name: 'Cancelado', value: 'cancelado' },
	];

	public readonly STATUS_NOTA_AVARIADA: any[] = [
		{ name: 'Aprovado', value: StatusNotaAvariada.APROVADA },
		{ name: 'Reprovado', value: StatusNotaAvariada.REPROVADA }
	];

	public readonly FORMA_PAGAMENTO: any[] = [
		{ name: 'À prazo', value: FormaPagamentoNotaVista.A_PRAZO },
		{ name: 'À vista', value: FormaPagamentoNotaVista.A_VISTA }

	];

	public readonly FORMA_PAGAMENTO_CONTA: any[] = [
		{ name: 'À prazo', value: FormaPagamento.A_PRAZO },
		{ name: 'À vista', value: FormaPagamento.A_VISTA }
	];


	public readonly INDICACAO_COMPRA: any[] = [
		{ name: 'E-mail', value: IndicacaoCompra.EMAIL },
		{ name: 'Ima', value: IndicacaoCompra.IMA },
		{ name: 'Outdoor', value: IndicacaoCompra.OUTDOOR },
		{ name: 'Internet', value: IndicacaoCompra.INTERNET },
		{ name: 'Rede Social', value: IndicacaoCompra.REDE_SOCIAL },
		{ name: 'Celular', value: IndicacaoCompra.CELULAR },
		{ name: 'Telefone', value: IndicacaoCompra.TELEFONE },
		{ name: 'WhatsApp', value: IndicacaoCompra.WHATSAPP },
		{ name: 'Aplicativo', value: IndicacaoCompra.APLICATIVO },
		{ name: 'Portaria', value: IndicacaoCompra.PORTARIA },
		{ name: 'Teleatendimento', value: IndicacaoCompra.TELEATENDIMENTO },
		{ name: 'Plantão', value: IndicacaoCompra.PLANTAO },
		{ name: 'Venda à Porta', value: IndicacaoCompra.VENDA_A_PORTA },
		{ name: 'Sinalização', value: IndicacaoCompra.SINALIZACAO },
		{ name: 'Impresso', value: IndicacaoCompra.IMPRESSO },
		{ name: 'Cliente Fidelizado', value: IndicacaoCompra.CLIENTE_FIDELIZADO },
		{ name: 'Automática', value: IndicacaoCompra.AUTOMATICA },
		{ name: 'Agregado', value: IndicacaoCompra.AGREGADO },
		{ name: 'Comércio/Indústria', value: IndicacaoCompra.COMERCIO_INDUSTRIA },
		{ name: 'Ch App', value: IndicacaoCompra.CH_APP },
		{ name: 'PG App', value: IndicacaoCompra.PG_APP },
		{ name: 'UP App', value: IndicacaoCompra.UP_APP },
		{ name: 'Aplicativo QR CODE', value: IndicacaoCompra.QRCODEAPP },
		{ name: 'Rádio', value: IndicacaoCompra.RADIO }
	];

	public readonly PRAZO_PAGAMENTO: any[] = [
		{ name: '1x', value: PrazoPagamento.UMA },
		{ name: '2x', value: PrazoPagamento.DUAS },
		{ name: '3x', value: PrazoPagamento.TRES },
		{ name: '4x', value: PrazoPagamento.QUATRO },
		{ name: '5x', value: PrazoPagamento.CINCO },
		{ name: '6x', value: PrazoPagamento.SEIS },
		{ name: '7x', value: PrazoPagamento.SETE },
		{ name: '8x', value: PrazoPagamento.OITO },
		{ name: '9x', value: PrazoPagamento.NOVE },
		{ name: '10x', value: PrazoPagamento.DEZ },
		{ name: '11x', value: PrazoPagamento.ONZE },
		{ name: '12x', value: PrazoPagamento.DOZE }

	];

	public readonly FORMA_PAGAMENTO_TIPO: any[] = [
		{ name: 'Dinheiro', value: FormaPagamentoNota.DINHEIRO },
		{ name: 'Cartão de Crédito', value: FormaPagamentoNota.CARTAO_CREDITO },
		{ name: 'Cartão de Débito', value: FormaPagamentoNota.CARTAO_DEBITO },
		{ name: 'Cheque', value: FormaPagamentoNota.CHEQUE },
		{ name: 'Boleto', value: FormaPagamentoNota.BOLETO }
	];

	public readonly TIPO_ESTOQUE: any[] = [
		{ name: 'Estoque Cheio', value: TipoEstoque.ESTOQUE_CHEIO },
		{ name: 'Estoque Vazio', value: TipoEstoque.ESTOQUE_VAZIO }
	];

	_toFixed(n, d) {
		return (Math.round(n * 10 ** d) / 10 ** d).toFixed(d);
	}

	async exportXLS(name: string, template: string, data: any, print = false) {
		try {
			const { data: html } = await axios.post(`${AppConfig.getAPIEndpoint()}/apis/ejs`, {
				template,
				data
			});
			if (print) {
				printJS({
					type: 'raw-html',
					printable: html,
					documentTitle: name,
					modalMessage: 'Imprimindo documento',
					showModal: true
				})
			}
			else {
				const a = document.createElement('a');
				const base64 = btoa(unescape(encodeURIComponent(html)));
				const url = 'data:application/vnd.ms-excel;base64,' + base64;
				a.setAttribute('href', url);
				a.setAttribute('download', name);
				a.click();
			}
		}
		catch (err) {
			_alert('Erro ao renderizar template');
		}
	}

	getPosition(): Promise<{ lat: number, lng: number, accuracy: number }> {
		return new Promise((resolve, reject) => {
			if (navigator.geolocation) {
				navigator.geolocation.getCurrentPosition(function (position) {
					var pos = {
						lat: position.coords.latitude,
						lng: position.coords.longitude,
						accuracy: position.coords.accuracy
					};
					resolve(pos);
				}, function (error) {
					reject(error);
				});
			} else {
				reject(new Error('Geolocalização não habilitada'));
			}
		})
	}

	async getUFBounds() {
		try {
			const position = await this.getPosition();
			const state = estadosPoligonos.find(s => s.borders.some(points => google.maps.geometry.poly.containsLocation(new google.maps.LatLng(position.lat, position.lng), new google.maps.Polygon({ paths: points.map(point => new google.maps.LatLng(point.lat, point.lng)) }))));
			return new google.maps.LatLngBounds(
				new google.maps.LatLng(
					state.borders.map(points => points.sort((a, b) => a.lat > b.lat ? 1 : -1)[0]).sort((a, b) => a.lat > b.lat ? 1 : -1)[0].lat,
					state.borders.map(points => points.sort((a, b) => a.lng > b.lng ? 1 : -1)[0]).sort((a, b) => a.lng > b.lng ? 1 : -1)[0].lng
				),
				new google.maps.LatLng(
					state.borders.map(points => points.sort((a, b) => a.lat < b.lat ? 1 : -1)[0]).sort((a, b) => a.lat < b.lat ? 1 : -1)[0].lat,
					state.borders.map(points => points.sort((a, b) => a.lng < b.lng ? 1 : -1)[0]).sort((a, b) => a.lng < b.lng ? 1 : -1)[0].lng
				)
			)
		}
		catch (err) {
			return null;
		}
	}

	async getPlace(inputElement: HTMLInputElement, callback: (endereco: Endereco) => void) {
		/* const input: any = new google.maps.places.Autocomplete(
			inputElement,
			{
				componentRestrictions: { country: 'BR' },
				types: ['address']
			}
		);
		const bounds = await this.getUFBounds();
		if (bounds) {
			input.setBounds(bounds);
		}
		input.setFields(['address_components']);
		input.addListener('place_changed', function () {
			const place = input.getPlace();
			const components = place.address_components;
			const endereco: Endereco = new Endereco();
			endereco.cep = ((components.find(c => c.types.includes('postal_code')) || {}).long_name || '').replace(/[^\d]/g, '').toUpperCase();
			endereco.estado = ((components.find(c => c.types.includes('administrative_area_level_1')) || {}).long_name || '').toUpperCase();
			endereco.cidade = ((components.find(c => c.types.includes('administrative_area_level_2')) || {}).long_name || '').toUpperCase();
			endereco.bairro = ((components.find(c =>
				c.types.includes('sublocality_level_1') ||
				c.types.includes('administrative_area_level_4') ||
				c.types.includes('administrative_area_level_3')
			) || {}).long_name || '').toUpperCase();
			//endereco.endereco = inputElement.value.toUpperCase();
			endereco.endereco = [
				(components.find(c => c.types.includes('route')) || {}).long_name || null,
				(components.find(c => c.types.includes('street_number')) || {}).long_name || null,
				(components.find(c => c.types.includes('sublocality_level_2')) || {}).long_name || null,
				(components.find(c => c.types.includes('sublocality_level_3')) || {}).long_name || null,
				(components.find(c => c.types.includes('sublocality_level_4')) || {}).long_name || null,
				(components.find(c => c.types.includes('sublocality_level_5')) || {}).long_name || null
			].filter(i => !!i).join(', ').toUpperCase();
			callback(endereco);
		}); */
	}

	drawPageNumber(hookData, doc) {
		doc.setFontSize(10);
		doc.text(hookData.pageNumber.toString(), doc.internal.pageSize.width - 15, doc.internal.pageSize.height - 10);
	}

	formatPhone(phone, autoDDD = '61', includeNine = false) {
		if (!phone) return null;
		const onlyNumber = phone.trim().replace(/[^\d]/g, '');
		const clearZeros = onlyNumber.replace(/^0+/, '');
		if (onlyNumber.length < 8) return null;
		const ddd = clearZeros.length > 9 ? clearZeros.substr(0, 2) : (autoDDD != null ? autoDDD : '');
		const eightDigits = clearZeros.replace(/^\d+(\d{8})$/, '$1');
		const withNine = (parseInt(eightDigits[0]) >= 6 || includeNine) ? '9' + eightDigits : eightDigits;
		return ddd + withNine;
	}

	small(text: string, size: number) {
		if (text.length > size) {
			return text.substr(0, size - 3) + '...';
		}
		return text;
	}

	formatDate: (d: any, showHour: boolean, dateSeparation?: string) => string = formatDate;

	/**
	 * Exibe o resultado do request para o usuário
	 *
	 * @param _res - Objeto resposta do request
	 * @param shown - Exibe ou esconde a informação para o usuário
	 */
	setResultObject(_res: ResultObject<any>, shown: boolean = true) {
		this.result.success = _res.success;
		this.result.title = _res.title;
		this.result.message = _res.message;
		this.result.shown = shown;
	}

	resetValidators() { this._r.navigateByUrl('/loading', { skipLocationChange: true }).then(() => this._r.navigate([this._routeUrl])); }

	formatProdutosResult(produtos: Produto[], produtoQuantidade, showValorUnitario: boolean = false): string {
		let _result = '';
		for (const c in produtos) {
			const produto = produtos[c];
			_result += (_result != '' ? ',\n ' : '') + produtoQuantidade[produto.idProduto] + 'x ' + produto.nome + (showValorUnitario == true ? '(R$' + produto.valorCheio + ')' : '');
		}
		return _result;
	}

	ternary(condition, ifValue, elseValue) {
		return condition ? ifValue : elseValue;
	}

	sendWhatsapp(telefone: string, key: string = 'message') {
		_dialog('Enviar mensagem', null, [
			{
				component: 'inputComponent',
				name: 'msg',
				args: [undefined, undefined, true, undefined, undefined, 10],
				default: localStorage.getItem(`wa-${key}`)
			}
		], { label: 'ENVIAR' }, undefined, 'https://i.pinimg.com/originals/67/8b/20/678b20b7e76e3c81baa4f8c6ee1bc656.png').then(values => {
			if (values) {
				window.open(`https://web.whatsapp.com/send?phone=55${this.formatPhone(telefone)}&text=${encodeURI(values.msg)}`, '_blank');
				localStorage.setItem(`wa-${key}`, values.msg);
			}
		})
	}

	getProdutoEEstoqueComQuantidade(
		produtos: Produto[],
		estoquesToUpdate: any[],
		produtoQuantidade,
		showValorUnitario: boolean = false,
		resultSeparator: string = ', ',
		formatPre: boolean = false,
		showTotal: boolean = false,
		unitaryValueFromPedido: boolean = false,
		showIdProduto: boolean = false
	): string {

		let _result = '';

		let totalQtd = 0;

		for (const est of (estoquesToUpdate || [])) {

			for (const c in produtos) {
				const produto = produtos[c];

				if (produto.idProduto == est.idProduto) {

					totalQtd += (est || {}).quantidade;

					let idPedido = formatPre ? `<b style="font-weight: 800;">${(est || {}).idProduto} - </b>` : (est || {}).idProduto;

					let quantidade = formatPre ? `<b>${(est || {}).quantidade}</b>` : (est || {}).quantidade;
					let produtoNome = formatPre ? `<b>${produto.nome}</b>` : produto.nome;
					let tipoEstoque = formatPre ? `<i>${this.getTipoEstoque((est.estoqueSelecionado || {}).tipoEstoque)}</i>` : this.getTipoEstoque((est.estoqueSelecionado || {}).tipoEstoque);

					let valorUnitario;

					if (unitaryValueFromPedido) {
						valorUnitario = (est.valorNegociado || est.valorCompleto || 0);
					} else {
						valorUnitario = this.getValorEstoque((est.estoqueSelecionado || {}).tipoEstoque, produto);
					}

					_result += (_result != '' ? resultSeparator : '')
						+ (showIdProduto ? idPedido : '')
						+ `x${quantidade} ${produtoNome}(${tipoEstoque})`
						+ (showValorUnitario == true ? ' (R$' + Number(valorUnitario).toFixed(2) + ')' : '');
					break;
				}
			}
		}

		if (formatPre && showTotal) {
			_result += `<hr/>x<b>${totalQtd} TOTAL</b>`;
		}

		return _result;
	}

	/**
	 * Formata o status do pedido em um icone do Material Icons
	 * @param status
	 */
	getStatusIcon(status: PedidoStatus): string {
		//if (status == PedidoStatus.PEDIDO_DESCONHECIDO) { return 'sentiment_dissatisfied'; }
		if (status == PedidoStatus.PEDIDO_AGENDADO) { return 'schedule'; }
		if (status == PedidoStatus.PEDIDO_NOVO) { return 'schedule'; }
		//if (status == PedidoStatus.PEDIDO_PRONTO) { return 'add_shopping_cart'; }
		//if (status == PedidoStatus.PEDIDO_ACEITO) { return 'navigation'; }
		//if (status == PedidoStatus.PEDIDO_NAO_ACEITO) { return 'sentiment_dissatisfied'; }
		if (status == PedidoStatus.PEDIDO_EM_TRANSITO) { return 'near_me'; }
		if (status == PedidoStatus.PEDIDO_ENTREGUE) { return 'check'; }
		//if (status == PedidoStatus.PEDIDO_NAO_ENTREGUE) { return 'close'; }
		if (status == PedidoStatus.PEDIDO_CANCELADO) { return 'close'; }
		//if (status == PedidoStatus.PEDIDO_CANCELADO_SISTEMA) { return 'close'; }
		if (status == PedidoStatus.PEDIDO_PENDENTE) { return 'notification_important'; }
		if (status == PedidoStatus.PEDIDO_REPASSADO) { return 'navigation'; }
		//if (status == PedidoStatus.PEDIDO_REPASSADO_PARA_APLICATIVO) { return 'navigation'; }
		//if (status == PedidoStatus.PEDIDO_NAO_REPASSADO) { return 'notification_important'; }
		return 'sentiment_dissatisfied';
	}

	/**
	 * Formata o prazo do tipo de pagamento da nota em um string
	 * @param prazo
	 */

	getTipoPrazoPagamento(formaPagamento: FormaPagamentoNotaVista) {
		if (formaPagamento == FormaPagamentoNotaVista.A_PRAZO) { return 'À prazo'; }
		if (formaPagamento == FormaPagamentoNotaVista.A_VISTA) { return 'À vista'; }
	}

	/**
	 * Formata o tipo gerado da nota fiscal em um texto legival.
	 *
	 * @param geradoPor
	 */
	getGeradoPor(geradoPor: ContaTipoGeradoPor) {
		let _g = (geradoPor != null ? geradoPor : '').toString().toLowerCase();
		if (_g == ContaTipoGeradoPor.MANUALMENTE.toString().toLowerCase()) { return 'Manualmente'; }
		if (_g == ContaTipoGeradoPor.MOVIMENTACAO_NOTA_FISCAL.toString().toLowerCase()) { return 'Nota Fiscal'; }
		if (_g == ContaTipoGeradoPor.MOVIMENTACAO_ENTRADA.toString().toLowerCase()) { return 'Entrada'; }
		if (_g == ContaTipoGeradoPor.SISTEMA.toString().toLowerCase()) { return 'Sistema'; }
		if (_g == ContaTipoGeradoPor.REPLICADO.toString().toLowerCase()) { return 'Replicado'; }
	}

	/**
	 * Formata o tipo do status na nota de avariados.
	 *
	 * @param geradoPor
	 */
	getStatusNota(statusNota: StatusNotaAvariada): string {
		if (statusNota == StatusNotaAvariada.REPROVADA) { return 'Reprovada'; }
		if (statusNota == StatusNotaAvariada.APROVADA) { return 'Aprovada'; }

		return '';
	}

	/**
	 * Formata o prazo do tipo de movimentação em um string
	 * @param prazo
	 */
	getPrazoPagamento(prazoPagamento: PrazoPagamento): string {
		if (prazoPagamento == PrazoPagamento.UMA) { return '1x'; }
		if (prazoPagamento == PrazoPagamento.DUAS) { return '2x'; }
		if (prazoPagamento == PrazoPagamento.TRES) { return '3x'; }
		if (prazoPagamento == PrazoPagamento.QUATRO) { return '4x'; }
		if (prazoPagamento == PrazoPagamento.CINCO) { return '5x'; }
		if (prazoPagamento == PrazoPagamento.SEIS) { return '6x'; }
		if (prazoPagamento == PrazoPagamento.SETE) { return '7x'; }
		if (prazoPagamento == PrazoPagamento.OITO) { return '8x'; }
		if (prazoPagamento == PrazoPagamento.NOVE) { return '9x'; }
		if (prazoPagamento == PrazoPagamento.DEZ) { return '10x'; }
		if (prazoPagamento == PrazoPagamento.ONZE) { return '11x'; }
		if (prazoPagamento == PrazoPagamento.DOZE) { return '12x'; }

		return '';
	}

	/**
	 * Formata o tipo de movimentação do estoque em um texto legivel
	 * @param tipoPagamento
	 */
	getTipoMovimentacaoEstoque(tipoMovimentacao: TipoMovimentacaoEstoque): string {
		if (tipoMovimentacao == TipoMovimentacaoEstoque.SAIDAS_SEM_NOTA) { return 'Saída'; }
		if (tipoMovimentacao == TipoMovimentacaoEstoque.DOACAO) { return 'Doação'; }
		if (tipoMovimentacao == TipoMovimentacaoEstoque.NOTA_FISCAL_SAIDA) { return 'Saída com Nota'; }
		if (tipoMovimentacao == TipoMovimentacaoEstoque.CONTROLE_AVARIAS) { return 'Saída de Avarias'; }
		if (tipoMovimentacao == TipoMovimentacaoEstoque.CONTROLE_AVARIAS_ENTRADA) { return 'Entrada de Avarias'; }
		if (tipoMovimentacao == TipoMovimentacaoEstoque.ENTRADA_SEM_NOTA) { return 'Entrada'; }
		if (tipoMovimentacao == TipoMovimentacaoEstoque.COMODATO_ENTRADA) { return 'Comodato de Entrada'; }
		if (tipoMovimentacao == TipoMovimentacaoEstoque.COMODATO_SAIDA) { return 'Comodato de Saída'; }
		if (tipoMovimentacao == TipoMovimentacaoEstoque.NOTA_FISCAL_ENTRADA) { return 'Entrada com Nota'; }
		if (tipoMovimentacao == TipoMovimentacaoEstoque.TRANSFERENCIA) { return 'Transferência'; }
		if (tipoMovimentacao == TipoMovimentacaoEstoque.ATUALIZACAO) { return 'Atualização de Estoque'; }
		if (tipoMovimentacao == TipoMovimentacaoEstoque.DEVOLUCAO) { return 'Devolução'; }
		if (tipoMovimentacao == TipoMovimentacaoEstoque.EMPRESTIMO_ENTRADA) { return 'Empréstimo de Entrada'; }
		if (tipoMovimentacao == TipoMovimentacaoEstoque.EMPRESTIMO_SAIDA) { return 'Empréstimo de Saída'; }

		return '';
	}

	/**
	 * Formata o tipo de indicação da compra em um texto legivel
	 * @param tipoPagamento
	 */
	getIndicacaoCompra(indicacaoCompra: IndicacaoCompra): string {
		if (indicacaoCompra == IndicacaoCompra.REDE_SOCIAL) { return 'Rede Social'; }
		if (indicacaoCompra == IndicacaoCompra.INTERNET) { return 'Internet'; }
		if (indicacaoCompra == IndicacaoCompra.OUTDOOR) { return 'Outdoor'; }
		if (indicacaoCompra == IndicacaoCompra.IMA) { return 'Ima'; }
		if (indicacaoCompra == IndicacaoCompra.EMAIL) { return 'E-mail'; }
		if (indicacaoCompra == IndicacaoCompra.APLICATIVO) { return 'Aplicativo'; }
		if (indicacaoCompra == IndicacaoCompra.PLANTAO) { return 'Plantão'; }
		if (indicacaoCompra == IndicacaoCompra.PORTARIA) { return 'Portaria'; }
		if (indicacaoCompra == IndicacaoCompra.TELEATENDIMENTO) { return 'Teleatendimento'; }
		if (indicacaoCompra == IndicacaoCompra.VENDA_A_PORTA) { return 'Venda à Porta'; }
		if (indicacaoCompra == IndicacaoCompra.WHATSAPP) { return 'WhatsApp'; }
		if (indicacaoCompra == IndicacaoCompra.SINALIZACAO) { return 'Sinalização'; }
		if (indicacaoCompra == IndicacaoCompra.IMPRESSO) { return 'Impresso'; }
		if (indicacaoCompra == IndicacaoCompra.CLIENTE_FIDELIZADO) { return 'Cliente Fidelizado'; }
		if (indicacaoCompra == IndicacaoCompra.AUTOMATICA) { return 'Automática'; }
		if (indicacaoCompra == IndicacaoCompra.AGREGADO) { return 'Agregado'; }
		if (indicacaoCompra == IndicacaoCompra.COMERCIO_INDUSTRIA) { return 'Comércio/Indústria'; }
		if (indicacaoCompra == IndicacaoCompra.TELEFONE) { return 'Telefone'; }
		if (indicacaoCompra == IndicacaoCompra.CELULAR) { return 'Celular'; }
		if (indicacaoCompra == IndicacaoCompra.CH_APP) { return 'Ch App'; }
		if (indicacaoCompra == IndicacaoCompra.PG_APP) { return 'PG App'; }
		if (indicacaoCompra == IndicacaoCompra.UP_APP) { return 'UP App'; }
		if (indicacaoCompra == IndicacaoCompra.QRCODEAPP) { return 'Aplicativo QR CODE'; }
		if (indicacaoCompra == IndicacaoCompra.RADIO) { return 'Rádio'; }
		return '';
	}

	getPerfilCliente(perfilCliente: PerfilCliente): string {
		return (PerfilClienteOptions.find(p => p.valor == perfilCliente) || { nome: 'SEM PERFIL' }).nome;
	}

	/**
	 * Formata o tipo de pagamento em um texto legivel
	 * @param tipoPagamento
	 */
	getTipoPagamentoName(_tp: TipoPagamento | 'total', concatString: string = null): string {
		let tipoPagamento = (_tp || '').toLowerCase();
		let cs = (concatString != null ? concatString : '');
		if (tipoPagamento == TipoPagamento.BOLETO.toLowerCase()) { return cs + 'Boleto'; }
		if (tipoPagamento == TipoPagamento.CARTAO_DEBITO.toLowerCase()) { return cs + 'Cartão de Débito'; }
		if (tipoPagamento == TipoPagamento.CARTAO_CREDITO.toLowerCase()) { return cs + 'Cartão de Crédito'; }
		if (tipoPagamento == TipoPagamento.DINHEIRO.toLowerCase()) { return cs + 'Dinheiro'; }
		if (tipoPagamento == TipoPagamento.VALE_GAS.toLowerCase()) { return cs + 'Vale-Gás'; }
		if (tipoPagamento == TipoPagamento.CHEQUE.toLowerCase()) { return cs + 'Cheque'; }
		if (tipoPagamento == TipoPagamento.OUTROS.toLowerCase()) { return cs + 'Outros'; }
		if (tipoPagamento == TipoPagamento.VENDA_ANTECIPADA.toLowerCase()) { return cs + 'Venda Antecipada'; }
		if (tipoPagamento == TipoPagamento.FIADO.toLowerCase()) { return cs + 'Fiado'; }
		if (tipoPagamento == TipoPagamento.TODOS.toLowerCase()) { return cs + 'Todos'; }
		if (tipoPagamento == TipoPagamento.TRANSFERENCIA.toLowerCase()) { return cs + 'Transferência'; }
		if (tipoPagamento == TipoPagamento.REQUISICAO.toLowerCase()) { return cs + 'Requisição'; }
		if (tipoPagamento == TipoPagamento.CONVENIO.toLowerCase()) { return cs + 'Convênio'; }
		if (tipoPagamento == TipoPagamento.DEBITO_AUTOMATICO.toLowerCase()) { return cs + 'Débito Automático'; }
		if (tipoPagamento == TipoPagamento.CHEQUE_PRE.toLowerCase()) { return cs + 'Cheque Pré'; }
		if (tipoPagamento == TipoPagamento.PIX.toLowerCase()) { return cs + 'PIX'; }
		if (tipoPagamento == TipoPagamento.VALE_AGUA.toLowerCase()) { return cs + 'Vale Água'; }
		if (tipoPagamento == TipoPagamento.PICPAY.toLowerCase()) { return cs + 'PicPay'; }
		if (tipoPagamento == 'total') { return cs + 'Total'; }

		return '';
	}

	getTipoPagamentoIcon(tipoPagamento: TipoPagamento): string {
		if (tipoPagamento == TipoPagamento.BOLETO) { return 'account_balance_wallet'; }
		if (tipoPagamento == TipoPagamento.CARTAO_DEBITO) { return 'credit_card'; }
		if (tipoPagamento == TipoPagamento.CARTAO_CREDITO) { return 'credit_card'; }
		if (tipoPagamento == TipoPagamento.DINHEIRO) { return 'attach_money'; }
		if (tipoPagamento == TipoPagamento.VALE_GAS) { return 'card_giftcard'; }
		if (tipoPagamento == TipoPagamento.CHEQUE) { return 'account_balance'; }
		if (tipoPagamento == TipoPagamento.OUTROS) { return 'more_horiz'; }
		if (tipoPagamento == TipoPagamento.VENDA_ANTECIPADA) { return 'card_giftcard'; }
		if (tipoPagamento == TipoPagamento.FIADO) { return 'card_giftcard'; }
		if (tipoPagamento == TipoPagamento.TODOS) { return 'more_horiz'; }
		if (tipoPagamento == TipoPagamento.CONVENIO) { return 'store'; }
		if (tipoPagamento == TipoPagamento.DEBITO_AUTOMATICO) { return 'account_balance_wallet'; }
		if (tipoPagamento == TipoPagamento.CHEQUE_PRE) { return 'account_balance'; }
		if (tipoPagamento == TipoPagamento.PIX) { return 'account_balance_wallet'; }
		if (tipoPagamento == TipoPagamento.VALE_AGUA) { return 'card_giftcard'; }
		if (tipoPagamento == TipoPagamento.PICPAY) { return 'account_balance_wallet'; }
		return '';
	}

	/**
	 * Formata o tipo de cartão em um texto legivel
	 *
	 * @param tipoCartao
	 */
	getTipoCartaoName(tipoCartao: TipoCartao | any): string {
		if (tipoCartao != null) {
			if (tipoCartao.toString().toLowerCase() == TipoCartao.CARTAO) return 'Cartão';
			if (tipoCartao.toString().toLowerCase() == TipoCartao.BOLETO) return 'Boleto';
			if (tipoCartao.toString().toLowerCase() == TipoCartao.CREDITO) return 'Crédito';
			if (tipoCartao.toString().toLowerCase() == TipoCartao.DEBITO) return 'Débito';
		}

		return '';
	}

	/**
	 * Formata o tipo de cliente em um texto legivel
	 *
	 * @param tipoCliente
	 */
	getTipoCliente(tipoCliente: TipoCliente): string {
		if (tipoCliente == TipoCliente.PESSOA_JURIDICA) { return 'Pessoa Jurídica'; }
		if (tipoCliente == TipoCliente.PESSOA_FISICA) { return 'Pessoa Física'; }
		if (tipoCliente == TipoCliente.COMERCIO) { return 'Comércio'; }
		if (tipoCliente == TipoCliente.PRODUTOR_RURAL) { return 'Produtor Rural'; }
		return '';
	}

	/**
	 * Formata o status do tipo de entrega em um texto legível
	 * @param status
	 */

	getStatusNameTipoVenda(status: LancarPedidoRapidoTipo): string {
		if (status == LancarPedidoRapidoTipo.PEDIDO_PORTARIA) { return 'Venda na portaria'; }
		if (status == LancarPedidoRapidoTipo.PEDIDO_ENTREGADOR) { return 'Venda para entregador'; }
	}

	formatTelefones(cliente: Cliente) {
		return [cliente.telefone, ...(cliente.outrosTelefones || [])].filter(x => !!x && !!x.trim()).join(', ');
	}

	/**
	 * Formata o status do pedido em um texto legivel
	 * @param status
	 */
	getStatusName(status: PedidoStatus): string {
		//if (status == PedidoStatus.PEDIDO_DESCONHECIDO) { return 'Desconhecido'; }
		if (status == PedidoStatus.PEDIDO_NOVO) { return 'Novo Pedido'; }
		if (status == PedidoStatus.PEDIDO_AGENDADO) { return 'Agendado'; }
		//if (status == PedidoStatus.PEDIDO_PRONTO) { return 'Pronto'; }
		//if (status == PedidoStatus.PEDIDO_ACEITO) { return 'Aceito'; }
		//if (status == PedidoStatus.PEDIDO_NAO_ACEITO) { return 'Não Aceito'; }
		if (status == PedidoStatus.PEDIDO_EM_TRANSITO) { return 'Em Trânsito'; }
		if (status == PedidoStatus.PEDIDO_ENTREGUE) { return 'Entregue'; }
		//if (status == PedidoStatus.PEDIDO_NAO_ENTREGUE) { return 'Não Entregue'; }
		if (status == PedidoStatus.PEDIDO_CANCELADO) { return 'Cancelado'; }
		//if (status == PedidoStatus.PEDIDO_CANCELADO_SISTEMA) { return 'Cancelado pelo Sistema'; }
		if (status == PedidoStatus.PEDIDO_PENDENTE) { return 'Pendente'; }
		if (status == PedidoStatus.PEDIDO_REPASSADO) { return 'Repassado'; }
		if (status == PedidoStatus.PEDIDO_DEVOLVIDO) { return 'Devolvido'; }
		//if (status == PedidoStatus.PEDIDO_REPASSADO_PARA_APLICATIVO) { return 'Repassado para Aplicativo'; }
		//if (status == PedidoStatus.PEDIDO_NAO_REPASSADO) { return 'Não Repassado'; }

		return 'Desconhecido';
	}

	/**
	 * Retorna uma classe para o ícone de status do tipo de lance de pedido
	 * @param status
	 */

	getStatusPedido(status: LancarPedidoRapidoTipo): string {
		if (status == LancarPedidoRapidoTipo.PEDIDO_PORTARIA) return 'order-status-box-green';
		if (status == LancarPedidoRapidoTipo.PEDIDO_ENTREGADOR) return 'order-status-box-red';

		return 'order-status-box-grey';
	}

	/**
	 * Retorna uma classe para o icone de status
	 * @param status
	 */
	getStatusClass(status: PedidoStatus): string {
		if (status == PedidoStatus.PEDIDO_AGENDADO) return 'order-status-box-black';
		if (status == PedidoStatus.PEDIDO_NOVO) return 'order-status-box-yellow';
		if (status == PedidoStatus.PEDIDO_EM_TRANSITO) return 'order-status-box-purple';
		if (status == PedidoStatus.PEDIDO_ENTREGUE) return 'order-status-box-green';
		if (status == PedidoStatus.PEDIDO_CANCELADO) return 'order-status-box-red';
		if (status == PedidoStatus.PEDIDO_PENDENTE) return 'order-status-box-orange';
		if (status == PedidoStatus.PEDIDO_REPASSADO) return 'order-status-box-cyan';
		if (status == PedidoStatus.PEDIDO_DEVOLVIDO) return 'order-status-box-red';

		return 'order-status-box-grey';
	}

	/**
	 * Formata situação de um veículo em um texto legivel
	 * @param situacao
	 */
	getSituacaoVeiculoName(situacao: SituacaoVeiculo): string {
		if (situacao == SituacaoVeiculo.ATIVO) { return 'Ativo'; }
		if (situacao == SituacaoVeiculo.CEDIDO_TERCEIROS) { return 'Cedido por Terceiros'; }
		if (situacao == SituacaoVeiculo.EM_MANUTENCAO) { return 'Em Manutenção'; }
		if (situacao == SituacaoVeiculo.FURTADO) { return 'Furtado'; }
		if (situacao == SituacaoVeiculo.INATIVO) { return 'Inativo'; }
		if (situacao == SituacaoVeiculo.SINISTRADO) { return 'Sinistrado'; }
		return '';
	}

	/**
	 * Formata o tipo de um veículo em um texto legivel
	 * @param status
	 */
	getTipoVeiculoName(tipoVeiculo: TipoVeiculo): string {
		if (tipoVeiculo == TipoVeiculo.AUTOMOVEL) { return 'Automóvel'; }
		if (tipoVeiculo == TipoVeiculo.CAMINHAO) { return 'Caminhão'; }
		if (tipoVeiculo == TipoVeiculo.CAMINHONETE) { return 'Caminhonete'; }
		if (tipoVeiculo == TipoVeiculo.CAMIONETA) { return 'Camioneta'; }
		if (tipoVeiculo == TipoVeiculo.CARRETA) { return 'Carreta'; }
		if (tipoVeiculo == TipoVeiculo.CAVALO) { return 'Cavalo'; }
		if (tipoVeiculo == TipoVeiculo.CHASSI_PLATAFORMA) { return 'Chassi Plataforma'; }
		if (tipoVeiculo == TipoVeiculo.MOTOCICLETA) { return 'Motocicleta'; }
		if (tipoVeiculo == TipoVeiculo.MOTOR_CASA) { return 'Motor Casa'; }
		if (tipoVeiculo == TipoVeiculo.QUADRICICLO) { return 'Quadriciclo'; }
		if (tipoVeiculo == TipoVeiculo.REBOQUE) { return 'Reboque'; }
		if (tipoVeiculo == TipoVeiculo.SIDE_CAR) { return 'Side Car'; }
		if (tipoVeiculo == TipoVeiculo.TRICICLO) { return 'Triciclo'; }
		if (tipoVeiculo == TipoVeiculo.UTILITARIO) { return 'Utilitário'; }

		return '';
	}

	/**
	 * Formata o tipo de estoque em um texto legivel
	 * @param situacao
	 */
	getTipoEstoque(tipoEstoque: TipoEstoque): string {
		if (tipoEstoque == TipoEstoque.ESTOQUE_VAZIO) { return 'Estoque Vazio'; }
		if (tipoEstoque == TipoEstoque.ESTOQUE_CHEIO) { return 'Estoque Cheio'; }
		if (tipoEstoque == TipoEstoque.ESTOQUE_UNICO) { return 'Estoque Único'; }
		return '---';
	}

	/**
	 * Formata a forma de pagamento em um texto legivel
	 * @param situacao
	 */
	getFormaPagamento(formaPagamento: FormaPagamento): string {
		if (formaPagamento == FormaPagamento.A_PRAZO) { return 'À Prazo'; }
		if (formaPagamento == FormaPagamento.A_VISTA) { return 'À Vista'; }

		return '';
	}

	getValorEstoque(tipoEstoque: TipoEstoque, produto: Produto): number {
		if (tipoEstoque == TipoEstoque.ESTOQUE_VAZIO) { return (produto || {} as any).valorVazio || 0; }
		if (tipoEstoque == TipoEstoque.ESTOQUE_CHEIO || tipoEstoque == TipoEstoque.ESTOQUE_UNICO) { return (produto || {} as any).valorCheio || 0; }

		return (produto || {} as any).valorCheio || 0;
	}

	/**
	 * Formata o tipo de estoque negociado em um texto legivel
	 * @param situacao
	 */
	getTipoEstoqueNegociado(tipoEstoqueNegociado: TipoEstoqueNegociado): string {
		if (tipoEstoqueNegociado == TipoEstoqueNegociado.ESTOQUE_VAZIO) { return 'Estoque Vazio'; }
		if (tipoEstoqueNegociado == TipoEstoqueNegociado.ESTOQUE_CHEIO) { return 'Estoque Cheio'; }
		if (tipoEstoqueNegociado == TipoEstoqueNegociado.TODOS) { return 'Todos'; }

		return '';
	}

	/**
	 * Formata o tipo de estoque em um texto legivel resumido
	 * @param situacao
	 */
	getTipoEstoqueResumido(tipoEstoque: TipoEstoque): string {
		if (tipoEstoque == TipoEstoque.ESTOQUE_VAZIO) { return 'Vazio'; }
		if (tipoEstoque == TipoEstoque.ESTOQUE_CHEIO) { return 'Cheio'; }
		if (tipoEstoque == TipoEstoque.ESTOQUE_UNICO) { return 'Único'; }

		return '';
	}

	/**
	 * Formata o status de uma venda em um texto legivel
	 * @param situacao
	 */
	getVendaStatus(vendaStatus: VendaStatus): string {
		if (vendaStatus == VendaStatus.AGENDADO) { return 'Agendado'; }
		if (vendaStatus == VendaStatus.INICIADO) { return 'Iniciado'; }
		if (vendaStatus == VendaStatus.EM_ANDAMENTO) { return 'Em Andamento'; }
		if (vendaStatus == VendaStatus.PAGAMENTO_PROCESSADO) { return 'Pagamento Processado'; }
		return 'Desconhecido';
	}

	/**
	 * Retorna uma classe para o icone de status de uma venda
	 * @param status
	 */
	getVendaStatusClass(vendaStatus: VendaStatus): string {
		if (vendaStatus == VendaStatus.AGENDADO) { return 'order-status-box-yellow'; }
		if (vendaStatus == VendaStatus.INICIADO) { return 'order-status-box-blue'; }
		if (vendaStatus == VendaStatus.EM_ANDAMENTO) { return 'order-status-box-cyan'; }
		if (vendaStatus == VendaStatus.PAGAMENTO_PROCESSADO) { return 'order-status-box-green'; }

		return 'order-status-box-grey';
	}

	/**
	 * Retorna uma string do tipo de entregador
	 * @param isPortaria
	 */
	getIsPortaria(isPortaria: boolean): string {
		if (isPortaria) { return 'Sim'; }
		if (!isPortaria) { return 'Não'; }
	}

	formatPagamentos(pagamentos: Pagamento[]) {
		if (pagamentos && pagamentos.length > 0) {
			let result = '';
			for (const pag of pagamentos) {
				result += this.getTipoPagamentoName(pag.tipoPagamento) + ' <b>(R$ ' + (pag.valor || 0).toFixed(2) + ')</b>\n';
			}
			return result;
		} else {
			return '--';
		}
	}

	formatPagamentosSimple(pagamentos: Pagamento[]) {
		if (pagamentos && pagamentos.length > 0) {
			let result = '';
			for (const pag of pagamentos) result += (result.length > 0 ? ', ' : '') + this.getTipoPagamentoName(pag.tipoPagamento);
			return result;
		} else {
			return '--';
		}
	}

	getLojasEEmpresasInfo(empresas: Empresa[], lojas: Loja[]) {
		let result;
		if ((empresas || []).length > 0) result = this.getEmpresasInfo(empresas, '');
		if ((lojas || []).length > 0) {
			if (result != null && result != '') result += ', ';
			else result = '';
			result += this.getLojasInfo(lojas, '');
		}
		return result;
	}

	/**
	 * Retorna uma string contendo os nomes das empresas passadas como
	 * parametro.
	 *
	 * @param empresas
	 */
	getEmpresasInfo(empresas: Empresa[], emptyResult: string = '--'): string {
		if (!!empresas && empresas.length > 0) {
			let _result = null;
			for (const emp of empresas) {
				if (emp.nomeFantasia != null || emp.razaoSocial != null) {
					_result = (_result == null || _result == '' ? '' : _result + ', ') + (emp.nomeFantasia != null ? emp.nomeFantasia : emp.razaoSocial);
				}
			}
			return _result;
		} else {
			return emptyResult;
		}
	}

	/**
	 * Retorna uma string contendo os nomes das lojas passadas como
	 * parametro.
	 *
	 * @param lojas
	 */
	getLojasInfo(lojas: Loja[], emptyResult: string = '--'): string {
		if (!!lojas && lojas.length > 0) {
			let _result = null;
			for (const loj of lojas) {
				if (loj.nomeFantasia != null || loj.razaoSocial != null) {
					_result = (_result == null || _result == '' ? '' : _result + ', ') + (loj.nomeFantasia != null ? loj.nomeFantasia : loj.razaoSocial);
				}
			}
			return _result;
		} else {
			return emptyResult;
		}
	}

	/**
	 * Retorna o endereço marcado como default na lista de endereços
	 *
	 * @param enderecos - Lista de endereços na qual o Endereço Default será selecionado
	 */
	getEnderecoDefault(enderecos: Endereco[]): Endereco {
		let enderecoDefault: Endereco = null;
		if (!!enderecos) {
			if (enderecos.length > 0) { enderecoDefault = enderecos[0]; }
			for (const end of enderecos) {
				delete end.historicoAcaos;
				if (end.isDefault) {
					enderecoDefault = end;
					break;
				}
			}
		}
		if (enderecoDefault) {
			if (enderecoDefault.uf == null && enderecoDefault.estadoAcronimo != null) enderecoDefault.uf = enderecoDefault.estadoAcronimo;
			else if (enderecoDefault.estadoAcronimo == null && enderecoDefault.uf != null) enderecoDefault.estadoAcronimo = enderecoDefault.uf;
		}
		return enderecoDefault || new Endereco();
	}

	/**
	 * Recupera o endereço default da Lista de endereços, formata o resultado em uma string
	 * e retorna o valor formatado.
	 *
	 * @param enderecos - Lista de endereços
	 */
	getEnderecoDefaultFormated(enderecos: Endereco[], small?: boolean): string {
		const e = this.getEnderecoDefault(enderecos);
		return this.formatEndereco(e, small);
	}

	formatEndereco(e: Endereco, small: boolean = true): string {
		if (e) {
			let end = [e.endereco, e.complemento, e.bairro, e.numero, e.cidade].filter(i => !!i).join(',');
			if (small) {
				end = end.length > 30 ? end.substr(0, 27) + '...' : end
			}
			return end;
		}
		return '---';
	}

	/**
	 * Formata o status de uma Conta em um texto legivel
	 * @param status
	 */
	getStatusContaName(_s: StatusConta, secondType: boolean = false): string {
		let status = ((_s || '') as any).toLowerCase();
		if (status == StatusConta.AGUARDANDO_PAGAMENTO) { return 'Aguardando Pag'; }
		if (status == StatusConta.VENCIDO) { return 'Vencido'; }
		if (status == StatusConta.PAGO) { return secondType ? 'Recebido' : 'Pago'; }
		if (status == StatusConta.CANCELADO) { return 'Cancelado'; }
		if (status == StatusConta.DEVOLVIDO) { return 'Devolvido'; }
		return 'Desconhecido';
	}

	// tslint:disable-next-line:member-ordering
	getTipoNotaFiscal: (tipo: TipoNotaFiscal) => string = getTipoNotaFiscal;

	getTipoNF(tipo: TipoNF): string {
		if (tipo === TipoNF.NFCE) return 'NFc-e';
		if (tipo === TipoNF.NFE) return 'NF-e';
		return '--';
	}

	/**
	 * Clona um objeto
	 */
	cloneJSON(obj) {
		if (!!obj) {
			return Array.isArray(obj) ? [...obj] : { ...obj };
		}
		return null;
	}

	isArray(o): boolean { return Array.isArray(o); }

	/**
	 * Gera um array de string de um enum ou JSON
	 */
	enumToString(object): string[] {
		return Object.keys(object).filter(
			(type) => isNaN(<any>type) && type !== 'values'
		);
	}

	filterArraysByDate<T>(array: T[], field: string, dateStart: Date, dateEnd: Date, skipNull: boolean = false): T[] {
		const result = [];

		const dateFilterStartMs = dateStart ? dateStart.getTime() : 0;
		const dateFilterEndMs = dateEnd ? dateEnd.getTime() : 0;

		for (const obj of (array || [])) {

			if (obj[field] != null) {

				/* Pula o onjeto caso ele esteja fora do range de filtragem */
				const fieldObjMS = obj[field] != null ? new Date(obj[field]).getTime() - UtilsService.TZ_CORRECTION : 0;
				if (fieldObjMS > 0 && dateFilterStartMs > 0 && dateFilterEndMs > 0 && (fieldObjMS < dateFilterStartMs || fieldObjMS > dateFilterEndMs)) {
					continue;
				}
			} else if (skipNull) { continue; }

			result.push(obj);
		}

		return result;
	}

	getTotalFromColums(data, objectColumn, valueColumns: string[]) {
		try {
			const _columns = {};

			this._resetColumns(_columns, valueColumns, true, false);

			for (const c in data) {
				const object = data[c];
				object.isSummaryRow = false;

				for (const valueColumn of valueColumns) {

					const _vColumn = (!!objectColumn ? object[objectColumn][valueColumn] : object[valueColumn]);

					if (isNaN(_vColumn)) continue;

					/*
					if (isNaN(_vColumn)) throw new Error(`${valueColumn} não é um número`);

					_columns[valueColumn].valorTotal += (_vColumn || 0);
					_columns[valueColumn].valorFiltrado += (_vColumn || 0);
					*/

					_columns[valueColumn] += (_vColumn || 0);
				}
			}

			return this.cloneJSON(_columns);

		} catch (err) { console.log(err); }

		return {};
	}

	filtrarSummaryRow(data, objectColumn, dataParam, valueColumns: string[], summaryType: SummaryType) {

		const customSummary = [7, 15, 30, 45];

		try {
			const _result: any[] = [];

			const _columns = {};

			this._resetColumns(_columns, valueColumns, true);

			let currentMonthSummary = null;
			let currentWeekSummary = null;
			let currentDaySummary = null;

			let currentCustomSummary = null;

			for (const c in data) {

				const object = data[c];
				object.isSummaryRow = false;

				if (object[dataParam] == null) {
					_result.unshift(object);
					continue;
				}

				const nextObject = (parseInt(c) + 1) <= (data.length - 1) ? data[(parseInt(c) + 1)] : null;

				const dateObject = new Date(object[dataParam]);
				const dateNextObject = nextObject != null ? new Date(nextObject[dataParam]) : null;

				_result.push(object);

				if (currentMonthSummary == null) currentMonthSummary = dateObject.getMonth();
				if (currentWeekSummary == null) currentWeekSummary = getWeekNumber(dateObject);
				if (currentDaySummary == null) currentDaySummary = dateObject.getDate();
				if (currentCustomSummary == null) currentCustomSummary = getCustomDayRoom(dateObject, customSummary[0]);

				for (const valueColumn of valueColumns) {
					const currentColumn = _columns[valueColumn];

					const _vColumn = (!!objectColumn ? object[objectColumn][valueColumn] : object[valueColumn]);

					currentColumn.valorTotal += (_vColumn || 0);
					currentColumn.valorFiltrado += (_vColumn || 0);
				}

				switch (summaryType) {
					case SummaryType.MONTH:
						if (nextObject == null || dateNextObject.getMonth() != currentMonthSummary) {

							_result.push({
								empresa: object.empresa,
								empresas: object.empresas,
								loja: object.loja,
								lojas: object.lojas,
								isSummaryRow: true,
								summaryDate: new Date(object[dataParam]),
								columns: this.cloneJSON(_columns)
							});

							this._resetColumns(_columns, valueColumns, false);

							currentMonthSummary = null;
						}
						break;
					case SummaryType.WEEK:
						if (nextObject == null || getWeekNumber(dateNextObject) != currentWeekSummary) {

							_result.push({
								empresa: object.empresa,
								empresas: object.empresas,
								loja: object.loja,
								lojas: object.lojas,
								isSummaryRow: true,
								summaryDate: new Date(object[dataParam]),
								columns: this.cloneJSON(_columns)
							});

							this._resetColumns(_columns, valueColumns, false);

							currentWeekSummary = null;
						}
						break;
					case SummaryType.DAY:
						if (nextObject == null || dateNextObject.getDate() != currentDaySummary) {

							_result.push({
								empresa: object.empresa,
								empresas: object.empresas,
								loja: object.loja,
								lojas: object.lojas,
								isSummaryRow: true,
								summaryDate: new Date(object[dataParam]),
								columns: this.cloneJSON(_columns)
							});

							this._resetColumns(_columns, valueColumns, false);

							currentDaySummary = null;
						}
						break;
					case SummaryType.CUSTOM:
						if (nextObject == null || getCustomDayRoom(dateObject, customSummary[0]) != currentCustomSummary) {

							const summaryDate = customSummary.shift();

							_result.push({
								empresa: object.empresa,
								empresas: object.empresas,
								loja: object.loja,
								lojas: object.lojas,
								isSummaryRow: true,
								summaryDate: summaryDate,
								columns: this.cloneJSON(_columns)
							});

							// this._resetColumns(_columns, valueColumns, false);

							currentCustomSummary = null;

							if (customSummary.length < 1) { customSummary.push(summaryDate + 15); }
						}
						break;
				}
			}

			return {
				result: _result,
				totalColumns: this.cloneJSON(_columns)
			};

		} catch (err) { console.log(err); }

		return {};
	}

	private _resetColumns(_columns, valueColumns: string[], resetTotal: boolean = true, createObject: boolean = true) {
		for (const valueColumn of valueColumns) {
			if (createObject) {
				_columns[valueColumn] = {
					valorTotal: resetTotal == true ? 0 : _columns[valueColumn].valorTotal,
					valorFiltrado: 0
				};
			} else {
				_columns[valueColumn] = 0;
			}
		}
	}
	/**
	 * Funções de exportação
	 */
	public exportAsExcelFile(excelFileName, jsonData, hideHeader = true): void {
		if (!!jsonData) {
			for (const row of jsonData) {
				for (const col in row) {
					if (row[col] && row[col].content != null) {
						row[col] = row[col].content
					}
				}
			}
			const workBook = XLSX.utils.book_new();
			const workSheet = XLSX.utils.json_to_sheet(jsonData, {
				skipHeader: hideHeader,
				cellStyles: true
			});
			XLSX.utils.book_append_sheet(workBook, workSheet, 'data');
			XLSX.writeFile(workBook, (excelFileName || Date.now()) + '_VENDERGAS' + '.xlsx', {
				bookSST: true
			});
		}
	}

	public exportAsExcelFileFromMultipleTables(excelFileName: string, jsonArray: any[]) {
		if (!!jsonArray) {
			const workBook = XLSX.utils.book_new();
			let i = 0
			for (const jsonData of jsonArray) {
				if (jsonData) {
					for (const row of jsonData) {
						for (const col in row) {
							if (row[col].content != null) {
								row[col] = row[col].content
							}
						}
					}
					const workSheet = XLSX.utils.json_to_sheet(jsonData, {
						skipHeader: true
					});
					XLSX.utils.book_append_sheet(workBook, workSheet, `data${++i}`);
				}
			}
			XLSX.writeFile(workBook, (excelFileName || Date.now()) + '_VENDERGAS' + '.xlsx');
		}
	}

	generateColAndRows(jsonData, isColumnDynamic: boolean = false) {
		const col = [];
		const rows = [];

		if (!!jsonData && jsonData.length > 0) {

			if (isColumnDynamic == true) {

				for (let i in jsonData) {
					for (const key in jsonData[i]) {
						if (col.find(r => r.toString() === key.toString()) == null) {
							col.push(key);
						}
					}
				}

				const _r = [];
				for (const data of (jsonData || [])) {
					for (const key in data) {
						if (data[key] != null) {
							_r.push(data[key]);
						}
					}
				}
				rows.push(_r);

			} else {

				for (const key in jsonData[0]) {
					col.push(key);
				}

				for (const data of (jsonData || [])) {
					const _r = [];

					for (const key in data) {
						if (data[key] != null) {
							_r.push(data[key]);
						}
					}

					rows.push(_r);
				}
			}
		}

		return {
			col: col,
			rows: rows
		};
	}

	gerarCupomPedido(pedido: Pedido, type: 'normal' | 'cupom' = 'cupom') {

		const doc = type === 'normal' ? new jsPDF('p', 'mm', 'a5') : new jsPDF('p', 'mm', [(150 * 2.835538752362949), (80 * 2.8294573643410854)]);

		const pageWidth = doc.internal.pageSize.width || doc.internal.pageSize.getWidth();
		const pageHeight = doc.internal.pageSize.height || doc.internal.pageSize.getHeight();

		let pedido_items = [
			{ title: 'n° Pedido', value: pedido.numero || '--' },
			{ title: 'Atendente', value: pedido.atendidoPor != null ? pedido.atendidoPor.nome || '--' : '--' },
			{ title: 'Data do Pedido', value: pedido.data != null ? formatDate(pedido.data, true, undefined, false, true) : '--' },
			{ title: 'Cliente', value: `${pedido.cliente != null ? pedido.cliente.razaoSocial || pedido.cliente.nome || '--' : '--'} / ${pedido.cliente != null ? pedido.cliente.telefone || '--' : '--'}` },
			{ title: 'Endereço', value: pedido.cliente != null && pedido.cliente.enderecos != null ? this.getEnderecoDefaultFormated(pedido.cliente.enderecos, false) : '--' }
		];

		let produtosItems = [];

		((pedido || {} as Pedido).estoquesToUpdate || []).forEach(e => {
			let prod = ((pedido || {} as Pedido).produtos || []).find(p => p.idProduto == e.idProduto || p._id == e.idProduto);
			if (prod != null) {
				produtosItems.push({
					title: (e.estoqueSelecionado.tipoEstoque == TipoEstoque.ESTOQUE_CHEIO ? prod.notaFiscal_nome_cheio : e.estoqueSelecionado.tipoEstoque == TipoEstoque.ESTOQUE_VAZIO ? prod.notaFiscal_nome_vazio : e.estoqueSelecionado.tipoEstoque == TipoEstoque.ESTOQUE_UNICO ? prod.notaFiscal_nome_unico : prod.nome) || prod.nome,
					value: `R$ ${Number(e.valorNegociado || e.valorCompleto || 0).toFixed(2)} x${e.quantidade}`
				});
			}
		});

		const pagamentoItems = pedido.pagamentos.map(pag => ({
			title: this.getTipoPagamentoName(pag.tipoPagamento),
			value: `R$ ${pag.valor.toFixed(2)}`
		}));

		const troco = this.calcularTroco(pedido);

		if (type === 'normal') {

			const countStringLines = function (string, maxCharactersRow = 33) {
				if (string != null) return Math.ceil((string.length / maxCharactersRow) * Math.pow(10, 0)) / Math.pow(10, 0);
				return 0;
			};

			const checkPage = function (newItemY) {
				let fixedY = newItemY;
				if (newItemY >= pageHeight - 3) {
					doc.addPage();
					fixedY = 0 // Restart height position
				}
				return fixedY;
			};

			const drawItems = function (startY, items) {
				let finalY = checkPage(startY);
				(items || []).forEach((i, index) => {
					let prevStringLines = countStringLines(index === 0 ? null : items[index - 1].value, 21);
					finalY = checkPage(finalY + (prevStringLines < 2 ? 7 : 7 + (3 * prevStringLines))) * 1.08;
					doc.setFontSize(11);
					doc.setFontType('bold');
					doc.text(
						i.title.toUpperCase(),
						5,
						finalY,
						{
							align: 'left',
							maxWidth: 10 // 70
						}
					);
					doc.setFontSize(12);
					doc.setFontType('normal');
					doc.text(
						i.value,
						pageWidth - 5,
						finalY,
						{
							align: 'right',
							maxWidth: 50 //
						}
					);

					if (index == (items.length - 1)) {
						let currentSTringLine = countStringLines(i.value, 21);
						finalY = checkPage(finalY + (currentSTringLine < 2 ? 0 : 3 * currentSTringLine));
					}
				});
				return finalY;
			};

			const drawTitle = function (startY, textLeft?, textRight?, negrito = true) {
				let finalY = checkPage(startY);

				doc.setFontSize(16);
				if (negrito) doc.setFontType('bold');
				if (textLeft != null) doc.text(textLeft, 5, finalY, 'left');
				if (textRight != null) doc.text(textRight, pageWidth - 5, finalY, 'right');

				return startY + 3;
			};

			const drawLine = function (startY) {
				let finalY = checkPage(startY);
				doc.line(2, finalY, pageWidth - 2, finalY);
				return finalY + 3;
			};

			doc.setFontSize(10);
			doc.setFontType('bold');
			doc.text('CUPOM DE PEDIDO', pageWidth / 2, 8, 'center');

			let estabelecimento = pedido != null ? pedido.empresa || pedido.loja : null;

			drawTitle(22, estabelecimento != null ? estabelecimento.nomeFantasia : '--', estabelecimento != null ? estabelecimento.telefone : '--');

			drawLine(25);

			const finalY = drawItems(25, pedido_items);

			let finalYTitle = drawTitle(finalY + 15, 'PRODUTOS');

			drawLine(finalYTitle);

			const finalYProdutos = drawItems(finalYTitle, produtosItems);

			drawLine(finalYProdutos + 4);

			let finalYPagTitle = drawTitle(finalYProdutos + 6, 'PAGAMENTOS');

			drawLine(finalYPagTitle);

			const finalYPag = drawItems(finalYPagTitle, pagamentoItems);

			drawLine(finalYPag + 4);

			let finalYTotal = drawTitle(finalYPag + 15, 'TOTAL: ', `R$ ${((pedido || {} as Pedido).valorTotal || 0).toFixed(2)}`);
			let finalYTroco = drawTitle(finalYTotal + 15, 'Troco: ', `R$ ${troco}`, false);

			let nomeCliente = pedido.cliente != null && pedido.cliente.nome != null ? pedido.cliente.nome : 'Assinatura';
			let clienteNomeArea = '__________________________________';

			if (nomeCliente.length > clienteNomeArea.length) {
				clienteNomeArea = '';
				for (let i = 0; i < nomeCliente.length; ++i) clienteNomeArea += '_';
			}

			doc.setFontSize(10);
			doc.setFontType('normal');

			doc.text(`${clienteNomeArea}`, pageWidth / 2, finalYTroco + 24, 'center');

			doc.setFontSize(8);
			doc.setFontType('bold');
			doc.text(`${nomeCliente.toUpperCase()}  `, pageWidth / 2, finalYTroco + 28, 'center');

		} else {
			const countStringLines = function (string, maxCharactersRow = 21) {
				if (string != null) return Math.ceil((string.length / maxCharactersRow) * Math.pow(10, 0)) / Math.pow(10, 0);
				return 0;
			};

			const checkPage = function (newItemY) {
				let fixedY = newItemY;
				if (newItemY >= pageHeight - 3) {
					doc.addPage();
					fixedY = 0 // Restart height position
				}
				return fixedY;
			};

			const drawItems = function (startY, items) {
				let finalY = checkPage(startY);
				(items || []).forEach((i, index) => {
					let prevStringLines = countStringLines(index === 0 ? null : items[index - 1].value, 21);
					finalY = checkPage(finalY + (prevStringLines < 2 ? 4 : 4 + (2 * prevStringLines))) * (index == 0 ? 1 : 1.08);
					doc.setFontSize(7);
					doc.setFontType('bold');
					doc.text(i.title.toUpperCase(), 5, finalY, { align: 'left', maxWidth: 40 });
					doc.setFontSize(8);
					doc.setFontType('normal');
					doc.text(i.value, pageWidth - 5, finalY, { align: 'right', maxWidth: 50 });

					if (index == (items.length - 1)) {
						let currentSTringLine = countStringLines(i.value, 21);
						finalY = checkPage(finalY + (currentSTringLine < 2 ? 0 : 1 * currentSTringLine));
					}
				});
				return finalY;
			};

			const drawTitle = function (startY, textLeft?, textRight?, negrito = true) {
				let finalY = checkPage(startY);

				doc.setFontSize(10);
				if (negrito) doc.setFontType('bold');
				if (textLeft != null) doc.text(textLeft, 5, finalY, 'left');
				if (textRight != null) doc.text(textRight, pageWidth - 5, finalY, 'right');

				return startY + 0.5;
			};

			const drawLine = function (startY) {
				let finalY = checkPage(startY);
				doc.line(0.5, finalY, pageWidth - 0.5, finalY);
				return finalY + 3;
			};

			doc.setFontSize(7);
			doc.setFontType('bold');
			doc.text('CUPOM DE PEDIDO', pageWidth / 2, 8, 'center');

			let estabelecimento = pedido != null ? pedido.empresa || pedido.loja : null;

			drawTitle(13, estabelecimento != null ? estabelecimento.nomeFantasia : '--', estabelecimento != null ? estabelecimento.telefone : '--');

			drawLine(14);

			const finalY = drawItems(15, pedido_items);

			let finalYTitle = drawTitle(finalY + 15, 'PRODUTOS');

			drawLine(finalYTitle + 0.5);

			const finalYProdutos = drawItems(finalYTitle + 1, produtosItems);

			let finalYPagTitle = drawTitle(finalYProdutos + 10, 'PAGAMENTOS');

			drawLine(finalYPagTitle + 0.5);

			const finalYPag = drawItems(finalYPagTitle, pagamentoItems);

			let finalYTotal = drawTitle(finalYPag + 10, 'TOTAL: ', `R$ ${((pedido || {} as Pedido).valorTotal || 0).toFixed(2)}`);
			let finalYTroco = drawTitle(finalYTotal + 4, 'Troco: ', `R$ ${troco}`, false);

			let nomeCliente = pedido.cliente != null && pedido.cliente.nome != null ? pedido.cliente.nome : 'Assinatura';
			let clienteNomeArea = '____________________________';

			if (nomeCliente.length > clienteNomeArea.length) {
				clienteNomeArea = '';
				for (let i = 0; i < nomeCliente.length; ++i) clienteNomeArea += '_';
			}

			doc.setFontSize(10);
			doc.setFontType('normal');

			doc.text(`${clienteNomeArea}`, pageWidth / 2, finalYTroco + 23, 'center');

			doc.setFontSize(7);
			doc.setFontType('bold');
			doc.text(`${nomeCliente.toUpperCase()}  `, pageWidth / 2, finalYTroco + 27, 'center');
		}

		// window.open(doc.output('bloburl'), '_blank');
		this.renderPDFInDocument(doc.output('datauristring'));
	}

	generateHeaderPDF(doc, pageName, infoDateStart, infoDateEnd, aditionalInfoOnHeader: { title, info }[], estabelecimento: Estabelecimento = null) {

		const weekNames = ['Domingo', 'Segunda-feira', 'Terça-feira', 'Quarta-feira', 'Quinta-feira', 'Sexta-feira', 'Sábado'];

		const today = new Date(Date.now());

		const pageWidth = doc.internal.pageSize.width || doc.internal.pageSize.getWidth();

		doc.setTextColor('#000000');
		doc.setFontSize(12);
		doc.text('Relatório VENDERGAS', pageWidth / 2, 6, 'center');

		// doc.fromHTML('<span style="font-family: Sans-Serif; font-size: 14px; color: #000; font-weight: 100;"> Relatório VENDERGAS </span>', 10, 2);

		doc.setDrawColor(170, 170, 170);
		doc.line(3, 13, 207, 13);

		if (infoDateStart != null && infoDateEnd != null) {
			doc.fromHTML(
				'<span style="font-family: Sans-Serif; font-size: 12px; color: #414141;">Informações referentes a data de' +
				' <b style="color: #8145e9">' + formatDate(infoDateStart, true, '', true) + '</b> a <b style="color: #8145e9">' + formatDate(infoDateEnd, true, '', true) + '</b>' +
				'</span>',
				2,
				5
			);
		}
		doc.setFontSize(8);
		doc.text('Emitido pelo sistema VENDERGAS em ' + formatDate(today, true, undefined, false) + ' (' + weekNames[today.getDay()] + ') ', 3, 16);

		doc.setFontSize(18);
		doc.setFontStyle('bold');
		doc.text(pageName, pageWidth / 2, 30, 'center');

		doc.setFontSize(12);

		let estabelecimentoName = '--';

		const est = estabelecimento || this.getEstabelecimentoOnCache();

		if (!!est) {

			if (est.allSelected) {
				estabelecimentoName = 'Todos';
			} else {

				if (!!est.empresa) {

					estabelecimentoName = est.empresa.nomeFantasia;
				} else if (!!est.loja) {

					estabelecimentoName = est.loja.nomeFantasia;
				} else if (!!est.multiSelecao) {

					for (const _e of est.multiSelecao) {

						if (!!_e.empresa) {

							estabelecimentoName = (estabelecimentoName == '--' ? _e.empresa.nomeFantasia : estabelecimentoName + ', ' + _e.empresa.nomeFantasia);
						} else if (!!_e.loja) {

							estabelecimentoName = (estabelecimentoName == '--' ? _e.loja.nomeFantasia : estabelecimentoName + ', ' + _e.loja.nomeFantasia);
						}
					}
				}
			}
		}

		doc.setFontStyle('normal');

		let titleStartY = 38;

		/* Configura os titulos das informações adicionais */
		if (!!aditionalInfoOnHeader) {
			for (const adInfo of aditionalInfoOnHeader) {
				doc.text(adInfo.title + ': ', 3, titleStartY);
				titleStartY += 6;
			}
		}

		doc.text('Estabelecimento: ', 3, titleStartY);

		titleStartY += 6;

		doc.text('Usuário: ', 3, titleStartY);

		doc.setFontStyle('bold');

		let infoStartY = 38;

		/* Configura as informações adicionais */
		if (!!aditionalInfoOnHeader) {
			for (const adInfo of aditionalInfoOnHeader) {
				doc.text(adInfo.info, 30, infoStartY);
				infoStartY += 6;
			}
		}

		doc.text(estabelecimentoName, 37, infoStartY); // Estabelecimento
		infoStartY += 6;
		doc.text(($getUserInfo() || { nome: '--' }).nome, 20, infoStartY); // Usuario

		// doc.fromHTML('<span style="font-family: Sans-Serif; font-size: 30px; color: #414141;">' + pageName + '</span>', 12.5, 23);
		// doc.fromHTML('<span style="font-family: Sans-Serif; font-size: 14px; color: #414141;">Tabela exportada em' + ' <b style="color: #8145e9">' + formatDate(today) + '</b></span>', 13, 36);

		/*
		if (!!infoDateStart && !!infoDateEnd) {
			doc.fromHTML('<span style="font-family: Sans-Serif; font-size: 14px; color: #414141;">Informações referentes a data de' +
				' <b style="color: #8145e9">' + formatDate(infoDateStart) + '</b> a <b style="color: #8145e9">' + formatDate(infoDateEnd) + '</b>' +
				'</span>', 13, 41);
		}
		 */
	}

	public blobToBase64(blob: Blob, callback?: (base64) => void) {
		const reader = new FileReader();
		reader.onload = function () {
			let dataUrl = reader.result;
			let base64 = (dataUrl as string).split(',')[1];
			if (callback != null) callback(base64);
		};
		reader.readAsDataURL(blob);
	}

	public isProprietario(usuario: Usuario) {
		return /propriet[aá]rio/i.test(usuario.role || '');
	}

	public exportAsPDFFile(
		pageName,
		PDFFileName,
		jsonData,
		infoDateStart,
		infoDateEnd,
		print: boolean = false,
		aditionalData: { jsonData, position: string } = null,
		col = null,
		aditionalInfoOnHeader: { title, info }[] = [],
		estabelecimento: Estabelecimento = null,
		hideHeader: boolean = false,
		orientation?: 'horizontal' | 'vertical',
		verifySizeOnPrint: boolean = false,
		fontSize = 8
	): void {

		if (!!jsonData && jsonData.length > 0) {
			// const doc = new jsPDF('l', 'mm', 'a4');
			const doc = new jsPDF(orientation === 'horizontal' ? 'l' : 'p', 'mm', 'a4');

			const tableData = this.generateColAndRows(jsonData);

			this.generateHeaderPDF(doc, pageName, infoDateStart, infoDateEnd, aditionalInfoOnHeader, estabelecimento);

			const pageHeight = 55;

			/** Body */
			doc.autoTable({
				// startY: doc.autoTableEndPosY() + 0.2,
				startY: pageHeight + 5,
				styles: { overflow: 'linebreak', cellWidth: 'auto', fontSize: fontSize },
				headStyles: {
					fillColor: [255, 255, 255],
					textColor: [0, 0, 0],
				},
				showHead: hideHeader ? 'never' : 'everyPage',
				showFoot: 'never',
				columnStyles: { text: { cellWidth: 'auto' } },
				margin: { left: 3, right: 3 },
				head: [col || tableData.col],
				body: tableData.rows,
				didDrawPage: (hookData) => {
					this.drawPageNumber(hookData, doc);
				}
			});

			if (!!aditionalData && !!aditionalData.jsonData && aditionalData.position == 'bottom') {

				doc.setDrawColor(0, 0, 0);
				doc.line(3, doc.autoTableEndPosY(), 207, doc.autoTableEndPosY());

				const aditionalTableData = this.generateColAndRows(aditionalData.jsonData);

				doc.autoTable({
					theme: 'plain',
					startY: doc.autoTableEndPosY() + 0.2,
					margin: { left: 3, right: 3 },
					styles: { overflow: 'linebreak', cellWidth: 'auto', fontSize: fontSize },
					showHead: 'never',
					showFoot: 'never',
					headStyles: { fillColor: [129, 69, 233] },
					bodyStyles: { fontStyle: 'bold' },
					columnStyles: { text: { cellWidth: 'auto' } },
					head: [aditionalTableData.col],
					body: aditionalTableData.rows,
					didDrawPage: (hookData) => {
						this.drawPageNumber(hookData, doc);
					}
				});
			}

			if (print) {
				let output = doc.output('datauristring');

				let size = 0;

				try {
					let binary = atob(output.split(',')[1]);
					let array = [];
					for (let i = 0; i < binary.length; i++) array.push(binary.charCodeAt(i));
					let file = new Blob([new Uint8Array(array)], { type: 'application/pdf' });
					size = (file.size / 1048576);
				} catch (err) {
					console.log(err);
				}

				if (verifySizeOnPrint && size > 1.5) {
					this._confirm(
						`O arquivo de impressão(${(size || 0).toFixed(2)} MB) ultrapassa o tamanho limite(1.5 MB) para ser exibido. Deseja baixar o arquivo?`,
						'Baixar',
						'Fechar'
					).then(res => {
						if (res) {
							doc.save((PDFFileName || Date.now()) + '_VENDERGAS' + '.pdf');
						}
					}).catch(err => console.log(err));
				} else {
					// doc.autoPrint();
					// window.open(doc.output('bloburl'), '_blank');
					this.renderPDFInDocument(output);
				}
			} else {
				doc.save((PDFFileName || Date.now()) + '_VENDERGAS' + '.pdf');
			}
		}
	}

	public exportAsPDFFileFromMultipleTable(pageName,
		PDFFileName,
		dataArray: { jsonData: any[], col: any, aditionalData: { jsonData, position: string }, hideHeader?: boolean }[],
		infoDateStart,
		infoDateEnd,
		print: boolean = false,
		aditionalData: { jsonData, position: string } = null,
		aditionalInfoOnHeader: { title, info }[] = [],
		isColumnDynamic: boolean = false,
		fontSize = 8
	): void {

		if (!!dataArray) {
			// const doc = new jsPDF('l');
			const doc = new jsPDF('p', 'mm', 'a4');

			this.generateHeaderPDF(doc, pageName, infoDateStart, infoDateEnd, aditionalInfoOnHeader);

			const pageHeight = 55;

			let count = 0;

			for (const da of dataArray) {

				const tableData = this.generateColAndRows(da.jsonData, isColumnDynamic);

				/** Body */
				doc.autoTable({
					// startY: doc.autoTableEndPosY() + 0.2,
					startY: (count > 0 ? doc.autoTableEndPosY() : pageHeight) + 5,
					styles: { overflow: 'linebreak', cellWidth: 'auto', fontSize: fontSize },
					headStyles: {
						fillColor: [255, 255, 255],
						textColor: [0, 0, 0]
					},
					showHead: da.hideHeader == true ? 'never' : 'everyPage',
					showFoot: 'never',
					columnStyles: { text: { cellWidth: 'auto' } },
					margin: { left: 3, right: 3 },
					head: [da.col || tableData.col],
					body: tableData.rows,
					didDrawPage: (hookData) => {
						this.drawPageNumber(hookData, doc);
					}
				});

				if (!!da.aditionalData && !!da.aditionalData.jsonData && da.aditionalData.position == 'bottom') {

					doc.setDrawColor(0, 0, 0);
					doc.line(3, doc.autoTableEndPosY(), 207, doc.autoTableEndPosY());

					const aditionalTableData = this.generateColAndRows(da.aditionalData.jsonData);

					doc.autoTable({
						theme: 'plain',
						startY: doc.autoTableEndPosY() + 0.2,
						margin: { left: 3, right: 3 },
						styles: { overflow: 'linebreak', cellWidth: 'auto', fontSize: fontSize },
						showHead: da.hideHeader == true ? 'never' : 'everyPage',
						showFoot: 'never',
						headStyles: { fillColor: [129, 69, 233] },
						bodyStyles: { fontStyle: 'bold' },
						columnStyles: { text: { cellWidth: 'auto' } },
						head: [aditionalTableData.col],
						body: aditionalTableData.rows,
						didDrawPage: (hookData) => {
							this.drawPageNumber(hookData, doc);
						}
					});
				}

				++count;
			}

			if (!!aditionalData && !!aditionalData.jsonData && aditionalData.position == 'bottom') {

				doc.setDrawColor(0, 0, 0);
				doc.line(3, doc.autoTableEndPosY(), 207, doc.autoTableEndPosY());

				const aditionalTableData = this.generateColAndRows(aditionalData.jsonData);

				doc.autoTable({
					theme: 'plain',
					startY: doc.autoTableEndPosY() + 0.2,
					margin: { left: 3, right: 3 },
					styles: { overflow: 'linebreak', cellWidth: 'auto', fontSize: fontSize },
					showHead: 'never',
					showFoot: 'never',
					headStyles: { fillColor: [129, 69, 233] },
					bodyStyles: { fontStyle: 'bold' },
					columnStyles: { text: { cellWidth: 'auto' } },
					head: [aditionalTableData.col],
					body: aditionalTableData.rows,
					didDrawPage: (hookData) => {
						this.drawPageNumber(hookData, doc);
					}
				});
			}

			if (print) {
				// doc.autoPrint();
				// window.open(doc.output('bloburl'), '_blank');
				this.renderPDFInDocument(doc.output('datauristring'));
			} else {
				doc.save((PDFFileName || Date.now()) + '_VENDERGAS' + '.pdf');
			}
		}
	}

	private getImgFromUrl(logo_url, callback) {
		const img = new Image();
		img.src = logo_url;
		img.onload = function () { callback(img); };
	}

	/* tslint:disabled */
	calcularTroco(pedido: Pedido): string {
		if (pedido.origem_app == true) {
			return pedido.troco.toFixed(2);
		}
		else {
			const pagDinheiro = pedido.pagamentos.find(pag => pag.tipoPagamento == TipoPagamento.DINHEIRO);
			if (pedido != null && pedido.troco && pedido.valorTotal != null && pagDinheiro) {
				return (pedido.troco - pagDinheiro.valor).toFixed(2);
			}
		}

		return '0.00';
	}

	sortArrayByKey(array, key) {
		return array.sort(function (a, b) {
			let x = a[key];
			let y = b[key];
			return ((x < y) ? -1 : ((x > y) ? 1 : 0));
		});
	}

	sortJSON(obj) {
		const r = {};
		for (const k of Object.keys(obj)) { r[k] = obj[k]; }
		return r;
	}

	formatEntregadorPedidosEmAndamento(entr: Entregador) {
		entr.pedidosEmAndamento = 0;
		for (const ped of (entr.pedidos || [])) {
			// if (ped.status != PedidoStatus.PEDIDO_ENTREGUE) { ++entr.pedidosEmAndamento; }
			if (
				ped.status == PedidoStatus.PEDIDO_REPASSADO ||
				ped.status == PedidoStatus.PEDIDO_EM_TRANSITO ||
				ped.status == PedidoStatus.PEDIDO_NOVO ||
				ped.status == PedidoStatus.PEDIDO_PENDENTE
			) { ++entr.pedidosEmAndamento; }
		}
	}

	getQuantityFromEstoquesToUpdate(pedido: Pedido, produto: Produto, tipoEstoque: TipoEstoque = null) {
		if (pedido && pedido.estoquesToUpdate && produto && produto.idProduto) {
			for (const est of (pedido.estoquesToUpdate || [])) {
				if (produto.idProduto == est.idProduto) {
					if (tipoEstoque == null || (tipoEstoque && est.estoqueSelecionado.tipoEstoque == tipoEstoque)) {
						return est.quantidade;
					}
				}
			}
		}

		return 0;
	}

	getValueFromEstoquesToUpdate(pedido: Pedido, produto: Produto, tipoEstoque: TipoEstoque = null) {
		if (pedido && pedido.estoquesToUpdate && produto && produto.idProduto) {
			for (const est of (pedido.estoquesToUpdate || [])) {
				if (produto.idProduto == est.idProduto) {
					let valorEstoque = (est.estoqueSelecionado.tipoEstoque == TipoEstoque.ESTOQUE_VAZIO ? produto.valorVazio : produto.valorCheio);
					if (tipoEstoque == null || (tipoEstoque && est.estoqueSelecionado.tipoEstoque == tipoEstoque)) {

						return ((est.valorNegociado || est.valorCompleto || valorEstoque || 0) * est.quantidade);
					}
				}
			}
		}

		return 0;
	}

	getValueFromEstoquesToUpdateWithoutOrder(estoquesToUpdate: any, produto: Produto, tipoEstoque: TipoEstoque = null) {
		let result = 0;

		if (estoquesToUpdate && produto && produto.idProduto) {
			for (const est of (estoquesToUpdate || [])) {
				if (est.idProduto == produto.idProduto) {
					if (tipoEstoque == null || (tipoEstoque && est.estoqueSelecionado.tipoEstoque == tipoEstoque)) {
						let valorEstoque = (est.estoqueSelecionado.tipoEstoque == TipoEstoque.ESTOQUE_VAZIO ? produto.valorVazio : produto.valorCheio);
						return (est.valorNegociado || est.valorCompleto || valorEstoque || 0);
					}
				}
			}
		}

		return result;
	}

	getQuantityFromEstoquesToUpdateWithoutOrder(estoquesToUpdate: any, produto: Produto, tipoEstoque: TipoEstoque = null) {
		let result = 0;

		if (estoquesToUpdate && produto && produto.idProduto) {
			for (const est of (estoquesToUpdate || [])) {
				if (est.idProduto == produto.idProduto) {
					if (tipoEstoque == null || (tipoEstoque && est.estoqueSelecionado.tipoEstoque == tipoEstoque)) {
						result += (est.quantidade || 0);
					}
				}
			}
		}

		return result;
	}

	getTotalValueFromEstoquesToUpdateWithoutOrder(estoquesToUpdate: any, produto: Produto, tipoEstoque: TipoEstoque = null) {
		let result = 0;

		if (estoquesToUpdate && produto && produto.idProduto) {

			if (estoquesToUpdate && produto && produto.idProduto) {
				for (const est of (estoquesToUpdate || [])) {
					if (est.idProduto == produto.idProduto) {
						if (tipoEstoque == null || (tipoEstoque && est.estoqueSelecionado.tipoEstoque == tipoEstoque)) {
							let valorEstoque = (est.estoqueSelecionado.tipoEstoque == TipoEstoque.ESTOQUE_VAZIO ? produto.valorVazio : produto.valorCheio);

							let value = (est.valorNegociado || est.valorCompleto || valorEstoque || 0);
							let qntd = (est.quantidade || 0);

							result += (value * qntd);
						}
					}
				}
			}
		}

		return result;
	}

	getOptionsEstabelecimentos() {
		const estabelecimentos = $getEstabelecimentoOnCache();
		const multiEmpresa = ((estabelecimentos || { multiSelecao: undefined }).multiSelecao || []).filter(e => e.tipoEstabelecimento == 'empresa').map(e => ({ value: e.empresa._id, text: e.empresa.nomeFantasia, tipo: 'empresa' }));
		const multiLoja = ((estabelecimentos || { multiSelecao: undefined }).multiSelecao || []).filter(e => e.tipoEstabelecimento == 'loja').map(e => ({ value: e.loja._id, text: e.loja.nomeFantasia, tipo: 'loja' }));
		return multiEmpresa.concat(multiLoja);
	}

	isSummaryRow(index, item): boolean { return (item || {}).isSummaryRow; }

	/**
	 * Checa se um item é um grupo. Função usada na propriedade 'when' em mat-table.
	 *
	 * @param index
	 * @param item
	 */
	isGroup(index, item): boolean { return (item || {}).isGroupBy; }

	/**
	 * Checa se um item é um entregador. Função usada na propriedade 'when' em mat-table.
	 *
	 * @param index
	 * @param item
	 */
	isSubHeaderRow(index, item): boolean { return (item || {}).isSubHeaderRow; }

	/**
	 * Checa se um item é um header de produto. Função usada na propriedade 'when' em mat-table.
	 *
	 * @param index
	 * @param item
	 */
	isProductHeader(index, item): boolean { return (item || {}).isNameProduct; }

	/**
	 * Checa se um item é um header. Função usada na propriedade 'when' em mat-table.
	 *
	 * @param index
	 * @param item
	 */
	isHeader(index, item): boolean { return (item || {}).isHeaderRow; }

	/**
	 * Formata os nomes das colunas para um header no mat-table.
	 *
	 * @param {string[]} columns - Colunas
	 */
	getColumnsHeaderName(columns: string[]): string[] {
		let result = [];
		if (columns) {
			for (let column of columns) result.push(`${column}-header`);
		}
		return result;
	}

	/**
	 * Checa se um item é um total de grupo. Função usada na propriedade 'when' em mat-table.
	 *
	 * @param index
	 * @param item
	 */
	isGroupTotal(index, item): boolean { return (item || {}).isGroupTotal; }

	isGroupTotalProdutos(index, item): boolean { return (item || {}).isTotalProdutos; }

	isTotalGeral(index, item): boolean { return (item || {}).isTotalGeral; }

	isGroupCusto(index, item): boolean { return (item || {}).isGroupCusto; }

	isGroupProdutos(index, item): boolean { return (item || {}).isGroupProdutos; }

	isGroupTotalSaldo(index, item): boolean { return (item || {}).isGroupSaldoTotal; }

	/**
	 * Formata um objeto de erro.
	 * @param _err - Erro a ser formatado
	 * @return ResultObject
	 */
	formatError(_err): ResultObject<any> {
		return {
			title: (_err.error || {} as any).title || (_err.statusText === 'Unauthorized' ? 'Não Autorizado' : _err.statusText) || _err.error || _err,
			message: (_err.error || {} as any).message || (_err.error === 'Unauthorized' ? 'Não Autorizado' : _err.error) || _err.message || '',
			success: (_err.error || {} as any).success,
			showAsInfo: (_err.error || {} as any).success
		} as ResultObject<any>;
	}

	alertError(_err, callback?: () => void, logErrorOnConsole: boolean = true) {
		let ef = this.formatError(_err);
		this._alert(ef.title, ef.message);
		if (logErrorOnConsole) console.log(_err);
		if (callback) callback();
	}

	fixValueToOperation(value: number): number {
		return value > 0 ? value : 1;
	}

	popularRelatorioPagamentoObject(
		pagamentoObj: {
			produtos: Produto[],
			produtoQuantidade: any[],
			tipoEstoque: any[],
			valorTotal: number
		},
		v: Venda | { pedido: Pedido },
		valorPagamento: number,
		separateProdutosByEstoque: boolean = false
	) {

		pedidosIt: for (const prod of v.pedido.produtos) {

			let idProd = prod.idProduto.toString();

			if (pagamentoObj.produtoQuantidade[idProd] == null) pagamentoObj.produtoQuantidade[idProd] = 0;

			pagamentoObj.produtoQuantidade[idProd] += parseInt(v.pedido.produtoQuantidade[idProd] || 0);
			pagamentoObj.tipoEstoque[idProd] = parseInt(v.pedido.tipoProdutoEstoque[idProd] || 0);

			for (const prodArray of pagamentoObj.produtos) {
				if (prod.idProduto == prodArray.idProduto) continue pedidosIt;
			}

			pagamentoObj.produtos.push(prod);
		}

		if (separateProdutosByEstoque) pagamentoObj.produtos = this.separarProdutoPorTipoDeEstoque(v.pedido.produtos);

		pagamentoObj.produtoQuantidade = (v.pedido.produtoQuantidade);
		pagamentoObj.tipoEstoque = (v.pedido.tipoProdutoEstoque);

		pagamentoObj.valorTotal += Number(valorPagamento || 0);
	}

	compareArrays(source: any[], array: any[]): boolean {
		if (!array) return false;
		if (source.length != array.length) return false;
		for (let i = 0, l = source.length; i < l; i++) {
			if (source[i] instanceof Array && array[i] instanceof Array) {
				if (!source[i].equals(array[i])) return false;
			} else if (source[i] != array[i]) return false;
		}
		return true;
	}

	getNomeEmpresaPrint(emp: Empresa, empList: Empresa[], loj: Loja, lojList: Loja[], customTitle: string): string {
		let result = '';
		if (emp != null) result = result + (emp.nomeFantasia || '');
		if (loj != null) result = result + ((result || '').length > 0 ? ' ,' : '') + (loj.nomeFantasia || '');
		(empList || []).forEach((e, i) => result = result + ((result || '').length > 0 ? ' ,' : '') + (e.nomeFantasia || ''));
		(lojList || []).forEach((l, i) => result = result + ((result || '').length > 0 || (empList || []).length > 0 ? ' ,' : '') + (l.nomeFantasia || ''));
		if (customTitle != null) result = result + ((result || '').length > 0 ? ' ,' : '') + customTitle;
		return result;
	}

	groupObjectsByEstabelecimento<T>(
		objects: T[] | any[],
		calculateTotalValueFrom: string[] = [],
		juntarEstabelecimento: boolean = false
	): ({ empresa: Empresa, loja: Loja, isGroupBy: boolean, customTitle: string } | T)[] {

		/** Inicia o resultJSON com um objeto para os objetos sem associação */
		const resultJson = {
			defaultObject: {
				objects: [],
				empresa: null,
				loja: null,
				customTitle: 'Sem Associação'
			}
		};

		const resultArray = [];

		for (const _obj of (objects || [])) {
			if ((_obj.lojas || []).length < 1 && !!_obj.loja) { _obj['lojas'] = [_obj.loja]; }
			if ((_obj.empresas || []).length < 1 && !!_obj.empresa) { _obj['empresas'] = [_obj.empresa]; }
		}

		let empresasNomes = [];
		let lojasNomes = [];

		/* Itera o array de objetos para separar os estabelecimentos por objeto */
		for (const _obj of (objects || [])) {

			/**
			 * Separa o resultado por estabelecimentos
			 */

			/* Empresas */
			for (const emp of (_obj.empresas || [])) {
				if (emp) {
					const nomeEmpresa = (emp.nomeFantasia || '').replace(/\s/g, '').toLowerCase() + (emp.idEmpresa || emp._id || '');
					if (empresasNomes.find(en => en.nome == nomeEmpresa) == null) empresasNomes.push({ nome: nomeEmpresa, empresa: this.cloneJSON(emp) });
				}
			}

			/* Lojas */
			for (const loj of (_obj.lojas || [])) {
				if (loj) {
					const nomeLoja = (loj.nomeFantasia || '').replace(/\s/g, '').toLowerCase() + (loj.idLoja || loj._id || '');
					if (lojasNomes.find(ln => ln.nome == nomeLoja) == null) lojasNomes.push({ nome: nomeLoja, loja: this.cloneJSON(loj) });
				}
			}
		}

		let estabelecimentos_concat = empresasNomes.concat(lojasNomes);

		estabelecimentos_concat.sort((a, b) => {
			if (a.nome > b.nome) return 1;
			if (a.nome < b.nome) return -1;
			return 0;
		});

		estabelecimentos_concat.forEach(es => {
			if (resultJson[es.nome] == null) {
				resultJson[es.nome] = {
					objects: [],
					empresa: es.empresa != null ? this.cloneJSON(es.empresa) : null,
					loja: es.loja != null ? this.cloneJSON(es.loja) : null
				};
			}
		});

		for (const _obj of (objects || [])) {

			/**
			 * Popula o resultado
			 */

			/* Empresas */
			for (const emp of (_obj.empresas || [])) {
				if (emp) {
					const nomeEmpresa = (emp.nomeFantasia || '').replace(/\s/g, '').toLowerCase() + (emp.idEmpresa || emp._id || '');
					resultJson[nomeEmpresa].objects.push(this.cloneJSON({ ..._obj, estId: emp._id }));
				}
			}

			/* Lojas */
			for (const loj of (_obj.lojas || [])) {
				if (loj) {
					const nomeLoja = (loj.nomeFantasia || '').replace(/\s/g, '').toLowerCase() + (loj.idLoja || loj._id || '');
					resultJson[nomeLoja].objects.push(this.cloneJSON({ ..._obj, estId: loj._id }));
				}
			}

			/**
			 * Caso o produto não tenha loja/empresa, popula para o objeto inicial
			 */
			if ((_obj.lojas || []).length < 1 && (_obj.empresas || []).length < 1) {
				resultJson.defaultObject.objects.push(this.cloneJSON(_obj));
			}
		}

		/** Salva os objetos em um array */
		for (const key in resultJson) {

			if (key == 'defaultObject') {

				/* Não salva o objeto default no resultArray se ele estiver vazio */
				if ((resultJson[key].objects || []).length < 1) {
					continue;
				}
			}
			resultArray.push(resultJson[key]);
		}

		const formatedResult = [];

		if (juntarEstabelecimento) {
			let _estabelecimentos = [];

			for (const obj of objects) {

				let _estabelecimento_source = _estabelecimentos.find(est => {
					let includes = this.compareArrays((est.empresas || []).map(e => e._id || e.idEmpresa).sort(), (obj.empresas || []).map(e => e._id || e.idEmpresa).sort());
					if (includes) {
						includes = this.compareArrays((est.lojas || []).map(l => l._id || l.idLoja).sort(), (obj.lojas || []).map(l => l._id || l.idLoja).sort());
					}
					return includes;
				});

				if (_estabelecimento_source == null) {
					_estabelecimentos.push({
						objects: [obj],
						empresas: obj.empresas,
						lojas: obj.lojas
					});
				} else {
					_estabelecimento_source.objects.push(obj);
					_estabelecimento_source.empresas = (_estabelecimento_source.empresas || []).concat((obj.empresas || []).filter(emp => (_estabelecimento_source.empresas || []).find(_emp => (emp._id || emp.idEmpresa) == (_emp._id || _emp.idEmpreas)) == null)) || [];
					_estabelecimento_source.lojas = (_estabelecimento_source.lojas || []).concat((obj.lojas || []).filter(emp => (_estabelecimento_source.lojas || []).find(_emp => (emp._id || emp.idLoja) == (_emp._id || _emp.idLoja)) == null)) || [];
				}
			}

			/** Resultados sem estabelecimento */
			if (resultJson != null && resultJson.defaultObject != null && (resultJson.defaultObject.objects || []).length > 0) {
				formatedResult.push({ customTitle: resultJson.defaultObject.customTitle, empresas: null, lojas: null, isGroupBy: true });
				(resultJson.defaultObject.objects || []).forEach(o => formatedResult.push(o));
			}

			_estabelecimentos.forEach(est => {
				formatedResult.push({
					customTitle: est.customTitle,
					empresas: est.empresas,
					lojas: est.lojas,
					isGroupBy: true
				});
				est.objects.forEach(o => formatedResult.push(o));
				if (calculateTotalValueFrom && calculateTotalValueFrom.length > 0) {
					formatedResult.push({
						...this.getTotalFromColums(est.objects, null, calculateTotalValueFrom),
						isGroupTotal: true,
						empresas: est.empresas,
						lojas: est.lojas
					});
				}
			});
		} else {

			/** Cria um array com o header do objeto seguido pelos results do header */
			for (const obj of resultArray) {
				formatedResult.push({
					customTitle: obj.customTitle,
					empresa: obj.empresa,
					loja: obj.loja,
					isGroupBy: true
				});

				for (const o of obj.objects) { formatedResult.push(o); }

				if (calculateTotalValueFrom && calculateTotalValueFrom.length > 0) {
					formatedResult.push({
						...this.getTotalFromColums(obj.objects, null, calculateTotalValueFrom),
						isGroupTotal: true,
						empresa: obj.empresa,
						loja: obj.loja
					});
				}
			}
		}


		return formatedResult;
	}

	generateUniqueId(randomLettersLength = 4): string { return getRandomLetter(randomLettersLength) + Date.now(); }

	/**
	 * Recebe um array de produtos e separa os produtos baseado no tipo de estoque selecionado
	 * nos pedidos desse produto.
	 *
	 * @param {Produto[]} produtos - produtos a serem separados por estoque
	 * @param {boolean} generateUniqueId - Se um id unico deve ser gerado para cada produto
	 * @param {boolean} separarPedidos - Se deve separar os pedidos por produto
	 * @param {boolean} ignoreIsInUse - Se deve ignorar a validação de estoque em uso
	 */
	separarProdutoPorTipoDeEstoque(produtos: Produto[], generateUniqueId: boolean = false, separarPedidos: boolean = true, ignoreIsInUse: boolean = false): Produto[] {

		const produtosFormatedByEstoque: Produto[] = [];

		for (const prod of produtos) {

			itEstoques: for (const est of prod.estoques) {

				if (est.isInUse || ignoreIsInUse) {

					for (const prodF of produtosFormatedByEstoque) {
						if (prodF.idProduto == prod.idProduto && prodF.tipoEstoqueToRender == est.tipoEstoque) { break itEstoques; }
					}

					const prodCloned = this.cloneJSON(prod);
					prodCloned.tipoEstoqueToRender = est.tipoEstoque;
					produtosFormatedByEstoque.push(prodCloned);
				}
			}
		}

		if (separarPedidos) this.separarPedidosPorTipoDeEstoqueNoProduto(produtosFormatedByEstoque);

		if (generateUniqueId) {
			for (const _p of produtosFormatedByEstoque) {
				_p._uniqueId = getRandomLetter(4) + Date.now();
			}
		}

		return produtosFormatedByEstoque;
	}

	// Checa se o usuário tem perrmissão para acessar a rota.
	checkPermission(option): boolean {
		let isPermited = false;
		let isVisible = true;

		const userInfo = TokenStorage.retrieveUserInfo();

		if (userInfo == null) return false;

		const rotasFront = userInfo.permissoes.paginas;
		const rotasFront_estabelecimento = (userInfo.permissoes_estabelecimento || <Permissoes>{}).paginas;

		if (option.visibleOnlyToManager == true) isVisible = (userInfo.isUserGroupOwner == true || userInfo.isAdmin == true);

		if (rotasFront) {
			for (const p of rotasFront) {
				if (p == '*' || p == option.pageId || option.pageId === this.IGNORE_PERMISSION) {
					isPermited = true;
					break;
				}
			}
		}

		if (isPermited && rotasFront_estabelecimento && userInfo.isAdmin != true) {
			isPermited = false;
			for (const p of rotasFront_estabelecimento) {
				if (p == '*' || p == option.pageId || option.pageId === this.IGNORE_PERMISSION) {
					isPermited = true;
					break;
				}
			}
		}

		return isPermited && isVisible;
	}

	/**
	 * Recebe um array de produtos e separa os pedidos baseado no 'tipoEstoqueToRender'
	 * do produto. Caso a propriedade 'tipoEstoqueToRender' for null, os pedidos não são
	 * filtrados.
	 *
	 * @param produtos
	 */
	separarPedidosPorTipoDeEstoqueNoProduto(produtos: Produto[]): void {

		for (const prod of produtos) {

			if (!!prod.pedidos) {

				const _pedidosFormated = [];

				for (const ped of prod.pedidos) {

					const clonedPed = this.cloneJSON(ped);

					if ((clonedPed.estoquesToUpdate || []).length > 0) {

						/** Separa o estoqueToUpdate */
						const _resultEstoqueToUpdate = [];
						for (const est of clonedPed.estoquesToUpdate) {

							if (est.estoqueSelecionado.tipoEstoque == prod.tipoEstoqueToRender) {
								_resultEstoqueToUpdate.push(this.cloneJSON(est));
							}
						}
						clonedPed.estoquesToUpdate = _resultEstoqueToUpdate;

						/** Separa os pedidos por tipo de estoque */
						for (const est of clonedPed.estoquesToUpdate) {

							if (est.idProduto == prod.idProduto && est.estoqueSelecionado.tipoEstoque == prod.tipoEstoqueToRender) {

								// clonedPed.valorTotal = clonedPed.valorTotal / est.quantidade;
								clonedPed.valorTotal = (est.valorNegociado || est.valorNegociado || 0) * est.quantidade;

								if (clonedPed.venda) clonedPed.venda.valorTotal = clonedPed.valorTotal;

								_pedidosFormated.push(clonedPed);
							}
						}
					} else {
						_pedidosFormated.push(clonedPed);
					}
				}

				prod.pedidos = _pedidosFormated;
			}
		}
	}

	/**
	 * Configura uma função para gerar dados no front.
	 *
	 * @param dummyData
	 * @param objectToPopulate
	 * @param callback
	 */
	setupGenerateFunction(dummyData: any[] = [], objectToPopulate: any = {}, callback = null) {
		window['generateData'] = () => {
			let obj = dummyData[Math.floor(Math.random() * dummyData.length)];
			for (const key in objectToPopulate) {
				if (obj[key]) { objectToPopulate[key] = obj[key]; }
			}
			if (callback) { callback(); }
		};
	}

	/**
	 * Limpa o objeto de endereço para envio em requisição.
	 *
	 * @param {Endereco | Object} endObject - objeto do Endereco.
	 */
	clearEnderecoObject(endObject: Endereco | any) {
		if (endObject == null) return;
		delete endObject.historicoAcaos;
		delete endObject.empresa;
		delete endObject.empresas;
		delete endObject.loja;
		delete endObject.lojas;
		delete endObject.empresaIdEmpresa;
		delete endObject.lojaIdLoja;
		delete endObject.grupo;
		delete endObject.grupoIdGrupo;
		delete endObject.entregador;
		delete endObject.entregadorIdEntregador;
		delete endObject.cliente;
		delete endObject.clienteIdCliente;
		delete endObject.usuario;
		delete endObject.usuarioIdUsuario;
	}

	/**
	 * Limpa os objetos de loja para envio em requisição.
	 *
	 * @param {Loja | Loja[]} lojaObject - objeto da loja
	 * @param {boolean} deleteAll - se exclui todas as informções do oject
	 */
	clearLojaObject(lojaObject: Loja | Loja[], deleteAll: boolean = false) {
		const $clearObject = (obj) => {
			if (obj == null) return;

			// delete obj.nomeFantasia;
			// delete obj.razaoSocial;
			// delete obj.isAtivo;

			if (deleteAll) {
				delete obj.enderecos;
				delete obj.nomeDistribuidora;
				delete obj.cnpj;
				delete obj.inscricaoEstadual;
			}

			for (const end of (obj.enderecos || [])) { this.clearEnderecoObject(end); }

			delete obj.pedidos;
			delete obj.usuarios;
			delete obj.entregadors;
			delete obj.clientes;
			delete obj.estoques;
			delete obj.movimentacaoEstoques;
			delete obj.produtos;
			delete obj.fornecedors;
			delete obj.veiculos;
			delete obj.vendas;
			delete obj.enderecoDefault;
			delete obj.certificadoPassword;
			delete obj.certificadoPath;
			delete obj.contas;
			delete obj.createdAt;
			delete obj.grupos;
			delete obj.historicoAcaos;
			delete obj.inscricaoMunicipal;
			delete obj.nomeContato;
			delete obj.nomeResponsavelLegal;
			delete obj.nomeResponsavelLegalSegundo;
			delete obj.notaAvariadas;
			delete obj.notificacaos;
			delete obj.numero;
			delete obj.taxaTransacaoCartaos;
			delete obj.telefone;
			delete obj.telefoneContatoAtendimento;
			delete obj.telefoneContatoAtendimentoFixo;
			delete obj.telefoneResponsavelLegal;
			delete obj.telefoneResponsavelLegalFixo;
			delete obj.telefoneResponsavelLegalFixoSegundo;
			delete obj.telefoneResponsavelLegalSegundo;
			delete obj.updatedAt;
			delete obj.valorNegociados;
			delete obj.empresa;
			delete obj.contaDespesas;
			delete obj.despesas;
			delete obj.usuarioFuncaos;
		};

		if (Array.isArray(lojaObject)) {
			lojaObject.forEach((loj) => $clearObject(loj));
		} else {
			$clearObject(lojaObject);
		}
	}

	/**
	 * Limpa os objetos de empresa para envio em requisição.
	 *
	 * @param {Empresa | Empresa[]} empresaObject - objeto da empresa
	 * @param {boolean} deleteAll - se exclui todas as informções do oject
	 */
	clearEmpresaObject(empresaObject: Empresa | Empresa[], deleteAll: boolean = false) {
		const $clearObject = (obj) => {
			if (obj == null) return;

			// delete obj.isAtivo;
			// delete obj.nomeFantasia;
			// delete obj.razaoSocial;

			if (deleteAll) {
				delete obj.enderecos;
				delete obj.nomeDistribuidora;
				delete obj.cnpj;
				delete obj.inscricaoEstadual;
			}

			for (const end of (obj.enderecos || [])) { this.clearEnderecoObject(end); }

			delete obj.pedidos;
			delete obj.usuarios;
			delete obj.entregadors;
			delete obj.clientes;
			delete obj.estoques;
			delete obj.movimentacaoEstoques;
			delete obj.produtos;
			delete obj.fornecedors;
			delete obj.veiculos;
			delete obj.vendas;
			delete obj.enderecoDefault;
			delete obj.certificadoPassword;
			delete obj.certificadoPath;
			delete obj.contas;
			delete obj.createdAt;
			delete obj.grupos;
			delete obj.historicoAcaos;
			delete obj.inscricaoMunicipal;
			delete obj.nomeContato;
			delete obj.nomeResponsavelLegal;
			delete obj.nomeResponsavelLegalSegundo;
			delete obj.notaAvariadas;
			delete obj.notificacaos;
			delete obj.numero;
			delete obj.taxaTransacaoCartaos;
			delete obj.telefoneContatoAtendimento;
			delete obj.telefoneContatoAtendimentoFixo;
			delete obj.telefoneResponsavelLegal;
			delete obj.telefoneResponsavelLegalFixo;
			delete obj.telefoneResponsavelLegalFixoSegundo;
			delete obj.telefoneResponsavelLegalSegundo;
			delete obj.updatedAt;
			delete obj.valorNegociados;
			delete obj.contaDespesas;
			delete obj.despesas;
			delete obj.usuarioFuncaos;

			for (let loja of ((obj || {} as any).lojas || [])) {
				this.clearLojaObject(loja);
			}
		};

		if (Array.isArray(empresaObject)) {
			empresaObject.forEach((emp) => $clearObject(emp));
		} else $clearObject(empresaObject);
	}

	/**
	 * Instancia uma nova data.
	 */
	newDate(value: number | string | Date = Date.now()) {
		return new Date(value);
	}

	validateEmail(email) {
		const re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
		return re.test(String(email).toLowerCase());
	}

	parseInputValueToUppercase(selector: any, $event) {
		if (selector != null && $event != null) {
			selector.value = $event.target.value.toUpperCase();
		}
	}

	dragElement(dragContainer: HTMLElement, dragControll?: HTMLElement) {
		if (dragContainer == null) return;
		dragContainer['dragActive'] = true;
		let pos1 = 0, pos2 = 0, pos3 = 0, pos4 = 0;
		if (dragControll) dragControll.onmousedown = dragMouseDown;
		else dragContainer.onmousedown = dragMouseDown;
		function dragMouseDown(e) {
			e = e || window.event;
			e.preventDefault();
			pos3 = e.clientX;
			pos4 = e.clientY;
			document.onmouseup = closeDragElement;
			document.onmousemove = elementDrag;
		}
		function elementDrag(e) {
			e = e || window.event;
			e.preventDefault();
			pos1 = pos3 - e.clientX;
			pos2 = pos4 - e.clientY;
			pos3 = e.clientX;
			pos4 = e.clientY;
			dragContainer.style.top = (dragContainer.offsetTop - pos2) + 'px';
			dragContainer.style.left = (dragContainer.offsetLeft - pos1) + 'px';
			// dragContainer.style.right = 'none';
			// dragContainer.style.bottom = 'none';
		}
		function closeDragElement() {
			document.onmouseup = null;
			document.onmousemove = null;
		}
	}

	limparString(string: string) {
		return (string || '').toString().replace(/\s/g, '').toLowerCase().normalize('NFD').replace(/[\u0300-\u036f]/g, '');
	}

	getDefaultDateOnCache(applyTZCorrection?: boolean): { dataInicio: Date, dataFim: Date } {
		try {
			let dataDefault = JSON.parse(localStorage.getItem(TokenStorage.KEY_CACHE_DEFAULT_DATE));
			if (dataDefault != null && dataDefault.dataInicio != null && dataDefault.dataFim != null) {
				return {
					dataInicio: new Date(!isNaN(dataDefault.dataInicio) && applyTZCorrection == true ? dataDefault.dataInicio + TZ_CORRECTION : dataDefault.dataInicio),
					dataFim: new Date(!isNaN(dataDefault.dataFim) && applyTZCorrection == true ? dataDefault.dataFim + TZ_CORRECTION : dataDefault.dataFim),
				};
			}
		} catch (err) { }
		return {
			dataInicio: DATE_FILTER_TODAY_CORRECTION.dataInicio,
			dataFim: DATE_FILTER_TODAY_CORRECTION.dataFim
		};
	}

	setDefaultDateOnCache(dataInicio: Date, dataFim: Date) {
		if (dataInicio != null && dataFim != null) {
			localStorage.setItem(TokenStorage.KEY_CACHE_DEFAULT_DATE, JSON.stringify({
				// dataInicio: dataInicio.toISOString(), dataFim: dataFim.toISOString()
				dataInicio: dataInicio.getTime(), dataFim: dataFim.getTime()
			}));
		}
	}

	countDecimals(value: number, defaultSize: number = 2, maxSize: number = 4): number {
		if (Math.floor(value) === value) return defaultSize;
		let decimals = value.toString().split('.')[1].length || 0;
		if (decimals < defaultSize) decimals = defaultSize;
		if (decimals > maxSize) decimals = maxSize;
		return decimals;
	}

	toFixed(number: number): string | number {
		if (number != null) return number.toFixed(this.countDecimals(number));
		return number;
	}

	getFiadoStatusName(fiadoStatus: FiadoStatus): string {
		switch (fiadoStatus) {
			case FiadoStatus.PAGO: return 'Pago';
			case FiadoStatus.AGUARDANDO_PAGAMENTO: return 'Aguardando Pagamento';
			case FiadoStatus.VENCIDO: return 'Vencido';
			default: return fiadoStatus as any;
		}
	}

	getFiadoStatusColor(fiadoStatus: FiadoStatus, row = null, valorPago = null): string {
		switch (fiadoStatus) {
			case FiadoStatus.PAGO:
				if (row && valorPago < row.valorFiado && valorPago > 0) {
					return '#000';
				}
				return 'green';
			case FiadoStatus.AGUARDANDO_PAGAMENTO: return 'orange';
			case FiadoStatus.VENCIDO: return 'red';
			default: return '#000';
		}
	}

	copyObject(obj) {
		return JSON.parse(JSON.stringify(obj));
	}

	replaceIE: (value: string, onlyNumbers?: boolean) => string | any = (replaceIE, onlyNumbers = false) => replaceIE.replace(onlyNumbers ? /[^0-9]/g : /[^0-9./-]/g, '');

	paraCentavos: (value: number) => number = value => Math.round(value * 100);

	tryCatch(fn?: Function, onSuccess?: Function, onError?: Function) {
		try {
			if (fn) fn();
			if (onSuccess) onSuccess();
		} catch (err) {
			if (onError) onError(err);
		}
	}

	simplify(value: string): string {
		if (!value) return '';
		value = value.replace(/[áàâã]/gi, 'a');
		value = value.replace(/[éèêẽ]/gi, 'e');
		value = value.replace(/[íìîĩ]/gi, 'i');
		value = value.replace(/[óòôõ]/gi, 'o');
		value = value.replace(/[úùôõ]/gi, 'u');
		value = value.replace(/[ç]/gi, 'c');
		return value.toLocaleLowerCase();
	}

	cloneObj: (obj: any) => any = obj => obj != null ? JSON.parse(JSON.stringify(obj)) : null;

	toNumber(n: any) {
		return Number(n);
	}

	toDate(d: string, format: string) {
		return moment(d, format).toDate();
	}

	getClienteFromConvenio(convenio: Convenio) {
		if (!convenio) return null;
		const cliente = new Cliente();
		cliente.nome = convenio.nome;
		cliente.telefone = convenio.telefone;
		cliente.cnpj = convenio.cnpj;
		cliente.email = convenio.email;
		cliente.enderecoDefault = new Endereco()
		cliente.enderecoDefault.observacao = convenio.observacao;
		cliente.enderecoDefault.cep = convenio.cep;
		cliente.enderecoDefault.uf = convenio.uf;
		cliente.enderecoDefault.estadoAcronimo = convenio.uf;
		cliente.enderecoDefault.cidade = convenio.localidade;
		cliente.enderecoDefault.localidade = convenio.localidade;
		cliente.enderecoDefault.bairro = convenio.bairro;
		cliente.enderecoDefault.endereco = convenio.logradouro;
		cliente.enderecoDefault.logradouro = convenio.logradouro
		cliente.enderecoDefault.complemento = convenio.complemento;
		cliente.enderecoDefault.numero = convenio.numero;
		cliente.enderecoDefault.referencia = convenio.referencia
		cliente.enderecoDefault.codigoMunicipio = convenio.ibge;
		cliente.enderecoDefault.ibge = convenio.ibge;
		cliente.enderecos = [cliente.enderecoDefault];
		return cliente;
	}

	popupCFE(
		pedido: Pedido,
		pedidosService: PedidosService,
		pagamentoComCartao = false,
		broadcaster: Broadcaster,
		snackBar: MatSnackBar,
		componentId: string = 'popupNFCE'
	): Promise<boolean> {
		return new Promise((resolve, reject) => {
			_dialog('Emitir Cupom Fiscal (CFe Sat)', 'Clique em "Não emitir" caso não queira emitir', [
				{
					component: 'checkBoxComponent',
					args: ['Utilizar destinatário padrão'],
					name: 'clientePadrao',
					default: false,
					valueProp: 'checked',
					disable: {
						value: true,
						elements: ['docCliente'],
					}
				},
				{
					name: 'docCliente',
					label: 'CPF/CNPJ do cliente',
					component: 'inputComponent',
					default: (pedido.cliente || { cnpj: null }).cnpj || (pedido.cliente || { cpf: null }).cpf
				},
				{
					name: 'autorizacaoCartao',
					label: 'Código de autorização do cartão',
					component: 'inputComponent',
					hide: !pagamentoComCartao
				}
			], { label: 'Emitir nota', bg: 'green', fg: 'white' }, { label: 'Não emitir', bg: 'red', fg: 'white' }).then((values) => {
				if (values) {
					broadcaster.broadcast$(BroadcasterEvents.POPUP_SEFAZ, true);
					this._load.show();
					pedidosService.emitirNota(pedido._id, false, null, null, undefined, componentId, values.docCliente, values.autorizacaoCartao, values.clientePadrao, true).subscribe(
						(res: any) => {
							pedido.tipoNota = 'CFeSat';
							broadcaster.broadcast$(BroadcasterEvents.POPUP_SEFAZ, false);
							showSnackBar('Nota fiscal emitida com sucesso!', Snackbar.TYPE_SUCCESS, snackBar)
							this._load.hide();
							resolve(true);
						},
						(err) => {
							let ef = this.formatError(err);
							this._alert(ef.title, ef.message);
							broadcaster.broadcast$(BroadcasterEvents.POPUP_SEFAZ, false);
							this._load.hide();
							reject(err);
						}
					);
				}
				else {
					resolve(false);
				}
			})
		});
	}

	downloadFileFromData(data: string, fileName: string, type: string = 'octet/stream') {
		const a = document.createElement('a');
		const file = new Blob([data], {type});
		const url = URL.createObjectURL(file);
		a.href = url;
		a.download = fileName;
		a.click();
		URL.revokeObjectURL(url);
	}

	popupNFCEMultiplo(
		pedidos: Pedido[],
		pedidosService: PedidosService,
		componentId: string = 'popupNFCE',
		broadcaster: Broadcaster,
		snackBar: MatSnackBar
	): Promise<boolean> {
		return new Promise((resolve, reject) => {
			_dialog('Emissão de Documento Fiscal', 'Escolha o tipo de documento que deseja emitir', [
				{
					label: 'Tipo de Documento Fiscal',
					name: 'tipoNota',
					component: 'selectComponent',
					args: [[
						{ value: 'nfce', text: 'NFCe' },
						{ value: 'CFeSat', text: 'CFe Sat' }
					]],
					default: localStorage.cfe == 'true' ? 'CFeSat' : 'nfce'
				},
				{
					component: 'checkBoxComponent',
					args: ['Utilizar destinatário padrão'],
					name: 'clientePadrao',
					default: false,
					valueProp: 'checked'
				}
			]).then((values) => {
				if (values) {
					broadcaster.broadcast$(BroadcasterEvents.POPUP_SEFAZ, true);
					this._load.show();
					pedidosService.emitirMultiplasNota(pedidos.map(p => p._id), componentId, values.clientePadrao, values.tipoNota == 'CFeSat').subscribe(
						(res: any) => {
							broadcaster.broadcast$(BroadcasterEvents.POPUP_SEFAZ, false);
							this._load.hide();
							showSnackBar('Notas emitidas com sucesso!', Snackbar.TYPE_SUCCESS, snackBar)
							if(values.tipoNota == 'nfce') {
								this.downloadFileFromData(res, 'notas-fiscais.zip');
							}
							resolve(true);
						},
						(err) => {
							let ef = this.formatError(err);
							this._alert(ef.title, ef.message);
							broadcaster.broadcast$(BroadcasterEvents.POPUP_SEFAZ, false);
							this._load.hide();
							reject(err);
						}
					)
				}
				else {
					resolve(false);
				}
			})
		});
	}

	popupNFCE(
		pedido: Pedido,
		pedidosService: PedidosService,
		smtpConfigService: SMTPConfigService,
		utils: UtilsService,
		pagamentoComCartao = false,
		broadcaster: Broadcaster,
		snackBar: MatSnackBar,
		baixarNota: Boolean = false,
		print = false,
		componentId: string = 'popupNFCE'
	): Promise<boolean> {
		return new Promise((resolve, reject) => {
			if (baixarNota) {
				if (pedido.tipoNota == 'CFeSat') {
					broadcaster.broadcast$(BroadcasterEvents.POPUP_SEFAZ, true);
					this._load.show();
					pedidosService.emitirNota(pedido._id, false, null, null, undefined, componentId, null, null, null, true).subscribe(
						(res: any) => {
							broadcaster.broadcast$(BroadcasterEvents.POPUP_SEFAZ, false);
							this._load.hide();
							broadcaster.broadcast$(BroadcasterEvents.POPUP_SEFAZ, false);
							this._load.hide();
							resolve(true);
						},
						(err) => {
							broadcaster.broadcast$(BroadcasterEvents.POPUP_SEFAZ, false);
							this._load.hide();
							let ef = this.formatError(err);
							this._alert(ef.title, ef.message);
							broadcaster.broadcast$(BroadcasterEvents.POPUP_SEFAZ, false);
							this._load.hide();
							reject(err);
						}
					);
				}
				else {
					this.popupNotCFE(pedido, pedidosService, smtpConfigService, utils, pagamentoComCartao, broadcaster, snackBar, true, print, componentId).then(resolve).catch(reject);
				}
			}
			else {
				_dialog('Emissão de Documento Fiscal', 'Escolha o tipo de documento que deseja emitir', [
					{
						label: 'Tipo de Documento Fiscal',
						name: 'tipoNota',
						component: 'selectComponent',
						args: [[
							{ value: 'nfce', text: 'NFCe' },
							{ value: 'CFeSat', text: 'CFe Sat' }
						]],
						default: localStorage.cfe == 'true' ? 'CFeSat' : 'nfce'
					},
				]).then((values) => {
					if (values) {
						if (values.tipoNota == 'CFeSat') {
							localStorage.cfe = 'true';
							this.popupCFE(pedido, pedidosService, pagamentoComCartao, broadcaster, snackBar, componentId).then(resolve).catch(reject);
						}
						else {
							localStorage.cfe = 'false';
							this.popupNotCFE(pedido, pedidosService, smtpConfigService, utils, pagamentoComCartao, broadcaster, snackBar, baixarNota, print, componentId).then(resolve).catch(reject);
						}
					}
					else {
						resolve(false);
					}
				})
			}
		});
	}

	popupNotCFE(
		pedido: Pedido,
		pedidosService: PedidosService,
		smtpConfigService: SMTPConfigService,
		utils: UtilsService,
		pagamentoComCartao = false,
		broadcaster: Broadcaster,
		snackBar: MatSnackBar,
		baixarNota: Boolean = false,
		print = false,
		componentId: string = 'popupNFCE'
	): Promise<boolean> {
		return new Promise((resolve, reject) => {
			smtpConfigService.getAll().subscribe(
				(smtpConfigs: SMTPConfig[]) => {
					_dialog(baixarNota ? 'Baixar Nota Fiscal (NFC-e)' : 'Emitir Nota Fiscal (NFC-e)', baixarNota ? null : 'Clique em "Não emitir" caso não queira emitir', [
						{
							component: 'checkBoxComponent',
							args: ['Utilizar destinatário padrão'],
							name: 'clientePadrao',
							default: false,
							valueProp: 'checked',
							disable: {
								value: true,
								elements: ['docCliente'],
							},
							hide: baixarNota
						},
						{
							name: 'docCliente',
							label: 'CPF/CNPJ do cliente',
							component: 'inputComponent',
							default: (pedido.cliente || { cnpj: null }).cnpj || (pedido.cliente || { cpf: null }).cpf,
							hide: baixarNota
						},
						{
							name: 'autorizacaoCartao',
							label: 'Código de autorização do cartão',
							component: 'inputComponent',
							hide: !pagamentoComCartao || baixarNota
						},
						{
							label: 'Enviar por email',
							name: 'sendToEmail',
							component: 'selectComponent',
							args: [[
								{ value: 'sim', text: 'Sim' },
								{ value: 'nao', text: 'Não' }
							]],
							disable: {
								value: 'nao',
								elements: ['idSmtpConfig', 'to'],
							},
							default: 'nao'
						},
						{
							label: 'De',
							name: 'idSmtpConfig',
							component: 'selectComponent',
							args: [smtpConfigs.map(smtpConfig => ({ value: smtpConfig._id, text: smtpConfig.user }))],
							default: smtpConfigs.length ? smtpConfigs[0]._id : null,
							props: {
								disabled: true
							}
						},
						{
							label: 'Para',
							name: 'to',
							component: 'inputComponent',
							default: pedido.cliente ? pedido.cliente.email : null,
							props: {
								disabled: true
							}
						},
						{
							component: 'checkBoxComponent',
							args: ['Baixar PDF'],
							name: 'baixarPdf',
							default: true,
							valueProp: 'checked'
						}
					], { label: baixarNota ? 'Baixar nota' : 'Emitir nota', bg: 'green', fg: 'white' }, { label: baixarNota ? 'Cancelar' : 'Não emitir', bg: 'red', fg: 'white' }).then((values) => {
						if (values) {
							if (!baixarNota) broadcaster.broadcast$(BroadcasterEvents.POPUP_SEFAZ, true);
							this._load.show();
							pedidosService.emitirNota(pedido._id, values.sendToEmail == 'sim', values.idSmtpConfig, values.to, undefined, componentId, values.docCliente, values.autorizacaoCartao, values.clientePadrao, false).subscribe(
								(res: any) => {
									pedido.tipoNota = 'nfce';
									if (res.url != null && values.baixarPdf) {
										utils.downloadFile(res.url, null, 'pdf', print, urlObject => this.renderPDFInDocument(urlObject));
									}
									broadcaster.broadcast$(BroadcasterEvents.POPUP_SEFAZ, false);
									if (!baixarNota) showSnackBar('Nota fiscal emitida com sucesso!', Snackbar.TYPE_SUCCESS, snackBar)
									this._load.hide();
									resolve(true);
								},
								(err) => {
									let ef = this.formatError(err);
									this._alert(ef.title, ef.message);
									broadcaster.broadcast$(BroadcasterEvents.POPUP_SEFAZ, false);
									this._load.hide();
									reject(err);
								}
							);
						}
						else {
							resolve(false);
						}
					})
				},
				(err) => {
					this._alert('Erro ao carregar as configurações de SMTP');
					reject(err);
				}
			);
		});
	}

}
