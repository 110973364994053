import {SelectValue} from '../interface/SelectValue';

export enum PedidoStatus {

	PEDIDO_CANCELADO = <any>'0',
	PEDIDO_AGENDADO = <any>'1',
	PEDIDO_NOVO = <any>'2',
	PEDIDO_REPASSADO = <any>'3',
	PEDIDO_EM_TRANSITO = <any>'4',
	PEDIDO_ENTREGUE = <any>'5',
	PEDIDO_PENDENTE = <any>'6',
	PEDIDO_DEVOLVIDO = <any>'7'
}

export const PedidoStatusSelectValues: SelectValue<PedidoStatus>[] = [
	{ value: PedidoStatus.PEDIDO_CANCELADO, text: 'Cancelado' },
	{ value: PedidoStatus.PEDIDO_AGENDADO, text: 'Agendado' },
	{ value: PedidoStatus.PEDIDO_NOVO, text: 'Novo' },
	{ value: PedidoStatus.PEDIDO_REPASSADO, text: 'Repassado' },
	{ value: PedidoStatus.PEDIDO_EM_TRANSITO, text: 'Em Trânsito' },
	{ value: PedidoStatus.PEDIDO_ENTREGUE, text: 'Entregue' },
	{ value: PedidoStatus.PEDIDO_PENDENTE, text: 'Pendente' },
	{ value: PedidoStatus.PEDIDO_DEVOLVIDO, text: 'Devolvido' }
];
