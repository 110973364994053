import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {TokenStorage} from '../../auth/token-storage.service';
import {AppConfig} from '../../../config/appConfig';
import {Observable} from 'rxjs';
import {Produto} from '../../models/api/Produto';
import {ResultObject} from '../../models/api/ResultObject';
import {ServicePattern} from '../../models/interface/ServicePattern';
import {EstabelecimentoFunctions} from '../../models/interface/EstabelecimentoFunctions';
import {Estabelecimento} from '../../models/template/Estabelecimento';
import {DateFilter} from '../../models/interface/DateFilter';

@Injectable()
export class ProdutosService extends EstabelecimentoFunctions implements ServicePattern<Produto> {

	readonly ROUTE = '/produtos';

	constructor(private http: HttpClient, private tokenStorage: TokenStorage) {
		super();
	}

	getAll(
		dateFilter: DateFilter = {dataInicio: null, dataFim: null},
		componentId: string = '',
		ignoreEstabelecimentoOnCache: boolean = false,
		estabelecimentos: { empresa?: string, loja?: string, multiEmpresa?: string, multiLoja?: string } = null,
		page: number = null,
		perPage: number = null,
		searchString: string = null,
		estabelecimentoOnParam: boolean = false,
		showAll?: boolean,
		filterApp?: boolean
	): Observable<Produto[]> {
		return this.http.get<Produto[]>(
			AppConfig.getAPIEndpoint() + '/produtos/list/' + this.tokenStorage.getUserInfo().idUsuario,
			{
				headers: AppConfig.getHTTPHeader().headers.set('componentId', componentId),
				params: {
					...(
						ignoreEstabelecimentoOnCache == true && estabelecimentoOnParam != true ?
							( estabelecimentos != null ? estabelecimentos : {} ) :
							this.getEstabelecimentoQueryParam(ignoreEstabelecimentoOnCache == true ? estabelecimentos : undefined)
					),
					...this.getDateFilterQueryParam(dateFilter.dataInicio, dateFilter.dataFim),
					...(page || page == 0 ? { page: page + 1 } : {}),
					...(perPage ? { per_page: perPage } : {}),
					...(searchString != null ? { searchString: searchString } : {}),
					...(showAll != null ? { showAll: showAll } : {}),
					...(filterApp != null ? { filterApp: filterApp } : {}),
				}
			}
		);
	}

	getAllWithEstoqueFilter(
		dateFilter: DateFilter = { dataInicio: null, dataFim: null },
		componentId: string = '',
		ignoreEstabelecimentoOnCache: boolean = false,
		estabelecimentos: { empresa?: string, loja?: string, multiEmpresa?: string, multiLoja?: string } = null,
		estabelecimentoOnParam: boolean = false,
		alwaysBringEstoqueResult: boolean = false,
		ignoreIsInUseFilter: boolean = false,
		showAll?: boolean
	): Observable<Produto[]> {
		return this.http.get<Produto[]>(
			AppConfig.getAPIEndpoint() + '/produtos/list/' + this.tokenStorage.getUserInfo().idUsuario,
			{
				headers: AppConfig.getHTTPHeader().headers.set('componentId', componentId),
				params: {
					...(
						ignoreEstabelecimentoOnCache == true && estabelecimentoOnParam != true ?
							( estabelecimentos != null ? estabelecimentos : {} ) :
							this.getEstabelecimentoQueryParam(ignoreEstabelecimentoOnCache == true ? estabelecimentos : undefined)
					),
					...this.getDateEstoqueFilterQueryParam(dateFilter.dataInicio, dateFilter.dataFim, false, true),
					...(alwaysBringEstoqueResult != null ? { alwaysBringEstoqueResult: alwaysBringEstoqueResult } : {}),
					...(ignoreIsInUseFilter != null ? { ignoreIsInUseFilter: ignoreIsInUseFilter } : {}),
					...(showAll != null ? { showAll: showAll } : {})
				}
			}
		);
	}

	get(idProduto: number): Observable<Produto> {
		// TODO
		return null;
	}

	save(body: Produto, getEstabelecimentoFromCache: boolean = true, componentId: string = ''): Observable<ResultObject<any>> {
		if (getEstabelecimentoFromCache) { body.estabelecimento = this.getEstabelecimentoOnCache(); }
		return this.http.post<ResultObject<any>>(
			AppConfig.getAPIEndpoint() + '/produtos/' + this.tokenStorage.getUserInfo().idUsuario,
			body,
			{
				headers: AppConfig.getHTTPHeader().headers.set('componentId', componentId)
			}
		);
	}

	update(body: Produto, componentId: string = ''): Observable<ResultObject<any>> {
		return this.http.put<ResultObject<any>>(
			AppConfig.getAPIEndpoint() + '/produtos/' + this.tokenStorage.getUserInfo().idUsuario,
			body,
			{
				headers: AppConfig.getHTTPHeader().headers.set('componentId', componentId)
			}
		);
	}

	delete(idProduto: number, componentId: string = ''): Observable<ResultObject<any>> {
		return this.http.delete<ResultObject<any>>(
			AppConfig.getAPIEndpoint() + '/produtos/' + this.tokenStorage.getUserInfo().idUsuario + '/' + idProduto,
			{
				headers: AppConfig.getHTTPHeader().headers.set('componentId', componentId)
			}
		);
	}

	getEntriesCount(componentId: string = ''): Observable<{entries: number}> {
		return this.http.get<{entries: number}>(
			AppConfig.getAPIEndpoint() + this.ROUTE + '/list/' + this.tokenStorage.getUserInfo().idUsuario,
			{
				headers: AppConfig.getHTTPHeader().headers.set('componentId', componentId),
				params: {
					...this.getEstabelecimentoQueryParam(),
					entriesOnly: true
				}
			}
		);
	}

	/**
	 * Formata a query string do filtro de data para Estoque
	 */
	getDateEstoqueFilterQueryParam(dataInicio = null, dataFim = null, isFirstQuery: boolean = false, generateJSON: boolean = true): string | any {

		if (!!dataInicio && !!dataFim) {
			dataInicio.setUTCHours(0, 0, 0, 0);
			dataFim.setUTCHours(23, 59, 59, 59);

			if (generateJSON) {
				return {
					estoqueDataInicio: dataInicio.toISOString(),
					estoqueDataFim: dataFim.toISOString()
				};
			} else {
				return (isFirstQuery ? '?' : '&') + 'estoqueDataInicio=' + dataInicio.toISOString() + '&estoqueDataFim=' + dataFim.toISOString();
			}
		}

		return generateJSON ? {} : '';
	}
}
