import {Grupo} from '../api/grupo/Grupo';
import {HistoricoAcao} from '../api/HistoricoAcao';
import {Estabelecimento} from '../template/Estabelecimento';

export class DefaultFields<T> {
	_id?: any;
	createdAt?: string;
	updatedAt?: string;
	deletedAt?: string;
	ativo?: boolean;

	grupoIdGrupo?: number;
	grupo?: Grupo;

	historicoAcaos?: HistoricoAcao[];

	/* Template Var */
	isGroupBy?: boolean;
	isSelected?: boolean;
	isEditMode?: boolean;
	isExibirMsg?: boolean;
	showPopUp?: boolean;
	isCadastroMode?: boolean;
	showDeleteConfirm?: boolean;
	showEditConfirm?: boolean;
	showCadastroConfirm?: boolean;
	showDisableConfirm?: boolean;
	showEnableConfirm?: boolean;
	showAutorizationPasswordConfirm?: boolean;
	_objectCopy?: T;
	isSummaryRow?: boolean;
	_showQuickInfoPopUp?: boolean;
	_uniqueId?: string;

	/** Request params */
	estabelecimento?: Estabelecimento;

}
