import {Produto} from './Produto';
import {Cliente} from './Cliente';
import {Usuario} from './Usuario';
import {PedidoStatus} from '../enum/PedidoStatus';
import {DefaultFields} from '../interface/DefaultFields';
import {Entregador} from './Entregador';
import {Estabelecimento} from '../template/Estabelecimento';
import {Venda} from './Venda';
import {Empresa} from './Empresa';
import {Loja} from './Loja';
import {TipoPagamento} from '../enum/TipoPagamento';
import {IndicacaoCompra} from '../enum/IndicacaoCompra';
import {TaxaTransacaoCartao} from './TaxaTransacaoCartao';
import {LancarPedidoRapidoTipo} from '../enum/LancarPedidoRapidoTipo';
import {Conta} from './Conta';
import {Notificacao} from './Notificacao';
import {Endereco} from './Endereco';
import {getRandomLetter} from '../../services/utils.service';
import { CupomDesconto } from './CupomDesconto';

export class Pedido extends DefaultFields<Pedido> {

	idPedido: number;
	frontId: string;

	data: Date;

	dataRepasse: Date;

	dataEntrega: Date;

	agendado: boolean;

	origem_app?: boolean;
	enderecoDeEntrega?: Endereco;

	dataPreenchidaAutomaticamente: boolean;
	timestampSistemaUsuario: number;

	tipoPagamento: TipoPagamento;

	pagamentoExtra: Pagamento;

	pagamentos: Pagamento[];
	pagamentosNoPedido: Pagamento[];

	valorTotal: number;

	produtos: Produto[];

	cliente: Cliente;

	contas: Conta[];

	notificacaos: Notificacao[];

	status: PedidoStatus;

	statusPedidoRapido: LancarPedidoRapidoTipo;

	entregador: Entregador;

	troco: number;

	atendidoPor: Usuario;

	produtoQuantidade: any;

	valoresNegociados: any;

	tipoProdutoEstoque: any;

	urlBoletoCloud: string;

	observacao: string;
	alterarEntregadorAcerto: boolean = true;

	venda: Venda;

	acertado: boolean;

	empresaIdEmpresa: string;
	empresa: Empresa;

	lojaIdLoja: string;
	loja: Loja;

	indicacaoCompra: IndicacaoCompra;

	numero: string;

	historico: { desc: string, timestamp: Date, novosDados: any, alteradoPor: string }[];

	observacaoCancelamento: string;

	observacaoDemora: string;

	taxaTransacaoCartao: TaxaTransacaoCartao;

	estoquesToUpdate: Produto[];

	isPedidoPortaria: boolean;

	rotaDeEntrega: { type: string, coordinates: number[][] };

	chaveNota: string;
	tipoNota?: string;
	boleto: any;

	/** Boleto */
	idBoleto: number;
	dataCriacaoBoleto: Date;
	boletoEmitidoPor: Usuario;

	/** Request Params */
	multiStatus: string;
	produtosToUpdate: any;
	entregadorIdEntregador: number;
	novoHistorico: any[];
	taxaTransacaoCartaoIdTaxaTransacaoCartao: number;
	atualizarEstoque: boolean;
	creditoParaUtilizar: number;
	boletoAction: 'emitir' | 'registrar' | null;

	creditoUtilizado: number;
	creditoTotal: number;
	naoGerarContaAReceber: boolean;

	/** Template Vars */
	estabelecimento: Estabelecimento;

	pagamentoEmExibicao: Pagamento;

	valorNegociado: number;

	statusOptionsExpanded: boolean;

	_showAgendamentoPopUp: boolean;

	cupomDesconto: CupomDesconto;
	parcelamento: number;

	alterarCliente: boolean;

	cancelarNota: boolean;
	cancelarNotaJson: {
		justificativa: string,
		tipoNota: string,
		cancelarBoleto: boolean,
		fuso: string,
	}
	// Cada boleto possui um token que é enviado para o boleto cloud para que não
	// seja gerado mais de um boleto para o mesmo pedido. By Pedro Santos
	tokenControle: string;
}

export enum FormaPagamento {
	A_VISTA = <any>'a_vista',
	A_PRAZO = <any>'a_prazo'
}

export class Pagamento {
	tipoPagamento: TipoPagamento;
	valor: number;
	observacao: string;
	fiado?: boolean;
	formaPagamento?: FormaPagamento;

	dataVencimento?: Date;
	dataPagamento?: Date;

	nomeBanco?: string;
	numeroCheque?: string;

	numeroBoleto?: string;
	numeroFatura?: string;

	idConvenio?: string;

	_uniqueId?: string;

	constructor(generateUniqueID: boolean = false) {
		if (generateUniqueID) {
			this._uniqueId = `${getRandomLetter(4)}${Date.now()}`;
			this.valor = 0;
		}
	}
}
