import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { TokenStorage } from '../../auth/token-storage.service';
import { AppConfig } from '../../../config/appConfig';

@Injectable()
export class TraducaoNFService {
	readonly ROUTE = '/traducaoNF'

	constructor(private http: HttpClient, private tokenStorage: TokenStorage) { }

	adicionar(regex: string, mensagem: string, componentId: string = ''): Observable<any> {
		return this.http.post<any[]>(
			AppConfig.getAPIEndpoint() + this.ROUTE + '/' + this.tokenStorage.getUserInfo().idUsuario,
			{ regex, mensagem },
			{
				headers: AppConfig.getHTTPHeader().headers.set('componentId', componentId)
			}
		)
	}

	remover(idTraducao: string, componentId: string = ''): Observable<any> {
		return this.http.delete<any>(
			AppConfig.getAPIEndpoint() + this.ROUTE + '/' + idTraducao + '/' + this.tokenStorage.getUserInfo().idUsuario,
			{
				headers: AppConfig.getHTTPHeader().headers.set('componentId', componentId)
			}
		)
	}

	listar(componentId: string = ''): Observable<any[]> {
		return this.http.get<any>(
			AppConfig.getAPIEndpoint() + this.ROUTE + '/' + this.tokenStorage.getUserInfo().idUsuario,
			{
				headers: new HttpHeaders({ componentId })
			}
		)
	}

	editar(idTraducao: string, regex: string, mensagem: string, componentId: string = ''): Observable<any> {
		return this.http.put<any>(
			AppConfig.getAPIEndpoint() + this.ROUTE + '/' + idTraducao + '/' + this.tokenStorage.getUserInfo().idUsuario,
			{ regex, mensagem },
			{
				headers: new HttpHeaders({ componentId })
			}
		)
	}
}