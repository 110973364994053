import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { TokenStorage } from '../../auth/token-storage.service';
import { AppConfig } from '../../../config/appConfig';

@Injectable()
export class TemplatesContratoService {
	readonly ROUTE = '/templatesContrato'

	constructor(private http: HttpClient, private tokenStorage: TokenStorage) { }

	getAll(empresa?: string, loja?: string, componentId: string = ''): Observable<any[]> {
		return this.http.get<any[]>(
			AppConfig.getAPIEndpoint() + this.ROUTE + '/' + this.tokenStorage.getUserInfo().idUsuario,
			{
				headers: AppConfig.getHTTPHeader().headers.set('componentId', componentId),
				params: {
					...(empresa ? { empresa } : {}),
					...(loja ? { loja } : {})
				}
			}
		)
	}

	renomear(idTemplate: string, nome: string, componentId: string = ''): Observable<any> {
		return this.http.put<any>(
			AppConfig.getAPIEndpoint() + this.ROUTE + '/renomear/' + idTemplate + '/' + this.tokenStorage.getUserInfo().idUsuario,
			{
				nome
			},
			{
				headers: AppConfig.getHTTPHeader().headers.set('componentId', componentId)
			}
		)
	}

	remover(idTemplate: string, componentId: string = '') {
		return this.http.delete<any>(
			AppConfig.getAPIEndpoint() + this.ROUTE + '/' + idTemplate + '/' + this.tokenStorage.getUserInfo().idUsuario,
			{
				headers: AppConfig.getHTTPHeader().headers.set('componentId', componentId)
			}
		)
	}

	baixar(idTemplate: string, componentId: string = '') {
		return this.http.get<any>(
			AppConfig.getAPIEndpoint() + this.ROUTE + '/' + idTemplate + '/' + this.tokenStorage.getUserInfo().idUsuario,
			{
				headers: AppConfig.getHTTPHeader().headers.set('componentId', componentId)
			}
		)
	}

	substituir(idTemplate: string, arquivo: File, componentId: string = ''): Observable<any> {
		const formData = new FormData()
		formData.append('arquivo', arquivo)
		return this.http.put<any>(
			AppConfig.getAPIEndpoint() + this.ROUTE + '/substituir/' + idTemplate + '/' + this.tokenStorage.getUserInfo().idUsuario,
			formData,
			{
				headers: new HttpHeaders({ componentId })
			}
		)
	}

	adicionar(nome: string, empresa: string, loja: string, arquivo: File, componentId: string = ''): Observable<any> {
		const formData = new FormData()
		formData.append('nome', nome)
		formData.append('arquivo', arquivo)
		if (empresa) {
			formData.append('empresa', empresa)
		}
		if (loja) {
			formData.append('loja', loja)
		}
		return this.http.post<any>(
			AppConfig.getAPIEndpoint() + this.ROUTE + '/' + this.tokenStorage.getUserInfo().idUsuario,
			formData,
			{
				headers: new HttpHeaders({ componentId })
			}
		)
	}

	editar(idTemplate: string, codigo: string, componentId: string = ''): Observable<any> {
		return this.http.put<any>(
			AppConfig.getAPIEndpoint() + this.ROUTE + '/editar/' + idTemplate + '/' + this.tokenStorage.getUserInfo().idUsuario,
			{ codigo },
			{
				headers: new HttpHeaders({ componentId })
			}
		)
	}
}