import {Injectable} from '@angular/core';
import {HttpClient, HttpParams} from '@angular/common/http';
import {TokenStorage} from '../../auth/token-storage.service';
import {AppConfig} from '../../../config/appConfig';
import {Observable} from 'rxjs';
import {Cliente} from '../../models/api/Cliente';
import {ResultObject} from '../../models/api/ResultObject';
import {ServicePattern} from '../../models/interface/ServicePattern';
import { EstabelecimentoFunctions } from '../../models/interface/EstabelecimentoFunctions';
import axios from 'axios'
import { PerfilCliente } from '../../models/enum/PerfilCliente';
import {Estabelecimento} from '../../models/template/Estabelecimento';
import { TipoEstoque } from '../../models/enum/TipoEstoque';
import { TipoCliente } from '../../models/enum/TipoCliente';
import { AlteracaoCliente, AlteracoesClienteResult } from '../../models/api/AlteracaoCliente'

@Injectable()
export class ClientesService extends EstabelecimentoFunctions implements ServicePattern<Cliente> {

	readonly ROUTE = '/clientes';

	constructor(private http: HttpClient, private tokenStorage: TokenStorage) {
		super();
	}

	getAniversariantes(
		dateFilter: { dataInicio: Date, dataFim: Date, campo: string } = { dataInicio: null, dataFim: null, campo: null },
		page: number = null,
		perPage: number = null,
		componentId: string = '',
		ignoreEstabelecimentoOnCache: boolean = false,
		estabelecimentos: { empresa?: string, loja?: string, multiEmpresa?: string, multiLoja?: string } = null,
		searchString: string = null,
		estabelecimentoOnParam: boolean = false
	): Observable<any> {
		return this.http.get<any>(
			AppConfig.getAPIEndpoint() + this.ROUTE + '/aniversariantes/' + this.tokenStorage.getUserInfo().idUsuario,
			{
				headers: AppConfig.getHTTPHeader().headers.set('componentId', componentId),
				params: {
					...(
						ignoreEstabelecimentoOnCache == true && estabelecimentoOnParam != true ?
							(estabelecimentos != null ? estabelecimentos : {}) :
							this.getEstabelecimentoQueryParam(ignoreEstabelecimentoOnCache == true ? estabelecimentos : undefined)
					),
					...this.getDateFilterWithFieldQueryParam(dateFilter.campo, dateFilter.dataInicio, dateFilter.dataFim),
					...(page || page == 0 ? { page: page + 1 } : {}),
					...(perPage ? { per_page: perPage } : {}),
					...(searchString != null ? { searchString: searchString } : {})
				}
			}
		);
	}

	incrementarValorNegociado(produto: string, tipoEstoque: TipoEstoque, valor: number, componentId = '') {
		return this.http.post<any>(
			AppConfig.getAPIEndpoint() + this.ROUTE + '/incrementar_valor_negociado/' + this.tokenStorage.getUserInfo().idUsuario,
			{
				produto,
				tipoEstoque,
				valor
			},
			{
				headers: AppConfig.getHTTPHeader().headers.set('componentId', componentId)
			}
		);
	}

	getAll(
		dateFilter: { dataInicio: Date, dataFim: Date, campo: string } = {dataInicio: null, dataFim: null, campo: null},
		page: number = null,
		perPage: number = null,
		componentId: string = '',
		ignoreEstabelecimentoOnCache: boolean = false,
		estabelecimentos: { empresa?: string, loja?: string, multiEmpresa?: string, multiLoja?: string } = null,
		searchString: string = null,
		estabelecimentoOnParam: boolean = false,
		vcard: boolean = false,
		perfilCliente?: PerfilCliente[],
		userTermQuery?: boolean,
		field?: string,
		silvaGas: boolean = false,
		tipoCliente?: TipoCliente[]
	): Observable<any> {
		if (vcard) {
			return new Observable((observer) => {
				axios({
					method: 'GET',
					url: AppConfig.getAPIEndpoint() + this.ROUTE + '/list/' + this.tokenStorage.getUserInfo().idUsuario,
					headers: {
						Authorization: this.tokenStorage.getAccessTokenSync()
					},
					params: {
						...(
							ignoreEstabelecimentoOnCache == true && estabelecimentoOnParam != true ?
								(estabelecimentos != null ? estabelecimentos : {}) :
								this.getEstabelecimentoQueryParam(ignoreEstabelecimentoOnCache == true ? estabelecimentos : undefined)
						),
						...this.getDateFilterWithFieldQueryParam(dateFilter.campo, dateFilter.dataInicio, dateFilter.dataFim),
						...(searchString != null ? {searchString: searchString} : {}),
						...(searchString != null && userTermQuery != null ? {userTermQuery: userTermQuery} : {}),
						...(vcard ? {vcard: 'true'} : {}),
						...(perfilCliente ? {perfil: perfilCliente.join(',')} : {}),
						...(field ? { field } : {}),
						...(tipoCliente ? { tipo: tipoCliente.join(',') } : {}),
						silvaGas: silvaGas ? 'true' : 'false'
					}
				}).then(response => {
					observer.next(response.data);
				}).catch(error => {
					observer.error(error);
				});
			});
		} else {
			return this.http.get<any>(
				AppConfig.getAPIEndpoint() + this.ROUTE + '/list/' + this.tokenStorage.getUserInfo().idUsuario,
				{
					headers: AppConfig.getHTTPHeader().headers.set('componentId', componentId),
					params: {
						...(
							ignoreEstabelecimentoOnCache == true && estabelecimentoOnParam != true ?
								(estabelecimentos != null ? estabelecimentos : {}) :
								this.getEstabelecimentoQueryParam(ignoreEstabelecimentoOnCache == true ? estabelecimentos : undefined)
						),
						...this.getDateFilterWithFieldQueryParam(dateFilter.campo, dateFilter.dataInicio, dateFilter.dataFim),
						...(page || page == 0 ? {page: page + 1} : {}),
						...(perPage ? {per_page: perPage} : {}),
						...(searchString != null ? {searchString: searchString} : {}),
						...(searchString != null && userTermQuery != null ? {userTermQuery: userTermQuery} : {}),
						...(vcard ? {vcard: 'true'} : {}),
						...(perfilCliente ? {perfil: perfilCliente.join(',')} : {}),
						...(field ? { field } : {}),
						...(tipoCliente ? { tipo: tipoCliente.join(',') } : {}),
						silvaGas: silvaGas ? 'true' : 'false'
					}
				}
			);
		}
	}

	concatenar(clientes: Cliente[], values, componentId: string = ''): Observable<any> {
		return this.http.post<any>(
			AppConfig.getAPIEndpoint() + this.ROUTE + '/concatenar/' + this.tokenStorage.getUserInfo().idUsuario,
			{
				clientes: clientes.map(cliente => cliente.idCliente),
				values
			},
			{
				headers: AppConfig.getHTTPHeader().headers.set('componentId', componentId)
			}
		);
	}

	desfazerConcatenacao(cliente: Cliente, componentId: string = ''): Observable<any> {
		return this.http.post<any>(
			AppConfig.getAPIEndpoint() + this.ROUTE + '/desfazer_concatenacao/' + cliente.idCliente + '/' + this.tokenStorage.getUserInfo().idUsuario,
			{},
			{
				headers: AppConfig.getHTTPHeader().headers.set('componentId', componentId)
			}
		);
	}

	get(idCliente: number, componentId: string = ''): Observable<Cliente> {
		return this.http.get<Cliente>(
			AppConfig.getAPIEndpoint() + this.ROUTE + '/' + idCliente + '/' + this.tokenStorage.getUserInfo().idUsuario,
			{
				headers: AppConfig.getHTTPHeader().headers.set('componentId', componentId),
				params: {
					...this.getEstabelecimentoQueryParam()
				}
			}
		);
	}

	obterLogs(estabelecimento: string, page: number, per_page: number, busca: string, componentId: string = '', tipoAlteracao: number): Observable<AlteracoesClienteResult> {
		return this.http.get<AlteracoesClienteResult>(
			AppConfig.getAPIEndpoint() + this.ROUTE + '/alteracoes/' + this.tokenStorage.getUserInfo().idUsuario,
			{
				headers: AppConfig.getHTTPHeader().headers.set('componentId', componentId),
				params: {
					estabelecimento,
					page: page.toString(),
					per_page: per_page.toString(),
					...(busca ? { busca } : {}),
					tipoAlteracao: tipoAlteracao.toString()
				}
			}
		);
	}

	byPhone(phone: string, componentId: string = ''): Observable<Cliente> {
		return this.http.get<Cliente>(
			AppConfig.getAPIEndpoint() + this.ROUTE + '/by/phone/' + this.tokenStorage.getUserInfo().idUsuario,
			{
				headers: AppConfig.getHTTPHeader().headers.set('componentId', componentId),
				params: {
					phone
				}
			}
		);
	}

	getMediaClientesCompra(
		dateFilter: { dataInicio: Date, dataFim: Date } = { dataInicio: null, dataFim: null },
		componentId: string = '',
		page: number = null,
		perPage: number = null,
		searchString: string = null,
		contatado: boolean = false,
		data_inicial?: Date,
		data_fim?: Date,
		statusAtendimento?: string,
		perfil?: string,
		valor?: string,
		produto?: string,
		atraso?: number,
		// Se for true só retorna clientes que possuem um último pedido, ou seja, 
		// já tenham feito algum pedido. By Pedro Santos
		ultimoPedido: boolean = false,
	): Observable<Cliente[]> {
		return this.http.get<Cliente[]>(
			AppConfig.getAPIEndpoint() + this.ROUTE + '/com_pedidos_atrasados/' + this.tokenStorage.getUserInfo().idUsuario,
			{
				headers: AppConfig.getHTTPHeader().headers.set('componentId', componentId),
				params: {
					contatado,
					...this.getEstabelecimentoQueryParam(),
					...(page || page == 0 ? { page: page + 1 } : {}),
					...(perPage ? { per_page: perPage } : {}),
					...(searchString != null ? { searchString: searchString } : {}),
					...(data_inicial ? {data_inicial}: {}),
					...(data_fim ? {data_fim}: {}),
					...(statusAtendimento ? {statusAtendimento}: {}),
					...(perfil ? {perfil}: {}),
					...(dateFilter.dataInicio ? {dataInicio: dateFilter.dataInicio} : {}),
					...(dateFilter.dataFim ? {dataFim: dateFilter.dataFim} : {}),
					...(valor ? {valor} : {}),
					...(produto ? {produto} : {}),
					...(atraso ? {atraso} : {}),
					...(ultimoPedido ? {ultimoPedido} : {})
				}
			}
		);
	}

	save(body: Cliente, getEstabelecimentoFromCache: boolean = true, componentId: string = ''): Observable<ResultObject<Cliente>> {
		if (getEstabelecimentoFromCache == true) body.estabelecimento = this.getEstabelecimentoOnCache();
		return this.http.post<ResultObject<Cliente>>(
			AppConfig.getAPIEndpoint() + this.ROUTE + '/' + this.tokenStorage.getUserInfo().idUsuario,
			body,
			{
				headers: AppConfig.getHTTPHeader().headers.set('componentId', componentId)
			}
		);
	}

	update(body: Cliente, componentId: string = ''): Observable<ResultObject<any>> {
		return this.http.put<ResultObject<any>>(
			AppConfig.getAPIEndpoint() + this.ROUTE + '/' + this.tokenStorage.getUserInfo().idUsuario,
			body,
			{
				headers: AppConfig.getHTTPHeader().headers.set('componentId', componentId)
			}
		);
	}

	delete(idCliente: number, componentId: string = ''): Observable<ResultObject<any>> {
		return this.http.delete<ResultObject<any>>(
			AppConfig.getAPIEndpoint() + this.ROUTE + '/' +
			this.tokenStorage.getUserInfo().idUsuario +
			'/' + idCliente,
			{
				headers: AppConfig.getHTTPHeader().headers.set('componentId', componentId)
			}
		);
	}
	// Exclui vários clientes de acordo com os ids passados. By Pedro Santos
	deleteMultiple(idsClientes: number[], componentId: string = ''): Observable<ResultObject<any>> {
		return this.http.delete<ResultObject<any>>(
			AppConfig.getAPIEndpoint() + this.ROUTE + '/excluir_varios/' +
			this.tokenStorage.getUserInfo().idUsuario +
			'/' + idsClientes,
			{
				headers: AppConfig.getHTTPHeader().headers.set('componentId', componentId)
			}
		);
	}

	mediaOculto(idCliente: number, componentId: string = ''): Observable<ResultObject<any>> {
		return this.http.delete<ResultObject<any>>(
			AppConfig.getAPIEndpoint() + this.ROUTE + '/mediaOculto/' +
			this.tokenStorage.getUserInfo().idUsuario +
			'/' + idCliente,
			{
				headers: AppConfig.getHTTPHeader().headers.set('componentId', componentId)
			}
		);
	}

	getEntriesCount(componentId: string = '', estabelecimento?: Estabelecimento): Observable<{entries: number}> {
		return this.http.get<{entries: number}>(
			AppConfig.getAPIEndpoint() + this.ROUTE + '/list/' + this.tokenStorage.getUserInfo().idUsuario,
			{
				headers: AppConfig.getHTTPHeader().headers.set('componentId', componentId),
				params: {
					...this.getEstabelecimentoQueryParam(estabelecimento),
					entriesOnly: true
				}
			}
		);
	}

	formatarResultadosCliente(clientes: Cliente[]): string {
		let HTML = '<div class="m-list-search__results m-widget4 m-widget4--progress">';
		if (clientes.length < 1) {
			HTML += '<span style="margin-top: 10px; margin-bottom: 10px; display: block" class="m-list-search__result-message--first">' +
				'Nenhum cliente encontrado' +
				'</span>';
		} else {
			clientes.forEach((c) => {

				let endereco = c.enderecos[0];

				for (const e of c.enderecos) {
					if (e.isDefault) {
						endereco = e;
						break;
					}
				}

				HTML += '<div style="padding-top: 10px; padding-bottom: 10px; cursor: pointer" class="m-widget4__item">' +
					'<div class="m-widget4__img m-widget4__img--pic">' +
					'<img style="width: 3.4rem" src="./assets/app/media/img/users/userProfile.png" alt="">' +
					'</div>' +
					'<div style="width: auto;padding-left: 10px;" class="m-widget4__info">' +
					'<span style="display: block; height: 10px; font-size: 14px; width: 250px; padding-top: 5px; margin-bottom: 7px" class="m-widget4__title text-overflow">' +
					c.nome +
					'</span>' +
					'<span style="display: block; width: 250px;height: 10px; padding-top: 5px;" class="m-widget4__sub text-overflow">' +
					c.telefone + ', ' + endereco.bairro + ', ' + endereco.cidade + ' - ' + endereco.estadoAcronimo +
					'</span>' +
					'</div>' +
					'</div>';
			});
		}
		HTML += '</div>';
		return HTML;
	}

	removeAssociation(idCliente: string, idEmpresa: string, idLoja: string, componentId: string = '') {
		return this.http.post<ResultObject<any>>(
			AppConfig.getAPIEndpoint() + '/clientes/remover/associacao/' + this.tokenStorage.getUserInfo().idUsuario,
			{
				idCliente: idCliente,
				idEmpresa: idEmpresa,
				idLoja: idLoja
			},
			{
				headers: AppConfig.getHTTPHeader().headers.set('componentId', componentId)
			}
		);
	}

	associate(idCliente: string, idEmpresa: string, idLoja: string, componentId: string = '') {
		return this.http.post<ResultObject<any>>(
			AppConfig.getAPIEndpoint() + '/clientes/associacao/' + this.tokenStorage.getUserInfo().idUsuario,
			{
				idCliente: idCliente,
				idEmpresa: idEmpresa,
				idLoja: idLoja
			},
			{ headers: AppConfig.getHTTPHeader().headers.set('componentId', componentId) }
		);
	}

	visitado(idCliente: string, comprou: boolean, status: string, visitadoPor: string, componentId: string = '') {
		return this.http.post<any>(
			AppConfig.getAPIEndpoint() + '/clientes/visitado/' + this.tokenStorage.getUserInfo().idUsuario + '/' + idCliente,
			{ comprou, status, visitadoPor },
			{ headers: AppConfig.getHTTPHeader().headers.set('componentId', componentId) }
		)
	}

}
