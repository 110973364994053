import {
	AfterViewInit,
	ChangeDetectorRef,
	Component,
	EventEmitter,
	Input,
	OnDestroy,
	OnInit,
	Output
} from '@angular/core';
import {fadeIn, fadeInOut} from '../../../../animations/fadeInOut';
import {
	getDateFirstDayOfMonth,
	getPrevMonthFirstDate,
	getPrevMonthLastDate,
	getPrevYearFirstDate,
	getPrevYearLastDate,
	getThisYearFirstDate,
	getThisYearLastDate,
	getTodayDate,
	getTodayDatePlus
} from '../../../../../core/services/utils.service';
import {Broadcaster, BroadcasterEvents} from '../../../../../core/models/broadcaster';

export const DEFAULT_DATE_OPTIONS = {
	showPlus15Option: false,
	showPlus30Option: false,
	showPlus45Option: false,
	showTodayOption: true,
	showYesterdayOption: true,
	showLastWeekOption: true,
	showLast15DaysOption: true,
	showLast30DaysOption: true,
	showThisMonthOption: true,
	showLastMonthOption: true,

	showDefaultDateOptions: false,
	showPreSelectedOptions: false
};

export interface Filtro {
	title: string;
	id: string;
	showOptions: boolean;
	selectTitle: string;
	isSelectType: boolean;
	isMultiSelecao: boolean;
	multiSelecao: any[];
	singleSelecao: any;
	opcoes: {
		nome: string;
		value: any;
		active: boolean;
		callback: any;
	}[];
}

export interface DateFiltro {
	title?: string;
	id: string;

	showDefaultDateOptions?: boolean;
	showPreSelectedOptions?: boolean;

	selectedDateOption?: string;
	callback?: any;
	showPlus15Option?: boolean;
	showPlus30Option?: boolean;
	showPlus45Option?: boolean;
	showPlus7Option?: boolean;
	showTodayOption?: boolean;
	showYesterdayOption?: boolean;
	showLastWeekOption?: boolean;
	showLast15DaysOption?: boolean;
	showLast30DaysOption?: boolean;
	showThisMonthOption?: boolean;
	showLastMonthOption?: boolean;

	canSelectHour?: boolean;

	preSelectionDateFilterStart?: Date;
	preSelectionDateFilterEnd?: Date;
	preSelectionIsDateAhead?: boolean;
	useDefaultDate?: boolean;
}

@Component({
	selector: 'm-filtro',
	templateUrl: './filtro.component.html',
	styleUrls: ['./filtro.component.scss'],
	animations: [fadeIn, fadeInOut],
})
export class FiltroComponent implements OnInit, AfterViewInit, OnDestroy {

	private static _context: FiltroComponent = null;

	/**
	 * Callbacks
	 */

	@Output() onInputSearch: EventEmitter<any> = new EventEmitter();
	@Output() onDateSelected: EventEmitter<any> = new EventEmitter();
	@Output() onFiltroSelected: EventEmitter<any> = new EventEmitter();
	@Output() onFilterToggle: EventEmitter<any> = new EventEmitter();

	@Input() searchInputDelay: number = 0;
	@Input() filtros: Filtro[] = [];

	@Input() aditionalDateFilters: DateFiltro[] = [];

	/* Date */
	@Input() dateFilterStart: Date;
	@Input() dateFilterEnd: Date;

	/** Fim Callbacks */

	/**
	 * Config
	 */

	@Input() onlyOneDateSelection: boolean = false;

	@Input() callOnDateSelected: boolean = false;

	/* Input search */
	@Input() searchTitle: string = 'Procurar';
	@Input() searchDesc: string = 'Nome, título, etc.';
	@Input() showSearch: boolean = true;

	/* Filtro */
	@Input() showFilter: boolean = true;
	@Input() showDefaultDateFilter: boolean = true;

	/* Date options */
	@Input() dateFilterTitle: string = 'Data';
	@Input() showTodayOption: boolean = true;
	@Input() showYesterdayOption: boolean = true;
	@Input() showLastWeekOption: boolean = true;
	@Input() showLast15DaysOption: boolean = true;
	@Input() showLast30DaysOption: boolean = true;
	@Input() showThisMonthOption: boolean = true;
	@Input() showLastMonthOption: boolean = true;
	@Input() selectedDateOption: string = 'todos';

	/** Fim Config */

	/**
	 * Template vars
	 */
	scroll: number;

	showFilterOptions: boolean = false;
	today: Date = new Date(Date.now());

	searchQuery: string;

	showDefaultDateOptions: boolean = false;
	showPreSelectedOptions: boolean = false;

	/* Pre Selection Fields */
	preSelectionSelectedDateOption: string = null;
	preSelectionDateFilterStart: Date;
	preSelectionDateFilterEnd: Date;
	preSelectionIsDateAhead: boolean = false;

	/* Observables */
	filterObservable;

	constructor(private cdRef: ChangeDetectorRef, private _broadcaster: Broadcaster) {

		FiltroComponent._context = this;

		this.filterObservable = this._broadcaster.on$(BroadcasterEvents.OPEN_FILTER).subscribe((data) => {
			this.toggleFilterOptions();
		});
	}

	ngOnInit(): void {}

	ngOnDestroy() {

		/** Se desinscreve no evento de scroll */
		window.removeEventListener('scroll', this.$scrollPositionListener);

		this._broadcaster.broadcast$(BroadcasterEvents.SHOW_FILTER_BUTTON, false);

		if (!!this.filterObservable) { this.filterObservable.unsubscribe(); }

		if (!!FiltroComponent._context) { FiltroComponent._context = null; }
	}

	ngAfterViewInit(): void {

		/** Se inscreve no evento de scroll */
		window.addEventListener('scroll', this.$scrollPositionListener);

		this._broadcaster.broadcast$(BroadcasterEvents.SHOW_FILTER_BUTTON, true);

		/* if (!!this.aditionalDateFilters) {
			for (const c in this.aditionalDateFilters) {
				this.aditionalDateFilters[c]['personalizadoDateFilterStart'] = getDateFirstDayOfMonth();
				this.aditionalDateFilters[c]['personalizadoDateFilterEnd'] = new Date(Date.now());
			}
		} */
	}

	$scrollPositionListener(event: Event) {

		if (!!FiltroComponent._context) {

			const $ctx = FiltroComponent._context;

			const value = (window.pageYOffset || document.documentElement.scrollTop) - (document.documentElement.clientTop || 0);

			if (value != $ctx.scroll && value < 160) {
				$ctx.scroll = value;
				document.getElementById('m-filtro-container-component').style.marginTop = '-' + $ctx.scroll + 'px';
				const valueCalculated = (190 - $ctx.scroll);
				document.getElementById('m-filtro-container-component').style.height = 'calc(100% - ' + (valueCalculated < 0 ? 0 : valueCalculated) + 'px)';
				$ctx.detectChanges();
			} else if (value >= 160) {
				document.getElementById('m-filtro-container-component').style.marginTop = '-160px';

				if (window.pageYOffset > 395) {
					document.getElementById('m-filtro-container-component').style.height = 'calc(100% - 90px)';
				} else {
					document.getElementById('m-filtro-container-component').style.height = 'calc(100% - 30px)';
				}
			}
		}
	}

	detectChanges() {
		try {
			this.cdRef.detectChanges();
		} catch (err) {  }
	}

	toggleFilterOptions() {
		this.showFilterOptions = !this.showFilterOptions;

		this.preSelectionSelectedDateOption = this.selectedDateOption;

		/* this.preSelectionDateFilterStart = !!this.dateFilterStart ? new Date(this.dateFilterStart.getTime()) : new Date(Date.now());
		this.preSelectionDateFilterEnd = this.dateFilterEnd ? new Date(this.dateFilterEnd.getTime()) : new Date(Date.now());

		if (this.preSelectionDateFilterStart) {
			// this.preSelectionDateFilterStart.setUTCHours(5, 1, 59);
			this.preSelectionDateFilterStart.setUTCHours(3, 0, 0);
		}
		if (this.preSelectionDateFilterEnd) {
			// this.preSelectionDateFilterEnd.setUTCHours(5, 59, 59);
			this.preSelectionDateFilterEnd.setUTCHours(2, 59, 59);
			this.preSelectionDateFilterEnd.setDate(this.preSelectionDateFilterEnd.getDate() + 1);
		} */

		/* for (const adf of (this.aditionalDateFilters || [])) {
			if(adf.useDefaultDate !== false) {
				adf.preSelectionDateFilterStart = new Date(Date.now());
				adf.preSelectionDateFilterEnd = new Date(Date.now());
				// adf.showDefaultDateOptions = true;
				if (adf.canSelectHour) {
					adf.preSelectionDateFilterStart.setHours(0, 0, 0);
					adf.preSelectionDateFilterEnd.setHours(23, 59, 59);
				}	
			}
		} */

		this.showPreSelectedOptions = false;
		this.showDefaultDateOptions = false;

		this._broadcaster.broadcast$(BroadcasterEvents.SHOW_FILTER_BUTTON, !this.showFilterOptions);
		this.onFilterToggle.emit({ shown: this.showFilterOptions });
		this.detectChanges();
	}

	/* tslint:disable */
	searchTimeout = null;
	/* tslint:enable */
	callOnInputSearch(value) {
		if (this.searchTimeout != null) clearTimeout(this.searchTimeout);
		this.searchTimeout = setTimeout(() => {
			this.onInputSearch.emit({ input: value });
		}, this.searchInputDelay || 0);
	}

	simplify(value: string): string {
		if (!value) return '';
		value = value.replace(/[áàâã]/gi, 'a');
		value = value.replace(/[éèêẽ]/gi, 'e');
		value = value.replace(/[íìîĩ]/gi, 'i');
		value = value.replace(/[óòôõ]/gi, 'o');
		value = value.replace(/[úùôõ]/gi, 'u');
		value = value.replace(/[ç]/gi, 'c');
		value = value.replace(/\s/gi, '');
		return value.toLocaleLowerCase();
	}

	changeOption(event, filtro) {
		const opcao = filtro.opcoes.find(op => this.simplify(op.nome || '').includes(this.simplify(event.target.value || '')));
		this.selectFilterOption(opcao, filtro);
	}

	selectFilterOption(opcaoSelecionada, filtro) {

		// if (filtro.isSelectType && !!opcaoSelecionada && !!opcaoSelecionada.callback) {
			// opcaoSelecionada.callback(opcaoSelecionada.value);
		// } else {

			/* Marca todas as opcões como não ativa caso o filtro não seja de multi seleção */
			if (filtro.isMultiSelecao != true) {

				for (let c = 0; c < filtro.opcoes.length; ++c) { filtro.opcoes[c].active = false; }

				/* Marca a opção selecionada como ativa e chama o callback da opção */
				opcaoSelecionada.active = true;
				// opcaoSelecionada.callback(opcaoSelecionada.value);
				filtro.singleSelecao = opcaoSelecionada.value;
			} else {

				opcaoSelecionada.active = !opcaoSelecionada.active;

				this.detectChanges();

				filtro.multiSelecao = [];

				for (const opt of filtro.opcoes) {
					if (opt.active) { filtro.multiSelecao.push(opt.value); }
				}
			}
		// }
	}

	/**
	 * Seleciona uma data com um valor pré-definido;
	 * @param start - Data inicial
	 * @param end - Data final
	 * @param option - Opção selecionada
	 */
	setOptionDateFilter(start, end, option, isDateAhead: boolean = false) {
		// this.selectedDateOption = option;
		// this.dateFilterStart = start;
		// this.dateFilterEnd = end;

		this.preSelectionSelectedDateOption = option;
		this.preSelectionDateFilterStart = start;
		this.preSelectionDateFilterEnd = end;
		this.preSelectionIsDateAhead = isDateAhead;
		this.showPreSelectedOptions = false;
		this.detectChanges();
	}

	/**
	 * Aplica a seleção de Filtro
	 */
	aplicarFiltro() {

		this.selectedDateOption = this.preSelectionSelectedDateOption;

		this.dateFilterStart = this.preSelectionDateFilterStart ? new Date(this.preSelectionDateFilterStart.getTime()) : null;
		this.dateFilterEnd = this.preSelectionDateFilterEnd ? new Date(this.preSelectionDateFilterEnd.getTime()) : null;

		const defaultDate = this.confirmDate(this.preSelectionIsDateAhead);

		if (this.callOnDateSelected == true) {
			this.onDateSelected.emit(defaultDate);
		}

		const resultFiltro = {
			'_searchQuery': this.searchQuery
		};

		for (const _f of this.filtros) {

			if (!!_f.id && _f.id != '') {
				resultFiltro[_f.id] = _f.isMultiSelecao ? _f.multiSelecao : _f.singleSelecao;
			}
		}

		for (const _adf of this.aditionalDateFilters) {
			if (this.onlyOneDateSelection && !_adf.showDefaultDateOptions) continue;
			if (!!_adf.id && _adf.id != '') {

				if (!_adf.canSelectHour) {
					if(_adf.preSelectionDateFilterStart) _adf.preSelectionDateFilterStart.setUTCHours(0, 0, 0);
					if(_adf.preSelectionDateFilterEnd) _adf.preSelectionDateFilterEnd.setUTCHours(23, 59, 59);
				}

				resultFiltro[_adf.id] = {
					dateFilterStart: _adf.preSelectionDateFilterStart,
					dateFilterEnd: _adf.preSelectionDateFilterEnd,
					isDateAhead: _adf.preSelectionIsDateAhead,
					isFilterSelected: _adf.showDefaultDateOptions
				};
			}
		}

		this.onFiltroSelected.emit(resultFiltro);

		this.toggleFilterOptions();
	}

	/**
	 * Confirma a data selecionada
	 */
	confirmDate(isDateAhead: boolean = false) {

		if(this.dateFilterStart) this.dateFilterStart.setUTCHours(0, 0, 0);
		if(this.dateFilterEnd) this.dateFilterEnd.setUTCHours(23, 59, 59);

		return {
			dateFilterStart: this.dateFilterStart,
			dateFilterEnd: this.dateFilterEnd,
			isDateAhead: isDateAhead
		};
	}

	/**
	 * Chama o callback do filtro de data adicional
	 */
	selectAditionalDateOption(adf, start, end, option, isDateAhead: boolean = false) {

		/* Marca todas as opcões como não ativa
		for (let c = 0; c < this.aditionalDateFilters.length; ++c) { this.aditionalDateFilters[c].selectedDateOption = 'todos'; }

		this.dateFilterStart = start;
		this.dateFilterEnd = end;
		*/

		/* Marca a opção selecionada como ativa e chama o callback da opção
		adf.selectedDateOption = option;
		adf.callback({ dateFilterStart: this.dateFilterStart, dateFilterEnd: this.dateFilterEnd, isDateAhead: isDateAhead });
		*/

		adf.selectedDateOption = option;
		adf.preSelectionDateFilterStart = start;
		adf.preSelectionDateFilterEnd = end;
		adf.preSelectionIsDateAhead = isDateAhead;
		adf.showPreSelectedOptions = false;

		if (adf.canSelectHour) {
			adf.preSelectionDateFilterStart.setHours(0, 0, 0);
			adf.preSelectionDateFilterEnd.setHours(23, 59, 59);
		}

		this.detectChanges();
	}

	toggleAdditionalDateOptions(adf) {

		let showFilter = !adf.showDefaultDateOptions;

		/**
		 * Se esse filtro foi configurado para somente uma seleção de data e
		 * essa opção foi selecionada, fecha a exibição dos outros filtros de data;
		 */
		if (showFilter && this.onlyOneDateSelection) {
			for (let a of (this.aditionalDateFilters || [])) a.showDefaultDateOptions = false;
		}

		adf.showDefaultDateOptions = showFilter;

		this.detectChanges();
	}

	/* tslint:disable */
	getTodayDate = getTodayDate;
	getTodayDatePlus = getTodayDatePlus;
	getDateFirstDayOfMonth = getDateFirstDayOfMonth;
	getPrevMonthFirstDate = getPrevMonthFirstDate;
	getPrevMonthLastDate = getPrevMonthLastDate;
	getPrevYearFirstDate = getPrevYearFirstDate;
	getPrevYearLastDate = getPrevYearLastDate;
	getThisYearFirstDate = getThisYearFirstDate;
	getThisYearLastDate = getThisYearLastDate;
	/* tslint:enable */

}
