import {SelectValue} from '../interface/SelectValue';

export enum TipoPagamento {
	BOLETO = 'boleto',
	CARTAO_DEBITO = 'cartao_debito',
	CARTAO_CREDITO = 'cartao_credito',
	DINHEIRO = 'dinheiro',
	VALE_GAS = 'valeGas',
	CHEQUE = 'cheque',
	OUTROS = 'outros',
	CARTAO_TODOS = 'cartao_todos',
	VENDA_ANTECIPADA = 'venda_antecipada',
	FIADO = 'fiado',
	TRANSFERENCIA = 'transferencia',
	REQUISICAO = 'requisicao',
	TODOS = 'todos',
	CONVENIO = 'convenio',
	DEBITO_AUTOMATICO = 'debito_automatico',
	CHEQUE_PRE = 'cheque_pre',
	PIX = 'pix',
	VALE_AGUA = 'vale_agua',
	PICPAY = 'picpay'
}

export const TipoPagamentoSelectValues: SelectValue<TipoPagamento>[] = [
	{ value: TipoPagamento.BOLETO, text: 'Boleto' },
	{ value: TipoPagamento.CARTAO_DEBITO, text: 'Cartão de Débito' },
	{ value: TipoPagamento.CARTAO_CREDITO, text: 'Cartão de Crédito' },
	{ value: TipoPagamento.DINHEIRO, text: 'Dinheiro' },
	{ value: TipoPagamento.VALE_GAS, text: 'Vale-Gás' },
	{ value: TipoPagamento.CHEQUE, text: 'Cheque' },
	{ value: TipoPagamento.TRANSFERENCIA, text: 'Transferência' },
	{ value: TipoPagamento.REQUISICAO, text: 'Requisição' },
	{ value: TipoPagamento.OUTROS, text: 'Outros' },
	{ value: TipoPagamento.CONVENIO, text: 'Convênio' },
	{ value: TipoPagamento.DEBITO_AUTOMATICO, text: 'Débito Automático' },
	{ value: TipoPagamento.CHEQUE_PRE, text: 'Cheque Pré' },
	{ value: TipoPagamento.PIX, text: 'PIX' },
	{ value: TipoPagamento.VALE_AGUA, text: 'Vale Água' },
	{ value: TipoPagamento.PICPAY, text: 'PicPay' }
];

