import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { TokenStorage } from '../../auth/token-storage.service';
import { Empresa } from '../../models/api/Empresa';
import { Observable } from 'rxjs';
import { AppConfig } from '../../../config/appConfig';
import { Loja } from '../../models/api/Loja';


@Injectable()
export class EstabelecimentosService {

	private ROUTE: string;

	constructor(private http: HttpClient, private tokenStorage: TokenStorage) {
		this.ROUTE = AppConfig.getAPIEndpoint() + '/estabelecimentos'
	}

	obterFaturas(ids: string[], componentId: string = ''): Observable<(Empresa|Loja)[]> {
		return this.http.get<(Empresa|Loja)[]>(
			this.ROUTE + '/faturas',
			{
				headers: AppConfig.getHTTPHeader().headers.set('componentId', componentId),
				params: {
					ids: ids.join(',')
				}
			}
		);
	}

}