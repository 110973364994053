import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { ServicePattern } from '../../../models/interface/ServicePattern';
import { Aplicativo } from '../../../models/api/aplicativo/Aplicativo';
import { EstabelecimentoFunctions } from '../../../models/interface/EstabelecimentoFunctions';
import { ResultObject } from '../../../models/api/ResultObject';
import { AppConfig } from '../../../../config/appConfig';
import { TokenStorage } from '../../../auth/token-storage.service';
import { DateFilter } from '../../../models/interface/DateFilter';
import { Cliente } from '../../../models/api/Cliente';
import { AppNotification } from '../../../interfaces/app-notification';

@Injectable()
export class AplicativoConfigService extends EstabelecimentoFunctions implements ServicePattern<Aplicativo> {

	readonly ROUTE = `${AppConfig.getAPIEndpoint()}/aplicativo/config`;

	constructor(private http: HttpClient, private tokenStorage: TokenStorage) {
		super();
	}

	getAll(
		dateFilter: DateFilter = { dataInicio: null, dataFim: null, campo: null },
		page: number = null,
		perPage: number = null,
		componentId: string = ''
	): Observable<Aplicativo[]> {
		return this.http.get<Aplicativo[]>(
			`${this.ROUTE}/${this.tokenStorage.getUserInfo().idUsuario}`,
			{
				headers: AppConfig.getHTTPHeader().headers.set('componentId', componentId),
				params: {
					...this.getEstabelecimentoQueryParam(),
					...this.getDateFilterQueryParam(dateFilter.dataInicio, dateFilter.dataFim),
					...(page || page == 0 ? { page: page + 1 } : {}),
					...(perPage ? { per_page: perPage } : {})
				}
			}
		);
	}

	save(body: Aplicativo, componentId: string = ''): Observable<ResultObject<Aplicativo>> {
		return this.http.post<ResultObject<any>>(
			`${this.ROUTE}/${this.tokenStorage.getUserInfo().idUsuario}`,
			body,
			{ headers: AppConfig.getHTTPHeader().headers.set('componentId', componentId) }
		);
	}

	update(body: Aplicativo, componentId: string = ''): Observable<ResultObject<any>> {
		return this.http.put<ResultObject<any>>(
			`${this.ROUTE}/${this.tokenStorage.getUserInfo().idUsuario}`,
			body,
			{ headers: AppConfig.getHTTPHeader().headers.set('componentId', componentId) }
		);
	}

	delete(_id: string, componentId: string = ''): Observable<ResultObject<any>> {
		return this.http.delete<ResultObject<any>>(
			`${this.ROUTE}/${this.tokenStorage.getUserInfo().idUsuario}/${_id}`,
			{ headers: AppConfig.getHTTPHeader().headers.set('componentId', componentId) }
		);
	}

	notification(body: AppNotification, idApp: string, componentId: string = ''): Observable<any> {
		return this.http.post<any>(
			`${this.ROUTE}/notification/${this.tokenStorage.getUserInfo().idUsuario}/${idApp}`,
			body,
			{ headers: AppConfig.getHTTPHeader().headers.set('componentId', componentId) }
		);
	}

	getNotifications(idApp: string, componentId: string = '', page = 1, per_page = 25): Observable<any> {
		return this.http.get<any>(
			`${this.ROUTE}/notification/${this.tokenStorage.getUserInfo().idUsuario}/${idApp}`,
			{
				headers: AppConfig.getHTTPHeader().headers.set('componentId', componentId),
				params: {
					page: page.toString(),
					per_page: per_page.toString()
				}
			}
		);
	}

}
