import {Fornecedor} from './Fornecedor';
import {DefaultFields} from '../interface/DefaultFields';
import {Estabelecimento} from '../template/Estabelecimento';
import {Estoque} from './Estoque';
import {Pedido} from './Pedido';
import {TipoEstoque} from '../enum/TipoEstoque';
import {ValorNegociado} from './ValorNegociado';
import {Empresa} from './Empresa';
import {Loja} from './Loja';

export class Produto extends DefaultFields<Produto> {
	idProduto: any;
	nome: string;
	descricao: string;
	codigo: string;
	valorCheio: number;
	valorVazio: number;
	valorCustoCheio: number;
	valorCustoVazio: number;
	valorKG: number;
	peso: number;
	marca: string;
	thumbnailUrl: string;
	imagensUrl: string[];
	quantidade: number;
	isDefault: boolean;
	isControlado: boolean;
	fornecedor: Fornecedor;
	valorNota: number;
	quantidadeNota: number;
	tipoEstoque: TipoEstoque;
	tipoProdutoEstoque: TipoEstoque;
	isUnico: boolean;
	estoques: Estoque[];
	pedidos: Pedido[];
	valorUnitario: number;
	categoria: string;
	categoriaObj: Object;
	desconto: number;
	numeroPedido: string;
	itemPedido?: string;
	empresa?: Empresa;
	loja?: Loja;
	unCom: string;
	customTitle?: string;
	cfopInputValue: string;

	notaFiscal_nome_cheio: string;
	notaFiscal_nome_vazio: string;
	notaFiscal_nome_unico: string;

	empresas: Empresa[];
	lojas: Loja[];

	valorNegociados: ValorNegociado[];

	_configLimiteValorNegociado: number;

	cfop_ESTOQUE_CHEIO_PF: String;
	cfop_ESTOQUE_CHEIO_PJ: String;
	cfop_SAIDA_ESTOQUE_CHEIO_PF: String;
	cfop_SAIDA_ESTOQUE_CHEIO_PJ: String;

	cfop_OUTROS_ESTADOS_ESTOQUE_CHEIO_PF: String;
	cfop_OUTROS_ESTADOS_ESTOQUE_CHEIO_PJ: String;
	cfop_OUTROS_ESTADOS_SAIDA_ESTOQUE_CHEIO_PF: String;
	cfop_OUTROS_ESTADOS_SAIDA_ESTOQUE_CHEIO_PJ: String;

	cfop_ESTOQUE_VAZIO_PF: String;
	cfop_ESTOQUE_VAZIO_PJ: String;
	cfop_SAIDA_ESTOQUE_VAZIO_PF: String;
	cfop_SAIDA_ESTOQUE_VAZIO_PJ: String;

	cfop_OUTROS_ESTADOS_ESTOQUE_VAZIO_PF: String;
	cfop_OUTROS_ESTADOS_ESTOQUE_VAZIO_PJ: String;
	cfop_OUTROS_ESTADOS_SAIDA_ESTOQUE_VAZIO_PF: String;
	cfop_OUTROS_ESTADOS_SAIDA_ESTOQUE_VAZIO_PJ: String;

	cfop_ESTOQUE_UNICO_PF: String;
	cfop_ESTOQUE_UNICO_PJ: String;
	cfop_SAIDA_ESTOQUE_UNICO_PF: String;
	cfop_SAIDA_ESTOQUE_UNICO_PJ: String;

	cfop_OUTROS_ESTADOS_SESTOQUE_UNICO_PF: String;
	cfop_OUTROS_ESTADOS_SESTOQUE_UNICO_PJ: String;
	cfop_OUTROS_ESTADOS_SSAIDA_ESTOQUE_UNICO_PF: String;
	cfop_OUTROS_ESTADOS_SSAIDA_ESTOQUE_UNICO_PJ: String;

	cest_ESTOQUE_CHEIO: String;
	cest_ESTOQUE_VAZIO: String;
	cest_ESTOQUE_UNICO: String;

	ncm_ESTOQUE_CHEIO: String;
	ncm_ESTOQUE_VAZIO: String;
	ncm_ESTOQUE_UNICO: String;

	cfop_REMESSA_ESTOQUE_CHEIO: String;
	cfop_REMESSA_OUTROS_ESTADOS_ESTOQUE_CHEIO: String;

	cfop_REMESSA_ESTOQUE_VAZIO: String;
	cfop_REMESSA_OUTROS_ESTADOS_ESTOQUE_VAZIO: String;

	cfop_REMESSA_ESTOQUE_UNICO: String;
	cfop_REMESSA_OUTROS_ESTADOS_ESTOQUE_UNICO: String;

	especie_ESTOQUE_CHEIO: string;
	especie_ESTOQUE_VAZIO: string;
	especie_ESTOQUE_UNICO: string;

	pesoBrutoKG_ESTOQUE_CHEIO: string;
	pesoBrutoKG_ESTOQUE_VAZIO: string;
	pesoBrutoKG_ESTOQUE_UNICO: string;

	pesoLiquidoKG_ESTOQUE_CHEIO: string;
	pesoLiquidoKG_ESTOQUE_VAZIO: string;
	pesoLiquidoKG_ESTOQUE_UNICO: string;

	app_mostrarNoApp?: boolean;
	app_mostrarEstoqueCheio?: boolean;
	app_mostrarEstoqueVazio?: boolean;
	app_mostrarEstoqueUnico?: boolean;
	app_nomeCheio?: string;
	app_nomeVazio?: string;
	app_nomeUnico?: string;

	codANP_ESTOQUE_CHEIO?: string;
	codANP_ESTOQUE_VAZIO?: string;
	codANP_ESTOQUE_UNICO?: string;

	descANP_ESTOQUE_CHEIO?: string;
	descANP_ESTOQUE_VAZIO?: string;
	descANP_ESTOQUE_UNICO?: string;


	ufConsumo_ESTOQUE_CHEIO?: string;
	ufConsumo_ESTOQUE_VAZIO?: string;
	ufConsumo_ESTOQUE_UNICO?: string;

	unTrib_ESTOQUE_CHEIO?: string;
	unTrib_ESTOQUE_VAZIO?: string;
	unTrib_ESTOQUE_UNICO?: string;

	unCom_ESTOQUE_CHEIO?: string;
	unCom_ESTOQUE_VAZIO?: string;
	unCom_ESTOQUE_UNICO?: string;

	/* Request Params */
	associarFornecedor: boolean;
	estabelecimento: Estabelecimento;
	estoqueSelecionado: Estoque;
	cfop: string;

	valorNegociado: number;
	valorCompleto: number;
	valorCusto: number;
	usarValorNegociado: boolean;
	fornecedorIdFornecedor: number;

	/** Template Var */
	tipoEstoqueToRender: TipoEstoque;
	valorProduto: number;

	constructor() {
		super();
		this.isDefault = false;
		this.desconto = 0;
	}
}
