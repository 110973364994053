import { Injectable } from '@angular/core';
import { HttpHeaders } from '@angular/common/http';
import { environment } from '../../environments/environment';
import * as L from 'leaflet';
// @ts-ignore
import _package from '../../../package.json';
import { TileLayer } from 'leaflet';
import { Control } from 'leaflet';
import { Marker } from 'leaflet';
import { Icon } from 'leaflet';

@Injectable()
export class AppConfig {

	public static readonly CONFIG = {

		/** Define se o ENDPOINT de desenvolvimento é local ou remoto */
		localDev: false,

		/** Versão atual do Callcenter */
		version: _package.version || 'versão não encontrada',

		/** Build atual do Callcenter */
		build: _package.build || 0,
	};

	/* Endpoints */
	private static readonly ENDPOINT_PROD = localStorage.endpoint || 'https://api.vendergas.com.br';
	private static readonly ENDPOINT_REMOTO_DEV = localStorage.endpoint || 'https://vendergas.duckdns.org';
	//private static readonly ENDPOINT_LOCAL_DEV = localStorage.endpoint || 'https://api.vendergas.com.br';
	//private static readonly ENDPOINT_LOCAL_DEV = 'https://vendergas.duckdns.org';
	private static readonly ENDPOINT_LOCAL_DEV = 'http://localhost:3000';
	private static readonly ENDPOINT_LOCATION = 'https://search.vendergas.com.br';

	public static readonly isOnDevelopment = !environment.production;

	/**
	 * Google Maps Keys
	 */
	public static readonly GOOGLE_MAPS_API_KEY = null;

	/**
	 * Retorna o Endpoint da API baseado no ambiente
	 */
	static getAPIEndpoint(): string {
		if (localStorage.getItem('__endpoint__')) return localStorage.getItem('__endpoint__');
		if (!environment.production) { return this.CONFIG.localDev ? this.ENDPOINT_LOCAL_DEV : this.ENDPOINT_REMOTO_DEV; }
		return this.ENDPOINT_PROD;
	}

	static getLocationEndpoint(): string {
		return this.ENDPOINT_LOCATION;
	}

	/**
	 * Retorna a versão da aplicação baseada no ambiente
	 * Ex: 0.0.1.debug para ambiente de desenvolvimento e 0.0.1 para ambiente de produção
	 */
	public static getVersion(): string {
		if (!environment.production) { return `${this.CONFIG.version}.DEV`; }
		return this.CONFIG.version;
	}

	/**
	 * Retorna a informação de build
	 */
	public static getBuild(): string | number { return this.CONFIG.build; }

	/**
	 * Retorna um json com um objeto HttpHeaders para request http
	 */
	static getHTTPHeader() {
		return {
			headers: new HttpHeaders({
				'Content-Type': 'application/json',
				'Cache-Control': 'no-cache'
			})
		};
	}

	static getLeafletZoomOptions(): Control.ZoomOptions { return { zoomInTitle: 'Aproximar', zoomOutTitle: 'Reduzir' }; }

	static getLeafletTileLayer(): TileLayer {
		/*
		return L.tileLayer('https://api.tiles.mapbox.com/v4/{id}/{z}/{x}/{y}.png?access_token={accessToken}', {
			attribution: 'Map data &copy; <a href="https://www.openstreetmap.org/">OpenStreetMap</a> contributors, <a href="https://creativecommons.org/licenses/by-sa/2.0/">CC-BY-SA</a>, Imagery © <a href="https://www.mapbox.com/">Mapbox</a>',
			maxZoom: 18,
			id: 'mapbox.streets',
			accessToken: 'pk.eyJ1IjoidmVuZGVyZ2FzIiwiYSI6ImNrMHkxODYyeDAwZnIzZG82bHdoNXZvYmUifQ.GAtt75LEoJXdxwU7FvKujw'
		});
		*/
		return L.tileLayer('https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png', {
			attribution: '&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
		});
	}

	static setMarkerIcon(marker: Marker, icon: string = './assets/app/media/img/icons/marker-icon.png'): Marker {
		return marker.setIcon(new Icon({
			iconUrl: icon,
			iconAnchor: [12, 41],
			iconSize: [25, 41],
			popupAnchor: [1, -34],
			shadowSize: [41, 41],
			shadowUrl: 'marker-shadow.png',
			tooltipAnchor: [16, -28]
		}));
	}

}
