import { Injectable } from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {TokenStorage} from '../../auth/token-storage.service';
import {EstabelecimentoFunctions} from '../../models/interface/EstabelecimentoFunctions';
import {Observable} from 'rxjs';
import {AppConfig} from '../../../config/appConfig';

@Injectable()
export class HistoricosService extends EstabelecimentoFunctions {

	private readonly ROUTE = '/historicoAcoes';

	constructor(private http: HttpClient, private tokenStorage: TokenStorage) {
		super();
	}

	getAll(dateFilter: { dataInicio: Date, dataFim: Date } = { dataInicio: null, dataFim: null }, page: number = null, perPage: number = null, componentId: string = ''): Observable<any[]> {
		return this.http.get<any[]>(
			AppConfig.getAPIEndpoint() + this.ROUTE + '/list/' + this.tokenStorage.getUserInfo().idUsuario,
			{
				headers: AppConfig.getHTTPHeader().headers.set('componentId', componentId),
				params: {
					...this.getEstabelecimentoQueryParam(),
					...this.getDateFilterQueryParam(dateFilter.dataInicio, dateFilter.dataFim),
					...(page || page == 0 ? { page: page + 1 } : {}),
					...(perPage ? { per_page: perPage } : {})
				}
			}
		);
	}


}
