import {Injectable} from '@angular/core';
import {EstabelecimentoFunctions} from '../../models/interface/EstabelecimentoFunctions';
import {ServicePattern} from '../../models/interface/ServicePattern';
import {HttpClient} from '@angular/common/http';
import {TokenStorage} from '../../auth/token-storage.service';
import {Observable} from 'rxjs';
import {AppConfig} from '../../../config/appConfig';
import {ResultObject} from '../../models/api/ResultObject';
import {Despesa} from '../../models/api/Despesa';
import {DateFilter} from '../../models/interface/DateFilter';
import { Estabelecimento } from '../../models/template/Estabelecimento';

export class DespesaFilter {

	idContaDespesa: string;

	constructor(params: any = {}) {
		if (!params) params = {};
		this.idContaDespesa = params._id || null;
	}

}

@Injectable()
export class DespesasService extends EstabelecimentoFunctions implements ServicePattern<Despesa> {

	readonly ROUTE = '/despesas';

	constructor(private http: HttpClient, private tokenStorage: TokenStorage ) {
		super();
	}

	getAll(
		dateFilter: DateFilter = {dataInicio: null, dataFim: null, campo: null},
		componentId: string = '',
		despesaFilter: DespesaFilter = new DespesaFilter(),
		page: number = null,
		pageSize: number = null,
		searchString?: string,
		naoMostrarNoDRE?: boolean
	): Observable<Despesa[]> {
		return this.http.get<Despesa[]>(
			AppConfig.getAPIEndpoint() + this.ROUTE + '/list/' + this.tokenStorage.getUserInfo().idUsuario,
			{
				headers: AppConfig.getHTTPHeader().headers.set('componentId', componentId),
				params: {
					...this.getEstabelecimentoQueryParam(),
					...this.getDateFilterWithFieldQueryParam(dateFilter.campo, dateFilter.dataInicio, dateFilter.dataFim),
					...(despesaFilter.idContaDespesa ? { _id: despesaFilter.idContaDespesa } : {}),
					...(page || page == 0 ? { page: page + 1 } : {}),
					...(pageSize ? { per_page: pageSize } : {}),
					...(searchString != null ? { searchString: searchString } : {}),
					...(naoMostrarNoDRE != null ? { naoMostrarNoDRE } : {})
				}
			}
		);
	}

	get(idDespesa: number): Observable<Despesa> {
		// TODO
		return null;
	}

	save(body: Despesa, getEstabelecimentoFromCache: boolean = true, componentId: string = ''): Observable<ResultObject<any>> {
		if (getEstabelecimentoFromCache) { body.estabelecimento = this.getEstabelecimentoOnCache(); }
		return this.http.post<ResultObject<any>>(
			AppConfig.getAPIEndpoint() + this.ROUTE + '/' + this.tokenStorage.getUserInfo().idUsuario,
			body,
			{headers: AppConfig.getHTTPHeader().headers.set('componentId', componentId)}
		);
	}

	update(body: Despesa, componentId: string = ''): Observable<ResultObject<any>> {
		return this.http.put<ResultObject<any>>(
			AppConfig.getAPIEndpoint() + this.ROUTE + '/' + this.tokenStorage.getUserInfo().idUsuario,
			body,
			{headers: AppConfig.getHTTPHeader().headers.set('componentId', componentId)}
		);
	}

	delete(idDespesa: number, componentId: string = ''): Observable<ResultObject<any>> {
		return this.http.delete<ResultObject<any>>(
			AppConfig.getAPIEndpoint()  + this.ROUTE + '/' +
			this.tokenStorage.getUserInfo().idUsuario +
			'/' + idDespesa,
			{
				headers: AppConfig.getHTTPHeader().headers.set('componentId', componentId)
			}
		);
	}

	getEntriesCount(componentId: string = '', estabelecimento?: Estabelecimento): Observable<{entries: number}> {
		return this.http.get<{entries: number}>(
			AppConfig.getAPIEndpoint() + this.ROUTE + '/list/' + this.tokenStorage.getUserInfo().idUsuario,
			{
				headers: AppConfig.getHTTPHeader().headers.set('componentId', componentId),
				params: {
					entriesOnly: 'true',
					...(estabelecimento ? estabelecimento.tipoEstabelecimento == this.tipoEstabelecimento.EMPRESA ? { empresa: estabelecimento.empresa._id } : { loja: estabelecimento.loja._id } : {})
				}
			}
		);
	}

	getEntriesOnlyQueryParam(isFirstQuery: boolean = false): string {
		const _s = isFirstQuery ? '?' : '&';
		return _s + 'entriesOnly=true';
	}

}
