export class ResultObject<T> {

	success?: boolean;
	title?: string;
	message?: string;
	shown?: boolean;
	object?: T;

	constructor(success: boolean = false, title: string = null, message: string = null, shown: boolean = false, object: T = null) {
		this.success = success;
		this.title = title;
		this.message = message;
		this.shown = shown;
		this.object = object;
	}
}
