export class EventEmitter {
	private events: Map<string, Function[]> = new Map();
	
	emit (topic: string, ...args: any[]) {
		const events = this.events.get(topic);
		if(Array.isArray(events)) {
			for(const event of events) {
				event(...args);
			}
		}
	}

	on (topic: string, cb: Function) {
		const oldEvents = this.events.get(topic);
		if(this.events.has(topic)) {
			return this.events.set(topic, [...oldEvents, cb]);			
		}
		return this.events.set(topic, [cb]);
	}

	off (topic: string, cb?: Function) {
		const currentEvents = this.events.get(topic);
		if(cb) {
			return this.events.set(topic, currentEvents.filter(event => event != cb));
		}
		return this.events.delete(topic);
	}

	once(topic: string, cb: Function) {
		this.on(topic, (...args: any[]) => {
			const res = cb(...args);
			this.off(topic, cb);
			return res;
		});
	}
}

export const bus = new EventEmitter();