import {BehaviorSubject, Observable, Subject, from, throwError} from 'rxjs';
import {map, catchError, tap, switchMap} from 'rxjs/operators';

import {Injectable} from '@angular/core';
import {HttpClient, HttpErrorResponse} from '@angular/common/http';
import {AuthService} from 'ngx-auth';

import {TokenStorage} from './token-storage.service';
import {AccessData} from '../models/api/AccessData';
import {Credential} from './credential';
import {AppConfig} from '../../config/appConfig';
import {Router} from '@angular/router';
import {SocketService} from '../services/api/socket.service';
import {SocketLocationService} from '../services/api/socketLocation.service';
import {Broadcaster, BroadcasterEvents} from '../models/broadcaster';

@Injectable()
export class AuthenticationService implements AuthService {

	constructor(
		private http: HttpClient,
		private tokenStorage: TokenStorage,
		private _router: Router,
		private _broadcaster: Broadcaster
	) {

	}

	/**
	 * Check, if user already authorized.
	 * @description Should return Observable with true or false values
	 * @returns {Observable<boolean>}
	 * @memberOf AuthService
	 */
	public isAuthorized(): Observable<boolean> {
		return this.tokenStorage.getAccessToken().pipe(map(token => !!token));
	}

	/**
	 * Get access token
	 * @description Should return access token in Observable from e.g. localStorage
	 * @returns {Observable<string>}
	 */
	public getAccessToken(): Observable<string> {
		return this.tokenStorage.getAccessToken();
	}

	/**
	 * Function, that should perform refresh token verifyTokenRequest
	 * @description Should be successfully completed so interceptor
	 * can execute pending requests or retry original one
	 * @returns {Observable<any>}
	 */
	public refreshToken(): Observable<any> {

		return this.tokenStorage.getUserInfoObservable()
			.pipe(
				map(userInfo => {
					if (userInfo == null) { this.logout(); }

					/* Verifica se o token do usuario ainda está ativo */
					if (Date.now() > userInfo.tokenExpiration) {
						this.logout();
					}
				}),
				catchError(err => {
					this.logout();
					return throwError(err);
				})
			);

		/*
		return this.tokenStorage.getRefreshToken().pipe(
			switchMap((refreshToken: string) => {
				return this.http.get<AccessData>(this.API_URL + this.API_ENDPOINT_REFRESH + '?' + this.util.urlParam(refreshToken));
			}),
			tap(this.saveAccessData.bind(this)),
			catchError(err => {
				this.logout();
				return throwError(err);
			})
		);
		*/
	}

	/**
	 * Function, checks response of failed request to determine,
	 * whether token be refreshed or not.
	 * @description Essentialy checks status
	 * @param {Response} response
	 * @returns {boolean}
	 */
	public refreshShouldHappen(response: HttpErrorResponse): boolean {
		return response.status === 401;
	}

	/**
	 * Verify that outgoing request is refresh-token,
	 * so interceptor won't intercept this request
	 * @param {string} url
	 * @returns {boolean}
	 */
	public verifyTokenRequest(url: string): boolean {
		//return url.endsWith(this.API_ENDPOINT_REFRESH);
		return false;
	}

	/**
	 * Submit login request
	 * @param {Credential} credential
	 * @returns {Observable<any>}
	 */
	public login(credential: Credential): Observable<any> {
		return this.http.post<AccessData>(
			AppConfig.getAPIEndpoint() + '/usuarios/auth',
			credential,
			AppConfig.getHTTPHeader()
		);
		/*
		.pipe(
			map((result: any) => {
				if (result instanceof Array) {
					return result.pop();
				}
				return result;
			}),
			tap(this.saveUserCredentials.bind(this)),
			catchError(this.handleError('login', []))
		);
		*/
	}

	/**
	 * Handle Http operation that failed.
	 * Let the app continue.
	 * @param operation - name of the operation that failed
	 * @param result - optional value to return as the observable result
	 */
	private handleError<T>(operation = 'operation', result?: any) {
		return (error: any): Observable<any> => {
			// TODO: send the error to remote logging infrastructure
			console.error(error); // log to console instead

			// Let the app keep running by returning an empty result.
			return from(result);
		};
	}

	/**
	 * Logout
	 */
	public logout(redirect: boolean = true): void {
		SocketService.disconnectSocket();
		SocketLocationService.disconnectSocket();

		localStorage.setItem(TokenStorage.IS_LOGGED, 'false');

		this.tokenStorage.clear();

		this._broadcaster.broadcast$(BroadcasterEvents.ON_LOGOUT, {});

		this._router.navigate(['/login']);
		//location.reload(redirect);
	}

	/**
	 * Save access data in the storage
	 * @private
	 * @param {AccessData} data
	 */
	private saveUserCredentials(accessData: AccessData) {
		if (typeof accessData !== 'undefined') {
			this.tokenStorage
				.setAccessToken(accessData.token)
				.setUserInfo(accessData.user);
			//this.onCredentialUpdated$.next(accessData);
		}
	}

	/**
	 * Submit registration request
	 * @param {Credential} credential
	 * @returns {Observable<any>}
	 */
	public register(credential: Credential): Observable<any> {
		// dummy token creation
		credential = Object.assign({}, credential, {
			accessToken: 'access-token-' + Math.random(),
			refreshToken: 'access-token-' + Math.random(),
			roles: ['USER'],
		});
		//return this.http.post(this.API_URL + this.API_ENDPOINT_REGISTER, credential).pipe(catchError(this.handleError('register', [])));
		return;
	}

	/**
	 * Submit forgot password request
	 * @param {Credential} credential
	 * @returns {Observable<any>}
	 */
	public requestPassword(credential: Credential): Observable<any> {

		return this.http.post<AccessData>(
			AppConfig.getAPIEndpoint() + '/usuarios/solicitar_senha',
			credential,
			AppConfig.getHTTPHeader()
		);
	}

	/**
	 * Submit recover password
	 * @param {Token} token
	 * @returns {Observable<any>
	*/

	public recoverPassword(credential: Credential): Observable<any> {
		return this.http.put<AccessData>(
			AppConfig.getAPIEndpoint() + '/usuarios/recuperar_senha',
			credential,
			AppConfig.getHTTPHeader()
		);
	}
}
