import {Injectable} from '@angular/core';
import {ServicePattern} from '../../../models/interface/ServicePattern';
import {HttpClient} from '@angular/common/http';
import {Observable} from 'rxjs';
import {AppConfig} from '../../../../config/appConfig';
import {ResultObject} from '../../../models/api/ResultObject';
import {EstabelecimentoFunctions} from '../../../models/interface/EstabelecimentoFunctions';
import {$getUserInfo} from '../../../auth/token-storage.service';
import {DateFilter} from '../../../models/interface/DateFilter';
import {BoletoConfig} from '../../../models/api/boleto/BoletoConfig';
import {Estabelecimento} from '../../../models/template/Estabelecimento';

export class BoletoConfigFilter {
	estabelecimento?: Estabelecimento;

	constructor(params: BoletoConfigFilter = {}) {
		if (!params) params = {};
		this.estabelecimento = params.estabelecimento;
	}
}

@Injectable()
export class BoletoConfigService extends EstabelecimentoFunctions implements ServicePattern<BoletoConfig> {

	readonly ENDPOINT = `${AppConfig.getAPIEndpoint()}/boleto/configuracao`;

	readonly USER_ID = $getUserInfo().idUsuario;

	constructor(private http: HttpClient) {
		super();
	}

	getAll(
		dateFilter: DateFilter = <DateFilter>{},
		componentId: string = '',
		page: number = null,
		perPage: number = null,
		boletoConfigFilter: BoletoConfigFilter = new BoletoConfigFilter()
	): Observable<BoletoConfig[]> {
		return this.http.get<BoletoConfig[]>(
			`${this.ENDPOINT}/list/${this.USER_ID}`,
			{
				headers: AppConfig.getHTTPHeader().headers.set('componentId', componentId),
				params: {
					...this.getEstabelecimentoQueryParam(boletoConfigFilter.estabelecimento),
					...this.getDateFilterQueryParam(dateFilter.dataInicio, dateFilter.dataFim),
					...(page || page == 0 ? { page: page + 1 } : {}),
					...(perPage ? { per_page: perPage } : {})
				}
			}
		);
	}

	get(_id: string, componentId: string = ''): Observable<BoletoConfig> {
		return this.http.get<BoletoConfig>(
			`${this.ENDPOINT}/info/${this.USER_ID}/${_id}`,
			{
				headers: AppConfig.getHTTPHeader().headers.set('componentId', componentId)
			}
		);
	}

	save(body: BoletoConfig, getEstabelecimentoFromCache: boolean = true, componentId: string = ''): Observable<ResultObject<any>> {
		if (getEstabelecimentoFromCache) body.estabelecimento = this.getEstabelecimentoOnCache();
		return this.http.post<ResultObject<any>>(
			`${this.ENDPOINT}/${this.USER_ID}`,
			body,
			{
				headers: AppConfig.getHTTPHeader().headers.set('componentId', componentId),
			}
		);
	}

	update(body: BoletoConfig, componentId: string = ''): Observable<ResultObject<any>> {
		return this.http.post<ResultObject<any>>(
			`${this.ENDPOINT}/${this.USER_ID}`,
			body,
			{
				headers: AppConfig.getHTTPHeader().headers.set('componentId', componentId)
			}
		);
	}

	delete(_id: string, componentId: string = ''): Observable<ResultObject<any>> {
		return this.http.delete<ResultObject<any>>(
			`${this.ENDPOINT}/${this.USER_ID}/${_id}`,
			{
				headers: AppConfig.getHTTPHeader().headers.set('componentId', componentId)
			}
		);
	}

}
