import {
	AfterViewInit,
	ChangeDetectorRef,
	Component,
	EventEmitter,
	Input,
	OnDestroy,
	OnInit,
	Output
} from '@angular/core';
import { ClientesService } from '../../../../core/services/api/clientes.service';
import {
	Cliente,
	ClienteAlerta,
	ClienteAlertaTipo,
	TipoCFOP,
	TipoCFOPSelectValues
} from '../../../../core/models/api/Cliente';
import { TokenStorage } from '../../../../core/auth/token-storage.service';
import { FormControl, Validators } from '@angular/forms';
import { EMPTY, merge, Observable } from 'rxjs';
import { map, startWith } from 'rxjs/operators';
import { Endereco, getObjectFromViaCEPParams } from '../../../../core/models/api/Endereco';

import { Produto } from '../../../../core/models/api/Produto';
import { ProdutosService } from '../../../../core/services/api/produtos.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { FormaPagamento, Pagamento, Pedido } from '../../../../core/models/api/Pedido';
import { MatSnackBar } from '@angular/material';
import { BoletoResponse, PedidosService } from '../../../../core/services/api/pedidos.service';
import {
	getRandomLetter,
	getTodayDatePlus,
	showSnackBar,
	UtilsService
} from '../../../../core/services/utils.service';
import { Snackbar } from '../../../../core/models/enum/Snackbar';
import { CallcenterFunctions } from '../../../../core/models/interface/CallcenterFunctions';
import { ActivatedRoute, Router } from '@angular/router';
import { PedidoStatus } from '../../../../core/models/enum/PedidoStatus';
import { Broadcaster, BroadcasterEvents } from '../../../../core/models/broadcaster';
import { Estabelecimento } from '../../../../core/models/template/Estabelecimento';
import { fadeIn, fadeInOut } from '../../../animations/fadeInOut';
import { TipoPagamento } from '../../../../core/models/enum/TipoPagamento';
import { EmpresasService } from '../../../../core/services/api/empresas.service';
import { LojasService } from '../../../../core/services/api/lojas.service';
import { Empresa } from '../../../../core/models/api/Empresa';
import { Loja } from '../../../../core/models/api/Loja';
import { TipoEstabelecimento } from '../../../../core/models/enum/TipoEstabelecimento';
import { TipoCliente } from '../../../../core/models/enum/TipoCliente';
import { Entregador } from '../../../../core/models/api/Entregador';
import { EntregadoresService } from '../../../../core/services/api/entregadores.service';
import { TaxaTransacaoCartao } from '../../../../core/models/api/TaxaTransacaoCartao';
import { TipoCartao } from '../../../../core/models/enum/TipoCartao';
import { TipoEstoque } from '../../../../core/models/enum/TipoEstoque';
import { StatusConta } from '../../../../core/models/enum/StatusConta';
import { TipoEstoqueNegociado } from '../../../../core/models/enum/TipoEstoqueNegociado';
import { Estoque } from '../../../../core/models/api/Estoque';
import { ValorNegociado } from '../../../../core/models/api/ValorNegociado';
import { $getEstabelecimentoOnCache } from '../../../../core/models/interface/EstabelecimentoFunctions';
import { Usuario } from '../../../../core/models/api/Usuario';
import { $getUserInfo } from '../../../../core/auth/token-storage.service';
import { PerfilClienteOptions } from '../../../../core/models/enum/PerfilCliente';
import * as L from 'leaflet';
import { AppConfig } from '../../../../config/appConfig';
import { VendasService } from '../../../../core/services/api/vendas.service';
import { TipoVendaAntecipada } from '../../../../core/models/enum/TipoVendaAntecipada';
import { SMTPConfigService } from '../../../../core/services/api/smtp-config.service';
import { ProdutoConvenio } from '../../../../core/models/api/ProdutoConvenio';
import { ProdutosConvenioService } from '../../../../core/services/api/produtos-convenio.service';
import { CSearch } from '../../../partials/buscar-cliente/buscar-cliente.component';
import moment from 'moment';
import { nanoid } from 'nanoid';

declare const _prompt;

@Component({
	selector: 'm-blank',
	templateUrl: './blank.component.html',
	styleUrls: ['./blank.component.scss'],
	animations: [fadeInOut, fadeIn]
})
export class BlankComponent extends CallcenterFunctions implements OnInit, OnDestroy, AfterViewInit {

	public static readonly ROUTE_ID = 'callcenter:blank';
	public static readonly VISIBLE_ONLY_TO_MANAGER: boolean = false;

	/* tslint:disable */
	@Output() onPedidoAtualizado: EventEmitter<any> = new EventEmitter();
	@Input() _resetValidators: boolean = true;

	@Input() get _pedido() {
		return this.pedido;
	}
	set _pedido(p: Pedido) {
		let _pedido = this.cloneJSON(p);
		_pedido.isEditMode = true;

		this.pedido = _pedido;

		if (this.pedido.cliente == null) this.pedido.cliente = new Cliente();

		this.pedido.cliente.enderecoDefault = this.getEnderecoDefault(this.pedido.cliente.enderecos);

		if (this.pedido.cliente.tipoCliente == null) this.pedido.cliente.tipoCliente = TipoCliente.PESSOA_FISICA;
		if (this.pedido.cliente.tipoCFOP == null) this.pedido.cliente.tipoCFOP = TipoCFOP.CONSUMIDOR_FINAL;
		if (this.pedido.cliente.outrosTelefones == null) this.pedido.cliente.outrosTelefones = [];
		this.popularOutrosTelefones_container(this.pedido.cliente);

		//this.cliente = this.pedido.cliente;
		if (this.pedido.cliente) {
			this.setCliente(this.pedido.cliente);
		}

		this.selectedProdutos = [];

		for (const est of ((_pedido || {} as any).estoquesToUpdate || [])) {
			for (const produto of ((this.pedido || {} as any).produtos || [])) {
				if (produto.idProduto == est.idProduto) {

					const _prod = this.cloneJSON(produto);

					_prod._uniqueId = getRandomLetter(4) + Date.now();

					est.estoqueSelecionado.isInUse = true;

					if (_prod.estoques == null) { _prod.estoques = []; }

					if (!_prod.estoques.find(e => e.tipoEstoque != est.estoqueSelecionado.tipoEstoque)) {
						_prod.estoques.push(est.estoqueSelecionado);
					}

					_prod.estoqueSelecionado = est.estoqueSelecionado;
					_prod.quantidade = est.quantidade;

					this.selectedProdutos.push(_prod);

					if (est.valorNegociado) {
						let tempVN = new ValorNegociado();
						tempVN.valor = est.valorNegociado;
						tempVN['produtoIdProduto'] = est.idProduto;
						tempVN.idProduto = est.idProduto;
						tempVN.cliente = this.cliente;
						tempVN.produto = produto;

						if (est.estoqueSelecionado) {
							switch (est.estoqueSelecionado.tipoEstoque) {
								case TipoEstoque.ESTOQUE_CHEIO: tempVN.estoqueNegociado = TipoEstoqueNegociado.ESTOQUE_CHEIO; break;
								case TipoEstoque.ESTOQUE_VAZIO: tempVN.estoqueNegociado = TipoEstoqueNegociado.ESTOQUE_VAZIO; break;
								case TipoEstoque.ESTOQUE_UNICO: tempVN.estoqueNegociado = TipoEstoqueNegociado.ESTOQUE_CHEIO; break;
							}
						}

						this.onValorNegociadoCadastrado(tempVN);
					}
					break;
				}
			}
		}

		this.showOrderSummary = true;

		this.selectEst(this.pedido.empresaIdEmpresa, this.pedido.lojaIdLoja);

		this.checkValorNegociado();
	}

	creditoParaUtilizar: number = 0;

	tipoCfopValues = TipoCFOPSelectValues;

	showOrderSummary: boolean = false;
	showEditOptions: boolean = false;
	produtosConvenio: ProdutoConvenio[] = [];
	/* tslint:enable */

	/* Form */

	produtoCtrl = new FormControl();
	entregadorCtrl = new FormControl();
	/* */

	/* Models */
	pedido: Pedido;
	cliente: Cliente;
	filteredClientes: Cliente[];

	singleFilteredCliente: Cliente;

	produtos: Produto[];
	filteredProdutos: Observable<Produto[]>;
	selectedProdutos: Produto[];
	entregadores: Entregador[];
	filteredEntregadores: Observable<Entregador[]>;
	selectedEntregador: Entregador;
	repassarPedido: boolean = true;
	findSilvaGas = false;

	perfilsCliente: any[] = PerfilClienteOptions;

	listEmpresa: Empresa[];
	listLoja: Loja[];

	toggleAlterarCliente: boolean = true;

	taxasTransacoes: TaxaTransacaoCartao[];

	produtoParaNegociar: Produto;
	/* */

	extraPayment: Pagamento;

	searchInput;

	status = PedidoStatus;

	showTaxaSelect: boolean = false;
	showBoletoOptions: boolean = false;
	showChequeOptions: boolean = false;

	loading: boolean;

	geocoder: any;

	estabelecimentoList = [];
	estabelecimetoSelecionado: any = 'a';

	gerarCodigoAleatorio: boolean = true;

	_showValorNegociadoPopUp: boolean = false;

	selectingClosesEst: boolean = false;
	checkingCNPJ: boolean = false;

	/** enums */
	clienteAlertaTipo = ClienteAlertaTipo;
	tipoCliente = TipoCliente;
	tipoCartao = TipoCartao;
	tipoPagamento = TipoPagamento;
	tipoEstoque = TipoEstoque;
	formaPagamento = FormaPagamento;
	pedidoStatus = PedidoStatus;

	userInfo: Usuario;

	showPagamentoBloqueadoText: boolean = false;
	showPopUpAddPhone: boolean = false;

	canUpdateDateField: boolean;
	intervalUpdateDateField;

	showPhoneCallsPopUp: boolean = false;
	frontId: string;
	frontIdList: string[] = [];

	/** */
	pedidoBoletoOptions: { show: boolean, pedidoStatus: PedidoStatus, action: 'emitir' | 'registrar' | null };

	private obs = [];

	constructor(
		private clientesService: ClientesService,
		private produtosService: ProdutosService,
		private cdRef: ChangeDetectorRef,
		private modalService: NgbModal,
		public snackBar: MatSnackBar,
		private pedidosService: PedidosService,
		public utils: UtilsService,
		public _router: Router,
		private broadcaster: Broadcaster,
		private empresasService: EmpresasService,
		private lojasService: LojasService,
		private entregadoresService: EntregadoresService,
		private route: ActivatedRoute,
		private vendasService: VendasService,
		private smtpConfigService: SMTPConfigService,
		private produtosConvenioService: ProdutosConvenioService
	) {
		super(_router);

		this.userInfo = $getUserInfo();

		if (this.userInfo) {

			// força o reload da rota quando o parametro é alterado
			this._router.routeReuseStrategy.shouldReuseRoute = () => false;

			this._reset(true, () => {
				this.obs.push(

					/**
					 * Observable de chamada
					 */
					this.broadcaster.on$(BroadcasterEvents.CHAMADA_BINA_INICIADA).subscribe((c: Cliente) => {
						this.configurarChamadaBina(c, c.empresas, c.lojas, c.voip);
					}),

					this.broadcaster.on$(BroadcasterEvents.PHONE_CALLS_TOGGLE).subscribe(() => {
						this.showPhoneCallsPopUp = !this.showPhoneCallsPopUp;
						this.detectChanges();
					}),

					/**
					 * Se inscreve no evento de mudança de estabelecimento
					 */
					this.broadcaster.on$(BroadcasterEvents.ESTABELECIMENTO_SELECIONADO).subscribe(async (data: Estabelecimento) => {
						this.estabelecimetoSelecionado = this.getEstabelecimentoName();

						if (this.showOrderSummary != true) {

							// this.setPedCod();
							// this.setClienteCod();
							// this.loadProdutos();
							// this.loadEntregadores();
							this.carregarEstabelecimentos(true);
						}
					})
				);
			});

			// this.geocoder = new google.maps.Geocoder;

		}
		else {
			_router.navigateByUrl('/login');
		}
	}

	async ngOnInit() {

		this.frontId = nanoid(36);

		this.broadcaster.broadcast$(BroadcasterEvents.SHOW_ESTABELECIMENTOS_SELECTED, true);
		this.broadcaster.broadcast$(BroadcasterEvents.SHOW_PHONE_CALLS_BUTTON, true);

		const popup = this.route.snapshot.queryParamMap.get('popup');
		const nome = this.route.snapshot.queryParamMap.get('name');
		const phone = this.route.snapshot.queryParamMap.get('phone');
		const client = this.route.snapshot.queryParamMap.get('client');

		if (popup == 'true') {
			const clienteVg = await this.buscarCliente(phone);
			if (clienteVg) {
				this.setCliente(clienteVg);
			}
			else {
				const cliente = new Cliente();
				cliente.nome = nome;
				cliente.telefone = phone;
				if (client) {
					const _client = JSON.parse(decodeURIComponent(client));
					cliente.enderecoDefault = new Endereco();
					if (_client.day && _client.month) cliente.dtAniversario = moment().month(_client.month).date(_client.day).startOf('day').toDate();
					if (_client.email) cliente.email = _client.email;
					if (_client.cep) cliente.enderecoDefault.cep = _client.cep;
					if (_client.bairro) cliente.enderecoDefault.bairro = _client.bairro;
					if (_client.complemento) cliente.enderecoDefault.complemento = _client.complemento;
					if (_client.localidade) cliente.enderecoDefault.cidade = _client.localidade;
					if (_client.ibge) {
						cliente.enderecoDefault.codigoMunicipio = _client.ibge;
						cliente.enderecoDefault.ibge = _client.ibge;
					}
					if (_client.logradouro) cliente.enderecoDefault.endereco = _client.logradouro;
					if (_client.numero) cliente.enderecoDefault.numero = _client.numero;
					if (_client.uf) {
						cliente.enderecoDefault.estadoAcronimo = _client.uf;
						cliente.enderecoDefault.uf = _client.uf;
						cliente.enderecoDefault.estado = _client.uf;
					}
				}
				this.setCliente(cliente);
			}
		}
	}

	ngOnDestroy() {

		/** Se desinscreve das chamadas do Broadcaster */
		(this.obs || []).forEach(o => o.unsubscribe());

		this.broadcaster.broadcast$(BroadcasterEvents.SHOW_PHONE_CALLS_BUTTON, false);
	}

	ngAfterViewInit() {
		this.setupAutocompleteInput();
		this.entregadorCtrl.valueChanges.subscribe(value => this.getEntregadores(value));

		this.configurarMapaRotaDeEntrega(this.pedido);

		setTimeout(() => {
			this._router.navigateByUrl('/empty')
		}, 0);
	}

	detectChanges() { try { this.cdRef.detectChanges(); } catch (err) { } }

	abrirBuscaCliente(clientes) {
		this.broadcaster.broadcast$(BroadcasterEvents.ABRIR_BUSCAR_CLIENTE, clientes);
	}

	setupAutocompleteInput() {
		this.getPlace(document.getElementById('input-endereco-cliente') as HTMLInputElement, (endereco) => {
			this.cliente.enderecoDefault.estadoAcronimo = endereco.estado;
			this.cliente.enderecoDefault.bairro = endereco.bairro;
			this.cliente.enderecoDefault.cidade = endereco.cidade;
			//this.cliente.enderecoDefault.cep = endereco.cep;
			this.cliente.enderecoDefault.endereco = endereco.endereco;
		});
	}

	setEndereco(endereco: Endereco) {
		const _id = (this.cliente.enderecoDefault || { _id: undefined })._id;
		this.cliente.enderecoDefault = endereco;
		this.cliente.enderecoDefault._id = _id;
		this.cliente.enderecoDefault.idEndereco = _id;
	}

	verificarClienteTelefone() {
		if (this.cliente.telefone) {
			this.broadcaster.broadcast$(BroadcasterEvents.LOADING, true);
			this.clientesService.getAll(
				undefined,
				0,
				10,
				BlankComponent.ROUTE_ID,
				undefined,
				undefined,
				this.cliente.telefone,
				undefined,
				undefined,
				undefined,
				undefined,
				'telefone',
				this.findSilvaGas
			).subscribe(
				data => {
					if (data.items.length == 1) {
						this._alert(`Já existe um cliente com esse número de telefone. Cliente: ${data.items[0].razaoSocial || data.items[0].nomeFantasia || data.items[0].nome}`)
						this.setCliente(data.items[0])
					}
					else if (data.items.length > 1) {
						this.abrirBuscaCliente(data.items)
					}
					this.broadcaster.broadcast$(BroadcasterEvents.LOADING, false);
				},
				err => {
					console.log(err);
					this.broadcaster.broadcast$(BroadcasterEvents.LOADING, false);
				}
			);
		}
	}

	setPedCod() {
		if (this.pedido != null && this.pedido.estabelecimento != null) {
			this.pedidosService.getEntriesCount(BlankComponent.ROUTE_ID, this.pedido.estabelecimento).subscribe(
				e => {
					if (this.gerarCodigoAleatorio && this.showOrderSummary != true) {
						this.pedido.numero = (e.entries + 1).toString();
						this.detectChanges();
					}
				},
				_err => console.log(_err),
			);
		}
	}

	setClienteCod() {
		if (this.pedido != null && this.pedido.estabelecimento != null) {
			this.clientesService.getEntriesCount(BlankComponent.ROUTE_ID, this.pedido.estabelecimento).subscribe(
				e => {
					// if (this.gerarCodigoAleatorio) {
					if (!!this.cliente && this.cliente.idCliente == null) {
						this.cliente.codigoCliente = (e.entries + 1).toString();
						this.detectChanges();
					}
				},
				_err => console.log(_err),
			);
		}
	}

	async loadProdutos() {
		try {
			let slp = JSON.parse(JSON.stringify(this.selectedProdutos));
			if (this.pedido != null && this.pedido.estabelecimento != null) {

				let estabelecimento = {
					multiEmpresa: [] as any,
					multiLoja: [] as any
				};

				let est = this.pedido.estabelecimento;

				if (est.tipoEstabelecimento == TipoEstabelecimento.EMPRESA && est.empresa != null) estabelecimento.multiEmpresa.push(est.empresa.idEmpresa);
				if (est.tipoEstabelecimento == TipoEstabelecimento.LOJA && est.loja != null) estabelecimento.multiLoja.push(est.loja.idLoja);

				estabelecimento.multiEmpresa = estabelecimento.multiEmpresa.length > 0 ? estabelecimento.multiEmpresa.join(',') as any : null;
				estabelecimento.multiLoja = estabelecimento.multiLoja.length > 0 ? estabelecimento.multiLoja.join(',') as any : null;

				if (estabelecimento.multiEmpresa == null) delete estabelecimento.multiEmpresa;
				if (estabelecimento.multiLoja == null) delete estabelecimento.multiLoja;

				this.produtos = await this.produtosService.getAll(undefined, BlankComponent.ROUTE_ID, true, estabelecimento).toPromise<Produto[]>();
			} else {
				this.produtos = [];
			}

			this.filteredProdutos = this.produtoCtrl.valueChanges.pipe(
				startWith(''),
				map(prod => prod ? this._filteProdutos(prod) : this.produtos.slice())
			);

			if (this.showOrderSummary != true && this.pedido.isEditMode != true) {
				this.selectedProdutos = [];
				for (const p of this.produtos) {
					if (p.isDefault) this.setProduto(p);
				}
			}

			if (this.pedido.isEditMode && slp.length) {
				const newSLP = [];
				for (const p of slp) {
					const sameProduct = JSON.parse(JSON.stringify(this.produtos.find(prod => this.simplify(prod.nome) == this.simplify(p.nome))));
					if (sameProduct) {
						sameProduct.estoqueSelecionado = p.estoqueSelecionado;
						sameProduct.quantidade = p.quantidade;
						sameProduct.valorNegociado = p.valorNegociado;
						sameProduct.usarValorNegociado = p.usarValorNegociado;
						sameProduct.estoques = p.estoques;
						sameProduct._uniqueId = p._uniqueId;
						newSLP.push(sameProduct);
					}
					else {
						newSLP.push(p);
					}
				}
				this.selectedProdutos = newSLP;
				this.calcularValorPedido();
			}

			this.detectChanges();
		} catch (err) {
			console.log(err);
			this.produtos = [];
		}
	}

	onParcelamentoAtualizado($event) {
		this.pedido.parcelamento = $event.value;
	}

	async getEntregadores(queryValue = null) {
		//if (this.userInfo.isAdmin) {
		if (this.pedido.estabelecimento) {
			this.filteredEntregadores = (await this.entregadoresService.getAll(
				undefined,
				0,
				5,
				BlankComponent.ROUTE_ID,
				queryValue,
				this.pedido.estabelecimento
			).toPromise<any>()).items;
			this.detectChanges();
		}
		//}
	}

	async loadEntregadores() {
		try {
			this.getEntregadores();
		} catch (e) {
			console.log(e);
		}
	}

	private _filteProdutos(value: string): Produto[] {
		const filterValue = value
			.toLowerCase()
			.replace(/\s/g, '')
			.replace(/[\u0300-\u036f]/g, '');
		return this.produtos.filter(prod => {
			const concatedData = (
				prod.nome +
				prod.valorCheio +
				prod.codigo +
				(!!prod.marca ? prod.marca : '')
			).replace(/\s/g, '').toLowerCase()
				.normalize('NFD').replace(/[\u0300-\u036f]/g, '');

			return concatedData.indexOf(filterValue) !== -1;
		});
	}

	async _reset(checkQueryParams: boolean = false, onLoadFinish?: () => void) {
		if (this.showOrderSummary != true) {

			this.pedidoBoletoOptions = {
				show: false,
				action: null,
				pedidoStatus: null
			};

			this.cliente = new Cliente();
			this.cliente.tipoCliente = TipoCliente.PESSOA_FISICA;
			this.cliente.tipoCFOP = TipoCFOP.CONSUMIDOR_FINAL;
			this.cliente.enderecoDefault = new Endereco();
			this.cliente.valorNegociados = [];
			this.cliente.outrosTelefones = [];
			this.cliente.outrosTelefones_container = [];

			this.creditoParaUtilizar = 0;

			this.cliente.pedidos = [];
			this.enableClienteFields();

			this.selectedProdutos = [];

			this.selectedEntregador = null;

			this.pedido = new Pedido();
			this.pedido.pagamentos = [];
			this.pedido.parcelamento = 1;
			this.canUpdateDateField = true;
			this.pedido.valorTotal = 0;
			this.pedido.data = new Date(Date.now());

			if (checkQueryParams) {
				let idCliente = this.route.snapshot.queryParamMap.get('idClienteChamada');
				let telefone = this.route.snapshot.queryParamMap.get('telefoneClienteChamada');
				let voip = this.route.snapshot.queryParamMap.get('voip');
				let empresas = (this.route.snapshot.queryParamMap.get('empresas') || '').split(',').filter(x => !!x);
				let lojas = (this.route.snapshot.queryParamMap.get('lojas') || '').split(',').filter(x => !!x);

				if (idCliente != null || telefone != null) {
					this.configurarChamadaBina({
						idCliente: idCliente,
						telefone: telefone
					} as Cliente, empresas, lojas, voip == 'true');
				}
			}

			if (this.intervalUpdateDateField != null) clearInterval(this.intervalUpdateDateField);
			this.intervalUpdateDateField = setInterval(() => {
				if (this.canUpdateDateField && this.pedido != null && this.pedido.isEditMode != true) {
					this.pedido.data = new Date(Date.now());
					this.detectChanges();
				}
			}, 5000);

			try {
				await Promise.all([
					// this.setPedCod(),
					// this.loadProdutos(),
					// this.loadEntregadores(),
					this.carregarEstabelecimentos()
				]);
			} catch (err) {
				console.log(err);
			}

			if (onLoadFinish) onLoadFinish();

			this.broadcaster.broadcast$(BroadcasterEvents.RESET_PEDIDO);
		}
	}

	checkPrazoMaximo() {
		if (this.cliente.prazoMaximo != null) {
			this.cliente.prazoMaximo_formated = getTodayDatePlus(this.cliente.prazoMaximo);
		}
	}

	buscarCliente(telefone): Promise<Cliente> {
		return new Promise((resolve, reject) => {
			this.clientesService.byPhone(telefone, BlankComponent.ROUTE_ID).subscribe(
				data => {
					resolve(data);
				},
				err => {
					reject(err);
				}
			);
		});
	}

	setCliente(c: Cliente | any, empresas: string[] = [], lojas: string[] = [], voip: boolean = false) {
		this.cliente = { ...c };
		if (voip) {
			this.cliente.empresas = empresas;
			this.cliente.lojas = lojas;
		}
		if (this.cliente.outrosTelefones == null) this.cliente.outrosTelefones = [];
		this.popularOutrosTelefones_container(this.cliente);
		// this.disableClienteFields();

		const _cEDefault = c.enderecoDefault || this.getEnderecoDefault(c.enderecos);

		if (this.cliente.tipoCFOP == null) this.cliente.tipoCFOP = TipoCFOP.CONSUMIDOR_FINAL;

		this.cliente.tipoCliente = c.tipoCliente;
		this.cliente.enderecoDefault = !!_cEDefault ? _cEDefault : new Endereco();

		this.checkPrazoMaximo();

		/* */
		if (!!this.cliente.pedidos && this.cliente.pedidos.length >= 1) {
			this.cliente.ultimaCompra = this.utils.formatDate(this.cliente.pedidos[0].createdAt, true);
		}

		for (const ped of c.pedidos || []) {
			let newC = <any>{ ...c };
			delete newC.pedidos;
			ped.cliente = newC;
		}

		this.searchInput = null;

		this.verificarBloqueio(this.pedido.pagamentos);

		this.checkValorNegociado();

		this.generateClienteAlertas(this.cliente);

		this.setLatlongFromCLiente();

		this.checkAtrasoPagamentoCliente();

		/**
		 * Verifica os créditos do cliente
		 */
		let idCliente = this.cliente != null ? this.cliente._id || this.cliente.idCliente : null;
		this.creditoParaUtilizar = 0;
		if (idCliente != null) {
			this.cliente._credito = 0;
			this._load.show(() => this.detectChanges(), 'buscando-creditos-cliente');
			this.vendasService.buscarVendasAntecipadasDeUmCliente(idCliente, BlankComponent.ROUTE_ID)
				.subscribe(
					res => {
						(res || []).forEach(v => {
							if (v.tipo == TipoVendaAntecipada.CREDITO) this.cliente._credito += Number(v.valor);
							else this.cliente._credito -= v.valor;
						});
						if (this.cliente._credito < 0) this.cliente._credito = 0;
						this.verificarCredito(true);
						this._load.hide(() => this.detectChanges(), 'buscando-creditos-cliente');
					},
					err => {
						console.log(err);
						this._load.hide(() => this.detectChanges(), 'buscando-creditos-cliente');
					}
				);
			//this.disableClienteFields();
		}
	}

	checkAtrasoPagamentoCliente() {
		if (!!this.cliente && !!this.cliente.contas) {

			if (this.cliente.alertas == null) {
				this.cliente.alertas = [];
			}

			for (const c of this.cliente.contas) {
				if (c.status == StatusConta.VENCIDO) {
					const alertaAtraso = new ClienteAlerta();
					alertaAtraso.tipo = ClienteAlertaTipo.DANGER;
					alertaAtraso.title = 'Este cliente tem um pagamento com atraso';
					alertaAtraso.desc = 'A conta do pedido n° ' + c.pedidoIdPedido +
						' com pagamento em ' + this.getTipoPagamentoName(c.tipoPagamento) +
						' venceu no dia ' + this.utils.formatDate(c.vencimento, false) + '.';
					this.cliente.alertas.push(alertaAtraso);
					this.cliente.atrasoPagamento = alertaAtraso;
					this.cliente.showAtrasoPagamentoConfirm = false;
					this.detectChanges();
					break;
				}
			}
		}
	}

	checkValorNegociado() {

		/**
		 * Verificação a seguir impede que o mesmo produto seja selecionado duas vezes com o mesmo estoque,
		 * evitando inconsistencia em relatórios de vendas.
		 */
		let filteredProdutos = [];
		(this.selectedProdutos || []).forEach(p => {
			let _prodFind = filteredProdutos.find((pf: Produto) => pf.idProduto === p.idProduto && (pf.estoqueSelecionado || <Estoque>{}).tipoEstoque === (p.estoqueSelecionado || <Estoque>{}).tipoEstoque);
			if (_prodFind != undefined) _prodFind.quantidade += Number(p.quantidade);
			else filteredProdutos.push(p);
		});

		this.selectedProdutos = filteredProdutos;
		this.detectChanges();

		if (!!this.cliente && !!this.cliente.valorNegociados && !!this.selectedProdutos) {

			selectedProdutos:
			for (const p of this.selectedProdutos) {
				if (!this.getValorConvenio(p)) {
					p.valorNegociado = null;
					p.usarValorNegociado = null;

					for (const valor of this.cliente.valorNegociados) {

						if (((valor.produto || {} as any).idProduto || valor.idProduto) == p.idProduto && !!p.estoqueSelecionado) {

							if (
								(valor.estoqueNegociado == TipoEstoqueNegociado.ESTOQUE_CHEIO && p.estoqueSelecionado.tipoEstoque == TipoEstoque.ESTOQUE_CHEIO) ||
								(valor.estoqueNegociado == TipoEstoqueNegociado.ESTOQUE_VAZIO && p.estoqueSelecionado.tipoEstoque == TipoEstoque.ESTOQUE_VAZIO) ||
								valor.estoqueNegociado == TipoEstoqueNegociado.TODOS
							) {
								p.valorNegociado = valor.valor;
								p.usarValorNegociado = true;
								continue selectedProdutos;
							}
						}
					}
				}
			}
		}

		this.calcularValorPedido();
		this.detectChanges();
	}

	selectAlerta(alerta: ClienteAlerta) {
		if (!!alerta.pedido) {

			alerta._showQuickInfoPopUp = true;
			this.cliente.alertaSelected = alerta;
		}
	}

	generateClienteAlertas(c: Cliente) {

		if (c == null) return;
		c.alertaSelected = null;
		c.alertas = [];

		if (c != null && (c.enderecos || []).length > 0) {
			let endDefault = this.getEnderecoDefault(c.enderecos);
			if (endDefault.observacao != null && endDefault.observacao != '') {
				c.alertas.push({
					tipo: ClienteAlertaTipo.INFO,
					title: 'Observação do cliente',
					desc: endDefault.observacao,
					pedido: null,
					_showQuickInfoPopUp: false
				});
			}
		}

		if ((c.pedidos || []).length > 0) {

			const ultimoPed = c.pedidos[0];

			/** Verifica se teve cancelamento no último pedido feito */
			if (ultimoPed.status == PedidoStatus.PEDIDO_CANCELADO) {
				c.alertas.push({
					tipo: ClienteAlertaTipo.DANGER,
					title: 'O último pedido deste cliente foi cancelado',
					desc: ultimoPed.observacaoCancelamento != null && ultimoPed.observacaoCancelamento.length > 0 ? ultimoPed.observacaoCancelamento : '--',
					pedido: ultimoPed,
					_showQuickInfoPopUp: false
				});
			}

			if (c.pedidos.length > 1) {

				/** Verifica atraso */
				for (const count in c.pedidos) {

					if (parseInt(count) > 1) {
						break;
					}

					const atrasoPed = c.pedidos[count];

					if (atrasoPed.status == PedidoStatus.PEDIDO_ENTREGUE) {

						const millisec = new Date(atrasoPed.dataEntrega).getTime() - new Date(atrasoPed.data).getTime();
						const minutosEntrega = parseInt((millisec / (1000 * 60)).toFixed(1));

						if (minutosEntrega >= 40) {

							c.alertas.push({
								tipo: ClienteAlertaTipo.WARNING,
								title: 'Atraso na entrega',
								desc: 'Este cliente teve um pedido que durou mais de 40 minutos.',
								pedido: atrasoPed,
								_showQuickInfoPopUp: false
							});
							break;
						}
					}
				}
			}
		}
	}

	setEntregador(e: Entregador) { this.selectedEntregador = e; }

	setProduto(p: Produto, blur?: boolean) {

		const _p = this.cloneJSON(p);

		let insert = true;
		let count = 0;
		let otherProd = null;

		for (const prod of this.selectedProdutos) {
			if (prod.idProduto == _p.idProduto) {
				++count;
				if (count == 2) {
					insert = false;
					break;
				} else otherProd = prod;
			}
		}

		if (insert) {
			_p._uniqueId = getRandomLetter(4) + Date.now();
			_p.quantidade = 1;

			for (const est of (_p.estoques || [])) {
				if (est.isInUse == true && est.tipoEstoque == TipoEstoque.ESTOQUE_CHEIO) {
					if (otherProd == null || otherProd.estoqueSelecionado == null || otherProd.estoqueSelecionado.tipoEstoque != est.tipoEstoque) {
						_p.estoqueSelecionado = est;
						break;
					}
				}

				if (est.isInUse == true && est.tipoEstoque == TipoEstoque.ESTOQUE_VAZIO) {
					if (!!otherProd && !!otherProd.estoqueSelecionado && otherProd.estoqueSelecionado.tipoEstoque != est.tipoEstoque) {
						_p.estoqueSelecionado = est;
						break;
					}
				}
			}

			/** Seleciona o estoque existente caso não tenha o estoque cheio */
			for (const estoque of _p.estoques) {
				if (_p.estoqueSelecionado == null && estoque.isInUse == true) {
					_p.estoqueSelecionado = estoque;
					break;
				}
			}

			this.selectedProdutos.push(_p);
		}

		if (blur) {
			let el = document.getElementById('input-buscar-produto');
			if (el) el.blur();
		}

		this.checkValorNegociado();
	}

	setPedidoFromHistoric(p: Pedido) {
		this.pedido.tipoPagamento = p.tipoPagamento;
		this.pedido.pagamentos = p.pagamentos || [];
		this.pedido.pagamentoExtra = this.extraPayment;
		this.selectedProdutos = [];

		if (p.estoquesToUpdate) {

			for (const prodEstq of p.estoquesToUpdate) {

				checkProdutos: for (const prod of p.produtos) {

					if (prod.idProduto == prodEstq.idProduto && prod.ativo) {

						for (const c in prod.estoques) {

							let _estq = prod.estoques[c];

							// if (_estq.idEstoque == prodEstq.estoqueSelecionado.idEstoque && _estq.tipoEstoque == prodEstq.estoqueSelecionado.tipoEstoque) {
							if (_estq.tipoEstoque == prodEstq.estoqueSelecionado.tipoEstoque) {

								const _prodToPush = this.cloneJSON(prod);

								_prodToPush._uniqueId = getRandomLetter(4) + Date.now();
								_prodToPush.estoques = prod.estoques;
								_prodToPush.quantidade = prodEstq.quantidade;
								_prodToPush.estoqueSelecionado = prod.estoques[c];
								_prodToPush.usarValorNegociado = true;

								this.selectedProdutos.push(_prodToPush);
								break checkProdutos;
							}
						}
					}
				}
			}

		} else {

			for (const prod of p.produtos) {
				if (prod.ativo) {
					prod.quantidade = p.produtoQuantidade[prod.idProduto];
					prod._uniqueId = getRandomLetter(4) + Date.now();
					this.selectedProdutos.push(prod);
				}
			}
		}

		this.detectChanges();

		this.checkValorNegociado();
	}

	removerProduto(p: Produto) {
		const res = [];
		for (const prod of this.selectedProdutos) {
			// if (prod.idProduto !== p.idProduto) {
			if (prod._uniqueId !== p._uniqueId) {
				res.push(prod);
			}
		}
		this.selectedProdutos = res;

		this.calcularValorPedido();
	}

	removerTodosPrdutos() {
		for (const p of (this.selectedProdutos || [])) this.removerProduto(p);
	}

	aumentarQuantidade(p: Produto) { ++p.quantidade; this.calcularValorPedido(); }

	diminuirQuantidade(p: Produto) {
		if (p.quantidade > 0) --p.quantidade;
		this.calcularValorPedido();
	}

	getValorConvenio(p: Produto) {
		const produtoConvenio = this.produtosConvenio.find(pc => pc.produto._id == p._id && pc.tipoEstoque == p.estoqueSelecionado.tipoEstoque);
		if (produtoConvenio) {
			return produtoConvenio.valor;
		}
		else {
			return null
		}
	}

	calcularValorPedido() {
		this.pedido.valorTotal = 0;
		for (const p of this.selectedProdutos) {
			const valorConvenio = this.getValorConvenio(p);
			if (p.valorNegociado != null && p.valorNegociado > 0 && p.usarValorNegociado == true) {
				this.pedido.valorTotal += p.valorNegociado * p.quantidade;
			}
			else if (valorConvenio) {
				this.pedido.valorTotal += valorConvenio * p.quantidade;
			}
			else {
				let valor = p.valorCheio;

				if (!!p.estoqueSelecionado && p.estoqueSelecionado.tipoEstoque == TipoEstoque.ESTOQUE_VAZIO) {
					valor = p.valorVazio;
				}

				this.pedido.valorTotal += valor * p.quantidade;
			}
		}

		this.detectChanges();
		this.verificarCredito();
	}

	verificarCredito(autoAppply: boolean = false) {
		if (this.cliente != null && this.cliente._credito > 0 && this.pedido != null && this.pedido.valorTotal <= this.cliente._credito) {
			this.creditoParaUtilizar = this.pedido.valorTotal;
		}
		else this.creditoParaUtilizar = 0;
		this.detectChanges();
	}

	async salvarPedido(status = null, validateBoletoSelection: boolean = true) {
		if (status != null) { this.pedido.status = status; }
		if (this.validate()) {
			// if (this.pedido.tipoPagamento === TipoPagamento.BOLETO && validateBoletoSelection && this.pedido.isEditMode != true) {
			if ((this.pedido.pagamentos || []).find(p => p.tipoPagamento === TipoPagamento.BOLETO) != null && validateBoletoSelection && this.pedido.isEditMode != true) {
				this.pedidoBoletoOptions.show = true;
				this.pedidoBoletoOptions.pedidoStatus = status;
				this.detectChanges();
			} else {
				let showConfirmClient = false;
				if (this.pedido.isEditMode && this.pedido.estabelecimento && this.pedido.estabelecimento.empresa != null && !(this.cliente.empresas || []).includes(this.pedido.estabelecimento.empresa._id)) showConfirmClient = true;
				if (this.pedido.isEditMode && this.pedido.estabelecimento && this.pedido.estabelecimento.loja != null && !(this.cliente.lojas || []).includes(this.pedido.estabelecimento.loja._id)) showConfirmClient = true;
				if (showConfirmClient) {
					if (!(await this._confirm('Esse cliente não pertence ao mesmo estabelecimento selecionado no pedido. Não é possível alterar o cliente do pedido.'))) return;
				}

				// if (this.pedido.tipoPagamento === TipoPagamento.BOLETO) this.pedido.boletoAction = this.pedidoBoletoOptions.action;
				if ((this.pedido.pagamentos || []).find(p => p.tipoPagamento === TipoPagamento.BOLETO) != null) this.pedido.boletoAction = this.pedidoBoletoOptions.action;
				if (this.pedido.isEditMode == true) this.atualizarPedido();
				else this.cadastrarPedido(status);
			}
		}
	}

	onPagamentoAtualizado($event: Pagamento[]) {
		this.pedido.pagamentos = $event;
		this.verificarBloqueio(this.pedido.pagamentos);
		if ($event.length && $event[0].tipoPagamento == TipoPagamento.CONVENIO && $event[0].idConvenio) {
			this.carregarProdutosConvenio($event[0].idConvenio);
		}
		else {
			this.produtosConvenio = [];
			//this.checkValorNegociado();
			this.calcularValorPedido();
		}
	}

	carregarProdutosConvenio(idConvenio: string) {
		this.loading = true;
		this.produtosConvenioService.getAll((this.pedido.estabelecimento.empresa || this.pedido.estabelecimento.loja)._id, idConvenio, 1, 1000, BlankComponent.ROUTE_ID).subscribe(
			(res) => {
				this.produtosConvenio = res.items;
				this.checkValorNegociado();
				this.calcularValorPedido();
				this.loading = false;
			},
			(err) => {
				this.produtosConvenio = [];
				this.checkValorNegociado();
				this.calcularValorPedido();
				console.log(err);
				this.loading = false;
			}
		)
	}

	pedidoSuccess(ctx) {
		ctx._reset();
		ctx.detectChanges();
		if (this.repassarPedido && localStorage.getItem(TokenStorage.REPASSAR_LANCAMENTO_PEDIDO) != 'false') {
			this._confirm('Deseja repassar esse pedido?', 'Sim', 'Não').then(ok => {
				if (ok) {
					ctx._router.navigate(['/pedidos/repassar']);
				}
			})
		}
	}

	async cadastrarPedido(status) {

		const ctx = this;

		this.loading = true;
		this.detectChanges();

		//this.geocoder.geocode({address: this.cliente.enderecoDefault.endereco + ', ' + this.cliente.enderecoDefault.cep + ', Brazil'}, function (results, statusReq) {

		/*
		if (statusReq === google.maps.GeocoderStatus.OK && ctx.cliente.idCliente == null) {
			const fromGeocodeResult = getObjectFromGeocodeResult(results[0]);
			ctx.cliente.enderecoDefault.lat = fromGeocodeResult.lat;
			ctx.cliente.enderecoDefault.long = fromGeocodeResult.long;
		}
		*/

		ctx.loading = true;
		ctx.pedido.status = status;

		if (status == PedidoStatus.PEDIDO_AGENDADO) {
			ctx.pedido.agendado = true;
		}

		ctx.pedido.dataPreenchidaAutomaticamente = ctx.canUpdateDateField;
		ctx.pedido.timestampSistemaUsuario = Date.now();

		if (ctx.selectedEntregador) {
			if (!ctx.selectedEntregador.empresas.find(e => 'emp' + e.idEmpresa == ctx.pedido.estabelecimento.estLocalId) && !ctx.selectedEntregador.lojas.find(l => 'loj' + l.idLoja == ctx.pedido.estabelecimento.estLocalId)) {
				ctx.loading = false;
				return this._alert(`O entregador ${ctx.selectedEntregador.nome} não pertence ao estabelecimento ${ctx.pedido.estabelecimento.nome}. Substitua o entregador para finalizar o pedido.`);
			}

			ctx.pedido.entregadorIdEntregador = ctx.selectedEntregador.idEntregador;
			ctx.pedido.dataRepasse = new Date(Date.now());

			if (status != PedidoStatus.PEDIDO_AGENDADO) {
				ctx.pedido.status = PedidoStatus.PEDIDO_REPASSADO;
				ctx.pedido.dataRepasse = new Date(Date.now());
			}

			this.repassarPedido = false;
		}
		else {
			this.repassarPedido = true;
		}

		const _result = [];

		for (const _p of ctx.pedido.produtos) {

			if (!_p.empresas.find(e => 'emp' + e.idEmpresa == ctx.pedido.estabelecimento.estLocalId) && !_p.lojas.find(l => 'loj' + l.idLoja == ctx.pedido.estabelecimento.estLocalId)) {
				ctx.loading = false;
				return this._alert(`O produto ${_p.nome} (${this.getTipoEstoque(_p.estoqueSelecionado.tipoEstoque)}) não pertence ao estabelecimento ${ctx.pedido.estabelecimento.nome}. Substitua o produto para finalizar o pedido.`);
			}

			let prod = new Produto();
			prod.idProduto = _p.idProduto;
			prod.quantidade = _p.quantidade;
			prod.valorNegociado = null;

			let valor = _p.valorCheio;
			let valorCusto = _p.valorCustoCheio || 0;

			if (_p.estoqueSelecionado && _p.estoqueSelecionado.tipoEstoque == TipoEstoque.ESTOQUE_VAZIO) {
				valor = _p.valorVazio;
				if (_p.valorCustoVazio) valorCusto = _p.valorCustoVazio;
			}

			prod.valorCusto = valorCusto;

			prod.valorCompleto = valor;
			prod.estoqueSelecionado = new Estoque();

			if (_p.valorNegociado != null && _p.usarValorNegociado == true) prod.valorNegociado = _p.valorNegociado;

			prod.estoqueSelecionado.idEstoque = _p.estoqueSelecionado.idEstoque;
			prod.estoqueSelecionado.tipoEstoque = _p.estoqueSelecionado.tipoEstoque;

			_result.push(ctx.cloneJSON(prod));

			prod = null;
		}

		ctx.pedido.produtos = _result;
		ctx.pedido.frontId = this.frontId;

		let body = { ...ctx.pedido };

		delete (<any>body.cliente || {}).vendas;
		delete (<any>body.cliente || {}).empresas;
		delete (<any>body.cliente || {}).alertaSelected;
		delete (<any>body.cliente || {}).alertas;

		let cpf_cnpj = null;
		let nome = null;

		if (this.pedidoBoletoOptions.action == 'emitir') {
			if (!body.cliente.cnpj && !body.cliente.cpf) {
				cpf_cnpj = await _prompt('CPF ou CNPJ do cliente');
				if (!cpf_cnpj) {
					ctx.loading = false;
					return;
				}
			}
			if (body.cliente.nome.length < 6) {
				nome = await _prompt('Insira o nome do cliente (deve conter no mínimo 6 caracteres)', body.cliente.nome);
				if (nome.length < 6) {
					ctx.loading = false;
					return;
				}
			}
		}

		// body.creditoParaUtilizar = this.creditoParaUtilizar;
		body.creditoParaUtilizar = 0;
		(body.pagamentos || []).forEach(p => {
			if (p.tipoPagamento === TipoPagamento.VENDA_ANTECIPADA) body.creditoParaUtilizar += Number(p.valor);
		});
		body.creditoTotal = this.cliente._credito;

		body.alterarCliente = this.toggleAlterarCliente;
		if (this.frontIdList.includes(this.frontId)) {
			this.loading = false;
			return this._alert('Este pedido já foi enviado');
		}

		this.frontIdList.push(this.frontId);
		ctx.pedidosService.save(body, false, BlankComponent.ROUTE_ID, cpf_cnpj, nome).subscribe(
			async (_res: any) => {
				ctx.loading = false;
				this.pedidoBoletoOptions.show = false;
				const boleto = _res.object.boleto;
				if (boleto != null) {
					this.utils.downloadFile(boleto.linkBoleto, boleto.nomeBoleto);
					await ctx._alert(
						'Pedido gerado com sucesso!',
						'Verifique o relatório de boletos emitidos pelo sistema para mais detalhes do BOLETO gerado.'
					);
				} else {
					showSnackBar('Pedido salvo com sucesso!', Snackbar.TYPE_SUCCESS, ctx.snackBar);
				}
				const ok = await this.popupNFCE(
					(_res as any).object,
					this.pedidosService,
					this.smtpConfigService,
					this.utils,
					body.pagamentos.some(pag => pag.tipoPagamento == TipoPagamento.CARTAO_CREDITO || pag.tipoPagamento == TipoPagamento.CARTAO_DEBITO || pag.tipoPagamento == TipoPagamento.CARTAO_TODOS),
					this.broadcaster,
					this.snackBar
				)
				if (ok) {
					showSnackBar('Nota fiscal emitida com sucesso!', Snackbar.TYPE_SUCCESS, this.snackBar)
				}
				ctx.pedidoSuccess(ctx)
				this.frontId = nanoid(36);
			},
			_err => {
				ctx.loading = false;
				ctx.setPedCod();
				ctx.detectChanges();
				console.log(_err);
				if (_err.error != null && _err.error.showAsInfo == true) {
					ctx._alert(
						(_err.error || <any>{}).title || _err.error || _err,
						(_err.error || <any>{}).message || _err.error || ''
					).then(r => {
						if (_err.error.pedidoCreated == true) ctx.pedidoSuccess(ctx);
					});
				} else {
					showSnackBar((_err.error || <any>{}).title || _err.error || _err, Snackbar.TYPE_DANGER, ctx.snackBar, (_err.error || <any>{}).message || _err.error || '');
				}
				this.frontId = nanoid(36);
			},
		);
	}

	atualizarPedido() {
		this.loading = true;
		this.detectChanges();
		if (this.pedido.novoHistorico == null) { this.pedido.novoHistorico = []; }

		this.pedido.novoHistorico.push({ desc: 'Dados do pedido foram atualizados' });

		if (this.selectedEntregador) {
			this.pedido.entregadorIdEntregador = this.selectedEntregador.idEntregador;
			this.pedido.dataRepasse = new Date(Date.now());
		}

		const _result = [];

		for (const _p of this.pedido.produtos) {

			delete _p.pedidos;

			const prod = new Produto();
			prod.idProduto = _p.idProduto;
			prod.quantidade = _p.quantidade;
			prod.valorNegociado = null;

			let valor = _p.valorCheio || 0;
			let valorCusto = _p.valorCustoCheio || 0;

			if (_p.estoqueSelecionado && _p.estoqueSelecionado.tipoEstoque == TipoEstoque.ESTOQUE_VAZIO) {
				valor = _p.valorVazio || 0;
				valorCusto = _p.valorCustoVazio || 0;
			}

			/** Verifica se existe um valor de custo já associado */
			if (this.pedido.estoquesToUpdate != null) {
				(this.pedido.estoquesToUpdate || []).forEach(estqUpdt => {
					if (estqUpdt.idProduto === prod.idProduto && (estqUpdt.estoqueSelecionado || <Estoque>{}).tipoEstoque == TipoEstoque.ESTOQUE_VAZIO) {
						if (estqUpdt.valorCusto != null) valorCusto = estqUpdt.valorCusto;
						else valorCusto = _p.valorCustoVazio;
					} else if (estqUpdt.idProduto === prod.idProduto) {
						if (estqUpdt.valorCusto != null) valorCusto = estqUpdt.valorCusto;
						else valorCusto = _p.valorCustoCheio;
					}
				});
			}

			prod.valorCusto = valorCusto;
			prod.valorCompleto = valor;

			prod.estoqueSelecionado = new Estoque();

			if (_p.valorNegociado != null && _p.usarValorNegociado == true) prod.valorNegociado = _p.valorNegociado;

			prod.estoqueSelecionado.idEstoque = _p.estoqueSelecionado.idEstoque;
			prod.estoqueSelecionado.tipoEstoque = _p.estoqueSelecionado.tipoEstoque;

			_result.push(this.cloneJSON(prod));
		}

		this.pedido.produtosToUpdate = _result;

		if (this.pedido.status == PedidoStatus.PEDIDO_AGENDADO) this.pedido.agendado = true;

		let body = { ...this.pedido };

		delete (<any>body.cliente || {}).vendas;
		delete (<any>body.cliente || {}).empresas;
		delete (<any>body.cliente || {}).alertaSelected;
		delete (<any>body.cliente || {}).alertas;
		delete (<any>body.cliente || {}).valorNegociados;

		this.pedidosService.update(body, BlankComponent.ROUTE_ID).subscribe(
			_res => {
				this.loading = false;
				this._reset();
				this.detectChanges();
				showSnackBar('Pedido atualizado com sucesso!', Snackbar.TYPE_SUCCESS, this.snackBar);
				this.onPedidoAtualizado.emit('Pedido atualizado com sucesso!');
				// this.setClienteCod();
			},
			_err => {
				this.loading = false;
				this.detectChanges();
				if (_err.error != null && _err.error.showAsInfo == true) {
					this._alert(
						(_err.error || <any>{}).title || _err.error || _err,
						(_err.error || <any>{}).message || _err.error || ''
					);
				} else {
					showSnackBar((_err.error || <any>{}).title || _err.error || _err, Snackbar.TYPE_DANGER, this.snackBar, (_err.error || <any>{}).message || _err.error || '');
				}
			},
		);
	}

	confirmarDataDeAgendamento() {
		if (this.validate(true)) {
			this.pedido._showAgendamentoPopUp = true;
			this.disableAUtoUpdateField();
			if (this.pedido.idPedido != null) this.pedido.data = new Date(this.pedido.data);
			this.detectChanges();
		}
	}

	validate(isScheduleValidation: boolean = false): boolean {

		for (let p of (this.pedido.pagamentos || [])) {
			if (
				this.cliente != null &&
				this.cliente.prazoMaximo_formated != null &&
				p.dataVencimento != null &&
				((p.tipoPagamento == TipoPagamento.CHEQUE && p.formaPagamento == FormaPagamento.A_PRAZO) || p.tipoPagamento == TipoPagamento.BOLETO)
			) {
				try {
					let f = n => n < 10 ? `0${n}` : n;

					let dateVencimento = new Date(p.dataVencimento);
					let dataVencimentoMS = new Date(`${dateVencimento.getFullYear()}-${f(dateVencimento.getMonth() + 1)}-${f(dateVencimento.getDate())}`).getTime();
					let prazoMaximo = new Date(this.cliente.prazoMaximo_formated);
					let prazoMaximoMS = new Date(`${prazoMaximo.getFullYear()}-${f(prazoMaximo.getMonth() + 1)}-${f(prazoMaximo.getDate())}`).getTime();
					if (dataVencimentoMS > prazoMaximoMS) {
						showSnackBar(`Data de vencimento do ${p.tipoPagamento == TipoPagamento.CHEQUE ? 'CHEQUE' : 'BOLETO'} está maior que o prazo máximo do cliente!`, Snackbar.TYPE_DANGER, this.snackBar);
						return false;
					}
				} catch (err) {
					console.log(err);
				}
			}
		}

		if (this.cliente.idCliente == null && !this.clienteNomeControl.valid && !this.pedido.isEditMode) {
			showSnackBar('Informe o nome do cliente antes de continuar!', Snackbar.TYPE_DANGER, this.snackBar);
			return false;
		}

		if (this.cliente.idCliente == null && !this.clienteTelefoneControl.valid && !this.pedido.isEditMode) {
			showSnackBar('Informe o telefone do cliente antes de continuar!', Snackbar.TYPE_DANGER, this.snackBar);
			return false;
		}

		if (this.cliente != null) {
			if (this.cliente.enderecoDefault != null) {
				if (this.cliente.enderecoDefault.observacao != null) this.cliente.enderecoDefault.observacao = this.cliente.enderecoDefault.observacao.toUpperCase();
				if (this.cliente.enderecoDefault.complemento != null) this.cliente.enderecoDefault.complemento = this.cliente.enderecoDefault.complemento.toUpperCase();
			}
			this.cliente.outrosTelefones = [];
			this.cliente.outrosTelefones_container.forEach(t => {
				if (t.telefone != '' && t.telefone != null) this.cliente.outrosTelefones.push(t.telefone);
			});
		}
		this.pedido.cliente = this.cliente;

		if (this.selectedProdutos.length < 1) {
			showSnackBar('Selecione pelo menos um produto antes de continuar!', Snackbar.TYPE_WARNING, this.snackBar);
			return false;
		} else {
			this.pedido.produtos = this.selectedProdutos;
		}

		if ((this.pedido.pagamentos || []).length < 1) {
			showSnackBar('Selecione ao menos uma forma de pagamento antes de continuar!', Snackbar.TYPE_WARNING, this.snackBar);
			return false;
		}

		let valorPagamentos = 0;
		(this.pedido.pagamentos || []).forEach(p => valorPagamentos += (parseFloat(p.valor.toFixed(2)) * 100));
		if ((this.pedido.pagamentos || []).length > 1 && parseInt(valorPagamentos.toString()) != parseInt((this.pedido.valorTotal * 100).toString())) {
			showSnackBar(`O valor informado nos pagamentos(R$ ${((valorPagamentos / 100) || 0).toFixed(2)}) difere do valor total do pedido(R$ ${(this.pedido.valorTotal || 0).toFixed(2)}). Verifique os valores informados antes de continuar!`, Snackbar.TYPE_WARNING, this.snackBar);
			return false;
		} else if ((this.pedido.pagamentos || []).length == 1) this.pedido.pagamentos[0].valor = this.pedido.valorTotal;

		this.pedido.pagamentosNoPedido = this.pedido.pagamentos;

		if (this.pedido.data == null) {
			showSnackBar('Informe a data do pedido antes de continuar!', Snackbar.TYPE_WARNING, this.snackBar);
			return false;
		}

		const pagDinheiro = (this.pedido.pagamentos || []).find(p => p.tipoPagamento == TipoPagamento.DINHEIRO);
		if (pagDinheiro != null && !!this.pedido.troco && parseInt((this.pedido.troco || '0').toString()) < parseInt((pagDinheiro.valor || '0').toString())) {
			showSnackBar('Valor do troco não pode ser menor que o valor pago em dinheiro!', Snackbar.TYPE_WARNING, this.snackBar);
			return false;
		} else if ((this.pedido.pagamentos || []).find(p => p.tipoPagamento == TipoPagamento.DINHEIRO) == null) {
			this.pedido.troco = null;
		}

		if (!isScheduleValidation) {

			if (!!this.cliente.atrasoPagamento && this.cliente.showAtrasoPagamentoConfirm != true) {
				this.cliente.showAtrasoPagamentoConfirm = true;
				return false;
			} else if (
				!!this.cliente.atrasoPagamento &&
				this.cliente.showAtrasoPagamentoConfirm == true &&
				(this.cliente.atrasoPagamentoPassConfirm == null || this.cliente.atrasoPagamentoPassConfirm == '')
			) {
				showSnackBar('Confirme sua senha para liberar o pedido!', Snackbar.TYPE_WARNING, this.snackBar);
				return false;
			}
		}

		if (this.pedido.status == PedidoStatus.PEDIDO_AGENDADO && (this.pedido.data ? this.pedido.data.getTime() < Date.now() : true)) {
			showSnackBar(
				'Data e hora do agendamento é menor que a data atual!',
				Snackbar.TYPE_WARNING,
				this.snackBar,
				'Selecione uma data correta e tente novamente.'
			);
			return false;
		}

		if (this.selectedProdutos.some(prod => prod.quantidade == 0)) {
			showSnackBar('Exstem produtos com quantidade 0!', Snackbar.TYPE_WARNING, this.snackBar);
			return false;
		}

		this.detectChanges();

		return true;
	}

	private validators = [Validators.required];

	clienteTelefoneControl = new FormControl('', this.validators);
	clienteNomeControl = new FormControl('', this.validators);
	clienteCidadeControl = new FormControl('', this.validators);
	clienteCodigoControl = new FormControl('', []);
	// clienteEmailControl = new FormControl('', [ Validators.required, Validators.email ]);
	clienteEmailControl = new FormControl('', []);
	clienteComplementoControl = new FormControl('', []);
	clienteBairroControl = new FormControl('', []);
	clienteCepControl = new FormControl('', []);
	clienteNumeroControl = new FormControl('', []);
	clienteEstadoControl = new FormControl('', []);
	clienteCodMunicipioControl = new FormControl('', []);
	clienteObservacaoControl = new FormControl('', []);
	clienteProdutoControl = new FormControl('', []);
	clienteAniversarioControl = new FormControl('', []);
	clientePrazoMaximoControl = new FormControl('', []);
	clientePerfilControl = new FormControl('', []);
	clienteCpfControl = new FormControl('', []);
	clienteCfopControl = new FormControl('', []);
	clienteCanalVendaControl = new FormControl('', []);
	/* tslint:enable */

	salvarCliente() {
		if (this.validateNewUser()) {
			if (this.cliente.idCliente != null || this.cliente._id != null) {
				if (this.cliente.idCliente == null && this.cliente._id != null) {
					this.cliente.idCliente = this.cliente._id;
				}
				this.atualizarCliente();
			} else {
				this.cadastrarCliente();
			}
		}
	}

	atualizarCliente() {
		this.loading = true;
		let body: Cliente = JSON.parse(JSON.stringify(this.cliente));
		delete body.alertas;
		delete body.enderecos;
		delete body.outrosTelefones_container;
		delete body.pedidos;
		delete body.valorNegociados;
		this.clientesService.update(body, BlankComponent.ROUTE_ID).subscribe(
			_res => {
				this.loading = false;
				showSnackBar('Cliente atualizado com sucesso!', Snackbar.TYPE_SUCCESS, this.snackBar);
				this.setClienteCod();
				this._reset();
			},
			_err => {
				console.log(_err);
				this.loading = false;
				// showSnackBar('Não foi possível atualizar esse cliente', Snackbar.TYPE_DANGER, this.snackBar, 'Se o erro persistir, contate o administrador');
				let ef = this.formatError(_err);
				this._alert(ef.title, ef.message);
			},
		);
	}

	cadastrarCliente() {
		const ctx = this;

		/*
		this.geocoder.geocode({address: this.cliente.enderecoDefault.endereco + ', ' + this.cliente.enderecoDefault.cep + ', Brazil'}, function (results, status) {
			if (status === google.maps.GeocoderStatus.OK) {

				const fromGeocodeResult = getObjectFromGeocodeResult(results[0]);

				ctx.cliente.enderecoDefault.lat = fromGeocodeResult.lat;
				ctx.cliente.enderecoDefault.long = fromGeocodeResult.long;
			}

			ctx.enviarCliente();
		});
		*/
		this.enviarCliente();
	}

	enviarCliente() {
		// if (this.validateNewUser()) {
		this.loading = true;
		this.cliente.outrosTelefones = [];
		this.cliente.outrosTelefones_container.forEach(t => {
			if (t.telefone != '' && t.telefone != null) this.cliente.outrosTelefones.push(t.telefone);
		});

		let body: Cliente = JSON.parse(JSON.stringify(this.cliente));
		delete body.alertas;
		delete body.enderecos;
		delete body.outrosTelefones_container;
		delete body.pedidos;
		delete body.valorNegociados;

		this.clientesService.save(body, false, BlankComponent.ROUTE_ID).subscribe(
			_res => {
				this.loading = false;
				this.setCliente(_res.object as Cliente);
				this.detectChanges();
				showSnackBar('Cliente salvo com sucesso!', Snackbar.TYPE_SUCCESS, this.snackBar);
				this.setClienteCod();
				this._reset();
			},
			_err => {
				this.loading = false;
				this.detectChanges();
				// showSnackBar('Não foi possível salvar esse cliente, tente novamente!', Snackbar.TYPE_DANGER, this.snackBar, 'Se o erro persistir, contate o administrador');
				let ef = this.formatError(_err);
				this._alert(ef.title, ef.message);
			},
		);
		// }
	}

	validateNewUser(): boolean {
		//if (!!this.cliente.idCliente) { return false; }

		if (!this.cliente.estabelecimento) {
			showSnackBar('Informe o estabelecimento', Snackbar.TYPE_DANGER, this.snackBar);
			return false;
		}

		if (!this.clienteTelefoneControl.valid) {
			this.clienteTelefoneControl.setErrors({ 'required': true });
			showSnackBar('Informe o telefone do cliente antes de continuar!', Snackbar.TYPE_DANGER, this.snackBar);
			return false;
		}

		if (!this.clienteNomeControl.valid) {
			showSnackBar('Informe o nome do cliente antes de continuar!', Snackbar.TYPE_DANGER, this.snackBar);
			return false;
		}

		//if (!this.clienteCodigoControl.valid) { return false; }
		//if (!this.clienteEmailControl.valid) { return false; }
		//if (!this.enderecoControl.valid) { return false; }
		//if (!this.clienteComplementoControl.valid) { return false; }
		//if (!this.clienteBairroControl.valid) { return false; }
		//if (!this.clienteCepControl.valid) { return false; }

		if (this.cliente != null) {
			if (this.cliente.enderecoDefault != null) {
				if (this.cliente.enderecoDefault.observacao != null) this.cliente.enderecoDefault.observacao = this.cliente.enderecoDefault.observacao.toUpperCase();
				if (this.cliente.enderecoDefault.complemento != null) this.cliente.enderecoDefault.complemento = this.cliente.enderecoDefault.complemento.toUpperCase();
			}
			this.cliente.outrosTelefones = [];
			this.cliente.outrosTelefones_container.forEach(t => {
				if (t.telefone != '' && t.telefone != null) this.cliente.outrosTelefones.push(t.telefone);
			});
		}

		return true;
	}

	disableClienteFields() {
		this.clienteTelefoneControl.disable();
		this.clienteNomeControl.disable();
		// this.clienteCodigoControl.disable();
		this.clienteEmailControl.disable();
		this.clienteComplementoControl.disable();
		this.clienteBairroControl.disable();
		this.clienteCepControl.disable();
		this.clienteEstadoControl.disable();
		this.clienteCodMunicipioControl.disable();
		this.clienteObservacaoControl.disable();
		this.clienteProdutoControl.disable();
		this.clienteAniversarioControl.disable();
		this.clientePrazoMaximoControl.disable();
		this.clientePerfilControl.disable();
		this.clienteCpfControl.disable();
		this.clienteCfopControl.disable();
		this.clienteCanalVendaControl.disable();
		this.clienteNumeroControl.disable();
		this.clienteCidadeControl.disable();
		this.clienteCodigoControl.disable();
	}

	enableClienteFields() {
		this.clienteTelefoneControl.enable();
		this.clienteNomeControl.enable();
		// this.clienteCodigoControl.enable();
		this.clienteEmailControl.enable();
		this.clienteComplementoControl.enable();
		this.clienteBairroControl.enable();
		this.clienteCepControl.enable();
		this.clienteEstadoControl.enable();
		this.clienteCodMunicipioControl.enable();
		this.clienteObservacaoControl.enable();
		this.clienteProdutoControl.enable();
		this.clienteAniversarioControl.enable();
		this.clientePrazoMaximoControl.enable();
		this.clientePerfilControl.enable();
		this.clienteCpfControl.enable();
		this.clienteCfopControl.enable();
		this.clienteCanalVendaControl.enable();
		this.clienteNumeroControl.enable();
		this.clienteCidadeControl.enable();
		this.clienteCodigoControl.enable();
	}

	enterTab(inputId, event) {
		if (event.keyCode === 13) document.getElementById(inputId).focus();
	}

	async carregarEstabelecimentos(selectCloseEst: boolean = true) {
		try {
			this.estabelecimentoList = [];
			this.estabelecimetoSelecionado = this.getEstabelecimentoName();

			let estOnCache = $getEstabelecimentoOnCache();

			const _listEmpresa = (await this.empresasService.getAll(undefined, BlankComponent.ROUTE_ID).toPromise<Empresa[]>()) || [];
			const _listLoja = (await this.lojasService.getAll(undefined, BlankComponent.ROUTE_ID).toPromise<Loja[]>()) || [];

			if (estOnCache.allSelected || ((estOnCache || <Estabelecimento>{}).multiSelecao || []).length < 1) {
				this.listEmpresa = _listEmpresa;
				this.listLoja = _listLoja;
			} else {
				this.listEmpresa = [];
				this.listLoja = [];
				for (const _est of estOnCache.multiSelecao) {
					if (_est.empresa) this.listEmpresa.push(_est.empresa);
					if (_est.loja) this.listLoja.push(_est.loja);
				}
			}

			const ASGas = _listEmpresa.find(emp => emp._id == '5d1b96e5a876ed3cbb556c6e');
			const SilvaGas = this.listEmpresa.find(emp => emp._id == '5d1399737e07b763d5e843eb');
			const SilvaGasToSelect = _listEmpresa.find(emp => emp._id == '5d1399737e07b763d5e843eb');

			if (ASGas && !SilvaGas && SilvaGasToSelect && !this.userInfo.isAdmin) {
				this.listEmpresa.push(SilvaGasToSelect);
				this.findSilvaGas = true;
			}
			else {
				this.findSilvaGas = false;
			}

			this.clearEmpresaObject(this.listEmpresa);
			this.clearLojaObject(this.listLoja);

			if (selectCloseEst) await this.selectCloserEst();

			this.estabelecimentoList.push({
				nome: 'Empresas',
				empresas: this.listEmpresa.filter((v) => v.isAtivo == true)
			});

			this.estabelecimentoList.push({
				nome: 'Lojas',
				lojas: this.listLoja.filter((v) => v.isAtivo == true)
			});

		} catch (e) {
			console.log(e);
			this.listEmpresa = [];
			this.listLoja = [];
			this.estabelecimentoList = [];
		}
	}

	resetCliente() {
		this.cliente = new Cliente();
		this.cliente.enderecoDefault = new Endereco();
		this.cliente.enderecos = [];
	}

	selecionarEmpresa(e: Empresa, reset = true) {

		const _e = this.cloneJSON(e);

		this.clearEmpresaObject(_e);

		const est = new Estabelecimento(TipoEstabelecimento.EMPRESA, _e, null);
		this.pedido.estabelecimento = est;
		this.cliente.estabelecimento = est;

		if (!this.pedido.isEditMode) {
			this.selectedProdutos = [];
			this.produtos = [];
			this.filteredProdutos = EMPTY;
			this.selectedEntregador = null;
			this.entregadores = [];
			this.filteredEntregadores = EMPTY;
		}

		this.setPedCod();
		this.setClienteCod();

		this.loadEntregadores();
		this.loadProdutos();

		this.detectChanges();

		if (this.pedido.isEditMode && this.pedido.empresaIdEmpresa != e._id) this.resetarSelecao();
		if (reset && this.pedido.isEditMode && !this.cliente.empresas.some(emp => emp._id == e._id)) this.resetCliente();
	}

	selecionarLoja(l: Loja, reset = true) {
		const _l = this.cloneJSON(l);

		this.clearLojaObject(_l);

		const est = new Estabelecimento(TipoEstabelecimento.LOJA, null, _l);
		this.pedido.estabelecimento = est;
		this.cliente.estabelecimento = est;

		if (!this.pedido.isEditMode) {
			this.selectedProdutos = [];
			this.produtos = [];
			this.filteredProdutos = EMPTY;
			this.selectedEntregador = null;
			this.entregadores = [];
			this.filteredEntregadores = EMPTY;
		}

		this.setPedCod();
		this.setClienteCod();

		this.loadEntregadores();
		this.loadProdutos();

		this.detectChanges();

		if (this.pedido.isEditMode && this.pedido.lojaIdLoja != l._id) this.resetarSelecao();
		if (reset && this.pedido.isEditMode && !this.cliente.lojas.some(loj => loj._id == l._id)) this.resetCliente();
	}

	resetarSelecao() {
		this.removerTodosPrdutos();
		this.pedido.entregadorIdEntregador = null;
		this.selectedEntregador = null;
	}

	updateProdutoObject(index, newValues: Produto) {
		newValues._showQuickInfoPopUp = false;
		this.selectedProdutos[index] = this.cloneJSON(newValues);
	}

	autocompleteAddress() {

		if (!!this.cliente.enderecoDefault.cep && this.cliente.enderecoDefault.cep.length >= 9) {

			this.utils.getCepFromCorreios(this.cliente.enderecoDefault.cep).subscribe((e: Endereco) => {
				this.cliente.enderecoDefault = getObjectFromViaCEPParams(e);
				this.setLatlongFromCLiente();
				this.detectChanges();
			}, _err => console.log(_err));
		}
	}

	autocompletePessoaJuridica() {
		const cnpj = this.cliente.cnpj.toString().replace(/[^\d]/g, '');
		if (cnpj.length >= 14) {

			this.checkingCNPJ = true;
			this.detectChanges();

			this.utils.getInfoFromCNPJ(cnpj).then(data => {
				if (!!data && data.status == 'OK') {
					this.cliente.nomeFantasia = data.fantasia;
					this.cliente.razaoSocial = data.nome;
					this.cliente.enderecoDefault.cep = data.cep;
					this.cliente.enderecoDefault.cidade = data.municipio;
					this.cliente.enderecoDefault.bairro = data.bairro;
					this.cliente.enderecoDefault.endereco = data.logradouro;

					if (data.telefone != null) {
						let telefones = data.telefone.split('/');
						if (telefones.length > 0) {
							this.cliente.telefone = telefones[0];
							if (telefones.length > 1) {
								this.cliente.outrosTelefones = telefones.slice(1, telefones.length);
								this.cliente.outrosTelefones_container = telefones.slice(1, telefones.length).map(t => {
									return {
										telefone: t,
										_uniqueId: getRandomLetter(4) + Date.now()
									};
								});
							}
						}
					}

					if ((data.qsa || []).length > 0) this.cliente.nome = data.qsa != null && data.qsa.length > 0 && data.qsa[0] ? data.qsa[0].nome : '';
				}
				this.checkingCNPJ = false;
				this.detectChanges();
			}).catch(err => {
				console.log(err);
				this.checkingCNPJ = false;
				this.detectChanges();
			});
		}
	}

	onValorNegociadoCadastrado(vNegociado) {
		if (this.cliente && vNegociado) {
			if (this.cliente.valorNegociados == null) { this.cliente.valorNegociados = []; }

			if (this.cliente.saveValorNegociado != true && vNegociado.idValorNegociado == null) {
				this.cliente.saveValorNegociado = true;
			}

			this._showValorNegociadoPopUp = false;

			this.cliente.valorNegociados.unshift(this.cloneJSON(vNegociado));
			this.checkValorNegociado();
		}
	}

	setLatlongFromCLiente() {
		const ctx = this;

		/*
		this.geocoder.geocode({address: this.cliente.enderecoDefault.endereco + ', ' + this.cliente.enderecoDefault.cep + ', Brazil'}, async function (results, status) {
			if (status === google.maps.GeocoderStatus.OK) {

				const fromGeocodeResult = getObjectFromGeocodeResult(results[0]);

				ctx.cliente.enderecoDefault.lat = fromGeocodeResult.lat;
				ctx.cliente.enderecoDefault.long = fromGeocodeResult.long;
			}

			await ctx.selectCloserEst();
		});
		*/

		this.selectCloserEst();
	}
	// Habilita/Desabilita os campos do cliente de acordo com o toggle. 
	// O campo de endereço é desabilitado atraves do componente "buscar-endereco". By Pedro Santos
	toggleCliente() {
		console.log(this.toggleAlterarCliente)
		this.toggleAlterarCliente = !this.toggleAlterarCliente;
		if (this.toggleAlterarCliente) {
			this.enableClienteFields();
		} else {
			this.disableClienteFields();
		}
	}

	async selectEst(id_empresa: string, id_loja: string) {
		if (id_empresa != null || id_loja != null) {
			this.selectingClosesEst = true;
			this.detectChanges();
			try {
				this.listEmpresa = (this.listEmpresa || []).length > 0 ? this.listEmpresa : (await this.empresasService.getAll(undefined, BlankComponent.ROUTE_ID).toPromise<Empresa[]>()) || [];
				this.listLoja = (this.listLoja || []).length > 0 ? this.listLoja : (await this.lojasService.getAll(undefined, BlankComponent.ROUTE_ID).toPromise<Loja[]>()) || [];

				this.clearEmpresaObject(this.listEmpresa);
				this.clearLojaObject(this.listLoja);

				if (id_empresa) this.selecionarEmpresa((this.listEmpresa || []).find(e => e.idEmpresa === id_empresa), false);
				else if (id_loja) this.selecionarLoja((this.listLoja || []).find(l => l.idLoja === id_loja), false);
			} catch (err) { console.log(err); }
			this.selectingClosesEst = false;
			this.detectChanges();
		}
	}

	async selectCloserEst() {
		this.selectingClosesEst = true;
		this.detectChanges();

		try {
			//this.listEmpresa = (this.listEmpresa || []).length > 0 ? this.listEmpresa : (await this.empresasService.getAll(undefined, BlankComponent.ROUTE_ID).toPromise<Empresa[]>()) || [];
			//this.listLoja = (this.listLoja || []).length > 0 ? this.listLoja : (await this.lojasService.getAll(undefined, BlankComponent.ROUTE_ID).toPromise<Loja[]>()) || [];

			this.clearEmpresaObject(this.listEmpresa);
			this.clearLojaObject(this.listLoja);

			let est_selecionado = false;

			if (this.cliente != null) {
				if (!est_selecionado && (this.cliente.empresas || []).length > 0) {
					this.selecionarEmpresa((this.listEmpresa || []).find(e => e.idEmpresa === this.cliente.empresas[0]), false);
					est_selecionado = true;
				}

				if (!est_selecionado && (this.cliente.lojas || []).length > 0) {
					this.selecionarLoja((this.listLoja || []).find(l => l.idLoja === this.cliente.lojas[0]), false);
					est_selecionado = true;
				}
			}

			if (!est_selecionado) {
				let estOn = this.getEstabelecimentoOnCache();
				let canSelectEmp = false;
				let canSelectLoja = false;
				if (estOn.empresa != null || (estOn.multiSelecao || []).find(e => e.empresa != null) != null) canSelectEmp = true;
				if (estOn.loja != null || (estOn.multiSelecao || []).find(e => e.loja != null) != null) canSelectLoja = true;
				if (canSelectEmp && (this.listEmpresa || []).length > 0) this.selecionarEmpresa(this.listEmpresa[0], false);
				else if (canSelectLoja && (this.listLoja || []).length > 0) this.selecionarLoja(this.listLoja[0], false);
			}
		} catch (err) { console.log(err); }

		this.selectingClosesEst = false;
		this.detectChanges();
	}

	formatCloserEst(estabelecimentos) {

		const clienteLat = parseFloat((((this.cliente || {} as any).enderecoDefault || {} as any).lat || '0'));
		const clienteLong = parseFloat((((this.cliente || {} as any).enderecoDefault || {} as any).long || '0'));

		for (const e of estabelecimentos) {
			let distance: any = null;

			if (e.type === TipoEstabelecimento.EMPRESA) {

				const empLat = parseFloat((e.empresa.enderecoDefault.lat || '0'));
				const empLong = parseFloat((e.empresa.enderecoDefault.long || '0'));

				distance = this.utils.getDistanceBetween(
					{ lat: empLat, lng: empLong },
					{ lat: clienteLat, lng: clienteLong }
				);

				e.distancia = (distance / 1000).toFixed(1);
			} else if (e.type === TipoEstabelecimento.LOJA) {

				const lojLat = parseFloat((e.loja.enderecoDefault.lat || '0'));
				const lojLong = parseFloat((e.loja.enderecoDefault.long || '0'));

				distance = this.utils.getDistanceBetween(
					{ lat: lojLat, lng: lojLong },
					{ lat: clienteLat, lng: clienteLong }
				);

				e.distancia = (distance / 1000).toFixed(1);
			}
		}

		estabelecimentos.sort((a, b) => parseFloat(a.distancia) - parseFloat(b.distancia));

		if (!!estabelecimentos && estabelecimentos.length > 0) {
			const closerEst = estabelecimentos[0];

			if (closerEst.type === TipoEstabelecimento.EMPRESA) {
				this.selecionarEmpresa(closerEst.empresa, false);
			} else if (closerEst.type === TipoEstabelecimento.LOJA) {
				this.selecionarLoja(closerEst.loja);
			}
		}
	}

	onSearch(search: CSearch) {
		if ((this.cliente._id || this.cliente.idCliente) == null) {
			switch (search.field) {
				case 'nome':
					this.cliente.nome = search.value;
					break;
				case 'telefone':
					this.cliente.telefone = search.value;
					break;
				case 'endereco':
					this.cliente.enderecoDefault.endereco = search.value;
					break;
				case 'documento':
					if (this.cliente.tipoCliente == TipoCliente.PESSOA_JURIDICA) {
						this.cliente.cnpj = search.value;
					}
					else {
						this.cliente.cpf = search.value;
					}
					break;
			}
		}
	}

	/* tslint:disable */
	msgPagBloqueadoTimeout;
	/* tslint:enabled */

	verificarBloqueio(pagamentos: Pagamento[]) {

		if (this.msgPagBloqueadoTimeout) clearTimeout(this.msgPagBloqueadoTimeout);

		/** Verifica se o cliente tem bloqueio nessa forma de pagamento */
		if (this.cliente && this.cliente.tipoPagamentoBloqueado && (pagamentos || []).find(tp => this.cliente.tipoPagamentoBloqueado.includes(tp.tipoPagamento)) != null) {
			this.showChequeOptions = false;
			this.showBoletoOptions = false;
			this.pedido.pagamentos = (this.pedido.pagamentos || []).filter(tp => tp.tipoPagamento == null || !this.cliente.tipoPagamentoBloqueado.includes(tp.tipoPagamento));
			this.showPagamentoBloqueadoText = true;

			this.msgPagBloqueadoTimeout = setTimeout(() => {
				this.showPagamentoBloqueadoText = false;
				this.detectChanges();
			}, 10000);

			this.detectChanges();
		} else {
			this.showPagamentoBloqueadoText = false;
		}
	}

	disableAUtoUpdateField() {
		this.canUpdateDateField = false;
		this.detectChanges();
	}

	async configurarChamadaBina(c: Cliente, empresas: string[] = [], lojas: string[] = [], voip: boolean) {
		console.log('=== CHAMADA BINA ===');
		if (c != null && (c.idCliente != null || c.telefone != null)) {
			await this._reset();
			if (c.idCliente != null) {
				this.clientesService.get(c.idCliente, BlankComponent.ROUTE_ID).subscribe((cliente: Cliente) => {
					this.setCliente(cliente, empresas, lojas, voip);
				}, _err => console.error(_err));
			}
			else {
				if (c.telefone != null) this.cliente.telefone = c.telefone;
				if (voip) {
					this.cliente.empresas = empresas;
					this.cliente.lojas = lojas;
					this.selectCloserEst();
				}
			}
		}
	}

	/**
	 * Cliente
	 */
	popularOutrosTelefones_container(cliente: Cliente) {
		cliente.outrosTelefones_container = [];
		cliente.outrosTelefones.forEach(t => {
			cliente.outrosTelefones_container.push({ telefone: t, _uniqueId: (t || '') + getRandomLetter(4) + Date.now() });
		});
	}

	adicionarOutroTelefone() {
		if (this.cliente != null) {
			if (this.cliente.outrosTelefones_container == null) this.cliente.outrosTelefones_container = [];
			this.cliente.outrosTelefones_container = this.cliente.outrosTelefones_container.filter(_t => _t.telefone !== '' && _t.telefone != null);
			this.cliente.outrosTelefones_container.push({ telefone: '', _uniqueId: getRandomLetter(4) + Date.now() });
			this.detectChanges();
		}
	}

	removerOutroTelefone(tel: { telefone: string, _uniqueId: string }) {
		if (this.cliente != null) {
			if (this.cliente.outrosTelefones_container == null) this.cliente.outrosTelefones_container = [];
			this.cliente.outrosTelefones_container = this.cliente.outrosTelefones_container.filter(_t => _t._uniqueId !== tel._uniqueId);
			this.detectChanges();
		}
	}

	getBoletoPagamento: () => Pagamento = () => (this.pedido.pagamentos || []).find(p => p.tipoPagamento == TipoPagamento.BOLETO);

	fecharBoletoOptions() {
		this.pedidoBoletoOptions.show = false;
		this.pedidoBoletoOptions.action = null;
		this.pedidoBoletoOptions.pedidoStatus = null;
		this.detectChanges();
	}

	registrarBoleto() { this.pedidoBoletoOptions.action = 'registrar'; this.detectChanges(); }

	emitirBoleto() { this.pedidoBoletoOptions.action = 'emitir'; this.detectChanges(); }

	configurarMapaRotaDeEntrega(pedido: Pedido) {
		if (this.showOrderSummary != true) return;

		let initCoord = pedido != null && pedido.rotaDeEntrega != null && (pedido.rotaDeEntrega.coordinates || []).length > 0 ? [pedido.rotaDeEntrega.coordinates[0][1], pedido.rotaDeEntrega.coordinates[0][0]] : null;

		const map = L.map('leaflet-map', {
			center: <any>(initCoord != null ? initCoord : [-15.7941, -47.8825]),
			zoom: 15,
			zoomControl: false
		});

		L.control.zoom(AppConfig.getLeafletZoomOptions()).addTo(map);

		AppConfig.getLeafletTileLayer().addTo(map);

		if (pedido.rotaDeEntrega != null && (pedido.rotaDeEntrega.coordinates || []).length > 0) {
			let initCoord = pedido.rotaDeEntrega.coordinates[0];
			let endCoord = pedido.rotaDeEntrega.coordinates[pedido.rotaDeEntrega.coordinates.length - 1];

			new L.Polyline(
				pedido.rotaDeEntrega.coordinates.map(c => new L.LatLng(c[1], c[0])),
				{
					color: '#0e0554',
					weight: 5,
					opacity: 1,
					smoothFactor: 1
				}
			).addTo(map);

			pedido.rotaDeEntrega.coordinates.map(c => new L.LatLng(c[1], c[0])).forEach(coord => {
				new L.CircleMarker(coord, {
					radius: 5,
					fillColor: "#0e0554",
					color: "#000",
					weight: 1,
					opacity: 0,
					fillOpacity: 0,
				}).addTo(map);
			});

			AppConfig.setMarkerIcon(L.marker([initCoord[1], initCoord[0]]))
				.bindTooltip('Início', { permanent: true, direction: 'top', offset: [0, -15] })
				.addTo(map)
				.openTooltip();

			AppConfig.setMarkerIcon(L.marker([endCoord[1], endCoord[0]]))
				.bindTooltip('Fim', { permanent: true, direction: 'top', offset: [0, -15] })
				.addTo(map)
				.openTooltip();
		}
	}
}
