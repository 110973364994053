import {Injectable} from '@angular/core';
import {ServicePattern} from '../../models/interface/ServicePattern';
import {Usuario} from '../../models/api/Usuario';
import {HttpClient} from '@angular/common/http';
import {TokenStorage} from '../../auth/token-storage.service';
import {Observable} from 'rxjs';
import {AppConfig} from '../../../config/appConfig';
import {ResultObject} from '../../models/api/ResultObject';
import {EstabelecimentoFunctions} from '../../models/interface/EstabelecimentoFunctions';
import {DateFilter} from '../../models/interface/DateFilter';

@Injectable()
export class UsuariosService extends EstabelecimentoFunctions implements ServicePattern<Usuario> {

	readonly ROUTE = '/usuarios';

	constructor(private http: HttpClient, private tokenStorage: TokenStorage) {
		super();
	}

	getAll(
		dateFilter: DateFilter = { dataInicio: null, dataFim: null },
		componentId: string = '',
		page: number = null,
		perPage: number = null,
		ignoreEstabelecimentoOnCache: boolean = false,
		estabelecimentos: { empresa?: string, loja?: string, multiEmpresa?: string, multiLoja?: string } = null,
		searchString: string = null,
		estabelecimentoOnParam: boolean = false,
		ignorePopulateFilter?: boolean,
		ultimaInteracao?: number
	): Observable<Usuario[]> {
		return this.http.get<Usuario[]>(
			AppConfig.getAPIEndpoint() + this.ROUTE + '/list/' + this.tokenStorage.getUserInfo().idUsuario,
			{
				headers: AppConfig.getHTTPHeader().headers.set('componentId', componentId),
				params: {
					...(
						ignoreEstabelecimentoOnCache == true && estabelecimentoOnParam != true ?
							( estabelecimentos != null ? estabelecimentos : {} ) :
							this.getEstabelecimentoQueryParam(ignoreEstabelecimentoOnCache == true ? estabelecimentos : undefined)
					),
					...this.getDateFilterQueryParam(dateFilter.dataInicio, dateFilter.dataFim),
					...(page || page == 0 ? { page: page + 1 } : {}),
					...(perPage ? { per_page: perPage } : {}),
					...(searchString != null ? { searchString: searchString } : {}),
					...(ignorePopulateFilter != null ? { ignorePopulateFilter: ignorePopulateFilter } : {}),
					...(ultimaInteracao ? { ultimaInteracao } : {})
				}
			}
		);
	}

	getAllByProfile(idUsuarioFuncao: number, componentId: string = ''): Observable<Usuario[]> {
		return this.http.get<Usuario[]>(
			AppConfig.getAPIEndpoint() + this.ROUTE + '/list/' + this.tokenStorage.getUserInfo().idUsuario,
			{
				headers: AppConfig.getHTTPHeader().headers.set('componentId', componentId),
				params: {
					perfil: idUsuarioFuncao.toString()
				}
			}
		);
	}

	get(idUsuarioFuncao: number): Observable<Usuario> {
		// TODO
		return null;
	}

	save(body: Usuario, getEstabelecimentoFromCache: boolean = true, componentId: string = ''): Observable<ResultObject<any>> {
		if (getEstabelecimentoFromCache) { body.estabelecimento = this.getEstabelecimentoOnCache(); }
		return this.http.post<ResultObject<any>>(
			AppConfig.getAPIEndpoint() + this.ROUTE + '/' + this.tokenStorage.getUserInfo().idUsuario,
			body,
			{
				headers: AppConfig.getHTTPHeader().headers.set('componentId', componentId)
			}
		);
	}

	update(body: Usuario, componentId: string = ''): Observable<ResultObject<any>> {
		return this.http.put<ResultObject<any>>(
			AppConfig.getAPIEndpoint() + this.ROUTE + '/' + this.tokenStorage.getUserInfo().idUsuario,
			body,
			{
				headers: AppConfig.getHTTPHeader().headers.set('componentId', componentId)
			}
		);
	}

	updateMeusDados(body: Usuario, componentId: string = ''): Observable<ResultObject<any>> {
		return this.http.put<ResultObject<any>>(
			AppConfig.getAPIEndpoint() + this.ROUTE + '/meus_dados/' + this.tokenStorage.getUserInfo().idUsuario,
			body,
			{
				headers: AppConfig.getHTTPHeader().headers.set('componentId', componentId)
			}
		);
	}

	updateFoto(formData: FormData, componentId: string = ''): Observable<ResultObject<any>> {
		return this.http.put<any>(
			AppConfig.getAPIEndpoint() + this.ROUTE + '/foto/' + this.tokenStorage.getUserInfo().idUsuario,
			formData
		)
	}

	delete(idUsuario: number, componentId: string = ''): Observable<ResultObject<any>> {
		return this.http.delete<ResultObject<any>>(
			AppConfig.getAPIEndpoint() + this.ROUTE + '/' + this.tokenStorage.getUserInfo().idUsuario + '/' + idUsuario,
			{
				headers: AppConfig.getHTTPHeader().headers.set('componentId', componentId)
			}
		);
	}

	getEntriesCount(componentId: string = ''): Observable<{entries: number}> {
		return this.http.get<{entries: number}>(
			AppConfig.getAPIEndpoint() + this.ROUTE + '/list/' + this.tokenStorage.getUserInfo().idUsuario,
			{
				headers: AppConfig.getHTTPHeader().headers.set('componentId', componentId),
				params: {
					...this.getEstabelecimentoQueryParam(),
					entriesOnly: true
				}
			}
		);
	}

	removeAssociation(idUsuario: any, idEmpresa: any, idLoja: any, componentId: string = '') {
		return this.http.post<ResultObject<any>>(
			AppConfig.getAPIEndpoint() + this.ROUTE + '/remover/associacao/' + this.tokenStorage.getUserInfo().idUsuario,
			{
				idUsuario: idUsuario,
				idEmpresa: idEmpresa,
				idLoja: idLoja
			},
			{
				headers: AppConfig.getHTTPHeader().headers.set('componentId', componentId)
			}
		);
	}

	associate(idUsuario: number, idEmpresa: number, idLoja: number, componentId: string = '') {
		return this.http.post<ResultObject<any>>(
			AppConfig.getAPIEndpoint() + this.ROUTE + '/associacao/' + this.tokenStorage.getUserInfo().idUsuario,
			{
				idUsuario: idUsuario,
				idEmpresa: idEmpresa,
				idLoja: idLoja
			},
			{
				headers: AppConfig.getHTTPHeader().headers.set('componentId', componentId)
			}
		);
	}

	autenticarSenhaDeAutorizacao(u: Usuario = new Usuario(), componentId: string = ''): Observable<any> {
		return this.http.post<any>(
			AppConfig.getAPIEndpoint() + '/usuarios/autorizacao/auth',
			{
				idUsuario: u.idUsuario,
				senhaAutorizacao: u.senhaAutorizacao
			},
			{
				headers: AppConfig.getHTTPHeader().headers.set('componentId', componentId)
			}
		);
	}

	logarComoAdmin(usuarioId: string, componentId: string = ''): Observable<any> {
		return this.http.get<any>(
			AppConfig.getAPIEndpoint() + this.ROUTE + '/auth_admin/' + usuarioId + '/' + this.tokenStorage.getUserInfo().idUsuario,
			{
				headers: AppConfig.getHTTPHeader().headers.set('componentId', componentId)
			}
		);
	}

	deslogar(id: string, componentId: string = ''): Observable<any> {
		return this.http.post<any>(
			AppConfig.getAPIEndpoint() + this.ROUTE + '/deslogar/' + this.tokenStorage.getUserInfo().idUsuario,
			{
				usuario: id
			},
			{
				headers: AppConfig.getHTTPHeader().headers.set('componentId', componentId)
			}
		)
	}

	reiniciar(id: string, componentId: string = ''): Observable<any> {
		return this.http.post<any>(
			AppConfig.getAPIEndpoint() + this.ROUTE + '/reiniciar/' + this.tokenStorage.getUserInfo().idUsuario,
			{
				usuario: id
			},
			{
				headers: AppConfig.getHTTPHeader().headers.set('componentId', componentId)
			}
		)
	}

	setEstabelecimentosBina(empresas: string[] = [], lojas: string[] = [], componentId: string = '', empresa: string = null, loja: string = null, usuario: string = null): Observable<any> {
		return this.http.put<any>(
			AppConfig.getAPIEndpoint() + this.ROUTE + '/estabelecimento_bina/' + this.tokenStorage.getUserInfo().idUsuario,
			{
				empresas,
				lojas,
				empresa,
				loja,
				usuario
			},
			{
				headers: AppConfig.getHTTPHeader().headers.set('componentId', componentId)
			}
		)
	}

	setEstabelecimentosBinaRecebe(empresas: string[] = [], lojas: string[] = [], componentId: string = '', empresa: string = null, loja: string = null, usuario: string = null): Observable<any> {
		return this.http.put<any>(
			AppConfig.getAPIEndpoint() + this.ROUTE + '/estabelecimento_bina_recebe/' + this.tokenStorage.getUserInfo().idUsuario,
			{
				empresas,
				lojas,
				empresa,
				usuario,
				loja
			},
			{
				headers: AppConfig.getHTTPHeader().headers.set('componentId', componentId)
			}
		)
	}

	checkSenhaAutorizacao(usuario, senha, componentId: string = '') {
		return this.http.post<any>(
			AppConfig.getAPIEndpoint() + this.ROUTE + '/check_senha_autorizacao/' + this.tokenStorage.getUserInfo().idUsuario,
			{
				usuario,
				senha
			},
			{
				headers: AppConfig.getHTTPHeader().headers.set('componentId', componentId)
			}
		)
	}

	userInfo(componentId: string = ''): Observable<Usuario> {
		return this.http.get<Usuario>(
			AppConfig.getAPIEndpoint() + this.ROUTE + '/userinfo/' + this.tokenStorage.getUserInfo().idUsuario,
			{
				headers: AppConfig.getHTTPHeader().headers.set('componentId', componentId)
			}
		);
	}
}
