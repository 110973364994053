import {Injectable} from '@angular/core';
import {ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot} from '@angular/router';
import {Observable, throwError} from 'rxjs';

import {TokenStorage} from '../token-storage.service';
import {AuthenticationService} from '../authentication.service';
import {LoginComponent} from '../../../content/pages/auth/login/login.component';
import {SocketService} from '../../services/api/socket.service';
import {RecoverPasswordComponent} from '../../../content/pages/auth/recover-password/recover-password.component';
import {Permissoes} from '../../models/api/Permissoes';
import {isLastPos} from '../../services/utils.service';
import { BlankComponent } from '../../../content/pages/components/blank/blank.component';

declare const _alert;

@Injectable()
export class AuthGuard implements CanActivate {

	constructor(
		private _router: Router,
		private tokenStorage: TokenStorage,
		private _authService: AuthenticationService
	) {

	}

	canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> | boolean {

		const _secundaryRouteIds = route.data['secundaryIds'];

		const routeIsVisibleOnlyToManager = route.data['visibleOnlyToManager'] as boolean;

		const userInfo = this.tokenStorage.getUserInfo();
		const accessToken = this.tokenStorage.getAccessTokenSync();

		let secondaryRoutes = [];

		if (_secundaryRouteIds != null) {
			try {
				Object.keys(_secundaryRouteIds).forEach(key => secondaryRoutes.push(_secundaryRouteIds[key]));
			} catch (err) {
				console.log(err);
			}
		}

		let routesToValidate = [route.data['id'] as string].concat(secondaryRoutes);

		/**
		 * Rotas para verificar
		 */
		let _i = 0;
		for (let routeId of routesToValidate) {

			if (!!userInfo) {

				/* Verifica se o token do usuario ainda está ativo */
				if ((!!accessToken && accessToken !== '') && Date.now() < userInfo.tokenExpiration) {
					if (routeId === LoginComponent.ROUTE_ID || routeId === RecoverPasswordComponent.ROUTE_ID) {
						this._router.navigate(['/']);
						return true;
					} else {

						let canPass: boolean = false;

						if (routeId == 'callcenter:empty') {
							canPass = true;
						}
						else {
							/* Verifica se o usuário tem permisão suficiente para visualizar a página */
							for (const p of userInfo.permissoes.paginas) {

								/* Deixa passar se for admin ou um usuario com todas as permissões */
								if (userInfo.isAdmin || p === '*') {
									if (userInfo.isAdmin || (routeIsVisibleOnlyToManager ? userInfo.isUserGroupOwner : true)) {
										// SocketService.initSocket();
										// return true;
										canPass = true;
									}
								}

								if (p === routeId) {
									if ((routeIsVisibleOnlyToManager ? userInfo.isUserGroupOwner : true)) {
										// SocketService.initSocket();
										// return true;
										canPass = true;
									}
								}
							}

							const rotasFront_estabelecimento = (userInfo.permissoes_estabelecimento || <Permissoes>{}).paginas;

							if (canPass && rotasFront_estabelecimento && userInfo.isAdmin != true) {
								canPass = false;
								for (const p of rotasFront_estabelecimento) {
									if (p == '*' || p == routeId) {
										canPass = true;
										break;
									}
								}
							}
						}

						if (canPass) {
							SocketService.initSocket();
							return true;
						}
					}
				}
			}

			if (isLastPos(_i, routesToValidate)) {
				if (routeId !== LoginComponent.ROUTE_ID && routeId != '' && routeId != BlankComponent.ROUTE_ID) {
					//this._authService.logout();
					_alert('Você não tem permissão para acessar esta tela');
					this._router.navigateByUrl('/empty');
					return false;
				} else {
					return true;
				}
			}

			++_i;
		}

	}
}
