import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import {Usuario} from '../models/api/Usuario';

@Injectable()
export class TokenStorage {

	public static readonly KEY_ACCESS_TOKEN = 'accessToken';
	public static readonly KEY_USER_INFO = 'userInfo';
	public static readonly IS_LOGGED = 'isLogged';
	public static readonly KEY_CACHE_ESTABELECIMENTO = 'cacheEstabelecimento';
	public static readonly KEY_CACHE_DEFAULT_DATE = 'defaultDate';
	public static readonly IGNORAR_POP_UP = 'ignorarPopUp';
	public static readonly POP_UP_SHOWED_TIMES = 'popUpShowedTimes';

	public static readonly PLAY_NOTIFICATION_SOUND = 'play_notification_sound';
	public static readonly PLAY_BINA_SOUND = 'play_bina_sound';
	public static readonly PLAY_CHAT_SOUND = 'play_chat_sound';
	public static readonly SHOW_NEW_ORDER_NOTIFICATION_ON_CART = 'show_new_order_notification_on_cart';

	public static readonly NFCE_LANCAMENTO_PEDIDO = 'nfce_lancamento_pedido';
	public static readonly REPASSAR_LANCAMENTO_PEDIDO = 'repassar_lancamento_pedido';

	public static retrieveUserInfo(): Usuario {
		return JSON.parse(localStorage.getItem(TokenStorage.KEY_USER_INFO));
	}

	/**
	 * Get access token
	 * @returns {Observable<string>}
	 */
	public getAccessToken(): Observable<string> {
		const token: string = <string>localStorage.getItem(TokenStorage.KEY_ACCESS_TOKEN);
		return of(token);
	}


	public getAccessTokenSync(): string {
		return localStorage.getItem(TokenStorage.KEY_ACCESS_TOKEN);
	}

	/**
	 * Set access token
	 * @returns {TokenStorage}
	 */
	public setAccessToken(token: string): TokenStorage {
		localStorage.setItem(TokenStorage.KEY_ACCESS_TOKEN, token);
		return this;
	}

	/**
	 * Get user informations
	 * @returns {Observable<string>}
	 */
	public getUserInfoObservable(): Observable<any> {
		const userInfo = localStorage.getItem(TokenStorage.KEY_USER_INFO);
		return of(userInfo);
	}

	public getUserInfo(): Usuario {
		return JSON.parse(localStorage.getItem(TokenStorage.KEY_USER_INFO));
	}

	/**
	 * Set user informations
	 * @returns {TokenStorage}
	 */
	public setUserInfo(userInfo: any): TokenStorage {
		localStorage.setItem(TokenStorage.KEY_USER_INFO, JSON.stringify(userInfo));
		localStorage.setItem(TokenStorage.IS_LOGGED, 'true');
		return this;
	}

	/**
	 * Remove tokens
	 */
	public clear() {
		localStorage.removeItem(TokenStorage.KEY_ACCESS_TOKEN);
		localStorage.removeItem(TokenStorage.KEY_USER_INFO);
		localStorage.removeItem(TokenStorage.KEY_CACHE_ESTABELECIMENTO);
		localStorage.removeItem(TokenStorage.POP_UP_SHOWED_TIMES);
		localStorage.removeItem(TokenStorage.IGNORAR_POP_UP);
		localStorage.removeItem('voip_data');
		//localStorage.removeItem('refreshToken');
		//localStorage.removeItem('userRoles');
	}
}

export function $getUserInfo(defaultValue?): Usuario {
	return JSON.parse(localStorage.getItem(TokenStorage.KEY_USER_INFO)) || defaultValue;
}

export function $isLogged(): boolean {
	return localStorage.getItem(TokenStorage.IS_LOGGED) == 'true';
}

/**
 * Admin Dashboard
 */
export const ADMIN_MENU_STORAGE = 'admin_menu_key';

export enum AdminMenuOptions {
	SYSTEM = <any>'admin_menu_system',
	MANAGEMENT = <any>'admin_menu_management',
}

export function $getAdminMenu(): AdminMenuOptions {
	return <any>localStorage.getItem(ADMIN_MENU_STORAGE);
}

export function $setAdminMenu(adminMenu: AdminMenuOptions): boolean {
	localStorage.setItem(ADMIN_MENU_STORAGE, adminMenu.toString());
	return true;
}
