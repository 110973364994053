import {DefaultFields} from '../interface/DefaultFields';
import {Empresa} from './Empresa';
import {Loja} from './Loja';
import {Cliente} from './Cliente';
import {Produto} from './Produto';
import {Usuario} from './Usuario';
import {TipoEstoqueNegociado} from '../enum/TipoEstoqueNegociado';

export class ValorNegociado extends DefaultFields<ValorNegociado> {

	idValorNegociado: number;

	valor: number;

	numero: string;

	estoqueNegociado: TipoEstoqueNegociado;

	incluidoPor: Usuario[];

	autorizadoPor: Usuario[];

	cliente: Cliente;

	produto: Produto;

	empresa: Empresa;

	loja: Loja;

	/** request fiels */
	idProduto: number;
	idCliente: number;

	autorizadoPorUsuarioId: number;
}
