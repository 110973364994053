import {Injectable} from '@angular/core';
import {EstabelecimentoFunctions} from '../../models/interface/EstabelecimentoFunctions';
import {ServicePattern} from '../../models/interface/ServicePattern';
import {HttpClient} from '@angular/common/http';
import {TokenStorage} from '../../auth/token-storage.service';
import {Observable} from 'rxjs';
import {AppConfig} from '../../../config/appConfig';
import {ResultObject} from '../../models/api/ResultObject';
import {NotaAvariada} from '../../models/api/NotaAvariada';

@Injectable()
export class NotasAvariadasService extends EstabelecimentoFunctions implements ServicePattern<NotaAvariada>{

	constructor(private http: HttpClient, private tokenStorage: TokenStorage) {
		super();
	}

	getAll(dateFilter: { dataInicio: Date, dataFim: Date } = { dataInicio: null, dataFim: null }, componentId: string = ''): Observable<NotaAvariada[]> {
		return this.http.get<NotaAvariada[]>(
			AppConfig.getAPIEndpoint() + '/notasAvariadas/list/' + this.tokenStorage.getUserInfo().idUsuario,
			{
				headers: AppConfig.getHTTPHeader().headers.set('componentId', componentId),
				params: {
					...this.getEstabelecimentoQueryParam(),
					...this.getDateFilterQueryParam(dateFilter.dataInicio, dateFilter.dataFim)
				}
			}
		);
	}

	get(idNotaAvariada: number, componentId: string = ''): Observable<NotaAvariada> {
		return this.http.get<NotaAvariada>(
			AppConfig.getAPIEndpoint() + '/notasAvariadas/' + idNotaAvariada + '/' + this.tokenStorage.getUserInfo().idUsuario,
			{
				headers: AppConfig.getHTTPHeader().headers.set('componentId', componentId),
			}
		);
	}

	save(body: NotaAvariada, componentId: string = ''): Observable<ResultObject<any>> {
		body.estabelecimento = this.getEstabelecimentoOnCache();
		return this.http.post<ResultObject<any>>(
			AppConfig.getAPIEndpoint() + '/notasAvariadas/' + this.tokenStorage.getUserInfo().idUsuario,
			body,
			{
				headers: AppConfig.getHTTPHeader().headers.set('componentId', componentId)
			}
		);
	}

	update(body: NotaAvariada, componentId: string = ''): Observable<ResultObject<any>> {
		return this.http.put<ResultObject<any>>(
			AppConfig.getAPIEndpoint() + '/notasAvariadas/' + this.tokenStorage.getUserInfo().idUsuario,
			body,
			{
				headers: AppConfig.getHTTPHeader().headers.set('componentId', componentId)
			}
		);
	}

	delete(idNotaAvariada: number): Observable<ResultObject<any>> {
		// TODO
		return null;
	}
}
