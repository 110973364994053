import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { TokenStorage } from '../../auth/token-storage.service';
import { AppConfig } from '../../../config/appConfig';

@Injectable()
export class BinaService {
	readonly ROUTE = '/bina';

	constructor(private http: HttpClient, private tokenStorage: TokenStorage) { }

	listarChamadas(
		page: number | any = null,
		perPage: number | any = null,
		dataInicio: Date | any = null,
		dataFim: Date | any = null,
		searchString: string | any = null,
		multiEmpresa: string | any = null,
		multiLoja: string | any = null,
		componentId: string | any = '',
	): Observable<any[]> {
		return this.http.get<any[]>(
			AppConfig.getAPIEndpoint() + this.ROUTE + '/chamadas/' + this.tokenStorage.getUserInfo().idUsuario,
			{
				headers: AppConfig.getHTTPHeader().headers.set('componentId', componentId),
				params: {
					...(page != null ? { page } : {}),
					...(perPage != null ? { perPage } : {}),
					...(dataInicio != null ? { dataInicio } : {}),
					...(dataFim != null ? { dataFim } : {}),
					...(searchString != null ? { searchString } : {}),
					...(multiEmpresa != null ? { multiEmpresa } : {}),
					...(multiLoja != null ? { multiLoja } : {}),
				}
			}
		);
	}

	iniciarPedido(idChamada: string, componentId: string = ''): Observable<any> {
		return this.http.put<any>(
			AppConfig.getAPIEndpoint() + this.ROUTE + '/atender/' + idChamada + '/' + this.tokenStorage.getUserInfo().idUsuario,
			{},
			{
				headers: AppConfig.getHTTPHeader().headers.set('componentId', componentId)
			}
		);
	}

	adicionarConfiguracaoBina(nome: string, regex?: string, taxaTransmissao?: number, porta?: number, componentId: string = '') {
		return this.http.post<any>(
			AppConfig.getAPIEndpoint() + this.ROUTE + '/config_bina/' + this.tokenStorage.getUserInfo().idUsuario,
			{
				nome,
				...(regex ? { regex } : {}),
				...(taxaTransmissao ? { taxaTransmissao } : {}),
				...(porta ? { porta } : {})
			},
			{
				headers: AppConfig.getHTTPHeader().headers.set('componentId', componentId)
			}
		);
	}

	listarConfiguracaoBina(componentId: string = '') {
		return this.http.get<any>(
			AppConfig.getAPIEndpoint() + this.ROUTE + '/config_bina/' + this.tokenStorage.getUserInfo().idUsuario,
			{
				headers: AppConfig.getHTTPHeader().headers.set('componentId', componentId)
			}
		);
	}

	alterarConfiguracaoBina(nome: string, idConfigBina: string, regex?: string, taxaTransmissao?: number, porta?: number, componentId: string = '') {
		return this.http.put<any>(
			AppConfig.getAPIEndpoint() + this.ROUTE + '/config_bina/' + idConfigBina + '/' + this.tokenStorage.getUserInfo().idUsuario,
			{
				nome,
				...(regex ? { regex } : {}),
				...(taxaTransmissao ? { taxaTransmissao } : {}),
				...(porta ? { porta } : {})
			},
			{
				headers: AppConfig.getHTTPHeader().headers.set('componentId', componentId)
			}
		);
	}

	removerConfiguracaoBina(idConfigBina: string, componentId: string = '') {
		return this.http.delete<any>(
			AppConfig.getAPIEndpoint() + this.ROUTE + '/config_bina/' + idConfigBina + '/' + this.tokenStorage.getUserInfo().idUsuario,
			{
				headers: AppConfig.getHTTPHeader().headers.set('componentId', componentId)
			}
		);
	}
}
