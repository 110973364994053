import {Injectable} from '@angular/core';
import {HttpClient, HttpParams} from '@angular/common/http';
import {TokenStorage} from '../../auth/token-storage.service';
import {Observable} from 'rxjs';
import {AppConfig} from '../../../config/appConfig';
import {EstabelecimentoFunctions} from '../../models/interface/EstabelecimentoFunctions';
import {TipoPagamento} from '../../models/enum/TipoPagamento';
import {DateFilter} from '../../models/interface/DateFilter';
import {DoughnutData} from '../../../content/partials/content/widgets/charts/doughnut-chart/doughnut-chart.component';
import { map } from 'rxjs/operators';


@Injectable()
export class EstatisticasService extends EstabelecimentoFunctions {

	constructor(private http: HttpClient, private tokenStorage: TokenStorage) {
		super();
	}

	getTempoMedioEntregas(dateFilter: { dataInicio: Date, dataFim: Date } = {
		dataInicio: null,
		dataFim: null
	}, componentId: string = ''): Observable<any> {
		return this.http.get(
			AppConfig.getAPIEndpoint() + '/estatisticas/media/tempo/entrega/' + this.tokenStorage.getUserInfo().idUsuario,
			{
				headers: AppConfig.getHTTPHeader().headers.set('componentId', componentId),
				params: {
					...this.getEstabelecimentoQueryParam(),
					...this.getDateFilterQueryParam(dateFilter.dataInicio, dateFilter.dataFim)
				}
			}
		);
	}

	getTempoMedioEntregasProduto(dateFilter: { dataInicio: Date, dataFim: Date } = {
		dataInicio: null,
		dataFim: null
	}, componentId: string = ''): Observable<any> {
		return this.http.get(
			AppConfig.getAPIEndpoint() + '/estatisticas/media/tempo/entrega/produtos/' + this.tokenStorage.getUserInfo().idUsuario,
			{
				headers: AppConfig.getHTTPHeader().headers.set('componentId', componentId),
				params: {
					...this.getEstabelecimentoQueryParam(),
					...this.getDateFilterQueryParam(dateFilter.dataInicio, dateFilter.dataFim)
				}
			}
		);
	}

	getTempoMedioEntregasEntregador(dateFilter: { dataInicio: Date, dataFim: Date } = {
		dataInicio: null,
		dataFim: null
	}, componentId: string = ''): Observable<any> {
		return this.http.get(
			AppConfig.getAPIEndpoint() + '/estatisticas/media/tempo/entrega/entregadores/' + this.tokenStorage.getUserInfo().idUsuario,
			{
				headers: AppConfig.getHTTPHeader().headers.set('componentId', componentId),
				params: {
					...this.getEstabelecimentoQueryParam(),
					...this.getDateFilterQueryParam(dateFilter.dataInicio, dateFilter.dataFim)
				}
			}
		);
	}

	quantidadeDeVendas(
		dateFilter: DateFilter = <DateFilter>{
			dataInicio: null,
			dataFim: null,
			mes: new Date().getMonth() + 1,
			ano: new Date().getFullYear()
		},
		idProduto: string = null,
		componentId: string = ''
	) {

		return this.http.get(
			AppConfig.getAPIEndpoint() + '/estatisticas/vendas_produto/' + this.tokenStorage.getUserInfo().idUsuario,
			{
				headers: AppConfig.getHTTPHeader().headers.set('componentId', componentId),
				params: {
					...this.getEstabelecimentoQueryParam(),
					...(idProduto ? { idProduto } : {}),

					/** dataInicio e dataFim tem prioridade sobre os parametros de mes e ano */
					...this.getDateFilterQueryParam(dateFilter.dataInicio, dateFilter.dataFim),
					...(dateFilter.mes ? { mes: <any>dateFilter.mes } : {}),
					...(dateFilter.ano ? { ano: <any>dateFilter.ano } : {})
				}
			}
		);
	}

	quantidadeDePedidos(
		dateFilter: DateFilter = <DateFilter>{
			dataInicio: null,
			dataFim: null,
			mes: new Date().getMonth() + 1,
			ano: new Date().getFullYear()
		},
		idProduto: string = null,
		componentId: string = ''
	) {

		return this.http.get(
			AppConfig.getAPIEndpoint() + '/estatisticas/pedidos/' + this.tokenStorage.getUserInfo().idUsuario,
			{
				headers: AppConfig.getHTTPHeader().headers.set('componentId', componentId),
				params: {
					...this.getEstabelecimentoQueryParam(),
					...(idProduto ? { idProduto } : {}),

					/** dataInicio e dataFim tem prioridade sobre os parametros de mes e anoo */
					...this.getDateFilterQueryParam(dateFilter.dataInicio, dateFilter.dataFim),
					...(dateFilter.mes ? { mes: <any>dateFilter.mes } : {}),
					...(dateFilter.ano ? { ano: <any>dateFilter.ano } : {})
				}
			}
		);
	}

	valoresDasVendas(
		dateFilter: DateFilter = <DateFilter>{
			dataInicio: null,
			dataFim: null,
			mes: new Date().getMonth() + 1,
			ano: new Date().getFullYear()
		},
		idProduto: string = null,
		componentId: string = ''
	) {

		return this.http.get(
			AppConfig.getAPIEndpoint() + '/estatisticas/valor_vendas/' + this.tokenStorage.getUserInfo().idUsuario,
			{
				headers: AppConfig.getHTTPHeader().headers.set('componentId', componentId),
				params: {
					...this.getEstabelecimentoQueryParam(),
					...(idProduto ? { idProduto } : {}),

					/** dataInicio e dataFim tem prioridade sobre os parametros de mes e anoo */
					...this.getDateFilterQueryParam(dateFilter.dataInicio, dateFilter.dataFim),
					...(dateFilter.mes ? { mes: <any>dateFilter.mes } : {}),
					...(dateFilter.ano ? { ano: <any>dateFilter.ano } : {})
				}
			}
		);
	}

	novosClientes(
		dateFilter: DateFilter = <DateFilter>{
			dataInicio: null,
			dataFim: null,
			mes: new Date().getMonth() + 1,
			ano: new Date().getFullYear()
		},
		componentId: string = ''
	) {

		return this.http.get(
			AppConfig.getAPIEndpoint() + '/estatisticas/clientes_cadastrados/' + this.tokenStorage.getUserInfo().idUsuario,
			{
				headers: AppConfig.getHTTPHeader().headers.set('componentId', componentId),
				params: {
					...this.getEstabelecimentoQueryParam(),

					/** dataInicio e dataFim tem prioridade sobre os parametros de mes e anoo */
					...this.getDateFilterQueryParam(dateFilter.dataInicio, dateFilter.dataFim),
					...(dateFilter.mes ? { mes: <any>dateFilter.mes } : {}),
					...(dateFilter.ano ? { ano: <any>dateFilter.ano } : {})
				}
			}
		);
	}

	/**
	 * @deprecated Rever forma que a informação é recuperada/renderizada. Subsituido por vendasPorPagamento.
	 */
	$$vendasPorTipoDePagamento(tipoPagamento: string, mes: number = new Date().getMonth() + 1, ano: number = new Date().getFullYear(), idEmpresa: string = null, idLoja: string = null, componentId: string = '') {
		return this.http.post(
			AppConfig.getAPIEndpoint() + '/estatisticas/vendas_tipo_pagamento/' + this.tokenStorage.getUserInfo().idUsuario,
			{
				...(tipoPagamento ? { tipoPagamento } : {}),
				...(mes ? {mes} : {}),
				...(mes ? {ano} : {}),
				...(idEmpresa ? { idEmpresa } : {}),
				...(idLoja ? { idLoja } : {})
			},
			{
				headers: AppConfig.getHTTPHeader().headers.set('componentId', componentId)
			}
		);
	}

	vendasPorPagamento(dateFilter: DateFilter = <any>{}, tipoPagamento: TipoPagamento = null, componentId: string = ''): Observable<DoughnutData[]> {
		return this.http.get<DoughnutData[]>(
			AppConfig.getAPIEndpoint() + '/estatisticas/vendas/pagamento/' + this.tokenStorage.getUserInfo().idUsuario,
			{
				headers: AppConfig.getHTTPHeader().headers.set('componentId', componentId),
				params: {
					...this.getEstabelecimentoQueryParam(),
					...this.getDateFilterQueryParam(dateFilter.dataInicio, dateFilter.dataFim, false),
					...(tipoPagamento ? { tipoPagamento: tipoPagamento } : {})
				}
			}
		);
	}

	vendasPorProduto(dateFilter: DateFilter = <any>{}, componentId: string = ''): Observable<DoughnutData[]> {
		return this.http.get<any[]>(
			AppConfig.getAPIEndpoint() + '/relatorios/vendas/produto/' + this.tokenStorage.getUserInfo().idUsuario,
			{
				headers: AppConfig.getHTTPHeader().headers.set('componentId', componentId),
				params: {
					...this.getEstabelecimentoQueryParam(),
					...this.getDateFilterQueryParam(dateFilter.dataInicio, dateFilter.dataFim, false)
				}
			}
		).pipe(
			map(res => {
				if(res.length){
					return res.map((item: any) => ({
						label: item.nome,
						rawData: item.vendasValorTotal,
						formatedData: `R$ ${item.vendasValorTotal.toFixed(2)} | ${item.qntdVendasProdutos}`
					}))
				}
				else {
					return [{
						label: 'SEM DADOS',
						rawData: 0
					}]
				}
			})
		)
	}

	dadosDosEstabelecimentos(mes: number = new Date().getMonth() + 1, ano: number = new Date().getFullYear(), idEmpresa: string = null, idLoja: string = null, componentId: string = '') {
		return this.http.post(
			AppConfig.getAPIEndpoint() + '/estatisticas/dados_estabelecimentos/' + this.tokenStorage.getUserInfo().idUsuario,
			{
				...(mes ? { mes } : {}),
				...(mes ? { ano } : {}),
				...(idEmpresa ? { idEmpresa } : {}),
				...(idLoja ? { idLoja } : {})
			},
			{
				headers: AppConfig.getHTTPHeader().headers.set('componentId', componentId)
			}
		);
	}

}
