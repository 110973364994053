import {Injectable} from '@angular/core';
import {HttpClient, HttpParams} from '@angular/common/http';
import {TokenStorage} from '../../auth/token-storage.service';
import {ServicePattern} from '../../models/interface/ServicePattern';
import {Estoque} from '../../models/api/Estoque';
import {Observable} from 'rxjs';
import {AppConfig} from '../../../config/appConfig';
import {ResultObject} from '../../models/api/ResultObject';
import {EstabelecimentoFunctions} from '../../models/interface/EstabelecimentoFunctions';


@Injectable()
export class EstoquesService extends EstabelecimentoFunctions implements ServicePattern<Estoque> {

	constructor(private http: HttpClient,
				private tokenStorage: TokenStorage) {
		super();
	}

	getAll(dateFilter: { dataInicio: Date, dataFim: Date } = { dataInicio: null, dataFim: null }, componentId: string = ''): Observable<Estoque[]> {
		return this.http.get<Estoque[]>(
			AppConfig.getAPIEndpoint() + '/estoques/list/' + this.tokenStorage.getUserInfo().idUsuario,
			{
				headers: AppConfig.getHTTPHeader().headers.set('componentId', componentId),
				params: {
					...this.getEstabelecimentoQueryParam(),
					...this.getDateFilterQueryParam(dateFilter.dataInicio, dateFilter.dataFim)
				}
			}
		);
	}

	obterBloqueios(empresas: string = '', lojas: string = '', componentId: string = ''): Observable<any> {
		return this.http.get<any>(
			AppConfig.getAPIEndpoint() + '/estoques/bloqueios/' + this.tokenStorage.getUserInfo().idUsuario,
			{
				headers: AppConfig.getHTTPHeader().headers.set('componentId', componentId),
				params: {
					empresas,
					lojas,
				}
			}
		);
	}

	toggleBloqueio(empresa: string = null, loja: string = null, data: Date, componentId: string = ''): Observable<any> {
		return this.http.post<any>(
			AppConfig.getAPIEndpoint() + '/estoques/bloqueios/' + this.tokenStorage.getUserInfo().idUsuario,
			{
				empresa,
				loja,
				data
			},
			{
				headers: AppConfig.getHTTPHeader().headers.set('componentId', componentId)
			}
		);
	}

	removerBloqueio(idBloqueio: string, componentId: string = ''): Observable<any> {
		return this.http.delete<any>(
			AppConfig.getAPIEndpoint() + '/estoques/bloqueios/' + idBloqueio + '/' + this.tokenStorage.getUserInfo().idUsuario,
			{
				headers: AppConfig.getHTTPHeader().headers.set('componentId', componentId)
			}
		);
	}

	get(idEstoque: number, componentId: string = ''): Observable<Estoque> {
		return this.http.get<Estoque>(
			AppConfig.getAPIEndpoint() + '/estoques/' + idEstoque + '/' + this.tokenStorage.getUserInfo().idUsuario,
			{
				headers: AppConfig.getHTTPHeader().headers.set('componentId', componentId)
			}
		);
	}

	save(body: Estoque, componentId: string = ''): Observable<ResultObject<any>> {
		return this.http.post<ResultObject<any>>(
			AppConfig.getAPIEndpoint() + '/estoques/' + this.tokenStorage.getUserInfo().idUsuario,
			body,
			{
				headers: AppConfig.getHTTPHeader().headers.set('componentId', componentId)
			}
		);
	}

	update(body: Estoque, componentId: string = ''): Observable<ResultObject<any>> {
		return this.http.put<ResultObject<any>>(
			AppConfig.getAPIEndpoint() + '/estoques/' + this.tokenStorage.getUserInfo().idUsuario,
			body,
			{
				headers: AppConfig.getHTTPHeader().headers.set('componentId', componentId)
			}
		);
	}

	delete(idEstoque: number): Observable<ResultObject<any>> {
		// TODO
		return null;
	}

	getEntriesCount(componentId: string = ''): Observable<{entries: number}> {
		return this.http.get<{entries: number}>(
			AppConfig.getAPIEndpoint() + '/estoques/list/' + this.tokenStorage.getUserInfo().idUsuario,
			{
				headers: AppConfig.getHTTPHeader().headers.set('componentId', componentId),
				params: {
					...this.getEstabelecimentoQueryParam(),
					entriesOnly: true
				}
			}
		);
	}

	zerar(data: number, idEmpresa: string, idLoja: string, idProduto: string, senhaAutorizacao: string, componentId: string = ''): Observable<ResultObject<any>> {
		return this.http.put<ResultObject<any>>(
			AppConfig.getAPIEndpoint() + '/estoques/zerar/' + this.tokenStorage.getUserInfo().idUsuario,
			{
				data,
				empresa: idEmpresa,
				produto: idProduto,
				loja: idLoja,
				senhaAutorizacao
			},
			{
				headers: AppConfig.getHTTPHeader().headers.set('componentId', componentId)
			}
		);
	}

	desfazer(senhaAutorizacao: string, componentId: string = ''): Observable<ResultObject<any>> {
		return this.http.post<ResultObject<any>>(
			AppConfig.getAPIEndpoint() + '/estoques/desfazer/' + this.tokenStorage.getUserInfo().idUsuario,
			{
				senhaAutorizacao
			},
			{
				headers: AppConfig.getHTTPHeader().headers.set('componentId', componentId)
			}
		)
	}

	historicoBloqueios(est: string, page: number, per_page: number, componentId = ''): Observable<any> {
		return this.http.get<any>(
			AppConfig.getAPIEndpoint() + '/estoques/historico_bloqueios/' + this.tokenStorage.getUserInfo().idUsuario,
			{
				headers: AppConfig.getHTTPHeader().headers.set('componentId', componentId),
				params: {
					page: page.toString(),
					per_page: per_page.toString(),
					est
				}
			}
		);
	}
}
