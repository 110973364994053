export enum TipoVeiculo {
	AUTOMOVEL = <number>0,
	CAMINHAO = <number>1,
	CAMINHONETE = <number>2,
	CAMIONETA = <number>3,
	CARRETA = <number>4,
	CAVALO = <number>5,
	CHASSI_PLATAFORMA = <number>6,
	MOTOCICLETA = <number>7,
	MOTOR_CASA = <number>8,
	QUADRICICLO = <number>9,
	REBOQUE = <number>10,
	SIDE_CAR = <number>11,
	TRICICLO = <number>12,
	UTILITARIO = <number>13
}
